import * as React from "react";

import { setEditState } from "src/actions";
import { AlgoNode, AlgoNodeInfoType } from "src/api";
import {
  MarkdownTextarea,
  NodeInfoTypeSelect,
  ReferencesEditor
} from "src/components";
import { IOpenAlgorithm } from "src/store";

export interface INodeEditInfoPanelProps {
  node?: AlgoNode;
  openAlgorithm: IOpenAlgorithm;
  setEditState: typeof setEditState;
  updateNode: (node: AlgoNode) => void;
}

export class NodeEditInfoPanel extends React.PureComponent<
  INodeEditInfoPanelProps
> {
  public render() {
    const {
      node,
      openAlgorithm: { editingState }
    } = this.props;
    const { infoPanelFocus } = editingState;

    if (!node) {
      return null;
    }
    let label = "Information Text";
    let value = node.info;
    switch (infoPanelFocus) {
      case AlgoNodeInfoType.meds:
        label = "Medications Text";
        value = node.dosages;
        break;
      case AlgoNodeInfoType.defs:
        label = "Definitions Text";
        value = node.definitions;
        break;
    }

    const sectionRenderer =
      infoPanelFocus === AlgoNodeInfoType.refs ? (
        <ReferencesEditor
          onChange={this.handleRefsChange}
          value={node.references}
        />
      ) : (
        <MarkdownTextarea
          label={label}
          onChange={this.handleTextChange}
          value={value || ""}
          enableRTE={true}
        />
      );

    return (
      <section className="flex flex-column flex-auto w-100">
        <NodeInfoTypeSelect
          value={infoPanelFocus}
          onChange={this.changeInfoType}
        />
        {sectionRenderer}
      </section>
    );
  }

  private changeInfoType = (value: AlgoNodeInfoType) =>
    this.props.setEditState({
      algoId: this.props.openAlgorithm.algorithm.id,
      state: { infoPanelFocus: value }
    });

  private handleTextChange = (text: string) => {
    const {
      openAlgorithm: {
        editingState: { infoPanelFocus }
      },
      node
    } = this.props;
    if (!node) {
      return;
    }

    switch (infoPanelFocus) {
      case AlgoNodeInfoType.info:
        node.info = text;
        break;
      case AlgoNodeInfoType.meds:
        node.dosages = text;
        break;
      case AlgoNodeInfoType.defs:
        node.definitions = text;
        break;
    }
    this.props.updateNode(node);
  };

  private handleRefsChange = (refs: string[]) => {
    const { node } = this.props;
    if (!node) {
      return;
    }
    node.references = refs;
    this.props.updateNode(node);
  };
}
