import { Button, MenuItem } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import { ItemRenderer, Select } from "@blueprintjs/select";
import * as React from "react";

import { ParameterType } from "src/store";

const kParameterTypes: ParameterType[] = ["boolean", "discrete", "numeric"];
const textForParameterType = (type: ParameterType) => {
  switch (type) {
    case "boolean":
      return "Boolean";
    case "discrete":
      return "List";
    case "numeric":
      return "Numeric";
  }
};

interface IParameterTypeSelectProps {
  className?: string;
  disabled?: boolean;
  onChange?: (value: ParameterType) => void;
  value?: ParameterType;
}

const TypeSelect = Select.ofType<ParameterType>();

export class ParameterTypeSelect extends React.PureComponent<
  IParameterTypeSelectProps
> {
  public render() {
    const { disabled, value } = this.props;
    return (
      <div className={`${this.props.className}`}>
        <TypeSelect
          className="flex flex-row flex-auto"
          disabled={disabled}
          filterable={false}
          popoverProps={{ minimal: true }}
          items={kParameterTypes}
          itemRenderer={this.itemRenderer}
          onItemSelect={this.selectionHandler}
          inputProps={{ disabled }}
        >
          <Button
            disabled={disabled}
            fill={true}
            alignText={"left"}
            text={value ? textForParameterType(value) : "Select..."}
            rightIcon={IconNames.CARET_DOWN}
          />
        </TypeSelect>
      </div>
    );
  }

  private itemRenderer: ItemRenderer<ParameterType> = (
    value,
    { handleClick, modifiers: { disabled } }
  ) => {
    return (
      <MenuItem
        disabled={disabled}
        key={value}
        onClick={handleClick}
        text={textForParameterType(value)}
      />
    );
  };

  private selectionHandler = (value: ParameterType) => {
    const { onChange } = this.props;
    if (onChange) {
      onChange(value);
    }
  };
}
