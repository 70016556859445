export * from "./model/object-base";
export * from "./model/algo-node";
export * from "./model/algo-node-path";
export * from "./model/algorithm";
export * from "./model/algostate";
export * from "./model/comment";
export * from "./model/event";
export * from "./model/label";
export * from "./model/moderation";
export * from "./model/media";
export * from "./model/user";
export * from "./model/consumption-utilities";
export * from "./model/card-attributes";
export * from "./model/algo-search-result";
export * from "./model/algo-variables";
export * from "./model/parameter-unit";
export * from "./model/parameter";
export * from "./model/study";
export * from "./model/visit";
export * from "./model/patient";

export * from "./json-serialization";
export * from "./endpoints";

export function notEmpty<TValue>(
  value: TValue | null | undefined
): value is TValue {
  return value !== null && value !== undefined;
}
