import * as firebase from "firebase/app";
import "firebase/messaging";
// Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyAuJTWjFY45xSRnJnWefB5n2SdXzcOImu8",
  appId: "1:901905253657:web:24cdd343f37e22ba",
  authDomain: "openxmed-ebd52.firebaseapp.com",
  databaseURL: "https://openxmed-ebd52.firebaseio.com",
  messagingSenderId: "901905253657",
  projectId: "openxmed-ebd52",
  storageBucket: "openxmed-ebd52.appspot.com"
};

firebase.initializeApp(firebaseConfig);

export const messagingSupported = () => {
  return (
    "Notification" in window &&
    navigator.serviceWorker &&
    firebase.messaging.isSupported()
  );
};
