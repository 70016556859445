import { H3, H6 } from "@blueprintjs/core";
import * as React from "react";
import { connect } from "react-redux";

import { Algorithm, shortName, Study } from "src/api";
import { IBreadcrumbLocation, IStoreState } from "src/store";

const PageTitle: React.SFC<IBreadcrumbLocation> = props => {
  const { relatedObject, title, subTitle } = props;
  let subTitleText = "";
  if (relatedObject instanceof Algorithm) {
    const relatedAlgo = relatedObject as Algorithm;
    subTitleText =
      subTitle && relatedAlgo.authors.length > 0
        ? `${relatedAlgo.authors.map(shortName).join(", ")} - ${subTitle}`
        : subTitle;
  } else if (relatedObject instanceof Study) {
    const relatedStudy = relatedObject as Study;
    subTitleText = `${shortName(relatedStudy.creator)} - ${subTitle}`;
  }

  const titleRenderer = title ? (
    <H3 className="ttc" style={{ marginBottom: 2 }}>
      {title}
    </H3>
  ) : null;

  return (
    <section className="flex flex-column ph2">
      {titleRenderer}
      {subTitleText && <H6 className="flex flex-wrap w-100">{subTitleText}</H6>}
    </section>
  );
};

const mapStateToProps = ({ algoStore, uiStore }: IStoreState) => {
  const { breadCrumbs } = uiStore;
  let pageTitle = "";
  let pageSubTitle = "";
  let relatedObject;

  breadCrumbs.forEach(b => {
    if (b.relatedObject instanceof Algorithm) {
      const algo = b.relatedObject as Algorithm;
      const { openAlgorithms } = algoStore;
      const currentOpenAlgo = openAlgorithms.find(
        oa => oa.algorithm.id === algo.id
      );
      let editing = false;
      if (currentOpenAlgo) {
        editing = currentOpenAlgo.editModeActive;
      }
      const title =
        algo.version > 1 && editing
          ? `${algo.title} (v${algo.version})`
          : algo.title;
      pageTitle = title;
      pageSubTitle = b.subTitle;
      relatedObject = b.relatedObject;
    } else if (b.relatedObject instanceof Study) {
      pageTitle = b.relatedObject.title;
      pageSubTitle = b.subTitle;
      relatedObject = b.relatedObject;
    }
  });

  return {
    relatedObject,
    subTitle: pageSubTitle,
    title: pageTitle
  };
};

export const ConnectedPageTitle = connect(mapStateToProps, {})(PageTitle);
