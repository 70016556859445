import { Button, ButtonGroup, TextArea } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import * as React from "react";

export interface IMarkdownTextareaProps {
  allowResize?: boolean;
  className?: string;
  enableRTE?: boolean;
  label: string;
  onChange: (value: string) => void;
  value: string;
}

export class MarkdownTextarea extends React.PureComponent<
  IMarkdownTextareaProps
> {
  private inputRef: HTMLTextAreaElement | undefined | null;
  private refHandlers = {
    textArea: (ref: HTMLTextAreaElement | null) => (this.inputRef = ref)
  };

  public render() {
    const {
      allowResize,
      className,
      enableRTE,
      label,
      onChange,
      value
    } = this.props;

    const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
      onChange(e.target.value);
    };

    const editingButtons = (
      <ButtonGroup className="pb1">
        <Button
          icon={IconNames.BOLD}
          onClick={this.doBoldThings}
          onMouseDown={this.preventFocusChange}
        />
        <Button
          className="mh1"
          icon={IconNames.ITALIC}
          onClick={this.doItalicThings}
          onMouseDown={this.preventFocusChange}
        />
        <Button
          className="mh1"
          icon={IconNames.PROPERTIES}
          onClick={this.doUnOrderedListThings}
          onMouseDown={this.preventFocusChange}
        />
        <Button
          className="mh1"
          icon={IconNames.NUMBERED_LIST}
          onClick={this.doOrderedListThings}
          onMouseDown={this.preventFocusChange}
        />
        <Button
          className="mh1"
          icon={IconNames.EXPAND_ALL}
          onClick={this.doExpandSectionThings}
          onMouseDown={this.preventFocusChange}
        />
      </ButtonGroup>
    );

    const style: React.CSSProperties = {
      minHeight: allowResize ? "100px" : undefined,
      resize: allowResize ? "vertical" : "none"
    };

    return (
      <div
        className={`${className || ""} flex-column flex flex-auto`}
        style={{ flexShrink: 0 }}
      >
        <p className="ttu mb1 zx-edit-grey">{label}</p>
        {enableRTE && editingButtons}
        <div className="zx-flex-fit">
          <TextArea
            inputRef={this.refHandlers.textArea}
            className="zx-input-edit"
            style={style}
            fill={true}
            onChange={handleChange}
            value={value}
          />
        </div>
      </div>
    );
  }

  private preventFocusChange = (e: React.MouseEvent) => {
    e.preventDefault();
  };

  private doBoldThings = () => {
    this.insertMarkdown("b");
  };

  private doItalicThings = () => {
    this.insertMarkdown("i");
  };

  private doOrderedListThings = () => {
    this.insertMarkdownList("1.");
  };

  private doExpandSectionThings = () => {
    this.insertMarkdown("<details>");
  };

  private doUnOrderedListThings = () => {
    this.insertMarkdownList("* ");
  };

  private insertMarkdown = (type: "b" | "i" | "<details>") => {
    const {
      inputRef: input,
      props: { onChange }
    } = this;

    if (input) {
      const start = input.selectionStart;
      let operatorStart;
      let operatorEnd;

      switch (type) {
        case "b":
          operatorStart = "**";
          break;
        case "i":
          operatorStart = "_";
          break;
        case "<details>":
          operatorStart = `${type}\n<summary>Expand Title Here!</summary>\n`;
          operatorEnd = "</details>\n";
      }

      if (!operatorEnd) {
        operatorEnd = operatorStart;
      }
      const end = input.selectionEnd + operatorStart.length;
      const selectEnd = end + operatorEnd.length;

      input.value =
        input.value.slice(0, start) + operatorStart + input.value.slice(start);
      input.value =
        input.value.slice(0, end) + operatorEnd + input.value.slice(end);

      input.selectionStart = start;
      input.selectionEnd = selectEnd;
      onChange(input.value);
    }
  };

  private insertMarkdownList = (operator: "* " | "1.") => {
    const {
      inputRef: input,
      props: { onChange }
    } = this;

    if (input) {
      const start = input.selectionStart;
      const end = input.selectionEnd + operator.length;
      const selectEnd = end;

      const lines = input.value.split("\n");
      let ti = 0;
      let li = 0;

      do {
        const lineText = lines[li];
        if (lineText === undefined) {
          break;
        }
        const lineLength = lineText.length;
        if (lineLength > 0) {
          if (
            (start >= ti && start <= ti + lineLength) ||
            (start <= ti && end > ti)
          ) {
            lines[li] = operator + lineText;
          }
        }

        ti += lineLength + 1;
        li++;
      } while (ti < end && li < lines.length);
      input.value = lines.join("\n");
      input.selectionStart = start;
      input.selectionEnd = selectEnd;

      onChange(input.value);
    }
  };
}
