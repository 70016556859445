import {
  Button,
  Classes,
  H5,
  Intent,
  Label,
  Radio,
  RadioGroup
} from "@blueprintjs/core";
import * as React from "react";
import { connect } from "react-redux";
import {
  Field,
  FormErrors,
  formValueSelector,
  InjectedFormProps,
  reduxForm,
  WrappedFieldProps
} from "redux-form";

import { IRegistrationData } from "src/actions";
import { ZxLabelType } from "src/api";
import { LabelMultiSelect, LocationSuggest } from "src/components";
import { IStoreState } from "src/store";

const kRegisterFormName = "register";

export interface IRegisterFormProps {
  className?: string;
  errorMessage?: string;
  isMedicalStudent: boolean;
  onCancel: () => void;
}

interface IRenderFieldProps {
  label: string;
  placeholder?: string;
  type?: string;
}

const disableEnterSubmit = (e: React.KeyboardEvent<HTMLFormElement>) => {
  const key = e.charCode || e.keyCode || 0;
  if (key === 13) {
    e.preventDefault();
  }
};

const renderLookup = (
  props: IRenderFieldProps & WrappedFieldProps,
  type: ZxLabelType
) => {
  const { label } = props;

  return (
    <>
      <Label className="white" style={{ marginBottom: 0 }}>
        <span className="ttc">{label}</span>
      </Label>
      <LabelMultiSelect className="mt1 mb3 w-100" {...props} type={type} />
    </>
  );
};
const renderMedSchools = (props: IRenderFieldProps & WrappedFieldProps) =>
  renderLookup(props, ZxLabelType.SCHOOLS);

const renderMedSpecialties = (props: IRenderFieldProps & WrappedFieldProps) =>
  renderLookup(props, ZxLabelType.SPECIALTIES);

const renderField: React.SFC<IRenderFieldProps & WrappedFieldProps> = props => {
  const {
    input,
    type,
    label,
    meta: { touched, error }
  } = props;

  const className = `${Classes.INPUT} w-100 mb1 ${
    touched && error ? Classes.INTENT_DANGER : ""
  }`;

  return (
    <Label className="white">
      {label}
      <input className={className} type={type} {...input} />
      <span className="red">{touched ? error : ""}</span>
    </Label>
  );
};

const renderCountrySearch: React.SFC<IRenderFieldProps &
  WrappedFieldProps> = props => {
  const { label } = props;

  return (
    <Label className="white">
      <span className="ttc">{label}</span>
      <LocationSuggest {...props} />
    </Label>
  );
};

const renderOccupationButtons: React.SFC<IRenderFieldProps &
  WrappedFieldProps> = props => {
  const {
    input,
    meta: { error, touched }
  } = props;

  const occupations = [
    { label: "Medical Student", value: "Medical Student" },
    { label: "Resident", value: "Resident" },
    { label: "Physician", value: "Physician" }
  ];

  const isOther = occupations.find(o => o.value === input.value) ? false : true;

  const occupationsMapper = (o: { value: string; label: string }) => (
    <Radio
      checked={o.value === props.input.value}
      key={o.value}
      label={o.label}
      large={true}
      value={o.value}
    />
  );

  const className = `${Classes.INPUT} w-100 mb1`;

  const otherField = (
    <Label className="white mb3">
      Occupation Title
      <input className={className} type="text" {...input} />
      <span className="red">{touched ? error : ""}</span>
    </Label>
  );

  return (
    <div className="mb3">
      <RadioGroup
        className={`${Classes.DARK}`}
        inline={true}
        label="Occupation"
        name="occupation"
        onChange={input.onChange}
        selectedValue={isOther ? "" : input.value}
      >
        {occupations.map(occupationsMapper)}
        <Radio
          checked={isOther}
          key="other"
          label="Other"
          large={true}
          value=""
        />
      </RadioGroup>
      {isOther && otherField}
    </div>
  );
};

const validate = (
  values: Readonly<IRegistrationData>
): FormErrors<IRegistrationData> => {
  const errors: FormErrors<IRegistrationData> = {}; // empty errors = form good
  const emailError =
    !values.email ||
    (values.email &&
      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,12}$/i.test(values.email))
      ? "Invalid email address"
      : undefined;
  errors.email = emailError;
  if (!values.firstName) {
    errors.firstName = "First name is required";
  }
  if (!values.lastName) {
    errors.lastName = "Last name is required";
  }
  if (!values.password) {
    errors.password = "Password is required";
  } else {
    if (values.password.length < 8) {
      errors.password = "A minimum of 8 characters";
    } else if (values.password.length > 38) {
      errors.password = "A maximum of 38 characters";
    } else if (!/[A-Z]/.test(values.password)) {
      errors.password = "Must contain an uppercase letter";
    } else if (!/[0-9]/.test(values.password)) {
      errors.password = "Must contain a number";
    }
  }
  if (values.confirmPassword !== values.password) {
    errors.confirmPassword = "Passwords must match";
  }
  if (!values.medicalSchools || values.medicalSchools.length === 0) {
    errors.medicalSchools = "Please add your medical school(s)";
  }
  if (
    values.occupation !== "Medical Student" &&
    (!values.medicalSpecialties || values.medicalSpecialties.length === 0)
  ) {
    errors.medicalSpecialties = "Please add your medical specialty";
  }
  if (!values.occupation) {
    errors.occupation = "Please select your occupation";
  }
  if (!values.locationDetails || !values.locationDetails.value.city) {
    errors.locationDetails = "Please provide your current city";
  }

  return errors;
};

const mapStateToProps = (state: IStoreState) => {
  const selector = formValueSelector(kRegisterFormName);
  const occupation = selector(state, "occupation");
  const isMedicalStudent = occupation === "Medical Student";

  return {
    isMedicalStudent
  };
};

const RegisterFormComponent: React.SFC<InjectedFormProps<
  IRegistrationData,
  IRegisterFormProps
> &
  IRegisterFormProps> = props => {
  const specialtiesField = !props.isMedicalStudent && (
    <Field
      name="medicalSpecialties"
      label="Medical Specialty"
      component={renderMedSpecialties}
    />
  );

  return (
    // Preventing enter to submit
    // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
    <form
      className={props.className}
      onSubmit={props.handleSubmit}
      onKeyPress={disableEnterSubmit}
    >
      <Field
        name="firstName"
        type="text"
        label="First Name"
        component={renderField}
      />
      <Field
        name="lastName"
        type="text"
        label="Last Name"
        component={renderField}
      />
      <Field
        name="email"
        type="email"
        label="Email Address"
        component={renderField}
      />
      <Field
        name="password"
        type="password"
        label="Create a Password"
        component={renderField}
      />
      <Field
        name="confirmPassword"
        type="password"
        label="Confirm your Password"
        component={renderField}
      />
      <p className="light-gray f7 mt0 mb4">
        Passwords are 8-38 characters, including at least 1 number and 1
        uppercase letter
      </p>

      <H5 className="zx-blue-lighter ttu">Professional</H5>

      <Field
        name="medicalSchools"
        label="Medical School"
        component={renderMedSchools}
      />
      <Field
        name="occupation"
        label="Occupation"
        component={renderOccupationButtons}
      />
      {specialtiesField}
      <H5 className="zx-blue-lighter ttu">Location of Current Practice</H5>
      <Field
        label="City"
        name="locationDetails"
        component={renderCountrySearch}
      />
      <div className="fr pb2">
        <Button
          className="mr2"
          intent={Intent.NONE}
          text="Cancel"
          onClick={props.onCancel}
        />
        <Button
          intent={Intent.PRIMARY}
          type="submit"
          text="Continue"
          disabled={!props.valid}
        />
      </div>
    </form>
  );
};

export const RegisterForm = connect(
  mapStateToProps,
  {}
)(
  reduxForm<IRegistrationData, IRegisterFormProps>({
    form: kRegisterFormName,
    initialValues: {
      locationDetails: {
        label: "",
        value: {
          city: "",
          country: "",
          region: ""
        }
      },
      medicalSchools: [],
      medicalSpecialties: [],
      occupation: "Medical Student"
    },
    validate
  })(RegisterFormComponent)
);

export default RegisterForm;
