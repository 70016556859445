import { Icon, ButtonGroup, Button } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import * as React from "react";
import { SortableElement, SortableContainer } from "react-sortable-hoc";

import { IOpenAlgorithm } from "src/store";
import { IAlgoNodeTarget } from "src/api";
import { SortHandle } from ".";
import { nodeTitleTextPlain } from "src/utilities";
import { removePath, setEditState } from "src/actions";
import { connect } from "react-redux";

export interface IPageNodeProps {
  link: IAlgoNodeTarget;
  openAlgorithm: IOpenAlgorithm;
}
interface IPageNodeDispatchProps {
  removePath: typeof removePath;
  setEditState: typeof setEditState;
}

export class PageContainedNodeComponent extends React.PureComponent<
  IPageNodeProps & IPageNodeDispatchProps
> {
  public render() {
    const {
      link,
      openAlgorithm: {
        algorithm: { id }
      }
    } = this.props;

    if (!link.node) {
      return (
        <section
          key={link.path.id}
          className="flex flex-row item-start pb2 zx-bg-charcoal-grey"
        />
      );
    }
    const title = nodeTitleTextPlain(link.node);
    const focusNode = () => {
      if (link.node) {
        this.props.setEditState({
          algoId: id,
          state: {
            selectedNodeIds: { [link.node.id]: 2, [link.path.parentId]: 1 },
            selectedPathId: link.path.id
          }
        });
      }
    };
    // const removeLink = () => {
    //   this.props.removePath({ algoId: id, path: link.path });
    // };

    return (
      <section
        key={link.path.id}
        className="flex flex-row items-center pb2 zx-bg-charcoal-grey"
      >
        <SortHandle />
        <div className="flex flex-column flex-auto">
          <div className="flex flex-row items-center justify-between pb2">
            <div className="flex flex-row">
              <Icon className="pl2 pr1" icon={link.node.icon()} />
              {title}
            </div>
            <ButtonGroup className="ml2">
              <Button
                icon={IconNames.EDIT}
                minimal={true}
                onClick={focusNode}
              />
              {/* <Button icon={IconNames.REMOVE} minimal={true} onClick={removeLink} /> */}
            </ButtonGroup>
          </div>
        </div>
      </section>
    );
  }
}

const PageContainedNode = connect(undefined, { removePath, setEditState })(
  PageContainedNodeComponent
);

export const SortablePageNode = SortableElement(
  ({ props }: { props: IPageNodeProps }) => <PageContainedNode {...props} />
);

export const SortablePageNodes = SortableContainer(
  ({ items }: { items: IPageNodeProps[] }) => {
    const wrapper = (props: IPageNodeProps, i: number) => (
      <SortablePageNode index={i} key={props.link.path.id} props={props} />
    );
    return <div>{items.map(wrapper)}</div>;
  }
);
