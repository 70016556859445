import {
  Button,
  ButtonGroup,
  Classes,
  Dialog,
  Intent,
  Menu,
  MenuItem,
  Popover,
  Position
} from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import * as React from "react";
import { connect } from "react-redux";
import { RouteComponentProps } from "react-router";

import { push } from "connected-react-router";
import {
  clearUiError,
  deleteAlgorithm,
  setNewAlgoDialogState,
  setUserFavouriteAlgorithms,
  toggleEditMode
} from "src/actions";
import { AlgorithmStatus, User } from "src/api";
import { kLibraryRootUrl } from "src/config/routes";
import { IOpenAlgorithm, IStoreState } from "src/store";
import { CssSize } from "../../components";

type IEditRunButtonComponentProps = RouteComponentProps<{ algoId: string }>;

interface IEditRunButtonInjectedComponentProps {
  busy: boolean;
  clearUiError: typeof clearUiError;
  cssSize: CssSize;
  deleteAlgorithm: typeof deleteAlgorithm.request;
  error?: Error;
  favesBusy: boolean;
  isFave: boolean;
  openAlgo?: IOpenAlgorithm;
  push: typeof push;
  user?: User;
  setNewAlgoDialogState: typeof setNewAlgoDialogState;
  setFavouriteAlgorithms: typeof setUserFavouriteAlgorithms.request;
  toggleEditMode: typeof toggleEditMode;
}

interface IEditRunButtonComponentState {
  algoMenuOpen: boolean;
  confirmRemoveAlgoOpen: boolean;
}

type EditRunButtonComponentProps = IEditRunButtonComponentProps &
  IEditRunButtonInjectedComponentProps;
class EditRunButtonComponent extends React.PureComponent<
  EditRunButtonComponentProps,
  IEditRunButtonComponentState
> {
  constructor(props: EditRunButtonComponentProps) {
    super(props);
    this.state = {
      algoMenuOpen: false,
      confirmRemoveAlgoOpen: false
    };
  }

  public componentDidUpdate() {
    if (this.state.confirmRemoveAlgoOpen && !this.props.openAlgo) {
      this.props.push(kLibraryRootUrl);
    }
  }

  public render() {
    const { cssSize, favesBusy, isFave, openAlgo, user } = this.props;

    if (!openAlgo || !user || cssSize === 0) {
      return null;
    }

    const { algorithm, editModeActive } = openAlgo;

    const toggle = (e: React.MouseEvent<HTMLElement>) => {
      if (e.currentTarget.id === "edit") {
        if (!editModeActive) {
          this.props.toggleEditMode(this.props.match.params.algoId);
        }
      } else {
        if (editModeActive) {
          this.props.toggleEditMode(this.props.match.params.algoId);
        }
      }
    };

    const editButtons = (
      <ButtonGroup className="pa2">
        <Button
          id="edit"
          text="Edit"
          active={editModeActive}
          intent={editModeActive ? Intent.WARNING : Intent.NONE}
          onClick={toggle}
        />
        <Button
          id="run"
          text="Run"
          active={!editModeActive}
          intent={!editModeActive ? Intent.WARNING : Intent.NONE}
          onClick={toggle}
        />
      </ButtonGroup>
    );

    const handleFaveBtnClick = () => {
      if (isFave) {
        this.props.setFavouriteAlgorithms({
          remove: { id: openAlgo.algorithm.id }
        });
      } else {
        this.props.setFavouriteAlgorithms({
          add: { id: openAlgo.algorithm.id }
        });
      }
    };

    return (
      <section className="flex flex-row">
        {this.renderRemovalConfirmDialog()}
        {algorithm.canBeEditedBy(user) && editButtons}
        <Button
          active={isFave}
          className="mv2 mr1"
          disabled={favesBusy}
          loading={favesBusy}
          icon={IconNames.BOOKMARK}
          onClick={handleFaveBtnClick}
        />
        {this.renderOptionsMenu()}
      </section>
    );
  }

  private renderOptionsMenu = () => {
    const { openAlgo, user } = this.props;
    const { algoMenuOpen } = this.state;

    if (!openAlgo) {
      return null;
    }

    const handleOpen = () => {
      this.setState({ algoMenuOpen: true });
    };
    const handleClose = () => {
      this.setState({ algoMenuOpen: false });
    };
    const handleMenuClick = () => {
      this.setState({ algoMenuOpen: !this.state.algoMenuOpen });
    };
    const content = (
      <Menu onClick={handleMenuClick} className="">
        {this.menuContent(openAlgo, user)}
      </Menu>
    );

    return (
      <Popover
        className="pv2"
        key="algoMenu"
        onClosed={handleClose}
        onOpened={handleOpen}
        minimal={true}
        position={Position.BOTTOM_RIGHT}
        content={content}
      >
        <Button active={algoMenuOpen} icon={IconNames.COG} minimal={true} />
      </Popover>
    );
  };

  private menuContent = (
    { algorithm }: IOpenAlgorithm,
    loggedInUser?: User
  ) => {
    const { id, status, version } = algorithm;
    const confirmDelete = () => {
      this.props.clearUiError();
      this.setState({ confirmRemoveAlgoOpen: true });
    };
    const createCopy = () =>
      this.props.setNewAlgoDialogState({ open: true, baseAlgoId: id });
    const newAlgoOption = (
      <MenuItem
        key="createNew"
        text="Create Copy..."
        onClick={createCopy}
        intent={Intent.PRIMARY}
        icon={IconNames.LAYER}
      />
    );
    const options = [newAlgoOption];

    if (status === AlgorithmStatus.draft) {
      options.unshift(
        <MenuItem
          className="mv1"
          intent={Intent.DANGER}
          key="delete"
          text="Delete Algorithm"
          onClick={confirmDelete}
          icon={IconNames.DELETE}
        />
      );
    } else if (status === AlgorithmStatus.published) {
      const confirmRevision = () =>
        this.props.setNewAlgoDialogState({
          baseAlgoId: id,
          open: true,
          version: version + 1
        });

      if (
        algorithm.authors.find(a => a.id === (loggedInUser && loggedInUser.id))
      ) {
        options.push(
          <MenuItem
            className="mv1"
            intent={Intent.SUCCESS}
            key="revision"
            text="New Revision..."
            onClick={confirmRevision}
            icon={IconNames.LAYER}
          />
        );
      }
    }
    return options;
  };

  private renderRemovalConfirmDialog = () => {
    const { confirmRemoveAlgoOpen } = this.state;
    const { openAlgo } = this.props;

    if (!openAlgo) {
      return null;
    }
    const handleClose = () => this.setState({ confirmRemoveAlgoOpen: false });

    return (
      <Dialog
        canOutsideClickClose={true}
        icon={IconNames.DELETE}
        isCloseButtonShown={false}
        onClose={handleClose}
        isOpen={confirmRemoveAlgoOpen}
        title="Confirm Delete"
      >
        <div className={`${Classes.DIALOG_BODY}`}>
          <p>This will remove &quot;{openAlgo.algorithm.title}&quot;.</p>
          <p>Are you sure?</p>
        </div>
        {this.renderDialogFooter()}
      </Dialog>
    );
  };

  private renderDialogFooter = () => {
    const { busy, error, openAlgo, user } = this.props;

    if (!user || !openAlgo) {
      return null;
    }

    const handleDelete = () => {
      this.props.deleteAlgorithm(openAlgo.algorithm.id);
    };

    const handleCancel = () => this.setState({ confirmRemoveAlgoOpen: false });
    return (
      <div className={`${Classes.DIALOG_FOOTER} flex flex-row justify-between`}>
        <Button
          disabled={busy}
          intent={Intent.NONE}
          onClick={handleCancel}
          text={"Cancel"}
        />
        {error && error.message.length > 0 && (
          <p className="mt2 orange">{error.message}</p>
        )}
        <Button
          className="ml2"
          disabled={busy}
          intent={Intent.DANGER}
          loading={busy}
          onClick={handleDelete}
          text="Delete"
        />
      </div>
    );
  };
}

const mapStateToProps = (
  { algoStore, uiStore, userStore }: IStoreState,
  ownProps: IEditRunButtonComponentProps
) => {
  const openAlgo = algoStore.openAlgorithms.find(
    a => a.algorithm.id === ownProps.match.params.algoId
  );

  const isFave = openAlgo
    ? userStore.faveAlgos.includes(openAlgo.algorithm.id)
    : false;

  return {
    busy: uiStore.library.libraryLoadersCount > 0,
    favesBusy: uiStore.community.userLoadersCount > 0,
    cssSize: uiStore.cssSize,
    error: uiStore.error,
    isFave,
    openAlgo,
    user: userStore.loggedInUser
  };
};

export const ConnectedEditRunButton = connect(mapStateToProps, {
  clearUiError,
  deleteAlgorithm: deleteAlgorithm.request,
  push,
  setNewAlgoDialogState,
  setFavouriteAlgorithms: setUserFavouriteAlgorithms.request,
  toggleEditMode
})(EditRunButtonComponent);
