import { Epic } from "redux-observable";

import { filter, map } from "rxjs/operators";
import { isActionOf } from "typesafe-actions";

import { rehydrate, resetStore } from "src/actions";

export const resetUserErrorOnRehydrate: Epic = action$ =>
  action$.pipe(
    filter(isActionOf(rehydrate)),
    map(() => resetStore())
  );
