import { IconNames } from "@blueprintjs/icons";
import Konva from "konva";
import { Vector2d } from "konva/types/types";

import { AlgoNodeType } from "src/api";

import {
  createAnchorPoint,
  createBlueprintIcon,
  createTextArea,
  ISubNodeRenderProps,
  kBackgroundShadow,
  kCharcoalGray,
  kCornerRadius,
  kEditGrey,
  kMinHeight,
  kNodeWidth,
  kOrange
} from ".";
import { createAlgoLinkVisual } from "./utilities";

export const createChoiceNode = (props: ISubNodeRenderProps) => {
  const { algoNodes, nodeId, parent, path, selectedNodeIds, x, y } = props;
  const node = algoNodes()[nodeId];
  if (!node) {
    return;
  }

  let height = 0;
  const fill = "white";
  const textColour = kCharcoalGray;

  const containerGroup: Konva.Group = new Konva.Group({
    dragBoundFunc: (e: Vector2d) => {
      return { x: containerGroup.getAbsolutePosition().x, y: e.y };
    },
    draggable: false,
    id: `${nodeId}=group`,
    x,
    y
  });

  // Icons
  const isMultiChoice = parent.kind === AlgoNodeType.multiSelect;
  const isSingleChoice = parent.kind === AlgoNodeType.singleSelect;
  const isVariable = parent.kind === AlgoNodeType.varInput;

  if (isSingleChoice) {
    containerGroup.add(
      new Konva.Circle({ radius: 8, fill: kEditGrey, x: 18, y: 16 })
    );
  }

  containerGroup.add(
    createBlueprintIcon({
      colour: isVariable ? kCharcoalGray : isMultiChoice ? kEditGrey : "white",
      id: `${nodeId}=caret`,
      name: isMultiChoice
        ? IconNames.TICK_CIRCLE
        : isSingleChoice
        ? IconNames.ARROW_RIGHT
        : IconNames.VARIABLE,
      scale: isMultiChoice || isVariable ? 1 : 0.75,
      x: isMultiChoice || isVariable ? 10 : 12,
      y: isMultiChoice || isVariable ? 8 : 10
    })
  );

  if (node.hasComments()) {
    containerGroup.add(
      createBlueprintIcon({
        colour: textColour,
        id: `${nodeId}=commentIcon`,
        name: IconNames.COMMENT,
        x: kNodeWidth - 60,
        y: 10
      })
    );
  }
  if (node.hasNotes()) {
    containerGroup.add(
      createBlueprintIcon({
        colour: textColour,
        id: `${nodeId}=notesIcon`,
        name: IconNames.ANNOTATION,
        x: kNodeWidth - 35,
        y: 10
      })
    );
  }

  // Text
  const titleText = createTextArea({
    id: `${nodeId}=titleText`,
    text: node.cachedTitle,
    textColour,
    width: kNodeWidth - 60,
    x: 25
  });

  height += titleText.getHeight();
  containerGroup.add(titleText);

  height = Math.max(height, kMinHeight);
  let anchorOut;

  if (isSingleChoice) {
    anchorOut = createAnchorPoint({
      id: `${nodeId}=out=${path.id}`,
      x: kNodeWidth,
      y: height / 2
    });
    containerGroup.add(anchorOut);

    const target = node.targets(algoNodes())[0];
    if (target) {
      createAlgoLinkVisual({
        container: containerGroup,
        height,
        idIcon: `${parent.id}=algo=${path.targetAlgorithmId}`,
        idLine: `${nodeId}=algoLink=${path.id}`,
        target
      });
    }
  }

  // Background
  const bgRect = new Konva.Rect({
    cornerRadius: kCornerRadius,
    fill,
    height,
    id: `${nodeId}=bg=${parent.id}`,
    ...kBackgroundShadow,
    stroke: selectedNodeIds()[nodeId] ? kOrange : undefined,
    strokeWidth: 5,
    width: kNodeWidth
  });
  containerGroup.add(bgRect);
  bgRect.moveToBottom();

  return { group: containerGroup, height, anchorOut };
};
