import { Alert, Button, Classes, Intent } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import * as React from "react";

export interface IReferencesEditorProps {
  value?: string[];
  onChange: (value: string[]) => void;
}

interface IReferencesEditorState {
  deleteIndex?: number;
}

export class ReferencesEditor extends React.PureComponent<
  IReferencesEditorProps,
  IReferencesEditorState
> {
  constructor(props: IReferencesEditorProps) {
    super(props);
    this.state = {};
  }

  public render() {
    const { value } = this.props;
    const { deleteIndex } = this.state;

    const handleCancelDelete = () => this.setState({ deleteIndex: undefined });
    const removeRef = () => {
      if (value && deleteIndex) {
        this.props.onChange(value.filter((a, j) => deleteIndex !== j));
        this.setState({ deleteIndex: undefined });
      }
    };

    return (
      <div className="flex flex-column flex-auto">
        <Alert
          canEscapeKeyCancel={true}
          canOutsideClickCancel={true}
          cancelButtonText="Cancel"
          onCancel={handleCancelDelete}
          confirmButtonText="Delete"
          onConfirm={removeRef}
          isOpen={deleteIndex !== undefined}
          icon={IconNames.TRASH}
          intent={Intent.DANGER}
        >
          <p>Are you sure you wish to remove this reference?</p>
        </Alert>
        <div className="flex flex-row justify-between">
          <p className="ttu mb1 zx-edit-grey">References</p>
          <Button
            minimal={true}
            rightIcon={IconNames.ADD}
            onClick={this.addNewRef}
          />
        </div>
        <div className="flex flex-column flex-auto">
          {value && value.map((r, i) => this.renderRef(r, i))}
        </div>
      </div>
    );
  }

  private addNewRef = () => {
    const { value } = this.props;
    const newValue = value ? [...value, ""] : [""];
    this.props.onChange(newValue);
  };

  private renderRef = (r: string, i: number) => {
    const { value } = this.props;

    const updateValue = (e: React.ChangeEvent<HTMLInputElement>) => {
      if (value) {
        this.props.onChange(
          value.map((item, index) => (index !== i ? item : e.target.value))
        );
      }
    };

    const handleConfirm = () => this.setState({ deleteIndex: i });

    return (
      <div key={i} className="flex flex-row w-100 mv1">
        <input
          className={`${Classes.INPUT} zx-input-edit w-100 mr1`}
          value={r}
          onChange={updateValue}
        />
        <Button
          minimal={true}
          rightIcon={IconNames.REMOVE}
          onClick={handleConfirm}
        />
      </div>
    );
  };
}
