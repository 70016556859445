import { Type } from "class-transformer";
import { IOptionProps } from "@blueprintjs/core";
import { omit } from "lodash";

import { BaseModel } from "./object-base";

export enum Gender {
  male = "MALE",
  female = "FEMALE"
}

export const kGenderOptions: IOptionProps[] = [
  { label: "Male", value: Gender.male },
  { label: "Female", value: Gender.female }
];

/// Because class-transformer is broken... https://github.com/typestack/routing-controllers/issues/200
export const stripFieldsForPatient = (patient: Patient) => omit(patient, "");

export class Patient extends BaseModel {
  public regionalPatientId = "";

  public email?: string;

  public firstName = "";

  public lastName = "";

  public gender: Gender = Gender.male;

  @Type(() => Date)
  public dateOfBirth?: Date;

  public countryCode = "US";

  public notes?: string;
}
