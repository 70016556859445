import { Classes, Label } from "@blueprintjs/core";
import * as React from "react";

export interface IEditLabeledTextFieldProps {
  label: string;
  onChange: (text: string) => void;
  value: string;
}

export class EditLabeledTextField extends React.PureComponent<
  IEditLabeledTextFieldProps
> {
  public render() {
    const { label, value } = this.props;

    return (
      <Label className="w-100">
        <p className="ttu zx-edit-grey">{label}</p>
        <input
          className={`${Classes.INPUT} zx-input-edit w-100`}
          value={value}
          onChange={this.titleChangeHandler}
        />
      </Label>
    );
  }

  private titleChangeHandler = (evt: React.ChangeEvent<HTMLInputElement>) => {
    this.props.onChange(evt.target.value);
  };
}
