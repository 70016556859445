import { cloneDeep, Dictionary, keyBy } from "lodash";

import {
  IChangeAlgoVarPayload,
  ICommentPayload,
  ICreateNodePayload,
  ICreatePathPayload,
  ILinkNodesPayload,
  IReorderPathsPayload,
  IUpdatePathPayload,
  IContainNodePayload
} from "src/actions";
import {
  AlgoNode,
  AlgoNodePath,
  AlgoNodeType,
  Algorithm,
  IAlgoNodeTarget,
  kConnectableNodeTypes,
  removeFromArray,
  updateArray,
  PathType,
  User
} from "src/api";
import { IOpenAlgorithm } from "src/store";
import { createOrUpdateOpenAlgoData } from ".";
import {
  applyQueueChanges,
  createCommentEvent,
  createCreateNodeEvent,
  createCreatePathEvent,
  createDeletePathEvent,
  createNodeDeleteEvent,
  createNodeUpdateEvent,
  createUpdateAlgoEvent,
  IEventQueueElement,
  updateCommentEvent,
  updatedEventQueue,
  updatePathEvent
} from "./event-queue";

export const updatedOpenAlgoArray = ({
  loggedInUser,
  openAlgos,
  updatedAlgo,
  updatedNodes,
  createOnly = true,
  clearSelection = false,
  removedIds = [],
  eventQueueAdditions
}: IUpdateOpenAlgoParams): IOpenAlgorithm[] => {
  if (updatedAlgo) {
    const algoIndex = openAlgos.findIndex(
      value => value.algorithm.id === updatedAlgo.id
    );
    if (algoIndex === -1) {
      const newArray = openAlgos.slice();
      newArray.push(createOrUpdateOpenAlgoData(loggedInUser, updatedAlgo));
      return newArray;
    } else {
      // Check for event queue error state
      const { algorithm, eventQueue } = openAlgos[algoIndex];
      if (eventQueue.find(e => e.error !== undefined)) {
        // tslint:disable-next-line:no-console
        console.error("Queue is in error state, not updating further");
        return openAlgos;
      }

      const updated = applyQueueChanges(
        { ...keyBy(algorithm.nodes, "id"), ...updatedNodes },
        updatedEventQueue(eventQueue, eventQueueAdditions)
      );

      updatedAlgo.nodes = [...Object.values(updated)];

      return openAlgos.map((item, index) =>
        index !== algoIndex
          ? item
          : createOnly
          ? item
          : createOrUpdateOpenAlgoData(
              loggedInUser,
              updatedAlgo,
              item,
              clearSelection,
              removedIds
            )
      );
    }
  }
  return openAlgos;
};

export const updatedOpenAlgoArrayWithPathReorder = (
  { algoId, newIndex, nodeId, oldIndex, type }: IReorderPathsPayload,
  openAlgos: IOpenAlgorithm[],
  loggedInUser: User
) => {
  const currentOpenAlgo = openAlgos.find(v => v.algorithm.id === algoId);
  if (currentOpenAlgo) {
    const { algoNodes, algorithm } = currentOpenAlgo;
    const node = algoNodes[nodeId];
    const nodeClone = node && cloneDeep(node);

    if (nodeClone) {
      const eventQueueAdditions: IEventQueueElement[] = [];

      const changed = nodeClone.reOrderPaths(
        oldIndex,
        newIndex,
        type,
        algoNodes
      );
      if (changed) {
        changed.forEach(c => eventQueueAdditions.push(updatePathEvent(c.path)));

        return updatedOpenAlgoArray({
          createOnly: false,
          eventQueueAdditions,
          loggedInUser,
          openAlgos,
          updatedAlgo: algorithm,
          updatedNodes: { [nodeClone.id]: nodeClone }
        });
      }
    }
  }
  return openAlgos;
};

export const updatedOpenAlgoArrayCreatingPath = (
  { algoId, linkType, nodeId, childId, varDetails }: ICreatePathPayload,
  openAlgos: IOpenAlgorithm[],
  loggedInUser: User
) => {
  const currentOpenAlgo = openAlgos.find(v => v.algorithm.id === algoId);
  if (currentOpenAlgo) {
    const { algoNodes, algorithm } = currentOpenAlgo;
    const node = cloneDeep(algoNodes[nodeId]);
    const nextOrder = node.nextDisplayOrder(algoNodes);
    const eventQueueAdditions: IEventQueueElement[] = [];

    if (node) {
      const newPath = new AlgoNodePath(nodeId, childId, nextOrder, 0, 0);
      if (linkType) {
        newPath.pathType = linkType;
      }
      newPath.paramsJson = varDetails;
      if (node.kind === AlgoNodeType.varDecision) {
        if (!newPath.paramsJson) {
          newPath.paramsJson = [{}];
        }
      }
      eventQueueAdditions.push(createCreatePathEvent(newPath));
      node.addPath(newPath);
    }

    return updatedOpenAlgoArray({
      createOnly: false,
      eventQueueAdditions,
      loggedInUser,
      openAlgos,
      updatedAlgo: algorithm
    });
  }
  return openAlgos;
};

export const updatedOpenAlgoArrayWithPathAction = (
  { algoId, path }: IUpdatePathPayload,
  openAlgos: IOpenAlgorithm[],
  action: "update" | "delete",
  loggedInUser: User
) => {
  const currentOpenAlgo = openAlgos.find(v => v.algorithm.id === algoId);
  if (currentOpenAlgo) {
    const { algorithm, algoNodes } = currentOpenAlgo;
    const parentNode = cloneDeep(algoNodes[path.parentId]);
    const childNode = path.childId
      ? cloneDeep(algoNodes[path.childId])
      : undefined;
    const eventQueueAdditions: IEventQueueElement[] = [];
    const updatedNodeDict = {};

    // Ensure these are the right way around
    if (path.high < path.low) {
      const temp = path.high;
      path.high = path.low;
      path.low = temp;
    }

    if (parentNode) {
      if (action === "update") {
        parentNode.paths = updateArray(parentNode.paths, path);
        eventQueueAdditions.push(updatePathEvent(path));
      } else if (action === "delete") {
        parentNode.paths = removeFromArray(parentNode.paths, path.id);
        eventQueueAdditions.push(createDeletePathEvent(path));
      }
      updatedNodeDict[parentNode.id] = parentNode;
    }

    if (childNode) {
      if (action === "update") {
        childNode.paths = updateArray(childNode.paths, path);
      } else if (action === "delete") {
        childNode.paths = removeFromArray(childNode.paths, path.id);
      }
      updatedNodeDict[childNode.id] = childNode;
    }

    return updatedOpenAlgoArray({
      createOnly: false,
      eventQueueAdditions,
      loggedInUser,
      openAlgos,
      updatedAlgo: algorithm,
      updatedNodes: updatedNodeDict
    });
  }
  return openAlgos;
};

export const updatedOpenAlgoArrayRemovingNodes = (
  nodeIds: Dictionary<number>,
  algoId: string,
  openAlgos: IOpenAlgorithm[],
  loggedInUser: User
) => {
  const affectedOpenAlgo = openAlgos.find(v => v.algorithm.id === algoId);

  // Don't clear selection if we are only mucking with choices
  if (affectedOpenAlgo) {
    const { algoNodes } = affectedOpenAlgo;
    const updatedNodeDict = {};
    const removedIds: string[] = [];
    let clearSelection = false;
    const eventQueueAdditions: IEventQueueElement[] = [];

    Object.keys(nodeIds).forEach(id => {
      const node = algoNodes[id];
      if (node) {
        // Remove from selection
        removedIds.push(id);
        if (kConnectableNodeTypes.includes(node.kind)) {
          clearSelection = true;
        }

        // Delete the node
        const renderingNode = cloneDeep(
          algoNodes[node.renderingContainerId(algoNodes)]
        );
        renderingNode.paths = renderingNode.removePathToId(node.id, true);
        updatedNodeDict[renderingNode.id] = renderingNode;
        // Remove any contained nodes too.
        node
          .contained(algoNodes)
          .forEach(
            c =>
              c.node && eventQueueAdditions.push(createNodeDeleteEvent(c.node))
          );

        eventQueueAdditions.push(createNodeDeleteEvent(node));

        const affectedChildren = node.unlink(algoNodes);
        affectedChildren.forEach(c => {
          updatedNodeDict[c.id] = c;
        });
      }
    });

    return updatedOpenAlgoArray({
      clearSelection,
      createOnly: false,
      eventQueueAdditions,
      loggedInUser,
      openAlgos,
      removedIds,
      updatedAlgo: affectedOpenAlgo.algorithm,
      updatedNodes: updatedNodeDict
    });
  }
  return openAlgos;
};

export const updatedOpenAlgoArrayLinkingNodes = (
  { algoId, parentId, childId, otherAlgoId, pathId }: ILinkNodesPayload,
  openAlgos: IOpenAlgorithm[],
  loggedInUser: User
) => {
  const currentOpenAlgo = openAlgos.find(v => v.algorithm.id === algoId);
  if (currentOpenAlgo) {
    const { algoNodes, algorithm } = currentOpenAlgo;

    const parentNode = cloneDeep(algoNodes[parentId]);
    const childNode = !otherAlgoId && childId && cloneDeep(algoNodes[childId]);
    const eventQueueAdditions: IEventQueueElement[] = [];
    const updatedNodeDict = {};
    const pathsToCleanup = []; // Cleanup any stray paths...

    const targetLinks = parentNode.targets(algoNodes);
    let existingTargetLink: IAlgoNodeTarget | undefined;

    if (
      [
        AlgoNodeType.varProcessor,
        AlgoNodeType.varInput,
        AlgoNodeType.page
      ].includes(parentNode.kind)
    ) {
      // These can have only one target connection.
      if (targetLinks.length === 1) {
        existingTargetLink = targetLinks[0];
      } else {
        existingTargetLink = targetLinks.find(
          l => l.path.id === pathId || l.path.childId === childId
        );
        // Clean up all other target paths.
        pathsToCleanup.push(
          ...targetLinks.filter(l =>
            existingTargetLink
              ? !(existingTargetLink.path.id !== l.path.id)
              : true
          )
        );
      }
    } else {
      if (targetLinks.length === 1) {
        existingTargetLink = targetLinks[0];
      } else {
        existingTargetLink = targetLinks.find(l => l.path.id === pathId);
      }
    }

    if (existingTargetLink) {
      // Update link for new target
      if (existingTargetLink.path.childId) {
        const child = algoNodes[existingTargetLink.path.childId];
        if (child) {
          child.removePathToId(existingTargetLink.path.parentId, false);
          updatedNodeDict[child.id] = child;
        }
      }
      existingTargetLink.path.childId = childId;
      existingTargetLink.path.targetAlgorithmId = otherAlgoId || null;

      if (childNode) {
        childNode.addPath(existingTargetLink.path);
      }
      eventQueueAdditions.push(updatePathEvent(existingTargetLink.path));
    } else {
      const newPath = new AlgoNodePath(parentId, childId);
      parentNode.addPath(newPath);
      if (childNode) {
        childNode.addPath(newPath);
      }
      eventQueueAdditions.push(createCreatePathEvent(newPath));
    }
    updatedNodeDict[parentNode.id] = parentNode;
    if (childNode) {
      updatedNodeDict[childNode.id] = childNode;
    }

    // TODO:
    // tslint:disable-next-line:no-console
    console.log(pathsToCleanup);
    pathsToCleanup.forEach(p =>
      eventQueueAdditions.push(createDeletePathEvent(p.path))
    );

    return updatedOpenAlgoArray({
      createOnly: false,
      eventQueueAdditions,
      loggedInUser,
      openAlgos,
      updatedAlgo: algorithm,
      updatedNodes: updatedNodeDict
    });
  }
  return openAlgos;
};

export const updatedOpenAlgoArrayContainingNode = (
  {
    algoId,
    containee: prevContainee,
    container: prevContainer,
    displayOrder
  }: IContainNodePayload,
  openAlgos: IOpenAlgorithm[],
  loggedInUser: User
) => {
  const foundIndex = openAlgos.findIndex(v => v.algorithm.id === algoId);
  if (foundIndex !== -1) {
    const foundAlgo = openAlgos[foundIndex];
    const { algoNodes, algorithm } = foundAlgo;
    const eventQueueAdditions: IEventQueueElement[] = [];
    const container = cloneDeep(prevContainer);
    const containee = cloneDeep(prevContainee);
    const updatedNodeDict = {
      [container.id]: container,
      [containee.id]: containee
    };

    // disconnect the containee
    containee.targets(algoNodes).forEach(nt => {
      if (nt.node) {
        const updatedTarget = cloneDeep(nt.node);
        updatedTarget.removePathToId(containee.id, false);
        updatedNodeDict[updatedTarget.id] = updatedTarget;
      }
      eventQueueAdditions.push(createDeletePathEvent(nt.path));
      containee.removePath(nt.path.id);
    });
    containee
      .backwardLinks()
      .forEach(p => eventQueueAdditions.push(createDeletePathEvent(p)));

    // add to container
    let nextOrder = displayOrder;
    if (nextOrder === undefined) {
      nextOrder = container.nextDisplayOrder(algoNodes);
    }
    const linkPath = new AlgoNodePath(container.id, containee.id, nextOrder);
    linkPath.pathType = PathType.contained;

    container.addPath(linkPath);
    containee.addPath(linkPath);

    eventQueueAdditions.push(createNodeUpdateEvent(container));
    eventQueueAdditions.push(createCreatePathEvent(linkPath));

    return updatedOpenAlgoArray({
      createOnly: false,
      eventQueueAdditions,
      loggedInUser,
      openAlgos,
      updatedAlgo: algorithm,
      updatedNodes: updatedNodeDict
    });
  }
  return openAlgos;
};

export const updatedOpenAlgoArrayForNodeText = (
  updatedNode: AlgoNode,
  algorithmId: string,
  openAlgos: IOpenAlgorithm[]
) => {
  const foundIndex = openAlgos.findIndex(v => v.algorithm.id === algorithmId);
  if (foundIndex !== -1) {
    const foundOpenAlgo = openAlgos[foundIndex];
    const { eventQueue } = foundOpenAlgo;
    updatedNode.setCachedText();
    const updateEvent = createNodeUpdateEvent(updatedNode);
    const updatedNodes = applyQueueChanges(
      { [updatedNode.id]: updatedNode },
      updatedEventQueue(eventQueue, [updateEvent])
    );

    const updatedOpenAlgo: IOpenAlgorithm = {
      ...foundOpenAlgo,
      algoNodes: {
        ...foundOpenAlgo.algoNodes,
        ...updatedNodes
      },
      algorithm: {
        ...foundOpenAlgo.algorithm,
        nodes: [
          ...foundOpenAlgo.algorithm.nodes,
          ...Object.values(updatedNodes)
        ]
      }
    };

    return openAlgos.map((oa, index) =>
      index !== foundIndex ? oa : updatedOpenAlgo
    );
  }
  return openAlgos;
};

export const updatedOpenAlgoArrayWithNodes = (
  updatedNodes: AlgoNode[],
  algorithmId: string,
  openAlgos: IOpenAlgorithm[],
  loggedInUser: User,
  createActionPayload?: ICreateNodePayload
) => {
  const algo = openAlgos.find(v => v.algorithm.id === algorithmId);
  if (algo) {
    const { algoNodes, algorithm, editingState } = algo;
    const eventQueueAdditions: IEventQueueElement[] = [];
    updatedNodes.forEach(n => n.setCachedText());
    const updatedNodeDict = keyBy(updatedNodes, "id");

    if (createActionPayload) {
      const {
        displayOrder,
        kind,
        algoId,
        parentId,
        contained
      } = createActionPayload;
      const createdNode = updatedNodes[0];
      createdNode.kind = kind;
      createdNode.algorithmId = algoId;

      createdNode.x = (40 - editingState.x) / editingState.zoom;
      createdNode.y = (40 - editingState.y) / editingState.zoom;

      eventQueueAdditions.push(createCreateNodeEvent(createdNode));

      if (parentId) {
        const parentNode = algorithm.nodes.find(n => n.id === parentId);
        if (parentNode) {
          // We're creating a contained or linked node, so update the parent too
          const parentClone = cloneDeep(parentNode);
          let linkPath;

          if (kind === AlgoNodeType.choice) {
            if (parentClone.kind === AlgoNodeType.multiSelect) {
              createdNode.weight = 1; // Default multiselect to a minimal weight
            }
            linkPath = parentClone.addOption(
              createdNode,
              displayOrder,
              algoNodes
            );
          } else {
            let nextOrder = displayOrder;
            if (nextOrder === undefined) {
              nextOrder = parentClone.nextDisplayOrder(algoNodes);
            }
            linkPath = new AlgoNodePath(parentId, createdNode.id, nextOrder);
            if (contained) {
              linkPath.pathType = PathType.contained;
              if (!createdNode.isContainable()) {
                linkPath = undefined;
              }
            }

            if (linkPath) {
              parentClone.addPath(linkPath);
              createdNode.addPath(linkPath);
            }
          }

          updatedNodeDict[parentClone.id] = parentClone;
          if (linkPath) {
            eventQueueAdditions.push(createCreatePathEvent(linkPath));
          }
        }
      }
    } else {
      // Update only
      updatedNodes.forEach(n => {
        eventQueueAdditions.push(createNodeUpdateEvent(n));
      });
    }

    return updatedOpenAlgoArray({
      createOnly: false,
      eventQueueAdditions,
      loggedInUser,
      openAlgos,
      updatedAlgo: algorithm,
      updatedNodes: updatedNodeDict
    });
  }
  return openAlgos;
};

export const updatedOpenAlgoArrayUpdatingAlgo = (
  details: IUpdateOpenAlgoParams
) => {
  const { updatedAlgo, openAlgos } = details;
  const eventQueueAdditions: IEventQueueElement[] = [];

  if (updatedAlgo) {
    const openAlgo = openAlgos.find(oa => oa.algorithm.id === updatedAlgo.id);
    if (openAlgo) {
      eventQueueAdditions.push(createUpdateAlgoEvent(updatedAlgo));
    }
  }
  return updatedOpenAlgoArray({ ...details, eventQueueAdditions });
};

interface IUpdateOpenAlgoParams {
  clearSelection?: boolean;
  createOnly?: boolean;
  eventQueueAdditions?: IEventQueueElement[];
  loggedInUser: User;
  openAlgos: IOpenAlgorithm[];
  removedIds?: string[];
  updatedAlgo?: Algorithm;
  updatedNodes?: Dictionary<AlgoNode>;
}

export const updatedOpenAlgoArrayCreatingComment = (
  { algoId, nodeId, comment }: ICommentPayload,
  openAlgos: IOpenAlgorithm[],
  loggedInUser: User
) => {
  const currentOpenAlgo = openAlgos.find(v => v.algorithm.id === algoId);
  if (currentOpenAlgo) {
    const { algoNodes, algorithm } = currentOpenAlgo;
    const node = cloneDeep(algoNodes[nodeId]);

    const eventQueueAdditions: IEventQueueElement[] = [];

    if (node) {
      eventQueueAdditions.push(createCommentEvent(comment));
    }

    return updatedOpenAlgoArray({
      createOnly: false,
      eventQueueAdditions,
      loggedInUser,
      openAlgos,
      updatedAlgo: algorithm
    });
  }
  return openAlgos;
};

export const updatedOpenAlgoArrayUpdatingComment = (
  { algoId, nodeId, comment }: ICommentPayload,
  openAlgos: IOpenAlgorithm[],
  loggedInUser: User
) => {
  const currentOpenAlgo = openAlgos.find(v => v.algorithm.id === algoId);
  if (currentOpenAlgo) {
    const { algoNodes, algorithm } = currentOpenAlgo;
    const node = cloneDeep(algoNodes[nodeId]);

    const eventQueueAdditions: IEventQueueElement[] = [];

    if (node) {
      eventQueueAdditions.push(updateCommentEvent(comment));
    }

    return updatedOpenAlgoArray({
      createOnly: false,
      eventQueueAdditions,
      loggedInUser,
      openAlgos,
      updatedAlgo: algorithm
    });
  }
  return openAlgos;
};

export const updatedOpenAlgoArrayChangingVariable = (
  { algoId, paramId, index, value }: IChangeAlgoVarPayload,
  openAlgos: IOpenAlgorithm[]
) => {
  const currentOpenAlgo = openAlgos.find(v => v.algorithm.id === algoId);
  if (currentOpenAlgo) {
    const newVars = cloneDeep(currentOpenAlgo.variableValues);
    let paramVar = newVars[paramId];
    if (!paramVar) {
      paramVar = { variableId: paramId, values: { [index]: value } };
    } else {
      paramVar.values[index] = value;
    }
    newVars[paramId] = paramVar;

    return openAlgos.map(oa =>
      oa.algorithm.id !== algoId ? oa : { ...oa, variableValues: newVars }
    );
  }
  return openAlgos;
};
