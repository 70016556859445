import { Label, Classes } from "@blueprintjs/core";
import * as React from "react";

import { User } from "src/api";
import * as firebase from "firebase/app";
import "firebase/auth";

interface IPasswordValidatorProps {
  disabled?: boolean;
  inputRef?: React.Ref<HTMLInputElement>;
  loggedInUser?: User;
  onSubmit: () => void;
}

interface IPasswordValidatorState {
  error?: Error;
  passwordText?: string;
}

type PasswordValidatorProps = IPasswordValidatorProps;

export class PasswordValidator extends React.PureComponent<
  PasswordValidatorProps,
  IPasswordValidatorState
> {
  constructor(props: PasswordValidatorProps) {
    super(props);
    this.state = {};
  }

  public render() {
    const { disabled } = this.props;
    const { error } = this.state;

    const updatePass = (e: React.ChangeEvent<HTMLInputElement>) => {
      this.setState({ passwordText: e.target.value });
    };
    const errorRenderer = (e: Error) => (
      <span className="mh2 orange">{e.message}</span>
    );
    const checkForEnter = (e: React.KeyboardEvent) => {
      if (e.key === "Enter") {
        this.props.onSubmit();
      }
    };

    return (
      <Label className="pa1 w-100">
        Account Password:
        <input
          ref={this.props.inputRef}
          type="password"
          disabled={disabled}
          className={`${Classes.INPUT} w-100`}
          value={this.state.passwordText || ""}
          onChange={updatePass}
          onKeyUp={checkForEnter}
        />
        {error && errorRenderer(error)}
      </Label>
    );
  }

  public submit = async () => {
    const { passwordText } = this.state;
    const { loggedInUser } = this.props;

    if (loggedInUser && passwordText && passwordText.length > 5) {
      try {
        const creds = await firebase
          .auth()
          .signInWithEmailAndPassword(loggedInUser.email, passwordText);
        if (creds.user) {
          return creds.user.email;
        }
      } catch (e) {
        this.setState({ error: e });
      }
    }
    return undefined;
  };
}
