import {
  Dialog,
  Button,
  Intent,
  Classes,
  Label,
  PopoverPosition
} from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import { DateInput, TimePrecision } from "@blueprintjs/datetime";
import * as React from "react";
import { connect } from "react-redux";
import { Dictionary } from "lodash";

import { Algorithm, User, Study, Visit, Patient } from "src/api";
import { IStoreState } from "src/store";
import { PatientNameSelect } from "src/components";
import { dateRenderer, dateParser } from "src/utilities";

interface IRunAlgorithmDialogProps {
  algorithm?: Algorithm;
  onClose: (visit?: Visit) => void;
  study: Study;
}
interface IRunAlgorithmDialogInjectedProps {
  error?: Error;
  loggedInUser?: User;
  studies: Dictionary<Study>;
}

interface IRunAlgorithmDialogState {
  busy: boolean;
  patient?: Patient;
  visit?: Visit;
}

type RunAlgorithmDialogProps = IRunAlgorithmDialogProps &
  IRunAlgorithmDialogInjectedProps;

class RunAlgorithmDialogComponent extends React.PureComponent<
  RunAlgorithmDialogProps,
  IRunAlgorithmDialogState
> {
  private patientSelect = React.createRef<DateInput>();

  constructor(props: RunAlgorithmDialogProps) {
    super(props);
    this.state = {
      busy: false
    };
  }

  public render() {
    const { algorithm, study } = this.props;

    return (
      <Dialog
        autoFocus={false}
        enforceFocus={false}
        icon={IconNames.LAYER}
        title={"Run algorithm for patient"}
        isCloseButtonShown={false}
        isOpen={algorithm !== undefined}
        onOpened={this.setFocus}
        onClose={this.closeDialog}
      >
        {this.renderBody(study, algorithm)}
        {this.renderFooter()}
      </Dialog>
    );
  }

  private renderBody = (study: Study, algorithm?: Algorithm) => {
    if (!algorithm) {
      return null;
    }
    const { patient } = this.state;

    const handleSelect = (p: Patient) => {
      this.setState({ patient: p });
    };

    const handleDateSelect = (selectedDate: Date, isUserChange: boolean) => {
      if (isUserChange) {
        //
        // this.setState({ visitDateTime: selectedDate });
      }
    };

    const now = new Date();

    const visitsForPatient =
      patient && study.visits
        ? study.visits.filter(v => v.patientId === patient.id)
        : [];
    const existingVisitDates = visitsForPatient.map(v => v.visitDate);

    return (
      <section className={`${Classes.DIALOG_BODY} overflow-y-auto`}>
        <section className="pv2 flex flex-column w-100">
          Run algorithm &apos;{algorithm.title}&apos; for a patient
          <div className="flex flex-row pt2">
            <div className="flex flex-column w-50">
              Patient
              <PatientNameSelect
                onChange={handleSelect}
                value={patient}
                simpleInterface={true}
                study={study}
              />
            </div>
            <div className="flex flex-column w-50">
              <Label>
                Visit Date
                <DateInput
                  dayPickerProps={{ selectedDays: existingVisitDates }}
                  formatDate={dateRenderer}
                  minDate={now}
                  maxDate={study.endDate}
                  onChange={handleDateSelect}
                  parseDate={dateParser}
                  placeholder=""
                  popoverProps={{
                    position: PopoverPosition.TOP,
                    minimal: true
                  }}
                  timePrecision={TimePrecision.MINUTE}
                />
              </Label>
            </div>
          </div>
        </section>
      </section>
    );
  };

  private renderFooter = () => {
    const { error } = this.props;
    const { busy } = this.state;

    const errorRenderer = (e: Error) => (
      <span className="mh2 orange">{e.message}</span>
    );

    return (
      <div className={`${Classes.DIALOG_FOOTER}`}>
        {error && errorRenderer(error)}
        <div className="flex flex-row justify-between pt2">
          <Button id="cancel" text="Cancel" onClick={this.closeDialog} />
          <Button intent={Intent.SUCCESS} text="Run..." loading={busy} />
        </div>
      </div>
    );
  };

  private setFocus = () => {
    setTimeout(() => {
      if (this.patientSelect.current) {
        // TODO: Set the focus
      }
    }, 100);
  };

  private closeDialog = async () => {
    const { visit } = this.state;
    this.props.onClose(visit);
    this.setState({ busy: false, patient: undefined });
  };
}

const mapStateToProps = ({ studiesStore, userStore }: IStoreState) => {
  return {
    error: studiesStore.error,
    loggedInUser: userStore.loggedInUser,
    studies: studiesStore.studies
  };
};

export const RunAlgorithmDialog = connect(
  mapStateToProps,
  {}
)(RunAlgorithmDialogComponent);
