import { Button, Checkbox, H5, Icon, Intent, Label } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import * as React from "react";
import { connect } from "react-redux";
import {
  Field,
  FormErrors,
  formValueSelector,
  InjectedFormProps,
  reduxForm
} from "redux-form";

import { ILocationDetails } from "src/actions";
import { Media, User, userIsAdmin } from "src/api";
import {
  AvatarEditDialog,
  FormField,
  IRenderFieldProps,
  LocationSuggest,
  renderMedSchools,
  renderMedSpecialties,
  renderRegions,
  RolePrivilegeMultiSelect
} from "src/components";
import { UserRoleSelect } from "src/components/userrole-select";
import { IStoreState } from "src/store";
import { disableEnterSubmit } from "src/utilities";

export interface IAdminEditUserFormProps {
  avatarUrl?: string;
  busy: boolean;
  currentLoggedInUser?: User;
  className?: string;
  form: string;
  showAdminControls?: boolean;
  showProfileFields?: boolean;
  updatedUserHasRegions?: boolean; // Optional to allow flaky redux-form typings to work properly
  userId?: string;
}

interface IAdminEditUserFormState {
  imageUrl?: string;
  showEditAvatar: boolean;
}

type AdminEditUserFormProps = InjectedFormProps<
  User & { locationDetails: ILocationDetails },
  IAdminEditUserFormProps
> &
  IAdminEditUserFormProps;

class AdminEditUserFormComponent extends React.PureComponent<
  AdminEditUserFormProps,
  IAdminEditUserFormState
> {
  constructor(props: AdminEditUserFormProps) {
    super(props);
    this.state = {
      showEditAvatar: false
    };
  }

  public render() {
    const {
      avatarUrl,
      busy,
      className,
      currentLoggedInUser,
      dirty,
      handleSubmit,
      pristine,
      showAdminControls,
      showProfileFields,
      submitting,
      updatedUserHasRegions,
      userId,
      valid
    } = this.props;
    const { imageUrl, showEditAvatar } = this.state;

    const canEditImage =
      userIsAdmin(currentLoggedInUser) ||
      (currentLoggedInUser && userId === currentLoggedInUser.id);

    const openAvatarEdit = () => this.setState({ showEditAvatar: true });
    const hideAvatarEdit = (newAvatarUrl?: string) =>
      this.setState({ showEditAvatar: false, imageUrl: newAvatarUrl });

    const imageComponent =
      imageUrl || avatarUrl ? (
        <img
          alt=""
          className="w-100 br-100 zx-shadow"
          draggable={false}
          src={imageUrl || avatarUrl}
        />
      ) : (
        <Icon
          className="w-100 br-100 zx-shadow"
          icon={IconNames.USER}
          iconSize={120}
        />
      );

    const profileFields = (
      <section>
        <Field
          name="interests"
          type="text"
          label="Interests"
          component={FormField}
          disabled={busy}
        />
        <Field
          name="biography"
          type="textarea"
          label="Bio"
          component={FormField}
          disabled={busy}
        />
      </section>
    );

    const adminControls = () => {
      const regionRestrictControl = (
        <Field
          label="User restricted to assigned regions?"
          name="regionsRestricted"
          component={this.renderRegionCheckbox}
          disabled={busy}
        />
      );

      return (
        showAdminControls &&
        userIsAdmin(currentLoggedInUser) && (
          <section className="pa2 mb2 zx-shadow">
            <H5 className="zx-blue-lighter ttu">Admin settings</H5>
            <div className="flex flex-row flex-auto">
              <Field
                label="User Role"
                name="role"
                component={UserRoleSelect}
                disabled={busy}
              />
              <div className="pl3 flex flex-auto">
                <Field
                  label="System Privileges"
                  name="systemRoles"
                  component={RolePrivilegeMultiSelect}
                  disabled={busy}
                />
              </div>
            </div>
            <Field
              label="User Region Affiliations"
              name="regions"
              component={renderRegions}
              disabled={busy}
            />
            {updatedUserHasRegions && regionRestrictControl}
          </section>
        )
      );
    };

    return (
      <form
        className={className}
        onSubmit={handleSubmit}
        onKeyPress={disableEnterSubmit}
      >
        <AvatarEditDialog isOpen={showEditAvatar} onClose={hideAvatarEdit} />
        <div className="flex flex-row items-stretch">
          <div className="flex flex-column flex-auto">
            <Field
              name="firstName"
              type="text"
              label="First Name"
              component={FormField}
              disabled={busy}
            />
            <Field
              name="lastName"
              type="text"
              label="Last Name"
              component={FormField}
              disabled={busy}
            />
          </div>
          <div>
            <div className="flex justify-end">
              <Button
                icon={IconNames.CONFIRM}
                minimal={true}
                type="submit"
                text="Save"
                disabled={!dirty || !valid || pristine || submitting}
                loading={busy}
              />
            </div>
            <div
              className={`w4 pv2 pl2 ${canEditImage ? "pointer" : ""}`}
              onClick={canEditImage ? openAvatarEdit : undefined}
            >
              {imageComponent}
            </div>
          </div>
        </div>

        {showProfileFields && profileFields}
        <H5 className="zx-blue-lighter ttu">Professional</H5>

        <Field
          name="title"
          label="Occupation"
          component={FormField}
          disabled={busy}
        />
        <Field
          name="medicalSchools"
          label="Medical Schools"
          component={renderMedSchools}
          disabled={busy}
        />
        <Field
          name="medicalSpecialties"
          label="Medical Specialties"
          component={renderMedSpecialties}
          disabled={busy}
        />

        <H5 className="zx-blue-lighter ttu">Location of Current Practice</H5>
        <Field
          label="City"
          name="locationDetails"
          component={this.renderCountrySearch}
          disabled={busy}
        />

        {adminControls()}

        <div className="pb2">
          <Button
            icon={IconNames.CONFIRM}
            intent={Intent.PRIMARY}
            type="submit"
            text="Save"
            disabled={!dirty || !valid || pristine || submitting}
            loading={busy}
          />
        </div>
      </form>
    );
  }

  private renderCountrySearch: React.SFC<IRenderFieldProps> = props => {
    const { label } = props;

    return (
      <Label>
        <span className="ttc">{label}</span>
        <LocationSuggest {...props} />
      </Label>
    );
  };

  private renderRegionCheckbox = (props: IRenderFieldProps) => {
    const {
      label,
      disabled,
      input: { value, onChange }
    } = props;

    return (
      <Checkbox
        checked={value === true}
        label={label}
        large={true}
        onChange={onChange}
        disabled={disabled}
      />
    );
  };
}

const validate = (
  values: Readonly<User & { locationDetails: ILocationDetails }>
): FormErrors<User & { locationDetails: ILocationDetails }> => {
  const errors: FormErrors<User & { locationDetails: ILocationDetails }> = {}; // empty errors = form good

  if (!values.firstName) {
    errors.firstName = "First name is required";
  }
  if (!values.lastName) {
    errors.lastName = "Last name is required";
  }

  if (!values.medicalSchools || values.medicalSchools.length === 0) {
    errors.medicalSchools = "Please add medical school(s)";
  }
  if (
    values.title !== "Medical Student" &&
    (!values.medicalSpecialties || values.medicalSpecialties.length === 0)
  ) {
    errors.medicalSpecialties = "Please add medical specialty";
  }
  if (!values.title) {
    errors.title = "Please add occupation";
  }

  return errors;
};

const mapStateToProps = (
  state: IStoreState,
  props: IAdminEditUserFormProps
) => {
  const selector = formValueSelector(props.form);
  const regions = selector(state, "regions");
  const avatar = selector(state, "avatar") as Media;
  const userId = selector(state, "id") as string;
  const hasRegions = regions && regions.length > 0;

  return {
    avatarUrl: avatar && avatar.thumb,
    updatedUserHasRegions: hasRegions,
    userId
  };
};

export const AdminEditUserForm = connect(
  mapStateToProps,
  {}
)(
  reduxForm<
    User & { locationDetails: ILocationDetails },
    IAdminEditUserFormProps
  >({
    validate
  })(AdminEditUserFormComponent)
);

export default AdminEditUserForm;
