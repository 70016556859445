import { IconName, IconNames } from "@blueprintjs/icons";
import { RolePriviledge, User, userIsAdmin } from "src/api";

// Define routing URLs for navigation in the app
export const kLibraryRootUrl = "/content";
export const kModerationMenuUrlKey = "/moderation";
export const kVariablesMenuUrlKey = "/variables";
export const kStudiesUrlKey = "/studies";
export const kStudyUrlKey = "/study";
export const kUsersRootUrl = "/users";
export const kCommunityRootUrl = "/contributors";
export const kLoginUrlKey = "/login";
export const kRegisterUrlKey = "/register";
export const kFirebaseHandlerUrlKey = "/usermgmt";
export const kSearchUrlKey = "/search";
export const kUserSearchUrlKey = "/searchUsers";
export const kProfileUrlKey = "/profile";

export const kUnsupportedBrowserKey = "unsupported";

export interface IMenuOption {
  leftIcon?: IconName;
  title: string;
  topLevelUrlKey: string;
  requiredPriviledge?: RolePriviledge;
  requiresAdmin: boolean;
}

// Define the top level navigation options
export const NavigationMenuOptions: IMenuOption[] = [
  // {
  //   leftIcon: IconNames.BOOK,
  //   requiresAdmin: false,
  //   title: "Library",
  //   topLevelUrlKey: kLibraryRootUrl
  // },
  {
    leftIcon: IconNames.PERSON,
    requiresAdmin: false,
    title: "Community",
    topLevelUrlKey: kCommunityRootUrl
  }
];

export const kAdminMenuOptions: IMenuOption[] = [
  {
    leftIcon: IconNames.INBOX_FILTERED,
    requiredPriviledge: RolePriviledge.Moderate,
    requiresAdmin: true,
    title: "Moderation",
    topLevelUrlKey: kModerationMenuUrlKey
  },
  {
    leftIcon: IconNames.PEOPLE,
    requiredPriviledge: RolePriviledge.AlterUser,
    requiresAdmin: true,
    title: "Users",
    topLevelUrlKey: kUsersRootUrl
  },
  {
    leftIcon: IconNames.VARIABLE,
    requiresAdmin: true,
    title: "Variables",
    topLevelUrlKey: kVariablesMenuUrlKey
  },
  {
    leftIcon: IconNames.STACKED_CHART,
    requiresAdmin: true,
    requiredPriviledge: RolePriviledge.StudyAdmin,
    title: "Studies",
    topLevelUrlKey: kStudiesUrlKey
  }
];

export const adminMenuOptionsForUser = (user?: User) => {
  if (!user) {
    return [];
  }

  if (userIsAdmin(user)) {
    return kAdminMenuOptions;
  }
  const abilities = user.systemRoles || [];
  return kAdminMenuOptions.filter(o => {
    if (!o.requiredPriviledge) {
      return false;
    }
    return abilities.includes(o.requiredPriviledge);
  });
};

export const kContentLayoutInfoKey = "info";
export const kContentLayoutUrlKey = "layout";
export const kContentNotesUrlKey = "notes";
export const kContentVersionsUrlKey = "versions";
export const kContentEditorialUrlKey = "editorial";
export const kContentYoursUrlKey = "yours";
export const kContentReleaseQueuUrlKey = "release-queue";
export const kNodesUrlKey = "nodes";

export const kPostsKey = "posts";
export const kConnectionsKey = "connections";
export const kAlgorithmsKey = "algorithms";

export const kVerify = "verify";

export interface IContentMenuOption {
  title: string;
  urlKey: string;
  requiresEditor: boolean;
}

// Profile area options
export const kPostsActivityMenuOption = { title: "Posts", urlKey: kPostsKey };
export const kConnectionsMenuOption = {
  title: "Connections",
  urlKey: kConnectionsKey
};
export const kAlgoActivityMenuOption = {
  title: "My Algorithms",
  urlKey: kAlgorithmsKey
};
export const kYourContentMenuOption = {
  title: "Your Content",
  urlKey: kContentYoursUrlKey
};

export const kEditorialContentMenuOption = {
  requiresEditor: true,
  title: "Editorial Content",
  urlKey: kContentEditorialUrlKey
};
