export * from "./utilities";
export * from "./start-node";
export * from "./algo-node";
export * from "./choice-node";
export * from "./output-node";
export * from "./page-node";

export const kNodeWidth = 350;
export const kPageWidth = kNodeWidth + 30;
export const kCornerRadius = 5;
export const kMainNodeMinHeight = 60;
export const kPageNodeMinHeight = 200;
export const kPageNodeBottomMargin = 15;
export const kMinHeight = 30;

// TODO: make thes reference the CSS values
export const kCharcoalGray = "#364149";
export const kCharcoalGray2 = "#546574";
export const kDisabledGrey = "#c0c0c0";
export const kCardBgBlue1 = "#38617f";
export const kCardBgBlue2 = "#567992";
export const kEditGrey = "#D5D8D9";
export const kPageGrey = "#dddddd";
export const kOrange = "#ef6342";
export const kEnclosedBorderGrey = "#999999";
