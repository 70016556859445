import { createAsyncAction, createAction } from "typesafe-actions";

import { IOpenxmedApiSuccessResponse, Study, Visit, Patient } from "src/api";
import { IPageParameters } from ".";

interface IRequestStudiesPayload extends IPageParameters {
  id?: string;
  query?: string;
}

export const retrieveStudies = createAsyncAction(
  "retrieveStudies/REQUEST",
  "retrieveStudies/SUCCESS",
  "retrieveStudies/FAILURE"
)<IRequestStudiesPayload, IOpenxmedApiSuccessResponse, Error>();

interface IRequestVisitsPayload extends IPageParameters {
  id?: string;
  patientId?: string;
  query?: string;
  studyId?: string;
}

export const retrieveVisits = createAsyncAction(
  "retrieveVisits/REQUEST",
  "retrieveVisits/SUCCESS",
  "retrieveVisits/FAILURE"
)<IRequestVisitsPayload, IOpenxmedApiSuccessResponse, Error>();

export const createStudy = createAsyncAction(
  "createStudy/REQUEST",
  "createStudy/SUCCESS",
  "createStudy/FAILURE"
)<Study, IOpenxmedApiSuccessResponse, Error>();

export const createStudyVisit = createAsyncAction(
  "createStudyVisit/REQUEST",
  "createStudyVisit/SUCCESS",
  "createStudyVisit/FAILURE"
)<Visit, IOpenxmedApiSuccessResponse, Error>();

export const updateStudy = createAsyncAction(
  "updateStudy/REQUEST",
  "updateStudy/SUCCESS",
  "updateStudy/FAILURE"
)<Study, IOpenxmedApiSuccessResponse, Error>();

export const deleteStudy = createAsyncAction(
  "deleteStudy/REQUEST",
  "deleteStudy/SUCCESS",
  "deleteStudy/FAILURE"
)<Study, IOpenxmedApiSuccessResponse, Error>();

export const clearStudyError = createAction("clearStudyError")();

export const searchPatients = createAsyncAction(
  "searchPatients/REQUEST",
  "searchPatients/SUCCESS",
  "searchPatients/FAILURE"
)<string, IOpenxmedApiSuccessResponse, Error>();

export interface IStudyPatientSearchParams {
  nameQuery: string;
  studyId: string;
}

export const searchStudyPatients = createAsyncAction(
  "searchStudyPatients/REQUEST",
  "searchStudyPatients/SUCCESS",
  "searchStudyPatients/FAILURE"
)<IStudyPatientSearchParams, IOpenxmedApiSuccessResponse, Error>();

export const createPatient = createAsyncAction(
  "createPatient/REQUEST",
  "createPatient/SUCCESS",
  "createPatient/FAILURE"
)<Patient, IOpenxmedApiSuccessResponse, Error>();

export const retrievePatient = createAction("retrievePatient")<string>();

export const getPatient = createAsyncAction(
  "getPatient/REQUEST",
  "getPatient/SUCCESS",
  "getPatient/FAILURE"
)<string, IOpenxmedApiSuccessResponse, Error>();

export interface IPatientStudyParams {
  patient: Patient;
  studyId: string;
}

export const assignPatientToStudy = createAsyncAction(
  "assignPatientToStudy/REQUEST",
  "assignPatientToStudy/SUCCESS",
  "assignPatientToStudy/FAILURE"
)<IPatientStudyParams, IOpenxmedApiSuccessResponse, Error>();

export const removePatientFromStudy = createAsyncAction(
  "removePatientFromStudy/REQUEST",
  "removePatientFromStudy/SUCCESS",
  "removePatientFromStudy/FAILURE"
)<IPatientStudyParams, IOpenxmedApiSuccessResponse, Error>();
