import { Button, Label, MenuItem } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import { ItemRenderer, Select } from "@blueprintjs/select";
import * as React from "react";
import { WrappedFieldProps } from "redux-form";

import { kSelectableUserRoles, UserRole, userRoleString } from "src/api";

export interface IUserRoleSelectProps extends WrappedFieldProps {
  className?: string;
  disabled: boolean;
  label: string;
}

const RoleSelect = Select.ofType<UserRole>();

export class UserRoleSelect extends React.PureComponent<IUserRoleSelectProps> {
  public render() {
    const {
      label,
      disabled,
      input: { value }
    } = this.props;
    return (
      <div className={`${this.props.className} pb2`}>
        <Label style={{ marginBottom: 0 }}>
          <span className="ttc">{label}</span>
        </Label>
        <RoleSelect
          disabled={disabled}
          filterable={false}
          popoverProps={{ minimal: true }}
          items={kSelectableUserRoles}
          itemRenderer={this.itemRenderer}
          onItemSelect={this.selectionHandler}
          inputProps={{ disabled }}
        >
          <Button
            text={userRoleString(value)}
            rightIcon={IconNames.CARET_DOWN}
          />
        </RoleSelect>
      </div>
    );
  }

  private itemRenderer: ItemRenderer<UserRole> = (
    value,
    { handleClick, modifiers: { disabled } }
  ) => {
    return (
      <MenuItem
        active={value === this.props.input.value}
        disabled={disabled}
        key={value}
        onClick={handleClick}
        text={userRoleString(value)}
      />
    );
  };

  private selectionHandler = (i: UserRole) => {
    this.props.input.onChange(i);
  };
}
