import Konva from "konva";
import { KonvaEventObject } from "konva/types/Node";
import { Dictionary } from "lodash";
import * as QueryString from "query-string";
import * as React from "react";
import { connect } from "react-redux";

import {
  createNode,
  createPath,
  linkNodes,
  setEditState,
  updateNodes,
  containNode,
  toggleEditMode
} from "src/actions";
import {
  AlgoNode,
  AlgoNodeType,
  kConnectableNodeTypes,
  PathType
} from "src/api";
import { IEditingState, IOpenAlgorithm, IStoreState } from "src/store";
import { CssSize } from "src/components";
import {
  createKonvaLine,
  createLine,
  createNodeWithProps,
  createSubLink,
  diffAlgoNodes,
  getPointerPosition,
  IAlgoNodeRenderProps,
  IRenderedNode,
  kNodeWidth,
  stagePosForZoom,
  targetIdContext,
  topLevelContainerNodeForId,
  kPageWidth,
  kCardBgBlue2
} from "./konva-components";

export interface IAlgorithmCanvasProps {
  disabled: boolean;
  openAlgorithm: IOpenAlgorithm;
  queryString?: string;
  urlNodeId?: string;
}

interface IAlgorithmCanvasInjectedProps {
  cssSize: CssSize;
  leftMenuOpen: boolean;
}

interface IAlgorithmCanvasDispatchProps {
  containNode: typeof containNode;
  createNode: typeof createNode;
  createPath: typeof createPath;
  linkNodes: typeof linkNodes;
  setEditState: typeof setEditState;
  toggleEditMode: typeof toggleEditMode;
  updateNodes: typeof updateNodes;
}

type AlgorithmCanvasProps = IAlgorithmCanvasProps &
  IAlgorithmCanvasDispatchProps &
  IAlgorithmCanvasInjectedProps;

const kStageContainer = "stageContainer";

class AlgorithmCanvasComponent extends React.PureComponent<
  AlgorithmCanvasProps
> {
  private stage: Konva.Stage | undefined;
  private lastDist = 0;
  private nodeLayer = new Konva.Layer();
  private linkLayer = new Konva.FastLayer();
  private overlayLayer = new Konva.Layer();
  private overlayLink?: Konva.Line;
  private konvaNodes: Dictionary<IRenderedNode> = {};
  private penIsDown = false;
  private interactionsDisabled = false;
  private dragged: Konva.Group | undefined;
  private dropTarget: Konva.Node | undefined;

  constructor(props: AlgorithmCanvasProps) {
    super(props);
    this.interactionsDisabled = props.disabled;
  }

  public componentDidMount() {
    const stage = new Konva.Stage({
      container: kStageContainer,
      draggable: true,
      scaleX: 1,
      scaleY: 1
    });
    window.addEventListener("resize", this.handleResize);
    stage.add(this.linkLayer);
    stage.add(this.nodeLayer);
    stage.add(this.overlayLayer);

    stage.on("dragstart", this.handleDragStart);
    stage.on("dragmove", this.handleDragMove);
    stage.on("dragend", this.handleDragEnd);
    stage.on("dragenter", this.handleDragEnter);
    stage.on("dragleave", this.handleDragLeave);
    stage.on("drop", this.handleDrop);
    stage.on("click tap", this.clickHandler);
    stage.on("mousedown touchstart", this.handlePenDown);
    stage.on("mousemove touchmove", this.handlePenMove);
    stage.on("mouseup touchend", this.handlePenUp);
    stage.on("mouseleave", this.handlePenLeave);

    const { openAlgorithm, queryString, urlNodeId } = this.props;

    const {
      algoNodes,
      algorithm,
      currentPage,
      decisionNodeIndicies,
      editModeActive,
      editingState,
      sectionNodes
    } = openAlgorithm;

    this.stage = stage;
    this.handleResize(); // Size the canvas for the initial render
    this.createKonvaObjects(); // Render all nodes
    this.handleEditingState(editingState, stage);

    if (currentPage > 0) {
      // Focus current node if not at the start.
      const dNodeIdx = decisionNodeIndicies[currentPage];
      const sectionNode = sectionNodes[currentPage];
      if (sectionNode) {
        const pageNode = sectionNode[dNodeIdx || 0];
        if (pageNode) {
          this.focusNode(pageNode.node);
        }
      }
    } else if (urlNodeId) {
      const urlNode = algoNodes[urlNodeId];
      if (urlNode) {
        const options = QueryString.parse(queryString || "");
        this.focusNode(urlNode, options);
      }
    }

    if (this.props.cssSize < 1 && editModeActive) {
      // Too small to edit, revert to run
      this.props.toggleEditMode(algorithm.id);
    }
  }

  public componentWillUnmount() {
    this.linkLayer.destroyChildren();
    this.nodeLayer.destroyChildren();
    if (this.stage) {
      this.stage.off("dragstart", this.handleDragStart);
      this.stage.off("dragmove", this.handleDragMove);
      this.stage.off("dragend", this.handleDragEnd);
      this.stage.off("dragenter", this.handleDragEnter);
      this.stage.off("dragleave", this.handleDragLeave);
      this.stage.off("drop", this.handleDrop);
      this.stage.off("click tap", this.clickHandler);
      this.stage.off("mousedown touchstart", this.handlePenDown);
      this.stage.off("mousemove touchmove", this.handlePenMove);
      this.stage.off("mouseup touchend", this.handlePenUp);
      this.stage.off("mouseleave", this.handlePenLeave);
      this.stage.destroyChildren();
    }
    window.removeEventListener("resize", this.handleResize);
  }

  public async componentDidUpdate(prevProps: AlgorithmCanvasProps) {
    const { stage } = this;
    if (!stage) {
      return;
    }
    const {
      disabled,
      leftMenuOpen,
      openAlgorithm: { algorithm, editingState }
    } = this.props;
    this.interactionsDisabled = disabled;

    if (
      editingState.algoInfoPanel !==
        prevProps.openAlgorithm.editingState.algoInfoPanel ||
      leftMenuOpen !== prevProps.leftMenuOpen
    ) {
      this.handleResize();
    }

    if (prevProps.openAlgorithm.algorithm.id !== algorithm.id) {
      this.clearKonvaObjects();
      this.createKonvaObjects();
      stage.position({ x: 0, y: 0 });
      stage.scale({ x: 1, y: 1 });
    } else {
      if (!this.penIsDown) {
        this.rerenderKonvaDiffs(prevProps);
      }
    }
    this.handleEditingState(editingState, stage, true);
  }

  public render() {
    const ignoreRightClick = (e: React.MouseEvent) => {
      e.preventDefault();
      e.stopPropagation();
    };

    return (
      <div
        id={kStageContainer}
        onWheel={this.handleWheel}
        onTouchMove={this.touchMoveHandler}
        onTouchEnd={this.touchEndHandler}
        onContextMenuCapture={ignoreRightClick}
        className="zx-bg-edit-grey br3 br--bottom flex-auto"
      />
    );
  }

  private async rerenderKonvaDiffs(prevProps: AlgorithmCanvasProps) {
    const {
      openAlgorithm: {
        algoNodes,
        editingState: { selectedNodeIds }
      }
    } = this.props;
    const prevNodes = prevProps.openAlgorithm.algoNodes;
    const {
      added,
      updated,
      deleted,
      pathsUpdates: {
        //   added: pathsAdded
        deleted: pathsDeleted
        //   updated: pathsUpdated,
      }
    } = diffAlgoNodes(prevNodes, algoNodes);

    const nodesToRender = { ...added, ...updated };
    const prevSelection = prevProps.openAlgorithm.editingState.selectedNodeIds;

    const addNodesToRerenderArray = (nodeSelection: Dictionary<number>) => {
      Object.keys(nodeSelection).forEach(nId => {
        const selectedNode = algoNodes[nId];
        if (selectedNode) {
          const container =
            algoNodes[selectedNode.renderingContainerId(algoNodes)];
          nodesToRender[container.id] = container || selectedNode;
        }
      });
    };

    // Ensure any previous selection gets re-rendered to remove any focus ring /visuals.
    addNodesToRerenderArray(prevSelection);
    // Add current new selection
    addNodesToRerenderArray(selectedNodeIds);

    const toDelete = Object.values(deleted);
    toDelete.forEach(d => {
      this.removeKonvaObjectsForNode(d);
    });

    const reRenderArray = Object.values(nodesToRender);
    let nodesNeedRerender = reRenderArray.length > 0 || toDelete.length > 0;

    const deletedPathIds = Object.keys(pathsDeleted);
    if (deletedPathIds.length > 0) {
      nodesNeedRerender = true;
      deletedPathIds.forEach(pId => {
        const deletedPath = pathsDeleted[pId];
        if (deletedPath && deletedPath.childId) {
          const backlinks = this.linksForNode(deletedPath.childId);
          backlinks.forEach(bl => {
            if (bl.link) {
              const { pathId } = targetIdContext(bl.link);
              if (pathId === pId) {
                bl.link.destroy();
              }
            }
          });
        }
      });
    }

    if (nodesNeedRerender) {
      reRenderArray.forEach(n => this.renderKonvaNode(n, algoNodes));
      this.setBacklinks(this.konvaNodes);
      this.nodeLayer.batchDraw();
      this.linkLayer.batchDraw();
    }

    // Focus any single additions - if they are top-level
    const addedIds = Object.keys(added);
    if (addedIds.length === 1) {
      if (kConnectableNodeTypes.includes(algoNodes[addedIds[0]].kind)) {
        const newSelection = {};
        newSelection[addedIds[0]] = addedIds.length;

        this.props.setEditState({
          algoId: this.props.openAlgorithm.algorithm.id,
          state: {
            selectedNodeIds: newSelection
          }
        });
      }
    }
  }

  // Update any redux-stored state to the view
  private handleEditingState(
    editingState: IEditingState,
    stage: Konva.Stage,
    useCenter = false
  ) {
    const { zoom, x, y } = editingState;

    if (zoom === -1) {
      this.fitToScreen();
    } else if (!stage.isDragging()) {
      const stagePos = stage.position();

      let moved = false;
      if (stagePos.x !== x || stagePos.y !== y) {
        stage.position({ x, y });
        stage.batchDraw();
        moved = true;
      }

      if (stage.scaleX() !== zoom) {
        if (moved) {
          this.zoom(zoom, { x, y });
        } else if (useCenter) {
          this.zoom(zoom, stagePosForZoom(stage, zoom));
        } else {
          this.zoom(zoom);
        }
      }
    }
  }

  private clearKonvaObjects() {
    const { stage, nodeLayer, linkLayer, konvaNodes } = this;

    if (!stage) {
      return;
    }
    Object.keys(konvaNodes).forEach(k => delete konvaNodes[k]);
    nodeLayer.destroyChildren();
    linkLayer.destroyChildren();
    stage.batchDraw();
  }

  private createKonvaObjects() {
    const { stage } = this;
    const {
      openAlgorithm: { algoNodes }
    } = this.props;

    if (!stage) {
      return;
    }

    Object.keys(algoNodes).forEach(id => {
      const node = algoNodes[id];
      this.renderKonvaNode(node, algoNodes);
    });
    this.setBacklinks(this.konvaNodes);
  }

  private removeKonvaObjectsForNode(node: AlgoNode) {
    const renderedNode = this.konvaNodes[node.id];

    if (renderedNode) {
      Object.values(renderedNode.links).forEach(l => l.link.destroy());
      Object.values(renderedNode.subOuts).forEach(l => {
        l.children.each(c => c.destroy());
        l.destroy();
      });

      renderedNode.group.destroy();
      delete this.konvaNodes[node.id];
      return true;
    }
    return false;
  }

  private algoNodes = () => this.props.openAlgorithm.algoNodes;

  private selectedNodeIds = () =>
    this.props.openAlgorithm.editingState.selectedNodeIds;

  private subSelectionId = () =>
    this.props.openAlgorithm.editingState.selectedPathId;

  private renderKonvaNode(node: AlgoNode, allNodes: Dictionary<AlgoNode>) {
    const {
      openAlgorithm: { algorithm }
    } = this.props;
    const { stage, nodeLayer } = this;
    if (node.algorithmId !== algorithm.id || !stage) {
      return;
    }

    const { localVars, variables } = algorithm;
    const existing = this.konvaNodes[node.id];
    if (existing) {
      existing.group.destroy();
      existing.subOuts = {};
    }
    const combinedVariables = localVars
      ? [...variables, ...Object.values(localVars)]
      : variables;

    const renderProps: IAlgoNodeRenderProps = {
      algoNodes: this.algoNodes,
      interactionDisabled: () => this.interactionsDisabled,
      linkLayer: this.linkLayer,
      linksForNode: this.linksForNode,
      nodeId: node.id,
      parameters: combinedVariables,
      selectedNodeIds: this.selectedNodeIds,
      subSelectionId: this.subSelectionId
    };
    const renderedNode = createNodeWithProps(renderProps);
    if (renderedNode) {
      nodeLayer.add(renderedNode.group);
      if (!existing) {
        this.konvaNodes[node.id] = renderedNode;
      } else {
        existing.group = renderedNode.group;
        existing.subOuts = renderedNode.subOuts;
      }
      this.createForwardLinksForNode(node, allNodes);
    }
  }

  private linksForNode = (nodeId: string) => {
    const nodeInfo = this.konvaNodes[nodeId];
    if (nodeInfo) {
      return Object.values(nodeInfo.links);
    }
    return [];
  };

  private createForwardLinksForNode(
    node: AlgoNode,
    allNodes: Dictionary<AlgoNode>
  ) {
    const { stage, linkLayer } = this;
    if (!stage) {
      return;
    }
    const startOffset = { x: 0, y: 0 };
    if (
      [
        AlgoNodeType.singleSelect,
        AlgoNodeType.intermediate,
        AlgoNodeType.varInput,
        AlgoNodeType.varProcessor,
        AlgoNodeType.varDecision
      ].includes(node.kind)
    ) {
      startOffset.x += kNodeWidth;
    } else if (AlgoNodeType.page === node.kind) {
      startOffset.x += kPageWidth;
    }

    const konvaNode = this.konvaNodes[node.id];
    if (konvaNode) {
      Object.keys(konvaNode.links).forEach(lk => {
        const value = konvaNode.links[lk];
        if (value.type === "forward") {
          value.link.destroy();
        }
        delete konvaNode.links[lk];
      });

      if (
        !(
          node.kind === AlgoNodeType.multiSelect ||
          node.kind === AlgoNodeType.varDecision
        )
      ) {
        // Handle generic targets (intermediate, start)
        node
          .targets(allNodes)
          .filter(t => t.path.pathType === PathType.link)
          .forEach(t => {
            if (t.path.childId && t.node && !t.path.targetAlgorithmId) {
              // Only draw complete links to nodes in this algorithm
              const link = createLine({
                end: t.node,
                start: node,
                startOffset
              });
              linkLayer.add(link);
              konvaNode.links[link.id()] = {
                link,
                startOffset,
                type: "forward"
              };
            }
          });
      }

      // Link any subOuts
      Object.keys(konvaNode.subOuts).forEach(pid => {
        const path = node.paths.find(p => p.id === pid);
        if (path && path.childId && !path.targetAlgorithmId) {
          const tNode1 = allNodes[path.childId];
          const subNode = konvaNode.subOuts[pid];
          if (tNode1 && subNode) {
            let targetNode = tNode1;
            if (node.kind === AlgoNodeType.singleSelect) {
              const possChoiceTargets = tNode1.targets(allNodes);
              if (possChoiceTargets.length > 0) {
                const possTarget = possChoiceTargets[0].node;
                if (possTarget) {
                  targetNode = possTarget;
                }
              }
            }
            if (
              targetNode.kind !== AlgoNodeType.choice &&
              targetNode.algorithmId === node.algorithmId
            ) {
              const link = createSubLink({
                konvaNode,
                node,
                subNode,
                targetNode
              });
              linkLayer.add(link.line);
              konvaNode.links[link.line.id()] = {
                link: link.line,
                startOffset: link.offset,
                type: "forward"
              };
            }
          }
        }
      });
    }
  }

  // Once we have all nodes and forward paths, we need to link them up to the other side node.
  private setBacklinks(konvaNodes: Dictionary<IRenderedNode>) {
    Object.keys(konvaNodes).forEach(id => {
      // Konva nodes are top level containers
      const konvaNode = this.konvaNodes[id];
      if (konvaNode) {
        Object.keys(konvaNode.links)
          .filter(key => konvaNode.links[key].type === "forward")
          .forEach(linkId => {
            const linkObject = konvaNode.links[linkId];
            const parts = targetIdContext(linkObject.link);
            if (parts.otherId) {
              const childKonvaNode = konvaNodes[parts.otherId];
              if (childKonvaNode) {
                childKonvaNode.links[linkId] = {
                  link: konvaNode.links[linkId].link,
                  startOffset: { x: 0, y: 0 },
                  type: "back"
                };
              }
            }
          });
      }
    });
  }

  private containerSize = () => {
    let width = 0;
    let height = 0;
    if (this.stage) {
      const container = document.querySelector(
        `#${kStageContainer}`
      ) as HTMLDivElement;
      if (container instanceof HTMLDivElement) {
        height = container.offsetHeight;
        width = container.offsetWidth;
      }
    }
    return { width, height };
  };

  private handleResize = () => {
    if (this.stage) {
      this.stage.size(this.containerSize());
    }
  };

  private handleDragStart = (e: KonvaEventObject<MouseEvent>) => {
    if (e.target instanceof Konva.Group) {
      e.target.moveTo(this.overlayLayer);
      this.nodeLayer.draw();
      this.dragged = e.target;
    }
  };

  private handleDragMove = (e: KonvaEventObject<MouseEvent>) => {
    if (this.stage) {
      const pos = this.stage.getPointerPosition();
      if (pos) {
        const intersectingShape = this.nodeLayer.getIntersection(
          pos,
          undefined
        );
        if (intersectingShape && this.dropTarget) {
          if (this.dropTarget !== intersectingShape) {
            this.dropTarget.fire(
              "dragleave",
              {
                type: "dragleave",
                target: this.dropTarget,
                evt: e
              },
              true
            );
            intersectingShape.fire(
              "dragenter",
              {
                type: "dragenter",
                target: intersectingShape,
                evt: e.evt
              },
              true
            );
            this.dropTarget = intersectingShape;
          } else {
            this.dropTarget.fire(
              "dragover",
              {
                type: "dragover",
                target: this.dropTarget,
                evt: e.evt
              },
              true
            );
          }
        } else if (!this.dropTarget && intersectingShape) {
          this.dropTarget = intersectingShape;
          intersectingShape.fire(
            "dragenter",
            {
              type: "dragenter",
              target: intersectingShape,
              evt: e.evt
            },
            true
          );
        } else if (this.dropTarget && !intersectingShape) {
          this.dropTarget.fire(
            "dragleave",
            {
              type: "dragleave",
              target: this.dropTarget,
              evt: e.evt
            },
            true
          );
          this.dropTarget = undefined;
        }
      }
    }
  };

  private handleDragEnd = (e: KonvaEventObject<MouseEvent>) => {
    const { target } = e;
    if (target instanceof Konva.Stage) {
      const { x, y } = target.position();
      this.props.setEditState({
        algoId: this.props.openAlgorithm.algorithm.id,
        state: {
          x,
          y
        }
      });
      return;
    }
    if (e.target instanceof Konva.Node && this.stage) {
      const pos = this.stage.getPointerPosition();
      if (pos) {
        const shape = this.nodeLayer.getIntersection(pos, undefined);
        if (shape) {
          this.dropTarget &&
            this.dropTarget.fire(
              "drop",
              {
                type: "drop",
                target: this.dropTarget,
                evt: e.evt
              },
              true
            );
        }
        this.dropTarget = undefined;
        this.dragged = undefined;

        target.moveTo(this.nodeLayer);
        this.nodeLayer.batchDraw();
        this.overlayLayer.batchDraw();
      }
    }
    if (this.interactionsDisabled) {
      return;
    }

    const targetContext = targetIdContext(target);
    const { targetId } = targetContext;
    if (targetId) {
      const {
        openAlgorithm: {
          algoNodes,
          algorithm: { id },
          editingState
        }
      } = this.props;
      const { selectedNodeIds } = editingState;
      const primaryNode = algoNodes[targetId];

      if (primaryNode) {
        const pos = target.getPosition();
        const updated = {};
        primaryNode.x = pos.x;
        primaryNode.y = pos.y;
        updated[targetId] = primaryNode;

        const otherNodeIds = Object.keys(selectedNodeIds).filter(
          nid => nid !== targetId
        );
        otherNodeIds.forEach(oid => {
          topLevelContainerNodeForId(oid, this.nodeLayer).forEach(
            (kn: Konva.Node) => {
              const otherNode = algoNodes[oid];
              const knPos = kn.position();
              otherNode.x = knPos.x;
              otherNode.y = knPos.y;
              updated[oid] = otherNode;
            }
          );
        });
        this.props.updateNodes({
          algoId: id,
          nodes: Object.values(updated)
        });
      }
    }
  };

  private handleDragEnter = (e: KonvaEventObject<MouseEvent>) => {
    const { target } = e;
    const {
      openAlgorithm: { algoNodes }
    } = this.props;
    if (this.dragged && this.dropTarget) {
      const { targetId } = targetIdContext(target);
      const { targetId: draggedId } = targetIdContext(this.dragged);
      const targetNode = algoNodes[targetId];
      const containee = algoNodes[draggedId];

      if (
        targetNode &&
        targetNode.kind === AlgoNodeType.page &&
        containee.isContainable()
      ) {
        if (target instanceof Konva.Shape) {
          target.stroke(kCardBgBlue2);
          target.strokeWidth(2);
          this.nodeLayer.draw();
        }
      }
    }
  };

  private handleDragLeave = (e: KonvaEventObject<MouseEvent>) => {
    const { target } = e;
    const {
      openAlgorithm: { algoNodes }
    } = this.props;
    if (this.dragged) {
      const { targetId } = targetIdContext(target);
      const { targetId: draggedId } = targetIdContext(this.dragged);
      const targetNode = algoNodes[targetId];
      const containee = algoNodes[draggedId];

      if (
        targetNode &&
        targetNode.kind === AlgoNodeType.page &&
        containee.isContainable()
      ) {
        if (target instanceof Konva.Shape) {
          target.stroke("");
          target.strokeWidth(1);
          this.nodeLayer.draw();
        }
      }
    }
  };

  private handleDrop = (e: KonvaEventObject<MouseEvent>) => {
    const { target } = e;
    const {
      openAlgorithm: {
        algoNodes,
        algorithm: { id: algoId }
      }
    } = this.props;
    if (this.dragged && this.dropTarget) {
      const { targetId } = targetIdContext(target);
      const { targetId: draggedId } = targetIdContext(this.dragged);
      const container = algoNodes[targetId];
      const containee = algoNodes[draggedId];

      if (container && container.kind === AlgoNodeType.page) {
        if (target instanceof Konva.Shape) {
          target.stroke("");
          target.strokeWidth(1);
          this.nodeLayer.draw();
        }
        if (containee.isContainable()) {
          this.props.containNode({ algoId, container, containee });
        }
      }
    }
  };

  private clickHandler = async (e: KonvaEventObject<MouseEvent>) => {
    if (this.interactionsDisabled) {
      return;
    }

    const { evt, target } = e;
    let {
      openAlgorithm: {
        editingState: { selectedNodeIds }
      }
    } = this.props;

    const {
      openAlgorithm: { algoNodes }
    } = this.props;
    const nodeIdsToRedraw = Object.keys(selectedNodeIds);
    let selectionIndex = nodeIdsToRedraw.length + 1;
    let selectedPathId;
    const targetContext = targetIdContext(target);

    if (target instanceof Konva.Stage) {
      selectedNodeIds = {};
    } else {
      const { context, otherId, targetId } = targetContext;

      if (targetId) {
        const targetNode = algoNodes[targetId];
        if (targetNode && targetNode.kind !== AlgoNodeType.entryPoint) {
          if (!this.handledClickAction(context, otherId || targetId)) {
            // Enable multi-select with shift or ctrl-click
            if (!(evt.shiftKey || evt.ctrlKey || evt.metaKey)) {
              selectedNodeIds = {};
              selectionIndex = 1;
            }

            if (otherId) {
              const otherNode = algoNodes[otherId];
              if (otherNode) {
                const containingNodeId = otherNode.containerId();
                nodeIdsToRedraw.push(containingNodeId);
                if (!selectedNodeIds[otherId]) {
                  selectedNodeIds[otherId] = selectionIndex++;
                }
              } else {
                // This means a path has been selected, from the parent.
                // Add that node to the selection
                const backLink = targetNode
                  .backwardLinks()
                  .find(bl => bl.id === otherId);
                if (backLink) {
                  const parent = algoNodes[backLink.parentId];
                  if (parent) {
                    nodeIdsToRedraw.push(parent.id);
                    if (!selectedNodeIds[parent.id]) {
                      selectedNodeIds[parent.id] = selectionIndex++;
                    }
                  }
                }
                selectedPathId = otherId;
              }
            }

            nodeIdsToRedraw.push(targetId);
            if (!selectedNodeIds[targetId]) {
              selectedNodeIds[targetId] = selectionIndex++;
            }
          }
        }
      }
    }

    this.props.setEditState({
      algoId: this.props.openAlgorithm.algorithm.id,
      state: {
        selectedNodeIds,
        selectedPathId
      }
    });

    const konvaNodesToLink = {};
    nodeIdsToRedraw.forEach(id => {
      const konvaNode = algoNodes[id];
      if (konvaNode) {
        this.renderKonvaNode(konvaNode, algoNodes);
        konvaNodesToLink[id] = konvaNode;
      }
    });
    this.setBacklinks(this.konvaNodes);
    this.nodeLayer.batchDraw();
  };

  private handledClickAction = (context: string, nodeId: string) => {
    const contextType = context.split("-")[0] || context;

    switch (contextType) {
      case AlgoNodeType.choice:
        this.props.createNode({
          algoId: this.props.openAlgorithm.algorithm.id,
          kind: AlgoNodeType.choice,
          parentId: nodeId
        });
        return true;

      case "undefined":
        // This is for adding a multi-choice output
        this.props.createPath({
          algoId: this.props.openAlgorithm.algorithm.id,
          nodeId
        });
        return true;
    }
    return false;
  };

  private handlePenDown = (e: KonvaEventObject<MouseEvent>) => {
    const { stage, overlayLayer } = this;

    if (stage && !this.interactionsDisabled) {
      this.penIsDown = true;
      const parsedId = targetIdContext(e.target);
      if (parsedId.context === "out") {
        e.cancelBubble = true;
        this.nodeLayer.children.each((c: Konva.Node) => c.draggable(false));
        const position = getPointerPosition(stage);
        if (position) {
          const { x, y } = position;
          const points = [x, y, x, y];
          this.overlayLink = createKonvaLine({
            id: `${parsedId.targetId}=l=${parsedId.otherId}`,
            points
          });
          overlayLayer.add(this.overlayLink);
        }
      }
    }
  };

  private handlePenMove = () => {
    const { stage, overlayLink, overlayLayer } = this;
    if (overlayLink && stage && !this.interactionsDisabled) {
      const position = getPointerPosition(stage);
      if (position) {
        const { x, y } = position;
        const points = overlayLink.points();
        points[2] = x;
        points[3] = y;
        overlayLink.points(points);
        overlayLayer.batchDraw();
      }
    }
  };

  private handlePenUp = (e: KonvaEventObject<MouseEvent>) => {
    const { overlayLayer, overlayLink, stage } = this;
    if (stage && !this.interactionsDisabled) {
      this.penIsDown = false;
      const parsedId = targetIdContext(e.target);
      if (parsedId.context === "in") {
        if (overlayLink) {
          const linkDetails = targetIdContext(overlayLink);
          this.props.linkNodes({
            algoId: this.props.openAlgorithm.algorithm.id,
            childId: parsedId.targetId,
            parentId: linkDetails.targetId,
            pathId: linkDetails.otherId // Context for multi-select
          });
        }
      }

      // Clean up
      this.nodeLayer.children.each((c: Konva.Node) => c.draggable(true));
      if (overlayLink) {
        e.cancelBubble = true;
        overlayLink.destroy();
        this.overlayLink = undefined;
        overlayLayer.batchDraw();
      }
    }
  };

  private handlePenLeave = (e: KonvaEventObject<MouseEvent>) => {
    if (e.target === this.stage) {
      this.handlePenUp(e);
    }
  };

  private touchMoveHandler = (e: React.TouchEvent) => {
    const { stage } = this;
    if (stage && !this.interactionsDisabled) {
      if (e.touches.length === 2) {
        e.preventDefault();
        e.stopPropagation();

        const touch1 = e.touches[0];
        const touch2 = e.touches[1];

        const touchMidPoint = {
          x: (touch2.clientX + touch1.clientX) / 2,
          y: (touch2.clientY + touch1.clientY) / 2
        };
        const dist = Math.hypot(
          touch1.clientX - touch2.clientX,
          touch1.clientY - touch2.clientY
        );

        if (this.lastDist === 0) {
          this.lastDist = dist;
        }
        const scale = (stage.scaleX() * dist) / this.lastDist;
        this.zoom(scale, stagePosForZoom(stage, scale, false, touchMidPoint));

        this.lastDist = dist;
      }
    }
  };

  private touchEndHandler = (e: React.TouchEvent) => {
    if (e.touches.length === 0) {
      this.lastDist = 0;
    }
  };

  private handleWheel = (e: React.WheelEvent<HTMLDivElement>) => {
    if (!this.stage) {
      return;
    }
    if (e.ctrlKey || e.metaKey) {
      const scaleBy = 1.2;
      const oldScale = this.stage.scaleX();
      const scale = e.deltaY < 0 ? oldScale * scaleBy : oldScale / scaleBy;
      const newScale = Math.max(Math.min(scale, 1.2), 0.1);
      const newPos = stagePosForZoom(this.stage, newScale, false);
      this.zoom(newScale, newPos);
    } else {
      // Move stage around
      const pos = this.stage.position();
      this.stage.position({ x: pos.x - e.deltaX, y: pos.y - e.deltaY });
      this.stage.batchDraw();
    }
  };

  private zoom = (scale: number, newPos?: { x: number; y: number }) => {
    const { stage } = this;
    if (!stage) {
      return;
    }
    const newScale = Math.max(Math.min(scale, 1.2), 0.1);

    if (newPos) {
      stage.position(newPos);
    }
    const oldScale = stage.scaleX();

    if (newScale !== oldScale) {
      stage.scaleX(newScale);
      stage.scaleY(newScale);
    }

    if (newPos || newScale !== oldScale) {
      stage.batchDraw();
      const { x, y } = stage.position();
      const {
        x: propsX,
        y: propsY,
        zoom: propsZoom
      } = this.props.openAlgorithm.editingState;

      if (propsX !== x || propsY !== y || propsZoom !== newScale) {
        this.props.setEditState({
          algoId: this.props.openAlgorithm.algorithm.id,
          state: {
            x,
            y,
            zoom: newScale
          }
        });
      }
    }
  };

  private fitToScreen = () => {
    const {
      openAlgorithm: {
        algoNodes,
        algorithm: { id }
      }
    } = this.props;
    const visibleSize = this.containerSize();
    const kLayoutMargin = 20;

    let minX = 0;
    let minY = 0;
    let maxX = visibleSize.width;
    let maxY = visibleSize.height;

    Object.keys(algoNodes).forEach(key => {
      const node = algoNodes[key];
      if (node.algorithmId === id) {
        minX = Math.min(minX, node.x);
        maxX = Math.max(maxX, node.x + kNodeWidth);
        minY = Math.min(minY, node.y);
        maxY = Math.max(maxY, node.y + kNodeWidth);
      }
    });

    const scaleXcourse = visibleSize.width / (maxX - minX);
    const scaleX =
      visibleSize.width / (maxX - minX + (2 * kLayoutMargin) / scaleXcourse);
    const scaleYcourse = visibleSize.height / (maxY - minY);
    const scaleY =
      visibleSize.height / (maxY - minY + (2 * kLayoutMargin) / scaleYcourse);

    const scale = Math.min(scaleX, scaleY);
    this.zoom(scale, {
      x: kLayoutMargin - minX * scale,
      y: kLayoutMargin - minY * scale
    });
  };

  private focusNode = (
    node: AlgoNode,
    options?: QueryString.ParsedQuery<string>
  ) => {
    const {
      algorithm: { id }
    } = this.props.openAlgorithm;
    let commentId;
    if (options) {
      commentId = options["comment"];
    }

    if (this.stage) {
      const konvaNode = this.konvaNodes[node.id];
      if (konvaNode) {
        this.props.setEditState({
          algoId: id,
          state: {
            panelFocus: commentId ? "notes" : "attrs",
            selectedNodeIds: { [node.id]: 1 },
            x: -1 * node.x + 100,
            y: -1 * node.y + 100,
            zoom: 1
          }
        });
      }
    }
  };
}

const mapStateToProps = ({ uiStore }: IStoreState) => {
  return {
    cssSize: uiStore.cssSize,
    leftMenuOpen: uiStore.leftMenuOpen
  };
};

export const AlgorithmCanvas = connect(mapStateToProps, {
  containNode,
  createNode,
  createPath,
  linkNodes,
  setEditState,
  toggleEditMode,
  updateNodes
})(AlgorithmCanvasComponent);
