import { Button, Classes, Dialog, Intent, Checkbox } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import * as React from "react";
import { Dictionary } from "lodash";
import { connect } from "react-redux";

import { IStoreState } from "src/store";
import { Study, User } from "src/api";
import { updateStudy, clearStudyError } from "src/actions";
import { PasswordValidator } from "src/components";

interface IApproveStudyDialogProps {
  isOpen: boolean;
  study: Study;
  onClose: (studyId?: string) => void;
}

interface IApproveStudyDialogInjectedProps {
  error?: Error;
  loggedInUser?: User;
  studies: Dictionary<Study>;
}

interface IApproveStudyDispatchProps {
  clearStudyError: typeof clearStudyError;
  updateStudy: typeof updateStudy.request;
}

interface IApproveStudyDialogComponentState {
  busy: boolean;
  checkboxChecked: boolean;
  passwordText?: string;
  passwordValidated: boolean;
}

type ApproveStudyDialogProps = IApproveStudyDialogProps &
  IApproveStudyDialogInjectedProps &
  IApproveStudyDispatchProps;

class ApproveStudyDialogComponent extends React.PureComponent<
  ApproveStudyDialogProps,
  IApproveStudyDialogComponentState
> {
  private passwordField = React.createRef<HTMLInputElement>();
  private passwordValidator = React.createRef<PasswordValidator>();

  constructor(props: ApproveStudyDialogProps) {
    super(props);

    this.state = {
      busy: false,
      checkboxChecked: false,
      passwordValidated: false
    };
    this.props.clearStudyError();
  }

  public componentDidUpdate(prevProps: ApproveStudyDialogProps) {
    const { error, isOpen } = this.props;

    if (!prevProps.isOpen && isOpen) {
      if (error) {
        this.props.clearStudyError();
        this.setState({ checkboxChecked: false });
      }
    }
  }

  public render() {
    const { isOpen, study } = this.props;

    return (
      <Dialog
        autoFocus={false}
        enforceFocus={false}
        icon={IconNames.STACKED_CHART}
        title={`Approve Study "${study.title}"`}
        isCloseButtonShown={false}
        isOpen={isOpen}
        onClose={this.closeDialog}
        onOpened={this.setFocus}
      >
        {this.renderBody()}
        {this.renderFooter()}
      </Dialog>
    );
  }

  private setFocus = () => {
    setTimeout(() => {
      if (this.passwordField.current) {
        this.passwordField.current.focus();
      }
    }, 100);
  };

  private renderFooter = () => {
    const { error } = this.props;
    const { busy, checkboxChecked } = this.state;

    const errorRenderer = (e: Error) => (
      <span className="mh2 orange">{e.message}</span>
    );

    return (
      <div className={`${Classes.DIALOG_FOOTER}`}>
        {error && errorRenderer(error)}
        <div className="flex flex-row justify-between pt2">
          <Button id="cancel" text="Cancel" onClick={this.closeDialog} />
          <Button
            intent={Intent.SUCCESS}
            text="Approve Study"
            disabled={!checkboxChecked}
            loading={busy}
            onClick={this.validatePassword}
          />
        </div>
      </div>
    );
  };

  private renderBody = () => {
    const { checkboxChecked, busy } = this.state;

    const handleCheckbox = (event: React.FormEvent<HTMLInputElement>) =>
      this.setState({ checkboxChecked: event.currentTarget.checked });

    return (
      <section className={`${Classes.DIALOG_BODY} overflow-y-auto`}>
        <Checkbox
          checked={this.state.checkboxChecked}
          onChange={handleCheckbox}
        >
          I certify that I have received the necessary ethics approval for this
          study.
        </Checkbox>
        <section className="pv2 flex flex-row w-100">
          <PasswordValidator
            ref={this.passwordValidator}
            disabled={!checkboxChecked || busy}
            loggedInUser={this.props.loggedInUser}
            onSubmit={this.validatePassword}
          />
        </section>
      </section>
    );
  };

  private closeDialog = async () => {
    this.props.onClose();
  };

  private validatePassword = async () => {
    if (this.passwordValidator.current) {
      this.setState({ busy: true });
      const resultOrError = await this.passwordValidator.current.submit();
      if (resultOrError as string) {
        if (resultOrError === this.props.loggedInUser!.email) {
          this.props.onClose();
        }
      }
      this.setState({ busy: false });
    }
  };
}

const mapStateToProps = ({ studiesStore, userStore }: IStoreState) => {
  return {
    error: studiesStore.error,
    loggedInUser: userStore.loggedInUser,
    studies: studiesStore.studies
  };
};

export const ApproveStudyDialog = connect(mapStateToProps, {
  updateStudy: updateStudy.request,
  clearStudyError
})(ApproveStudyDialogComponent);
