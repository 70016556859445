import { Tab, Tabs } from "@blueprintjs/core";
import * as React from "react";

import { ConsumePanelFocusType, IOpenAlgorithm } from "src/store";
import { DecisionTree } from ".";
import { DecisionNodes } from "./decision-nodes";

export interface IDecisionTabProps {
  algorithm: IOpenAlgorithm;
  className?: string;
  focus?: ConsumePanelFocusType;
  style?: React.CSSProperties;
  onFocusChange: (newFocus: ConsumePanelFocusType) => void;
  onClick?: () => void;
}

interface IDecisionTabState {
  selectedPane: string;
}

export class DecisionTabs extends React.PureComponent<
  IDecisionTabProps,
  IDecisionTabState
> {
  constructor(props: IDecisionTabProps) {
    super(props);

    this.state = {
      selectedPane: props.focus
        ? props.focus
        : props.algorithm.algoState.decisionsJson.length === 0
        ? "contents"
        : "choices"
    };
  }

  public componentDidUpdate() {
    const { algorithm, focus } = this.props;
    const { selectedPane } = this.state;

    if (focus) {
      if (this.state.selectedPane !== focus) {
        this.setState({ selectedPane: focus });
      }
    } else if (
      algorithm.algoState.decisionsJson.length > 0 &&
      selectedPane !== "choices"
    ) {
      this.setState({ selectedPane: "choices" });
    }
  }

  public render() {
    const { algorithm, className, style, onClick, onFocusChange } = this.props;
    const { selectedPane } = this.state;

    const handleFocusChange = (
      newTabId: React.ReactText,
      _: React.ReactText,
      e: React.MouseEvent<HTMLElement>
    ) => {
      e.stopPropagation();
      if (onFocusChange) {
        onFocusChange(newTabId.toString() as ConsumePanelFocusType);
      }
    };

    return (
      <div
        onClick={onClick}
        style={style}
        className={`h-100 flex ${className}`}
      >
        <section className="flex flex-auto bg-white pa2 br3 w-100 h-100">
          <Tabs
            className="w-100"
            onChange={handleFocusChange}
            selectedTabId={selectedPane}
          >
            <Tab
              className="zx-edit-panel-height"
              id="choices"
              panel={<DecisionTree algorithm={algorithm} />}
              title="Review Choices"
            />
            <Tab
              className="zx-edit-panel-height"
              id="contents"
              panel={<DecisionNodes algorithm={algorithm} />}
              title="Contents"
            />
          </Tabs>
        </section>
      </div>
    );
  }
}
