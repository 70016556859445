import { Button, Tab, Tabs } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import * as React from "react";

import {
  AlgoNode,
  AlgoNodeInfoType,
  commentCountForNode,
  CommentKind,
  AlgorithmStatus
} from "src/api";
import { InfoTabType, IOpenAlgorithm } from "src/store";
import { NodeDefs, NodeInfo, NodeMedications, NodeRefs } from ".";
import { NodeComments } from "./node-comments";

export interface INodeInfoPaneProps {
  className?: string;
  width?: number;
  openAlgorithm: IOpenAlgorithm;
  onTabChange: (tab: InfoTabType) => void;
  onClose?: (node: AlgoNode) => void;
  node?: AlgoNode;
  focus?: InfoTabType;
}

export class NodeInfoPane extends React.PureComponent<INodeInfoPaneProps> {
  public render() {
    const {
      className,
      focus,
      node,
      onClose,
      onTabChange,
      openAlgorithm,
      width
    } = this.props;

    if (!node) {
      return null;
    }

    // Adjust width
    const innerWidth = width ? width - 30 : 0;
    const handleClose = () => onClose && node && onClose(node);

    const handleClick = (e: React.MouseEvent) => e.stopPropagation();
    const handleTabChange = (newTab: string) => {
      if (newTab !== focus) {
        onTabChange(newTab as InfoTabType);
      }
    };

    const infoTab = (
      <Tab
        id={AlgoNodeInfoType.info}
        panel={<NodeInfo width={innerWidth} node={node} />}
        title="Info"
      />
    );
    const refsTab = (
      <Tab
        id={AlgoNodeInfoType.refs}
        panel={<NodeRefs width={innerWidth} node={node} />}
        title="References"
      />
    );
    const defsTab = (
      <Tab
        id={AlgoNodeInfoType.defs}
        panel={<NodeDefs width={innerWidth} node={node} />}
        title="Definitions"
      />
    );
    const dosagesTab = (
      <Tab
        id={AlgoNodeInfoType.meds}
        panel={<NodeMedications width={innerWidth} node={node} />}
        title="Medications"
      />
    );

    const commentKind = [
      AlgorithmStatus.published,
      AlgorithmStatus.superceded
    ].includes(openAlgorithm.algorithm.status)
      ? CommentKind.Comment
      : CommentKind.Note;

    const commentsPanel = (
      <NodeComments
        commentKindFilter={commentKind}
        node={node}
        width={innerWidth}
        openAlgorithm={openAlgorithm}
      />
    );

    const tabTitle = `${
      commentKind === CommentKind.Comment ? "Comments" : "Notes"
    } (${commentCountForNode(node, commentKind)})`;

    return (
      <article
        className={`pb2 h-auto w-100 br2 ${className}`}
        onClick={handleClick}
      >
        <section className="pa1 h-100 w-100 dit">
          <Tabs
            selectedTabId={focus}
            onChange={handleTabChange}
            animate={false}
          >
            {node.info && infoTab}
            {node.references.length > 0 && refsTab}
            {node.definitions && defsTab}
            {node.dosages && dosagesTab}
            <Tab id="comments" panel={commentsPanel} title={tabTitle} />
            <Tabs.Expander />
            {onClose && (
              <Button
                icon={IconNames.CROSS}
                onClick={handleClose}
                minimal={true}
              />
            )}
          </Tabs>
        </section>
      </article>
    );
  }
}
