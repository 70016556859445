import { H6 } from "@blueprintjs/core";
import { DateRange, DateRangeInput } from "@blueprintjs/datetime";
import * as React from "react";
import {
  Field,
  Fields,
  InjectedFormProps,
  reduxForm,
  WrappedFieldsProps,
  FormErrors
} from "redux-form";

import { fullName, IStudyUsersRolesSplit, Study } from "src/api";
import { IRenderFieldProps, UserMultiselect } from "src/components";
import { dateParser, dateRenderer } from "src/utilities";

interface IEditStudyFormProps {
  className?: string;
  form: string;
  study?: Study;
}

type EditStudyFormProps = InjectedFormProps<
  Study & IStudyUsersRolesSplit,
  IEditStudyFormProps
> &
  IEditStudyFormProps;

class EditStudyFormComponent extends React.PureComponent<EditStudyFormProps> {
  public render() {
    const { className, study } = this.props;

    if (!study) {
      return null;
    }

    return (
      <form
        className={`w-100 flex flex-column ${className}`}
        onSubmit={this.props.handleSubmit}
      >
        <section className="w-100 flex flex-row pv2">
          <div className="mr2 ph1 pv1">
            <H6>Creator</H6>
            <p>{fullName(study.creator)}</p>
          </div>

          <div className="mh2 ph1 pv1">
            <H6>Created</H6>
            <p>{dateRenderer(study.createdAt)}</p>
          </div>
          <div className="mh2 ph1 pv1">
            <div className="flex flex-row">
              <H6>Start Date</H6>
              <H6 style={{ marginLeft: "8em" }}>End Date</H6>
            </div>
            <Fields
              component={this.renderDateRange}
              names={["startDate", "endDate"]}
            />
          </div>
        </section>
        <section>
          <div>Study Users: View</div>
          <Field name="viewOnlyUsers" component={this.renderUserLookup} />
          <div>Study Users: Data Entry</div>
          <Field name="dataEntryUsers" component={this.renderUserLookup} />
          <div>Study Users: Management</div>
          <Field name="studyAdmins" component={this.renderUserLookup} />
        </section>
      </form>
    );
  }
  public renderUserLookup = (props: IRenderFieldProps) => {
    return (
      <div className="flex flex-column mb2">
        <UserMultiselect
          className="zx-input-blue-border"
          {...props}
          type={"all"}
        />
      </div>
    );
  };

  private renderDateRange = (fields: WrappedFieldsProps) => {
    const minDate = new Date();
    const maxDate = new Date(minDate);
    maxDate.setFullYear(maxDate.getFullYear() + 10);

    const startDate = fields.startDate.input.value || undefined;
    const endDate = fields.endDate.input.value || undefined;
    const error = fields.startDate.meta.error || fields.endDate.meta.error;

    const handleRangeChange = (selectedRange: DateRange) => {
      const start = selectedRange[0];
      const end = selectedRange[1];
      if (start) {
        fields.startDate.input.onChange(start);
      }
      if (end) {
        fields.endDate.input.onChange(end);
      }
    };

    const errorRenderer = error && (
      <span className="zx-orange pa1">{error}</span>
    );
    return (
      <div>
        <DateRangeInput
          allowSingleDayRange={true}
          className="zx-date-range"
          minDate={fields.startDate.input.value || minDate}
          maxDate={maxDate}
          formatDate={dateRenderer}
          parseDate={dateParser}
          shortcuts={false}
          value={[startDate, endDate]}
          onChange={handleRangeChange}
        />
        {errorRenderer}
      </div>
    );
  };
}
const validate = (
  values: Readonly<Study & IStudyUsersRolesSplit>
): FormErrors<Study & IStudyUsersRolesSplit> => {
  const errors: FormErrors<Study & IStudyUsersRolesSplit> = {}; // empty errors = form good

  if (!values.startDate) {
    errors.startDate = "Missing start date";
  }
  if (!values.endDate) {
    errors.endDate = "Missing end date";
  }

  if (!values.dataEntryUsers || values.dataEntryUsers.length === 0) {
    errors.dataEntryUsers = "Data entry users missing";
  }
  return errors;
};

export const EditStudyForm = reduxForm<
  Study & IStudyUsersRolesSplit,
  IEditStudyFormProps
>({
  validate
})(EditStudyFormComponent);
