import {
  Button,
  Classes,
  H2,
  H4,
  InputGroup,
  Intent,
  Spinner
} from "@blueprintjs/core";
import * as firebase from "firebase/app";
import "firebase/auth";
import * as QueryString from "query-string";
import * as React from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";

import { kFirebaseHandler, serverRoot } from "src/api";

import openxmedLogo from "src/resources/openxmed-logo-orange.svg";
import zenxSolutionLogo from "src/resources/zenxmed-solution-logo.svg";

enum HandlerState {
  initial,
  codeChecked,
  codeApplied,
  backendUpdated,
  serverCallInProgress,
  complete,
  error
}

type FirebaseMode = "resetPassword" | "recoverEmail" | "verifyEmail";

interface IFirebaseHandlerState {
  mode?: FirebaseMode;
  oobCode: string;
  apiKey?: string;
  continueUrl?: string;

  emailVerifyError?: string;
  email?: string;
  fromEmail?: string;

  password?: string;
  confirmPassword?: string;
  newPasswordError?: string;
  showPasswordRequirements: boolean;

  state: HandlerState;
}

class FirebaseHandlerComponent extends React.Component<
  RouteComponentProps,
  IFirebaseHandlerState
> {
  constructor(props: RouteComponentProps) {
    super(props);

    this.state = {
      oobCode: "",
      showPasswordRequirements: false,
      state: HandlerState.initial
    };
  }

  public componentDidMount() {
    // Validate and grab the url parts
    const params = QueryString.parse(this.props.location.search);
    const { mode, oobCode, apiKey, continueUrl } = params;

    const fbMode = mode as FirebaseMode;
    const oobCodeString = oobCode as string;
    const apiKeyString = apiKey as string;
    const continueUrlString = continueUrl as string | undefined;

    if (!oobCodeString || !fbMode || !apiKeyString) {
      this.props.history.replace("/");
    } else {
      this.setState({
        apiKey: apiKeyString,
        continueUrl: continueUrlString,
        mode: fbMode,
        oobCode: oobCodeString
      });
    }
  }

  public render() {
    const { mode, oobCode, continueUrl } = this.state;

    if (!oobCode) {
      return <div />;
    }
    let content;

    switch (mode) {
      case "resetPassword":
        content = this.handleResetPassword(oobCode, continueUrl);
        break;

      case "recoverEmail":
        content = this.handleRecoverEmail(oobCode);
        break;

      case "verifyEmail":
        content = this.handleVerifyEmail(oobCode, continueUrl);
        break;

      default:
        content = <div />;
    }

    return (
      <section className={`${Classes.DARK} tc mw5 mw7-ns center pa3 ph4`}>
        {this.renderHeader()}
        {content}
        {this.renderFooter()}
      </section>
    );
  }

  private renderHeader = () => {
    return (
      <div className="center mb3">
        <img src={openxmedLogo} alt="logo" />
      </div>
    );
  };

  private renderFooter = () => {
    return (
      <div className="">
        <img src={zenxSolutionLogo} alt="logo" />
      </div>
    );
  };

  private handleVerifyEmail = (oobCode: string, continueUrl?: string) => {
    const { state, emailVerifyError, mode } = this.state;

    switch (state) {
      case HandlerState.complete:
        return (
          <>
            <p>Thank you for verifying your email address.</p>
            <br />
            <p>
              As we are building a community of physicians, residents and
              medical students, we will now begin verifying the account details
              you provided when creating your account.
            </p>
            <p>
              We appreciate your patience and in the meantime, invite you learn
              more about
              <a
                style={{ color: "var(--xmed-orange)" }}
                href="https://www.openxmed.com"
              >
                {" Zenxmed "}
              </a>
              {"and the "}
              <a
                style={{ color: "var(--xmed-orange)" }}
                href="https://cms.openxmed.com"
              >
                {"Openxmed platform"}
              </a>
              .
            </p>
          </>
        );

      case HandlerState.error:
        // tslint:disable-next-line:no-console
        console.warn(emailVerifyError);
        return (
          <>
            <H4>Link expired</H4>
            <p>
              The link to verify your email has expired. Please request a new
              one.
            </p>
          </>
        );

      case HandlerState.initial:
        const url = `${serverRoot}/${kFirebaseHandler}${this.props.location.search}`;

        fetch(url)
          .then(response => {
            if (response.status !== 200) {
              response.json().then(error => {
                const { code, message } = error;
                if (
                  (mode === "verifyEmail" && message.includes("expire")) ||
                  code === "auth/invalid-action-code"
                ) {
                  // If the verify email link has expired, tell them they're good.
                  this.setState({ state: HandlerState.complete });
                } else {
                  this.setState({
                    emailVerifyError: message,
                    state: HandlerState.error
                  });
                }
              });
            } else {
              this.setState({ state: HandlerState.complete });
            }
          })
          .catch(error =>
            this.setState({
              emailVerifyError: error,
              state: HandlerState.error
            })
          );
      // Fallthrough intentional
      default:
        return this.showBusy("Verifying email address...");
    }
  };

  private handleRecoverEmail = (oobCode: string) => {
    // TODO: see https://firebase.google.com/docs/auth/custom-email-handler
    return <>Email recovery not yet supported</>;
  };

  private handleResetPassword = (oobCode: string, continueUrl?: string) => {
    const { state, newPasswordError, password, confirmPassword } = this.state;

    switch (state) {
      case HandlerState.complete:
        // Password reset has been confirmed and new password updated.

        // TODO: Display a link back to the app, or sign-in the user directly
        // if the page belongs to the same domain as the app:
        // auth.signInWithEmailAndPassword(accountEmail, newPassword);

        const onResetAcknowledged = continueUrl
          ? () => this.props.history.push(continueUrl)
          : undefined;
        const continueButton = (
          <Button intent={Intent.PRIMARY} onClick={onResetAcknowledged}>
            CONTINUE
          </Button>
        );

        return (
          <>
            <H4>Your password is now reset.</H4>
            {continueUrl && continueButton}
          </>
        );

      case HandlerState.error:
        return (
          <>
            <p>There was a problem with the password reset process.</p>
            <p className="">{newPasswordError}</p>
          </>
        );

      case HandlerState.serverCallInProgress:
        return this.showBusy("Setting new password...");

      case HandlerState.initial:
        firebase
          .auth()
          .verifyPasswordResetCode(oobCode)
          .then(email => {
            this.setState({ email, state: HandlerState.codeChecked });
          })
          .catch(error =>
            this.setState({
              newPasswordError: error.message,
              state: HandlerState.error
            })
          );

        return this.showBusy("Verifying email address...");

      case HandlerState.codeChecked:
      // Fallthrough intentional
      default:
        const passwordIsValid = this.passwordValid(password);
        const formIsValid =
          password !== undefined &&
          passwordIsValid &&
          confirmPassword === password;

        const passwordOnChange = (event: React.ChangeEvent<HTMLInputElement>) =>
          this.handleTextChange(event);

        const emailPart = this.state.email && (
          <p>
            for <strong>{this.state.email}</strong>
          </p>
        );

        const passwordRequirements = !passwordIsValid && (
          <main className="pa2 tl">
            <p className="f5">Your password must contain:</p>
            <p className="f6 lh-copy measure-narrow">
              <li>8 characters minumum</li>
              <li>at least 1 number</li>
              <li>1 uppercase letter</li>
            </p>
          </main>
        );
        const passwordsMatch = passwordIsValid && !formIsValid && (
          <p className="f6 red tl">Passwords do not match</p>
        );
        const passwordError = this.state.newPasswordError ? (
          <p className="">{this.state.newPasswordError}</p>
        ) : (
          <p>&nbsp;</p>
        );

        return (
          <>
            <H2>Reset Password</H2>
            {emailPart}
            <br />
            <div>
              <InputGroup
                className="dark ma2"
                onChange={passwordOnChange}
                id="password"
                placeholder="New Password"
                type="password"
                required={true}
              />
              <InputGroup
                className="dark ma2"
                onChange={passwordOnChange}
                id="passwordConfirm"
                placeholder="Confirm your password"
                type="password"
                required={true}
              />
              {passwordRequirements}
              {passwordsMatch}
            </div>
            {passwordError}
            <Button
              intent={Intent.PRIMARY}
              disabled={!formIsValid}
              onClick={this.sendNewResetEmail}
            >
              Update Password
            </Button>
          </>
        );
    }
  };

  private showBusy = (statusText: string) => {
    return (
      <>
        <Spinner intent={Intent.PRIMARY} />
        <p className="mt3">{statusText}</p>
      </>
    );
  };

  private handleTextChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    switch (event.target.id) {
      case "password":
        this.setState({
          password: event.target.value
        });
        break;

      case "passwordConfirm":
        this.setState({ confirmPassword: event.target.value });
        break;

      default:
    }
  };

  private passwordValid = (value?: string) => {
    if (!value) {
      return false;
    }
    if (value.length < 8 || !/[A-Z]/.test(value) || !/[0-9]/.test(value)) {
      return false;
    }
    return true;
  };

  private sendNewResetEmail = () => {
    const { email, password, confirmPassword, oobCode } = this.state;
    let errorString;

    if (!email) {
      errorString = "No email address provided for reset.";
    }

    if (password !== confirmPassword) {
      errorString = "The two passwords don't match. Please try again.";
    }

    if (errorString) {
      this.setState({ newPasswordError: errorString });
    } else {
      // Go do the password reset
      if (password) {
        this.setState({ state: HandlerState.serverCallInProgress });
        firebase
          .auth()
          .confirmPasswordReset(oobCode, password)
          .then(response => {
            this.setState({ state: HandlerState.complete });
          })
          .catch(error =>
            this.setState({
              newPasswordError: error.message,
              state: HandlerState.error
            })
          );
      }
    }
  };
}

export const FirebaseHandler = withRouter(FirebaseHandlerComponent);
