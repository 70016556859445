import { Dialog, Classes, Button, Intent, Label } from "@blueprintjs/core";
import { TimePrecision, DateInput } from "@blueprintjs/datetime";
import { IconNames } from "@blueprintjs/icons";
import * as React from "react";
import { connect } from "react-redux";

import { Patient, fullName, User, Study, Visit } from "src/api";
import { IStoreState } from "src/store";
import { Dictionary } from "lodash";
import { dateRenderer, dateParser } from "src/utilities";
import { createStudyVisit } from "src/actions";

interface INewVisitDialogProps {
  onClose: (visit?: Visit) => void;
  patient?: Patient;
  study: Study;
}
interface INewVisitDialogInjectedProps {
  error?: Error;
  loggedInUser?: User;
  studies: Dictionary<Study>;
}
interface INewVisitDialogDispatchProps {
  createStudyVisit: typeof createStudyVisit.request;
}

interface INewVisitDialogState {
  busy: boolean;
  visitDateTime?: Date;
  createdVisit?: Visit;
}

type NewVisitDialogProps = INewVisitDialogProps &
  INewVisitDialogInjectedProps &
  INewVisitDialogDispatchProps;

class NewVisitDialogComponent extends React.PureComponent<
  NewVisitDialogProps,
  INewVisitDialogState
> {
  private dateField = React.createRef<DateInput>();

  constructor(props: NewVisitDialogProps) {
    super(props);
    this.state = {
      busy: false
    };
  }

  public componentDidUpdate() {
    const { createdVisit } = this.state;
    const { study } = this.props;

    if (createdVisit) {
      if ((study.visits || []).find(v => v.id === createdVisit.id)) {
        this.closeDialog();
      }
    }
  }

  public render() {
    const { patient } = this.props;

    return (
      <Dialog
        autoFocus={false}
        enforceFocus={false}
        icon={IconNames.CALENDAR}
        title={`New visit for ${fullName(patient)}`}
        isCloseButtonShown={false}
        isOpen={patient !== undefined}
        onOpened={this.setFocus}
        onClose={this.closeDialog}
      >
        {this.renderBody()}
        {this.renderFooter()}
      </Dialog>
    );
  }

  private renderBody = () => {
    const { patient, study } = this.props;
    const handleDateSelect = (selectedDate: Date, isUserChange: boolean) => {
      if (isUserChange) {
        this.setState({ visitDateTime: selectedDate });
      }
    };
    const now = new Date();

    const visitsForPatient =
      patient && study.visits
        ? study.visits.filter(v => v.patientId === patient.id)
        : [];

    const existingVisitDates = visitsForPatient.map(v => v.visitDate);

    return (
      <section className={`${Classes.DIALOG_BODY} overflow-y-auto`}>
        <section className="pv2 flex flex-row w-100">
          <Label>
            Visit Date
            <DateInput
              dayPickerProps={{ disabledDays: existingVisitDates }}
              formatDate={dateRenderer}
              minDate={now}
              maxDate={study.endDate}
              onChange={handleDateSelect}
              parseDate={dateParser}
              placeholder=""
              timePrecision={TimePrecision.MINUTE}
            />
          </Label>
        </section>
      </section>
    );
  };

  private renderFooter = () => {
    const { patient, error, study } = this.props;
    const { busy, visitDateTime } = this.state;

    const errorRenderer = (e: Error) => (
      <span className="mh2 orange">{e.message}</span>
    );
    const handleNewVisit = () => {
      if (patient && study && visitDateTime) {
        const visit = new Visit();
        visit.patientId = patient.id;
        visit.studyId = study.id;
        visit.visitDate = visitDateTime;
        visit.countryCode = patient.countryCode;
        this.props.createStudyVisit(visit);
        this.setState({ createdVisit: visit, busy: true });
      }
    };

    return (
      <div className={`${Classes.DIALOG_FOOTER}`}>
        {error && errorRenderer(error)}
        <div className="flex flex-row justify-between pt2">
          <Button id="cancel" text="Cancel" onClick={this.closeDialog} />
          <Button
            disabled={visitDateTime === undefined}
            intent={Intent.SUCCESS}
            text="New Visit"
            loading={busy}
            onClick={handleNewVisit}
          />
        </div>
      </div>
    );
  };

  private setFocus = () => {
    setTimeout(() => {
      if (this.dateField.current) {
        // TODO: Set the focus
      }
    }, 100);
  };

  private closeDialog = async () => {
    const { createdVisit } = this.state;
    this.props.onClose(createdVisit);
    this.setState({
      busy: false,
      createdVisit: undefined,
      visitDateTime: undefined
    });
  };
}

const mapStateToProps = ({ studiesStore, userStore }: IStoreState) => {
  return {
    error: studiesStore.error,
    loggedInUser: userStore.loggedInUser,
    studies: studiesStore.studies
  };
};

export const NewVisitDialog = connect(mapStateToProps, {
  createStudyVisit: createStudyVisit.request
})(NewVisitDialogComponent);
