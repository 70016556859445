import { Transform, Type } from "class-transformer";
import { omit } from "lodash";

import { BaseModel } from "./object-base";
import { User } from "./user";

export const stripFieldsForVisit = (visit: Visit) => omit(visit, "creator");

export class Visit extends BaseModel {
  // Compound primary key
  public studyId = "";
  public countryCode = "";

  @Type(() => User)
  public creator?: User;

  @Transform(s => s || "")
  public patientId = "";

  public detailsJson?: {};

  @Type(() => Date)
  public visitDate: Date = new Date();
}
