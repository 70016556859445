import { Dialog, Intent, Spinner } from "@blueprintjs/core";
import { replace } from "connected-react-router";
import * as React from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { connect } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router-dom";

import { IRegistrationData, registerUser, resetStore } from "src/actions";
import {
  kReCaptchaHandler,
  kReCaptchaKey,
  postRequestInit,
  serverRoot,
  User
} from "src/api";
import { IStoreState } from "src/store";
import { RegisterForm, ZenxTerms } from "./sign-up";

import logo from "src/resources/openxmed-logo-orange.svg";

interface IRegisterProps extends RouteComponentProps {
  clearError: typeof resetStore;
  error?: Error;
  replace: typeof replace;
  registerUser: typeof registerUser.request;
  user?: User;
}

interface IRegisterState {
  error?: Error;
  isBusy: boolean;
  recaptchaToken?: string;
  registrationValues?: IRegistrationData;
  showTerms: boolean;
  seenAllTerms: boolean;
}

class RegisterComponent extends React.PureComponent<
  IRegisterProps,
  IRegisterState
> {
  private recaptchaRef = React.createRef<ReCAPTCHA>();
  constructor(props: IRegisterProps) {
    super(props);
    this.state = {
      isBusy: false,
      seenAllTerms: false,
      showTerms: true
    };
  }

  public render() {
    return (
      <article className="flex-ns justify-between vh-100 pa3">
        <section className="vh-100-ns">
          <img
            className="ma1 mb3"
            src={logo}
            alt="logo"
            style={{ height: 30 }}
          />
        </section>
        {this.renderMiddleSection()}
        <section />
      </article>
    );
  }

  public componentDidUpdate() {
    const { error, recaptchaToken, registrationValues, isBusy } = this.state;
    const { user } = this.props;
    const propsError = this.props.error;

    if (propsError) {
      this.setState({ error: propsError });
      this.props.clearError();
    }

    if (error || propsError) {
      this.setState({ isBusy: false });
    } else {
      // Handle logic in no error case
      if (!user) {
        if (recaptchaToken && registrationValues && !isBusy) {
          // We should start
          this.verifyRecaptcha();
        }
      }
    }
  }

  private renderMiddleSection = () => {
    const { error, isBusy, showTerms, registrationValues } = this.state;

    const body = isBusy ? (
      <Spinner
        className="pa4"
        intent={Intent.PRIMARY}
        size={Spinner.SIZE_LARGE}
      />
    ) : (
      <>
        <Dialog
          style={{ width: "75%" }}
          title="TERMS"
          isOpen={showTerms}
          isCloseButtonShown={false}
          canEscapeKeyClose={false}
          canOutsideClickClose={false}
        >
          <ZenxTerms
            acceptedTerms={this.acceptedTerms}
            refusedTerms={this.goBack}
          />
        </Dialog>
        {error && <p className="red">{error.message}</p>}
        <RegisterForm
          className="mb6"
          onCancel={this.goBack}
          onSubmit={this.formSubmit}
          initialValues={registrationValues}
        />
        <ReCAPTCHA
          ref={this.recaptchaRef}
          size="invisible"
          sitekey={kReCaptchaKey}
          onChange={this.recaptchaChange}
          onExpired={this.recaptchaExpiry}
        />
      </>
    );
    return <section className="pt4">{body}</section>;
  };

  private recaptchaChange = (token: string | null) => {
    this.setState({ recaptchaToken: token ? token : undefined });
  };

  private recaptchaExpiry = () => this.setState({ recaptchaToken: undefined });

  private acceptedTerms = () =>
    this.setState({ seenAllTerms: true, showTerms: false });

  private goBack = () => this.props.replace("/");

  private formSubmit = (values: IRegistrationData) => {
    if (
      this.recaptchaRef &&
      this.recaptchaRef.current &&
      !this.state.recaptchaToken
    ) {
      this.recaptchaRef.current.execute();
    }
    this.setState({ registrationValues: values, error: undefined });
  };

  private verifyRecaptcha = async () => {
    const { recaptchaToken, registrationValues } = this.state;
    if (registrationValues) {
      this.setState({ isBusy: true });
      const config = postRequestInit(
        JSON.stringify({ recaptchaResponse: recaptchaToken })
      );
      try {
        const response = await fetch(
          `${serverRoot}/${kReCaptchaHandler}?type=invisible`,
          config
        );
        if (response.ok) {
          // Create the new firebase user and update our account, and log in (EPIC)
          this.props.registerUser(registrationValues);
        } else {
          this.setState({
            error: new Error(
              `Malformed Recaptcha request; code ${response.status}`
            )
          });
        }
      } catch (error) {
        this.setState({ error, isBusy: false });
      }
    }
  };
}

const mapStateToProps = ({ userStore }: IStoreState) => ({
  error: userStore.error,
  user: userStore.loggedInUser
});

export const Register = withRouter(
  connect(mapStateToProps, {
    clearError: resetStore,
    registerUser: registerUser.request,
    replace
  })(RegisterComponent)
);
