import * as React from "react";

import { AlgoNode, CommentKind } from "src/api";
import { IOpenAlgorithm } from "src/store";
import { NodeComments } from "../consumption";

export interface INodeEditNotesPanelProps {
  node?: AlgoNode;
  openAlgorithm: IOpenAlgorithm;
}

interface INodeEditNotesPanelState {
  showNotes: boolean;
  showComments: boolean;
}

export class NodeEditNotesPanel extends React.PureComponent<
  INodeEditNotesPanelProps,
  INodeEditNotesPanelState
> {
  constructor(props: INodeEditNotesPanelProps) {
    super(props);
    this.state = {
      showComments: false,
      showNotes: true
    };
  }

  public render() {
    const { node, openAlgorithm } = this.props;
    const { showComments, showNotes } = this.state;

    let filter;
    if (showNotes && !showComments) {
      filter = CommentKind.Note;
    } else if (showComments && !showNotes) {
      filter = CommentKind.Comment;
    }

    return (
      <section className="flex flex-column flex-auto w-100">
        <NodeComments
          commentKindFilter={filter}
          node={node}
          width={window.innerWidth}
          openAlgorithm={openAlgorithm}
        />
      </section>
    );
  }
}
