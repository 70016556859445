import { cloneDeep, Dictionary } from "lodash";
import {
  createBackendComment,
  createBackendNode,
  createBackendPath,
  deleteBackendComment,
  deleteBackendNode,
  deleteBackendPath,
  // ReduxAction,
  updateBackendAlgorithm,
  updateBackendComment,
  updateBackendNode,
  updateBackendPath
} from "src/actions";
import {
  AlgoNode,
  AlgoNodePath,
  Algorithm,
  Comment,
  CommentRefType,
  dictionaryFromPayload,
  IOpenxmedApiFailureResponse,
  IOpenxmedApiTaggedSuccessResponse,
  IXmedObjectInfo,
  objectFromPayload,
  removeFromArray,
  updateArray,
  User
} from "src/api";
import { IAlgoStore, IOpenAlgorithm } from "src/store";
import { ActionType } from "typesafe-actions";
import * as uuid from "uuid";

import { insertOrUpdateComment } from "./commenting-handlers";
import { updatedOpenAlgoArray } from "./openalgo-updater";

export enum QueueEventType {
  create = "create",
  update = "update",
  delete = "delete"
}

export enum EventObjectType {
  algo = "algo",
  node = "node",
  path = "path",
  comment = "comment"
}

export enum ElementState {
  new = "n",
  queued = "q",
  running = "r",
  complete = "c"
}

export interface IEventQueueElement {
  changedItem: Algorithm | AlgoNode | AlgoNodePath | Comment;
  error?: Error;
  id: string;
  itemType: EventObjectType;
  state: ElementState;
  type: QueueEventType;
}

export const createUpdateAlgoEvent = (algo: Algorithm): IEventQueueElement => {
  return {
    changedItem: algo,
    id: uuid.v4(),
    itemType: EventObjectType.algo,
    state: ElementState.new,
    type: QueueEventType.update
  };
};

export const createCreateNodeEvent = (node: AlgoNode): IEventQueueElement => {
  return {
    changedItem: node,
    id: uuid.v4(),
    itemType: EventObjectType.node,
    state: ElementState.new,
    type: QueueEventType.create
  };
};

export const createNodeUpdateEvent = (node: AlgoNode): IEventQueueElement => {
  return {
    changedItem: node,
    id: uuid.v4(),
    itemType: EventObjectType.node,
    state: ElementState.new,
    type: QueueEventType.update
  };
};

export const createNodeDeleteEvent = (node: AlgoNode): IEventQueueElement => {
  return {
    changedItem: node,
    id: uuid.v4(),
    itemType: EventObjectType.node,
    state: ElementState.new,
    type: QueueEventType.delete
  };
};

export const createCreatePathEvent = (
  link: AlgoNodePath
): IEventQueueElement => {
  return {
    changedItem: link,
    id: uuid.v4(),
    itemType: EventObjectType.path,
    state: ElementState.new,
    type: QueueEventType.create
  };
};

export const updatePathEvent = (link: AlgoNodePath): IEventQueueElement => {
  return {
    changedItem: link,
    id: uuid.v4(),
    itemType: EventObjectType.path,
    state: ElementState.new,
    type: QueueEventType.update
  };
};

export const createDeletePathEvent = (
  path: AlgoNodePath
): IEventQueueElement => {
  return {
    changedItem: path,
    id: uuid.v4(),
    itemType: EventObjectType.path,
    state: ElementState.new,
    type: QueueEventType.delete
  };
};

export const createCommentEvent = (comment: Comment): IEventQueueElement => {
  return {
    changedItem: comment,
    id: uuid.v4(),
    itemType: EventObjectType.comment,
    state: ElementState.new,
    type: QueueEventType.create
  };
};

export const updateCommentEvent = (comment: Comment): IEventQueueElement => {
  return {
    changedItem: comment,
    id: uuid.v4(),
    itemType: EventObjectType.comment,
    state: ElementState.new,
    type: QueueEventType.update
  };
};

export const removeCommentEvent = (comment: Comment): IEventQueueElement => {
  return {
    changedItem: comment,
    id: uuid.v4(),
    itemType: EventObjectType.comment,
    state: ElementState.new,
    type: QueueEventType.delete
  };
};

export const appendOrUpdateEvent = (
  event: IEventQueueElement,
  queue: IEventQueueElement[]
) => {
  const queueIndex = queue.findIndex(
    evt =>
      evt.type === event.type &&
      evt.changedItem.id === event.changedItem.id &&
      evt.state === ElementState.new
  );
  if (queueIndex === -1) {
    queue.push(event);
  } else {
    queue.splice(queueIndex, 1, event);
  }
  return queue;
};

export const findLayoutEvent = (
  type: QueueEventType,
  itemId: string,
  queue: IEventQueueElement[]
): IEventQueueElement | undefined => {
  for (const event of queue) {
    if (event.type === type && event.changedItem.id === itemId) {
      return event;
    }
  }
  return undefined;
};

export const removeLayoutEvent = (
  event: IEventQueueElement,
  fromQueue: IEventQueueElement[]
) => {
  const index = fromQueue.lastIndexOf(event);
  if (index !== -1) {
    fromQueue.splice(index, 1);
  }
};

export const updatedEventQueue = (
  queue: IEventQueueElement[],
  additions?: IEventQueueElement[]
) => {
  if (queue.find(e => e.error !== undefined)) {
    // tslint:disable-next-line:no-console
    console.error("Queue is in error state, not updating further");
    return queue;
  }

  if (additions) {
    additions.forEach(e => (queue = appendOrUpdateEvent(e, queue)));
  }
  return queue;
};

export const updatedOpenAlgoArrayClearingQueue = (
  algoId: string,
  algorithms: Dictionary<Algorithm>,
  openAlgos: IOpenAlgorithm[],
  loggedInUser: User
): IOpenAlgorithm[] => {
  const currentOpenAlgo = openAlgos.find(v => v.algorithm.id === algoId);
  if (currentOpenAlgo) {
    const oAs = openAlgos.map(oa =>
      oa.algorithm.id === algoId
        ? { ...oa, algorithm: algorithms[algoId], eventQueue: [] }
        : oa
    );

    return updatedOpenAlgoArray({
      createOnly: false,
      loggedInUser,
      openAlgos: oAs,
      updatedAlgo: currentOpenAlgo.algorithm
    });
  }
  return openAlgos;
};

/**
 * Grabs all the changes in the eventQueue and returns a dictionary of
 * all the affected nodes
 */
export const applyQueueChanges = (
  existing: Dictionary<AlgoNode>,
  queue: IEventQueueElement[]
): Dictionary<AlgoNode> => {
  const nodes = cloneDeep(existing);
  let queueError: Error;

  queue.forEach(event => {
    const item = event.changedItem;
    if (event.error) {
      queueError = event.error;
    }

    if (item && !queueError) {
      switch (event.type) {
        case QueueEventType.delete:
          if (event.itemType === EventObjectType.node) {
            // null out any references from parent objects
            const node = nodes[item.id];
            if (node) {
              node.backwardLinks().forEach(pb => {
                const parent = nodes[pb.parentId];
                if (parent) {
                  const affectedPaths = parent
                  .forwardLinks()
                  .filter(pf => pf.childId === item.id);
                  affectedPaths.forEach(p => {
                    p.childId = undefined;
                    parent.paths = updateArray(parent.paths, p);
                  });
                }
              });
            }
            delete nodes[item.id];
          } else if (event.itemType === EventObjectType.path) {
            const path = item as AlgoNodePath;
            const parentNode = nodes[path.parentId];
            if (parentNode) {
              parentNode.removePath(path.id);
            }
            if (path.childId) {
              const childNode = nodes[path.childId];
              if (childNode) {
                childNode.removePath(path.id);
              }
            }
          }
          break;

        case QueueEventType.create:
          if (event.itemType === EventObjectType.node) {
            nodes[item.id] = item as AlgoNode;
          } else if (event.itemType === EventObjectType.path) {
            const path = item as AlgoNodePath;

            const parentNode = nodes[path.parentId];
            if (parentNode) {
              parentNode.addPath(path);
            }
            if (path.childId) {
              const childNode = nodes[path.childId];
              if (childNode) {
                childNode.addPath(path);
              }
            }
          } else if (event.itemType === EventObjectType.comment) {
            const comment = item as Comment;
            let node: AlgoNode | undefined;

            if (comment.linkedRefType === CommentRefType.Node) {
              node = nodes[comment.linkedRefId];
              node.comments = updateArray(node.comments, comment);
            } else if (comment.linkedRefType === CommentRefType.Comment) {
              Object.keys(nodes).forEach(k => {
                if (!node) {
                  const n = nodes[k];
                  if (n) {
                    n.comments.forEach(c => {
                      if (c.id === comment.linkedRefId) {
                        c.comments = updateArray(c.comments || [], comment);
                        node = n;
                      }
                    });
                  }
                }
              });
            }

            if (node) {
              nodes[node.id] = node;
            }
          }
          break;

        case QueueEventType.update:
          if (event.itemType === EventObjectType.node) {
            const existingNode = nodes[item.id];
            if (existingNode) {
              const copy = cloneDeep(existingNode);
              Object.assign(copy, item);
              nodes[item.id] = copy;
            }
          } else if (event.itemType === EventObjectType.path) {
            const path = item as AlgoNodePath;
            const parentNode = nodes[path.parentId];

            if (parentNode) {
              let i = 0;
              for (const p of parentNode.paths) {
                if (p.id === path.id) {
                  const oldChildId = p.childId;
                  parentNode.paths.splice(i, 1, path);

                  if (oldChildId) {
                    const oldChild = nodes[oldChildId];
                    if (oldChild) {
                      oldChild.removePath(path.id);
                    }
                  }
                  if (path.childId) {
                    const newChild = nodes[path.childId];
                    if (newChild) {
                      newChild.addPath(path);
                    }
                  }
                  break;
                }
                i++;
              }
            }
          }
          break;

        default:
      }
    }
  });
  return nodes;
};
const actionForEventQueueElement = (e: IEventQueueElement) => {
  switch (e.type) {
    case QueueEventType.delete:
      if (e.itemType === EventObjectType.node) {
        return deleteBackendNode.request({
          node: e.changedItem as AlgoNode,
          tag: e.id
        });
      } else if (e.itemType === EventObjectType.path) {
        return deleteBackendPath.request({
          path: e.changedItem as AlgoNodePath,
          tag: e.id
        });
      } else if (e.itemType === EventObjectType.comment) {
        if (e.itemType === EventObjectType.comment) {
          return deleteBackendComment.request({
            comment: e.changedItem as Comment,
            tag: e.id
          });
        }
      }
      break;

    case QueueEventType.create:
      if (e.itemType === EventObjectType.node) {
        return createBackendNode.request({
          node: e.changedItem as AlgoNode,
          tag: e.id
        });
      } else if (e.itemType === EventObjectType.path) {
        return createBackendPath.request({
          path: e.changedItem as AlgoNodePath,
          tag: e.id
        });
      } else if (e.itemType === EventObjectType.comment) {
        return createBackendComment.request({
          comment: e.changedItem as Comment,
          tag: e.id
        });
      }
      break;

    case QueueEventType.update:
      if (e.itemType === EventObjectType.node) {
        return updateBackendNode.request({
          node: e.changedItem as AlgoNode,
          tag: e.id
        });
      }
      if (e.itemType === EventObjectType.path) {
        return updateBackendPath.request({
          path: e.changedItem as AlgoNodePath,
          tag: e.id
        });
      }
      if (e.itemType === EventObjectType.algo) {
        return updateBackendAlgorithm.request({
          algo: e.changedItem as Algorithm,
          tag: e.id
        });
      }
      if (e.itemType === EventObjectType.comment) {
        return updateBackendComment.request({
          comment: e.changedItem as Comment,
          tag: e.id
        });
      }
  }
  return undefined;
};
// TODO: This should be an actiontype of ReduxAction (or something...) broken in TS 3.4 https://github.com/Microsoft/TypeScript/issues/30188
export const actionsForEventQueue = (
  eventQueue: IEventQueueElement[]
): Array<ActionType<any>> => {
  const operations: Array<ActionType<any>> = [];
  eventQueue
    .filter(i => i.state === ElementState.queued)
    .forEach(e => {
      const action = actionForEventQueueElement(e);
      if (action) {
        operations.push(action);
        e.state = ElementState.running;
      }
    });
  return operations;
};

/**
 * Queues up the next one to be processed (in the epic)
 * @param algoId Algorithm ID
 * @param openAlgos Arrray to update and pass back to redux
 */
export const updatedOpenAlgoArrayQueuingNext = (
  algoId: string,
  openAlgos: IOpenAlgorithm[]
): IOpenAlgorithm[] =>
  openAlgos.map(openAlgo => {
    if (
      openAlgo.algorithm.id === algoId &&
      !openAlgo.eventQueue.find(e => e.state === ElementState.queued)
    ) {
      const nextIndex = openAlgo.eventQueue.findIndex(
        e => e.state === ElementState.new
      );
      if (nextIndex > -1) {
        const eventQueue = openAlgo.eventQueue.map((e, i) =>
          i !== nextIndex ? e : { ...e, state: ElementState.queued }
        );

        return {
          ...openAlgo,
          eventQueue
        };
      }
    }
    return openAlgo;
  });

/**
 * Processes a server update of failure type
 * @param payload Response payload
 * @param openAlgos Current open algos
 */
export const updatedOpenAlgoArrayProcessingFailure = (
  payload: IOpenxmedApiFailureResponse,
  openAlgos: IOpenAlgorithm[]
) =>
  openAlgos.map(oa => {
    const index = oa.eventQueue.findIndex(e => payload.tag === e.id);
    if (index > -1) {
      return {
        ...oa,
        eventQueue: oa.eventQueue.map((event, i) => {
          if (i === index) {
            if (
              event.type === QueueEventType.delete &&
              payload.error.name === "404" // Deleted and not found is ok.
            ) {
              return {
                ...event,
                state: ElementState.complete
              };
            } else {
              return {
                ...event,
                error: payload.error,
                state: ElementState.complete
              };
            }
          }
          return event;
        })
      };
    }
    return oa;
  });

/**
 * Updates any open algorithms with the changes and removes from the queue
 * @param payload API payload
 * @param openAlgos array of open algos
 */
const updatedOpenAlgoArrayProcessingSuccess = (
  payload: IOpenxmedApiTaggedSuccessResponse,
  openAlgos: IOpenAlgorithm[]
) =>
  openAlgos.map(oa => {
    const index = oa.eventQueue.findIndex(e => payload.tag === e.id);
    if (index > -1) {
      return {
        ...oa,
        eventQueue: oa.eventQueue.filter(e => payload.tag !== e.id)
      };
    }
    return oa;
  });

export const updateAlgoWithNode = (node: AlgoNode, store: IAlgoStore) => {
  const found = store.allAlgorithms[node.algorithmId];
  if (found) {
    const algo = cloneDeep(found);
    algo.nodes = updateArray(algo.nodes, node);
    node
      .forwardLinks()
      .filter(fl => fl.childId)
      .forEach(fl => {
        const childNode = algo.nodes.find(n => n.id === fl.childId);
        if (childNode) {
          const nodeClone = cloneDeep(childNode);
          childNode.addPath(fl);
          algo.nodes = updateArray(algo.nodes, nodeClone);
        }
      });
    node.backwardLinks().forEach(fl => {
      const parentNode = algo.nodes.find(n => n.id === fl.parentId);
      if (parentNode) {
        const nodeClone = cloneDeep(parentNode);
        parentNode.addPath(fl);
        algo.nodes = updateArray(algo.nodes, nodeClone);
      }
    });
    return algo;
  }
  return undefined;
};

const processDelete = (
  guid: string,
  store: IAlgoStore,
  event: IEventQueueElement,
  loggedInUser: User,
  openAlgo?: IOpenAlgorithm
): IAlgoStore => {
  if (!openAlgo) {
    return store;
  }

  const {
    algoNodes,
    algorithm: { id }
  } = openAlgo;
  const updatedAlgo = cloneDeep(store.allAlgorithms[id]);
  const updatedNodeDict = {};

  switch (event.itemType) {
    case EventObjectType.node:
      const node = algoNodes[guid];
      if (node) {
        // Delete any contained children from local store
        node.options(algoNodes).forEach(nt => {
          if (nt.node) {
            // Unlink any child targets
            const optionChildren = nt.node.unlink(algoNodes);
            optionChildren.forEach(c => {
              updatedNodeDict[c.id] = c;
            });

            // Remove from selection
            delete openAlgo.editingState.selectedNodeIds[nt.node.id];

            // Delete node
            updatedAlgo.nodes = removeFromArray(updatedAlgo.nodes, nt.node.id);
          }
        });
      }

      // Remove from selection
      delete openAlgo.editingState.selectedNodeIds[guid];
      // Delete node
      updatedAlgo.nodes = removeFromArray(updatedAlgo.nodes, guid);
      break;

    case EventObjectType.path:
      break;

    case EventObjectType.comment:
      // TODO: Implement this
      break;
  }
  return {
    ...store,
    allAlgorithms: { ...store.allAlgorithms, [updatedAlgo.id]: updatedAlgo },
    openAlgorithms: updatedOpenAlgoArray({
      createOnly: false,
      loggedInUser,
      openAlgos: store.openAlgorithms,
      updatedAlgo,
      updatedNodes: updatedNodeDict
    })
  };
};

const processElement = (
  object: IXmedObjectInfo,
  store: IAlgoStore,
  event: IEventQueueElement,
  loggedInUser: User,
  openAlgo?: IOpenAlgorithm
): IAlgoStore => {
  // tslint:disable-next-line:no-string-literal
  const refId = object.attributes && object.attributes["refId"];
  if (refId && object.id !== refId) {
    // tslint:disable-next-line:no-console
    console.error("Mismatch of ID!");
  }

  switch (object.type) {
    case "uuids":
      // Something was deleted
      return processDelete(object.id, store, event, loggedInUser, openAlgo);

    case "comments": {
      const comment = objectFromPayload(object, Comment);
      if (comment) {
        return insertOrUpdateComment(comment, store, loggedInUser);
      }
      break;
    }

    case "algorithms": {
      const algos = dictionaryFromPayload([object], Algorithm);

      let updatedOpenAlgos = store.openAlgorithms;
      Object.values(algos).forEach(
        algo =>
          (updatedOpenAlgos = updatedOpenAlgoArray({
            createOnly: false,
            loggedInUser,
            openAlgos: store.openAlgorithms,
            updatedAlgo: algo
          }))
      );

      return {
        ...store,
        allAlgorithms: { ...store.allAlgorithms, ...algos },
        openAlgorithms: updatedOpenAlgos
      };
    }

    case "nodes": {
      const node = objectFromPayload(object, AlgoNode);
      if (node) {
        const algo = updateAlgoWithNode(node, store);
        if (algo) {
          return {
            ...store,
            allAlgorithms: { ...store.allAlgorithms, [algo.id]: algo },
            openAlgorithms: updatedOpenAlgoArray({
              createOnly: false,
              loggedInUser,
              openAlgos: store.openAlgorithms,
              updatedAlgo: algo,
              updatedNodes: { [node.id]: node }
            })
          };
        }
      }
      break;
    }

    case "paths":
      // We _ONLY_ look in open algos for path updates...
      const path = objectFromPayload(object, AlgoNodePath);
      if (path) {
        const updatedAlgos: Dictionary<Algorithm> = {};

        store.openAlgorithms.forEach(oa => {
          const nodeIndex = oa.algorithm.nodes.findIndex(
            n => n.paths.find(p => p.id === path.id) !== undefined
          );
          if (nodeIndex === -1) {
            return;
          }

          const node = cloneDeep(oa.algorithm.nodes[nodeIndex]);
          node.addPath(path);
          const updatedAlgo = updateAlgoWithNode(node, store);
          if (updatedAlgo) {
            updatedAlgos[oa.algorithm.id] = updatedAlgo;
          }
        });

        let updatedOpenAlgos = store.openAlgorithms;
        Object.values(updatedAlgos).forEach(
          algo =>
            (updatedOpenAlgos = updatedOpenAlgoArray({
              createOnly: false,
              loggedInUser,
              openAlgos: store.openAlgorithms,
              updatedAlgo: algo
            }))
        );

        return {
          ...store,
          allAlgorithms: { ...store.allAlgorithms, ...updatedAlgos },
          openAlgorithms: updatedOpenAlgos
        };
      }
  }
  return store;
};

/**
 *
 * @param payload A server-returned payload
 * @param store The current algoStore
 * @returns Updated store state
 */
export const algoStoreUpdatedWithPayload = (
  payload: IOpenxmedApiTaggedSuccessResponse,
  store: IAlgoStore,
  loggedInUser: User
): IAlgoStore => {
  const {
    apiResponse: { data }
  } = payload;

  const toProcess: IXmedObjectInfo[] =
    data.constructor === Array ? data : [data];
  let queueElement: IEventQueueElement | undefined;
  const openAlgo = store.openAlgorithms.find(oa => {
    queueElement = oa.eventQueue.find(e => e.id === payload.tag);
    return queueElement ? true : false;
  });

  toProcess.forEach(
    el =>
      queueElement &&
      (store = processElement(el, store, queueElement, loggedInUser, openAlgo))
  );

  return {
    ...store,
    openAlgorithms: updatedOpenAlgoArrayProcessingSuccess(
      payload,
      store.openAlgorithms
    )
  };
};
