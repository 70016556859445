import { Classes, Label, HTMLSelect } from "@blueprintjs/core";
import { DateInput } from "@blueprintjs/datetime";
import * as React from "react";

import { Patient, kGenderOptions, Gender } from "src/api";
import { dateParser, dateRenderer } from "src/utilities";

export interface INewPatientFormProps {
  disabled?: boolean;
  patient: Patient;
  onChange: (updated: Patient) => void;
}

export class NewPatientForm extends React.PureComponent<INewPatientFormProps> {
  public render() {
    const { disabled, patient } = this.props;

    if (!patient) {
      return null;
    }

    return (
      <section>
        <section className="w-100 flex">
          <Label className="pa2">
            First Name
            <input
              className={Classes.INPUT}
              disabled={disabled}
              onChange={this.updateFirstName}
              value={patient.firstName}
            />
          </Label>
          <Label className="pa2">
            Last Name
            <input
              className={Classes.INPUT}
              disabled={disabled}
              onChange={this.updateLastName}
              value={patient.lastName}
            />
          </Label>
        </section>
        <section className="w-100 flex pb1 mb1">
          <Label className="pa2">
            Date of Birth
            <DateInput
              disabled={disabled}
              formatDate={dateRenderer}
              initialMonth={new Date(2000, 4)}
              maxDate={new Date()}
              onChange={this.updateDob}
              parseDate={dateParser}
              value={patient.dateOfBirth}
            />
          </Label>
          <Label className="pa2">
            Sex
            <HTMLSelect
              disabled={disabled}
              options={kGenderOptions}
              onChange={this.updateGender}
              value={patient.gender}
            />
          </Label>
        </section>
      </section>
    );
  }

  private updateFirstName = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.props.onChange({ ...this.props.patient, firstName: e.target.value });
  };
  private updateLastName = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.props.onChange({ ...this.props.patient, lastName: e.target.value });
  };
  private updateDob = (selectedDate: Date) => {
    this.props.onChange({ ...this.props.patient, dateOfBirth: selectedDate });
  };
  private updateGender = (e: React.ChangeEvent<HTMLSelectElement>) => {
    this.props.onChange({
      ...this.props.patient,
      gender: e.target.value as Gender
    });
  };
}
