import { Classes, Tab, Tabs } from "@blueprintjs/core";
import { cloneDeep, sortBy, toPairs } from "lodash";
import * as React from "react";
import { connect } from "react-redux";

import {
  createNode,
  createPath,
  removeNodes,
  reorderPaths,
  setEditState,
  updateNodes,
  updateNodeText,
  removePath
} from "src/actions";
import { AlgoNode, AlgoNodeType } from "src/api";
import { EditTabType, IOpenAlgorithm } from "src/store";
import {
  NodeEditAttributesPanel,
  NodeEditChoicesPanel,
  NodeEditInfoPanel,
  NodeEditNotesPanel,
  NodeEditRulesPanel
} from ".";
import { NodeEditCalculationsPanel } from "./node-edit-calculations-panel";
import { NodeEditContainedPanel } from "./node-edit-contained-panel";

export interface IEditPanelProps {
  disabled: boolean;
  openAlgorithm: IOpenAlgorithm;
}

interface IEditPanelDispatchProps {
  createNode: typeof createNode;
  createPath: typeof createPath;
  reorderPaths: typeof reorderPaths;
  setEditState: typeof setEditState;
  removeNodes: typeof removeNodes;
  removePath: typeof removePath;
  updateNodeText: typeof updateNodeText;
  updateNodes: typeof updateNodes;
}

class EditPanelComponent extends React.PureComponent<
  IEditPanelProps & IEditPanelDispatchProps
> {
  public render() {
    const { disabled, openAlgorithm } = this.props;
    const {
      algoNodes,
      editingState: { selectedNodeIds, selectedPathId }
    } = openAlgorithm;

    if (disabled) {
      return null;
    }

    // Currently this gets the _LAST_ selected node
    let nodeToDisplay;
    const orderedSelection = sortBy(toPairs(selectedNodeIds), 1);
    const lastSelectedNodeId = orderedSelection.pop();
    if (lastSelectedNodeId) {
      if (orderedSelection.length === 0) {
        nodeToDisplay = cloneDeep(algoNodes[lastSelectedNodeId[0]]);
      } else {
        if (orderedSelection.length === 1) {
          // If we have one other that happens to be the parent,
          // then we allow the selection
          const otherSelectedId = orderedSelection.pop();
          const firstNode = cloneDeep(algoNodes[lastSelectedNodeId[0]]);

          if (otherSelectedId) {
            const otherId = otherSelectedId[0];
            if (selectedPathId) {
              const otherNode = algoNodes[otherId];
              if (otherNode.forwardLinks().find(p => p.id === selectedPathId)) {
                nodeToDisplay = firstNode;
              }
            } else if (firstNode.containerId() === otherId) {
              nodeToDisplay = firstNode;
            }
          }
        }
      }
    }

    const cssStyle = {
      height: nodeToDisplay ? "calc(100% - 0.5em)" : "auto",
      opacity: nodeToDisplay ? 1 : 0.3,
      right: 0,
      top: 0,
      zIndex: 10
    };

    return (
      <section
        className={`${Classes.DARK} absolute flex flex-row zx-bg-charcoal-grey mt2 br3 pa2 w-auto zx-mw-5-5`}
        style={cssStyle}
      >
        {!nodeToDisplay && <p className="pa3">Select a node to edit</p>}
        {nodeToDisplay && this.renderTabs(nodeToDisplay, openAlgorithm)}
      </section>
    );
  }

  private renderTabs = (
    nodeToDisplay: AlgoNode,
    openAlgorithm: IOpenAlgorithm
  ) => {
    const {
      editingState: { panelFocus }
    } = openAlgorithm;

    const handleTabChange = (newTab: EditTabType) => {
      this.props.setEditState({
        algoId: openAlgorithm.algorithm.id,
        state: { panelFocus: newTab }
      });
    };

    return (
      <Tabs
        className="pa1 w-100"
        selectedTabId={panelFocus}
        onChange={handleTabChange}
      >
        {this.renderAttrsPanel(nodeToDisplay, openAlgorithm)}
        {this.renderCalcsPanel(nodeToDisplay, openAlgorithm)}
        {this.renderContainedPanel(nodeToDisplay, openAlgorithm)}
        {this.renderChoicesTab(nodeToDisplay, openAlgorithm)}
        {this.renderInfoTab(nodeToDisplay, openAlgorithm)}
        {this.renderDiscussionTab(nodeToDisplay, openAlgorithm)}
      </Tabs>
    );
  };

  private renderInfoTab = (
    nodeToDisplay: AlgoNode,
    openAlgorithm: IOpenAlgorithm
  ) => {
    if (nodeToDisplay.kind === AlgoNodeType.sharedText) {
      return null;
    }
    const infoPanel = (
      <NodeEditInfoPanel
        node={nodeToDisplay}
        openAlgorithm={openAlgorithm}
        setEditState={this.props.setEditState}
        updateNode={this.updateNode}
      />
    );
    return (
      <Tab
        className="zx-edit-panel-height"
        id="info"
        panel={infoPanel}
        title="Info"
      />
    );
  };

  private renderDiscussionTab = (
    nodeToDisplay: AlgoNode,
    openAlgorithm: IOpenAlgorithm
  ) => {
    if (nodeToDisplay.kind === AlgoNodeType.sharedText) {
      return null;
    }

    return (
      <Tab
        className="zx-edit-panel-height"
        id="notes"
        panel={
          <NodeEditNotesPanel
            node={nodeToDisplay}
            openAlgorithm={openAlgorithm}
          />
        }
        title={"Discussion"}
      />
    );
  };

  private renderAttrsPanel = (
    nodeToDisplay: AlgoNode,
    openAlgorithm: IOpenAlgorithm
  ) => {
    let panel = (
      <NodeEditAttributesPanel
        openAlgorithm={openAlgorithm}
        node={nodeToDisplay}
        removeNodes={this.props.removeNodes}
        updateNodeText={this.updateNodeText}
      />
    );
    let title = "Attributes";

    if (nodeToDisplay.kind === AlgoNodeType.sharedText) {
      const {
        editingState: { selectedNodeIds, selectedPathId }
      } = openAlgorithm;

      // Secondary selection is the parent
      const parentId = Object.keys(selectedNodeIds).find(
        id => selectedNodeIds[id] === 1
      );
      const backlinks = nodeToDisplay.backwardLinks();

      const path =
        backlinks.find(p => p.id === selectedPathId) ||
        backlinks.find(p => p.parentId === parentId);

      title = "Display Rules";
      panel = (
        <NodeEditRulesPanel
          openAlgorithm={openAlgorithm}
          containedNode={nodeToDisplay}
          path={path}
          setEditState={this.props.setEditState}
          updateNode={this.updateNode}
          removePath={this.props.removePath}
        />
      );
    }

    return (
      <Tab
        className="zx-edit-panel-height"
        id="attrs"
        panel={panel}
        title={title}
      />
    );
  };

  private renderContainedPanel = (
    nodeToDisplay: AlgoNode,
    openAlgorithm: IOpenAlgorithm
  ) => {
    if (
      ![AlgoNodeType.terminal, AlgoNodeType.page].includes(nodeToDisplay.kind)
    ) {
      return null;
    }

    const containedPanel = (
      <NodeEditContainedPanel
        createNode={this.props.createNode}
        createPath={this.props.createPath}
        node={nodeToDisplay}
        openAlgorithm={openAlgorithm}
        reorderPaths={this.props.reorderPaths}
        setEditState={this.props.setEditState}
        updateNode={this.updateNode}
      />
    );
    return (
      <Tab
        className="zx-edit-panel-height"
        id="contained"
        panel={containedPanel}
        title="Components"
      />
    );
  };

  private renderCalcsPanel = (
    nodeToDisplay: AlgoNode,
    openAlgorithm: IOpenAlgorithm
  ) => {
    if (nodeToDisplay.kind !== AlgoNodeType.varProcessor) {
      return null;
    }

    const calcsPanel = (
      <NodeEditCalculationsPanel
        createPath={this.props.createPath}
        node={nodeToDisplay}
        openAlgorithm={openAlgorithm}
        reorderPaths={this.props.reorderPaths}
        setEditState={this.props.setEditState}
        updateNode={this.updateNode}
      />
    );

    return (
      <Tab
        className="zx-edit-panel-height"
        id="calcs"
        panel={calcsPanel}
        title="Calculations"
      />
    );
  };

  private renderChoicesTab = (
    nodeToDisplay: AlgoNode,
    openAlgorithm: IOpenAlgorithm
  ) => {
    if (
      ![
        AlgoNodeType.varInput,
        AlgoNodeType.varDecision,
        AlgoNodeType.singleSelect,
        AlgoNodeType.multiSelect
      ].includes(nodeToDisplay.kind)
    ) {
      return null;
    }

    let title = "Options";
    switch (nodeToDisplay.kind) {
      case AlgoNodeType.varDecision:
        title = "Outputs";
        break;
      case AlgoNodeType.varInput:
        title = "Variables";
        break;
      case AlgoNodeType.singleSelect:
        title = "Answers";
        break;
    }

    const choicesPanel = (
      <NodeEditChoicesPanel
        createNode={this.props.createNode}
        createPath={this.props.createPath}
        node={nodeToDisplay}
        openAlgorithm={openAlgorithm}
        reorderPaths={this.props.reorderPaths}
        setEditState={this.props.setEditState}
        updateNode={this.updateNode}
      />
    );
    return (
      <Tab
        className="zx-edit-panel-height"
        id="choices"
        panel={choicesPanel}
        title={title}
      />
    );
  };

  private updateNodeText = (node: AlgoNode) => {
    const { openAlgorithm } = this.props;

    this.props.updateNodeText({
      algoId: openAlgorithm.algorithm.id,
      node
    });
  };

  private updateNode = (node: AlgoNode) => {
    const { openAlgorithm } = this.props;

    this.props.updateNodes({
      algoId: openAlgorithm.algorithm.id,
      nodes: [node]
    });
  };
}

export const EditPanel = connect(undefined, {
  createNode,
  createPath,
  removeNodes,
  removePath,
  reorderPaths,
  setEditState,
  updateNodeText,
  updateNodes
})(EditPanelComponent);
