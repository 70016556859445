import { Alert, Button, ButtonGroup, Intent } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import { clone } from "lodash";
import * as React from "react";
import { connect } from "react-redux";
import { SortableContainer, SortableElement } from "react-sortable-hoc";

import { linkNodes, removeNodes, updateNodes, updatePath } from "src/actions";
import {
  AlgoNode,
  AlgoNodeType,
  Algorithm,
  AlgoVariableType,
  detailsForVariableOperator,
  IAlgoNodeTarget,
  NodeActionType,
  notEmpty,
  Parameter,
  ParameterWithIndex,
  VariableOperator,
  VariableType
} from "src/api";
import {
  AlgoNodeSelect,
  AlgorithmSelect,
  NodeActionTypeSelect,
  ParameterSelect,
  VariableOperatorSelect,
  VariableTypeSelect
} from "src/components";
import { IOpenAlgorithm, IStoreState } from "src/store";
import { SortHandle } from ".";

export interface IChoiceOptionProps {
  choice?: IAlgoNodeTarget;
  minimal?: boolean;
  openAlgorithm: IOpenAlgorithm;
  parentNode: AlgoNode;
}

interface IChoiceOptionInjectedProps {
  otherAlgorithms: Algorithm[];
}

interface IChoiceOptionDispatchProps {
  linkNodes: typeof linkNodes;
  removeNodes: typeof removeNodes;
  updateNodes: typeof updateNodes;
  updatePath: typeof updatePath;
}

interface IChoiceOptionState {
  deleteWarnOpen: boolean;
  choiceAction: NodeActionType;
}

type ChoiceOptionComponentProps = IChoiceOptionProps &
  IChoiceOptionInjectedProps &
  IChoiceOptionDispatchProps;

class ChoiceOptionComponent extends React.PureComponent<
  ChoiceOptionComponentProps,
  IChoiceOptionState
> {
  constructor(props: ChoiceOptionComponentProps) {
    super(props);
    const { choice, parentNode } = props;
    let initialChoiceAction =
      parentNode.kind === AlgoNodeType.varInput
        ? NodeActionType.setVariable
        : NodeActionType.goToNode;

    if (choice) {
      const { node } = choice;
      if (node) {
        const target = node.targets(props.openAlgorithm.algoNodes)[0];
        if (target && target.path.targetAlgorithmId) {
          initialChoiceAction = NodeActionType.goToAlgorithm;
        }
      }
    }

    this.state = {
      choiceAction: initialChoiceAction,
      deleteWarnOpen: false
    };
  }

  public render() {
    const { choice, minimal, openAlgorithm, parentNode } = this.props;
    const { deleteWarnOpen } = this.state;

    const handleConfirm = () => this.setState({ deleteWarnOpen: true });
    const handleCancelDelete = () => this.setState({ deleteWarnOpen: false });
    if (!choice || !choice.node) {
      return null;
    }

    return (
      <section
        key={choice.path.id}
        className="flex flex-row item-start pb2 zx-bg-charcoal-grey"
      >
        <Alert
          canEscapeKeyCancel={true}
          canOutsideClickCancel={true}
          cancelButtonText="Cancel"
          onCancel={handleCancelDelete}
          confirmButtonText="Delete"
          onConfirm={this.handleRemove}
          isOpen={deleteWarnOpen}
          icon={IconNames.TRASH}
          intent={Intent.DANGER}
        >
          <p>Are you sure you wish to remove this choice?</p>
        </Alert>
        {!minimal && <SortHandle />}
        <div className="flex flex-column flex-auto">
          <div className="flex flex-row items-start pb1">
            <textarea
              className="flex flex-auto zx-input-edit"
              style={{}}
              value={choice.node.title}
              onChange={this.handleTextChange}
            />
            {!minimal && (
              <Button
                icon={IconNames.REMOVE}
                minimal={true}
                onClick={handleConfirm}
              />
            )}
          </div>
          {this.renderOptionActions(choice, openAlgorithm, parentNode)}
        </div>
      </section>
    );
  }

  private renderOptionActions = (
    choice: IAlgoNodeTarget,
    openAlgorithm: IOpenAlgorithm,
    parent: AlgoNode
  ) => {
    const { choiceAction } = this.state;

    const handleActionChange = (actionType: NodeActionType) => {
      this.setState({ choiceAction: actionType });
    };

    // TODO: multiple actions??
    const actionCount = 1;
    const showRemove = actionCount > 1;

    return (
      <div className="flex flex-row justify-between items-start pb2">
        <div className="flex flex-row flex-auto">
          <NodeActionTypeSelect
            className="pr1"
            nodeType={parent.kind}
            onChange={handleActionChange}
            value={choiceAction}
            variableActions={true}
          />
          {this.renderOptionActionHandler(choice, parent, openAlgorithm)}
        </div>
        <ButtonGroup>
          <Button
            icon={IconNames.REMOVE}
            minimal={true}
            style={{ visibility: showRemove ? "visible" : "hidden" }}
          />
        </ButtonGroup>
      </div>
    );
  };

  private renderOptionActionHandler = (
    choice: IAlgoNodeTarget,
    parent: AlgoNode,
    openAlgorithm: IOpenAlgorithm
  ) => {
    if (!choice.node) {
      return null;
    }
    switch (parent.kind) {
      case AlgoNodeType.multiSelect:
        return this.renderMultiSelectChoice(choice.node);

      case AlgoNodeType.singleSelect:
        return this.renderSingleSelectChoice(choice, openAlgorithm);

      case AlgoNodeType.varInput:
        return this.renderVarInputChoice(choice, openAlgorithm, parent);

      default:
        return null;
    }
  };

  private renderMultiSelectChoice = (choiceNode: AlgoNode) => {
    const variableType = AlgoVariableType.weight;
    const variableOperator =
      choiceNode.weight === 0 ? VariableOperator.clear : VariableOperator.add;
    const operatorDetails = detailsForVariableOperator(variableOperator);

    const handleOperatorChange = (opType: VariableOperator) => {
      if (opType === VariableOperator.add && choiceNode.weight === 0) {
        this.handleWeightChange(1);
      } else if (opType === VariableOperator.clear) {
        this.handleWeightChange(0);
      }
    };

    const operatorDetailsRenderer = (
      details: { type: string; constraint: VariableType },
      i: number
    ) => {
      const handleVariableChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (variableType === AlgoVariableType.weight) {
          this.handleWeightChange(parseInt(e.target.value, 10));
        }
      };
      return (
        <input
          key={i}
          className="ml2 zx-input-edit flex-auto"
          onChange={handleVariableChange}
          type={details.type}
          value={choiceNode.weight}
        />
      );
    };

    return (
      <div className="flex flex-column flex-auto ">
        <VariableTypeSelect className="flex" value={AlgoVariableType.weight} />
        <div className="flex flex-row flex-auto pt1">
          <VariableOperatorSelect
            decisionEnabled={false}
            variableType={variableType}
            value={variableOperator}
            onChange={handleOperatorChange}
          />
          {operatorDetails.inputs.map(operatorDetailsRenderer)}
        </div>
      </div>
    );
  };

  private renderSingleSelectChoice = (
    choice: IAlgoNodeTarget,
    openAlgorithm: IOpenAlgorithm
  ) => {
    if (!choice.node) {
      return null;
    }

    const { choiceAction } = this.state;
    const { otherAlgorithms } = this.props;

    // Get the target node if any
    const target = choice.node.targets(openAlgorithm.algoNodes)[0];
    const excluded = [choice.path.parentId, choice.path.childId].filter(
      notEmpty
    );
    const targetAlgo = target
      ? target.path.targetAlgorithmId
        ? otherAlgorithms.find(a => a.id === target.path.targetAlgorithmId)
        : undefined
      : undefined;

    const handleAlgoChange = (algorithm: Algorithm) => {
      const startNode = algorithm.nodes.find(
        n => n.kind === AlgoNodeType.entryPoint
      );
      if (startNode) {
        this.handleTargetChange(startNode, algorithm.id);
      }
    };

    const component =
      choiceAction === NodeActionType.goToNode ? (
        <AlgoNodeSelect
          className="flex"
          onChange={this.handleTargetChange}
          openAlgorithm={openAlgorithm}
          nodeIdsToExclude={excluded}
          value={target && target.node}
        />
      ) : (
        <AlgorithmSelect onChange={handleAlgoChange} value={targetAlgo} />
      );
    return <div className="flex flex-column flex-auto ">{component}</div>;
  };

  private renderVarInputChoice = (
    choice: IAlgoNodeTarget,
    openAlgorithm: IOpenAlgorithm,
    parentNode: AlgoNode
  ) => {
    const { choiceAction } = this.state;
    const {
      algoNodes,
      algorithm: { id, localVars, variables }
    } = openAlgorithm;
    const { node, path } = choice;

    const varUsage = path.paramsJson && path.paramsJson[0];
    const combinedVariables = localVars
      ? [...variables, ...Object.values(localVars)]
      : variables;

    let value: ParameterWithIndex | undefined;
    if (varUsage) {
      const val = combinedVariables.find(v => v.id === varUsage.id);
      if (val) {
        value = { ...val, valueIndex: varUsage.groupNumber };
      }
    }

    // Path to the choice node contains the variable to use in the JSON
    const handleVarChange = (
      param: Parameter,
      groupIndex: number | undefined
    ) => {
      const newPath = clone(path);
      newPath.paramsJson = [{ id: param.id, groupNumber: groupIndex }];
      this.props.updatePath({ algoId: id, path: newPath });
      if (node) {
        const updatedNode = clone(node);
        updatedNode.title = param.title;
        this.props.updateNodes({ algoId: id, nodes: [updatedNode] });
      }
    };

    const usedVariables: Parameter[] = parentNode
      .options(algoNodes)
      .map(o => (o.path.paramsJson ? o.path.paramsJson[0].id : undefined))
      .map(vId =>
        value && value.id === vId // Is the currently selected value
          ? // Show variable if it has multiple parts, otherwise don't
            combinedVariables.find(
              v => v.id === vId && v.detailsJson.length === 1
            )
          : combinedVariables.find(v => v.id === vId)
      )
      .filter(notEmpty);

    const listItems = combinedVariables
      .filter(v => !v.output)
      .filter(v => !usedVariables.find(uv => uv.id === v.id));

    const actionHandler = choiceAction === NodeActionType.setVariable && (
      <ParameterSelect
        items={listItems}
        onChange={handleVarChange}
        value={value}
      />
    );
    return <div className="flex flex-column flex-auto ">{actionHandler}</div>;
  };

  private handleTextChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const {
      choice,
      openAlgorithm: { algorithm }
    } = this.props;
    if (choice && choice.node) {
      const updatedNode = clone(choice.node);
      updatedNode.title = e.target.value;
      this.props.updateNodes({ algoId: algorithm.id, nodes: [updatedNode] });
    }
  };

  private handleTargetChange = (node: AlgoNode, otherAlgoId?: string) => {
    const {
      choice,
      openAlgorithm: { algorithm }
    } = this.props;
    if (choice && choice.path.childId) {
      this.props.linkNodes({
        algoId: algorithm.id,
        childId: node.id,
        otherAlgoId,
        parentId: choice.path.childId,
        pathId: choice.path.id
      });
    }
  };

  private handleWeightChange = (weight: number) => {
    const {
      choice,
      openAlgorithm: { algorithm, algoNodes },
      parentNode
    } = this.props;
    if (choice && choice.node) {
      const updatedNode = clone(choice.node);
      if (updatedNode.weight !== 0 && weight === 0) {
        // Check for another zero value and change it.
        if (parentNode) {
          parentNode
            .options(algoNodes)
            .filter(o => o.path.id !== choice.path.id)
            .filter(o => o.node && o.node.weight === 0)
            .forEach(o => {
              if (o.node) {
                const updatedOption = clone(o.node);
                updatedOption.weight = 1;
                this.props.updateNodes({
                  algoId: algorithm.id,
                  nodes: [updatedOption]
                });
              }
            });
        }
      }
      updatedNode.weight = weight;
      this.props.updateNodes({ algoId: algorithm.id, nodes: [updatedNode] });
    }
  };

  private handleRemove = () => {
    const {
      choice,
      openAlgorithm: { algorithm }
    } = this.props;
    if (choice && choice.node) {
      this.props.removeNodes({
        algoId: algorithm.id,
        nodeIds: { [choice.node.id]: 1 }
      });
    }
  };
}

const mapStateToProps = (
  { algoStore }: IStoreState,
  props: IChoiceOptionProps
): IChoiceOptionInjectedProps => {
  const otherAlgorithms = Object.values(algoStore.allAlgorithms).filter(
    a => a.id !== props.openAlgorithm.algorithm.id
  );

  return {
    otherAlgorithms
  };
};

export const ChoiceOption = connect(mapStateToProps, {
  linkNodes,
  updateNodes,
  removeNodes,
  updatePath
})(ChoiceOptionComponent);

export const SortableChoice = SortableElement(
  ({ props }: { props: IChoiceOptionProps }) => <ChoiceOption {...props} />
);

export const SortableChoices = SortableContainer(
  ({ items }: { items: IChoiceOptionProps[] }) => {
    const wrapper = (props: IChoiceOptionProps, i: number) => (
      <SortableChoice
        index={i}
        key={props.choice && props.choice.path.id}
        props={props}
      />
    );
    return <div>{items.map(wrapper)}</div>;
  }
);
