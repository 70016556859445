import { Button, MenuItem } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import { ItemRenderer, Select } from "@blueprintjs/select";
import * as React from "react";

import {
  AlgoNodeType,
  detailsForNodeActionType,
  kMultiSelectNodeActionTypes,
  kSingleSelectNodeActionTypes,
  NodeActionType
} from "src/api";

export interface INodeActionTypeSelectProps {
  className?: string;
  disabled?: boolean;
  nodeType: AlgoNodeType;
  onChange?: (value: NodeActionType) => void;
  value?: NodeActionType;
  variableActions: boolean;
}

const TypeSelect = Select.ofType<NodeActionType>();

export class NodeActionTypeSelect extends React.PureComponent<
  INodeActionTypeSelectProps
> {
  public render() {
    const { disabled, nodeType, value, variableActions } = this.props;

    let items: NodeActionType[];

    switch (nodeType) {
      case AlgoNodeType.multiSelect:
        items = kMultiSelectNodeActionTypes;
        break;
      case AlgoNodeType.singleSelect:
      case AlgoNodeType.varDecision:
        items = kSingleSelectNodeActionTypes(variableActions);
        break;
      case AlgoNodeType.varInput:
        items = [NodeActionType.setVariable];
        break;

      default:
        items = [];
    }

    return (
      <div className={`${this.props.className}`}>
        <TypeSelect
          disabled={disabled || items.length < 2}
          filterable={false}
          popoverProps={{ minimal: true }}
          items={items}
          itemRenderer={this.itemRenderer}
          onItemSelect={this.selectionHandler}
          inputProps={{ disabled }}
        >
          <Button
            icon={detailsForNodeActionType(value || items[0]).icon}
            rightIcon={items.length > 1 ? IconNames.CARET_DOWN : undefined}
          />
        </TypeSelect>
      </div>
    );
  }

  private itemRenderer: ItemRenderer<NodeActionType> = (
    value,
    { handleClick, modifiers: { disabled } }
  ) => {
    const details = detailsForNodeActionType(value);
    return (
      <MenuItem
        disabled={disabled}
        icon={details.icon}
        key={value}
        onClick={handleClick}
        text={details.text}
      />
    );
  };

  private selectionHandler = (value: NodeActionType) => {
    const { onChange } = this.props;
    if (onChange) {
      onChange(value);
    }
  };
}
