import {
  Button,
  Classes,
  Dialog,
  Intent,
  Menu,
  MenuItem,
  Popover,
  Position,
  Tab,
  Tabs
} from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import { push } from "connected-react-router";
import { Dictionary } from "lodash";
import * as React from "react";
import { connect } from "react-redux";
import { RouteComponentProps } from "react-router-dom";
import { submit, FormErrors, getFormSyncErrors } from "redux-form";

import {
  deleteStudy,
  updateStudy,
  assignPatientToStudy,
  removePatientFromStudy
} from "src/actions";
import { IStudyUsersRolesSplit, Study, StudyStatus } from "src/api";
import { ConnectedPageTitle } from "src/components";
import { kStudiesUrlKey } from "src/config/routes";
import { IStoreState } from "src/store";
import { EditStudyAlgorithms } from "./edit-study-algorithms";
import { EditStudyForm } from "./edit-study-form";
import { ApproveStudyDialog } from "./approve-study-dialog";
import { EditStudyVariables } from "./edit-study-variables";
import { EditStudyPatients } from ".";

type IEditStudyProps = RouteComponentProps<{ studyId: string }>;

interface IEditStudyInjectedProps {
  busy: boolean;
  error?: Error;
  studyErrors: FormErrors<Study & IStudyUsersRolesSplit>;
  studies: Dictionary<Study>;
}

interface IEditStudyDispatchProps {
  deleteStudy: typeof deleteStudy.request;
  push: typeof push;
  submit: typeof submit;
  updateStudy: typeof updateStudy.request;
  addPatient: typeof assignPatientToStudy.request;
  removePatient: typeof removePatientFromStudy.request;
}

type EditStudyProps = IEditStudyProps &
  IEditStudyInjectedProps &
  IEditStudyDispatchProps;
type StudyTabType = "info" | "algos";

interface IEditStudyState {
  confirmRemoveDialogOpen: boolean;
  confirmApproveDialogOpen: boolean;
  optionsMenuOpen: boolean;
  panelFocus: StudyTabType;
  statusText: string;
  updatedStudyToSave?: Study;
}

class EditStudyComponent extends React.PureComponent<
  EditStudyProps,
  IEditStudyState
> {
  private autoSaveTimer: NodeJS.Timeout | undefined;

  public constructor(props: EditStudyProps) {
    super(props);
    this.state = {
      confirmRemoveDialogOpen: false,
      confirmApproveDialogOpen: false,
      optionsMenuOpen: false,
      panelFocus: "info",
      statusText: ""
    };
  }

  public componentDidMount() {
    this.autoSaveTimer = setInterval(async () => {
      this.saveStudy();
    }, 3000);
  }

  public componentWillUnmount() {
    if (this.autoSaveTimer) {
      clearTimeout(this.autoSaveTimer);
      this.saveStudy();
    }
  }

  public componentDidUpdate(prevProps: EditStudyProps) {
    const {
      busy,
      error,
      studies,
      studyErrors,
      match: {
        params: { studyId }
      }
    } = this.props;
    const {
      updatedStudyToSave: needsSaving,
      confirmRemoveDialogOpen,
      statusText
    } = this.state;
    const study = studies[studyId];

    if (confirmRemoveDialogOpen && !study) {
      this.setState({
        confirmRemoveDialogOpen: false,
        updatedStudyToSave: undefined
      });
      this.props.push(kStudiesUrlKey);
    } else if (!(error || busy) && needsSaving) {
      this.props.updateStudy(needsSaving);
      this.setState({ updatedStudyToSave: undefined });
    }

    // Set status text
    const kFixBasicIssues = "Basic study info missing";
    let newStatusText = "";
    if (Object.keys(studyErrors).length > 0) {
      newStatusText = kFixBasicIssues;
    } else if (study && study.algorithms.length === 0) {
      newStatusText = "Algorithms are missing";
    }

    if (statusText !== newStatusText) {
      this.setState({ statusText: newStatusText });
    }
  }

  public render() {
    const { panelFocus } = this.state;
    const {
      studies,
      studyErrors,
      match: {
        params: { studyId }
      }
    } = this.props;

    const handleTabChange = (newTab: StudyTabType) =>
      this.setState({ panelFocus: newTab });

    const study = studies[studyId];

    if (!study) {
      return null;
    }
    const disabled = Object.keys(studyErrors).length > 0;
    return (
      <section className="pa2 w-100 h-100">
        {this.renderRemovalConfirmDialog(study)}
        <section className="flex flex-row justify-between">
          <ConnectedPageTitle />
          {this.renderOptionsMenu(study)}
        </section>
        <Tabs
          className="pa2 w-100 h-100"
          selectedTabId={panelFocus}
          onChange={handleTabChange}
        >
          <Tab
            id="info"
            panel={this.renderInfoSection(study)}
            title="Info & Permissions"
          />
          <Tab
            id="algos"
            panel={this.renderAlgoSection(study)}
            title="Algorithms"
            disabled={disabled}
          />
          <Tab
            id="vars"
            panel={this.renderVarsSection(study)}
            title="Variables"
            disabled={disabled}
          />
          <Tab
            id="patients"
            panel={this.renderPatientsSection(study)}
            title="Patients"
            disabled={disabled}
          />
        </Tabs>
      </section>
    );
  }

  private renderInfoSection = (study: Study) => {
    const usersByRole = study.splitStudyUsersByRole();

    return (
      <section>
        <EditStudyForm
          initialValues={{ ...study, ...usersByRole }}
          form={study.id}
          onSubmit={this.handleSubmit}
          study={study}
        />
      </section>
    );
  };

  private renderAlgoSection = (study: Study) => {
    return (
      <section>
        <EditStudyAlgorithms study={study} />
      </section>
    );
  };

  private renderVarsSection = (study: Study) => {
    return (
      <section>
        <EditStudyVariables study={study} />
      </section>
    );
  };
  private renderPatientsSection = (study: Study) => {
    return (
      <section>
        <EditStudyPatients study={study} />
      </section>
    );
  };

  private renderOptionsMenu = (study: Study) => {
    const { optionsMenuOpen, statusText } = this.state;

    const handleOpen = () => {
      this.setState({ optionsMenuOpen: true });
    };
    const handleClose = () => {
      this.setState({ optionsMenuOpen: false });
    };
    const handleMenuClick = () => {
      this.setState({ optionsMenuOpen: !optionsMenuOpen });
    };
    const content = (
      <Menu onClick={handleMenuClick} className="">
        {this.menuContent()}
      </Menu>
    );

    const openConfirm = () => this.setState({ confirmApproveDialogOpen: true });
    const handleApproveClose = () =>
      this.setState({ confirmApproveDialogOpen: false });
    const approvedDisabled =
      this.state.statusText.length > 0 ||
      study.status === StudyStatus.awaitingReview;

    return (
      <div className="flex flex-row justify-end items-center">
        <ApproveStudyDialog
          isOpen={this.state.confirmApproveDialogOpen}
          study={study}
          onClose={handleApproveClose}
        />
        <span className="pa1 pr2">{statusText}</span>
        <Button
          className="pv2 mr1"
          disabled={approvedDisabled}
          text="Request Approval"
          icon={IconNames.CONFIRM}
          intent={Intent.SUCCESS}
          onClick={openConfirm}
        />
        <Popover
          className="pv2"
          key="algoMenu"
          onClosed={handleClose}
          onOpened={handleOpen}
          minimal={true}
          position={Position.BOTTOM_RIGHT}
          content={content}
        >
          <Button
            active={optionsMenuOpen}
            icon={IconNames.COG}
            minimal={true}
          />
        </Popover>
      </div>
    );
  };

  private menuContent = () => {
    const confirmDelete = () => {
      this.setState({ confirmRemoveDialogOpen: true });
    };

    const deleteItem = (
      <MenuItem
        icon={IconNames.DELETE}
        intent={Intent.DANGER}
        key="delete"
        onClick={confirmDelete}
        text="Delete"
      />
    );
    return [deleteItem];
  };

  private renderRemovalConfirmDialog = (study: Study) => {
    const { confirmRemoveDialogOpen } = this.state;

    const handleClose = () => this.setState({ confirmRemoveDialogOpen: false });

    return (
      <Dialog
        canOutsideClickClose={true}
        icon={IconNames.DELETE}
        isCloseButtonShown={false}
        onClose={handleClose}
        isOpen={confirmRemoveDialogOpen}
        title="Confirm Delete"
      >
        <div className={`${Classes.DIALOG_BODY}`}>
          <p>This will remove the study &apos;{study.title}&apos;.</p>
          <p>Are you sure?</p>
        </div>
        {this.renderDialogFooter(study)}
      </Dialog>
    );
  };

  private renderDialogFooter = (study: Study) => {
    const { busy, error } = this.props;

    const handleDelete = () => {
      this.props.deleteStudy(study);
    };

    const handleCancel = () =>
      this.setState({ confirmRemoveDialogOpen: false });

    const errorRenderer = (e: Error) =>
      e.message.length > 0 && <p className="mt2 orange">{e.message}</p>;

    return (
      <div className={`${Classes.DIALOG_FOOTER} flex flex-row justify-between`}>
        <Button
          disabled={busy}
          intent={Intent.NONE}
          onClick={handleCancel}
          text={"Cancel"}
        />
        {error && errorRenderer(error)}
        <Button
          className="ml2"
          disabled={busy}
          intent={Intent.DANGER}
          loading={busy}
          onClick={handleDelete}
          text="Delete"
        />
      </div>
    );
  };

  private saveStudy = () => {
    const {
      match: {
        params: { studyId }
      }
    } = this.props;
    this.props.submit(studyId);
  };

  private handleSubmit = (updated: Study & IStudyUsersRolesSplit) => {
    const {
      match: {
        params: { studyId }
      },
      studies
    } = this.props;
    const { updatedStudyToSave: needsSaving } = this.state;

    const origStudy = studies[studyId];
    updated.users = updated.mergeStudyUserTypes(updated);

    if (origStudy) {
      if (origStudy.needsUpdate(updated)) {
        if (!needsSaving) {
          this.setState({ updatedStudyToSave: updated });
        }
      } else {
        const { added, deleted } = origStudy.changedPatients(updated);
        const addedIds = Object.keys(added);
        const deletedIds = Object.keys(deleted);
        if (addedIds.length > 0) {
          this.props.addPatient({ patient: added[addedIds[0]], studyId });
        } else if (deletedIds.length > 0) {
          this.props.removePatient({
            patient: deleted[deletedIds[0]],
            studyId
          });
        }
      }
    }
  };
}

const mapStateToProps = (state: IStoreState, props: IEditStudyProps) => {
  const { studiesStore, userStore, uiStore } = state;

  const studyErrors: FormErrors<Study &
    IStudyUsersRolesSplit> = getFormSyncErrors(props.match.params.studyId)(
    state
  );

  return {
    busy: uiStore.studies.studiesLoadersCount > 0,
    error: studiesStore.error,
    loggedInUser: userStore.loggedInUser,
    studyErrors: studyErrors || {},
    studies: studiesStore.studies
  };
};

export const EditStudy = connect(mapStateToProps, {
  deleteStudy: deleteStudy.request,
  push,
  submit,
  updateStudy: updateStudy.request,
  addPatient: assignPatientToStudy.request,
  removePatient: removePatientFromStudy.request
})(EditStudyComponent);
