import { Button } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import * as React from "react";
import { change, formValueSelector } from "redux-form";
import { connect } from "react-redux";
import { cloneDeep } from "lodash";

import {
  Algorithm,
  fullName,
  Study,
  updateArray,
  removeFromArray,
  StudyStatus
} from "src/api";
import { AddAlgorithmDialog } from "./add-algorithm-dialog";
import { IStoreState } from "src/store";

interface IEditStudyAlgorithmsProps {
  className?: string;
  study: Study;
}

interface IEditStudyAlgorithmsInjectedProps {
  algorithms: Algorithm[];
}

interface IEditStudyAlgorithmsDispatchProps {
  changeValues: typeof change;
}

interface IEditStudyAlgorithmsState {
  addAlgoDialogOpen: boolean;
}

type EditStudyAlgorithmsProps = IEditStudyAlgorithmsProps &
  IEditStudyAlgorithmsInjectedProps &
  IEditStudyAlgorithmsDispatchProps;

class EditStudyAlgorithmComponent extends React.PureComponent<
  EditStudyAlgorithmsProps,
  IEditStudyAlgorithmsState
> {
  constructor(props: EditStudyAlgorithmsProps) {
    super(props);

    this.state = {
      addAlgoDialogOpen: false
    };
  }

  public render() {
    const { algorithms, study } = this.props;
    const { addAlgoDialogOpen } = this.state;

    const handleClose = (algos?: Algorithm[]) => {
      if (algos) {
        let updated = cloneDeep(study.algorithms);
        algos.forEach(a => (updated = updateArray(updated, a)));
        this.props.changeValues(study.id, "algorithms", updated);
      }
      this.setState({ addAlgoDialogOpen: false });
    };

    return (
      <section>
        <AddAlgorithmDialog
          isOpen={addAlgoDialogOpen}
          onClose={handleClose}
          study={study}
        />
        {this.renderAlgorithms(study, algorithms)}
      </section>
    );
  }

  private renderAlgorithms = (study: Study, algorithms: Algorithm[]) => {
    const openDialog = () => this.setState({ addAlgoDialogOpen: true });

    const editable = study.status !== StudyStatus.approved;
    const editButton = editable && (
      <Button
        minimal={true}
        rightIcon={IconNames.ADD}
        text="Add algorithms"
        onClick={openDialog}
      />
    );

    return (
      <section>
        <section className="w-100 flex b bb b--gray pb1 mb1">
          <div className="fl w-30 pt2 pa1">Name</div>
          <div className="fl w-40 pt2 pa1">Topics</div>
          <div className="fl w-30 flex justify-between">
            <span className="pa1 pt2">Author</span>
            {editButton}
          </div>
        </section>
        <section>
          {algorithms.length === 0 && (
            <span className="zx-blue">No algorithms added yet</span>
          )}
          {this.renderAlgoRows(study, algorithms)}
        </section>
      </section>
    );
  };

  private renderAlgoRows = (study: Study, algorithms: Algorithm[]) => {
    const {
      study: { id }
    } = this.props;

    return algorithms.map(a => {
      const removeRow = () => {
        const updated = removeFromArray(algorithms, a.id);
        this.props.changeValues(id, "algorithms", updated);
      };
      return this.renderAlgoRow(study, a, removeRow);
    });
  };

  private renderAlgoRow = (
    study: Study,
    algo: Algorithm,
    removeAlgo: () => void
  ) => {
    const editable = study.status !== StudyStatus.approved;
    return (
      <div key={algo.id} className="flex flex-row w-100">
        <div className="fl w-30 pa1">{algo.title}</div>
        <div className="fl w-40 pa1">
          {algo.medicalSpecialties.map(s => s.topic).join(", ")}
        </div>
        <div className="fl w-30 flex justify-between">
          <span className="pa1 pt2">
            {algo.authors.map(fullName).join(", ")}
          </span>
          {editable && (
            <Button
              icon={IconNames.REMOVE}
              minimal={true}
              onClick={removeAlgo}
            />
          )}
        </div>
      </div>
    );
  };
}

const mapStateToProps = (
  state: IStoreState,
  { study }: IEditStudyAlgorithmsProps
) => {
  const selector = formValueSelector(study.id);
  const algorithms = selector(state, "algorithms");
  return {
    algorithms
  };
};

export const EditStudyAlgorithms = connect(mapStateToProps, {
  changeValues: change
})(EditStudyAlgorithmComponent);
