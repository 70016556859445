import { Button, H3, H6, Icon, Intent, Tab, Tabs } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import * as React from "react";
import { connect } from "react-redux";

import { ILocationDetails, updateUser } from "src/actions";
import {
  fullName,
  User,
  userIsAdmin,
  userLocationString,
  userRoleString
} from "src/api";
import { AvatarEditDialog } from "src/components";
import { IStoreState } from "src/store";
import { UserProfileAlgos, UserProfileDetails, UserProfileSettings } from ".";
import { UserEvents } from "../admin/user-events";

export interface IUserProfileProps {
  busy: boolean;
  error?: Error;
  loggedInUser?: User;
  showOnlyProfile?: boolean;
  updateUser?: typeof updateUser.request;
  user?: User;
}

type TabName = "profile" | "algos" | "history";
interface IUserProfileState {
  editMode: boolean;
  imageUrl?: string;
  selectedTab: TabName;
  showEditAvatar: boolean;
}

class UserProfileComponent extends React.PureComponent<
  IUserProfileProps,
  IUserProfileState
> {
  constructor(props: IUserProfileProps) {
    super(props);

    this.state = {
      editMode: false,
      selectedTab: "profile",
      showEditAvatar: false
    };
  }

  public componentDidUpdate(oldProps: IUserProfileProps) {
    const { busy, error } = this.props;
    const { busy: oldBusy } = oldProps;

    if (!busy && oldBusy && !error) {
      if (this.state.editMode) {
        this.setState({ editMode: false });
      }
    }
  }

  public render() {
    const {
      busy,
      loggedInUser,
      showOnlyProfile,
      updateUser: updateReq,
      user
    } = this.props;
    const { editMode, imageUrl: uploadedImage, showEditAvatar } = this.state;

    if (!user) {
      return null;
    }

    const { role } = user;
    const avatarUrl = user.avatar && user.avatar.thumb;
    const editable = updateReq && loggedInUser && user.id === loggedInUser.id;
    const changeTab = (newId: TabName) => this.setState({ selectedTab: newId });

    const openAvatarEdit = () => this.setState({ showEditAvatar: true });
    const closeAvatarEdit = (imageUrl?: string) =>
      this.setState({
        editMode: imageUrl ? false : true,
        imageUrl,
        showEditAvatar: false
      });
    const toggleEdit = () => {
      this.setState({ editMode: !editMode });
    };

    const imageSection =
      uploadedImage || avatarUrl ? (
        <img
          alt=""
          className={`h3 w3 br-100 mb3 ${editMode ? "ba b--orange" : ""}`}
          draggable={false}
          src={uploadedImage || avatarUrl}
        />
      ) : (
        <Icon
          className={`h3 w3 mb3 ${editMode ? "ba b--orange" : ""}`}
          icon={IconNames.USER}
          iconSize={64}
        />
      );

    const editButton = editable && (
      <Button
        text={editMode ? "Cancel" : "Edit"}
        intent={editMode ? Intent.DANGER : Intent.SUCCESS}
        small={true}
        onClick={toggleEdit}
      />
    );
    const historySection =
      !(showOnlyProfile || editMode) &&
      userIsAdmin(loggedInUser) &&
      this.historyTab(user, loggedInUser);
    const algosTab =
      !(showOnlyProfile || editMode) && this.algorithmsTab(user, loggedInUser);
    const settingsTab = user === loggedInUser && this.settingsTab(user);

    return (
      <section>
        <AvatarEditDialog isOpen={showEditAvatar} onClose={closeAvatarEdit} />
        <section className="flex flex-row items-start justify-between pa2">
          <section className="flex flex-row ">
            <div
              className={`${editMode ? "pointer" : ""}`}
              onClick={editMode ? openAvatarEdit : undefined}
            >
              {imageSection}
            </div>
            <div className="flex flex-column flex-auto pa2">
              <H3>{fullName(user)}</H3>
              <H6>{userRoleString(role)}</H6>
            </div>
          </section>
          {editButton}
        </section>
        <Tabs
          className="pa2"
          selectedTabId={this.state.selectedTab}
          onChange={changeTab}
        >
          {this.profileTab(user, loggedInUser, editMode, busy)}
          {settingsTab}
          {algosTab}
          {historySection}
        </Tabs>
        <section className="flex flex-row pa2" />
      </section>
    );
  }

  private handleUserUpdate = (
    values: User & { locationDetails: ILocationDetails }
  ) => {
    // Break out new location info
    const { locationDetails } = values;
    if (locationDetails) {
      const {
        value: { country, region, city }
      } = locationDetails;
      if (country) {
        values.country = country;
      }
      if (region) {
        values.region = region;
      }
      if (city) {
        values.city = city;
      }
    }
    if (this.props.updateUser) {
      this.props.updateUser(values);
      // this.setState({ editMode: false });
    }
  };

  private profileTab = (
    user: User,
    loggedInUser: User | undefined,
    editMode: boolean,
    busy: boolean
  ) => {
    const initialValues = {
      ...user,
      locationDetails: {
        label: userLocationString(user),
        value: {
          city: user.city,
          country: user.country,
          region: user.region
        }
      }
    };
    const profilePanel = (
      <UserProfileDetails
        busy={busy}
        initialValues={initialValues}
        loggedInUser={loggedInUser}
        editMode={editMode}
        form={user.id}
        onSubmit={this.handleUserUpdate}
        user={user}
      />
    );
    return <Tab id="profile" panel={profilePanel} title="Profile" />;
  };

  private settingsTab = (user: User) => {
    return (
      <Tab
        id="settings"
        panel={
          <UserProfileSettings user={user} updateUser={this.props.updateUser} />
        }
        title="Settings"
      />
    );
  };

  private algorithmsTab = (user: User, loggedInUser?: User) => {
    return (
      <Tab
        id="algos"
        panel={<UserProfileAlgos user={user} />}
        title="Algorithms"
      />
    );
  };

  private historyTab = (user: User, loggedInUser?: User) => {
    return (
      <Tab
        id="history"
        panel={<UserEvents user={user} loggedInUser={loggedInUser} />}
        title="History"
      />
    );
  };
}

const mapStateToProps = ({ uiStore, userStore }: IStoreState) => {
  return {
    busy: uiStore.community.userLoadersCount > 0,
    error: uiStore.error,
    loggedInUser: userStore.loggedInUser
  };
};

export const UserProfile = connect(mapStateToProps, {})(UserProfileComponent);
