import {
  Button,
  Classes,
  H3,
  H4,
  H5,
  Popover,
  PopoverPosition,
  Spinner,
  Icon
} from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import moment from "moment";
import { Dictionary } from "lodash";
import * as React from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { connect } from "react-redux";

import { retrievePatient } from "src/actions";
import { Algorithm, Study, Patient, Visit, User, fullName } from "src/api";
import { IStoreState } from "src/store";
import { PatientNameSelect } from "src/components";
import { NewVisitDialog, RunAlgorithmDialog } from ".";

type IStudyDashboardProps = RouteComponentProps<{
  studyId: string;
  patientId: string;
}>;

interface IStudyDashboardInjectedProps {
  error?: Error;
  canExecuteAlgorithms: boolean;
  loggedInUser?: User;
  patients: Dictionary<Patient>;
  study?: Study;
}

interface IStudyDashboardDispatchProps {
  retrievePatient: typeof retrievePatient;
}

interface IStudyDashboardState {
  patientForNewVisit?: Patient;
  selectedAlgorithm?: Algorithm;
}

type StudyDashboardProps = IStudyDashboardProps &
  IStudyDashboardInjectedProps &
  IStudyDashboardDispatchProps;

class StudyDashboardComponent extends React.PureComponent<
  StudyDashboardProps,
  IStudyDashboardState
> {
  constructor(props: StudyDashboardProps) {
    super(props);
    this.state = {};
  }

  public render() {
    const { canExecuteAlgorithms, study } = this.props;

    if (!study) {
      return (
        <section className="w-100 mt4">
          <Spinner size={50} />
        </section>
      );
    }

    const startDate = moment(study.startDate);
    const now = moment(new Date());
    const roundedDaysSinceStart = Math.round(
      moment.duration(now.diff(startDate)).asDays()
    );
    const started = roundedDaysSinceStart > 0;

    return (
      <section className="flex flex-column flex-auto pa2">
        <H3 className="ttu zx-blue">{study.title}</H3>
        <section
          className={`${Classes.DARK} flex flex-row w-100 br3 zx-bg-charcoal-grey`}
        >
          <div className="pv2">
            <div className="ph2 b br b--white h-100">
              <p>Days</p>
              {started ? <H4>{roundedDaysSinceStart}</H4> : "-"}
              <p>
                {started ? "Since" : "Starts"} {startDate.format("YYYY-MM-DD")}
              </p>
            </div>
          </div>
          <div className="pv2">
            <div className="ph2 b br b--white h-100">
              <p>Patients</p>
              <H4>{`${study.patients ? study.patients.length : 0}`}</H4>
            </div>
          </div>
          <div className="pv2">
            <div className="ph2 b br b--white h-100">
              <p>Visits</p>-
            </div>
          </div>
          <div className="pv2">
            <div className="ph2 b br b--white h-100">
              <p>Algorithms</p>
              {`${study.algorithms ? study.algorithms.length : 0}`}
            </div>
          </div>
        </section>
        <section className="pt2 flex flex-auto">
          {this.renderVisitsSection(study)}
          {canExecuteAlgorithms && this.renderAlgoSection(study)}
        </section>
      </section>
    );
  }

  private renderAlgoSection = (study: Study) => {
    const { selectedAlgorithm } = this.state;
    const handleClose = () => {
      // TODO: Process running the algorithm on this visit
      this.setState({ selectedAlgorithm: undefined });
    };

    return (
      <section className="flex flex-column pa2 w-30">
        <RunAlgorithmDialog
          algorithm={selectedAlgorithm}
          onClose={handleClose}
          study={study}
        />
        <div className="flex flex-row zx-blue b bb b--gray">
          <Icon className="ph1" icon={IconNames.LAYERS} />
          <H5>Algorithms</H5>
        </div>
        {study.algorithms.map(this.renderAlgoRow)}
      </section>
    );
  };

  private renderAlgoRow = (algo: Algorithm, index: number) => {
    const setAlgo = () => this.setState({ selectedAlgorithm: algo });

    return (
      <div
        className="flex pa1 justify-between zx-blue b bb b--gray pointer"
        key={index}
        onClick={setAlgo}
      >
        <div>{algo.title}</div>
        <Icon icon={IconNames.CARET_RIGHT} />
      </div>
    );
  };

  private renderVisitsSection = (study: Study) => {
    const { patientForNewVisit } = this.state;
    const handleSelect = (patient: Patient) => {
      this.setState({ patientForNewVisit: patient });
    };

    const handleClose = () => {
      this.setState({ patientForNewVisit: undefined });
    };

    return (
      <section className="flex flex-column flex-auto bg-white pa3 br3 zx-shadow">
        <NewVisitDialog
          patient={patientForNewVisit}
          onClose={handleClose}
          study={study}
        />
        <div className="flex flex-row justify-between w-100">
          <H5>Visits</H5>
          <Popover
            className={Classes.DARK}
            position={PopoverPosition.BOTTOM_RIGHT}
            minimal={true}
          >
            <Button text="New Visit" rightIcon={IconNames.ADD} />
            <PatientNameSelect onChange={handleSelect} study={study} />
          </Popover>
        </div>
        {this.renderVisits(study)}
      </section>
    );
  };

  private renderVisits = (study: Study) => {
    const visits = study.visits || [];
    return (
      <section>
        <section className="w-100 flex b bb b--light-gray pv2 pb1 mb1">
          <div className="fl w-60 pl1 pv1 zx-blue">Name</div>
          <div className="fl w-20 pl1 pv1 zx-blue">Date</div>
          <div className="fl w-20 pl1 pv1 zx-blue">Data Input</div>
        </section>
        {visits.map(this.visitRenderer)}
      </section>
    );
  };

  private visitRenderer = (visit: Visit) => {
    const dataComplete =
      visit.detailsJson && Object.keys(visit.detailsJson).length > 0;
    return (
      <section
        className="w-100 flex b bb b--light-gray zx-charcoal-grey"
        key={visit.id}
      >
        <div className="fl w-60 pa1">{this.renderPatient(visit.patientId)}</div>
        <div className="fl w-20 pa1">
          <p>{moment(visit.visitDate).format("YYYY-MM-DD")}</p>
        </div>
        <div className={`fl w20 pa1 ${dataComplete ? "" : "zx-orange"}`}>
          <p>{dataComplete ? "Complete" : "Incomplete"}</p>
        </div>
      </section>
    );
  };

  private renderPatient = (patientId: string) => {
    const patient = this.props.patients[patientId];
    if (!patient) {
      this.props.retrievePatient(patientId);
      return (
        <div>
          <Spinner size={Spinner.SIZE_SMALL} />
        </div>
      );
    }

    return (
      <div className="flex w-100 flex-column">
        <div className="zx-blue">
          <H5 style={{ color: "var(--app-blue)" }}>{fullName(patient)}</H5>
        </div>
        <p>{patient.notes || "No patient notes"}</p>
      </div>
    );
  };
}

const mapStateToProps = (
  { studiesStore, uiStore, userStore }: IStoreState,
  props: IStudyDashboardProps
) => {
  const studyId = props.match.params.studyId;
  const study = studiesStore.studies[studyId];
  const { loggedInUser } = userStore;

  const studyUsers = study && study.splitStudyUsersByRole();
  const canExecuteAlgorithms =
    (studyUsers &&
      loggedInUser &&
      (studyUsers.studyAdmins.find(u => u.id === loggedInUser.id) ||
        studyUsers.viewOnlyUsers.find(u => u.id === loggedInUser.id)) !==
        undefined) ||
    false;

  return {
    canExecuteAlgorithms,
    error: uiStore.error,
    loggedInUser,
    patients: studiesStore.patients,
    study
  };
};

export const StudyDashboard = withRouter(
  connect(mapStateToProps, { retrievePatient })(StudyDashboardComponent)
);
