import { Button, MenuItem } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import { ItemRenderer, Select } from "@blueprintjs/select";
import * as React from "react";

import {
  AlgoVariableType,
  kSelectableVariableTypes,
  textForAlgoVariableType
} from "src/api";

export interface IVariableTypeSelectProps {
  className?: string;
  disabled?: boolean;
  onChange?: (value: AlgoVariableType) => void;
  value?: AlgoVariableType;
}

const TypeSelect = Select.ofType<AlgoVariableType>();

export class VariableTypeSelect extends React.PureComponent<
  IVariableTypeSelectProps
> {
  public render() {
    const { disabled, value } = this.props;
    return (
      <div className={`${this.props.className}`}>
        <TypeSelect
          className="flex flex-row flex-auto"
          disabled={disabled}
          filterable={false}
          popoverProps={{ minimal: true }}
          items={kSelectableVariableTypes}
          itemRenderer={this.itemRenderer}
          onItemSelect={this.selectionHandler}
          inputProps={{ disabled }}
        >
          <Button
            fill={true}
            alignText={"left"}
            text={textForAlgoVariableType(value || kSelectableVariableTypes[0])}
            rightIcon={IconNames.CARET_DOWN}
          />
        </TypeSelect>
      </div>
    );
  }

  private itemRenderer: ItemRenderer<AlgoVariableType> = (
    value,
    { handleClick, modifiers: { disabled } }
  ) => {
    return (
      <MenuItem
        disabled={disabled}
        key={value}
        onClick={handleClick}
        text={textForAlgoVariableType(value)}
      />
    );
  };

  private selectionHandler = (value: AlgoVariableType) => {
    const { onChange } = this.props;
    if (onChange) {
      onChange(value);
    }
  };
}
