import { Intent, Position, Toaster } from "@blueprintjs/core";
import { push } from "connected-react-router";
import * as firebase from "firebase/app";
import "firebase/messaging";
import * as React from "react";
import { connect } from "react-redux";

import { IconNames } from "@blueprintjs/icons";
import {
  clearAlgoError,
  clearUserError,
  clearUsersError,
  setUiError
} from "src/actions";
import { IStoreState } from "src/store";

interface INotificationToasterProps {
  position?: Position;
  userError?: Error;
  usersError?: Error;
  algoError?: Error;
  clearAlgoError: typeof clearAlgoError;
  clearUserError: typeof clearUserError;
  clearUsersError: typeof clearUsersError;
  setUiError: typeof setUiError;
  push: typeof push;
}

class NotificationToasterComponent extends React.PureComponent<
  INotificationToasterProps
> {
  private toaster: Toaster | undefined;
  private refHandlers = {
    toaster: (ref: Toaster) => (this.toaster = ref)
  };

  constructor(props: INotificationToasterProps) {
    super(props);
    this.state = {
      toasts: []
    };
  }

  public componentDidMount() {
    try {
      if (firebase.messaging.isSupported()) {
        firebase.messaging().onMessage(m => {
          // tslint:disable-next-line:no-console
          console.log(m);
          this.addNotificationToast(m.notification.body);
        });
      }
    } catch (e) {
      // tslint:disable-next-line:no-console
      console.error(e);
    }
  }

  public componentDidUpdate(oldProps: INotificationToasterProps) {
    const { userError, algoError, usersError } = this.props;

    if (userError && !oldProps.userError) {
      this.addErrorToast(userError);
    }

    if (usersError && !oldProps.usersError) {
      this.addErrorToast(usersError);
    }

    if (algoError && !oldProps.algoError) {
      this.addErrorToast(algoError);
    }

    if (userError) {
      this.props.clearUserError();
      this.props.setUiError(userError);
    }

    if (usersError) {
      this.props.clearUsersError();
      this.props.setUiError(usersError);
    }

    if (algoError) {
      this.props.clearAlgoError();
      this.props.setUiError(algoError);
    }
  }

  public render() {
    return (
      <React.Fragment>
        <Toaster position={Position.TOP_RIGHT} ref={this.refHandlers.toaster} />
      </React.Fragment>
    );
  }

  private addErrorToast = (error: Error) => {
    if (this.toaster && error.message.length > 0) {
      // Do not display duplicates
      const toasts = this.toaster.getToasts();
      if (toasts.length > 0) {
        const previousMessage = toasts[toasts.length - 1].message;
        if (previousMessage === error.message) {
          return;
        }
      }

      this.toaster.show({
        icon: IconNames.WARNING_SIGN,
        intent: Intent.PRIMARY,
        message: error.message,
        onDismiss: this.handleDismiss,
        timeout: 10000
      });
    }
  };

  private addNotificationToast = (body: string, actionUrl?: string) => {
    if (this.toaster) {
      this.toaster.show({
        action: actionUrl ? { href: actionUrl, text: "Go!" } : undefined,
        icon: IconNames.INBOX_UPDATE,
        intent: Intent.SUCCESS,
        message: body,
        onDismiss: this.handleDismiss,
        timeout: 10000
      });
    }
  };

  private handleDismiss = () => {
    // Grab ui error for display?
  };
}

const mapStateToProps = ({
  algoStore,
  userStore,
  usersStore,
  parameterStore
}: IStoreState) => {
  return {
    algoError: algoStore.error,
    paramError: parameterStore.error,
    userError: userStore.error,
    usersError: usersStore.error
  };
};

export const NotificationToaster = connect(mapStateToProps, {
  clearAlgoError,
  clearUserError,
  clearUsersError,
  push,
  setUiError
})(NotificationToasterComponent);
