import { Classes, Button } from "@blueprintjs/core";
import * as React from "react";
import { connect } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router-dom";

import { logout } from "src/actions";
import { User } from "src/api";
import { IStoreState } from "src/store";

import logo from "src/resources/openxmed-logo-orange.svg";

interface IInsufficientAccessProps extends RouteComponentProps {
  user?: User;
  logout: typeof logout;
}

class InsufficientAccessComponent extends React.Component<
  IInsufficientAccessProps
> {
  public render() {
    const signOut = () => this.props.logout();

    return (
      <article className="flex-ns justify-between vh-100 pa3">
        <section className="vh-100-ns" style={{ flexShrink: 0 }}>
          <img
            alt="logo"
            className="ma1 mb3"
            src={logo}
            style={{ height: 30 }}
          />
        </section>
        {this.renderMiddleSection()}
        <section>
          <Button onClick={signOut} onKeyPress={signOut}>
            Sign out
          </Button>
        </section>
      </article>
    );
  }

  private renderMiddleSection = () => {
    return (
      <section className={`${Classes.DARK} pt4`}>
        <p>Thanks for signing up.</p>
        <p>
          To ensure the integrity of our physician-led community, we are in the
          process of verifying your details. Once this has been completed, you
          will be notified and granted access to the site. Expect to hear from
          us within 48 hours.
        </p>
        <p />
        <a href="https://www.openxmed.com">Openxmed Home</a>
      </section>
    );
  };
}

const mapStateToProps = ({ userStore }: IStoreState) => ({
  user: userStore.loggedInUser
});

export const InsufficientAccess = withRouter(
  connect(mapStateToProps, { logout })(InsufficientAccessComponent)
);
