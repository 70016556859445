import * as React from "react";
import { connect } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router-dom";

import { updateUser } from "src/actions";
import { User } from "src/api";
import { ConnectedPageTitle } from "src/components";
import { IStoreState } from "src/store";

import { UserProfile } from "./community";

export interface IProfileProps
  extends RouteComponentProps<{ userId?: string }> {
  busy: boolean;
}

interface IProfileInjectedProps {
  loggedInUser?: User;
  updateUser: typeof updateUser.request;
}

class ProfilePageComponent extends React.PureComponent<
  IProfileProps & IProfileInjectedProps
> {
  public render() {
    const { loggedInUser } = this.props;

    if (!loggedInUser) {
      return null;
    }

    const user = loggedInUser;

    return (
      <section
        id="userProfilePage"
        className="pa2 pv3 w-100 overflow-y-auto overflow-x-hidden animated fadeIn faster"
      >
        <ConnectedPageTitle />
        <section className="w-100 pa2 br3 zx-shadow bg-light-gray">
          <UserProfile
            // className="h-100 w-100 pa2"
            updateUser={this.props.updateUser}
            user={user}
          />
        </section>
      </section>
    );
  }
}

const mapStateToProps = ({ userStore }: IStoreState) => {
  return {
    loggedInUser: userStore.loggedInUser
  };
};

export const ProfilePage = withRouter(
  connect(mapStateToProps, {
    updateUser: updateUser.request
  })(ProfilePageComponent)
);
