import { Exclude } from "class-transformer";

import { IVariableUsage, Parameter } from "..";
import { AlgoNodeType } from "./algo-node";
import { varUsageString } from "./algo-variables";
import { BaseModel } from "./object-base";

export enum PathType {
  link = "LINK",
  contained = "CONTAINED"
}

export class AlgoNodePath extends BaseModel {
  public parentId: string;
  public childId: string | undefined;
  public displayOrder: number;
  public pathType: PathType = PathType.link;

  public low: number;
  public high: number;
  public targetAlgorithmId: string | null;
  public paramsJson?: IVariableUsage[] | null;

  constructor(
    parentId: string,
    childId: string | undefined,
    order = 0,
    low = -1,
    high = -1
  ) {
    super();

    this.parentId = parentId;
    this.childId = childId;
    this.displayOrder = order;

    this.low = low;
    this.high = high;
    this.targetAlgorithmId = null;
  }

  @Exclude()
  public equals(other?: AlgoNodePath) {
    if (
      !other ||
      this.parentId !== other.parentId ||
      this.childId !== other.childId ||
      this.low !== other.low ||
      this.high !== other.high ||
      this.displayOrder !== other.displayOrder
    ) {
      return false;
    }
    return true;
  }

  @Exclude()
  public title(parentType: AlgoNodeType, parameters: Parameter[]) {
    if (this.childId) {
      if (parentType === AlgoNodeType.multiSelect) {
        return `Output ${this.displayOrder + 1} - ${
          this.low === this.high
            ? `value of ${this.low}`
            : `values ${this.low} - ${this.high}`
        }`;
      } else {
        if (this.paramsJson) {
          return this.paramsJson
            .map(p => varUsageString(p, parameters))
            .join("\n");
        }
        return `Variable-controlled output ${this.displayOrder}`;
      }
    }
    return `New unconnected output ${this.displayOrder}`;
  }
}
