import { Type } from "class-transformer";

import { User } from "..";
import { BaseModel } from "./object-base";

export enum MediaFormats {
  jpeg = "image/jpeg",
  png = "image/png",
  mp4 = "video/mp4"
}

export class Media extends BaseModel {
  public title = "";
  public format: MediaFormats = MediaFormats.jpeg;

  public url = "";
  public micro = "";
  public thumb = "";

  @Type(() => User)
  public creator?: User;
}
