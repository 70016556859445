import { Button, H6, Spinner } from "@blueprintjs/core";
import { push } from "connected-react-router";
import { values } from "lodash";
import * as React from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { connect } from "react-redux";
import {
  Route,
  RouteComponentProps,
  Switch,
  withRouter
} from "react-router-dom";

import { getUsers, IUsersRequestData } from "src/actions";
import {
  fullName,
  kUserFetchSize,
  RolePriviledge,
  sortUsersByLastName,
  User,
  userIsAdmin,
  userMatchFilter,
  userRoleString
} from "src/api";
import { ConnectedPageTitle } from "src/components";
import { kUserSearchUrlKey, kUsersRootUrl, kVerify } from "src/config/routes";
import { IStoreState } from "src/store";
import { dateRenderer } from "src/utilities";
import { UserAdmin, UserVerification } from ".";
import { UserVerificationDetails } from "./user-verification-details";

interface IUserAdminPageProps extends RouteComponentProps<{ query?: string }> {
  adminUser: boolean;
  busy: boolean;
  error?: Error;
  getUsers: typeof getUsers.request;
  loggedInUser?: User;
  moreAvailable: boolean;
  offset: number;
  push: typeof push;
  users: User[];
}

class UserAdminPageComponent extends React.PureComponent<IUserAdminPageProps> {
  public render() {
    const { busy, users } = this.props;
    return (
      <section
        id="userAdminPage"
        className="pa2 w-100 mv2 overflow-y-auto overflow-x-hidden animated fadeIn faster"
      >
        {busy && users.length === 0 ? <Spinner /> : this.renderContent()}
      </section>
    );
  }

  private renderContent = () => {
    const {
      adminUser,
      users,
      loggedInUser,
      moreAvailable,
      match: {
        params: { query }
      }
    } = this.props;
    const hasVerifyPriv =
      loggedInUser &&
      loggedInUser.systemRoles &&
      loggedInUser.systemRoles.includes(RolePriviledge.AlterUser);

    const userVerifyRoute = (
      <Route
        path={`${kUsersRootUrl}/${kVerify}/:userId`}
        component={UserVerificationDetails}
      />
    );
    const userAdminRoute = (
      <Route path={`${kUsersRootUrl}/:userId`} component={UserAdmin} />
    );

    return (
      <section>
        <Switch>
          <Route path={`${kUserSearchUrlKey}/:query`}>
            {this.renderUsers(users, loggedInUser, moreAvailable, query)}
          </Route>
          {(adminUser || hasVerifyPriv) && userVerifyRoute}
          {adminUser && userAdminRoute}
          <Route path={`${kUsersRootUrl}`} render={this.renderUserLists} />
        </Switch>
      </section>
    );
  };

  private renderUserLists = () => {
    const { adminUser, users, loggedInUser, moreAvailable } = this.props;
    return (
      <section>
        <ConnectedPageTitle />
        <UserVerification />
        {adminUser && this.renderUsers(users, loggedInUser, moreAvailable)}
      </section>
    );
  };

  private renderUsers = (
    users: User[],
    loggedInUser: User | undefined,
    more: boolean,
    query?: string
  ) => {
    const props = this.props;

    const getNext = () => {
      const { offset } = props;
      props.getUsers({ skip: offset, take: kUserFetchSize });
    };

    const userRenderer = () =>
      users
        .filter(u => (query ? userMatchFilter(u, query) : true))
        .map(u => this.renderRow(u, loggedInUser));

    const loadMoreButton = (
      <Button className="fr" text="Load more..." onClick={getNext} />
    );

    return (
      <section className={"mt2 animated fadeIn faster bg-white br3 w-100"}>
        <section className="w-100 flex b bb b--light-gray pa2 pb1 mb1">
          <div className="fl w-60 pa1">Name</div>
          <div className="fl w-20 pa1">Role</div>
          <div className="fl w-20 pa1">Since</div>
        </section>
        <section>
          <InfiniteScroll
            hasMore={more}
            dataLength={users.length}
            next={getNext}
            loader={<Spinner />}
            scrollableTarget="userAdminPage"
          >
            {userRenderer()}
          </InfiniteScroll>
          {!query && more && loadMoreButton}
        </section>
      </section>
    );
  };

  private renderRow = (u: User, operator?: User) => {
    const adminUser = userIsAdmin(operator);
    const handleClick = () => {
      if (adminUser) {
        this.props.push(`${kUsersRootUrl}/${u.id}`);
      }
    };

    return (
      <div
        id={u.id}
        key={u.id}
        className={`${adminUser ? "pointer" : ""} flex ph2`}
        onClick={adminUser ? handleClick : undefined}
      >
        <div className="fl w-60 pa1">
          <H6 className="ttc zx-link-colour" style={{ marginBottom: 2 }}>
            {fullName(u)}
          </H6>
          <p>{u.email}</p>
        </div>
        <div className="fl w-20 pa1">{userRoleString(u.role)}</div>
        <div className="fl w-20 pa1">{dateRenderer(u.createdAt)}</div>
      </div>
    );
  };
}

const mapStateToProps = ({ userStore, usersStore, uiStore }: IStoreState) => {
  const allUsers = sortUsersByLastName(values(usersStore.allUsers));

  return {
    adminUser: userIsAdmin(userStore.loggedInUser),
    busy: uiStore.community.contributorsLoadersCount > 0,
    loggedInUser: userStore.loggedInUser,
    moreAvailable: usersStore.moreAvailable,
    offset: usersStore.fetchOffset,
    users: allUsers
  };
};

export const UserAdministration = withRouter(
  connect(mapStateToProps, {
    getUsers: (params: IUsersRequestData) => getUsers.request(params),
    push
  })(UserAdminPageComponent)
);
