import { Button, Classes, Intent, Label } from "@blueprintjs/core";
import * as React from "react";
import {
  Field,
  Form,
  FormErrors,
  formValues,
  InjectedFormProps,
  reduxForm,
  WrappedFieldProps
} from "redux-form";

export interface IAuthData {
  email: string;
  password: string;
}

export interface ILoginFormProps {
  errorMessage?: string;
  handleForgotPassword: () => void;
}

interface IRenderFieldProps {
  placeholder: string;
  type: string;
}

const renderField: React.SFC<IRenderFieldProps & WrappedFieldProps> = props => {
  const {
    input,
    type,
    placeholder,
    meta: { touched, error }
  } = props;
  const className = `${Classes.INPUT} ${
    touched && error ? Classes.INTENT_DANGER : ""
  }`;

  return (
    <Label className="white">
      <span className="ttc">{placeholder}</span>
      <div>
        <input
          style={{ width: "100%" }}
          className={className}
          type={type}
          placeholder={placeholder}
          {...input}
        />
        {touched && error && <p className="pt2 red">{error}</p>}
      </div>
    </Label>
  );
};

const Login: React.SFC<InjectedFormProps<IAuthData, ILoginFormProps> &
  ILoginFormProps> = props => {
  const { errorMessage, handleSubmit, handleForgotPassword } = props;

  const onForgotPassword = () => {
    formValues(props.form);
    handleForgotPassword();
  };
  const errorMessageRendered = (
    <span className="mt2 orange">{errorMessage}</span>
  );

  return (
    <Form onSubmit={handleSubmit}>
      <Field
        name="email"
        component={renderField}
        placeholder={"Email address"}
        type="email"
      />
      <Field
        name="password"
        component={renderField}
        placeholder={"Password"}
        type="password"
      />
      {errorMessage && errorMessage.length > 0 && errorMessageRendered}
      <section className="mt3">
        <Button
          className="mr2"
          text="Sign In"
          intent={Intent.PRIMARY}
          type="submit"
        />
        <Button
          className="zx-bp3-button-clear"
          text="Forgot Password"
          intent={Intent.NONE}
          type="button"
          onClick={onForgotPassword}
        />
      </section>
    </Form>
  );
};

const validation = (values: Readonly<IAuthData>): FormErrors<IAuthData> => {
  const errors: FormErrors<IAuthData> = {}; // empty errors = form good
  const emailError =
    !values.email ||
    (values.email &&
      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]+$/i.test(values.email))
      ? "Invalid email address"
      : undefined;
  errors.email = emailError;
  if (!values.password || values.password.length < 8) {
    const passwordError = "Password should be at least 8 characters";
    errors.password = passwordError;
  }
  return errors;
};

export const LoginForm = reduxForm<IAuthData, ILoginFormProps>({
  form: "login",
  validate: validation
})(Login);

export default LoginForm;
