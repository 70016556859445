import { Button, ButtonGroup } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import { cloneDeep } from "lodash";
import * as React from "react";

import { updatePath, linkNodes } from "src/actions";
import {
  AlgoNode,
  AlgoNodeType,
  Algorithm,
  IAlgoNodeTarget,
  NodeActionType,
  notEmpty,
  VariableOperator
} from "src/api";
import { IOpenAlgorithm } from "src/store";
import { AlgoNodeSelect, NodeActionTypeSelect } from ".";
import { AlgorithmSelect } from "..";

interface INodeTargetRendererProps {
  openAlgorithm: IOpenAlgorithm;
  otherAlgorithms: Algorithm[];
  target?: IAlgoNodeTarget;
  parent: AlgoNode;
  linkNodes: typeof linkNodes;
  updatePath: typeof updatePath;
}

interface INodeTargetRendererState {
  outputAction: NodeActionType;
  variableOperator: VariableOperator;
}

export class NodeTargetRenderer extends React.PureComponent<
  INodeTargetRendererProps,
  INodeTargetRendererState
> {
  constructor(props: INodeTargetRendererProps) {
    super(props);

    const { target } = props;
    let path;
    if (target) {
      path = target.path;
    }

    const outputInitialAction =
      path && path.targetAlgorithmId
        ? NodeActionType.goToAlgorithm
        : NodeActionType.goToNode;

    this.state = {
      outputAction: outputInitialAction,
      variableOperator:
        path && path.high === path.low
          ? VariableOperator.isEqualTo
          : VariableOperator.between
    };
  }

  public render() {
    const { openAlgorithm, otherAlgorithms, parent, target } = this.props;
    const { outputAction } = this.state;
    const {
      algorithm: { localVars, variables }
    } = openAlgorithm;
    const algoVars = localVars
      ? [...variables, ...Object.values(localVars)]
      : variables;

    let pathVars = [];
    if (target) {
      pathVars = target.path.paramsJson || [];
    }

    const excluded = [parent.id].filter(notEmpty);
    const handleTargetChange = (value: AlgoNode) => {
      this.createOrUpdatePath({ newTargetId: value.id });
    };

    const handleActionChange = (actionType: NodeActionType) => {
      this.setState({ outputAction: actionType });
    };

    const handleAlgoChange = (algorithm: Algorithm) => {
      this.createOrUpdatePath({
        otherAlgoId: algorithm.id
      });
    };

    const targetAlgo = target
      ? target.path.targetAlgorithmId
        ? otherAlgorithms.find(a => a.id === target.path.targetAlgorithmId)
        : undefined
      : undefined;

    const selectTargetRenderer =
      outputAction === NodeActionType.goToNode ? (
        <AlgoNodeSelect
          className="flex"
          onChange={handleTargetChange}
          openAlgorithm={openAlgorithm}
          nodeIdsToExclude={excluded}
          value={target && target.node}
        />
      ) : (
        <AlgorithmSelect
          localAlgos={otherAlgorithms}
          onChange={handleAlgoChange}
          value={targetAlgo}
        />
      );

    return (
      <div className="flex flex-row justify-between items-start pb2">
        <div className="flex flex-row flex-auto">
          <NodeActionTypeSelect
            className="pr1"
            value={outputAction}
            nodeType={AlgoNodeType.singleSelect}
            onChange={handleActionChange}
            variableActions={algoVars.length + pathVars.length > 0}
          />
          <div className="flex flex-column flex-auto ">
            {selectTargetRenderer}
          </div>
        </div>
        <ButtonGroup style={{ visibility: "hidden" }}>
          <Button icon={IconNames.REMOVE} minimal={true} />
        </ButtonGroup>
      </div>
    );
  }

  private createOrUpdatePath = (details: {
    newTargetId?: string;
    otherAlgoId?: string;
  }) => {
    const {
      target,
      openAlgorithm: { algorithm },
      parent
    } = this.props;
    const { otherAlgoId, newTargetId } = details;

    if (!target) {
      const childId = newTargetId;

      this.props.linkNodes({
        algoId: algorithm.id,
        childId,
        otherAlgoId,
        parentId: parent.id,
        pathId: undefined
      });
      return;
    }

    const updatedPath = cloneDeep(target.path);
    if (newTargetId) {
      updatedPath.childId = newTargetId;
      updatedPath.targetAlgorithmId = null;
    }

    if (otherAlgoId) {
      updatedPath.targetAlgorithmId = otherAlgoId;
      updatedPath.childId = undefined;
    }

    this.props.updatePath({
      algoId: algorithm.id,
      otherAlgoId,
      path: updatedPath
    });
  };
}
