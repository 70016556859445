import moment from "moment";
import * as React from "react";

import { Algorithm, Comment } from "src/api";

interface IAlgoNotesProps {
  algo: Algorithm;
  className?: string;
}

const renderComment = (comment: Comment, index: number) => {
  return (
    <div className="pa1" key={index}>
      <span>{moment(comment.createdAt).fromNow()}: </span> {comment.content}
    </div>
  );
};

export const AlgoNotes: React.SFC<IAlgoNotesProps> = ({
  algo: { comments },
  className
}: IAlgoNotesProps) => {
  return <section className={className}>{comments.map(renderComment)}</section>;
};
