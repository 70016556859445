import { Button, Icon, MenuItem } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import { ItemPredicate, ItemRenderer, Select } from "@blueprintjs/select";
import * as React from "react";

import { AlgoNode, kConnectableNodeTypes } from "src/api";
import { IOpenAlgorithm } from "src/store";
import {
  highlightText,
  nodeTitleTextPlain,
  sortAlphabetically
} from "src/utilities";

export interface IAlgoNodeSelectProps {
  className?: string;
  disabled?: boolean;
  onChange?: (value: AlgoNode) => void;
  openAlgorithm: IOpenAlgorithm;
  value?: AlgoNode;
  nodeIdsToExclude?: string[]; // Array of node ids
}

const NodeSelect = Select.ofType<AlgoNode>();

export class AlgoNodeSelect extends React.PureComponent<IAlgoNodeSelectProps> {
  public render() {
    const { disabled, openAlgorithm, value, nodeIdsToExclude } = this.props;
    const items = Object.values(openAlgorithm.algoNodes)
      .filter(n => kConnectableNodeTypes.includes(n.kind))
      .filter(it => {
        if (nodeIdsToExclude && nodeIdsToExclude.includes(it.id)) {
          return false;
        }
        return true;
      })
      .filter(n => !n.isContained())
      .sort((a, b) =>
        sortAlphabetically(nodeTitleTextPlain(a), nodeTitleTextPlain(b))
      );

    const popoverProps = {
      minimal: true,
      popoverClassName: "zx-scrolling-popover"
    };

    return (
      <div className={`${this.props.className}`}>
        <NodeSelect
          className="flex flex-row flex-auto"
          disabled={disabled}
          popoverProps={popoverProps}
          itemPredicate={this.filterNodes}
          items={items}
          itemRenderer={this.itemRenderer}
          onItemSelect={this.selectionHandler}
          inputProps={{ disabled }}
        >
          <Button
            alignText={"left"}
            fill={true}
            text={value ? nodeTitleTextPlain(value) : "Select Node"}
            rightIcon={IconNames.CARET_DOWN}
          />
        </NodeSelect>
      </div>
    );
  }

  private filterNodes: ItemPredicate<AlgoNode> = (
    query,
    node,
    index,
    exactMatch
  ) => {
    const normalizedTitle = node.title.toLowerCase();
    const normalizedQuery = query.toLowerCase();

    if (exactMatch) {
      return normalizedTitle === normalizedQuery;
    } else {
      return (
        nodeTitleTextPlain(node)
          .toLowerCase()
          .indexOf(normalizedQuery) >= 0
      );
    }
  };

  private itemRenderer: ItemRenderer<AlgoNode> = (
    value,
    { handleClick, modifiers: { disabled }, query }
  ) => {
    const { value: selectedValue } = this.props;
    const textRenderer = () => {
      return (
        <div>
          <Icon icon={value && value.icon()} />
          {highlightText(nodeTitleTextPlain(value), query)}
        </div>
      );
    };
    const icon =
      selectedValue && value.id === selectedValue.id
        ? IconNames.TICK
        : IconNames.BLANK;

    return (
      <MenuItem
        style={{ maxWidth: 400 }}
        disabled={disabled}
        icon={icon}
        key={value.id}
        onClick={handleClick}
        text={textRenderer()}
      />
    );
  };

  private selectionHandler = (value: AlgoNode) => {
    const { onChange } = this.props;
    if (onChange) {
      onChange(value);
    }
  };
}
