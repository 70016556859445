import * as Sentry from "@sentry/browser";
import { classToPlain, serialize } from "class-transformer";
import * as firebase from "firebase/app";
import "firebase/auth";
import { getType } from "typesafe-actions";

import { cloneDeep, omit } from "lodash";
import {
  createAlgorithm,
  createBackendComment,
  createBackendNode,
  createBackendPath,
  createParameter,
  createParameterUnit,
  createStudy,
  deleteAlgorithm,
  deleteBackendComment,
  deleteBackendNode,
  deleteBackendPath,
  deleteParameter,
  deleteParameterUnit,
  deleteStudy,
  getAlgorithm,
  getAlgorithmSearchResults,
  getAlgosByTopic,
  getCommentsForAlgorithm,
  getMyAlgos,
  getSelf,
  getTopics,
  getUser,
  getUsers,
  postUserAvatar,
  ReduxAction,
  registerPushToken,
  registerUser,
  retrieveAlgoList,
  retrieveEvents,
  retrieveParameters,
  retrieveStudies,
  retrieveUnits,
  saveAlgoState,
  searchAlgoStarts,
  searchLabels,
  setUserRole,
  updateAlgorithmStatus,
  updateBackendAlgorithm,
  updateBackendComment,
  updateBackendNode,
  updateBackendPath,
  updateParameter,
  updateParameterUnit,
  updateStudy,
  updateUser,
  setNodeImage,
  searchPatients,
  createPatient,
  assignPatientToStudy,
  removePatientFromStudy,
  searchStudyPatients,
  createStudyVisit,
  getPatient,
  getAlgorithmRevisions,
  setUserFavouriteAlgorithms,
  getUserFavouriteAlgorithms
} from "src/actions";
import {
  AlgoNode,
  Comment,
  CommentKind,
  kAlgoNodeKeyOmissions,
  stripFieldsForStudy,
  Study,
  stripFieldsForPatient,
  stripFieldsForVisit
} from "src/api";
import {
  createAlgorithmConfig,
  getAlgorithmParams,
  getTopicListParams,
  retrieveAlgoListParams,
  saveAlgoStateParams
} from "./algorithm-endpoints";
import { AlgoNodePath } from "./model/algo-node-path";
import { Algorithm, kAlgoKeyOmissions } from "./model/algorithm";
import { CommentRefType } from "./model/comment";
import { Parameter } from "./model/parameter";
import { ParameterUnit } from "./model/parameter-unit";
import { stripFieldsForUser, UserRole, User } from "./model/user";
import {
  getEventParams,
  getUsersParams,
  mapRegistrationData
} from "./user-endpoints";

// API definitions
export const serverRoot =
  process.env.NODE_ENV === "development"
    ? "http://localhost:8080"
    : "https://api.openxmed.com";
export const webSocketUrl =
  process.env.NODE_ENV === "development"
    ? "ws://localhost:8079/socket"
    : "wss://api.openxmed.com:443/socket";
export const kReCaptchaKey = "6Lev-H0UAAAAADKfWUETd0Kig9AfDIIQU0AXuPXU";

export const kFromAlgo = "fromAlgo";
export const kUsers = "users";
export const myProfile = `/${kUsers}/self`;
export const kAlgorithms = "algorithms";
export const kRevisions = "revisions";
export const kTopics = "topics";
export const kByTopic = "bytopic";
export const kByUser = "byuser";
export const kAlgorithmSearch = "algorithms/search";
export const kHighlightStart = "<zx-h>";
export const kHighlightEnd = "</zx-h>";
export const kHighlightStartRegEx = RegExp(kHighlightStart, "g");
export const kHighlightEndRegEx = RegExp(kHighlightEnd, "g");
export const kAlgorithmCloneKey = "clone";
export const kAlgorithmOverwriteKey = "overwrite";
export const kAlgoFavourites = "favourite-algos";
export const kLabels = "labels";
export const kNodes = "nodes";
export const kPaths = "paths";
export const kEvents = "events";
export const kComments = "comments";
export const kDevices = "devices";
export const kModerations = "moderations";
export const kParameters = "parameters";
export const kUnits = "units";
export const kStudies = "studies";
export const kVisits = "visits";
export const kPatients = "patients";
export const kFirebaseHandler = "firebase";
export const kReCaptchaHandler = "public/recaptcha";

export const kTeleportApiSearch = "https://api.teleport.org/api/cities";

export const kUserFetchSize = 50;
export const kAlgosFetchSize = 50;

export interface IFetchParams {
  url: string;
  config: RequestInit;
}

function headers(contentIsJson: boolean, authToken?: string): Headers {
  let options = {};
  if (contentIsJson) {
    options = {
      Accept: "application/json",
      "Content-Type": "application/json"
    };
  }

  if (authToken && authToken.length > 0) {
    options = Object.assign(options, { Authorization: `Bearer ${authToken}` });
  }
  return new Headers(options);
}

function defaultParams(authToken?: string): {} {
  return {
    cache: "default",
    credentials: "same-origin",
    headers: headers(true, authToken),
    mode: "cors"
  };
}

// Although not strictly constants, this serves as a temporary home for the
// network config objects
export function getRequestInit(authToken?: string): RequestInit {
  return {
    ...defaultParams(authToken),
    method: "GET"
  };
}

export function postMultipartRequestInit(
  body: FormData,
  authToken?: string
): RequestInit {
  return {
    body,
    cache: "default",
    credentials: "same-origin",
    headers: headers(false, authToken),
    method: "POST",
    mode: "cors"
  };
}

export function postRequestInit(body: string, authToken?: string): RequestInit {
  return {
    ...defaultParams(authToken),
    body,
    method: "POST"
  };
}

export function putRequestInit(body: string, authToken?: string): RequestInit {
  return {
    ...defaultParams(authToken),
    body,
    method: "PUT"
  };
}

export function deleteRequestInit(authToken?: string): RequestInit {
  return {
    ...defaultParams(authToken),
    method: "DELETE"
  };
}

/**
 * Creates the correct configuration for the API based on the redux action
 * @param action Action to perform
 */
export const requestConfigForAction = (
  action: ReduxAction,
  token?: string,
  signal: AbortSignal | null = null
) => {
  switch (action.type) {
    case getType(getSelf.request):
      return {
        config: { ...getRequestInit(token), signal },
        url: `${serverRoot}${myProfile}`
      };

    case getType(registerPushToken.request):
      const body = {
        deviceId: action.payload.deviceId,
        pushId: action.payload.pushToken,
        type: navigator.appName
      };
      return {
        config: { ...postRequestInit(JSON.stringify(body), token), signal },
        url: `${serverRoot}/${kDevices}`
      };

    case getType(searchLabels.request):
      const { type, searchQuery } = action.payload;
      const baseUrl = `${serverRoot}/${kLabels}?category=${type}`;

      return {
        config: { ...getRequestInit(token), signal },
        url: searchQuery ? `${baseUrl}&title=${searchQuery}` : baseUrl
      };

    case getType(searchAlgoStarts.request):
      return {
        config: { ...getRequestInit(token), signal },
        url: `${serverRoot}/${kAlgorithms}/startNodeSearch?query=${action.payload}`
      };

    case getType(registerUser.request):
      const userToRegister = serialize(mapRegistrationData(action.payload));
      return {
        config: { ...postRequestInit(userToRegister, token), signal },
        url: `${serverRoot}/${kUsers}`
      };

    case getType(createAlgorithm.request):
      return createAlgorithmConfig(action, token, signal);

    case getType(getAlgorithmRevisions.request):
      return {
        config: { ...getRequestInit(token), signal },
        url: `${serverRoot}/${kAlgorithms}/${action.payload}/${kRevisions}`
      };

    case getType(updateAlgorithmStatus.request):
      const updateAlgoTypeBody = JSON.stringify({
        ...omit(
          classToPlain<Algorithm>(action.payload.algorithm),
          kAlgoKeyOmissions
        ),
        reason: action.payload.reason
      });

      return {
        config: { ...putRequestInit(updateAlgoTypeBody, token), signal },
        url: `${serverRoot}/${kAlgorithms}/${action.payload.algorithm.id}/status`
      };

    case getType(deleteAlgorithm.request):
      return {
        config: { ...deleteRequestInit(token), signal },
        url: `${serverRoot}/${kAlgorithms}/${action.payload}`
      };

    case getType(retrieveAlgoList.request):
      return { ...retrieveAlgoListParams(action, token), signal };

    case getType(getTopics.request):
      return { ...getTopicListParams(action, token), signal };

    case getType(getAlgosByTopic.request):
      return {
        config: { ...getRequestInit(token), signal },
        url: `${serverRoot}/${kAlgorithms}/${kByTopic}/${action.payload}`
      };

    case getType(getMyAlgos.request):
      return {
        config: { ...getRequestInit(token), signal },
        url: `${serverRoot}/${kAlgorithms}/${kByUser}/${action.payload}`
      };

    case getType(saveAlgoState.request):
      return saveAlgoStateParams(action, token, signal);

    case getType(getUsers.request):
      return getUsersParams(action, token, signal);

    case getType(getUser.request):
      return {
        config: { ...getRequestInit(token), signal },
        url: `${serverRoot}/${kUsers}/${action.payload}`
      };

    case getType(setUserRole.request): {
      const updatedUser = serialize(stripFieldsForUser(action.payload));
      return {
        config: { ...putRequestInit(updatedUser, token), signal },
        url: `${serverRoot}/${kUsers}/${action.payload.id}/role`
      };
    }

    case getType(updateUser.request): {
      const updatedUser = serialize(stripFieldsForUser(action.payload));
      return {
        config: { ...putRequestInit(updatedUser, token), signal },
        url: `${serverRoot}/${kUsers}/${action.payload.id}`
      };
    }

    case getType(retrieveEvents.request):
      return getEventParams(action, token, signal);

    case getType(getAlgorithm.request):
      return getAlgorithmParams(action, token, signal);

    case getType(getCommentsForAlgorithm.request):
      return {
        config: { ...getRequestInit(token), signal },
        url: `${serverRoot}/${kAlgorithms}/${action.payload.algorithmId}/${kComments}?kind=${CommentKind.Comment}&include=nodes`
      };

    case getType(createBackendComment.request): {
      const commentObj = classToPlain<Comment>(action.payload.comment);
      const json = JSON.stringify(commentObj);
      let url = `${serverRoot}/${kNodes}/${action.payload.comment.nodeId}/${kComments}`;

      switch (action.payload.comment.linkedRefType) {
        case CommentRefType.Comment:
          url = `${serverRoot}/${kComments}/${action.payload.comment.linkedRefId}/${kComments}`;
          break;

        case CommentRefType.Algorithm:
          url = `${serverRoot}/${kAlgorithms}/${action.payload.comment.algorithmId}/${kComments}`;
          break;
      }

      return {
        config: { ...postRequestInit(json, token), signal },
        url
      };
    }

    case getType(updateBackendComment.request): {
      const commentObj = classToPlain<Comment>(action.payload.comment);
      const json = JSON.stringify(commentObj);

      return {
        config: { ...putRequestInit(json, token), signal },
        url: `${serverRoot}/${kComments}/${action.payload.comment.id}`
      };
    }

    case getType(deleteBackendComment.request):
      return {
        config: { ...deleteRequestInit(token), signal },
        url: `${serverRoot}/${kComments}/${action.payload.comment.id}`
      };

    case getType(getAlgorithmSearchResults.request):
      return {
        config: { ...getRequestInit(token), signal },
        url: `${serverRoot}/${kAlgorithmSearch}?query=${
          action.payload.query
        }&highlightStart=<zx-h>&highlightEnd=</zx-h>${
          action.payload.user.role !== UserRole.Administrator
            ? "&status=PUBLISHED"
            : ""
        }`
      };

    case getType(updateBackendAlgorithm.request):
      const prunedAlgo = cloneDeep(action.payload.algo);
      prunedAlgo.editors = action.payload.algo.editors.map(e => ({
        id: e.id
      })) as User[];
      prunedAlgo.authors = action.payload.algo.authors.map(a => ({
        id: a.id
      })) as User[];

      const updatedAlgo = omit(
        classToPlain<Algorithm>(prunedAlgo),
        kAlgoKeyOmissions
      );
      const updateAlgoBody = JSON.stringify(updatedAlgo);

      return {
        config: { ...putRequestInit(updateAlgoBody, token), signal },
        url: `${serverRoot}/${kAlgorithms}/${action.payload.algo.id}`
      };

    case getType(createBackendNode.request):
      const createNodeBody = JSON.stringify(
        omit(classToPlain<AlgoNode>(action.payload.node), kAlgoNodeKeyOmissions)
      );
      return {
        config: { ...postRequestInit(createNodeBody, token), signal },
        url: `${serverRoot}/${kAlgorithms}/${action.payload.node.algorithmId}/${kNodes}`
      };

    case getType(updateBackendNode.request):
      const updateNodeBody = JSON.stringify(
        omit(classToPlain<AlgoNode>(action.payload.node), kAlgoNodeKeyOmissions)
      );

      return {
        config: { ...putRequestInit(updateNodeBody, token), signal },
        url: `${serverRoot}/${kNodes}/${action.payload.node.id}`
      };

    case getType(deleteBackendNode.request):
      return {
        config: { ...deleteRequestInit(token), signal },
        url: `${serverRoot}/${kNodes}/${action.payload.node.id}`
      };

    case getType(createBackendPath.request):
      const createPathBody = JSON.stringify(
        classToPlain<AlgoNodePath>(action.payload.path)
      );

      return {
        config: { ...postRequestInit(createPathBody, token), signal },
        url: `${serverRoot}/${kNodes}/${action.payload.path.parentId}/${kPaths}`
      };

    case getType(updateBackendPath.request):
      const updatePathBody = JSON.stringify(
        classToPlain<AlgoNodePath>(action.payload.path)
      );

      return {
        config: { ...putRequestInit(updatePathBody, token), signal },
        url: `${serverRoot}/${kPaths}/${action.payload.path.id}`
      };

    case getType(deleteBackendPath.request):
      return {
        config: { ...deleteRequestInit(token), signal },
        url: `${serverRoot}/${kPaths}/${action.payload.path.id}`
      };

    case getType(postUserAvatar.request):
      const formData = new FormData();
      formData.append("profileImage", action.payload);
      return {
        config: { ...postMultipartRequestInit(formData, token), signal },
        url: `${serverRoot}/${kUsers}/avatar`
      };

    case getType(setNodeImage.request):
      const imageData = new FormData();
      imageData.append("nodeImage", action.payload.imageData);
      return {
        config: { ...postMultipartRequestInit(imageData, token), signal },
        url: `${serverRoot}/${kNodes}/${action.payload.nodeId}/image`
      };

    case getType(retrieveParameters.request): {
      let url = `${serverRoot}/${kParameters}`;
      if (action.payload.id) {
        url += `/${action.payload.id}`;
      } else if (action.payload.query) {
        url += `?query=${action.payload.query}`;
      }
      return {
        config: { ...getRequestInit(token), signal },
        url
      };
    }

    case getType(retrieveUnits.request): {
      let url = `${serverRoot}/${kUnits}`;
      if (action.payload.id) {
        url += `/${action.payload.id}`;
      } else if (action.payload.query) {
        url += `?query=${action.payload.query}`;
      }
      return {
        config: { ...getRequestInit(token), signal },
        url
      };
    }

    case getType(createParameter.request): {
      const url = `${serverRoot}/${kParameters}`;
      const paramBody = JSON.stringify(classToPlain<Parameter>(action.payload));
      return {
        config: { ...postRequestInit(paramBody, token), signal },
        url
      };
    }

    case getType(updateParameter.request): {
      const url = `${serverRoot}/${kParameters}/${action.payload.id}`;
      const paramBody = JSON.stringify(classToPlain<Parameter>(action.payload));
      return {
        config: { ...putRequestInit(paramBody, token), signal },
        url
      };
    }

    case getType(deleteParameter.request): {
      const url = `${serverRoot}/${kParameters}/${action.payload.parameter.id}`;
      return {
        config: { ...deleteRequestInit(token), signal },
        url
      };
    }

    case getType(createParameterUnit.request): {
      const url = `${serverRoot}/${kUnits}`;
      const unitBody = JSON.stringify(
        classToPlain<ParameterUnit>(action.payload)
      );
      return {
        config: { ...postRequestInit(unitBody, token), signal },
        url
      };
    }

    case getType(updateParameterUnit.request): {
      const url = `${serverRoot}/${kUnits}/${action.payload.id}`;
      const unitBody = JSON.stringify(
        classToPlain<ParameterUnit>(action.payload)
      );
      return {
        config: { ...putRequestInit(unitBody, token), signal },
        url
      };
    }

    case getType(deleteParameterUnit.request): {
      const url = `${serverRoot}/${kUnits}/${action.payload.unit.id}`;

      return {
        config: { ...deleteRequestInit(token), signal },
        url
      };
    }

    case getType(retrieveStudies.request): {
      let url = `${serverRoot}/${kStudies}`;

      if (action.payload.id) {
        url += `/${action.payload.id}`;
      }
      url += `?skip=${action.payload.skip}&take=${action.payload.take}`;

      if (action.payload.query) {
        url += `&query=${action.payload.query}`;
      }

      return {
        config: { ...getRequestInit(token), signal },
        url
      };
    }

    case getType(createStudy.request): {
      const url = `${serverRoot}/${kStudies}`;
      const studyBody = JSON.stringify(classToPlain<Study>(action.payload));
      return {
        config: { ...postRequestInit(studyBody, token), signal },
        url
      };
    }

    case getType(updateStudy.request): {
      const url = `${serverRoot}/${kStudies}/${action.payload.id}`;
      const studyBody = serialize(stripFieldsForStudy(action.payload));
      return {
        config: { ...putRequestInit(studyBody, token), signal },
        url
      };
    }

    case getType(deleteStudy.request): {
      const url = `${serverRoot}/${kStudies}/${action.payload.id}`;
      return {
        config: { ...deleteRequestInit(token), signal },
        url
      };
    }

    case getType(searchPatients.request): {
      const url = `${serverRoot}/${kPatients}/?healthIdQuery=${action.payload}`;
      return {
        config: { ...getRequestInit(token), signal },
        url
      };
    }

    case getType(createPatient.request): {
      const url = `${serverRoot}/${kPatients}`;
      const patientBody = serialize(stripFieldsForPatient(action.payload));
      return {
        config: { ...postRequestInit(patientBody, token), signal },
        url
      };
    }

    case getType(getPatient.request): {
      const url = `${serverRoot}/${kPatients}/${action.payload}`;
      return {
        config: { ...getRequestInit(token), signal },
        url
      };
    }

    case getType(assignPatientToStudy.request): {
      const url = `${serverRoot}/${kStudies}/${action.payload.studyId}/${kPatients}`;
      const assignBody = serialize(
        stripFieldsForPatient(action.payload.patient)
      );

      return {
        config: { ...postRequestInit(assignBody, token), signal },
        url
      };
    }

    case getType(removePatientFromStudy.request): {
      const url = `${serverRoot}/${kStudies}/${action.payload.studyId}/${kPatients}/${action.payload.patient.countryCode}/${action.payload.patient.id}`;

      return {
        config: { ...deleteRequestInit(token), signal },
        url
      };
    }

    case getType(searchStudyPatients.request): {
      const url = `${serverRoot}/${kStudies}/${action.payload.studyId}/${kPatients}/?nameQuery=${action.payload.nameQuery}`;
      return {
        config: { ...getRequestInit(token), signal },
        url
      };
    }

    case getType(createStudyVisit.request): {
      const url = `${serverRoot}/${kVisits}`;
      const visitBody = serialize(stripFieldsForVisit(action.payload));

      return {
        config: { ...postRequestInit(visitBody, token), signal },
        url
      };
    }

    case getType(getUserFavouriteAlgorithms.request): {
      return {
        config: { ...getRequestInit(token), signal },
        url: `${serverRoot}/${kUsers}/self/${kAlgoFavourites}`
      };
    }

    case getType(setUserFavouriteAlgorithms.request): {
      const favesBody = serialize(action.payload);

      return {
        config: { ...putRequestInit(favesBody, token), signal },
        url: `${serverRoot}/${kUsers}/self/${kAlgoFavourites}`
      };
    }

    default:
      throw new Error("API not configured!");
  }
};

export interface IRequestResponse {
  httpResponse: Response;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  apiResponse: any;
}

const fetchWithTimeout = (
  { url, config }: IFetchParams,
  timeout = process.env.NODE_ENV === "development" ? 50000 : 50000
) => {
  if (config.signal && config.signal.aborted) {
    return Promise.reject(new Error("AbortError"));
  }
  let signaller: AbortController | undefined;
  if (!config.signal) {
    signaller = new AbortController();
    config.signal = signaller.signal;
  }

  return Promise.race([
    fetch(url, config),
    new Promise<Response>((resolve, reject) =>
      setTimeout(() => {
        if (config.signal?.aborted) {
          // If it has timed out then return
          resolve();
          return;
        }
        reject(new Error("The network request timed out"));
        if (signaller) {
          signaller.abort();
        }
      }, timeout)
    )
  ]);
};

/**
 * Makes a call to the endpoint specified with the config included
 * @param url Url to call
 * @param config Request config to call with
 * @throws
 */
export const makeApiRequest = async ({
  url,
  config
}: IFetchParams): Promise<IRequestResponse> => {
  // Need a copy of the config in case of a token expiry
  const configCopy = cloneDeep(config);
  const request = fetchWithTimeout({ url, config });

  try {
    const httpResponse = await request;
    // Try to handle the error cases in general, else pass back to the caller
    if (httpResponse.status > 399) {
      const netError = new Error("Networking Error");
      netError.message = httpResponse.statusText;
      netError.name = httpResponse.status.toString();
      throw netError;
    }
    const json = await httpResponse.json();
    return Promise.resolve({ httpResponse, apiResponse: json });
  } catch (error) {
    const errorCode = error.name;

    const errorsToIgnore = ["401", "404", "AbortError"];
    if (!errorsToIgnore.includes(errorCode)) {
      // Log to sentry
      Sentry.withScope(scope => {
        scope.setTag("url", url);
        scope.setExtra("request", configCopy);
        Sentry.captureException(error);
      });
    }

    switch (errorCode) {
      case "401":
        const currentUser = firebase.auth().currentUser;
        if (currentUser !== null) {
          try {
            const newToken = await currentUser.getIdToken(true);
            let isJsonRequest = false;
            if (configCopy.headers) {
              const castHeaders = configCopy.headers as Headers;
              castHeaders.forEach(value => {
                if (value === "application/json") {
                  isJsonRequest = true;
                }
              });
            }
            configCopy.headers = headers(isJsonRequest, newToken);
          } catch (e) {
            throw e;
          }
          return makeApiRequest({ url, config: configCopy });
        } else {
          throw error;
        }

      case "TypeError":
        throw new Error(
          "Network request failed (the server could not be reached). Are you connected to the Internet?"
        );

      case "403":
        error.message = "Insufficient Permissions";
        throw error;

      case "404":
        // Pass the URL up to the next level for tracing.
        error.stack = url;
        throw error;

      case "409":
        error.message = "Already in use. Please try a different value.";
        error.stack = url;
        throw error;

      default:
        // tslint:disable-next-line:no-console
        console.error(error);
        throw error;
    }
  }
};
