import { Type } from "class-transformer";

import { Media, User } from "..";
import { BaseModel } from "./object-base";

export class ParameterUnit extends BaseModel {
  public name = "";
  public shortName = "";

  public avatar?: Media;
  @Type(() => User)
  public createdBy?: User;

  constructor(name?: string) {
    super();
    if (name) {
      this.name = name;
    }
  }
}
