import { ITagProps, MenuItem, Spinner } from "@blueprintjs/core";
import { ItemRenderer, MultiSelect } from "@blueprintjs/select";
import * as React from "react";
import { connect } from "react-redux";
import { WrappedFieldProps } from "redux-form";

import { getUsers } from "src/actions";
import {
  arrayFromPayload,
  fullName,
  Label,
  makeApiRequest,
  requestConfigForAction,
  User,
  userIsContributor,
  ZxUserType,
  IXmedObjectInfo
} from "src/api";
import { IStoreState } from "src/store";
import { highlightText } from "src/utilities";

export interface IUserMultiSelectProps extends WrappedFieldProps {
  className?: string;
  disabled?: boolean;
  exclude?: User[];
  multiSelect?: boolean;
  placeholder?: string;
  preventEmpty?: boolean;
  specialties?: Label[];
  type: ZxUserType;
}

interface IUserMultiSelectInjectedProps {
  token?: string;
  user?: User;
}

interface IUserMultiSelectState {
  abortController?: AbortController;
  error?: Error;
  items: User[];
  loading: boolean;
  placeholder: string;
  query: string;
  selectedItems: User[];
}
declare type UserMultiselectProps = IUserMultiSelectProps &
  IUserMultiSelectInjectedProps;

class UserMultiSelectComponent extends React.PureComponent<
  UserMultiselectProps,
  IUserMultiSelectState
> {
  private debouncer: NodeJS.Timeout | undefined;

  constructor(props: IUserMultiSelectProps) {
    super(props);

    this.state = {
      items: [],
      loading: false,
      placeholder: props.placeholder || this.defaultPlaceholder(),
      query: "",
      // TODO: we can probably remove state for selected items if always in a redux-form
      selectedItems: props.input.value ? props.input.value : []
    };
  }

  public render() {
    const {
      className,
      disabled,
      meta: { error },
      preventEmpty
    } = this.props;
    const { items, placeholder, query, selectedItems } = this.state;
    const lookupError = this.state.error;

    const tagProps = (val: React.ReactNode, index: number): ITagProps => {
      if (selectedItems.length === 1 && preventEmpty) {
        return {
          onRemove: undefined
        };
      }
      return {};
    };
    const tagInputProps = {
      disabled,
      onRemove: this.handleTagRemove,
      tagProps
    };
    const errorRenderer = error && !lookupError && (
      <span className="zx-orange pa1">{error}</span>
    );

    return (
      <div>
        <div className={className}>
          <MultiSelect<User>
            items={items}
            initialContent={"Start typing..."}
            itemRenderer={this.itemRenderer}
            noResults={this.renderNoResults()}
            onItemSelect={this.handleSelect}
            onQueryChange={this.doSearchDebounced}
            tagRenderer={this.tagRenderer}
            tagInputProps={tagInputProps}
            popoverProps={{ minimal: true, usePortal: true }}
            openOnKeyDown={true}
            query={query}
            selectedItems={selectedItems}
            placeholder={placeholder}
          />
        </div>
        {errorRenderer}
        {lookupError && <p className="zx-orange pv1">{lookupError.message}</p>}
      </div>
    );
  }

  private renderNoResults = () => {
    const { loading } = this.state;
    return loading ? (
      <Spinner size={Spinner.SIZE_SMALL} />
    ) : (
      <MenuItem disabled={true} text={"No results."} />
    );
  };

  private defaultPlaceholder = () => {
    switch (this.props.type) {
      case "authors":
        return "Authors";
      case "editors":
        return "Reviewers";
      default:
        return "Start typing...";
    }
  };

  private handleTagRemove = (item: string, index: number) => {
    const { preventEmpty } = this.props;
    const { selectedItems } = this.state;

    if (selectedItems.length === 1 && preventEmpty) {
      return;
    }
    this.deselectItem(this.state.selectedItems[index]);
  };

  private deselectItem = async (item: User) => {
    const selectedItems = this.state.selectedItems.filter(
      i => i.id !== item.id
    );
    await this.setState({ selectedItems: [...selectedItems] });
    this.props.input.onChange(this.state.selectedItems);
  };

  private isLabelSelected = (label: User) => {
    return this.state.selectedItems.filter(l => l.id === label.id).length > 0;
  };

  private handleSelect = (item: User) => {
    const {
      input: { onChange }
    } = this.props;

    let selectedItems;
    if (!this.isLabelSelected(item)) {
      selectedItems = [...this.state.selectedItems, item];

      if (selectedItems.length !== this.state.selectedItems.length) {
        this.setState({ selectedItems, query: "" });
        onChange(selectedItems);
      }
    }
  };

  private tagRenderer = (item: User) => fullName(item);

  private itemRenderer: ItemRenderer<User> = (
    item,
    { handleClick, modifiers: { active, disabled }, query }
  ) => {
    return (
      <MenuItem
        active={active}
        disabled={disabled}
        key={item.id}
        onClick={handleClick}
        text={highlightText(fullName(item), query)}
      />
    );
  };

  private doSearchDebounced = (query: string) => {
    if (this.debouncer) {
      clearTimeout(this.debouncer);
    }
    // const { items } = this.state;
    const newItems: User[] = [];
    if (query.length > 0) {
      this.debouncer = setTimeout(() => this.doUserLookup(query), 600);
      this.setState({ loading: true, items: newItems });
    } else {
      this.setState({ loading: false, items: [] });
    }
  };

  private doUserLookup = async (query: string) => {
    const { abortController: prevController } = this.state;

    if (prevController) {
      prevController.abort();
    }

    const { exclude, specialties } = this.props;
    const { selectedItems } = this.state;

    const specialtyIds = specialties && specialties.map(s => s.id);

    if (query.length > 0) {
      const newController = new AbortController();
      this.setState({
        abortController: newController,
        loading: true,
        query,
        error: undefined
      });

      try {
        const response = await makeApiRequest(
          requestConfigForAction(
            getUsers.request({
              nameQuery: query,
              skip: 0,
              specialtyIds,
              take: 20
            }),
            this.props.token,
            newController.signal
          )
        );
        if (response.apiResponse.data) {
          const users = arrayFromPayload(
            response.apiResponse.data as IXmedObjectInfo[],
            User
          )
            .filter(userIsContributor)
            .filter(u => {
              if (!exclude) {
                return true;
              }
              if (exclude.find(i => i.id === u.id)) {
                return false;
              } else {
                return true;
              }
            })
            .filter(u =>
              selectedItems.find(i => i.id === u.id) ? false : true
            );
          this.setState({ abortController: undefined, items: users, loading: false });
        }
      } catch (error) {
        this.setState({ abortController: undefined, loading: false, error });
      }
    }
  };
}

const mapStateToProps = ({ userStore }: IStoreState) => {
  return {
    token: userStore.authToken,
    user: userStore.loggedInUser
  };
};

export const UserMultiselect = connect(
  mapStateToProps,
  {}
)(UserMultiSelectComponent);
