import { Dictionary, cloneDeep } from "lodash";

import {
  Algorithm,
  AlgoNode,
  AlgoNodeType,
  IAlgoNodeTarget,
  Parameter,
  textForNodeType,
  ObjectType
} from "src/api";
import { nodeTitleTextPlain, truncateString } from "src/utilities";
import {
  createValidationIssue,
  IAlgoError,
  AlgoErrorSeverity
} from "./algorithm-validator";
import { IOpenAlgorithm, AlgoPanelFocus } from "src/store";

const createAlgoParamValidationIssue = (
  issueTitle: string,
  algo: Algorithm
) => {
  return {
    issueDetails: issueTitle,
    objectId: algo.id,
    objectType: "algorithms" as ObjectType,
    severity: AlgoErrorSeverity.Error,
    targetPanel: "vars" as AlgoPanelFocus
  };
};

const checkAlgoVariableUsage = (openAlgo: IOpenAlgorithm): IAlgoError[] => {
  const issues: IAlgoError[] = [];
  const { algorithm } = openAlgo;
  const { localVars, nodes, variables } = algorithm;
  const varsNotUsed = cloneDeep(
    localVars ? [...variables, ...Object.values(localVars)] : variables
  );

  nodes.forEach(n => {
    n.paths.forEach(path => {
      if (path.paramsJson) {
        path.paramsJson.forEach(vu => {
          if (vu.id) {
            const index = varsNotUsed.findIndex(p => p.id === vu.id);
            if (index > -1) {
              varsNotUsed.splice(index, 1);
            }
          }
        });
      }
    });
  });
  varsNotUsed.forEach(param => {
    if (param) {
      issues.push(
        createAlgoParamValidationIssue(
          `Variable "${param.title}" is unused in the algorithm`,
          algorithm
        )
      );
    }
  });

  return issues;
};

export const validateVariables = (openAlgo: IOpenAlgorithm) => {
  return checkAlgoVariableUsage(openAlgo);
};

export const checkVarChosen = (
  { node: targetNode, path }: IAlgoNodeTarget,
  algoNodes: Dictionary<AlgoNode>,
  variables: Parameter[]
) => {
  const errors: IAlgoError[] = [];
  const parentNode = algoNodes[path.parentId];
  const { paramsJson } = path;
  const node = [AlgoNodeType.varDecision].includes(parentNode.kind)
    ? parentNode
    : targetNode || parentNode;

  if (paramsJson && paramsJson.length > 0) {
    paramsJson.forEach(vu => {
      if (!vu.id) {
        errors.push(
          createValidationIssue(
            `${textForNodeType(parentNode.kind)} node '${truncateString(
              nodeTitleTextPlain(node)
            )}' - variable is not set `,
            node.id,
            "nodes",
            undefined,
            "choices",
            path.id
          )
        );
      } else {
        const variable = variables.find(v => v.id === vu.id);
        if (!variable) {
          errors.push(
            createValidationIssue(
              `${textForNodeType(parentNode.kind)} node '${truncateString(
                nodeTitleTextPlain(node)
              )}' variable ${vu.id} cannot be found in the algorithm `,
              node.id,
              "nodes",
              undefined,
              "choices",
              path.id
            )
          );
        }
      }
    });
  } else {
    // no paramsJson
    if (
      parentNode.kind === AlgoNodeType.varInput &&
      targetNode &&
      targetNode.kind === AlgoNodeType.choice
    ) {
      errors.push(
        createValidationIssue(
          `${textForNodeType(parentNode.kind)} node '${truncateString(
            nodeTitleTextPlain(node)
          )}' - variable is not set `,
          node.id,
          "nodes",
          undefined,
          "choices",
          path.id
        )
      );
    }
  }
  return errors;
};

const checkProcessor = (
  node: AlgoNode,
  algoNodes: Dictionary<AlgoNode>,
  variables: Parameter[]
) => {
  const errors: IAlgoError[] = [];
  const calcs = node.calcs(algoNodes);

  // Check does something...
  if (calcs.length === 0) {
    errors.push(
      createValidationIssue(
        `${textForNodeType(node.kind)} node '${truncateString(
          nodeTitleTextPlain(node)
        )}' - doesn't do any processing `,
        node.id,
        "nodes"
      )
    );
  } else {
    calcs.forEach(c => {
      const { paramsJson } = c.path;
      if (paramsJson) {
        // Each of these must be a criteria or a consequence
        const criteria = paramsJson.filter(p => p.operatorDetails);
        const consequences = paramsJson.filter(p => p.resultDetails);

        if (consequences.length === 0) {
          errors.push(
            createValidationIssue(
              `${textForNodeType(node.kind)} node '${truncateString(
                nodeTitleTextPlain(node)
              )}' - has no consequence set `,
              node.id,
              "nodes",
              undefined,
              "choices",
              c.path.id
            )
          );
        } else {
          consequences.forEach(vu => {
            if (vu.resultDetails) {
              if (!vu.resultDetails.operator) {
                errors.push(
                  createValidationIssue(
                    `${textForNodeType(node.kind)} node '${truncateString(
                      nodeTitleTextPlain(node)
                    )}' - has no operator set for the consequence`,
                    node.id,
                    "nodes",
                    undefined,
                    "choices",
                    c.path.id
                  )
                );
              }
              if (!vu.resultDetails.values) {
                errors.push(
                  createValidationIssue(
                    `${textForNodeType(node.kind)} node '${truncateString(
                      nodeTitleTextPlain(node)
                    )}' - has no value set for the consequence change`,
                    node.id,
                    "nodes",
                    undefined,
                    "choices",
                    c.path.id
                  )
                );
              }
            }
          });
        }
        if (criteria.length === 0) {
          errors.push(
            createValidationIssue(
              `${textForNodeType(node.kind)} node '${truncateString(
                nodeTitleTextPlain(node)
              )}' - has no criteria set `,
              node.id,
              "nodes",
              undefined,
              "choices",
              c.path.id
            )
          );
        } else {
          criteria.forEach(vu => {
            if (vu.resultDetails) {
              if (!vu.resultDetails.operator) {
                errors.push(
                  createValidationIssue(
                    `${textForNodeType(node.kind)} node '${truncateString(
                      nodeTitleTextPlain(node)
                    )}' - has no operator set for the criterion`,
                    node.id,
                    "nodes",
                    undefined,
                    "choices",
                    c.path.id
                  )
                );
              }
              if (!vu.resultDetails.values) {
                errors.push(
                  createValidationIssue(
                    `${textForNodeType(node.kind)} node '${truncateString(
                      nodeTitleTextPlain(node)
                    )}' - has no value set for the criterion`,
                    node.id,
                    "nodes",
                    undefined,
                    "choices",
                    c.path.id
                  )
                );
              }
            }
          });
        }
      }
    });
  }
  return errors;
};

const checkDecision = (
  node: AlgoNode,
  algoNodes: Dictionary<AlgoNode>,
  variables: Parameter[]
) => {
  const errors: IAlgoError[] = [];
  const calcs = node.calcs(algoNodes);
  if (calcs.length === 0) {
    errors.push(
      createValidationIssue(
        `${textForNodeType(node.kind)} node '${truncateString(
          nodeTitleTextPlain(node)
        )}' - doesn't do anything `,
        node.id,
        "nodes"
      )
    );
  } else {
    // Check we have an output set
    const totalOutputs = calcs.filter(c => c.node || c.path.targetAlgorithmId);
    if (totalOutputs.length === 0) {
      errors.push(
        createValidationIssue(
          `${textForNodeType(node.kind)} node '${truncateString(
            nodeTitleTextPlain(node)
          )}' - has no output set`,
          node.id,
          "nodes"
        )
      );
    }

    // Check we have connected criteria
    const unconnectedCriteria = calcs.filter(
      c => !(c.node || c.path.targetAlgorithmId)
    );

    unconnectedCriteria.forEach(uc => {
      errors.push(
        createValidationIssue(
          `${textForNodeType(node.kind)} node '${truncateString(
            nodeTitleTextPlain(node)
          )}' - criteria is unconnected`,
          node.id,
          "nodes",
          undefined,
          "choices",
          uc.path.id
        )
      );
    });
  }
  return errors;
};

export const checkVars = (
  node: AlgoNode,
  algoNodes: Dictionary<AlgoNode>,
  variables: Parameter[]
) => {
  const errors: IAlgoError[] = [];

  let paths;

  switch (node.kind) {
    case AlgoNodeType.varInput:
      paths = node.options(algoNodes);
      break;

    case AlgoNodeType.varProcessor:
      paths = node.calcs(algoNodes);
      errors.push(...checkProcessor(node, algoNodes, variables));
      break;

    case AlgoNodeType.varDecision:
      paths = node.targets(algoNodes);
      errors.push(...checkDecision(node, algoNodes, variables));
      break;
  }

  if (paths) {
    paths.forEach(nt =>
      errors.push(...checkVarChosen(nt, algoNodes, variables))
    );
  }

  return errors;
};
