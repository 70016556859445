import {
  Algorithm,
  fullName,
  ObjectType,
  User,
  userIsContributor
} from "src/api";

import { AlgoPanelFocus, EditTabType, IOpenAlgorithm } from "src/store";
import { validateNodes } from "./node-validator";
import { validateVariables } from "./variables-validator";

export enum AlgoErrorSeverity {
  Error = "error",
  Warning = "warning"
}

export enum AlgoIssueType {
  UnconnectedNode,
  IncompleteField,
  MultichoiceOptionRangeOverlap,
  MultichoiceTargetsIncomplete
}

export interface IAlgoError {
  issueDetails: string;
  severity: AlgoErrorSeverity;
  objectId?: string; // UUID
  pathId?: string; // UUID
  objectType?: ObjectType;
  targetPanel?: EditTabType | AlgoPanelFocus;
}

export const createValidationIssue = (
  issueTitle: string,
  objectId?: string,
  objectType?: ObjectType,
  severity: AlgoErrorSeverity = AlgoErrorSeverity.Error,
  targetPanel: EditTabType | AlgoPanelFocus | undefined = undefined,
  pathId?: string
) => {
  return {
    issueDetails: issueTitle,
    objectId,
    objectType,
    pathId,
    severity,
    targetPanel
  };
};

const createAlgoValidationIssue = (issueTitle: string, algo: Algorithm) => {
  return {
    issueDetails: issueTitle,
    objectId: algo.id,
    objectType: "algorithms" as ObjectType,
    severity: AlgoErrorSeverity.Error,
    targetPanel: "info" as AlgoPanelFocus
  };
};

const verifyUser = (u: User, context: string) => {
  if (u.archived || !userIsContributor(u)) {
    return {
      issueDetails: `${context} '${fullName(u)}' is not a valid contributor`,
      objectId: u.id,
      objectType: "users" as ObjectType,
      severity: AlgoErrorSeverity.Error
    };
  }
  return undefined;
};

const checkAlgoFields = (openAlgo: IOpenAlgorithm): IAlgoError[] => {
  const errors: IAlgoError[] = [];
  const { algorithm: algo } = openAlgo;
  if (algo.title.length < 3) {
    errors.push(
      createAlgoValidationIssue("Algorithm is missing a title", algo)
    );
  }
  if (algo.summary.length < 3) {
    errors.push(
      createAlgoValidationIssue("Algorithm is missing a summary", algo)
    );
  }
  if (algo.synopsis.length < 3) {
    errors.push(
      createAlgoValidationIssue("Algorithm is missing a synopsis", algo)
    );
  }

  if (algo.references.length === 0) {
    errors.push({
      ...createAlgoValidationIssue("Algorithm has no references", algo),
      targetPanel: "refs"
    });
  }
  if (algo.keywords.length === 0) {
    errors.push(createAlgoValidationIssue("Algorithm has no keywords", algo));
  }
  if (algo.medicalSpecialties.length === 0) {
    errors.push(
      createAlgoValidationIssue("Algorithm has no topic(s) set", algo)
    );
  }

  if (algo.authors.length === 0) {
    errors.push(createAlgoValidationIssue("Algorithm has no author", algo));
  }
  algo.authors.forEach(a => {
    const verifyError = verifyUser(a, "Author");
    if (verifyError) {
      errors.push(verifyError);
    }
  });

  if (algo.editors.length === 0) {
    errors.push(createAlgoValidationIssue("Algorithm has no reviewer", algo));
  }
  algo.editors.forEach(e => {
    const verifyError = verifyUser(e, "Reviewer");
    if (verifyError) {
      errors.push(verifyError);
    }
  });

  return errors;
};

/**
 * Validates an algorithm for completeness. Returns an array of strings describing the issues
 * @param algorithm Algorithm to check for syntax and completeness
 */
export const validateAlgorithm = async (
  openAlgorithm: IOpenAlgorithm
): Promise<IAlgoError[]> => {
  const errors = checkAlgoFields(openAlgorithm);
  errors.push(...validateNodes(openAlgorithm));
  errors.push(...validateVariables(openAlgorithm));

  return errors;
};
