import { Button, Icon } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import * as React from "react";
import { connect } from "react-redux";

import { registerForPush } from "src/actions";
import { User, userIsAdmin } from "src/api";
import { messagingSupported } from "src/config/firebase";
import { IStoreState } from "src/store";

interface IPushEnablerButtonProps {
  loggedInUser?: User;
  pushToken?: string;
  registerForPush: typeof registerForPush.request;
}

class PushEnablerButtonComponent extends React.PureComponent<
  IPushEnablerButtonProps
> {
  public render() {
    if (messagingSupported() && userIsAdmin(this.props.loggedInUser)) {
      if (this.props.pushToken) {
        return <Icon icon={IconNames.NOTIFICATIONS_UPDATED} />;
      }

      // Display the UI to let the user toggle notifications
      const registerForPushMessages = () => this.props.registerForPush();

      return (
        <Button
          icon={IconNames.NOTIFICATIONS}
          onClick={registerForPushMessages}
          title="Enable push messages"
          minimal={true}
        />
      );
    }
    return null;
  }
}

const mapStateToProps = ({ userStore }: IStoreState) => {
  return {
    loggedInUser: userStore.loggedInUser,
    pushToken: userStore.pushToken
  };
};

export const PushEnablerButton = connect(mapStateToProps, {
  registerForPush: registerForPush.request
})(PushEnablerButtonComponent);
