import { Exclude, Transform, Type } from "class-transformer";

import { Dictionary } from "lodash";
import { Comment, Event, Label, User, userIsAdmin } from "..";
import { AlgoNode } from "..";
import { AlgoState } from "./algostate";
import { BaseModel, kBaseModelOmissions } from "./object-base";
import { Parameter } from "./parameter";

export enum AlgorithmStatus {
  draft = "DRAFT",
  awaitingReview = "WAITING_FOR_REVIEW",
  inReview = "IN_REVIEW",
  awaitingFormatting = "WAITING_FOR_FORMATTING",
  inFormatting = "IN_FORMATTING",
  approved = "APPROVED",
  published = "PUBLISHED",
  superceded = "SUPERCEDED"
}

export const kSelectableAlgoStatuses = [
  AlgorithmStatus.inReview,
  AlgorithmStatus.approved
];
export const algoStatusString = (status: AlgorithmStatus): string => {
  switch (status) {
    case AlgorithmStatus.draft:
      return "Draft";
    case AlgorithmStatus.awaitingReview:
      return "Awaiting Review";
    case AlgorithmStatus.inReview:
      return "In Review";
    case AlgorithmStatus.awaitingFormatting:
      return "Ready for Formatting";
    case AlgorithmStatus.inFormatting:
      return "In Formatting";
    case AlgorithmStatus.approved:
      return "Approved";
    case AlgorithmStatus.published:
      return "Published";
    case AlgorithmStatus.superceded:
      return "Superseded";
    default:
      return `${status}`;
  }
};

export const kAlgoKeyOmissions = [
  ...kBaseModelOmissions,
  "events",
  "nodes",
  "comments"
];

export class Algorithm extends BaseModel {
  @Transform(s => s || "")
  public title = "";

  public status: AlgorithmStatus = AlgorithmStatus.draft;
  public version = 0.1;

  @Exclude({ toPlainOnly: true })
  @Type(() => AlgoNode)
  @Transform(n => n || [])
  public nodes: AlgoNode[] = [];

  @Exclude({ toPlainOnly: true })
  @Type(() => Comment)
  public comments: Comment[] = [];

  @Type(() => Label)
  public keywords: Label[] = [];

  @Type(() => Label)
  public medicalSpecialties: Label[] = [];

  @Type(() => User)
  public editors: User[] = [];

  @Type(() => User)
  public authors: User[] = [];

  @Exclude({ toPlainOnly: true })
  @Type(() => Event)
  public events?: Event[] = [];

  @Transform(s => s || "")
  public summary = "";
  @Transform(s => s || "")
  public synopsis = "";
  @Transform(s => s || "")
  public epidemiology = "";
  @Transform(s => s || "")
  public evidence = "";

  public createdAt: Date = new Date();
  public submittedAt: Date = new Date();

  @Type(() => String)
  public references: string[] = [];

  @Type(() => Label)
  public regions: Label[] = [];

  @Type(() => AlgoState)
  public algoStates: AlgoState[] = [];

  @Type(() => Parameter)
  public variables: Parameter[] = [];

  public localVars?: Dictionary<Parameter>;

  @Exclude()
  public canBeEditedBy = (user?: User) => {
    if (!user) {
      return false;
    }

    if (
      this.status !== AlgorithmStatus.published &&
      (this.authors.find(u => u.id === user.id) ||
        this.editors.find(u => u.id === user.id))
    ) {
      return true;
    }

    if (/*this.editors.length === 0 && */ userIsAdmin(user)) {
      // Admins get editor rights if no editor set
      return true;
    }
    return false;
  };
}

export function createNewAlgorithm(title: string, forUser: User): Algorithm {
  return {
    ...new Algorithm(),
    authors: [forUser],
    status: AlgorithmStatus.draft,
    title,
    version: 0.1
  };
}
