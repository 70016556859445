import DOMPurify from "dompurify";
import marked from "marked";
import * as React from "react";

import { AlgoNode } from "src/api";

export interface INodeMedsProps {
  node: AlgoNode;
  width?: number;
}

export class NodeMedications extends React.PureComponent<INodeMedsProps> {
  public render() {
    const { node, width } = this.props;
    if (!node.dosages) {
      return null;
    }

    const dosagesHtml = DOMPurify.sanitize(marked(node.dosages));
    return (
      <section>
        <p
          style={width ? { width } : undefined}
          dangerouslySetInnerHTML={{ __html: dosagesHtml }}
        />
      </section>
    );
  }
}
