import { HTMLTable, Icon } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import { push } from "connected-react-router";
import { values } from "lodash";
import moment from "moment";
import * as React from "react";
import { connect } from "react-redux";

import { RouteComponentProps, withRouter } from "react-router-dom";
import {
  dateSort,
  fullName,
  lastNameSort,
  occupationSort,
  User,
  userIsAdmin,
  UserRole
} from "src/api";
import { kUsersRootUrl, kVerify } from "src/config/routes";
import { IStoreState } from "src/store";

interface IUserVerificationProps
  extends RouteComponentProps<{ query?: string }> {
  adminUser: boolean;
  busy: boolean;
  error?: Error;
  loggedInUser?: User;
  push: typeof push;

  users: User[];
}
type SortType = "name" | "occ" | "date";

interface IUserVerificationState {
  ascending: boolean;
  sort: SortType;
}

class UserVerificationComponent extends React.PureComponent<
  IUserVerificationProps,
  IUserVerificationState
> {
  constructor(props: IUserVerificationProps) {
    super(props);
    this.state = {
      ascending: true,
      sort: "date"
    };
  }

  public render() {
    const { users } = this.props;
    const { ascending, sort } = this.state;

    if (users.length === 0) {
      return null;
    }

    const icon = (
      <Icon icon={ascending ? IconNames.CARET_UP : IconNames.CARET_DOWN} />
    );

    const sortFunction = (a: User, b: User) =>
      sort === "date"
        ? dateSort(a, b, ascending)
        : sort === "occ"
        ? occupationSort(a, b, ascending)
        : lastNameSort(a, b, ascending);

    const sortedUsers = users.sort(sortFunction);
    const applySort = (type: SortType) => {
      const direction = sort === type ? !ascending : ascending;
      this.setState({ sort: type, ascending: direction });
    };

    const sortByName = () => applySort("name");
    const sortByOccupation = () => applySort("occ");
    const sortByDate = () => applySort("date");

    return (
      <section className={"mt2 pa2 bg-white br3"}>
        <HTMLTable className="w-100 flex-auto" condensed={true}>
          <thead>
            <tr>
              <th className="pointer" onClick={sortByName}>
                Awaiting approval ({sortedUsers.length})
                {sort === "name" && icon}
              </th>
              <th className="pointer" onClick={sortByOccupation}>
                Occupation
                {sort === "occ" && icon}
              </th>
              <th className="pointer" onClick={sortByDate}>
                Registered
                {sort === "date" && icon}
              </th>
            </tr>
          </thead>
          <tbody>{sortedUsers.map(this.renderUnverifiedRow)}</tbody>
        </HTMLTable>
      </section>
    );
  }

  private renderUnverifiedRow = (u: User) => {
    const handleClick = () => {
      this.props.push(`${kUsersRootUrl}/${kVerify}/${u.id}`);
    };

    return (
      <tr id={u.id} key={u.id} className="pointer ph2" onClick={handleClick}>
        <td>
          <div>
            <Icon className="pr2" icon={IconNames.PERSON} />
            {fullName(u)}
          </div>
        </td>
        <td>{u.title || "(not specified)"}</td>
        <td>{moment(u.createdAt).format("MMM Do YYYY")}</td>
      </tr>
    );
  };
}

const mapStateToProps = ({ userStore, usersStore, uiStore }: IStoreState) => {
  return {
    adminUser: userIsAdmin(userStore.loggedInUser),
    busy: uiStore.community.contributorsLoadersCount > 0,
    loggedInUser: userStore.loggedInUser,
    users: values(usersStore.allUsers).filter(u => u.role === UserRole.Basic)
  };
};

export const UserVerification = withRouter(
  connect(mapStateToProps, {
    push
  })(UserVerificationComponent)
);
