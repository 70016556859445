import { getType } from "typesafe-actions";

import {
  clearUsersError,
  getUser,
  getUsers,
  logout,
  ReduxAction,
  setUserRole,
  updateUser
} from "src/actions";

import {
  dictionaryFromPayload,
  kUserFetchSize,
  objectFromPayload,
  User
} from "src/api";
import { IUsersStore } from "src/store";

const INITIAL_STATE: IUsersStore = {
  allUsers: {},
  fetchOffset: 0,
  moreAvailable: true
};

export const usersReducer = (
  usersStore: IUsersStore = INITIAL_STATE,
  action: ReduxAction
): IUsersStore => {
  switch (action.type) {
    case getType(clearUsersError):
      return {
        ...usersStore,
        error: undefined
      };

    case getType(logout):
      return {
        ...usersStore,
        allUsers: {}
      };

    case getType(getUsers.request):
      const updatedStore = {
        ...usersStore,
        fetchOffset: action.payload.skip
      };
      if (updatedStore.fetchOffset === 0) {
        updatedStore.moreAvailable = true;
      }
      return updatedStore;

    case getType(getUsers.success):
      const fetchedUsers = dictionaryFromPayload(
        action.payload.apiResponse.data,
        User
      );
      const usersCount = Object.keys(fetchedUsers).length;
      const updatedAllUsers = {
        ...usersStore.allUsers,
        ...fetchedUsers
      };

      return {
        ...usersStore,
        allUsers: updatedAllUsers,
        fetchOffset: usersStore.fetchOffset + usersCount,
        moreAvailable: !(usersCount < kUserFetchSize)
      };

    case getType(getUser.success):
    case getType(updateUser.success):
    case getType(setUserRole.success):
      const user = objectFromPayload(action.payload.apiResponse.data, User);

      if (!user) {
        return usersStore;
      } else {
        return {
          ...usersStore,
          allUsers: {
            ...usersStore.allUsers,
            [user.id]: user
          }
        };
      }

    case getType(getUsers.failure):
    case getType(getUser.failure):
    case getType(updateUser.failure):
    case getType(setUserRole.failure):
      return {
        ...usersStore,
        error: action.payload
      };

    default:
      return usersStore;
  }
};
