import { Button, H4, H5 } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import * as React from "react";

import { setEditState } from "src/actions";
import { AlgoNodeInfoType } from "src/api";
import { IOpenAlgorithm } from "src/store";
import { nodeTitleTextPlain, sortAlphabetically } from "src/utilities";

export interface INodeRefsProps {
  openAlgorithm: IOpenAlgorithm;
  setEditState: typeof setEditState;
}

export class NodeReferences extends React.PureComponent<INodeRefsProps> {
  public render() {
    const {
      openAlgorithm: { algorithm }
    } = this.props;
    const { nodes } = algorithm;

    const nodesWithRefs = nodes
      .filter(n => n.references.length > 0)
      .filter(n => n.references.filter(r => r.length > 0).length > 0)
      .sort((a, b) => sortAlphabetically(a.id, b.id));

    if (nodesWithRefs.length === 0) {
      return null;
    }

    const nodeRefsRenderer = nodesWithRefs.map((n, i) => {
      const goToNode = () => {
        this.props.setEditState({
          algoId: algorithm.id,
          state: {
            algoInfoPanel: undefined,
            infoPanelFocus: AlgoNodeInfoType.refs,
            panelFocus: "info",
            selectedNodeIds: { [n.id]: 1 }
          }
        });
      };
      const refsRenderer = n.references.map((r, j) => <li key={j}>{r} </li>);

      return (
        <section key={i}>
          <div className="flex flex-row w-100 justify-between">
            <H5>{nodeTitleTextPlain(n)}</H5>
            <Button
              icon={IconNames.EYE_OPEN}
              minimal={true}
              onClick={goToNode}
            />
          </div>
          <ul>{refsRenderer}</ul>
        </section>
      );
    });

    return (
      <section>
        <H4>Node References</H4>
        {nodeRefsRenderer}
      </section>
    );
  }
}
