import { Dictionary } from "lodash";
import { createAction, createAsyncAction } from "typesafe-actions";

import {
  AlgoNode,
  AlgoNodePath,
  AlgoNodeType,
  Algorithm,
  AlgorithmStatus,
  CardAttributes,
  Comment,
  IOpenxmedApiFailureResponse,
  IOpenxmedApiSuccessResponse,
  IOpenxmedApiTaggedSuccessResponse,
  IVariableUsage,
  Label,
  Parameter,
  ParameterUnit,
  User,
  ZxLabelType,
  PathType
} from "src/api";
import {
  ConsumePanelFocusType,
  IEditingState,
  InfoTabType,
  IOpenAlgorithm
} from "src/store";
import { IPageParameters } from ".";

export interface ILabelQuery {
  type: ZxLabelType;
  searchQuery?: string;
}

export const searchLabels = createAsyncAction(
  "searchLabels/REQUEST",
  "searchLabels/SUCCESS",
  "searchLabels/FAILURE"
)<ILabelQuery, IOpenxmedApiSuccessResponse, Error>();

export interface ILibraryQuery {
  medicalSpecialtyIds?: Label[];
  status?: AlgorithmStatus[];
  userId?: string;
}
export type IPagedLibraryQuery = ILibraryQuery & IPageParameters;

export const getTopics = createAsyncAction(
  "getTopics/REQUEST",
  "getTopics/SUCCESS",
  "getTopics/FAILURE"
)<ILibraryQuery, IOpenxmedApiSuccessResponse, Error>();

export const getAlgosByTopic = createAsyncAction(
  "getAlgosByTopic/REQUEST",
  "getAlgosByTopic/SUCCESS",
  "getAlgosByTopic/FAILURE"
)<string, IOpenxmedApiSuccessResponse, Error>();

export const getMyAlgos = createAsyncAction(
  "getMyAlgos/REQUEST",
  "getMyAlgos/SUCCESS",
  "getMyAlgos/FAILURE"
)<string | undefined, IOpenxmedApiSuccessResponse, Error>();

export const retrieveAlgoList = createAsyncAction(
  "retrieveAlgoList/REQUEST",
  "retrieveAlgoList/SUCCESS",
  "retrieveAlgoList/FAILURE"
)<IPagedLibraryQuery, IOpenxmedApiSuccessResponse, Error>();

export interface IGetAlgorithmParams {
  allowOld?: boolean;
  id: string;
  includeNodes: boolean;
}

export const getAlgorithm = createAsyncAction(
  "getAlgorithm/REQUEST",
  "getAlgorithm/SUCCESS",
  "getAlgorithm/FAILURE"
)<IGetAlgorithmParams, IOpenxmedApiSuccessResponse, Error>();

export const searchAlgoStarts = createAsyncAction(
  "searchAlgoStarts/REQUEST",
  "searchAlgoStarts/SUCCESS",
  "searchAlgoStarts/FAILURE"
)<string, IOpenxmedApiSuccessResponse, Error>();

export const closeAlgorithm = createAction("closeAlgorithm")<string>();
export const clearAlgoError = createAction("clearAlgoError")();

export interface IConsumeAlgoPayload {
  openAlgorithm: IOpenAlgorithm;
  nodeAttributes: CardAttributes;
  tappedChoice?: AlgoNode;
}

export const tappedNode = createAction("tappedNode")<IConsumeAlgoPayload>();

export interface ITappedInfoPayload {
  node: AlgoNode;
  focus: InfoTabType;
}

export const tappedInfoOrComments = createAction("tappedInfoOrComments")<
  ITappedInfoPayload
>();

export interface IChangeAlgoPagePayload {
  details: IOpenAlgorithm;
  page: number;
}
export const changeAlgoPage = createAction("changeAlgoPage")<
  IChangeAlgoPagePayload
>();

export interface IChangeAlgoCommentPayload {
  details: IOpenAlgorithm;
  commentText: string;
}

export const updateInProgressComment = createAction("updateInProgressComment")<
  IChangeAlgoCommentPayload
>();

export interface ICommentPayload {
  algoId: string;
  nodeId: string;
  comment: Comment;
}

export const addComment = createAction("addComment")<ICommentPayload>();
export const updateComment = createAction("updateComment")<ICommentPayload>();
export const deleteComment = createAction("deleteComment")<ICommentPayload>();

export const getCommentsForAlgorithm = createAsyncAction(
  "getCommentsForAlgorithm/REQUEST",
  "getCommentsForAlgorithm/SUCCESS",
  "getCommentsForAlgorithm/FAILURE"
)<AlgoNode, IOpenxmedApiSuccessResponse, Error>();

export interface ISearchParams {
  query: string;
  user: User;
}
export const getAlgorithmSearchResults = createAsyncAction(
  "getAlgorithmSearchResults/REQUEST",
  "getAlgorithmSearchResults/SUCCESS",
  "getAlgorithmSearchResults/FAILURE"
)<ISearchParams, IOpenxmedApiSuccessResponse, Error>();

export const saveAlgoState = createAsyncAction(
  "saveAlgoState/REQUEST",
  "saveAlgoState/SUCCESS",
  "saveAlgoState/FAILURE"
)<IOpenAlgorithm, IOpenxmedApiSuccessResponse, Error>();

export const updateConsumePanelFocus = createAction("updateConsumePanelFocus")<{
  algoId: string;
  firstNavigation?: boolean;
  focus: ConsumePanelFocusType;
}>();

export const toggleEditMode = createAction("toggleEditMode")<string>();

export const setEditState = createAction("setEditState")<{
  algoId: string;
  state: Partial<IEditingState>;
}>();

export const updateNodes = createAction("updateNodes")<{
  algoId: string;
  nodes: AlgoNode[];
}>();
export const updateNodeText = createAction("updateNodeText")<{
  algoId: string;
  node: AlgoNode;
}>();

export interface ICreateNodePayload {
  algoId: string;
  displayOrder?: number;
  kind: AlgoNodeType;
  parentId?: string;
  contained?: boolean;
}

export const createNode = createAction("createNode")<ICreateNodePayload>();
export const removeNodes = createAction("removeNodes")<{
  nodeIds: Dictionary<number>;
  algoId: string;
}>();

export interface ILinkNodesPayload {
  parentId: string;
  algoId: string;
  pathId?: string;
  childId?: string;
  otherAlgoId?: string;
}

export const linkNodes = createAction("linkNodes")<ILinkNodesPayload>();

export interface ICreatePathPayload {
  algoId: string;
  childId?: string;
  linkType?: PathType;
  nodeId: string;
  varDetails?: IVariableUsage[];
}

export interface IContainNodePayload {
  algoId: string;
  container: AlgoNode;
  containee: AlgoNode;
  displayOrder?: number;
}

export const createPath = createAction("createPath")<ICreatePathPayload>();

export interface IUpdatePathPayload {
  algoId: string;
  path: AlgoNodePath;
  otherAlgoId?: string;
}

export const updatePath = createAction("updatePath")<IUpdatePathPayload>();
export const removePath = createAction("removePath")<IUpdatePathPayload>();
export const updateAlgo = createAction("updateAlgo")<Algorithm>();
export const containNode = createAction("containNode")<IContainNodePayload>();

export type PathNodeTypes = "choices" | "outputs" | "calcs" | "contained";

export interface IReorderPathsPayload {
  algoId: string;
  newIndex: number;
  nodeId: string;
  oldIndex: number;
  type: PathNodeTypes;
}

export const reorderPaths = createAction("reorderPaths")<
  IReorderPathsPayload
>();
export const processQueue = createAction("processQueue")<string>();
export const clearQueueErrors = createAction("clearQueueErrors")<string>();

export interface ITaggedRequestBase {
  tag: string;
}

export interface ITaggedNodeRequestPayload extends ITaggedRequestBase {
  node: AlgoNode;
}

export const createBackendNode = createAsyncAction(
  "createBackendNode/REQUEST",
  "createBackendNode/SUCCESS",
  "createBackendNode/FAILURE"
)<
  ITaggedNodeRequestPayload,
  IOpenxmedApiTaggedSuccessResponse,
  IOpenxmedApiFailureResponse
>();

export const updateBackendNode = createAsyncAction(
  "updateBackendNode/REQUEST",
  "updateBackendNode/SUCCESS",
  "updateBackendNode/FAILURE"
)<
  ITaggedNodeRequestPayload,
  IOpenxmedApiTaggedSuccessResponse,
  IOpenxmedApiFailureResponse
>();

export const deleteBackendNode = createAsyncAction(
  "deleteBackendNode/REQUEST",
  "deleteBackendNode/SUCCESS",
  "deleteBackendNode/FAILURE"
)<
  ITaggedNodeRequestPayload,
  IOpenxmedApiTaggedSuccessResponse,
  IOpenxmedApiFailureResponse
>();

export interface ITaggedPathRequestPayload extends ITaggedRequestBase {
  path: AlgoNodePath;
}

export const createBackendPath = createAsyncAction(
  "createBackendPath/REQUEST",
  "createBackendPath/SUCCESS",
  "createBackendPath/FAILURE"
)<
  ITaggedPathRequestPayload,
  IOpenxmedApiTaggedSuccessResponse,
  IOpenxmedApiFailureResponse
>();

export const updateBackendPath = createAsyncAction(
  "updateBackendPath/REQUEST",
  "updateBackendPath/SUCCESS",
  "updateBackendPath/FAILURE"
)<
  ITaggedPathRequestPayload,
  IOpenxmedApiTaggedSuccessResponse,
  IOpenxmedApiFailureResponse
>();

export const deleteBackendPath = createAsyncAction(
  "deleteBackendPath/REQUEST",
  "deleteBackendPath/SUCCESS",
  "deleteBackendPath/FAILURE"
)<
  ITaggedPathRequestPayload,
  IOpenxmedApiTaggedSuccessResponse,
  IOpenxmedApiFailureResponse
>();

export interface ITaggedAlgoRequestPayload extends ITaggedRequestBase {
  algo: Algorithm;
}

export const updateBackendAlgorithm = createAsyncAction(
  "updateBackendAlgorithm/REQUEST",
  "updateBackendAlgorithm/SUCCESS",
  "updateBackendAlgorithm/FAILURE"
)<
  ITaggedAlgoRequestPayload,
  IOpenxmedApiTaggedSuccessResponse,
  IOpenxmedApiFailureResponse
>();

export interface ITaggedCommentRequestPayload extends ITaggedRequestBase {
  comment: Comment;
}

export const createBackendComment = createAsyncAction(
  "createBackendComment/REQUEST",
  "createBackendComment/SUCCESS",
  "createBackendComment/FAILURE"
)<
  ITaggedCommentRequestPayload,
  IOpenxmedApiTaggedSuccessResponse,
  IOpenxmedApiFailureResponse
>();

export const updateBackendComment = createAsyncAction(
  "updateBackendComment/REQUEST",
  "updateBackendComment/SUCCESS",
  "updateBackendComment/FAILURE"
)<
  ITaggedCommentRequestPayload,
  IOpenxmedApiTaggedSuccessResponse,
  IOpenxmedApiFailureResponse
>();

export const deleteBackendComment = createAsyncAction(
  "deleteBackendComment/REQUEST",
  "deleteBackendComment/SUCCESS",
  "deleteBackendComment/FAILURE"
)<
  ITaggedCommentRequestPayload,
  IOpenxmedApiTaggedSuccessResponse,
  IOpenxmedApiFailureResponse
>();

export interface ICreateAlgorithmPayload {
  algorithm: Algorithm;
  baseAlgo?: Algorithm;
  newRevisionMessage?: string;
}

export const createAlgorithm = createAsyncAction(
  "createAlgorithm/REQUEST",
  "createAlgorithm/SUCCESS",
  "createAlgorithm/FAILURE"
)<ICreateAlgorithmPayload, IOpenxmedApiSuccessResponse, Error>();

export const getAlgorithmRevisions = createAsyncAction(
  "getAlgorithmRevisions/REQUEST",
  "getAlgorithmRevisions/SUCCESS",
  "getAlgorithmRevisions/FAILURE"
)<string, IOpenxmedApiSuccessResponse, Error>();

export const deleteAlgorithm = createAsyncAction(
  "deleteAlgorithm/REQUEST",
  "deleteAlgorithm/SUCCESS",
  "deleteAlgorithm/FAILURE"
)<string, IOpenxmedApiSuccessResponse, Error>();

interface IReasonedRequest {
  reason: string;
}

export interface IAlgorithmStatusUpdatePayload extends IReasonedRequest {
  algorithm: Algorithm;
}
export const updateAlgorithmStatus = createAsyncAction(
  "updateAlgorithmStatus/REQUEST",
  "updateAlgorithmStatus/SUCCESS",
  "updateAlgorithmStatus/FAILURE"
)<IAlgorithmStatusUpdatePayload, IOpenxmedApiSuccessResponse, Error>();

interface IRequestParametersPayload extends IPageParameters {
  id?: string;
  query?: string;
}

export const retrieveParameters = createAsyncAction(
  "retrieveParameters/REQUEST",
  "retrieveParameters/SUCCESS",
  "retrieveParameters/FAILURE"
)<IRequestParametersPayload, IOpenxmedApiSuccessResponse, Error>();

export const createParameter = createAsyncAction(
  "createParameter/REQUEST",
  "createParameter/SUCCESS",
  "createParameter/FAILURE"
)<Parameter, IOpenxmedApiSuccessResponse, Error>();

export const updateParameter = createAsyncAction(
  "updateParameter/REQUEST",
  "updateParameter/SUCCESS",
  "updateParameter/FAILURE"
)<Parameter, IOpenxmedApiSuccessResponse, Error>();

export interface ITaggedParameterPayload extends ITaggedRequestBase {
  parameter: Parameter;
}

export const deleteParameter = createAsyncAction(
  "deleteParameter/REQUEST",
  "deleteParameter/SUCCESS",
  "deleteParameter/FAILURE"
)<
  ITaggedParameterPayload,
  IOpenxmedApiTaggedSuccessResponse,
  IOpenxmedApiFailureResponse
>();

export const retrieveUnits = createAsyncAction(
  "retrieveUnits/REQUEST",
  "retrieveUnits/SUCCESS",
  "retrieveUnits/FAILURE"
)<IRequestParametersPayload, IOpenxmedApiSuccessResponse, Error>();

export const createParameterUnit = createAsyncAction(
  "createParameterUnit/REQUEST",
  "createParameterUnit/SUCCESS",
  "createParameterUnit/FAILURE"
)<ParameterUnit, IOpenxmedApiSuccessResponse, Error>();

export const updateParameterUnit = createAsyncAction(
  "updateParameterUnit/REQUEST",
  "updateParameterUnit/SUCCESS",
  "updateParameterUnit/FAILURE"
)<ParameterUnit, IOpenxmedApiSuccessResponse, Error>();

export interface ITaggedUnitPayload extends ITaggedRequestBase {
  unit: ParameterUnit;
}
export const deleteParameterUnit = createAsyncAction(
  "deleteParameterUnit/REQUEST",
  "deleteParameterUnit/SUCCESS",
  "deleteParameterUnit/FAILURE"
)<
  ITaggedUnitPayload,
  IOpenxmedApiTaggedSuccessResponse,
  IOpenxmedApiFailureResponse
>();

export const clearParamsError = createAction("clearParamsError")();

export interface IChangeVarPayload {
  paramId: string;
  index: number;
  value: number | boolean | string | undefined;
}

export interface IChangeAlgoVarPayload extends IChangeVarPayload {
  algoId: string;
}

export const changeAlgoVar = createAction("changeAlgoVar")<
  IChangeAlgoVarPayload
>();

interface INodeImagePayload {
  nodeId: string;
  imageData: File;
}
export const setNodeImage = createAsyncAction(
  "setNodeImage/REQUEST",
  "setNodeImage/SUCCESS",
  "setNodeImage/FAILURE"
)<INodeImagePayload, IOpenxmedApiSuccessResponse, Error>();
