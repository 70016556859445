import { IconNames } from "@blueprintjs/icons";
import { push } from "connected-react-router";
import moment from "moment";
import { Epic, StateObservable } from "redux-observable";
import { of } from "rxjs";
import { filter, flatMap, ignoreElements, map } from "rxjs/operators";
import { isActionOf } from "typesafe-actions";

import {
  closeAlgorithm,
  createAlgorithm,
  getAlgorithm,
  getTopics,
  getUser,
  kAlgorithmIdBreadcrumbLocation,
  kNodeIdBreadcrumbLocation,
  kTopicIdBreadcrumbLocation,
  locationChange,
  retrieveStudies,
  setBreadcrumbs,
  tappedNode,
  updateStudy
} from "src/actions";
import { fullName, kFromAlgo, User, userRoleString } from "src/api";
import {
  kCommunityRootUrl,
  kLibraryRootUrl,
  kModerationMenuUrlKey,
  kProfileUrlKey,
  kSearchUrlKey,
  kStudiesUrlKey,
  kUsersRootUrl,
  kVariablesMenuUrlKey,
  kVerify
} from "src/config/routes";
import { IBreadcrumbLocation, IStoreState } from "src/store";

export const redirectOnAlgoClose: Epic = action$ =>
  action$.pipe(
    filter(isActionOf(closeAlgorithm)),
    map(() => push(kLibraryRootUrl))
  );

export const openOnAlgoCreate: Epic = action$ =>
  action$.pipe(
    filter(isActionOf(createAlgorithm.success)),
    map(action =>
      push(`${kLibraryRootUrl}/undefined/${action.payload.apiResponse.data.id}`)
    )
  );

export const loadLinkedAlgo: Epic = (
  action$,
  state$: StateObservable<IStoreState>
) =>
  action$.pipe(
    filter(isActionOf(tappedNode)),
    map(action => {
      const algoId = action.payload.openAlgorithm.algorithm.id;
      const openAlgo = state$.value.algoStore.openAlgorithms.find(
        oa => oa.algorithm.id === algoId
      );
      if (openAlgo) {
        const {
          algoState: { decisionsJson }
        } = openAlgo;
        const last = decisionsJson[decisionsJson.length - 1];
        if (last && last.nextAlgoTargetId) {
          return of(
            push(
              `${kLibraryRootUrl}/undefined/${last.nextAlgoTargetId}?${kFromAlgo}=${algoId}`
            )
          );
        }
      }
      return undefined;
    }),
    flatMap(op => (op ? op : of(ignoreElements)))
  );

const addUserBreadcrumb = (user: User, breadcrumbs: IBreadcrumbLocation[]) => {
  breadcrumbs.push({
    icon: IconNames.USER,
    subTitle: userRoleString(user.role),
    title: fullName(user)
  });
};

const breadcrumbsForLibrary = (components: string[], store: IStoreState) => {
  const breadcrumbs: IBreadcrumbLocation[] = [];
  let targetLocation = kLibraryRootUrl;

  breadcrumbs.push({
    icon: IconNames.HOME,
    subTitle: "",
    targetLocation,
    title: "Library"
  });

  if (components.length > kTopicIdBreadcrumbLocation) {
    // Next is a topic UUID
    targetLocation += `/${components[kTopicIdBreadcrumbLocation]}`;
    let algorithmID;
    let nodeID;

    if (components.length > kAlgorithmIdBreadcrumbLocation) {
      // Next is an algorithm ID, so we can optimise the topic lookup...
      algorithmID = components[kAlgorithmIdBreadcrumbLocation];
    }

    if (components.length > kNodeIdBreadcrumbLocation) {
      nodeID = components[kNodeIdBreadcrumbLocation];
    }

    if (algorithmID) {
      const algorithm = store.algoStore.allAlgorithms[algorithmID];
      if (algorithm) {
        if (components[1] === "undefined") {
          breadcrumbs.push({
            subTitle: "",
            targetLocation,
            title: "No specialty"
          });
        } else {
          for (const specialty of algorithm.medicalSpecialties) {
            if (specialty.id === components[1]) {
              breadcrumbs.push({
                relatedObject: specialty,
                subTitle: "",
                targetLocation,
                title: specialty.topic
              });
            }
          }
        }
        const subTitle = `Modified ${moment(algorithm.updatedAt).format(
          "MMMM Do YYYY"
        )}`;
        targetLocation += `/${algorithmID}`;
        breadcrumbs.push({
          relatedObject: algorithm,
          subTitle,
          targetLocation,
          title: algorithm.title
        });
      }

      if (nodeID) {
        targetLocation += `/${nodeID}`;
        breadcrumbs.push({
          relatedObject: nodeID,
          subTitle: "",
          targetLocation,
          title: ""
        });
      }
    } else {
      for (const algo of Object.values(store.algoStore.allAlgorithms)) {
        const specialty = algo.medicalSpecialties.find(
          s => s.id === components[1]
        );

        if (specialty) {
          breadcrumbs.push({
            relatedObject: specialty,
            subTitle: "",
            targetLocation,
            title: specialty.topic
          });
          break;
        }
      }
    }
  }
  return breadcrumbs;
};

const breadcrumbsForCommunity = (components: string[], store: IStoreState) => {
  const breadcrumbs: IBreadcrumbLocation[] = [];
  const targetLocation = kCommunityRootUrl;

  breadcrumbs.push({
    icon: IconNames.HOME,
    subTitle: "",
    targetLocation,
    title: "Community"
  });

  if (components.length > 1) {
    // Next is a user UUID
    const user = store.usersStore.allUsers[components[1]];
    if (user) {
      addUserBreadcrumb(user, breadcrumbs);
    }
  }

  return breadcrumbs;
};

const breadcrumbsForProfile = (components: string[], store: IStoreState) => {
  const breadcrumbs: IBreadcrumbLocation[] = [];
  const targetLocation = kModerationMenuUrlKey;

  breadcrumbs.push({
    icon: IconNames.USER,
    subTitle: "",
    targetLocation,
    title: "Your Profile"
  });

  return breadcrumbs;
};

const breadcrumbsForModeration = (components: string[], store: IStoreState) => {
  const breadcrumbs: IBreadcrumbLocation[] = [];
  const targetLocation = kModerationMenuUrlKey;

  breadcrumbs.push({
    icon: IconNames.HOME,
    subTitle: "",
    targetLocation,
    title: "Moderation"
  });

  return breadcrumbs;
};

const breadcrumbsForUserAdmin = (components: string[], store: IStoreState) => {
  const breadcrumbs: IBreadcrumbLocation[] = [];
  const targetLocation = kUsersRootUrl;

  breadcrumbs.push({
    icon: IconNames.HOME,
    subTitle: "",
    targetLocation,
    title: "User admin."
  });

  if (components.length > 1) {
    const part = components[1];
    if (part === kVerify) {
      breadcrumbs.push({
        icon: IconNames.TICK,
        subTitle: "",
        targetLocation,
        title: "Verify User"
      });
    } else {
      // Next is a user UUID
      const user = store.usersStore.allUsers[part];
      if (user) {
        addUserBreadcrumb(user, breadcrumbs);
      }
    }
  }
  if (components.length > 2) {
    const part = components[2];
    // Next is a user UUID
    const user = store.usersStore.allUsers[part];
    if (user) {
      addUserBreadcrumb(user, breadcrumbs);
    }
  }
  return breadcrumbs;
};

const breadcrumbsForSearch = (components: string[], store: IStoreState) => {
  const breadcrumbs: IBreadcrumbLocation[] = [];
  const targetLocation = kSearchUrlKey;

  breadcrumbs.push({
    icon: IconNames.SEARCH,
    subTitle: "",
    targetLocation,
    title: "Search"
  });

  if (components.length > 1) {
    // Next is a search term
    breadcrumbs.push({
      subTitle: "",
      title: `'${components[1]}'`
    });
  }
  return breadcrumbs;
};

const breadcrumbsForVariables = (components: string[], store: IStoreState) => {
  const breadcrumbs: IBreadcrumbLocation[] = [];
  const targetLocation = kVariablesMenuUrlKey;

  breadcrumbs.push({
    icon: IconNames.VARIABLE,
    subTitle: "",
    targetLocation,
    title: "Variables"
  });

  return breadcrumbs;
};

const breadcrumbsForStudies = (components: string[], store: IStoreState) => {
  const breadcrumbs: IBreadcrumbLocation[] = [];
  const targetLocation = kStudiesUrlKey;

  breadcrumbs.push({
    icon: IconNames.STACKED_CHART,
    subTitle: "",
    targetLocation,
    title: "Studies"
  });

  if (components.length > 1) {
    const studyId = components[1];
    const study = store.studiesStore.studies[studyId];
    if (study) {
      breadcrumbs.push({
        icon: IconNames.STACKED_CHART,
        relatedObject: study,
        subTitle: `Modified ${moment(study.updatedAt).format("MMMM Do YYYY")}`,
        targetLocation,
        title: study.title
      });
    }
  }

  return breadcrumbs;
};

const breadCrumbsFromLocation = (location: string, store: IStoreState) => {
  const components = location.split("/").slice(1);

  if (location.startsWith(kLibraryRootUrl)) {
    return breadcrumbsForLibrary(components, store);
  } else if (location.startsWith(kSearchUrlKey)) {
    return breadcrumbsForSearch(components, store);
  } else if (location.startsWith(kCommunityRootUrl)) {
    return breadcrumbsForCommunity(components, store);
  } else if (location.startsWith(kUsersRootUrl)) {
    return breadcrumbsForUserAdmin(components, store);
  } else if (location.startsWith(kModerationMenuUrlKey)) {
    return breadcrumbsForModeration(components, store);
  } else if (location.startsWith(kProfileUrlKey)) {
    return breadcrumbsForProfile(components, store);
  } else if (location.startsWith(kVariablesMenuUrlKey)) {
    return breadcrumbsForVariables(components, store);
  } else if (location.startsWith(kStudiesUrlKey)) {
    return breadcrumbsForStudies(components, store);
  }
  return [];
};

export const updateBreadcrumbs: Epic = (action$, store$) =>
  action$.pipe(
    filter(a => {
      return (
        isActionOf(
          [
            locationChange,
            getAlgorithm.success,
            getTopics.success,
            getUser.success,
            retrieveStudies.success
          ],
          a
        ) || isActionOf([updateStudy.success], a)
      );
    }),
    map(() =>
      breadCrumbsFromLocation(
        store$.value.router.location.pathname,
        store$.value
      )
    ),
    map(setBreadcrumbs)
  );
