import { H5, H6, Spinner } from "@blueprintjs/core";
import { groupBy } from "lodash";
import moment from "moment";
import * as React from "react";
import { connect } from "react-redux";

import { retrieveEvents } from "src/actions";
import {
  Event,
  EventCategory,
  EventType,
  eventTypeString,
  fullName,
  sortByCreatedDate,
  User,
  userRoleString
} from "src/api";
import { IStoreState } from "src/store";

export interface IUserEventsProps {
  loggedInUser?: User;
  user: User;
}

interface IUserEventsInjectedProps {
  authoredEvents: Event[];
  eventsAffecting: Event[];
  busy: boolean;
}

interface IUserEventsDispatchProps {
  getEvents: typeof retrieveEvents.request;
}

type UserEventProps = IUserEventsProps &
  IUserEventsInjectedProps &
  IUserEventsDispatchProps;

class UserEventsComponent extends React.PureComponent<UserEventProps> {
  constructor(props: UserEventProps) {
    super(props);
    this.state = {
      busy: true
    };
  }

  public componentDidMount() {
    const { getEvents, user } = this.props;

    getEvents({
      category: EventCategory.User,
      refId: user.id,
      sourceRefId: user.id
    });
  }

  public render() {
    const { busy, eventsAffecting, authoredEvents } = this.props;

    if (busy) {
      return <Spinner />;
    }
    return (
      <article className="animated fadeIn faster">
        <section className="bg-white pa2 br3 w-100 zx-shadow">
          <H5>User history</H5>
          {eventsAffecting.map(this.renderEvent)}
        </section>
        <section className="bg-white mv2 pa2 br3 w-100 zx-shadow">
          <H5>Events by this user</H5>
          {authoredEvents.map(this.renderAuthoredEvent)}
        </section>
      </article>
    );
  }

  private renderEvent = (e: Event) => {
    if (
      [
        EventType.UserBlocked,
        EventType.UserCreated,
        EventType.UserUpdated,
        EventType.UserVerified
      ].includes(e.kind)
    ) {
      const eventString =
        e.content &&
        e.content.newRole &&
        ` to ${userRoleString(e.content.newRole)} ${e.author &&
          `, by ${fullName(e.author)} (${e.author.email})`}`;

      return (
        <div key={e.id} className="flex">
          <H6>{moment(e.createdAt).format("MMM Do YYYY")}</H6>
          <span className="ph1">
            {`- ${eventTypeString(e.kind)}`}
            {eventString}
          </span>
        </div>
      );
    }
    return null;
  };

  private renderAuthoredEvent = (e: Event) => {
    if (
      [
        EventType.UserBlocked,
        EventType.UserUpdated,
        EventType.UserVerified
      ].includes(e.kind)
    ) {
      const eventString =
        e.content && e.content.newRole
          ? `${e.content.title} to ${userRoleString(e.content.newRole)}`
          : e.content && e.content.title && `${e.content.title} (${e.refId})`;

      return (
        <div key={e.id} className="flex">
          <H6>{moment(e.createdAt).format("MMM Do YYYY")}</H6>
          <span className="ph1">
            {`- ${eventTypeString(e.kind)} `}
            {eventString}
          </span>
        </div>
      );
    }
    return null;
  };
}

const mapStateToProps = (
  { eventsStore, uiStore }: IStoreState,
  props: IUserEventsProps
): IUserEventsInjectedProps => {
  const userEvents = Object.values(eventsStore.events).filter(
    e => e.category === EventCategory.User
  );
  const userEventsToUser = groupBy(userEvents, e => e.refId);
  const userEventsByUser = userEvents.filter(
    e => e.author && e.author.id === props.user.id
  );

  return {
    authoredEvents: sortByCreatedDate(userEventsByUser, false) || [],
    busy: uiStore.events.eventsLoadersCount > 0,
    eventsAffecting:
      sortByCreatedDate(userEventsToUser[props.user.id], false) || []
  };
};

export const UserEvents = connect(mapStateToProps, {
  getEvents: retrieveEvents.request
})(UserEventsComponent);
