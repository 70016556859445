import { Button } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import * as React from "react";
import * as ReactDOM from "react-dom";
import {
  Field,
  FieldArray,
  InjectedFormProps,
  reduxForm,
  WrappedFieldArrayProps
} from "redux-form";

import { setEditState } from "src/actions";
import { Algorithm } from "src/api";
import { FormArrayField, NodeReferences } from "src/components";
import { IOpenAlgorithm } from "src/store";

export interface IRenderWrappedFieldArrayProps<T>
  extends WrappedFieldArrayProps<T> {
  className?: string;
  label: string;
}

export interface IAlgoEditRefsFormProps {
  className?: string;
  focusedField?: string;
  openAlgorithm: IOpenAlgorithm;
  setEditState: typeof setEditState;
}

class AlgoEditRefsFormComponent extends React.PureComponent<
  InjectedFormProps<Algorithm, IAlgoEditRefsFormProps> & IAlgoEditRefsFormProps
> {
  public render() {
    const { className, focusedField, handleSubmit, openAlgorithm } = this.props;

    return (
      <form
        className={`pa2 w-100 flex flex-column ${className}`}
        onSubmit={handleSubmit}
      >
        <div className="flex flex-column items-start">
          <div className="flex flex-row">
            <Button
              icon={IconNames.BOLD}
              disabled={!focusedField}
              onClick={this.doBoldThings}
              onMouseDown={this.preventFocusChange}
            />
            <Button
              icon={IconNames.ITALIC}
              disabled={!focusedField}
              onClick={this.doItalicThings}
              onMouseDown={this.preventFocusChange}
            />
          </div>
        </div>
        <FieldArray
          name="references"
          component={this.renderRefs}
          label="references"
        />
        <NodeReferences
          openAlgorithm={openAlgorithm}
          setEditState={this.props.setEditState}
        />
      </form>
    );
  }

  private preventFocusChange = (e: React.MouseEvent) => {
    e.preventDefault();
  };

  private doBoldThings = () => {
    this.insertMarkdown("b");
  };

  private doItalicThings = () => {
    this.insertMarkdown("i");
  };

  private insertMarkdown = (type: "b" | "i") => {
    const { focusedField } = this.props;
    if (focusedField) {
      // eslint-disable-next-line react/no-find-dom-node
      const form = ReactDOM.findDOMNode(this);
      if (form && form instanceof Element) {
        const input = (form as Element).querySelector(`#${focusedField}`);
        if (input instanceof HTMLTextAreaElement) {
          const start = input.selectionStart;
          const operator = type === "b" ? "**" : "_";
          const end = input.selectionEnd + operator.length;

          input.value =
            input.value.slice(0, start) + operator + input.value.slice(start);
          input.value =
            input.value.slice(0, end) + operator + input.value.slice(end);
          const event = new Event("change");
          input.dispatchEvent(event);
        }
      }
    }
  };

  private renderRefs = (props: IRenderWrappedFieldArrayProps<string>) => {
    const { fields } = props;

    const addRef = () => {
      fields.push("New Reference");
    };

    const fieldRenderer = (field: any, i: number) => {
      const removeRef = () => {
        fields.remove(i);
      };
      return (
        <div className="flex flex-row items-center w-100" key={i} id={`${i}`}>
          <Field
            className="zx-input-blue-border flex flex-auto w-100"
            component={FormArrayField}
            name={field}
            type="textarea"
          />
          <Button icon={IconNames.REMOVE} minimal={true} onClick={removeRef} />
          <Button icon={IconNames.ADD} minimal={true} onClick={addRef} />
        </div>
      );
    };

    return (
      <div className="w-100 flex flex-auto">
        <div className="w-100 flex flex-column items-start">
          {fields.map(fieldRenderer)}
          {fields.length === 0 && (
            <Button icon={IconNames.ADD} minimal={true} onClick={addRef} />
          )}
        </div>
      </div>
    );
  };
}

export const AlgoEditRefsForm = reduxForm<Algorithm, IAlgoEditRefsFormProps>(
  {}
)(AlgoEditRefsFormComponent);
