import { Dictionary } from "lodash";

import { AlgoNode, AlgoNodeType, textForNodeType } from "src/api";
import { IOpenAlgorithm } from "src/store";
import { nodeTitleTextPlain, truncateString } from "src/utilities";
import {
  AlgoErrorSeverity,
  createValidationIssue,
  IAlgoError,
  verifyMultiChoiceWeights
} from ".";
import { checkVars } from "./variables-validator";

const checkAllConnected = (openAlgo: IOpenAlgorithm): IAlgoError[] => {
  const errors: IAlgoError[] = [];
  const { algorithm: algo, algoNodes } = openAlgo;

  const remainingNodes = { ...algoNodes };
  if (!algo.nodes) {
    return errors;
  }
  const startNodes = algo.nodes.filter(n => n.kind === AlgoNodeType.entryPoint);

  const checkNodeAndTargets = (node: AlgoNode | undefined) => {
    if (node) {
      if (remainingNodes[node.id]) {
        delete remainingNodes[node.id];

        if (node.kind === AlgoNodeType.singleSelect) {
          node.options(algoNodes).forEach(opt => {
            if (opt.node) {
              opt.node
                .targets(algoNodes)
                .forEach(t => checkNodeAndTargets(t.node));
              delete remainingNodes[opt.node.id];
            }
          });
        } else {
          node.targets(algoNodes).forEach(t => checkNodeAndTargets(t.node));
          node.options(algoNodes).forEach(o => {
            if (o.node) {
              delete remainingNodes[o.node.id];
            }
          });
        }
      }
    }
  };

  startNodes.forEach(sn => {
    checkNodeAndTargets(sn);
  });

  // Report any unconnected.
  Object.values(remainingNodes)
    .filter(n => n.algorithmId === algo.id)
    .forEach(n => {
      if (n.paths.length === 0 && n.kind === AlgoNodeType.choice) {
        errors.push(
          createValidationIssue(
            `${textForNodeType(n.kind)} node '${truncateString(
              nodeTitleTextPlain(n)
            )}' is not connected - it has no paths. Contact an administrator to fix.`,
            n.id,
            "nodes"
          )
        );
      } else {
        if (n.backwardLinks().length === 0) {
          const containerNode = algoNodes[n.containerId()];
          if (containerNode) {
            errors.push(
              createValidationIssue(
                `${textForNodeType(n.kind)} node '${truncateString(
                  nodeTitleTextPlain(n)
                )}' ${
                  n.kind === AlgoNodeType.choice
                    ? `(in decision node ${truncateString(
                        containerNode
                          ? nodeTitleTextPlain(containerNode)
                          : "Unknown parent node"
                      )})`
                    : ""
                } is not connected`,
                n.id,
                "nodes"
              )
            );
          }
        }
      }
    });
  return errors;
};

const checkQuestionNodeTitle = (
  node: AlgoNode,
  algoNodes: Dictionary<AlgoNode>
) => {
  const errors: IAlgoError[] = [];
  let missingTitle = false;
  let missingQuestion = false;
  let badlyFormatted = false;

  if (node.isDecision()) {
    const titleLines = node.title.split("\n");
    if (titleLines.length < 2) {
      badlyFormatted = true;
      if (titleLines.length < 1) {
        missingQuestion = true;
      }
    } else {
      if (titleLines[0].length < 2) {
        missingTitle = true;
      }
      if (titleLines[1].length < 2) {
        missingQuestion = true;
      }
    }
  }
  if (missingTitle) {
    errors.push(
      createValidationIssue(
        `${textForNodeType(node.kind)} node '${truncateString(
          nodeTitleTextPlain(node)
        )}' is missing a title`,
        node.id,
        "nodes"
      )
    );
  }
  if (missingQuestion) {
    errors.push(
      createValidationIssue(
        `${textForNodeType(node.kind)} node '${truncateString(
          nodeTitleTextPlain(node)
        )}' is missing a question`,
        node.id,
        "nodes"
      )
    );
  }
  if (badlyFormatted) {
    errors.push(
      createValidationIssue(
        `${textForNodeType(node.kind)} node '${truncateString(
          nodeTitleTextPlain(node)
        )}' is missing details`,
        node.id,
        "nodes"
      )
    );
  }
  return errors;
};

const checkNodeContent = (
  node: AlgoNode,
  openAlgo: IOpenAlgorithm
): IAlgoError[] => {
  const { algoNodes, algorithm } = openAlgo;
  const errors: IAlgoError[] = [];
  if (node.algorithmId !== algorithm.id) {
    return errors;
  }

  const { localVars, variables } = algorithm;
  const containerNode: AlgoNode | undefined = algoNodes[node.containerId()];
  const combinedVariables = localVars
    ? [...variables, ...Object.values(localVars)]
    : variables;

  if (node.kind !== AlgoNodeType.entryPoint) {
    if (!node.title || node.title.length === 0) {
      errors.push(
        createValidationIssue(
          `${textForNodeType(node.kind)} node '${truncateString(
            nodeTitleTextPlain(node)
          )}' is missing a title`,
          node.id,
          "nodes"
        )
      );
    }

    if (
      (!node.info || node.info.length < 2) &&
      containerNode &&
      containerNode.kind !== AlgoNodeType.varInput
    ) {
      errors.push(
        createValidationIssue(
          `${textForNodeType(node.kind)} node '${truncateString(
            nodeTitleTextPlain(node)
          )}' has no info section`,
          node.id,
          "nodes",
          AlgoErrorSeverity.Warning,
          "info"
        )
      );
    }

    if (
      node.kind !== AlgoNodeType.terminal &&
      node.forwardLinks().length === 0
    ) {
      if (
        containerNode &&
        [
          AlgoNodeType.singleSelect,
          AlgoNodeType.varDecision,
          AlgoNodeType.varProcessor
        ].includes(containerNode.kind)
      ) {
        errors.push(
          createValidationIssue(
            `${textForNodeType(node.kind)} node '${truncateString(
              nodeTitleTextPlain(node)
            )}' is missing any onward connections`,
            node.id,
            "nodes"
          )
        );
      }
    }

    switch (node.kind) {
      case AlgoNodeType.choice:
        break;

      case AlgoNodeType.intermediate:
        break;

      case AlgoNodeType.multiSelect:
        errors.push(...verifyMultiChoiceWeights(node, algoNodes));
        errors.push(...checkQuestionNodeTitle(node, algoNodes));
        break;

      case AlgoNodeType.singleSelect:
        errors.push(...checkQuestionNodeTitle(node, algoNodes));
        break;

      case AlgoNodeType.terminal:
        break;

      case AlgoNodeType.varProcessor:
      case AlgoNodeType.varDecision:
      case AlgoNodeType.varInput:
        errors.push(...checkVars(node, algoNodes, combinedVariables));
        break;

      default:
    }
  }
  return errors;
};

export const validateNodes = (openAlgo: IOpenAlgorithm): IAlgoError[] => {
  const errors: IAlgoError[] = checkAllConnected(openAlgo);

  const { algorithm: algo } = openAlgo;
  algo.nodes.forEach(n => {
    errors.push(...checkNodeContent(n, openAlgo));
  });

  return errors;
};
