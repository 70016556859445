import { Alert, Button, ButtonGroup, Divider, Intent } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import * as React from "react";
import { connect } from "react-redux";
import { SortableContainer, SortableElement } from "react-sortable-hoc";

import { cloneDeep } from "lodash";
import { removePath, updatePath, linkNodes } from "src/actions";
import {
  AlgoNode,
  AlgoNodePath,
  AlgoNodeType,
  Algorithm,
  IVariableOperation,
  Parameter,
  ParameterWithIndex,
  VariableOperator
} from "src/api";
import {
  NodeTargetRenderer,
  ParameterSelect,
  VariableComponentRenderer,
  VariableCriteriaUpdater
} from "src/components";
import { IOpenAlgorithm, IStoreState, ParameterType } from "src/store";
import { SortHandle } from ".";

export interface INodeVarCalculationProps {
  hideDragHandle?: boolean;
  path: AlgoNodePath;
  openAlgorithm: IOpenAlgorithm;
  parentNode: AlgoNode;
}

interface INodeVarCalculationInjectedProps {
  otherAlgorithms: Algorithm[];
}

interface INodeVarCalculationDispatchProps {
  linkNodes: typeof linkNodes;
  updatePath: typeof updatePath;
  removePath: typeof removePath;
}

interface INodeVarCalculationComponentState {
  deleteWarnOpen: boolean;
}

type NodeVarCalculationComponentProps = INodeVarCalculationProps &
  INodeVarCalculationInjectedProps &
  INodeVarCalculationDispatchProps;

class NodeVarCalculationComponent extends React.PureComponent<
  NodeVarCalculationComponentProps,
  INodeVarCalculationComponentState
> {
  constructor(props: NodeVarCalculationComponentProps) {
    super(props);

    this.state = {
      deleteWarnOpen: false
    };
  }

  public render() {
    const {
      hideDragHandle,
      openAlgorithm,
      otherAlgorithms,
      parentNode,
      path
    } = this.props;
    const { deleteWarnOpen } = this.state;

    const handleConfirm = () => this.setState({ deleteWarnOpen: true });
    const handleCancelDelete = () => this.setState({ deleteWarnOpen: false });

    const varCount = path.paramsJson ? path.paramsJson.length : 0;

    const processorSection = (
      <>
        <p className="flex flex-auto yellow pl1">then change</p>
        {this.renderParameterTarget(path, openAlgorithm)}
      </>
    );

    const targetObject = {
      node: path.childId ? openAlgorithm.algoNodes[path.childId] : undefined,
      path
    };

    const decisionSection = (
      <>
        <p className="flex flex-auto yellow pl1">then go to</p>
        <NodeTargetRenderer
          parent={parentNode}
          target={targetObject}
          openAlgorithm={openAlgorithm}
          otherAlgorithms={otherAlgorithms}
          updatePath={this.props.updatePath}
          linkNodes={this.props.linkNodes}
        />
      </>
    );
    const hideRemove =
      (targetObject.node &&
        targetObject.node.kind === AlgoNodeType.sharedText) ||
      false;

    const removeButton = !hideRemove && (
      <ButtonGroup>
        <Button
          icon={IconNames.REMOVE}
          minimal={true}
          onClick={handleConfirm}
        />
      </ButtonGroup>
    );

    const headerSection = varCount > 0 && (
      <div className="flex flex-row items-start justify-between pb1">
        <p className="flex flex-auto yellow pl1">
          {varCount > 1
            ? "if the following are true"
            : "if the following is true"}
        </p>
        {removeButton}
      </div>
    );

    return (
      <section
        key={path.id}
        className="flex flex-column pb2 zx-bg-charcoal-grey"
      >
        <Alert
          canEscapeKeyCancel={true}
          canOutsideClickCancel={true}
          cancelButtonText="Cancel"
          onCancel={handleCancelDelete}
          confirmButtonText="Delete"
          onConfirm={this.handleRemove}
          isOpen={deleteWarnOpen}
          icon={IconNames.TRASH}
          intent={Intent.DANGER}
        >
          <p>Are you sure you wish to remove this calculation?</p>
        </Alert>
        <div className="flex flex-row item-start">
          {!hideDragHandle && <SortHandle />}
          <div className="flex flex-column flex-auto">
            {headerSection}
            <VariableCriteriaUpdater
              path={path}
              openAlgo={openAlgorithm}
              updatePath={this.props.updatePath}
            />
            {parentNode.kind === AlgoNodeType.varProcessor && processorSection}
            {parentNode.kind === AlgoNodeType.varDecision && decisionSection}
          </div>
        </div>
        <Divider className="bg-white" />
      </section>
    );
  }

  private renderParameterTarget = (
    path: AlgoNodePath,
    openAlgo: IOpenAlgorithm
  ) => {
    const { paramsJson } = path;
    const {
      algorithm: { id, localVars, variables }
    } = openAlgo;

    if (!paramsJson) {
      return null;
    }
    const targetParams = paramsJson.filter(
      vu => vu.resultDetails !== undefined
    )[0];
    const combinedVariables = localVars
      ? [
          ...variables.filter(v => v.output === true),
          ...Object.values(localVars)
        ]
      : variables.filter(v => v.output === true);

    let paramVal;
    let varType: ParameterType | undefined;
    let resultDetails: IVariableOperation | undefined;

    if (targetParams) {
      resultDetails = targetParams.resultDetails;

      const targetParam = resultDetails
        ? combinedVariables.find(v => v.id === targetParams.id)
        : undefined;

      if (targetParam) {
        varType = targetParam.detailsJson[0].type;
        paramVal = {
          ...targetParam,
          valueIndex: targetParams.groupNumber || 0
        };
      }
    }

    const handleVarChange = (
      newTargetParam: Parameter,
      index: number | undefined
    ) => {
      this.handleTargetChange(
        path,
        { ...newTargetParam, valueIndex: index },
        id
      );
    };

    return (
      <div className="w-100 pb2">
        <ParameterSelect
          items={combinedVariables}
          onChange={handleVarChange}
          value={paramVal}
        />
        <VariableComponentRenderer
          detailsIndex={0}
          openAlgo={openAlgo}
          paramVal={paramVal}
          path={path}
          resultDetails={resultDetails}
          updatePath={this.props.updatePath}
          varType={varType}
        />
      </div>
    );
  };

  private handleTargetChange = (
    path: AlgoNodePath,
    param: ParameterWithIndex,
    algoId: string
  ) => {
    const { paramsJson } = path;
    if (paramsJson) {
      const existingTarget = paramsJson.filter(vu => vu.resultDetails)[0];
      const newPath = cloneDeep(path);

      if (existingTarget) {
        newPath.paramsJson = paramsJson.map(v => {
          if (!v.resultDetails) {
            return v;
          }

          const newUsage = {
            ...v,
            groupNumber: param.valueIndex,
            id: param.id,
            resultDetails: {}
          };
          return newUsage;
        });
      } else {
        // Need to add default operators
        const type = param.detailsJson[param.valueIndex || 0].type;
        let operator;
        switch (type) {
          case "numeric":
            operator = VariableOperator.add;
        }
        newPath.paramsJson = [
          ...paramsJson,
          {
            groupNumber: param.valueIndex,
            id: param.id,
            resultDetails: {
              operator
            }
          }
        ];
      }

      this.props.updatePath({
        algoId,
        path: newPath
      });
    }
  };

  private handleRemove = () => {
    const {
      path,
      openAlgorithm: { algorithm }
    } = this.props;

    this.props.removePath({ algoId: algorithm.id, path });
  };
}

const mapStateToProps = (
  { algoStore }: IStoreState,
  props: INodeVarCalculationProps
): INodeVarCalculationInjectedProps => {
  const otherAlgorithms = Object.values(algoStore.allAlgorithms).filter(
    a => a.id !== props.openAlgorithm.algorithm.id
  );

  return {
    otherAlgorithms
  };
};

export const NodeVarCalculation = connect(mapStateToProps, {
  linkNodes,
  updatePath,
  removePath
})(NodeVarCalculationComponent);

export const SortableNodeVarCalculation = SortableElement(
  ({ props }: { props: INodeVarCalculationProps }) => (
    <NodeVarCalculation {...props} />
  )
);

export const SortableCalculations = SortableContainer(
  ({ items }: { items: INodeVarCalculationProps[] }) => {
    const wrapper = (props: any, i: number) => (
      <SortableNodeVarCalculation index={i} key={props.path.id} props={props} />
    );
    return <div>{items.map(wrapper)}</div>;
  }
);
