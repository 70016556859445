import { IconNames } from "@blueprintjs/icons";
import Konva from "konva";

import { AlgoNodeType, AlgoNodePath } from "src/api";
import {
  createChoiceNode,
  createTextArea,
  IAlgoNodeRenderProps,
  kCardBgBlue1,
  kCharcoalGray,
  kDisabledGrey,
  kOrange,
  kCornerRadius,
  kEditGrey,
  kMainNodeMinHeight,
  kMinHeight,
  kNodeWidth
} from ".";
import { createOutputNode } from "./output-node";
import {
  createAddNodeControl,
  createAlgoLinkVisual,
  createAnchorPoint,
  createBlueprintIcon,
  IRenderedNode,
  kBackgroundShadow,
  dragFunc,
  shouldShowSelection
} from "./utilities";

export const createAlgoNode = (
  props: IAlgoNodeRenderProps,
  path?: AlgoNodePath
): IRenderedNode | undefined => {
  const { nodeId, algoNodes, x, y } = props;
  const allNodes = algoNodes();
  const node = allNodes[nodeId];
  if (!node) {
    return undefined;
  }
  const { kind } = node;
  const subOuts = {};

  let height = 0;
  let dash;
  let fill = "white";
  let textColour = kCharcoalGray;

  let stroke;
  const anchorYoffset = kMinHeight;

  switch (kind) {
    case AlgoNodeType.intermediate:
      fill = kCardBgBlue1;
      textColour = "white";
      break;

    case AlgoNodeType.varProcessor:
    case AlgoNodeType.varDecision:
      fill = path ? "white" : kEditGrey;
      dash = [4, 2];
      stroke = path ? kDisabledGrey : kCharcoalGray;
      break;
  }

  const showSelection = shouldShowSelection(props, path);
  if (showSelection) {
    stroke = kOrange;
  }

  // Top level container for this node
  const containerGroup: Konva.Group = new Konva.Group({
    dragBoundFunc: path ? undefined : dragFunc(props, () => containerGroup),
    draggable: path ? false : true,
    id: `${path ? `${node.id}=${path.id}` : `${node.id}`}=group`,
    x: x !== undefined ? x : node.x,
    y: y !== undefined ? y : node.y
  });

  // Text

  if (node.isDecision()) {
    const mainTitle = createTextArea({
      fontSize: 14,
      fontStyle: "bold",
      id: `${node.id}=mainTitleText`,
      text: node.cachedMainTitle.trim(),
      textColour: kCharcoalGray,
      width: kNodeWidth,
      x: 0,
      y: -30
    });
    containerGroup.add(mainTitle);
  }

  const titleText = createTextArea({
    fontSize: 14,
    fontStyle: "bold",
    id: `${node.id}=titleText`,
    text: node.cachedTitle.trim(),
    textColour,
    verticalAlign: "middle",
    width: kNodeWidth - 85,
    x: 30,
    y: 2
  });
  const titleTextAreaHeight = titleText.getHeight();

  height += titleTextAreaHeight;
  containerGroup.add(titleText);

  const textYLoc = Math.max(height, anchorYoffset + 10);
  // Adjust Title to full height available.
  const diff = anchorYoffset + 20 - height;
  if (diff > 0) {
    titleText.height(titleTextAreaHeight + diff);
  }

  const theRestText = node.cachedRest.trim();
  if (theRestText.length > 0) {
    const textBox = createTextArea({
      id: `${node.id}=mainText`,
      text: theRestText,
      textColour,
      width: kNodeWidth - 10,
      x: 5,
      y: textYLoc
    });
    height = textYLoc + textBox.getHeight();
    containerGroup.add(textBox);
  }

  height = Math.max(height, kMainNodeMinHeight);

  // Background for the main node
  const bgRect = new Konva.Rect({
    cornerRadius: kCornerRadius,
    dash: showSelection ? undefined : dash,
    fill,
    height,
    id: `${path ? `${node.id}=bg=${path.id}` : `${node.id}`}=bg`,
    ...(!stroke ? kBackgroundShadow : undefined),
    stroke,
    strokeWidth: showSelection ? 3 : 1,
    width: kNodeWidth
  });
  containerGroup.add(bgRect);
  bgRect.moveToBottom();

  if (!path) {
    // Move the group so that the link lines hit their visual points.
    containerGroup.offsetY(anchorYoffset);
  }

  // Anchor points, on top of everything else.

  const apIn = createAnchorPoint({
    alpha: path ? 0 : 1,
    id: `${node.id}=in`,
    y: anchorYoffset
  });
  containerGroup.add(apIn);

  // Type icon
  containerGroup.add(
    createBlueprintIcon({
      colour: textColour,
      id: `${node.id}=typeIcon`,
      name: node.icon(),
      scale: 1.2,
      x: 15,
      y: anchorYoffset - 10
    })
  );

  // Icons - these are 16 wide at 1x
  if (node.hasComments()) {
    containerGroup.add(
      createBlueprintIcon({
        colour: textColour,
        id: `${node.id}=commentIcon`,
        name: IconNames.COMMENT,
        x: kNodeWidth - 60,
        y: anchorYoffset - 10
      })
    );
  }
  if (node.hasNotes()) {
    containerGroup.add(
      createBlueprintIcon({
        colour: textColour,
        id: `${node.id}=notesIcon`,
        name: IconNames.ANNOTATION,
        x: kNodeWidth - 35,
        y: anchorYoffset - 10
      })
    );
  }

  // Output connector
  let apOut;
  if (
    !path &&
    [
      AlgoNodeType.intermediate,
      AlgoNodeType.varInput,
      AlgoNodeType.varProcessor
    ].includes(node.kind)
  ) {
    apOut = createAnchorPoint({
      id: `${node.id}=out`,
      x: kNodeWidth,
      y: anchorYoffset
    });
    containerGroup.add(apOut);
    const target = node.targets(algoNodes())[0];
    if (target) {
      createAlgoLinkVisual({
        container: containerGroup,
        height,
        idIcon: `${node.id}=algo=${target.path.targetAlgorithmId}`,
        idLine: `${nodeId}=algoLink=${target.path.id}`,
        target
      });
    }
  }

  // Choice nodes if applicable
  if (
    [
      AlgoNodeType.singleSelect,
      AlgoNodeType.multiSelect,
      AlgoNodeType.varInput
    ].includes(node.kind)
  ) {
    node.options(allNodes).forEach(o => {
      if (o.node) {
        height += path ? 6 : 4;
        const choiceNode = createChoiceNode({
          ...props,
          nodeId: o.node.id,
          parent: node,
          path: o.path,
          x: 0,
          y: height
        });
        if (choiceNode) {
          height += choiceNode.height;
          containerGroup.add(choiceNode.group);
          if (node.kind === AlgoNodeType.singleSelect) {
            subOuts[o.path.id] = choiceNode.anchorOut;
          }
        }
      }
    });

    height += 4;
    const addNewChoice = createAddNodeControl({
      addKind: AlgoNodeType.choice,
      bgColour: path ? "white" : undefined,
      parent: node,
      y: height
    });
    containerGroup.add(addNewChoice.group);
    height += addNewChoice.height;
    addNewChoice.group.setZIndex(0); // So the shadow doesn't interfere
  }

  if (
    node.kind === AlgoNodeType.multiSelect ||
    node.kind === AlgoNodeType.varDecision
  ) {
    // Add outs.
    node.targets(algoNodes()).forEach(p => {
      height += 4;
      const outputNode = createOutputNode({
        ...props,
        nodeId: p.path.childId || "unconnected",
        parent: node,
        path: p.path,
        y: height
      });
      if (outputNode) {
        height += outputNode.height;
        containerGroup.add(outputNode.group);
        subOuts[p.path.id] = outputNode.anchorOut;
      }
    });

    height += 4;
    const addNewOutput = createAddNodeControl({
      parent: node,
      y: height
    });
    containerGroup.add(addNewOutput.group);
    height += addNewOutput.height;
    addNewOutput.group.setZIndex(0); // So the shadow doesn't interfere
  }

  return {
    anchorIn: apIn,
    anchorOut: apOut,
    group: containerGroup,
    height,
    links: {},
    subOuts
  };
};
