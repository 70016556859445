import {
  Dialog,
  Classes,
  Tabs,
  Tab,
  Icon,
  IconName,
  Button,
  Intent
} from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import { cloneDeep } from "lodash";
import * as React from "react";
import { connect } from "react-redux";

import { setEditState, updateNodeText, removeNodes } from "src/actions";
import { AlgoNode } from "src/api";
import { IOpenAlgorithm } from "src/store";
import { NodeEditAttributesPanel } from ".";
import { nodeTextPartsPlain, sortAlphabetically } from "src/utilities";

export interface ISharedNodeEditDialogProps {
  openAlgorithm: IOpenAlgorithm;
}

interface ISharedNodeEditDialogDispatchProps {
  removeNodes: typeof removeNodes;
  setEditState: typeof setEditState;
  updateNodeText: typeof updateNodeText;
}

type SharedNodeEditDialogProps = ISharedNodeEditDialogProps &
  ISharedNodeEditDialogDispatchProps;

interface ISharedNodeEditDialogState {
  updatedText?: string;
}

export class SharedNodeEditDialogComponent extends React.PureComponent<
  SharedNodeEditDialogProps,
  ISharedNodeEditDialogState
> {
  constructor(props: SharedNodeEditDialogProps) {
    super(props);
    const {
      openAlgorithm: {
        algoNodes,
        editingState: { editSharedNodeId }
      }
    } = props;

    let updatedText;
    if (editSharedNodeId) {
      const node = algoNodes[editSharedNodeId];
      if (node) {
        updatedText = node.title;
      }
    }
    this.state = {
      updatedText
    };
  }

  public componentDidUpdate(oldProps: SharedNodeEditDialogProps) {
    const {
      openAlgorithm: {
        algoNodes,
        editingState: { editSharedNodeId }
      }
    } = this.props;
    const {
      openAlgorithm: {
        editingState: { editSharedNodeId: oldSharedNodeId }
      }
    } = oldProps;

    if (editSharedNodeId !== oldSharedNodeId) {
      let updatedText;
      if (editSharedNodeId) {
        const node = algoNodes[editSharedNodeId];
        if (node) {
          updatedText = node.title;
        }
      }
      this.setState({ updatedText });
    }
  }

  public render() {
    const isOpen =
      this.props.openAlgorithm.editingState.editSharedNodeId !== undefined;

    return (
      <Dialog
        enforceFocus={false}
        icon={IconNames.LABEL}
        title={"Edit Shared Text"}
        isOpen={isOpen}
        onOpened={this.setFocus}
        onClose={this.closeDialog}
        style={{ minHeight: "400px" }}
      >
        {this.renderBody()}
      </Dialog>
    );
  }

  private renderBody = () => {
    const { openAlgorithm } = this.props;
    const { updatedText } = this.state;

    const {
      algoNodes,
      editingState: { editSharedNodeId }
    } = openAlgorithm;

    const node = editSharedNodeId ? algoNodes[editSharedNodeId] : undefined;
    if (!node) {
      return null;
    }

    const displayNode = cloneDeep(node);
    if (updatedText) {
      displayNode.title = updatedText;
    }
    const usedIn = node.backwardLinks().map(l => algoNodes[l.parentId]);

    const attrsPanel = (
      <NodeEditAttributesPanel
        allowTextAreaResize={true}
        disableDelete={true}
        openAlgorithm={openAlgorithm}
        node={displayNode}
        removeNodes={this.props.removeNodes}
        updateNodeText={this.updateNodeText}
      />
    );

    const body = (
      <section key="body" className={`${Classes.DIALOG_BODY} overflow-y-auto`}>
        <section className="flex flex-row flex-auto w-100">
          <Tabs className="w-10 flex flex-column flex-auto">
            <Tab id="attrs" panel={attrsPanel} title="Shared Text" />
            <Tab id="usage" panel={this.usedInPanel(usedIn)} title="Used In" />
          </Tabs>
        </section>
      </section>
    );
    return [
      body,
      this.renderFooter(
        displayNode,
        (updatedText && updatedText.localeCompare(node.title) !== 0) || false
      )
    ];
  };

  private renderFooter = (updated: AlgoNode, modified: boolean) => {
    const saveAndClose = () => this.saveAndClose(updated);

    return (
      <div className={`${Classes.DIALOG_FOOTER}`} key="footer">
        <div className="flex flex-row justify-between pt2">
          <Button id="cancel" text="Cancel" onClick={this.closeDialog} />
          <Button
            id="updated"
            disabled={!modified}
            intent={Intent.SUCCESS}
            text="Update"
            onClick={saveAndClose}
          />
        </div>
      </div>
    );
  };

  private usedInPanel = (parents: AlgoNode[]) => {
    const orderedParents = parents
      .map(n => ({
        id: n.id,
        title: nodeTextPartsPlain(n).title,
        icon: n.icon() as IconName
      }))
      .sort((a, b) => sortAlphabetically(a.title, b.title));

    const rowRenderer = (node: {
      id: string;
      title: string;
      icon: IconName | undefined;
    }) => {
      return (
        <section
          className="w-100 flex b bb b--gray pv2 pb1 mb1 zx-blue"
          key={node.id}
        >
          <Icon className="pr1" icon={node.icon} />
          <div>{node.title}</div>
        </section>
      );
    };

    return (
      <article>
        <div className="pb1 b bb b--gray">Node Name</div>
        {orderedParents.map(rowRenderer)}
      </article>
    );
  };

  private setFocus = () => {
    setTimeout(() => {
      // TODO: Set the focus
    }, 100);
  };

  private closeDialog = async () => {
    const {
      openAlgorithm: {
        algorithm: { id }
      }
    } = this.props;
    this.props.setEditState({
      algoId: id,
      state: { editSharedNodeId: undefined }
    });
  };

  private updateNodeText = (node: AlgoNode) => {
    this.setState({ updatedText: node.title });
  };

  private saveAndClose = (node: AlgoNode) => {
    const { openAlgorithm } = this.props;

    this.props.updateNodeText({
      algoId: openAlgorithm.algorithm.id,
      node
    });
    this.closeDialog();
  };
}

export const SharedNodeEditDialog = connect(null, {
  removeNodes,
  setEditState,
  updateNodeText
})(SharedNodeEditDialogComponent);
