import { Button, H4, H5, Icon, Spinner } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import * as React from "react";

import { setEditState } from "src/actions";
import { CollapsiblePanel } from "src/components/collapsible-panel";
import { IOpenAlgorithm } from "src/store";
import {
  AlgoErrorSeverity,
  IAlgoError
} from "./validation/algorithm-validator";

export interface IAlgorithmValidationReportProps {
  errorSelectHandler: (error: IAlgoError) => void;
  handleClose: () => void;
  openAlgorithm: IOpenAlgorithm;

  setEditState: typeof setEditState;
}

export class AlgorithmValidationReport extends React.PureComponent<
  IAlgorithmValidationReportProps
> {
  public render() {
    const {
      openAlgorithm: {
        editingState: { validationErrors }
      }
    } = this.props;

    const errors = validationErrors && validationErrors.errors;
    const warnings = validationErrors && validationErrors.warnings;

    return (
      <article
        className="animated fadeIn faster zx-shadow pa2 br2 overflow-y-auto"
        style={{ maxHeight: "75vh" }}
      >
        <div className="w-100 flex flex-row justify-between">
          <div className="flex flex-row pt2 pl2">
            <H4>Syntax Issues</H4>
          </div>
          <Button
            icon={IconNames.CROSS}
            minimal={true}
            onClick={this.props.handleClose}
          />
        </div>
        {!validationErrors && <Spinner className="pa3 center" />}
        {this.reportBody(errors, warnings)}
      </article>
    );
  }

  private reportBody = (errors?: IAlgoError[], warnings?: IAlgoError[]) => {
    if (!errors || !warnings) {
      return null;
    }
    const noErrors = <H5>No validation errors found</H5>;

    const warningRenderer = (
      <CollapsiblePanel title="Warnings" expandInitially={false}>
        {warnings.map(this.renderError)}
      </CollapsiblePanel>
    );
    return (
      <section className="flex flex-column br3 w-100">
        {errors.length === 0 && noErrors}
        <>
          {errors.map(this.renderError)}
          {warnings.length > 0 && warningRenderer}
        </>
      </section>
    );
  };

  private renderError = (ae: IAlgoError, index: number) => {
    const { errorSelectHandler } = this.props;

    const onClick = () => errorSelectHandler(ae);
    const isError = ae.severity === AlgoErrorSeverity.Error;

    return (
      <section key={index} className={"flex pointer ph2"} onClick={onClick}>
        <Icon
          icon={isError ? IconNames.ERROR : IconNames.WARNING_SIGN}
          className={`mr2 ${isError ? "red" : "yellow"}`}
          iconSize={14}
        />
        <p>{ae.issueDetails}</p>
      </section>
    );
  };
}
