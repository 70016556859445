import { sortAlphabetically } from "./text";
import { Dictionary } from "lodash";

export * from "./text";

// Typescript force array type
export function notEmpty<TValue>(
  value: TValue | null | undefined
): value is TValue {
  return value !== null && value !== undefined;
}

export function moveIndex<T>(from: number, to: number, array: T[]) {
  array.splice(to, 0, array.splice(from, 1)[0]);
}

// For forms
export const disableEnterSubmit = (e: React.KeyboardEvent<HTMLFormElement>) => {
  const key = e.charCode || e.keyCode || 0;
  if (key === 13) {
    e.preventDefault();
  }
};

export async function asyncForEach<T>(
  array: T[],
  callback: (object: T, index: number, array: T[]) => void
) {
  for (let index = 0; index < array.length; index++) {
    await callback(array[index], index, array);
  }
}

export const focusFieldOnMount = (input: HTMLInputElement | null) =>
  input && input.focus();

export function compareArraysById<
  T extends { id: string; equals?: (v: T) => boolean }
>(oldArray: T[], newArray: T[]) {
  const sortedOld = oldArray.sort((a, b) => sortAlphabetically(a.id, b.id));
  const sortedNew = newArray.sort((a, b) => sortAlphabetically(a.id, b.id));

  const added: Dictionary<T> = {};
  const updated: Dictionary<T> = {};
  const deleted: Dictionary<T> = {};

  let indexCurrent = 0;
  let indexPrev = 0;
  const maxLoops = Math.max(sortedOld.length, sortedNew.length);

  while (indexCurrent < maxLoops) {
    const current = sortedNew[indexCurrent];
    const prev = sortedOld[indexPrev];

    if ((prev === undefined && current) || (current && current.id < prev.id)) {
      // Added
      added[current.id] = current;
      indexCurrent++;
      continue;
    }

    if ((!current && prev) || (prev && current.id > prev.id)) {
      // Removed
      deleted[prev.id] = prev;
      indexPrev++;
      continue;
    }

    if (prev && current && current.equals && !current.equals(prev)) {
      updated[current.id] = current;
    }
    indexCurrent++;
    indexPrev++;
  }

  return { added, deleted, updated };
}

export const preventDefault = (e: React.MouseEvent) => e.preventDefault();
