import { Button } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import * as React from "react";
import { SortEnd } from "react-sortable-hoc";

import { createPath, reorderPaths, setEditState } from "src/actions";
import { AlgoNode } from "src/api";
import { IOpenAlgorithm } from "src/store";
import { SortableCalculations } from "./node-var-calculation";

interface INodeEditCalculationsPanelProps {
  createPath: typeof createPath;
  node?: AlgoNode;
  openAlgorithm: IOpenAlgorithm;
  reorderPaths: typeof reorderPaths;
  setEditState: typeof setEditState;
  updateNode: (node: AlgoNode) => void;
}

export class NodeEditCalculationsPanel extends React.PureComponent<
  INodeEditCalculationsPanelProps
> {
  public render() {
    const { node, openAlgorithm } = this.props;
    const { algoNodes } = openAlgorithm;

    if (!node) {
      return null;
    }

    const items = node.calcs(algoNodes).map(calc => ({
      openAlgorithm,
      parentNode: node,
      path: calc.path
    }));

    const addButton = (
      <Button
        icon={IconNames.ADD}
        minimal={true}
        onClick={this.createNewCalc}
        text="Add Operation"
      />
    );

    return (
      <section key={node.id} className="w-100">
        <SortableCalculations
          items={items}
          lockAxis="y"
          lockToContainerEdges={true}
          onSortEnd={this.handleChoiceSort}
          useDragHandle={true}
        />
        {addButton}
      </section>
    );
  }

  private handleChoiceSort = (sort: SortEnd) => {
    const {
      openAlgorithm: {
        algorithm: { id }
      },
      node
    } = this.props;
    const { newIndex, oldIndex } = sort;

    if (node && newIndex !== oldIndex) {
      this.props.reorderPaths({
        algoId: id,
        newIndex,
        nodeId: node.id,
        oldIndex,
        type: "calcs"
      });
    }
  };

  private createNewCalc = () => {
    const {
      node,
      openAlgorithm: { algorithm }
    } = this.props;
    if (node) {
      this.props.createPath({
        algoId: algorithm.id,
        nodeId: node.id,
        varDetails: [{}]
      });
    }
  };
}
