import { Tab, Tabs } from "@blueprintjs/core";
import * as React from "react";

import { User } from "src/api";
import { IOpenAlgorithm } from "src/store";
import { AlgoContributors, AlgoIntro, AlgoRefs } from ".";

export interface IAlgoInfoProps {
  algoDetails: IOpenAlgorithm;
  className?: string;
  style?: React.CSSProperties;
  loggedInUser?: User;
  onClick?: () => void;
}

export class AlgoInfo extends React.PureComponent<IAlgoInfoProps> {
  public render() {
    const { algoDetails, className, loggedInUser, style } = this.props;
    const handleTabChange = (
      newTabId: React.ReactText,
      prevTabId: React.ReactText,
      e: React.MouseEvent<HTMLElement>
    ) => {
      e.stopPropagation();
    };

    return (
      <section style={style} className={`${className}`}>
        <section className="flex flex-auto pa2 pr0 br3 bg-white w-100 h-100">
          <Tabs
            className="flex flex-column flex-auto pa2 pr1 w-100"
            defaultSelectedTabId="intro"
            onChange={handleTabChange}
          >
            <Tab
              className="flex flex-auto overflow-y-auto"
              id="intro"
              panel={<AlgoIntro algoDetails={algoDetails} />}
              title="Introduction"
            />
            <Tab
              className="flex flex-auto overflow-y-auto"
              id="refs"
              panel={<AlgoRefs algoDetails={algoDetails} />}
              title="References"
            />
            <Tab
              className="flex flex-auto overflow-y-auto"
              id="contribs"
              panel={
                <AlgoContributors
                  algoDetails={algoDetails}
                  loggedInUser={loggedInUser}
                />
              }
              title="Contributors"
            />
          </Tabs>
        </section>
      </section>
    );
  }
}
