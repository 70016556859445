import { Dictionary } from "lodash";
import moment from "moment";

import { ISelectionData, IVariableValue } from "src/store";
import { BaseModel } from "..";

export class AlgoState extends BaseModel {
  public friendlyName = `Saved ${moment(new Date()).toLocaleString()}`;

  public decisionsJson: ISelectionData[] = [];
  public variablesJson: Dictionary<IVariableValue> = {};
}
