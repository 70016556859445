import { Classes, H5, Icon, Spinner } from "@blueprintjs/core";
import { push } from "connected-react-router";
import * as React from "react";
import { connect } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router-dom";

import { IconNames } from "@blueprintjs/icons";
import { uniqBy } from "lodash";
import {
  AlgorithmSearchResult,
  fullName,
  User,
  userIsContributor,
  userMatchFilter
} from "src/api";
import { kCommunityRootUrl, kLibraryRootUrl } from "src/config/routes";
import { IStoreState } from "src/store";
import { AlgoSearchResult } from "./algorithms";

interface ISearchResultsProps extends RouteComponentProps<{ query: string }> {
  algoSearchResults: AlgorithmSearchResult[];
  busy: boolean;
  openSearchResults: AlgorithmSearchResult[];
  query: string;
  push: typeof push;
  userSearchResults: User[];
}

class SearchResultsPageComponent extends React.PureComponent<
  ISearchResultsProps
> {
  public render() {
    const {
      busy,
      query,
      algoSearchResults,
      openSearchResults,
      userSearchResults
    } = this.props;

    if (
      algoSearchResults.length === 0 &&
      openSearchResults.length === 0 &&
      userSearchResults.length === 0
    ) {
      return <section className="pa2 flex-auto w-100">No results</section>;
    }

    const searchResultMapper = (r: AlgorithmSearchResult) => {
      const goToAlgo = () => this.goToAlgorithm(r);
      return (
        <section key={r._id}>
          {this.renderSectionHeader(r._source.title, 1, query, goToAlgo)}
          <AlgoSearchResult
            result={r}
            query={query}
            push={this.props.push}
            isCurrent={true}
          />
        </section>
      );
    };
    const algoResultMapper = (r: AlgorithmSearchResult) => (
      <AlgoSearchResult
        key={r._id}
        result={r}
        query={query}
        push={this.props.push}
        isCurrent={false}
      />
    );
    const searchResultsHeader =
      algoSearchResults.length > 0 &&
      this.renderSectionHeader("Algorithms", algoSearchResults.length, query);
    const userResultsHeader =
      userSearchResults.length > 0 &&
      this.renderSectionHeader("Contributors", algoSearchResults.length, query);

    const body = busy ? (
      <Spinner />
    ) : (
      <>
        {openSearchResults.map(searchResultMapper)}
        {searchResultsHeader}
        {algoSearchResults.map(algoResultMapper)}
        {userResultsHeader}
        {userSearchResults.map(u => this.renderUserResult(u))}
      </>
    );
    return (
      <section className="pa2 flex-auto w-100 mv2 overflow-y-auto overflow-x-hidden animated fadeIn faster">
        {body}
      </section>
    );
  }

  private renderSectionHeader = (
    title: string,
    resultCount: number,
    term: string,
    onClick?: () => void
  ) => {
    return (
      <>
        <div
          className={`${Classes.DARK} ${
            onClick ? "pointer" : ""
          } w-100 zx-backmost-card-blue pv1`}
          onClick={onClick}
        >
          <p className="ttu pl2 mb0 white">{title}</p>
        </div>
        {resultCount === 0 && (
          <p className="pa2">{`No results for search term: ${term}`}</p>
        )}
      </>
    );
  };

  private renderUserResult = (u: User) => {
    const goToContributor = () => {
      this.props.push(`${kCommunityRootUrl}/${u.id}`);
    };

    const cssStyle = {
      paddingBottom: "0.25em",
      paddingTop: "0.25em"
    };

    return (
      <div key={u.id} className="pa1 pointer" onClick={goToContributor}>
        <H5 style={cssStyle}>
          <Icon
            className="mr2 zx-blue-extra-light"
            icon={IconNames.PERSON}
            iconSize={14}
          />
          {`${fullName(u as User)}`}
          {u.title && (
            <span style={{ fontWeight: 300 }}>{` (${u.title})`}</span>
          )}
        </H5>
      </div>
    );
  };

  private goToAlgorithm = (r: AlgorithmSearchResult) =>
    this.props.push(this.pushUrl(r));

  private pushUrl = (r: AlgorithmSearchResult) => {
    const { id, medicalSpecialties } = r._source;
    const specialty =
      medicalSpecialties && medicalSpecialties[0] && medicalSpecialties[0].id;
    return `${kLibraryRootUrl}/${specialty}/${id}`;
  };
}

const mapStateToProps = (
  { algoStore, uiStore, usersStore }: IStoreState,
  ownProps: ISearchResultsProps
) => {
  const openAlgoIds = algoStore.openAlgorithms.map(a => a.algorithm.id);
  const query = ownProps.match.params.query;

  const algoSearchResults = algoStore.searchResults.filter(
    s => !openAlgoIds.includes(s._source.id)
  );
  const openSearchResults = algoStore.searchResults.filter(s =>
    openAlgoIds.includes(s._source.id)
  );

  const userSearchResults = uniqBy(
    Object.values(usersStore.allUsers)
      .filter(u => userMatchFilter(u, query))
      .filter(userIsContributor),
    v => v.id
  );

  return {
    algoSearchResults,
    busy: uiStore.search.searchLoadersCount > 0,
    openSearchResults,
    query,
    userSearchResults
  };
};

export const SearchResultsPage = withRouter(
  connect(mapStateToProps, { push })(SearchResultsPageComponent)
);
