import { Button, Dialog, Icon, Intent, Spinner } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import * as React from "react";
import ReactAvatarImageCropper from "react-avatar-image-cropper";
import { connect } from "react-redux";

import { postUserAvatar } from "src/actions";
import { IStoreState } from "src/store";

interface IAvatarEditDialogProps {
  isOpen: boolean;
  onClose: (imageUrl?: string) => void;
  postUserAvatar: typeof postUserAvatar.request;
}

interface IAvatarEditDialogState {
  imageUrl?: string;
}

class AvatarEditDialogComponent extends React.PureComponent<
  IAvatarEditDialogProps,
  IAvatarEditDialogState
> {
  constructor(props: IAvatarEditDialogProps) {
    super(props);
    this.state = {};
  }

  public render() {
    const { isOpen } = this.props;

    const hideAvatarEdit = () => this.props.onClose();
    const handleCroppedData = (data: Blob) => {
      const imageUrl = window.URL.createObjectURL(data);
      this.setState({
        imageUrl
      });
      this.props.postUserAvatar(data);
      this.props.onClose(imageUrl);
    };

    const style: React.CSSProperties = {
      backgroundColor: "var(--charcoal-grey-2)",
      height: "276px",
      width: "260px"
    };

    const cancelButton = (
      <Button
        className="ml4 mr2"
        key={0}
        icon={IconNames.CROSS}
        intent={Intent.NONE}
      />
    );
    const cropperActions = [
      cancelButton,
      <Button key={1} icon={IconNames.TICK} intent={Intent.SUCCESS} />
    ];
    const icon = (
      <Icon
        color="var(--edit-bg-grey)"
        className="w-100"
        icon={IconNames.USER}
        iconSize={120}
      />
    );
    const cropperProps = {
      actions: cropperActions,
      icon,
      rootStyle: {
        borderRadius: "50vh"
      },
      sliderDiv: {
        backgroundColor: "var(--charcoal-grey-2)",
        borderRadius: 4,
        paddingLeft: 10,
        paddingRight: 10
      },
      sliderBtnStyle: {
        backgroundColor: "var(--charcoal-grey)",
        marginLeft: -8
      },
      sliderConStyle: {
        backgroundColor: "none"
      },
      textStyle: { color: "var(--edit-bg-grey)" }
    };

    return (
      <Dialog
        icon={IconNames.CAMERA}
        isOpen={isOpen}
        onClose={hideAvatarEdit}
        title={"Choose a new profile image"}
      >
        <div className="mt2 pa2 pb4 center zx-shadow br3" style={style}>
          <ReactAvatarImageCropper
            apply={handleCroppedData}
            className="w-100"
            loadingNode={<Spinner />}
            {...cropperProps}
            text="Select image..."
          />
        </div>
      </Dialog>
    );
  }
}

const mapStateToProps = ({ uiStore, userStore }: IStoreState) => ({
  busy: uiStore.community.userLoadersCount > 0,
  error: uiStore.error,
  loggedInUser: userStore.loggedInUser
});

export const AvatarEditDialog = connect(mapStateToProps, {
  postUserAvatar: postUserAvatar.request
})(AvatarEditDialogComponent);
