import { cloneDeep } from "lodash";
import * as React from "react";

import { IChangeVarPayload, updatePath } from "src/actions";
import {
  AlgoNodePath,
  AlgoVariableType,
  detailsForVariableOperator,
  IVariableConstraint,
  IVariableOperation,
  IVariableOperatorDetails,
  IVariableUsage,
  Parameter,
  ParameterWithIndex,
  VariableOperator
} from "src/api";
import { IOpenAlgorithm, ParameterType } from "src/store";
import { VariableOperatorSelect } from ".";
import { ParameterInput } from "../parameters";

interface IVariableComponentRendererProps {
  detailsIndex: number;
  path: AlgoNodePath;
  openAlgo: IOpenAlgorithm;
  operatorDetails?: IVariableConstraint;
  resultDetails?: IVariableOperation;
  paramVal?: ParameterWithIndex;
  updatePath: typeof updatePath;
  varType?: ParameterType;
}

export class VariableComponentRenderer extends React.PureComponent<
  IVariableComponentRendererProps
> {
  public render() {
    const {
      detailsIndex,
      path,
      operatorDetails,
      openAlgo,
      paramVal,
      resultDetails,
      varType
    } = this.props;

    const paramRenderer = (
      param: ParameterWithIndex | undefined,
      varT: ParameterType | undefined
    ) => {
      if (param) {
        switch (varT) {
          case "numeric":
            return this.renderNumericComponent(
              param,
              path,
              openAlgo,
              detailsIndex,
              operatorDetails || resultDetails,
              resultDetails !== undefined
            );

          case "boolean":
            return this.renderBooleanComponent(
              param,
              path,
              openAlgo,
              operatorDetails || resultDetails,
              resultDetails !== undefined
            );
          case "discrete":
            return this.renderDiscreteComponent(
              param,
              path,
              openAlgo,
              operatorDetails || resultDetails,
              resultDetails !== undefined
            );
        }
      }
      return null;
    };

    return (
      <div className="flex flex-row items-start">
        {paramRenderer(paramVal, varType)}
      </div>
    );
  }

  private renderNumericComponent = (
    parameter: ParameterWithIndex,
    path: AlgoNodePath,
    openAlgo: IOpenAlgorithm,
    detailsIndex: number,
    paramOpValue?: IVariableConstraint | IVariableOperation,
    targetMode = false
  ) => {
    const {
      algorithm: { id }
    } = openAlgo;

    let operator;
    let operatorDetails: IVariableOperatorDetails | undefined;
    if (paramOpValue) {
      operator = paramOpValue.operator;
      operatorDetails = operator
        ? detailsForVariableOperator(operator)
        : undefined;
    }

    const handleOperatorChange = (value: VariableOperator) => {
      targetMode
        ? this.handleTargetOperatorChange(path, value, undefined, id)
        : this.handleOperatorChange(path, id, parameter, value);
    };

    const operatorDetailsRenderer = (
      operatorDeets: IVariableOperatorDetails
    ) => {
      const inputRenderer = operatorDeets.inputs.map((_, i) => {
        return [
          i > 0 && operatorDeets && operatorDeets.text[i] && (
            <span key={0} className="">
              {operatorDeets.text[i]}
            </span>
          ),
          this.renderParameterInput(
            path,
            openAlgo,
            parameter,
            i,
            paramOpValue,
            operatorDeets.inputs.length === i + 1,
            targetMode
          )
        ];
      });

      return (
        <div className="flex flex-row flex-auto items-center" key={0}>
          {inputRenderer}
        </div>
      );
    };

    const renderers = operatorDetails
      ? operatorDetailsRenderer(operatorDetails)
      : this.renderParameterInput(
          path,
          openAlgo,
          parameter,
          0,
          paramOpValue,
          true,
          targetMode
        );

    return (
      <div className="flex flex-row pv1">
        <VariableOperatorSelect
          className="mt1"
          decisionEnabled={targetMode ? false : true}
          onChange={handleOperatorChange}
          value={operator}
          variableType={AlgoVariableType.numeric}
        />
        {renderers}
      </div>
    );
  };

  private renderBooleanComponent = (
    parameter: ParameterWithIndex,
    path: AlgoNodePath,
    openAlgo: IOpenAlgorithm,
    paramOpValue?: IVariableConstraint | IVariableOperation,
    targetMode = false
  ) => {
    return [
      this.labelRenderer(targetMode),
      this.renderParameterInput(
        path,
        openAlgo,
        parameter,
        parameter.valueIndex || 0,
        paramOpValue,
        true,
        targetMode
      )
    ];
  };

  private labelRenderer(targetMode: boolean) {
    return (
      <span className="pt2 pl2" key="is">
        {targetMode ? "to" : "is"}
      </span>
    );
  }

  private renderDiscreteComponent = (
    parameter: ParameterWithIndex,
    path: AlgoNodePath,
    openAlgo: IOpenAlgorithm,
    paramOpValue?: IVariableConstraint | IVariableOperation,
    targetMode = false
  ) => {
    return [
      this.labelRenderer(targetMode),
      this.renderParameterInput(
        path,
        openAlgo,
        parameter,
        parameter.valueIndex || 0,
        paramOpValue,
        true,
        targetMode
      )
    ];
  };

  private renderParameterInput = (
    path: AlgoNodePath,
    openAlgo: IOpenAlgorithm,
    parameter: ParameterWithIndex,
    groupIndex: number,
    paramOpValue: IVariableConstraint | IVariableOperation | undefined,
    showUnits = false,
    targetMode = false
  ) => {
    const {
      algorithm: { id }
    } = openAlgo;

    const handleChange = (details: IChangeVarPayload) => {
      if (targetMode) {
        this.handleTargetVarValueChange(details, path, id /*groupIndex*/);
      } else {
        this.handleVarValueChange(details, path, id, groupIndex);
      }
    };

    const values = paramOpValue
      ? targetMode
        ? (paramOpValue as IVariableOperation).values || {}
        : (paramOpValue as IVariableConstraint).values[groupIndex]
      : {};

    return (
      <ParameterInput
        key={groupIndex}
        editMode={true}
        parameter={parameter}
        parameterIndex={parameter.valueIndex}
        onChange={handleChange}
        showTitle={false}
        showUnits={showUnits}
        values={values}
      />
    );
  };

  private handleTargetOperatorChange = (
    path: AlgoNodePath,
    operator: VariableOperator,
    values: any,
    algoId: string
  ) => {
    const { paramsJson } = path;
    if (paramsJson) {
      const newPath = cloneDeep(path);
      newPath.paramsJson = paramsJson.map(v => {
        if (!v.resultDetails) {
          return v;
        }

        const newUsage = {
          ...v,
          resultDetails: {
            ...v.resultDetails,
            operator
          }
        };
        return newUsage;
      });

      this.props.updatePath({
        algoId,
        path: newPath
      });
    }
  };

  private handleTargetVarValueChange = (
    details: IChangeVarPayload,
    path: AlgoNodePath,
    algoId: string
    // groupIndex = 0
  ) => {
    const { index, value } = details;
    const { paramsJson } = path;

    if (paramsJson && value) {
      const newPath = cloneDeep(path);
      newPath.paramsJson = paramsJson.map(v => {
        if (!v.resultDetails) {
          return v;
        }
        const newUsage = {
          ...v,
          resultDetails: {
            ...v.resultDetails,
            values: { ...v.resultDetails.values, [index]: value }
          }
        };
        return newUsage;
      });

      this.props.updatePath({
        algoId,
        path: newPath
      });
    }
  };

  private handleVarValueChange = (
    details: IChangeVarPayload,
    path: AlgoNodePath,
    algoId: string,
    groupIndex = 0
  ) => {
    // groupIndex is the index into the dictionary for a range of variables
    const { paramId, index, value } = details;
    // Index is the index into the dictionary of a grouped variable so usually zero.

    const { paramsJson } = path;

    if (paramsJson) {
      const newValues = paramsJson.map(
        (v: IVariableUsage): IVariableUsage => {
          if (v.id !== paramId) {
            return v;
          }
          const newConstraint: IVariableConstraint = v.operatorDetails
            ? {
                ...v.operatorDetails,
                values: {
                  ...v.operatorDetails.values,
                  [groupIndex]: {
                    ...v.operatorDetails.values[groupIndex],
                    [index]: value
                  }
                }
              }
            : {
                operator: VariableOperator.isEqualTo,
                values: {
                  [groupIndex]: { [index]: value }
                }
              };

          const newUsage = {
            ...v,
            operatorDetails: newConstraint
          };
          return newUsage;
        }
      );

      const newPath = cloneDeep(path);
      newPath.paramsJson = newValues;

      this.props.updatePath({
        algoId,
        path: newPath
      });
    }
  };

  private handleOperatorChange = (
    path: AlgoNodePath,
    algoId: string,
    param: Parameter,
    varOp: VariableOperator
  ) => {
    const { paramsJson } = path;
    if (paramsJson) {
      const newValues = paramsJson.map(
        (v: IVariableUsage): IVariableUsage => {
          if (v.id !== param.id) {
            return v;
          }
          const newValue: IVariableConstraint = v.operatorDetails
            ? {
                ...v.operatorDetails,
                operator: varOp
              }
            : {
                operator: varOp,
                values: {}
              };
          const newUsage = {
            ...v,
            operatorDetails: newValue
          };
          return newUsage;
        }
      );
      const newPath = cloneDeep(path);
      newPath.paramsJson = newValues;

      this.props.updatePath({
        algoId,
        path: newPath
      });
    }
  };
}
