import { IconName } from "@blueprintjs/core";
import { IconNames, IconSvgPaths16 } from "@blueprintjs/icons";
import Konva from "konva";
import { Vector2d } from "konva/types/types";
import { BaseLayer } from "konva/types/BaseLayer";
import { Dictionary } from "lodash";

import {
  AlgoNode,
  AlgoNodePath,
  AlgoNodeType,
  IAlgoNodeTarget,
  Parameter,
  PathType
} from "src/api";
import { sortAlphabetically } from "src/utilities";
import {
  createStartNode,
  kCardBgBlue2,
  kCharcoalGray,
  kCornerRadius,
  kEditGrey,
  kNodeWidth,
  createPageNode
} from ".";
import { createAlgoNode } from ".";

export interface IRenderedNode {
  anchorIn: Konva.Container | Konva.Circle | Konva.Group;
  anchorOut?: Konva.Container | Konva.Circle | Konva.Group;
  group: Konva.Group;
  height: number;
  links: Dictionary<IRenderedLink>;
  subOuts: Dictionary<Konva.Container | Konva.Circle>;
}

export interface IRenderedLink {
  link: Konva.Line;
  startOffset: Vector2d;
  type: "forward" | "back";
}

export interface IAlgoNodeRenderProps {
  algoNodes: () => Dictionary<AlgoNode>;
  interactionDisabled: () => boolean;
  nodeId: string;
  parameters: Parameter[];
  selectedNodeIds: () => Dictionary<number>;
  subSelectionId: () => string | undefined;
  linksForNode: (nodeId: string) => IRenderedLink[];
  linkLayer: Konva.FastLayer;
  x?: number; // Optional overrides
  y?: number;
}

export interface ISubNodeRenderProps extends IAlgoNodeRenderProps {
  path: AlgoNodePath;
  parent: AlgoNode;
}

interface IKonvaTextAreaProps {
  align?: string;
  textColour?: string;
  fontFamily?: string;
  fontStyle?: string;
  fontSize?: number;
  id?: string;
  listening?: boolean;
  padding?: number;
  text: string;
  verticalAlign?: string;
  width: number;
  x?: number;
  y?: number;
}

Konva.dragDistance = 5;

export const kBackgroundShadow = {
  shadowBlur: 4,
  shadowColor: "black",
  shadowOffset: { x: 0, y: 0 },
  shadowOpacity: 0.33
};

export const targetIdContext = (
  target: Konva.Shape | Konva.Stage | Konva.Line | Konva.Group
) => {
  const targetContext = target.id().split("=");
  return {
    context: targetContext[1],
    otherId: targetContext[2],
    pathId: targetContext[3],
    targetId: targetContext[0]
  };
};

export const topLevelContainerNodeForId = (nodeId: string, layer: BaseLayer) =>
  layer
    .find(`#${nodeId}=group`)
    .toArray()
    .filter((k: Konva.Node) => k.getParent() instanceof Konva.Layer);

export const removeNodeFromStageById = (stage: Konva.Stage, id: string) => {
  stage
    .find(`#${id}`)
    .toArray()
    .forEach(n => n.destroy());
};

export const createNodeWithProps = (
  props: IAlgoNodeRenderProps
): IRenderedNode | undefined => {
  const { algoNodes, nodeId } = props;
  const node = algoNodes()[nodeId];
  if (!node) {
    return;
  }
  switch (node.kind) {
    case AlgoNodeType.entryPoint:
      return createStartNode(props);

    case AlgoNodeType.singleSelect:
    case AlgoNodeType.multiSelect:
    case AlgoNodeType.intermediate:
    case AlgoNodeType.varInput:
    case AlgoNodeType.varProcessor:
    case AlgoNodeType.varDecision:
      // Don't render contained nodes.
      if (
        node.backwardLinks().filter(l => l.pathType === PathType.contained)
          .length > 0
      ) {
        return undefined;
      }
      return createAlgoNode(props);

    case AlgoNodeType.terminal:
    case AlgoNodeType.page:
      return createPageNode(props);

    default:
      return undefined;
  }
};

/**
 * Creates a Konva text area, filling in some defaults
 * @param params Properties for the text area
 */
export const createTextArea = ({
  align = "left",
  textColour = kCharcoalGray,
  fontFamily = "museo-sans, sans-serif",
  fontSize = 13,
  fontStyle,
  id,
  listening = false,
  padding = 10,
  text,
  verticalAlign,
  width,
  x = 0,
  y = 0
}: IKonvaTextAreaProps): Konva.Text => {
  return new Konva.Text({
    align,
    fill: textColour,
    fontFamily,
    fontSize,
    fontStyle,
    id,
    listening,
    padding,
    text,
    verticalAlign,
    width,
    x,
    y
  });
};

export const createAlgoLinkLine = ({
  id,
  points
}: {
  id: string;
  points: number[];
}) =>
  new Konva.Line({
    dash: [10, 5],
    id,
    lineCap: "round",
    points,
    stroke: kCardBgBlue2,
    strokeWidth: 4
  });

export const createKonvaLine = ({
  id,
  points
}: {
  id?: string;
  points: number[];
}) =>
  new Konva.Line({
    id,
    lineCap: "round",
    lineJoin: "bevel",
    listening: false,
    points,
    stroke: kCardBgBlue2,
    strokeWidth: 4,
    tension: 0
  });

export const createAnchorPoint = ({
  colour = kCardBgBlue2,
  alpha = 1,
  fill = "white",
  id,
  x,
  y
}: {
  alpha?: number;
  colour?: string;
  fill?: string;
  id: string;
  x?: number;
  y?: number;
}) => {
  return new Konva.Circle({
    fill,
    id,
    opacity: alpha,
    radius: 8,
    stroke: colour,
    strokeWidth: 4,
    x,
    y
  });
};

export const createBlueprintIcon = ({
  colour = "white",
  id,
  name,
  scale = 1,
  x,
  y
}: {
  colour?: string;
  id: string;
  name?: IconName;
  scale?: number;
  x: number;
  y: number;
}) => {
  const data = IconSvgPaths16[name || IconNames.DISABLE].join("; ");
  return new Konva.Path({
    data,
    fill: colour,
    id,
    listening: false,
    scaleX: scale,
    scaleY: scale,
    x,
    y
  });
};

export const createAlgoLinkVisual = ({
  container,
  height,
  idIcon,
  idLine,
  target,
  xOffset = kNodeWidth
}: {
  container: Konva.Group;
  height: number;
  idIcon: string;
  idLine: string;
  target: IAlgoNodeTarget;
  xOffset?: number;
}) => {
  if (target.path.targetAlgorithmId) {
    const points = [xOffset, height / 2, xOffset + 60, height / 2];
    const algoLinkLine = createAlgoLinkLine({ id: idLine, points });
    const algoIcon = createBlueprintIcon({
      colour: kCardBgBlue2,
      id: idIcon,
      name: IconNames.LAYER,
      scale: 2,
      x: xOffset + 60,
      y: height / 2 - 16
    });

    container.add(algoLinkLine);
    container.add(algoIcon);
    algoLinkLine.moveToBottom();
  }
};

export const createAddNodeControl = ({
  addKind,
  bgColour = kEditGrey,
  parent,
  x,
  y
}: {
  addKind?: AlgoNodeType;
  bgColour?: string;
  parent: AlgoNode;
  x?: number;
  y?: number;
}) => {
  let height = 0;
  const group = new Konva.Group({
    draggable: false,
    id: `${parent.id}=${addKind}-group`,
    x,
    y
  });

  group.add(
    createBlueprintIcon({
      colour: kCardBgBlue2,
      id: `${parent.id}=${addKind}-icon`,
      name: IconNames.ADD,
      x: 32,
      y: 8
    })
  );

  const text = createTextArea({
    id: `${parent.id}=${addKind}-text`,
    text:
      addKind === AlgoNodeType.choice
        ? parent.kind === AlgoNodeType.singleSelect
          ? "Answer"
          : parent.kind === AlgoNodeType.multiSelect
          ? "Option"
          : "Variable"
        : "Output",
    textColour: kCardBgBlue2,
    width: 75,
    x: 45
  });
  group.add(text);
  height += text.getHeight();

  // Background
  const bgRect = new Konva.Rect({
    cornerRadius: kCornerRadius,
    fill: bgColour,
    height,
    id: `${parent.id}=${addKind}`,
    opacity: 0.7,
    stroke: undefined,
    strokeWidth: 4,
    width: 90,
    x: 20
  });
  group.add(bgRect);
  bgRect.moveToBottom();

  return { group, height };
};

export const createLine = ({
  id,
  start,
  startOffset,
  end
}: {
  id?: string;
  end: AlgoNode;
  start: AlgoNode;
  startOffset: Vector2d;
}) => {
  const points = [
    start.x + startOffset.x,
    start.y + startOffset.y,
    end.x,
    end.y
  ];
  let lineId = id;
  if (!lineId) {
    const path = start.paths.find(p => p.childId === end.id) || end;
    lineId = `${start.id}=l=${end.id}=${path.id}`;
  }

  return createKonvaLine({ id: lineId, points });
};

export const createSubLink = ({
  konvaNode,
  node,
  subNode,
  targetNode
}: {
  konvaNode: IRenderedNode;
  node: AlgoNode;
  subNode: Konva.Container | Konva.Circle;
  targetNode: AlgoNode;
}) => {
  const startOffset = { x: 0, y: 0 };

  const circleInGroup = subNode.getPosition();
  const groupInParent = subNode.getParent().getPosition();
  konvaNode.group.offsetY();

  startOffset.x = groupInParent.x + circleInGroup.x;
  startOffset.y = groupInParent.y + circleInGroup.y - konvaNode.group.offsetY();

  return {
    line: createLine({
      end: targetNode,
      id: `${node.id}=l=${targetNode.id}=${subNode.id()}`,
      start: node,
      startOffset
    }),
    offset: startOffset
  };
};

export const stagePosForZoom = (
  stage: Konva.Stage,
  scale: number,
  centerZoom = true,
  pointerPosOverride?: Vector2d
) => {
  const stagePos = stage.position();
  const oldScale = stage.scaleX();

  let pointerPos;
  if (centerZoom) {
    const container = stage.getContent();
    const midInStageCoords = {
      x: container.clientWidth / 2,
      y: container.clientHeight / 2
    };
    pointerPos = midInStageCoords;
  } else {
    pointerPos = pointerPosOverride
      ? pointerPosOverride
      : stage.getPointerPosition() || { x: 0, y: 0 };
  }
  const mousePointTo = {
    x: (pointerPos.x - stagePos.x) / oldScale,
    y: (pointerPos.y - stagePos.y) / oldScale
  };

  const newPos = {
    x: pointerPos.x - mousePointTo.x * scale,
    y: pointerPos.y - mousePointTo.y * scale
  };
  return newPos;
};

export const getPointerPosition = (
  stage: Konva.Stage
): Vector2d | undefined => {
  const stageOffset = stage.position();
  const scale = stage.scaleX();
  const stagePos = stage.getPointerPosition();
  if (stagePos) {
    return {
      x: Math.floor((stagePos.x - stageOffset.x) / scale),
      y: Math.floor((stagePos.y - stageOffset.y) / scale)
    };
  }
  return undefined;
};

export const needsRerender = (old: AlgoNode, current: AlgoNode) => {
  if (
    old.title !== current.title ||
    old.hasComments() !== current.hasComments() ||
    old.kind !== current.kind ||
    old.x !== current.x ||
    old.y !== current.y
  ) {
    return true;
  }
  return false;
};

export const diffPaths = (old: AlgoNodePath[], newPaths: AlgoNodePath[]) => {
  const added = {};
  const updated = {};
  const deleted = {};

  const prevPaths = old.sort((a, b) => sortAlphabetically(a.id, b.id));
  const currentPaths = newPaths.sort((a, b) => sortAlphabetically(a.id, b.id));
  let indexCurrent = 0;
  let indexPrev = 0;

  while (indexCurrent < currentPaths.length) {
    const current = currentPaths[indexCurrent];
    const prev = prevPaths[indexPrev];

    if (prev === undefined || current.id < prev.id) {
      // Added
      added[current.id] = current;
      indexCurrent++;
      continue;
    }

    if (current.id > prev.id) {
      // Removed
      deleted[prev.id] = prev;
      indexPrev++;
      continue;
    }

    if (!current.equals(prev)) {
      updated[current.id] = current;
    }

    indexCurrent++;
    indexPrev++;
  }

  return { added, deleted, updated };
};

export const nodeDiff = (old: AlgoNode, current: AlgoNode) => {
  let isDifferent = false;

  const { added, deleted, updated } = diffPaths(old.paths, current.paths);

  if (
    !current.equals(old) ||
    Object.keys(added).length > 0 ||
    Object.keys(deleted).length > 0 ||
    Object.keys(updated).length > 0
  ) {
    isDifferent = true;
  }

  return {
    isDifferent,
    pathChanges: { added, deleted, updated }
  };
};

export const diffAlgoNodes = (
  prevNodes: Dictionary<AlgoNode>,
  currentNodes: Dictionary<AlgoNode>
) => {
  const added: Dictionary<AlgoNode> = {};
  const deleted: Dictionary<AlgoNode> = {};
  const updated: Dictionary<AlgoNode> = {};
  let addedPaths: Dictionary<AlgoNodePath> = {};
  let updatedPaths: Dictionary<AlgoNodePath> = {};
  let deletedPaths: Dictionary<AlgoNodePath> = {};

  const prevNodeIds = Object.keys(prevNodes).sort(sortAlphabetically);
  const currentNodeIds = Object.keys(currentNodes).sort(sortAlphabetically);
  let indexCurrent = 0;
  let indexPrev = 0;

  while (indexCurrent < currentNodeIds.length) {
    const current = currentNodeIds[indexCurrent];
    const prev = prevNodeIds[indexPrev];

    const updatedNode = currentNodes[current];
    const containerId = updatedNode.containerId();

    if (current < prev || prev === undefined) {
      // Node added
      added[current] = updatedNode;
      if (containerId !== current) {
        updated[containerId] = currentNodes[containerId];
      }
      indexCurrent++;
      continue;
    }

    if (current > prev) {
      // Node removed
      const prevNode = prevNodes[prev];
      const prevContainerId = prevNode.containerId();
      const stillExistingContainer = currentNodes[prevContainerId];
      if (prevContainerId !== prev && stillExistingContainer) {
        updated[prevContainerId] = stillExistingContainer;
      } else {
        deleted[prev] = prevNode;
      }
      indexPrev++;
      continue;
    }
    const compare = nodeDiff(prevNodes[prev], currentNodes[current]);
    if (compare.isDifferent) {
      updated[current] = updatedNode;
      if (containerId !== current) {
        updated[containerId] = currentNodes[containerId];
      }

      addedPaths = Object.assign(addedPaths, compare.pathChanges.added);
      updatedPaths = Object.assign(updatedPaths, compare.pathChanges.updated);
      deletedPaths = Object.assign(deletedPaths, compare.pathChanges.deleted);
    }
    indexCurrent++;
    indexPrev++;
  }

  return {
    added,
    deleted,
    pathsUpdates: {
      added: addedPaths,
      deleted: deletedPaths,
      updated: updatedPaths
    },
    updated
  };
};

export const dragFunc = (
  props: IAlgoNodeRenderProps,
  containerGroupAccessor: () => Konva.Group
): ((e: Vector2d) => Vector2d) => {
  const {
    algoNodes,
    interactionDisabled,
    linksForNode,
    linkLayer,
    nodeId,
    selectedNodeIds
  } = props;

  return (e: Vector2d) => {
    const containerGroup = containerGroupAccessor();
    if (interactionDisabled()) {
      return containerGroup.getAbsolutePosition();
    }

    const nodes = algoNodes();
    const n = nodes[nodeId];
    const pos = containerGroup.getPosition();
    if (!pos || !n) {
      return e;
    }

    const delta = { x: pos.x - n.x, y: pos.y - n.y };

    linksForNode(nodeId).forEach(rl => {
      if (rl.type === "forward") {
        const points = rl.link.points();
        points[0] = pos.x + rl.startOffset.x;
        points[1] = pos.y + rl.startOffset.y;
        rl.link.points(points);
      } else if (rl.type === "back") {
        const points = rl.link.points();
        points[2] = pos.x;
        points[3] = pos.y;
        rl.link.points(points);
      }
    });

    // Drag all selected nodes, if this node is part of the selection
    const selection = selectedNodeIds();
    if (selection[nodeId]) {
      const otherNodeIds = Object.keys(selection).filter(id => id !== nodeId);
      const nodeLayer = containerGroup.getLayer();
      if (nodeLayer) {
        otherNodeIds.forEach(id => {
          topLevelContainerNodeForId(id, nodeLayer).forEach(
            (kn: Konva.Node) => {
              const algoNode = nodes[id];
              const knPos = {
                x: algoNode.x + delta.x,
                y: algoNode.y + delta.y
              };
              kn.position(knPos);
              linksForNode(id).forEach(rl => {
                if (rl.type === "forward") {
                  const points = rl.link.points();
                  points[0] = knPos.x + rl.startOffset.x;
                  points[1] = knPos.y + rl.startOffset.y;
                  rl.link.points(points);
                } else if (rl.type === "back") {
                  const points = rl.link.points();
                  points[2] = knPos.x;
                  points[3] = knPos.y;
                  rl.link.points(points);
                }
              });
            }
          );
        });
        nodeLayer.batchDraw();
      }
    }
    linkLayer.batchDraw();
    return e;
  };
};

export const shouldShowSelection = (
  { selectedNodeIds, subSelectionId, algoNodes, nodeId }: IAlgoNodeRenderProps,
  context?: AlgoNodePath
) => {
  const selected = selectedNodeIds();
  const subSelected = subSelectionId();
  const allAlgoNodes = algoNodes();

  return (
    (subSelected === undefined || (context && context.id === subSelected)) && // Make sure no subselection, or matches context
    selected[nodeId] && // Make sure the node is actually in the selection
    !allAlgoNodes[nodeId] // Don't select parents of options
      .options(allAlgoNodes)
      .find(
        v => v.path.childId && Object.keys(selected).includes(v.path.childId)
      ) &&
    !allAlgoNodes[nodeId] // Don't select parents of contained
      .contained(allAlgoNodes)
      .find(
        v => v.path.childId && Object.keys(selected).includes(v.path.childId)
      )
  );
};
