import { Dictionary, omit } from "lodash";
import { getType } from "typesafe-actions";

import {
  createStudy,
  deleteStudy,
  ReduxAction,
  retrieveVisits,
  updateStudy,
  clearStudyError,
  createPatient,
  assignPatientToStudy,
  removePatientFromStudy,
  createStudyVisit,
  getPatient,
  retrievePatient
} from "src/actions";
import { retrieveStudies } from "src/actions";
import {
  arrayFromPayload,
  dictionaryFromPayload,
  objectFromPayload,
  Study,
  updateArray,
  Visit,
  Patient
} from "src/api";
import { IStudiesStore } from "src/store";

const INITIAL_STATE = {
  patients: {},
  requestedPatients: {},
  studies: {}
};

const updatedStudiesWithVisits = (
  visits: Visit[],
  studies: Dictionary<Study>
) => {
  const updatedStudies = { ...studies };
  visits.forEach(v => {
    if (v.studyId) {
      const study = studies[v.studyId];
      if (study) {
        study.visits = updateArray(study.visits || [], v);
      }
    }
  });
  return updatedStudies;
};

export const studiesReducer = (
  studiesStore: IStudiesStore = INITIAL_STATE,
  action: ReduxAction
) => {
  switch (action.type) {
    case getType(retrievePatient):
      return {
        ...studiesStore,
        requestedPatients: {
          ...studiesStore.requestedPatients,
          [action.payload]: action.payload
        }
      };

    case getType(getPatient.request):
      return {
        ...studiesStore,
        error: undefined,
        requestedPatients: omit(studiesStore, action.payload)
      };

    case getType(createStudy.request):
    case getType(retrieveStudies.request):
    case getType(retrieveVisits.request):
    case getType(createPatient.request):
    case getType(updateStudy.request):
    case getType(deleteStudy.request):
    case getType(assignPatientToStudy.request):
    case getType(removePatientFromStudy.request):
    case getType(createStudyVisit.request):
      return { ...studiesStore, error: undefined };

    case getType(createStudy.success):
    case getType(updateStudy.success):
    case getType(assignPatientToStudy.success):
    case getType(removePatientFromStudy.success):
      const study = objectFromPayload(action.payload.apiResponse.data, Study);

      if (!study) {
        return studiesStore;
      }
      return {
        ...studiesStore,
        studies: { ...studiesStore.studies, [study.id]: study }
      };

    case getType(retrieveStudies.success):
      const studies = dictionaryFromPayload(
        action.payload.apiResponse.data,
        Study
      );

      return {
        ...studiesStore,
        studies: { ...studiesStore.studies, ...studies }
      };

    case getType(retrieveVisits.success):
      const visits = arrayFromPayload(action.payload.apiResponse.data, Visit);

      return {
        ...studiesStore,
        studies: updatedStudiesWithVisits(visits, studiesStore.studies)
      };

    case getType(deleteStudy.success): {
      const deletedStudy = objectFromPayload(
        action.payload.apiResponse.data,
        Study
      );
      if (deletedStudy) {
        return {
          ...studiesStore,
          studies: omit(studiesStore.studies, deletedStudy.id)
        };
      }
      return studiesStore;
    }

    case getType(getPatient.success):
    case getType(createPatient.success):
      const patient = objectFromPayload(
        action.payload.apiResponse.data,
        Patient
      );
      if (patient) {
        return {
          ...studiesStore,
          patients: { ...studiesStore.patients, [patient.id]: patient }
        };
      }
      return studiesStore;

    case getType(createStudyVisit.success): {
      const visit = objectFromPayload(action.payload.apiResponse.data, Visit);
      if (visit && visit.studyId) {
        return {
          ...studiesStore,
          studies: updatedStudiesWithVisits([visit], studiesStore.studies)
        };
      }
      return studiesStore;
    }

    case getType(retrieveStudies.failure):
    case getType(retrieveVisits.failure):
    case getType(updateStudy.failure):
    case getType(createStudy.failure):
    case getType(deleteStudy.failure):
    case getType(createPatient.failure):
    case getType(getPatient.failure):
    case getType(assignPatientToStudy.failure):
    case getType(removePatientFromStudy.failure):
    case getType(createStudyVisit.failure):
      return {
        ...studiesStore,
        error: action.payload
      };

    case getType(clearStudyError):
      return {
        ...studiesStore,
        error: undefined
      };
    default:
      return studiesStore;
  }
};
