import { Button, ButtonGroup, Icon } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import { cloneDeep } from "lodash";
import * as React from "react";
import { SortableContainer, SortableElement } from "react-sortable-hoc";

import { updatePath } from "src/actions";
import {
  AlgoNodePath,
  AlgoNodeType,
  IVariableUsage,
  Parameter,
  VariableOperator
} from "src/api";
import { IOpenAlgorithm, ParameterType } from "src/store";
import { ParameterSelect, VariableComponentRenderer } from "..";

interface IVariableCriteriaUpdaterProps {
  openAlgo: IOpenAlgorithm;
  path: AlgoNodePath;
  updatePath: typeof updatePath;
}

export class VariableCriteriaUpdater extends React.PureComponent<
  IVariableCriteriaUpdaterProps
> {
  public render() {
    const { openAlgo, path } = this.props;
    const { paramsJson } = path;
    const {
      algorithm: { id }
    } = openAlgo;

    const createNewCriteria = () => {
      const newPath = cloneDeep(path);
      if (newPath.paramsJson) {
        newPath.paramsJson.push({ operatorDetails: undefined });
      } else {
        newPath.paramsJson = [{}];
      }
      this.props.updatePath({ algoId: id, path: newPath });
    };

    const renderParams =
      paramsJson &&
      paramsJson.map((_, i) =>
        this.renderParameterComponent(path, i, openAlgo)
      );

    return (
      <div className="w-100 pb2">
        {renderParams}
        <div className="pa2 pl3">
          <Button
            icon={IconNames.ADD}
            minimal={true}
            onClick={createNewCriteria}
            text="Add Variable Condition"
          />
        </div>
      </div>
    );
  }

  private renderParameterComponent = (
    path: AlgoNodePath,
    paramsIndex: number,
    openAlgo: IOpenAlgorithm
  ) => {
    const { paramsJson } = path;
    const {
      algorithm: { id, localVars, variables },
      algoNodes
    } = openAlgo;

    if (!paramsJson) {
      return null;
    }
    const childNode = (path.childId && algoNodes[path.childId]) || undefined;
    const showRemove =
      (childNode && childNode.kind === AlgoNodeType.sharedText) ||
      paramsJson.filter(p => !p.resultDetails).length > 1;

    const values = paramsJson[paramsIndex];
    if (values.resultDetails) {
      return null;
    }

    const combinedVariables = localVars
      ? [...variables, ...Object.values(localVars)]
      : variables;

    const parameter = combinedVariables.find(v => v.id === values.id);
    const groupNumber = values.groupNumber || 0;
    const paramVal = parameter
      ? { ...parameter, valueIndex: groupNumber }
      : undefined;

    let varType: ParameterType | undefined;
    if (parameter && parameter.detailsJson) {
      varType = parameter.detailsJson[0].type;
    }

    const operatorDetails = values.operatorDetails || undefined;

    const handleVarChange = (
      newParam: Parameter,
      groupIndex: number | undefined
    ) => {
      const newPath = cloneDeep(path);
      const newValue: IVariableUsage = {
        groupNumber: groupIndex,
        id: newParam.id
      };

      const paramId = groupIndex || 0;
      // Set default for boolean types
      if (newParam.detailsJson[paramId].type === "boolean") {
        newValue.operatorDetails = {
          operator: VariableOperator.isEqualTo,
          values: {
            [paramId]: { 0: true }
          }
        };
      }
      // Set default for discrete types
      if (newParam.detailsJson[paramId].type === "discrete") {
        newValue.operatorDetails = {
          operator: VariableOperator.isEqualTo,
          values: {
            [paramId]: {
              0: newParam.detailsJson[paramId].discreteValues![0].id
            }
          }
        };
      }
      if (newPath.paramsJson) {
        newPath.paramsJson = newPath.paramsJson.map((vu, i) =>
          i !== paramsIndex ? vu : newValue
        );
      } else {
        newPath.paramsJson = [newValue];
      }
      this.props.updatePath({ algoId: id, path: newPath });
    };

    const removeCriteria = () => {
      const newPath = cloneDeep(path);
      if (newPath.paramsJson) {
        newPath.paramsJson = newPath.paramsJson.filter(
          (_, i) => i !== paramsIndex
        );
      }
      this.props.updatePath({ algoId: id, path: newPath });
    };

    const parentNode = algoNodes[path.parentId];

    const filteredVariables =
      parentNode.kind === AlgoNodeType.varDecision
        ? combinedVariables
        : combinedVariables.filter(v => !v.output);

    return (
      <div className="w-100" key={paramsIndex}>
        <div className="w-100 flex flex-row flex-auto">
          <Icon className="mt2 mr1" icon={IconNames.VARIABLE} />
          <div className="flex flex-column flex-auto">
            <ParameterSelect
              items={filteredVariables}
              hideGroups={true}
              onChange={handleVarChange}
              value={paramVal}
            />
            <VariableComponentRenderer
              detailsIndex={paramsIndex}
              openAlgo={openAlgo}
              operatorDetails={operatorDetails}
              paramVal={paramVal}
              path={path}
              updatePath={this.props.updatePath}
              varType={varType}
            />
          </div>
          <ButtonGroup>
            <Button
              icon={IconNames.REMOVE}
              minimal={true}
              onClick={removeCriteria}
              style={{ visibility: showRemove ? "visible" : "hidden" }}
            />
          </ButtonGroup>
        </div>
      </div>
    );
  };
}

export const SortableCalculation = SortableElement(
  ({ props }: { props: IVariableCriteriaUpdaterProps }) => (
    <VariableCriteriaUpdater {...props} />
  )
);

export const SortableCalculations = SortableContainer(
  ({ items }: { items: IVariableCriteriaUpdaterProps[] }) => {
    const itemsRenderer = items.map((props, i) => (
      <SortableCalculation key={props.path.id} index={i} props={props} />
    ));
    return <div>{itemsRenderer}</div>;
  }
);
