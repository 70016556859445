import { Button, Spinner } from "@blueprintjs/core";
import { push } from "connected-react-router";
import { Dictionary, values } from "lodash";
import * as React from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { connect } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router-dom";

import { getUsers, IUsersRequestData, updateUser } from "src/actions";
import {
  kUserFetchSize,
  sortUsersByLastName,
  User,
  userIsContributor
} from "src/api";
import { ConnectedPageTitle } from "src/components";
import { kCommunityRootUrl } from "src/config/routes";
import { IStoreState } from "src/store";
import { UserCard, UserProfile } from "./community";

interface ICommunityPageProps extends RouteComponentProps<{ userId?: string }> {
  allUsers: Dictionary<User>;
  busy: boolean;
  contributors: User[];
  getUsers: typeof getUsers.request;
  loggedInUser?: User;
  moreAvailable: boolean;
  offset: number;
  push: typeof push;
  updateUser: typeof updateUser.request;
}

class CommunityPageComponent extends React.PureComponent<ICommunityPageProps> {
  public render() {
    const {
      allUsers,
      busy,
      contributors,
      match: {
        params: { userId }
      }
    } = this.props;

    const bodySection =
      busy && contributors.length === 0 ? (
        <Spinner />
      ) : userId ? (
        <UserProfile
          user={allUsers[userId]}
          updateUser={this.props.updateUser}
        />
      ) : (
        this.renderCommunity()
      );

    return (
      <section
        id="communityPage"
        className="pa2 w-100 mv2 overflow-y-auto overflow-x-hidden animated fadeIn faster"
      >
        {bodySection}
      </section>
    );
  }

  private renderCommunity = () => {
    const {
      contributors,
      loggedInUser,
      match: {
        params: { userId }
      },
      moreAvailable,
      offset
    } = this.props;

    const getNext = () => {
      this.props.getUsers({ skip: offset, take: kUserFetchSize });
    };

    const showProfile = (user: User) =>
      this.props.push(`${kCommunityRootUrl}/${user.id}`);

    const contributorCards = contributors
      .filter(c => (userId ? c.id === userId : true))
      .map(c => (
        <UserCard
          key={c.id}
          user={c}
          loggedInUser={loggedInUser}
          onClick={showProfile}
        />
      ));

    const moreButton = !userId && moreAvailable && (
      <div>
        <Button className="fr" text="Show more..." onClick={getNext} />
      </div>
    );

    return (
      <>
        <ConnectedPageTitle />
        <section className="pa2 flex flex-column">
          <InfiniteScroll
            hasMore={moreAvailable}
            dataLength={contributors.length}
            next={getNext}
            loader={<Spinner />}
            scrollableTarget="communityPage"
          >
            {contributorCards}
          </InfiniteScroll>
          {moreButton}
        </section>
      </>
    );
  };
}

const mapStateToProps = ({ userStore, usersStore, uiStore }: IStoreState) => {
  const contributors = sortUsersByLastName(
    values(usersStore.allUsers).filter(c => userIsContributor(c))
  );

  return {
    allUsers: usersStore.allUsers,
    busy: uiStore.community.contributorsLoadersCount > 0,
    contributors,
    loggedInUser: userStore.loggedInUser,
    moreAvailable: usersStore.moreAvailable,
    offset: usersStore.fetchOffset
  };
};

export const CommunityPage = withRouter(
  connect(mapStateToProps, {
    getUsers: (params: IUsersRequestData) => getUsers.request(params),
    push,
    updateUser: updateUser.request
  })(CommunityPageComponent)
);
