import { Button, MenuItem, Spinner } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import { ItemRenderer, MultiSelect } from "@blueprintjs/select";
import * as React from "react";
import { WrappedFieldProps } from "redux-form";
import { connect } from "react-redux";

import { searchLabels } from "src/actions";
import {
  arrayFromPayload,
  Label as LabelData,
  makeApiRequest,
  requestConfigForAction,
  ZxLabelType,
  IXmedObjectInfo,
  filterLabels
} from "src/api";
import { IStoreState } from "src/store";
import { highlightText } from "src/utilities";

export interface ILabelMultiSelectProps extends WrappedFieldProps {
  className?: string;
  disabled?: boolean;
  multiSelect?: boolean;
  placeholder?: string;
  type: ZxLabelType;
}

interface ILabelMultiSelectInjectedProps {
  token?: string;
}

interface ILabelMultiSelectState {
  abortController?: AbortController;
  error?: Error;
  items: LabelData[];
  loading: boolean;
  placeholder: string;
  query: string;
  selectedItems: LabelData[];
}

class LabelMultiSelectComponent extends React.PureComponent<
  ILabelMultiSelectProps & ILabelMultiSelectInjectedProps,
  ILabelMultiSelectState
> {
  private debouncer: NodeJS.Timeout | undefined;

  constructor(props: ILabelMultiSelectProps) {
    super(props);

    this.state = {
      items: [],
      loading: false,
      placeholder: props.placeholder || this.defaultPlaceholder(),
      query: "",
      // TODO: we can probably remove state for selected items if always in a redux-form
      selectedItems: props.input.value ? props.input.value : []
    };
  }

  public render() {
    const {
      disabled,
      meta: { error },
      type
    } = this.props;
    const { items, placeholder, query, selectedItems } = this.state;
    const lookupError = this.state.error;

    const tagInputProps = {
      disabled,
      onRemove: this.handleTagRemove,
      rightElement: this.clearButton()
    };
    const errorRenderer = error && !lookupError && (
      <span className="zx-orange pv1">{error}</span>
    );

    const allowCreate =
      items.length === 0 &&
      ([ZxLabelType.KEYWORDS, ZxLabelType.SCHOOLS] as ZxLabelType[]).includes(
        type
      );

    return (
      <div className={this.props.className}>
        <MultiSelect<LabelData>
          createNewItemFromQuery={this.createNewItemFromQuery}
          createNewItemRenderer={
            allowCreate ? this.createNewItemRenderer : undefined
          }
          items={items}
          initialContent={"Start typing..."}
          itemRenderer={this.itemRenderer}
          noResults={this.renderNoResults()}
          onItemSelect={this.handleSelect}
          onQueryChange={this.doSearchDebounced}
          tagRenderer={this.tagRenderer}
          tagInputProps={tagInputProps}
          popoverProps={{ minimal: true, usePortal: true }}
          openOnKeyDown={true}
          query={query}
          selectedItems={selectedItems}
          placeholder={placeholder}
        />
        {errorRenderer}
        {lookupError && <p className="zx-orange pv1">{lookupError.message}</p>}
      </div>
    );
  }

  private createNewItemRenderer = (
    query: string,
    active: boolean,
    handleClick: React.MouseEventHandler<HTMLElement>
  ): JSX.Element | undefined => {
    const { loading } = this.state;
    let addText;
    if (loading) {
      return <Spinner size={Spinner.SIZE_SMALL} />;
    }

    switch (this.props.type) {
      case ZxLabelType.KEYWORDS:
        addText = `Add new keyword '${query}'`;
        break;
      case ZxLabelType.SPECIALTIES:
        addText = `Add new specialty '${query}'`;
        break;
      case ZxLabelType.SCHOOLS:
        addText = `Add new school '${query}'`;
        break;
      default:
        addText = `Add new... '${query}'`;
    }

    return (
      <MenuItem
        active={active}
        icon={IconNames.ADD}
        onClick={handleClick}
        text={addText}
      />
    );
  };

  private createNewItemFromQuery = (query: string) => {
    const newLabel = new LabelData();
    newLabel.category = this.props.type;
    newLabel.title = query.charAt(0).toUpperCase() + query.slice(1);
    return newLabel;
  };

  private renderNoResults = () => {
    const { loading } = this.state;
    return loading ? (
      <Spinner size={Spinner.SIZE_SMALL} />
    ) : (
      <MenuItem disabled={true} text={"No results."} />
    );
  };

  private defaultPlaceholder = () => {
    switch (this.props.type) {
      case ZxLabelType.KEYWORDS:
        return "Add Keyword: Start Typing...";
      case ZxLabelType.SPECIALTIES:
        return "Medical Specialty";
      case ZxLabelType.SCHOOLS:
        return "Medical Schools";
      default:
        return "Start typing...";
    }
  };

  private clearButton = () => {
    const { disabled } = this.props;

    return this.state.selectedItems.length > 0 && !disabled ? (
      <Button icon="cross" minimal={true} onClick={this.handleClear} />
    ) : (
      undefined
    );
  };

  private handleClear = async () => {
    this.setState({ selectedItems: [] });
    this.props.input.onChange(this.state.selectedItems);
  };

  private handleTagRemove = (item: string, index: number) => {
    this.deselectItem(this.state.selectedItems[index]);
  };

  private deselectItem = async (item: LabelData) => {
    const selectedItems = this.state.selectedItems.filter(
      i => i.id !== item.id
    );
    this.setState({ selectedItems: [...selectedItems] });
    this.props.input.onChange(this.state.selectedItems);
  };

  private isLabelSelected = (label: LabelData) => {
    return this.state.selectedItems.filter(l => l.id === label.id).length > 0;
  };

  private handleSelect = (item: LabelData) => {
    const {
      input: { onChange }
    } = this.props;

    let selectedItems;
    if (!this.isLabelSelected(item)) {
      selectedItems = [...this.state.selectedItems, item];

      if (selectedItems.length !== this.state.selectedItems.length) {
        this.setState({ selectedItems, query: "" });
        onChange(selectedItems);
      }
    }
  };

  private tagRenderer = (item: LabelData) =>
    item.topic ? `${item.topic} (${item.title})` : `${item.title}`;

  private itemRenderer: ItemRenderer<LabelData> = (
    item,
    { handleClick, modifiers: { active, disabled }, query }
  ) => {
    return (
      <MenuItem
        active={active}
        disabled={disabled}
        key={item.id}
        onClick={handleClick}
        text={highlightText(this.tagRenderer(item), query)}
      />
    );
  };

  private doSearchDebounced = (query: string) => {
    if (this.debouncer) {
      clearTimeout(this.debouncer);
    }
    const { items } = this.state;
    const newItems = filterLabels(items, query);
    if (query.length > 0) {
      this.debouncer = setTimeout(() => this.doApiTagSearch(query), 600);
      this.setState({ loading: true, items: newItems });
    } else {
      this.setState({ loading: false, items: [] });
    }
  };

  private doApiTagSearch = async (query: string) => {
    const { abortController: prevController } = this.state;

    if (prevController) {
      prevController.abort();
    }

    if (query.length > 0) {
      const newController = new AbortController();
      this.setState({
        abortController: newController,
        loading: true,
        query,
        error: undefined
      });

      try {
        const { type } = this.props;
        const response = await makeApiRequest(
          requestConfigForAction(
            searchLabels.request({ type, searchQuery: query }),
            this.props.token,
            newController.signal
          )
        );
        if (response.apiResponse.data) {
          const labels = filterLabels(
            arrayFromPayload(
              response.apiResponse.data as IXmedObjectInfo[],
              LabelData
            ),
            query
          );
          this.setState({ abortController: undefined, items: labels, loading: false });
        }
      } catch (error) {
        this.setState({abortController: undefined, loading: false, error });
      }
    }
  };
}

const mapStateToProps = ({ userStore }: IStoreState) => {
  return {
    token: userStore.authToken
  };
};

export const LabelMultiSelect = connect(
  mapStateToProps,
  {}
)(LabelMultiSelectComponent);
