import { getType } from "typesafe-actions";

import { ReduxAction, retrieveEvents } from "src/actions";
import { dictionaryFromPayload, Event } from "src/api";
import { IEventsStore } from "src/store";

const INITIAL_STATE = {
  events: {}
};

export const eventsReducer = (
  eventsStore: IEventsStore = INITIAL_STATE,
  action: ReduxAction
): IEventsStore => {
  switch (action.type) {
    case getType(retrieveEvents.success):
      const events = dictionaryFromPayload<Event>(
        action.payload.apiResponse.data,
        Event
      );
      return {
        ...eventsStore,
        events: { ...eventsStore.events, ...events }
      };

    case getType(retrieveEvents.failure):
      return {
        ...eventsStore,
        error: action.payload
      };

    default:
      return eventsStore;
  }
};
