import { omit } from "lodash";
import { getType } from "typesafe-actions";

import {
  clearUiError,
  createAlgorithm,
  createStudy,
  deleteAlgorithm,
  deleteStudy,
  getAlgorithm,
  getAlgorithmSearchResults,
  getAlgosByTopic,
  getTopics,
  getUsers,
  ReduxAction,
  retrieveAlgoList,
  retrieveEvents,
  retrieveStudies,
  retrieveVisits,
  saveAlgoState,
  setBreadcrumbs,
  setCssSize,
  setLeftMenuState,
  setNewAlgoDialogState,
  setUiError,
  setUserRole,
  updateAlgorithmStatus,
  updateStudy,
  updateUser,
  setNodeImage,
  clearLightboxSource,
  setLightboxSource,
  getUserFavouriteAlgorithms,
  setUserFavouriteAlgorithms
} from "src/actions";
import { IInterfaceStore } from "src/store";

const INITIAL_STATE: IInterfaceStore = {
  breadCrumbs: [],
  community: {
    contributorsLoadersCount: 0,
    userLoadersCount: 0
  },
  cssSize: 0,
  events: {
    eventsLoadersCount: 0
  },
  leftMenuOpen: false,
  library: {
    libraryLoadersCount: 0
  },
  mediaUploads: {},
  newAlgoDialogOpen: false,
  search: {
    lastSearchTerms: [],
    searchLoadersCount: 0
  },
  studies: {
    studiesLoadersCount: 0
  }
};

const incrementLibraryLoaders = (uiStore: IInterfaceStore) => {
  return {
    ...uiStore,
    library: {
      ...uiStore.library,
      libraryLoadersCount: uiStore.library.libraryLoadersCount + 1
    }
  };
};

const decrementLibraryLoaders = (uiStore: IInterfaceStore) => {
  return {
    ...uiStore,
    library: {
      ...uiStore.library,
      libraryLoadersCount: uiStore.library.libraryLoadersCount - 1
    }
  };
};

const incrementCommunityLoaders = (uiStore: IInterfaceStore) => {
  return {
    ...uiStore,
    community: {
      ...uiStore.community,
      contributorsLoadersCount: uiStore.community.contributorsLoadersCount + 1
    }
  };
};

const decrementCommunityLoaders = (uiStore: IInterfaceStore) => {
  return {
    ...uiStore,
    community: {
      ...uiStore.community,
      contributorsLoadersCount: uiStore.community.contributorsLoadersCount - 1
    }
  };
};

const incrementUserLoaders = (uiStore: IInterfaceStore) => {
  return {
    ...uiStore,
    community: {
      ...uiStore.community,
      userLoadersCount: uiStore.community.userLoadersCount + 1
    }
  };
};

const decrementUserLoaders = (uiStore: IInterfaceStore) => {
  return {
    ...uiStore,
    community: {
      ...uiStore.community,
      userLoadersCount: uiStore.community.userLoadersCount - 1
    }
  };
};
const updateSearchStore = (uiStore: IInterfaceStore, term: string) => {
  const newTerms = uiStore.search.lastSearchTerms.includes(term)
    ? uiStore.search.lastSearchTerms
    : [term, ...uiStore.search.lastSearchTerms];
  return {
    ...uiStore,
    search: {
      ...uiStore.search,
      lastSearchTerms: newTerms.slice(0, 5),
      searchLoadersCount: uiStore.search.searchLoadersCount + 1
    }
  };
};

const decrementSearchLoaders = (uiStore: IInterfaceStore) => {
  return {
    ...uiStore,
    search: {
      ...uiStore.search,
      searchLoadersCount: uiStore.search.searchLoadersCount - 1
    }
  };
};

const incrementEventsLoaders = (uiStore: IInterfaceStore) => {
  return {
    ...uiStore,
    events: {
      ...uiStore.events,
      eventsLoadersCount: uiStore.events.eventsLoadersCount + 1
    }
  };
};

const decrementEventsLoaders = (uiStore: IInterfaceStore) => {
  return {
    ...uiStore,
    events: {
      ...uiStore.events,
      eventsLoadersCount: uiStore.events.eventsLoadersCount - 1
    }
  };
};

const incrementStudyLoaders = (uiStore: IInterfaceStore) => {
  return {
    ...uiStore,
    studies: {
      ...uiStore.studies,
      studiesLoadersCount: uiStore.studies.studiesLoadersCount + 1
    }
  };
};

const decrementStudyLoaders = (uiStore: IInterfaceStore) => {
  return {
    ...uiStore,
    studies: {
      ...uiStore.studies,
      studiesLoadersCount: uiStore.studies.studiesLoadersCount - 1
    }
  };
};

export const interfaceReducer = (
  uiStore: IInterfaceStore = INITIAL_STATE,
  action: ReduxAction
): IInterfaceStore => {
  switch (action.type) {
    case getType(setBreadcrumbs):
      return {
        ...uiStore,
        breadCrumbs: action.payload
      };

    case getType(setNodeImage.request): {
      return {
        ...uiStore,
        error: undefined,
        mediaUploads: {
          ...uiStore.mediaUploads,
          [action.payload.nodeId]: action.payload.imageData
        }
      };
    }

    case getType(setNodeImage.success):
      return {
        ...uiStore,
        mediaUploads: {
          ...omit(uiStore.mediaUploads, action.payload.apiResponse.data.id)
        }
      };

    case getType(createStudy.request):
    case getType(retrieveStudies.request):
    case getType(retrieveVisits.request):
    case getType(updateStudy.request):
    case getType(deleteStudy.request):
      return incrementStudyLoaders(uiStore);

    case getType(retrieveStudies.success):
    case getType(retrieveStudies.failure):
    case getType(createStudy.success):
    case getType(createStudy.failure):
    case getType(updateStudy.success):
    case getType(updateStudy.failure):
    case getType(retrieveVisits.success):
    case getType(retrieveVisits.failure):
    case getType(deleteStudy.success):
    case getType(deleteStudy.failure):
      return decrementStudyLoaders(uiStore);

    case getType(getUsers.request):
      return incrementCommunityLoaders(uiStore);

    case getType(getUsers.success):
    case getType(getUsers.failure):
      return decrementCommunityLoaders(uiStore);

    case getType(retrieveAlgoList.request):
    case getType(getTopics.request):
    case getType(getAlgosByTopic.request):
    case getType(getAlgorithm.request):
    case getType(saveAlgoState.request):
    case getType(deleteAlgorithm.request):
    case getType(updateAlgorithmStatus.request):
    case getType(createAlgorithm.request):
      return incrementLibraryLoaders(uiStore);

    case getType(retrieveAlgoList.success):
    case getType(retrieveAlgoList.failure):
    case getType(getTopics.success):
    case getType(getTopics.failure):
    case getType(getAlgosByTopic.success):
    case getType(getAlgosByTopic.failure):
    case getType(getAlgorithm.success):
    case getType(getAlgorithm.failure):
    case getType(saveAlgoState.success):
    case getType(saveAlgoState.failure):
    case getType(deleteAlgorithm.success):
    case getType(deleteAlgorithm.failure):
    case getType(updateAlgorithmStatus.success):
    case getType(updateAlgorithmStatus.failure):
    case getType(createAlgorithm.success):
    case getType(createAlgorithm.failure):
      return decrementLibraryLoaders(uiStore);

    case getType(getAlgorithmSearchResults.request):
      return updateSearchStore(uiStore, action.payload.query);

    case getType(getAlgorithmSearchResults.success):
    case getType(getAlgorithmSearchResults.failure):
      return decrementSearchLoaders(uiStore);

    case getType(retrieveEvents.request):
      return incrementEventsLoaders(uiStore);

    case getType(retrieveEvents.success):
    case getType(retrieveEvents.failure):
      return decrementEventsLoaders(uiStore);

    case getType(getUserFavouriteAlgorithms.request):
    case getType(setUserFavouriteAlgorithms.request):
    case getType(updateUser.request):
    case getType(setUserRole.request):
      return incrementUserLoaders(uiStore);

    case getType(getUserFavouriteAlgorithms.success):
    case getType(getUserFavouriteAlgorithms.failure):
    case getType(setUserFavouriteAlgorithms.success):
    case getType(setUserFavouriteAlgorithms.failure):
    case getType(updateUser.success):
    case getType(updateUser.failure):
    case getType(setUserRole.success):
    case getType(setUserRole.failure):
      return decrementUserLoaders(uiStore);

    case getType(setUiError):
      return {
        ...uiStore,
        error: action.payload
      };

    case getType(clearUiError):
      return {
        ...uiStore,
        error: undefined
      };

    case getType(setCssSize):
      return {
        ...uiStore,
        cssSize: action.payload
      };

    case getType(setLeftMenuState):
      return {
        ...uiStore,
        leftMenuOpen: action.payload
      };

    case getType(setNewAlgoDialogState):
      return {
        ...uiStore,
        newAlgoBaseId: action.payload.baseAlgoId,
        newAlgoDialogOpen: action.payload.open,
        newAlgoVersion: action.payload.version
      };

    case getType(setLightboxSource):
      return {
        ...uiStore,
        mediaLightboxSource: action.payload
      };

    case getType(clearLightboxSource):
      return {
        ...uiStore,
        mediaLightboxSource: undefined
      };

    default:
      return uiStore;
  }
};
