import { Button, MenuItem } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import { ItemRenderer, Select } from "@blueprintjs/select";
import * as React from "react";

import {
  AlgoVariableType,
  detailsForVariableOperator,
  operatorsForVariableType,
  VariableOperator
} from "src/api";

export interface IVariableOperatorSelectProps {
  className?: string;
  decisionEnabled: boolean;
  disabled?: boolean;
  onChange?: (value: VariableOperator) => void;
  value?: VariableOperator;
  variableType: AlgoVariableType;
}

const VarOpSelect = Select.ofType<VariableOperator>();

export class VariableOperatorSelect extends React.PureComponent<
  IVariableOperatorSelectProps
> {
  public render() {
    const { decisionEnabled, disabled, value, variableType } = this.props;
    const items = operatorsForVariableType(variableType)[
      decisionEnabled ? 1 : 0
    ];
    const details = detailsForVariableOperator(value || items[0]);

    return (
      <div className={`${this.props.className || ""}`}>
        <VarOpSelect
          className="flex flex-row flex-auto"
          disabled={disabled}
          filterable={false}
          popoverProps={{ minimal: true }}
          items={items}
          itemRenderer={this.itemRenderer}
          onItemSelect={this.selectionHandler}
          inputProps={{ disabled }}
        >
          <Button
            fill={true}
            alignText={"left"}
            icon={details.icon}
            text={details.icon ? undefined : details.text[0]}
            rightIcon={IconNames.CARET_DOWN}
          />
        </VarOpSelect>
      </div>
    );
  }

  private itemRenderer: ItemRenderer<VariableOperator> = (
    value,
    { handleClick, modifiers: { disabled } }
  ) => {
    const details = detailsForVariableOperator(value);

    return (
      <MenuItem
        disabled={disabled}
        icon={details.icon}
        key={value}
        onClick={handleClick}
        text={details.text[0]}
      />
    );
  };

  private selectionHandler = (value: VariableOperator) => {
    const { onChange } = this.props;
    if (onChange) {
      onChange(value);
    }
  };
}
