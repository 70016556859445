import * as React from "react";
import { connect } from "react-redux";

import { Button, Classes, Dialog, Intent, Label } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import { clearUiError, updateAlgorithmStatus } from "src/actions";
import {
  AlgorithmStatus,
  algoStatusString,
  fullName,
  User,
  userIsAdmin
} from "src/api";
import { algoIconForStatus } from "src/components";
import { IOpenAlgorithm, IStoreState } from "src/store";

interface IAlgorithmApprovalControlProps {
  openAlgorithm: IOpenAlgorithm;
}

interface IAlgorithmApprovalControlInjectedProps {
  busy: boolean;
  error?: Error;
  loggedInUser?: User;

  clearUiError: typeof clearUiError;
  updateAlgoStatus: typeof updateAlgorithmStatus.request;
}

interface IAlgorithmApprovalControlState {
  confirmSubmitOpen: boolean;
  message?: string;
  reviewDialogOpen: boolean;
}

type AlgorithmApprovalControlProps = IAlgorithmApprovalControlProps &
  IAlgorithmApprovalControlInjectedProps;

class AlgorithmApprovalControlComponent extends React.PureComponent<
  AlgorithmApprovalControlProps,
  IAlgorithmApprovalControlState
> {
  public constructor(props: AlgorithmApprovalControlProps) {
    super(props);
    this.state = {
      confirmSubmitOpen: false,
      reviewDialogOpen: false
    };
  }

  public componentDidUpdate(prevProps: AlgorithmApprovalControlProps) {
    const {
      openAlgorithm: {
        algorithm: { status }
      }
    } = this.props;
    const {
      openAlgorithm: {
        algorithm: { status: oldStatus }
      }
    } = prevProps;
    if (status !== oldStatus) {
      this.setState({ reviewDialogOpen: false, confirmSubmitOpen: false });
    }
  }

  public render() {
    const { busy, loggedInUser, openAlgorithm } = this.props;
    const { algorithm } = openAlgorithm;
    const { status } = algorithm;

    if (!loggedInUser) {
      return null;
    }

    const isAuthor =
      algorithm.authors.find(a => a.id === loggedInUser.id) !== undefined;
    const isEditor =
      algorithm.editors.find(a => a.id === loggedInUser.id) !== undefined ||
      (algorithm.editors.length === 0 && userIsAdmin(loggedInUser)); // Admins get editor rights if no editor set

    if (isAuthor) {
      if (status === AlgorithmStatus.draft) {
        return this.renderSendForReview(openAlgorithm, busy, loggedInUser);
      }
    } else if (isEditor) {
      if (
        status === AlgorithmStatus.awaitingReview ||
        status === AlgorithmStatus.inReview
      ) {
        return this.renderReviewControl(openAlgorithm, loggedInUser);
      }
    }

    return (
      <Button
        disabled={true}
        icon={algoIconForStatus(algorithm.status)}
        text={algoStatusString(algorithm.status)}
      />
    );
  }

  private renderSendForReview = (
    openAlgorithm: IOpenAlgorithm,
    busy: boolean,
    user: User
  ) => {
    const {
      algorithm,
      editingState: { validationErrors }
    } = openAlgorithm;

    const { confirmSubmitOpen } = this.state;
    const hasErrors = validationErrors
      ? validationErrors.errors.length > 0
      : false;

    const handleClose = () => this.setState({ confirmSubmitOpen: false });
    const dialogProps = {
      cancelAction: handleClose,
      cancelText: "Cancel",
      confirmAction: () =>
        this.props.updateAlgoStatus({
          algorithm: {
            ...algorithm,
            status: AlgorithmStatus.inReview
          },
          reason: `Submitted for review by ${fullName(user)}`
        }),
      confirmDisabled: busy,
      confirmIntent: Intent.SUCCESS,
      confirmText: "Send for Review"
    };

    const openDialog = () => {
      this.props.clearUiError();
      this.setState({ confirmSubmitOpen: true });
    };

    return (
      <section>
        <Button
          disabled={hasErrors || !validationErrors}
          icon={IconNames.SEND_TO}
          onClick={openDialog}
          text="Send for Review"
        />
        <Dialog
          className={`${Classes.DARK}`}
          isOpen={confirmSubmitOpen}
          onClose={handleClose}
          title="Send for Review"
        >
          <div className={`${Classes.DIALOG_BODY}`}>
            <p>Sumitting for review will alert the reviewer(s).</p>
            <p>Are you sure?</p>
          </div>
          {this.renderDialogFooter(dialogProps)}
        </Dialog>
      </section>
    );
  };

  private renderReviewControl = (openAlgorithm: IOpenAlgorithm, user: User) => {
    const {
      algorithm,
      editingState: { validationErrors }
    } = openAlgorithm;

    const { message, reviewDialogOpen } = this.state;
    const hasErrors = validationErrors
      ? validationErrors.errors.length > 0
      : false;

    const handleClose = () => this.setState({ reviewDialogOpen: false });
    const handleMessageChange = (
      event: React.ChangeEvent<HTMLTextAreaElement>
    ) => this.setState({ message: event.currentTarget.value });
    const dialogProps = {
      cancelAction: () =>
        this.props.updateAlgoStatus({
          algorithm: {
            ...algorithm,
            status: AlgorithmStatus.draft
          },
          reason: `Rejected by ${fullName(user)} -  ${message ||
            "No reason given"}`
        }),
      cancelText: "Reject",
      confirmAction: () =>
        this.props.updateAlgoStatus({
          algorithm: {
            ...algorithm,
            status: AlgorithmStatus.published
          },
          reason: message || "No reason given"
        }),
      confirmDisabled:
        hasErrors || !validationErrors || (message ? message.length < 5 : true),
      confirmIntent: Intent.SUCCESS,
      confirmText: "Publish"
    };

    const openDialog = () => {
      this.props.clearUiError();
      this.setState({ reviewDialogOpen: true });
    };

    return (
      <section>
        <Button
          icon={algoIconForStatus(algorithm.status)}
          onClick={openDialog}
          text="Review..."
        />
        <Dialog
          className={`${Classes.DARK}`}
          isOpen={reviewDialogOpen}
          onClose={handleClose}
          title="Review Algorithm"
        >
          <div className={`${Classes.DIALOG_BODY}`}>
            <Label>
              Message
              <textarea
                className="mv2 w-100 br2"
                style={{ height: 100, maxHeight: 150, resize: "none" }}
                value={message}
                onChange={handleMessageChange}
              />
            </Label>
            <p>
              Publishing or rejecting the algorithm will notify the author and
              update the algorithm.
            </p>
          </div>
          {this.renderDialogFooter(dialogProps)}
        </Dialog>
      </section>
    );
  };

  private renderDialogFooter = ({
    confirmText,
    cancelText,
    cancelAction,
    confirmAction,
    confirmIntent,
    confirmDisabled
  }: {
    confirmAction: () => void;
    confirmDisabled: boolean;
    confirmIntent: Intent;
    confirmText: string;
    cancelAction: () => void;
    cancelText: string;
  }) => {
    const { busy, error } = this.props;

    return (
      <div className={`${Classes.DIALOG_FOOTER} flex flex-row justify-between`}>
        <Button
          disabled={busy}
          intent={Intent.NONE}
          onClick={cancelAction}
          text={cancelText}
        />
        {error && error.message.length > 0 && (
          <p className="mt2 orange">{error.message}</p>
        )}
        <Button
          className="ml2"
          disabled={confirmDisabled}
          intent={confirmIntent}
          loading={busy}
          onClick={confirmAction}
          text={confirmText}
        />
      </div>
    );
  };
}

const mapStateToProps = ({ uiStore, userStore }: IStoreState) => {
  return {
    busy: uiStore.library.libraryLoadersCount > 0,
    error: uiStore.error,
    loggedInUser: userStore.loggedInUser
  };
};

export const AlgorithmApprovalControl = connect(mapStateToProps, {
  clearUiError,
  updateAlgoStatus: updateAlgorithmStatus.request
})(AlgorithmApprovalControlComponent);
