import { Label } from "@blueprintjs/core";
import * as React from "react";
import { change } from "redux-form";

import { ZxLabelType, ZxUserType } from "src/api";
import { IRenderFieldProps, LabelMultiSelect } from ".";
import { UserMultiselect } from "./user-multiselect";

const renderLookup = (props: IRenderFieldProps, type: ZxLabelType) => {
  const {
    label,
    input,
    meta: { form }
  } = props;

  if (type === ZxLabelType.REGIONS) {
    const origOnChange = input.onChange;
    // Typing not working here...

    input.onChange = (values: any) => {
      if (values.length === 0) {
        props.meta.dispatch(change(form, "regionsRestricted", false));
      }
      origOnChange(values);
    };
  }

  return (
    <div className="flex flex-column mb2">
      <Label style={{ marginBottom: 0 }}>
        <span className="ttc">{label}</span>
      </Label>
      <LabelMultiSelect className="mt1 mb3 w-100" {...props} type={type} />
    </div>
  );
};

const renderUserLookup = (props: IRenderFieldProps, type: ZxUserType) => {
  const { label } = props;

  return (
    <div className="flex flex-column mb2">
      <Label style={{ marginBottom: 0 }}>
        <span className="ttc">{label}</span>
      </Label>
      <UserMultiselect
        className="mt1 mb3 w-100 b--blue"
        {...props}
        preventEmpty={type === "authors"}
        type={type}
      />
    </div>
  );
};

export const renderMedSchools = (props: IRenderFieldProps) =>
  renderLookup(props, ZxLabelType.SCHOOLS);

export const renderMedSpecialties = (props: IRenderFieldProps) =>
  renderLookup(props, ZxLabelType.SPECIALTIES);

export const renderRegions = (props: IRenderFieldProps) =>
  renderLookup(props, ZxLabelType.REGIONS);

export const renderKeywords = (props: IRenderFieldProps) =>
  renderLookup(props, ZxLabelType.KEYWORDS);

export const renderAuthors = (props: IRenderFieldProps) =>
  renderUserLookup(props, "authors");
export const renderEditors = (props: IRenderFieldProps) =>
  renderUserLookup(props, "editors");
