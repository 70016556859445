import { Spinner } from "@blueprintjs/core";
import * as Sentry from "@sentry/browser";
import { ConnectedRouter } from "connected-react-router";
import firebase from "firebase/app";
import "firebase/auth";
import * as React from "react";
import * as ReactDOM from "react-dom";
import WebFont from "webfontloader";

import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import "reflect-metadata"; // Dependency for class-transformer

import "src/config/firebase";

import { configureStore } from "../src/store";
import { setFirebaseUser } from "./actions";

import App from "./App";
import { register as registerServiceWorker } from "./serviceWorker";

export const { store, history, persistor } = configureStore();

// Catch errors in production
if (process.env.NODE_ENV !== "development") {
  Sentry.init({
    dsn: "https://cd7c51e4151e4eb796f30123a835c65b@sentry.io/1371384"
  });
}

// Load up our fonts
WebFont.load({
  typekit: {
    id: "dgd4gis"
  }
});

// Handle firebase updates in redux
firebase.auth().onIdTokenChanged(user => {
  store.dispatch(setFirebaseUser(user));
});

const renderLoading = () => {
  return (
    <>
      <div style={{ height: "100vh", width: "100vw" }}>
        <Spinner />
      </div>
    </>
  );
};

const Root = () => (
  <div
    className="animated fadeIn faster"
    style={{ height: "100vh", background: "var(--charcoal-grey)" }}
  >
    <Provider store={store}>
      <PersistGate loading={renderLoading()} persistor={persistor}>
        <ConnectedRouter history={history}>
          <App />
        </ConnectedRouter>
      </PersistGate>
    </Provider>
  </div>
);

ReactDOM.render(<Root />, document.getElementById("root") as HTMLElement);
registerServiceWorker();
