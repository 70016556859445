import { getType } from "typesafe-actions";

import {
  clearUserError,
  getSelf,
  getUser,
  login,
  logout,
  postUserAvatar,
  ReduxAction,
  registerPushToken,
  registerUser,
  resetPassword,
  resetStore,
  setAuthToken,
  setFirebaseUser,
  updateUser,
  updateStudy,
  createStudy,
  deleteStudy,
  setUserActiveStudy,
  getUserFavouriteAlgorithms,
  setUserFavouriteAlgorithms
} from "src/actions";

import {
  objectFromPayload,
  User,
  Study,
  updateArray,
  removeFromArray,
  StudyStatus,
  arrayFromPayload,
  Algorithm
} from "src/api";
import { Device } from "src/api/model/device";
import { IUserStore } from "src/store";

const INITIAL_STATE: IUserStore = {
  faveAlgos: [],
  firebase: {},
  resetSucceeded: false
};

let loggedInUserCache: User | undefined;

export const accessLoggedInUser = () => loggedInUserCache;

export const userReducer = (
  userStore: IUserStore = INITIAL_STATE,
  action: ReduxAction
): IUserStore => {
  switch (action.type) {
    case getType(resetStore):
      return {
        ...userStore,
        authToken: undefined,
        error: undefined,
        resetSucceeded: false
      };

    case getType(clearUserError):
      return {
        ...userStore,
        error: undefined
      };

    case getType(registerPushToken.success):
      const device = objectFromPayload(action.payload.apiResponse.data, Device);
      if (device) {
        return {
          ...userStore,
          deviceId: device.deviceId,
          pushToken: device.pushId
        };
      }
      return userStore;

    case getType(setFirebaseUser):
      return {
        ...userStore,
        firebase: {
          ...userStore.firebase,
          user: action.payload ? action.payload : undefined
        }
      };

    case getType(setAuthToken):
      const authToken = action.payload;

      return {
        ...userStore,
        authToken
      };

    case getType(resetPassword.request):
      return {
        ...userStore,
        error: undefined,
        resetSucceeded: false
      };

    case getType(login.success):
      return {
        ...userStore,
        error: undefined,
        firebase: {
          ...userStore.firebase,
          userCreds: action.payload
        }
      };

    case getType(resetPassword.success):
      return {
        ...userStore,
        resetSucceeded: true
      };

    case getType(getSelf.success):
    case getType(registerUser.success):
      const loggedInUser = objectFromPayload(
        action.payload.apiResponse.data,
        User
      );

      loggedInUserCache = loggedInUser;

      if (userStore.openStudyId) {
        if (
          loggedInUser &&
          (!loggedInUser.activeStudyInfo ||
            !loggedInUser.activeStudyInfo.find(
              si => si.id === userStore.openStudyId
            ))
        ) {
          return {
            ...userStore,
            loggedInUser,
            openStudyId: undefined
          };
        }
      }

      return {
        ...userStore,
        loggedInUser
      };

    case getType(updateUser.success):
    case getType(getUser.success):
    case getType(postUserAvatar.success):
      const user = objectFromPayload(action.payload.apiResponse.data, User);
      if (
        userStore.loggedInUser &&
        user &&
        user.id === userStore.loggedInUser.id
      ) {
        return { ...userStore, loggedInUser: user };
      }
      return userStore;

    case getType(createStudy.success):
    case getType(updateStudy.success):
      const study = objectFromPayload(action.payload.apiResponse.data, Study);
      if (
        userStore.loggedInUser &&
        study &&
        study.status === StudyStatus.approved
      ) {
        let updatedStudyInfo = userStore.loggedInUser.activeStudyInfo;
        if (
          study.users &&
          study.users.find(u => u.id === userStore.loggedInUser!.id)
        ) {
          updatedStudyInfo = updateArray(updatedStudyInfo || [], study);
        } else {
          updatedStudyInfo = removeFromArray(updatedStudyInfo || [], study.id);
        }
        return {
          ...userStore,
          loggedInUser: {
            ...userStore.loggedInUser,
            activeStudyInfo: updatedStudyInfo
          }
        };
      }
      return userStore;

    case getType(deleteStudy.success):
      const deletedStudy = objectFromPayload(
        action.payload.apiResponse.data,
        Study
      );
      if (deletedStudy && userStore.loggedInUser) {
        return {
          ...userStore,
          loggedInUser: {
            ...userStore.loggedInUser,
            activeStudyInfo: removeFromArray(
              userStore.loggedInUser.activeStudyInfo || [],
              deletedStudy.id
            )
          }
        };
      }
      return userStore;

    case getType(logout):
      loggedInUserCache = undefined;

      return {
        ...userStore,
        authToken: undefined,
        firebase: {
          user: undefined,
          userCreds: undefined
        },
        loggedInUser: undefined,
        pushToken: undefined
      };

    case getType(setUserActiveStudy):
      return {
        ...userStore,
        openStudyId: action.payload
      };

    case getType(getUserFavouriteAlgorithms.success):
    case getType(setUserFavouriteAlgorithms.success): {
      const newFaves = arrayFromPayload(
        action.payload.apiResponse.data,
        Algorithm
      );
      return { ...userStore, faveAlgos: newFaves.map(a => a.id) };
    }

    case getType(resetPassword.failure):
    case getType(login.failure):
    case getType(getSelf.failure):
    case getType(registerUser.failure):
    case getType(postUserAvatar.failure):
    case getType(getUserFavouriteAlgorithms.failure):
    case getType(setUserFavouriteAlgorithms.failure):
      return {
        ...userStore,
        error: action.payload
      };

    default:
      return userStore;
  }
};
