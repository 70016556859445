import { Checkbox, Radio, RadioGroup } from "@blueprintjs/core";
import * as React from "react";

import { updateUser } from "src/actions";
import { User, NotificationSettings } from "src/api";
import { cloneDeep } from "lodash";

interface UserProfileSettingsProps {
  user: User;
  updateUser?: typeof updateUser.request;
}

type NotificationFrequency = "d" | "w" | "i";

interface UserProfileSettingsState {
  algoEdits: boolean;
  algoComments: boolean;
  editorialEvents: boolean;
  frequency: NotificationFrequency;
}

export class UserProfileSettings extends React.PureComponent<
  UserProfileSettingsProps,
  UserProfileSettingsState
> {
  constructor(props: UserProfileSettingsProps) {
    super(props);
    const notificationSettings = props.user.notificationSettings;

    const state: UserProfileSettingsState = {
      algoEdits: false,
      algoComments: false,
      editorialEvents: false,
      frequency: "i"
    };

    if (!notificationSettings) {
      state.algoEdits = true;
      state.algoComments = true;
      state.editorialEvents = true;
    } else {
      notificationSettings.forEach(s => {
        switch (s) {
          case NotificationSettings.AlgoEditDaily:
            state.algoEdits = true;
            state.frequency = "d";
            break;
          case NotificationSettings.AlgoEditWeekly:
            state.algoEdits = true;
            state.frequency = "w";
            break;
          case NotificationSettings.CommentsImmediate:
            state.algoComments = true;
            state.frequency = "i";
            break;
          case NotificationSettings.CommentsDaily:
            state.algoComments = true;
            state.frequency = "d";
            break;
          case NotificationSettings.CommentsWeekly:
            state.algoComments = true;
            state.frequency = "w";
            break;
          case NotificationSettings.AlgoEditorialImmediate:
            state.editorialEvents = true;
            state.frequency = "i";
            break;
          case NotificationSettings.AlgoEditorialDaily:
            state.editorialEvents = true;
            state.frequency = "d";
            break;
          case NotificationSettings.AlgoEditorialWeekly:
            state.editorialEvents = true;
            state.frequency = "w";
            break;
        }
      });
    }
    this.state = state;
  }

  public componentDidUpdate(
    oldProps: UserProfileSettingsProps,
    prevState: UserProfileSettingsState
  ) {
    const { algoComments, algoEdits, editorialEvents, frequency } = this.state;
    const {
      algoComments: prevAlgoComments,
      algoEdits: prevAlgoEdits,
      editorialEvents: prevEditorialEvents,
      frequency: prevFrequency
    } = prevState;

    if (
      algoComments !== prevAlgoComments ||
      algoEdits !== prevAlgoEdits ||
      editorialEvents !== prevEditorialEvents ||
      frequency !== prevFrequency
    ) {
      this.updateUser();
    }
  }

  public render() {
    const { algoComments, algoEdits, editorialEvents, frequency } = this.state;

    const editLabel = (
      <p>
        <small>
          Algorithms you follow, collaborate on, or review. Maximum frequency of
          notifications is once a day.
        </small>
      </p>
    );
    return (
      <section>
        <p className="ttu mb1 zx-blue-extra-light">Email Notifications</p>
        <p>Events</p>
        <Checkbox
          checked={algoEdits}
          label="Algorithm Edits"
          labelElement={editLabel}
          onChange={this.handleAlgoEditChange}
        />
        <Checkbox
          checked={algoComments}
          label="Algorithm Comments"
          onChange={this.handleAlgoCommentChange}
        />
        <Checkbox
          checked={editorialEvents}
          label="Editorial Events"
          onChange={this.handleAlgoEditorialChange}
        />
        <RadioGroup
          label="Frequency"
          onChange={this.handleFrequencyChange}
          selectedValue={frequency}
        >
          <Radio label="Immediate (algorithm edits are daily only)" value="i" />
          <Radio label="Daily Digest" value="d" />
          <Radio label="Weekly Digest" value="w" />
        </RadioGroup>
      </section>
    );
  }

  private handleFrequencyChange = (
    event: React.FormEvent<HTMLInputElement>
  ) => {
    this.setState({
      frequency: event.currentTarget.value as NotificationFrequency
    });
  };

  private handleAlgoEditChange = (event: React.FormEvent<HTMLInputElement>) => {
    this.setState({ algoEdits: event.currentTarget.checked });
  };

  private handleAlgoCommentChange = (
    event: React.FormEvent<HTMLInputElement>
  ) => {
    this.setState({ algoComments: event.currentTarget.checked });
  };

  private handleAlgoEditorialChange = (
    event: React.FormEvent<HTMLInputElement>
  ) => {
    this.setState({ editorialEvents: event.currentTarget.checked });
  };

  private updateUser = () => {
    if (this.props.updateUser) {
      const {
        algoComments,
        algoEdits,
        editorialEvents,
        frequency
      } = this.state;
      const newNotificationOptions: NotificationSettings[] = [];

      if (algoComments) {
        if (frequency === "i") {
          newNotificationOptions.push(NotificationSettings.CommentsImmediate);
        } else if (frequency === "d") {
          newNotificationOptions.push(NotificationSettings.CommentsDaily);
        } else if (frequency === "w") {
          newNotificationOptions.push(NotificationSettings.CommentsWeekly);
        }
      }
      if (algoEdits) {
        if (frequency === "i") {
          newNotificationOptions.push(NotificationSettings.AlgoEditDaily);
        } else if (frequency === "d") {
          newNotificationOptions.push(NotificationSettings.AlgoEditDaily);
        } else if (frequency === "w") {
          newNotificationOptions.push(NotificationSettings.AlgoEditWeekly);
        }
      }
      if (editorialEvents) {
        if (frequency === "i") {
          newNotificationOptions.push(
            NotificationSettings.AlgoEditorialImmediate
          );
        } else if (frequency === "d") {
          newNotificationOptions.push(NotificationSettings.AlgoEditorialDaily);
        } else if (frequency === "w") {
          newNotificationOptions.push(NotificationSettings.AlgoEditorialWeekly);
        }
      }

      const updated = cloneDeep(this.props.user);
      updated.notificationSettings = newNotificationOptions;
      this.props.updateUser(updated);
    }
  };
}
