import { Menu, MenuItem, MenuDivider, H6 } from "@blueprintjs/core";
import { ItemRenderer, Suggest, ItemListRenderer } from "@blueprintjs/select";
import * as React from "react";
import { connect } from "react-redux";

import { searchPatients } from "src/actions";
import {
  arrayFromPayload,
  makeApiRequest,
  requestConfigForAction,
  Patient,
  fullName,
  IXmedObjectInfo
} from "src/api";
import { IStoreState } from "src/store";

export interface IPatientSelectProps {
  className?: string;
  disabled?: boolean;
  idFormat?: RegExp;
  value: Patient | undefined | null;
  onChange: (value: Patient, created: boolean) => void;
}

interface IPatientSelectInjectedProps {
  token?: string;
}

interface IPatientSelectState {
  searchResults: Patient[];
  error?: Error;
  loading: boolean;
  query: string;
}

type PatientSelectComponentProps = IPatientSelectProps &
  IPatientSelectInjectedProps;

const PatientSuggest = Suggest.ofType<Patient>();

class PatientSelectComponent extends React.PureComponent<
  PatientSelectComponentProps,
  IPatientSelectState
> {
  constructor(props: PatientSelectComponentProps) {
    super(props);
    this.state = {
      loading: false,
      query: "",
      searchResults: []
    };
  }

  public render() {
    const { className, disabled, value } = this.props;
    const { searchResults } = this.state;
    const popoverProps = {
      minimal: true,
      popoverClassName: "zx-scrolling-popover"
    };
    const items =
      searchResults.length > 0
        ? searchResults
        : value
        ? [value]
        : searchResults;

    return (
      <div className={`${className}`}>
        <PatientSuggest
          disabled={disabled}
          popoverProps={popoverProps}
          items={items}
          itemRenderer={this.itemRenderer}
          itemListRenderer={this.itemListRenderer}
          inputValueRenderer={this.inputValueRenderer}
          onItemSelect={this.selectionHandler}
          onQueryChange={this.doApiPatientSearch}
          inputProps={{ disabled }}
          resetOnClose={true}
          selectedItem={value}
        />
      </div>
    );
  }

  private itemListRenderer: ItemListRenderer<Patient> = itemListProps => {
    const { items, query, renderItem } = itemListProps;
    const { onChange, value } = this.props;

    const idRegEx = this.props.idFormat || RegExp("^[0-9]{5}-[0-9]{4}$");
    const valid = query.match(idRegEx);

    const matchesSection =
      items.length > 0 ? (
        <div className="pa1 ph2">
          <H6 style={{ color: "grey" }}>Patient Matches</H6>
          {items.map(renderItem)}
        </div>
      ) : query.length > 6 ? (
        <div className="pa1 ph2">
          <H6 style={{ color: "grey" }}>No patient matches</H6>
        </div>
      ) : (
        undefined
      );

    const handleNew = () => {
      const newPatient = new Patient();
      newPatient.regionalPatientId = query;
      onChange(newPatient, true);
    };

    const addNewPatientSection = [
      <MenuDivider key="newDiv" />,
      <MenuItem key="newPatient" onClick={handleNew} text="New Patient" />
    ];

    const formatInfo = !value && !valid && (
      <div className="pa1 ph2">
        <H6>Format must be #####-####</H6>
      </div>
    );

    const minLengthInstructions = !value && query.length < 7 && (
      <div className="pa1 ph2">
        <H6>Enter at least 6 characters to search</H6>
      </div>
    );

    return (
      <Menu>
        {formatInfo}
        {minLengthInstructions}
        {matchesSection}
        {valid && addNewPatientSection}
      </Menu>
    );
  };

  private itemRenderer: ItemRenderer<Patient> = (
    value,
    { handleClick, modifiers: { disabled } }
  ) => {
    return (
      <MenuItem
        active={value === this.props.value}
        disabled={disabled}
        key={value.id}
        onClick={handleClick}
        text={`${value.regionalPatientId} ${fullName(value)}`}
      />
    );
  };

  private inputValueRenderer = (item: Patient) => item.regionalPatientId;

  private selectionHandler = (i: Patient) => {
    const { onChange } = this.props;
    if (onChange) {
      onChange(i, false);
    }
  };

  private doApiPatientSearch = async (query: string) => {
    if (query.length < 7) {
      this.setState({ searchResults: [], query });
    } else {
      if (query.length > 6) {
        this.setState({ loading: true, query, error: undefined });
        try {
          const response = await makeApiRequest(
            requestConfigForAction(
              searchPatients.request(query),
              this.props.token
            )
          );
          if (response.apiResponse.data) {
            const patients = arrayFromPayload(
              response.apiResponse.data as IXmedObjectInfo[],
              Patient
            );
            this.setState({
              loading: false,
              searchResults: [...patients]
            });
          }
        } catch (error) {
          this.setState({ loading: false, error });
        }
      }
    }
  };
}

const mapStateToProps = ({
  userStore
}: IStoreState): IPatientSelectInjectedProps => {
  return {
    token: userStore.authToken
  };
};

export const PatientSelect = connect(
  mapStateToProps,
  {}
)(PatientSelectComponent);
