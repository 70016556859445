import { IconNames } from "@blueprintjs/icons";
import Konva from "konva";

import {
  createAnchorPoint,
  createBlueprintIcon,
  createTextArea,
  ISubNodeRenderProps,
  kBackgroundShadow,
  kCharcoalGray,
  kCornerRadius,
  kEditGrey,
  kMinHeight,
  kNodeWidth,
  kOrange
} from ".";
import { createAlgoLinkVisual } from "./utilities";

export const createOutputNode = (props: ISubNodeRenderProps) => {
  const {
    algoNodes,
    nodeId,
    parameters,
    parent,
    path,
    subSelectionId,
    x,
    y
  } = props;
  const node = algoNodes()[nodeId];

  let height = 0;
  const fill = "white";
  const textColour = kCharcoalGray;

  // Generate ID and title
  const konvaNodeId = `${nodeId}=${path.id}`;
  const text = path.title(parent.kind, parameters);

  const containerGroup = new Konva.Group({
    draggable: false,
    id: `${nodeId}`,
    x,
    y
  });

  // Icons
  containerGroup.add(
    new Konva.Circle({ radius: 8, fill: kEditGrey, x: 18, y: 16 })
  );
  containerGroup.add(
    createBlueprintIcon({
      colour: "white",
      id: konvaNodeId,
      name: IconNames.ARROW_RIGHT,
      scale: 0.75,
      x: 12,
      y: 10
    })
  );

  // Text
  const titleText = createTextArea({
    id: `${konvaNodeId}=titleText`,
    text,
    textColour,
    width: kNodeWidth - 60,
    x: 25
  });

  height += titleText.getHeight();
  containerGroup.add(titleText);

  height = Math.max(height, kMinHeight);

  const anchorOut = createAnchorPoint({
    id: `${parent.id}=out=${path.id}`,
    x: kNodeWidth,
    y: height / 2
  });
  containerGroup.add(anchorOut);

  createAlgoLinkVisual({
    container: containerGroup,
    height,
    idIcon: `${parent.id}=algo=${path.targetAlgorithmId}`,
    idLine: `${nodeId}=algoLink=${path.id}`,
    target: { path, node }
  });

  // Background
  const bgRect = new Konva.Rect({
    cornerRadius: kCornerRadius,
    fill,
    height,
    id: `${parent.id}=bg=${path.id}`,
    ...kBackgroundShadow,
    stroke: subSelectionId() === path.id ? kOrange : undefined,
    strokeWidth: 4,
    width: kNodeWidth
  });
  containerGroup.add(bgRect);
  bgRect.moveToBottom();

  return { group: containerGroup, height, anchorOut };
};
