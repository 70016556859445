import { Classes } from "@blueprintjs/core";
import * as React from "react";

import logo from "src/resources/openxmed-logo-orange.svg";

export class BrowserUnsupported extends React.PureComponent {
  public render() {
    return (
      <article className={`${Classes.DARK} flex justify-center w-100 h-100`}>
        <section className="pa2 pt3">
          <img src={logo} className="pt3" alt="logo" />
          <p className="ttu pa1 mb1 zx-blue-extra-light">
            Browser Not Supported
          </p>
          <p className="white" style={{ maxWidth: 500 }}>
            Unfortunately, your current brwoser is not supported by Openxmed.
            However, it&apos;s an easy fix: just update to any of these
            browsers:
          </p>
          <ul className="white">
            <li>
              <a href="https://www.google.com/chrome/">Chrome</a> 42 or later
            </li>
            <li>
              <a href="https://play.google.com/store/apps/details?id=com.android.chrome&hl=en_US">
                Chrome for Android
              </a>{" "}
              70 or later
            </li>
            <li>
              <a href="https://www.microsoft.com/en-ca/windows/microsoft-edge">
                Edge
              </a>{" "}
              14 or later
            </li>
            <li>
              <a href="https://www.mozilla.org/en-CA/firefox/">Firefox</a> 39 or
              later
            </li>
            <li>
              <a href="https://www.mozilla.org/en-CA/firefox/mobile/">
                Firefox for Android
              </a>{" "}
              63 or later
            </li>
            <li>
              <a href="https://www.apple.com/ca/safari/">Safari</a> for macOS
              10.1 or later (macOS 10.10 and above)
            </li>
            <li>
              <a href="https://www.apple.com/ca/safari/">Safari for iOS</a> (iOS
              10.3 and above)
            </li>
            <li>
              <a href="https://www.opera.com">Opera</a> 29 or later
            </li>
            <li>
              <a href="https://www.opera.com/mobile">Opera Mobile</a> 46 or
              later
            </li>
          </ul>
        </section>
      </article>
    );
  }
}
