import { createAction } from "typesafe-actions";

import { CssSize } from "src/components";
import { IBreadcrumbLocation } from "src/store";
import { Media } from "src/api";

export const setBreadcrumbs = createAction("setBreadCrumbs")<
  IBreadcrumbLocation[]
>();

export const setUiError = createAction("setUiError")<Error>();
export const clearUiError = createAction("clearUiError")();
export const setCssSize = createAction("setCssSize")<CssSize>();
export const setLeftMenuState = createAction("setLeftMenuState")<boolean>();

interface ISetAlgoDialogState {
  baseAlgoId?: string;
  open: boolean;
  version?: number;
}

export const setNewAlgoDialogState = createAction("setNewAlgoDialogState")<
  ISetAlgoDialogState
>();

export const setLightboxSource = createAction("setLightboxSource")<{
  caption: string;
  media: Media;
}>();

export const clearLightboxSource = createAction("clearLightboxSource")();
