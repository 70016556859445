import { H5, Icon } from "@blueprintjs/core";
import { push } from "connected-react-router";
import DOMPurify from "dompurify";
import marked from "marked";
import * as React from "react";

import { IconNames } from "@blueprintjs/icons";
import { cloneDeep, flatten, uniqBy } from "lodash";
import {
  AlgoNodeType,
  AlgorithmSearchResult,
  AlgorithmStatus,
  algoStatusString,
  fullName,
  kHighlightEndRegEx,
  kHighlightStartRegEx,
  notEmpty,
  User
} from "src/api";
import {
  AlgorithmSearchResultSource,
  ISearchNodeType
} from "src/api/model/algo-search-result-source";
import { kCommunityRootUrl, kLibraryRootUrl } from "src/config/routes";
import { markdownStripper, nodeDecisionTextPlain } from "src/utilities";

interface INodeMatch {
  node: {
    parentId?: string;
    id: string;
    title: string;
    info: string;
    kind: AlgoNodeType;
  };
  algoId: string;
  nodes: ISearchNodeType[];
  isInfo: boolean;
  matchedString: string;
}

interface INodeDetails {
  allInfoQnodes: INodeMatch[];
  infoMatchChoiceNodes: INodeMatch[];
  otherInfoMatchNodes: INodeMatch[];
  otherTitleMatchNodes: INodeMatch[];
  questionNodes: INodeMatch[];
  titleMatchChoiceNodes: INodeMatch[];
}

export interface IAlgoSearchResultProps {
  result: AlgorithmSearchResult;
  isCurrent: boolean;
  push: typeof push;
  query: string;
}

export class AlgoSearchResult extends React.PureComponent<
  IAlgoSearchResultProps
> {
  public render() {
    const { isCurrent, result } = this.props;
    const goToAlgo = () => this.goToAlgorithm();

    const sectionHeaderRenderer = () => {
      const html = DOMPurify.sanitize(
        marked(
          `${result._source.title} ${
            result._source.status !== AlgorithmStatus.published
              ? ` (${algoStatusString(result._source.status)})`
              : ""
          }`,
          { renderer: markdownStripper() }
        )
      );

      return (
        <H5
          className="pointer"
          onClick={goToAlgo}
          dangerouslySetInnerHTML={{ __html: html }}
        />
      );
    };
    return (
      <article className="pa2 w-100" key={result.id}>
        {!isCurrent && sectionHeaderRenderer()}
        {this.renderHighlights(result)}
      </article>
    );
  }

  public renderTextInSpan = (text: string) => {
    const html = DOMPurify.sanitize(
      marked(text, { renderer: markdownStripper() })
        .replace(kHighlightStartRegEx, "<strong>")
        .replace(kHighlightEndRegEx, "</strong>")
    );
    return <span dangerouslySetInnerHTML={{ __html: html }} />;
  };

  private goToAlgorithm = (option?: string) =>
    this.props.push(this.pushUrl(undefined, option));

  private pushUrl = (nodeId?: string, options?: string) => {
    const { result } = this.props;
    const { id, medicalSpecialties } = result._source;
    const specialty =
      medicalSpecialties && medicalSpecialties[0] && medicalSpecialties[0].id;
    return `${kLibraryRootUrl}/${specialty}/${id}${nodeId ? `/${nodeId}` : ""}${
      options ? `?nav=${options}` : ""
    }`;
  };

  private renderHighlights = (result: AlgorithmSearchResult) => {
    const { highlight } = result;
    if (!highlight) {
      return null;
    }
    const nodeTitleMatches = this.nodeTitleMatches(result);
    const nodeInfoMatches = this.nodeInfoMatches(result);
    const userMatches = this.userMatches(result);

    const nodeDetails = this.categorizeNodes(
      nodeTitleMatches,
      nodeInfoMatches,
      result._source.nodes
    );
    const goToAlgoInfo = () => this.goToAlgorithm("info");

    return (
      <section key={result._source.id} className="pl2 pt2">
        <div onClick={goToAlgoInfo}>
          {highlight.summary && this.renderAlgoHits(highlight.summary)}
        </div>
        <div onClick={goToAlgoInfo}>
          {highlight.synopsis && this.renderAlgoHits(highlight.synopsis)}
        </div>
        <div>{userMatches && this.renderUserMatches(userMatches)}</div>
        <div onClick={goToAlgoInfo}>
          {highlight.epidemiology &&
            this.renderAlgoHits(highlight.epidemiology)}
        </div>
        <div onClick={goToAlgoInfo}>
          {highlight.evidence && this.renderAlgoHits(highlight.evidence)}
        </div>
        {this.renderQuestionHits(nodeDetails)}
        {this.renderOtherNodeHits(nodeDetails)}
      </section>
    );
  };

  private categorizeNodes = (
    titleMatches: INodeMatch[] | undefined,
    infoMatches: INodeMatch[] | undefined,
    nodeSource: ISearchNodeType[]
  ) => {
    const questionNodes: INodeMatch[] = [];
    const titleMatchChoiceNodes: INodeMatch[] = [];
    const otherTitleMatchNodes: INodeMatch[] = [];

    const allInfoQnodes: INodeMatch[] = [];
    const infoMatchChoiceNodes: INodeMatch[] = [];
    const otherInfoMatchNodes: INodeMatch[] = [];

    if (titleMatches) {
      titleMatches.forEach(m => {
        const { node } = m;
        if (!m) {
          return;
        }
        switch (node.kind) {
          case AlgoNodeType.singleSelect:
          case AlgoNodeType.multiSelect:
            questionNodes.push(m);
            break;

          case AlgoNodeType.choice:
            titleMatchChoiceNodes.push(m);
            break;

          default:
            otherTitleMatchNodes.push(m);
        }
      });
    }

    if (infoMatches) {
      infoMatches.forEach(m => {
        const { node } = m;
        if (!m) {
          return;
        }

        switch (node.kind) {
          case AlgoNodeType.singleSelect:
          case AlgoNodeType.multiSelect:
            allInfoQnodes.push(m);
            break;

          case AlgoNodeType.choice:
            infoMatchChoiceNodes.push(m);
            break;

          default:
            otherInfoMatchNodes.push(m);
        }
      });

      // Gotta insert info nodes if there isn't one there on the title, otherwise it won't show
      allInfoQnodes.forEach(n => {
        const index = questionNodes.findIndex(q => q.node.id === n.node.id);
        if (index < 0) {
          const fakeParent = cloneDeep(n);
          fakeParent.matchedString = nodeDecisionTextPlain(n.node);
          questionNodes.push(fakeParent);
        }
      });

      // Same for non-question nodes
      otherInfoMatchNodes.forEach(n => {
        const index = otherTitleMatchNodes.findIndex(
          q => q.node.id === n.node.id
        );
        if (index < 0) {
          const fakeParent = cloneDeep(n);
          fakeParent.matchedString = nodeDecisionTextPlain(n.node);
          otherTitleMatchNodes.push(fakeParent);
        }
      });
    }

    // Add info for choices if not there
    infoMatchChoiceNodes.forEach(mn => {
      const index = titleMatchChoiceNodes.findIndex(
        cm => cm.node.id === mn.node.id
      );
      if (index < 0) {
        const fakeParent = cloneDeep(mn);
        fakeParent.matchedString = nodeDecisionTextPlain(mn.node);
        titleMatchChoiceNodes.push(fakeParent);
      }
    });

    // And finally, add question for a choice if it's not there
    titleMatchChoiceNodes.forEach(cnm => {
      const index = questionNodes.findIndex(
        q => q.node.id === cnm.node.parentId
      );
      if (index < 0) {
        const parent = nodeSource.filter(n => n.id === cnm.node.parentId)[0];
        if (parent) {
          questionNodes.push({
            ...cnm,
            matchedString: nodeDecisionTextPlain(parent),
            node: parent
          });
        }
      }
    });

    return {
      allInfoQnodes,
      infoMatchChoiceNodes,
      otherInfoMatchNodes,
      otherTitleMatchNodes,
      questionNodes,
      titleMatchChoiceNodes
    };
  };

  private nodeTitleMatches = (result: AlgorithmSearchResult) => {
    const { highlight } = result;
    if (highlight) {
      const nodeTitleMatches = highlight["nodes.title"];

      if (nodeTitleMatches) {
        const titleMatches = this.nodesFromMatches(
          nodeTitleMatches,
          "title",
          result._source
        );
        return titleMatches;
      }
    }
    return undefined;
  };

  private nodeInfoMatches = (
    result: AlgorithmSearchResult
  ): INodeMatch[] | undefined => {
    const { highlight } = result;

    if (highlight) {
      const infoMatches = ["definitions", "info", "dosages"]
        .map(t => {
          const highlighted = highlight[`nodes.${t}`];
          if (highlighted) {
            return this.nodesFromMatches(highlighted, t, result._source);
          }
          return undefined;
        })
        .filter(notEmpty);

      if (infoMatches) {
        const returnVals: INodeMatch[] = [];
        return returnVals.concat.apply([], infoMatches);
      }
    }
    return undefined;
  };

  private userMatches = (
    result: AlgorithmSearchResult
  ): Array<Partial<User>> | undefined => {
    const { highlight } = result;

    if (highlight) {
      const areas = ["authors", "editors"];
      const userFields = ["lastName", "firstName"];
      const userMatches = areas.map(a => {
        return flatten(
          userFields
            .map(f => {
              const highlighted = highlight[`${a}.${f}`];
              if (highlighted) {
                return this.usersFromMatches(highlighted, a, f, result._source);
              }
              return undefined;
            })
            .filter(notEmpty)
        );
      });

      if (userMatches) {
        return flatten(userMatches).filter(notEmpty);
      }
    }
    return undefined;
  };

  private nodesFromMatches = (
    matches: string[],
    field: string,
    source: AlgorithmSearchResultSource
  ) => {
    const results = matches
      .map(s => {
        const strippedString = s
          .replace(kHighlightStartRegEx, "")
          .replace(kHighlightEndRegEx, "");
        const nodes = source.nodes.filter(
          n => n[field] && n[field].includes(strippedString)
        );
        const node = nodes && nodes[0];
        const isInfo = field !== "title";
        if (node) {
          let parentId;
          if (node.paths) {
            node.paths
              .filter(p => p.childId === node.id)
              .forEach(p => {
                // TODO: make multi-parent nodes work better than this. (when we care...)
                parentId = p.parentId;
              });
          }
          return {
            algoId: source.id,
            isInfo,
            matchedString: s,
            node: {
              ...node,
              parentId
            },
            nodes: source.nodes
          };
        }
        return undefined;
      })
      .filter(notEmpty);
    return uniqBy(results, r => r.node.id);
  };

  private usersFromMatches = (
    matches: string[],
    area: string,
    field: string,
    source: AlgorithmSearchResultSource
  ) => {
    const results = matches
      .map(m => {
        const strippedString = m
          .replace(kHighlightStartRegEx, "")
          .replace(kHighlightEndRegEx, "");

        const user = source[area].filter(
          (a: Partial<User>) => a[field] && a[field].includes(strippedString)
        );
        return user;
      })
      .filter(notEmpty);

    return uniqBy(flatten(results), r => r.id);
  };

  private renderQuestionHits = (nodeDetails: INodeDetails) => {
    return nodeDetails.questionNodes.map(qn =>
      this.renderQuestionNode(qn, nodeDetails)
    );
  };

  private renderQuestionNode = (
    { node, matchedString }: INodeMatch,
    allNodeMatches: INodeDetails
  ) => {
    const {
      allInfoQnodes,
      titleMatchChoiceNodes,
      infoMatchChoiceNodes
    } = allNodeMatches;
    if (!node) {
      return null;
    }
    const infoNodes =
      allInfoQnodes && allInfoQnodes.filter(n => n.node.id === node.id);

    const goToAlgoWithNode = () => {
      this.props.push(this.pushUrl(node.id));
    };
    const goToInfoNode = () => {
      this.props.push(this.pushUrl(node.id, "info"));
    };

    const titleMatchRenderer = () => {
      const icon =
        node.kind === AlgoNodeType.singleSelect ? (
          <span className="zx-icon-text">A</span>
        ) : (
          <Icon
            className="mr2 zx-blue-extra-light"
            icon={IconNames.TICK_CIRCLE}
            iconSize={14}
          />
        );

      return titleMatchChoiceNodes
        .filter(m => m.node.parentId === node.id)
        .map((n, i) => {
          const infoMatchRenderer = () =>
            infoMatchChoiceNodes
              .filter(m => m.node.id === n.node.id)
              .map((cn, ci) => {
                return (
                  <p key={ci} onClick={goToInfoNode} className="pointer ml4">
                    <Icon
                      className="mr2 zx-blue-extra-light"
                      icon={IconNames.INFO_SIGN}
                      iconSize={14}
                    />
                    {this.renderTextInSpan(cn.matchedString)}
                  </p>
                );
              });

          return (
            <section key={i}>
              <p key={i} onClick={goToAlgoWithNode} className="pointer ml3">
                {icon}
                {this.renderTextInSpan(n.matchedString)}
              </p>
              {infoMatchChoiceNodes && infoMatchRenderer()}
            </section>
          );
        });
    };

    return (
      <div key={node.id}>
        <p onClick={goToAlgoWithNode} className="pointer">
          <span className="zx-icon-text">Q</span>
          {this.renderTextInSpan(matchedString)}
        </p>
        {infoNodes && this.infoNodeRenderer(infoNodes, goToInfoNode)}
        {titleMatchChoiceNodes && titleMatchRenderer()}
      </div>
    );
  };

  private infoNodeRenderer = (
    infoNodes: INodeMatch[],
    clickHandler?: () => void
  ) =>
    infoNodes.map((n, i) => {
      const clss = `ml3 ${clickHandler ? "pointer" : ""}`;
      return (
        <p key={i} onClick={clickHandler} className={clss}>
          <Icon
            className="mr2 zx-blue-extra-light"
            icon={IconNames.INFO_SIGN}
            iconSize={14}
          />
          {this.renderTextInSpan(n.matchedString)}
        </p>
      );
    });

  private renderOtherNodeHits = (nodeDetails: INodeDetails) => {
    return nodeDetails.otherTitleMatchNodes.map(n =>
      this.renderNonQuestionNode(n, nodeDetails)
    );
  };

  private renderNonQuestionNode = (
    { node, matchedString }: INodeMatch,
    allNodeMatches: INodeDetails
  ) => {
    const { otherInfoMatchNodes } = allNodeMatches;
    if (!node) {
      return null;
    }
    const infoNodes =
      otherInfoMatchNodes &&
      otherInfoMatchNodes.filter(n => n.node.id === node.id);

    const goToAlgoWithNode = () => {
      this.props.push(this.pushUrl(node.id));
    };

    const icon = (
      <span className="zx-icon-text">{`${
        node.kind === AlgoNodeType.terminal ? "T" : "I"
      }`}</span>
    );

    return (
      <div key={node.id} onClick={goToAlgoWithNode} className="pointer">
        <p>
          {icon}
          {this.renderTextInSpan(matchedString)}
        </p>
        {infoNodes && this.infoNodeRenderer(infoNodes)}
      </div>
    );
  };

  private renderAlgoHits = (titles: string[] | undefined) => {
    if (!titles) {
      return null;
    }

    return titles.map(s => {
      return (
        <p key={s} className="pointer">
          <Icon
            className="mr2 zx-blue-extra-light"
            icon={IconNames.INFO_SIGN}
            iconSize={14}
          />
          {this.renderTextInSpan(s)}
        </p>
      );
    });
  };

  private renderUserMatches = (userMatches: Array<Partial<User>>) => {
    return userMatches.map(u => {
      const goToContributor = () => {
        this.props.push(`${kCommunityRootUrl}/${u.id}`);
      };
      return (
        <p key={u.id} className="pointer" onClick={goToContributor}>
          <Icon
            className="mr2 zx-blue-extra-light"
            icon={IconNames.PERSON}
            iconSize={14}
          />
          {this.renderTextInSpan(fullName(u as User))}
        </p>
      );
    });
  };
}
