import { H5 } from "@blueprintjs/core";
import { push } from "connected-react-router";
import { Dictionary } from "lodash";
import marked from "marked";
import * as React from "react";
import { connect } from "react-redux";

import {
  changeAlgoVar,
  IConsumeAlgoPayload,
  setLightboxSource
} from "src/actions";
import { AlgoNode, Algorithm, CardAttributes } from "src/api";
import { CssSize } from "src/components";
import { InfoTabType, IOpenAlgorithm, IStoreState } from "src/store";
import { markdownStripper, nodeDecisionTextPlain } from "src/utilities";
import { NodeCard } from ".";

interface IAlgoScrollerProps {
  openAlgo: IOpenAlgorithm;
  currentPage: number;
  nodeIdForInfo?: string;
  nodeIdForComments?: string;
  width?: number;
  cssSize: CssSize;
  tappedInfo: (node: AlgoNode, focus: InfoTabType) => void;
  tappedComments: (node: AlgoNode) => void;
  tappedNode: (nodeDetails: IConsumeAlgoPayload) => void;
  moveToPage: (page: number) => void;
}

interface IAlgoScrollerInjectedProps {
  allAlgorithms: Dictionary<Algorithm>;
}

interface IAlgoScrollerDispatchProps {
  setLightboxSource: typeof setLightboxSource;
  changeAlgoVar: typeof changeAlgoVar;
  push: typeof push;
}

declare type AlgoScrollerComponentProps = IAlgoScrollerProps &
  IAlgoScrollerInjectedProps &
  IAlgoScrollerDispatchProps;

class AlgoScrollerComponent extends React.PureComponent<
  AlgoScrollerComponentProps
> {
  private ignoreMouseEvents = false; // This is set to true if touch events are handled - we get both on some browsers...
  private algoScroller = React.createRef<HTMLDivElement>();
  private pointerDown = false;
  private startX = 0;
  private startY = 0;
  private lockScrollTo: undefined | "h" | "v";
  private scrollXStart = 0;
  private scrollYStart = 0;
  private scrollTimeout: NodeJS.Timeout | undefined;
  private endInteractionTimeout: NodeJS.Timeout | undefined;
  private currentScrollAnimation = -1;
  private panInProgress = false;
  private cards: Dictionary<Dictionary<React.RefObject<NodeCard>>> = {};

  public componentDidMount() {
    const { currentPage, width } = this.props;
    const { algoScroller } = this;
    if (algoScroller.current && width && currentPage > 0) {
      const pageSize = width ? width : 0;
      algoScroller.current.scrollLeft = Math.round(pageSize * currentPage);
    }
  }

  public componentDidUpdate(oldProps: IAlgoScrollerProps) {
    const { currentPage, width } = this.props;
    const { algoScroller } = this;

    if (algoScroller.current) {
      this.clearTimeouts();
      const pageSize = width ? width : 0;
      const scrollLeft = Math.round(pageSize * currentPage);
      let scrollTop = algoScroller.current.scrollTop;

      if (oldProps.currentPage !== currentPage) {
        scrollTop = 0;
        this.animateScrollTo(scrollTop, scrollLeft);
      } else if (width !== oldProps.width) {
        algoScroller.current.scrollLeft = scrollLeft;
      }
    }
  }

  public render() {
    const { openAlgo: algorithm, cssSize, width } = this.props;
    const { decisionNodeIndicies } = algorithm;

    const style = {
      marginBottom: cssSize < 2 ? 0 : -15,
      marginTop: cssSize < 2 ? 0 : -33,
      width
    };

    const algorithms = algorithm.sectionNodes.map((section, index) =>
      section.length > 0
        ? this.renderSection(section, decisionNodeIndicies[index], index)
        : null
    );

    return (
      <div
        onTouchCancel={this.onTouchCancel}
        onMouseDown={this.onMouseDown}
        onMouseUp={this.onMouseUp}
        onMouseMove={this.onMouseMove}
        onWheel={this.onWheel}
        onScroll={this.onScroll}
        onTouchEnd={this.onTouchEnd}
        onTouchStart={this.onTouchStart}
        onTouchMove={this.onTouchMove}
        onDragLeave={this.onDragLeave}
        onMouseLeave={this.onMouseLeave}
        ref={this.algoScroller}
        className="br3 flex overflow-scroll zx-hide-scrollbar zx-no-selection"
        style={style}
      >
        {algorithms}
      </div>
    );
  }

  private renderSection = (
    sectionNodes: CardAttributes[],
    decisionNodeIndex: number,
    column: number
  ) => {
    const { openAlgo } = this.props;
    if (!openAlgo) {
      return null;
    }
    const decisionTitle = nodeDecisionTextPlain(
      sectionNodes[decisionNodeIndex].node
    );
    this.cards[column] = {};

    return (
      <section key={sectionNodes[0].position.column} className={"mt1"}>
        <H5 className="ttu" style={{ marginLeft: 8 }}>
          {marked(decisionTitle, { renderer: markdownStripper() })}
        </H5>
        {this.sectionRenderer(sectionNodes, decisionNodeIndex, column)}
      </section>
    );
  };

  private sectionRenderer = (
    sectionNodes: CardAttributes[],
    decisionNodeIndex: number,
    column: number
  ) => {
    const { openAlgo, width } = this.props;

    return sectionNodes.map((n, cardRow) => {
      const selection = openAlgo.algoState.decisionsJson.find(
        sd => sd.nodeId === n.node.id
      );
      const colourClass = this.colourClassForCard(cardRow, decisionNodeIndex);
      const handleClick = (node: AlgoNode) => {
        if (!this.panInProgress) {
          this.props.tappedNode({
            nodeAttributes: n,
            openAlgorithm: openAlgo,
            tappedChoice: node.id !== n.node.id ? node : undefined
          });
        }
      };
      const handleComments = (node: AlgoNode) => {
        this.props.tappedComments(node);
      };
      const handleInfo = (node: AlgoNode, focus: InfoTabType) => {
        this.props.tappedInfo(node, focus);
      };

      this.cards[column][cardRow] = React.createRef();

      return (
        <NodeCard
          ref={this.cards[column][cardRow]}
          style={cardRow !== 0 ? { marginTop: -10 } : undefined}
          className={`${colourClass}`}
          changeAlgoVar={this.props.changeAlgoVar}
          width={width}
          key={cardRow}
          row={cardRow}
          column={column}
          nodeDetails={n}
          isLast={cardRow === sectionNodes.length - 1}
          {...this.props}
          {...{
            handleClick,
            handleComments,
            handleInfo,
            selection
          }}
        />
      );
    });
  };

  private colourClassForCard = (index: number, decisionNodeIndex: number) => {
    const preIndex = decisionNodeIndex - index;
    if (preIndex > 0) {
      switch (index % 3) {
        case 0:
          return "zx-backmost-card-blue white";
        case 1:
          return "zx-backmost-but-one-card-blue white";
        case 2:
          return "zx-backmost-but-two-card-blue white";
      }
    }
    return "bg-white";
  };

  private onMouseDown = (e: React.MouseEvent<HTMLDivElement>) => {
    if (!this.ignoreMouseEvents) {
      this.handlePointerStart(e, e.clientX, e.clientY);
    }
  };
  private onTouchStart = (e: React.TouchEvent<HTMLDivElement>) => {
    this.ignoreMouseEvents = true;
    this.handlePointerStart(e, e.touches[0].clientX, e.touches[0].clientY);
  };

  private handlePointerStart = (
    e: React.MouseEvent<HTMLDivElement> | React.TouchEvent<HTMLDivElement>,
    x: number,
    y: number
  ) => {
    this.clearTimeouts();
    this.pointerDown = true;
    this.startX = x;
    this.startY = y;
    this.scrollXStart = e.currentTarget.scrollLeft;
    this.scrollYStart = e.currentTarget.scrollTop;
  };

  private onMouseMove = (e: React.MouseEvent<HTMLDivElement>) => {
    if (!this.ignoreMouseEvents) {
      this.handleMove(this.startX - e.clientX, this.startY - e.clientY, e);
    }
  };
  private onTouchMove = (e: React.TouchEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    this.handleMove(
      this.startX - e.touches[0].clientX,
      this.startY - e.touches[0].clientY,
      e
    );
  };

  private handleMove = (
    diffX: number,
    diffY: number,
    e: React.SyntheticEvent
  ) => {
    if (this.pointerDown && this.algoScroller.current) {
      const xAmount = Math.abs(diffX);
      const yAmount = Math.abs(diffY);

      if (!this.lockScrollTo) {
        this.lockScrollTo = xAmount > 2 ? "h" : yAmount > 4 ? "v" : undefined;
      }

      let xPos = this.scrollXStart + diffX;
      let yPos = this.scrollYStart + diffY;

      if (this.panInProgress || this.lockScrollTo) {
        this.panInProgress = true;
        if (!this.ignoreMouseEvents) {
          // Only lock scroll on mouse systems.
          if (this.lockScrollTo === "h") {
            yPos = this.scrollYStart;
          } else if (this.lockScrollTo === "v") {
            xPos = this.scrollXStart;
          }
        }
      }
      e.preventDefault();
      e.stopPropagation();
      this.algoScroller.current.scrollTop = yPos;
      this.algoScroller.current.scrollLeft = xPos;
    }
  };

  private onMouseUp = (e: React.MouseEvent<HTMLDivElement>) => {
    if (!this.ignoreMouseEvents) {
      this.handlePointerEnd(e);
    }
  };
  private onTouchEnd = (e: React.TouchEvent<HTMLDivElement>) =>
    this.handlePointerEnd(e);
  private handlePointerEnd = (
    e: React.TouchEvent<HTMLDivElement> | React.MouseEvent<HTMLDivElement>
  ) => {
    this.endInteraction(e);
  };

  private onTouchCancel = (
    e: React.TouchEvent<HTMLDivElement> | React.MouseEvent<HTMLDivElement>
  ) => this.endInteraction(e);

  private handleEndOfPan = (change: number) => {
    const { currentPage, width, moveToPage } = this.props;

    if (width && this.algoScroller.current) {
      const magnitude = Math.abs(change);
      if (magnitude > width / 2) {
        const pageCount = Math.round(magnitude / width);

        if (change < 0) {
          moveToPage(currentPage - pageCount);
        } else {
          moveToPage(currentPage + pageCount);
        }
      } else {
        this.animateScrollTo(
          this.algoScroller.current.scrollTop,
          Math.floor(width * currentPage)
        );
      }
    }
    this.panInProgress = false;
  };

  private onWheel = (e: React.WheelEvent<HTMLDivElement>) => {
    const { currentPage, width } = this.props;
    if (width && this.algoScroller.current) {
      const expectedX = currentPage * width;
      const currentX = e.currentTarget.scrollLeft;
      this.handleEndOfPanLater(currentX - expectedX);
      this.algoScroller.current.scrollTop = this.verticalScrollPosition(e);
    }
  };

  private onScroll = (e: React.UIEvent<HTMLDivElement>) => {
    const { currentPage, width } = this.props;

    if (width && this.algoScroller) {
      // Handle inertial scrolling on mobile devices...
      if (!this.pointerDown && this.panInProgress) {
        const expectedX = currentPage * width;
        const currentX = e.currentTarget.scrollLeft;
        this.handleEndOfPanLater(currentX - expectedX);
      }
    }
  };

  private handleEndOfPanLater = (xDelta: number) => {
    this.clearTimeouts();
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const that = this;
    this.scrollTimeout = setTimeout(() => {
      that.handleEndOfPan(xDelta);
    }, 100);
  };

  private clearTimeouts = () => {
    if (this.scrollTimeout) {
      clearTimeout(this.scrollTimeout);
    }
    if (this.endInteractionTimeout) {
      clearTimeout(this.endInteractionTimeout);
    }
  };

  private verticalScrollPosition = (e: React.SyntheticEvent) => {
    // Limit the vertical scroll DISABLED UNTIL FIND OUT WHY .current.frame.height is not updated on expansion
    // const sectionCards = this.cards[this.props.currentPage];
    // const lastIndex = Object.keys(sectionCards)
    //   .sort()
    //   .pop();
    // if (lastIndex) {
    //   const bottomCard = sectionCards[lastIndex];
    //   if (bottomCard && bottomCard.current) {
    //     const frame = bottomCard.current.frame;
    //     if (frame) {
    //       const yOffset = e.currentTarget.scrollTop;
    //       const scrollerFrame = e.currentTarget.getBoundingClientRect();
    //       if (yOffset + scrollerFrame.height > frame.bottom) {
    //         const wheelEvent = e as React.WheelEvent;
    //         if (wheelEvent.deltaY > 0) {
    //           e.preventDefault();
    //           e.stopPropagation();
    //         }
    //       }
    //     }
    //   }
    // }
    return e.currentTarget.scrollTop;
  };

  private onDragLeave = (
    e: React.TouchEvent<HTMLDivElement> | React.MouseEvent<HTMLDivElement>
  ) => this.endInteraction(e);
  private onMouseLeave = (
    e: React.TouchEvent<HTMLDivElement> | React.MouseEvent<HTMLDivElement>
  ) => this.endInteraction(e);
  private endInteraction = (
    e: React.TouchEvent<HTMLDivElement> | React.MouseEvent<HTMLDivElement>
  ) => {
    this.pointerDown = false;
    if (this.panInProgress) {
      e.stopPropagation();

      // Handle the case of no inertia
      this.handleEndOfPanLater(
        (this.algoScroller.current ? this.algoScroller.current.scrollLeft : 0) -
          this.scrollXStart
      );
    }
    this.startX = 0;
    this.startY = 0;
    this.scrollXStart = 0;
    this.scrollYStart = 0;
    this.lockScrollTo = undefined;

    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const that = this;
    // Do this so that the click events on children are consumed rather than firing
    this.endInteractionTimeout = setTimeout(() => {
      that.panInProgress = false;
      that.lockScrollTo = undefined;
    }, 100);
  };

  private animateScrollTo = async (top: number, left: number) => {
    const duration = 400;
    const step = 20;

    return new Promise(resolve => {
      if (this.algoScroller.current) {
        if (this.currentScrollAnimation > -1) {
          cancelAnimationFrame(this.currentScrollAnimation);
          this.currentScrollAnimation = -1;
        }

        const startTop = this.algoScroller.current.scrollTop;
        const changeTop = top - startTop;
        const startLeft = this.algoScroller.current.scrollLeft;
        const changeLeft = left - startLeft;
        let currentTime = 0;

        const animate = () => {
          currentTime += step;
          const nextTop = this.easing(
            currentTime,
            startTop,
            changeTop,
            duration
          );
          const nextLeft = this.easing(
            currentTime,
            startLeft,
            changeLeft,
            duration
          );
          if (this.algoScroller.current) {
            this.algoScroller.current.scrollLeft = nextLeft;
            this.algoScroller.current.scrollTop = nextTop;
          }
          if (currentTime < duration) {
            this.currentScrollAnimation = requestAnimationFrame(animate);
          } else {
            resolve();
          }
        };
        animate();
      }
    });
  };

  private easing = (
    time: number,
    start: number,
    change: number,
    duration: number
  ) => {
    time /= duration / 2;
    if (time < 1) {
      return (change / 2) * time * time + start;
    }

    time--;
    return (-change / 2) * (time * (time - 2) - 1) + start;
  };
}

const mapStateToProps = ({
  algoStore
}: IStoreState): IAlgoScrollerInjectedProps => {
  return {
    allAlgorithms: algoStore.allAlgorithms
  };
};

export const AlgoScroller = connect<
  IAlgoScrollerInjectedProps,
  IAlgoScrollerDispatchProps,
  IAlgoScrollerProps,
  IStoreState
>(mapStateToProps, { changeAlgoVar, push, setLightboxSource })(
  AlgoScrollerComponent
);
