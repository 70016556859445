import {
  Button,
  Classes,
  Dialog,
  Intent,
  Collapse,
  Checkbox
} from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import * as React from "react";
import { Dictionary } from "lodash";
import { connect } from "react-redux";

import { IStoreState } from "src/store";
import { Study, User, Patient } from "src/api";
import { clearStudyError, createPatient } from "src/actions";
import { PatientSelect } from "src/components";
import { NewPatientForm } from ".";

interface IAddPatientDialogProps {
  isOpen: boolean;
  study: Study;
  onClose: (patient?: Patient) => void;
}

interface IAddPatientDialogInjectedProps {
  error?: Error;
  loggedInUser?: User;
  patients: Dictionary<Patient>;
  studies: Dictionary<Study>;
}

interface IAddAlgorithmDispatchProps {
  clearStudyError: typeof clearStudyError;
  createPatient: typeof createPatient.request;
}

interface IAddPatientDialogComponentState {
  consentCheckboxChecked: boolean;
  created: boolean;
  patientToAdd?: Patient;
  busy: boolean;
}

type AddPatientDialogProps = IAddPatientDialogProps &
  IAddPatientDialogInjectedProps &
  IAddAlgorithmDispatchProps;

class AddPatientDialogComponent extends React.PureComponent<
  AddPatientDialogProps,
  IAddPatientDialogComponentState
> {
  constructor(props: AddPatientDialogProps) {
    super(props);

    this.state = {
      busy: false,
      consentCheckboxChecked: false,
      created: false
    };
    this.props.clearStudyError();
  }

  public componentDidUpdate() {
    const { patients, error } = this.props;
    const { busy, patientToAdd } = this.state;
    if (busy && patientToAdd) {
      const createdPatient = patients[patientToAdd.id];
      if (createdPatient) {
        this.closeDialog(createdPatient);
      }
    }
    if (error && busy) {
      this.setState({ busy: false });
    }
  }

  public render() {
    const { isOpen, study } = this.props;
    const handleClose = () => this.closeDialog();

    return (
      <Dialog
        canOutsideClickClose={false}
        icon={IconNames.LAYERS}
        title={`Add Patient to Study "${study.title}"`}
        isOpen={isOpen}
        onClose={handleClose}
      >
        {this.renderBody()}
        {this.renderFooter()}
      </Dialog>
    );
  }

  private renderFooter = () => {
    const { error } = this.props;
    const { busy } = this.state;

    const errorRenderer = (e: Error) => (
      <span className="mh2 orange">{e.message}</span>
    );
    const handleCancel = () => this.closeDialog();
    const handleAdd = () =>
      this.closeDialog(this.state.patientToAdd, this.state.created);

    return (
      <div className={`${Classes.DIALOG_FOOTER}`}>
        {error && errorRenderer(error)}
        <div className="flex flex-row justify-between pt2">
          <Button id="cancel" text="Cancel" onClick={handleCancel} />
          <Button
            disabled={this.addButtonDisabled()}
            intent={Intent.SUCCESS}
            loading={busy}
            onClick={handleAdd}
            text="Add to Study"
          />
        </div>
      </div>
    );
  };

  private addButtonDisabled = () => {
    const { consentCheckboxChecked, patientToAdd } = this.state;
    if (!consentCheckboxChecked) {
      return true;
    }

    if (patientToAdd) {
      if (
        patientToAdd.firstName.length > 0 &&
        patientToAdd.lastName.length > 0 &&
        patientToAdd.dateOfBirth
      ) {
        return false;
      }
    }
    return true;
  };

  private renderBody = () => {
    const { busy, consentCheckboxChecked, created, patientToAdd } = this.state;

    const handleChange = (patient: Patient, newPatient: boolean) => {
      this.setState({ patientToAdd: patient, created: newPatient });
    };

    const handlePatientUpdate = (update: Patient) => {
      this.setState({ patientToAdd: update });
    };

    const handleCheckbox = (e: React.FormEvent<HTMLInputElement>) => {
      this.setState({ consentCheckboxChecked: e.currentTarget.checked });
    };

    return (
      <section className={`${Classes.DIALOG_BODY}`}>
        Patient details:
        <div className="w-50 pv2">
          Health ID:
          <PatientSelect
            className="br2 zx-input-blue-border"
            disabled={busy}
            value={patientToAdd}
            onChange={handleChange}
          />
        </div>
        <Collapse isOpen={patientToAdd ? true : false}>
          <NewPatientForm
            patient={patientToAdd!}
            onChange={handlePatientUpdate}
            disabled={!created || busy}
          />
          <Checkbox
            label="Patient consent form obtained"
            onChange={handleCheckbox}
            checked={consentCheckboxChecked}
          />
        </Collapse>
      </section>
    );
  };

  private closeDialog = (patient?: Patient, created?: boolean) => {
    if (patient && created) {
      this.props.createPatient(patient);
      this.setState({ busy: true });
    } else {
      this.setState({
        busy: false,
        consentCheckboxChecked: false,
        created: false,
        patientToAdd: undefined
      });
      this.props.onClose(patient);
    }
  };
}

const mapStateToProps = ({ studiesStore, userStore }: IStoreState) => {
  return {
    error: studiesStore.error,
    loggedInUser: userStore.loggedInUser,
    patients: studiesStore.patients,
    studies: studiesStore.studies
  };
};

export const AddPatientDialog = connect(mapStateToProps, {
  clearStudyError,
  createPatient: createPatient.request
})(AddPatientDialogComponent);
