import { H5 } from "@blueprintjs/core";
import * as React from "react";

import { IOpenAlgorithm } from "src/store";

export interface IAlgoIntroProps {
  algoDetails: IOpenAlgorithm;
}

export class AlgoIntro extends React.PureComponent<IAlgoIntroProps> {
  public render() {
    const { algorithm } = this.props.algoDetails;
    return (
      <section>
        <H5>Synopsis</H5>
        <p>{algorithm.synopsis}</p>
        <H5>Summary</H5>
        <p>{algorithm.summary}</p>
      </section>
    );
  }
}
