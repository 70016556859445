import * as React from "react";
import { connect } from "react-redux";

import { changeAlgoPage } from "src/actions";
import { IOpenAlgorithm } from "src/store";
import { nodeDecisionTextPlain } from "src/utilities";

export interface IDecisionNodeProps {
  algorithm: IOpenAlgorithm;
  className?: string;
  style?: React.CSSProperties;
  changeAlgoPage: typeof changeAlgoPage;
}

class DecisionNodesComponent extends React.PureComponent<IDecisionNodeProps> {
  public render() {
    const { className, style } = this.props;
    return (
      <section
        style={style}
        className={`h-auto flex-column ${className} w-100 min-h-100`}
      >
        {this.renderDecisions(this.props)}
      </section>
    );
  }

  private renderDecisions = (props: IDecisionNodeProps) => {
    const { algorithm } = props;
    const { decisionNodes, currentPage } = algorithm;

    return decisionNodes.map((node, index) => {
      const questionTitle = nodeDecisionTextPlain(node);
      const isCurrent = currentPage === index;
      const handleClick = () => {
        if (!isCurrent) {
          props.changeAlgoPage({ details: algorithm, page: index });
        }
      };

      return (
        <section
          key={index}
          className={`${isCurrent ? "bg-black-10 br2" : "pointer"}`}
        >
          <p className="pa2" onClick={handleClick}>
            {questionTitle}
          </p>
        </section>
      );
    });
  };
}

export const DecisionNodes = connect(undefined, { changeAlgoPage })(
  DecisionNodesComponent
);
