import {
  Button,
  ButtonGroup,
  H4,
  H5,
  H6,
  Icon,
  Intent,
  Spinner,
  TextArea
} from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import DOMPurify from "dompurify";
import marked from "marked";
import moment from "moment";
import * as React from "react";
import { connect } from "react-redux";

import {
  addComment,
  updateComment,
  updateInProgressComment
} from "src/actions";
import {
  AlgoNode,
  Comment,
  CommentKind,
  CommentRefType,
  fullName,
  sortByCreatedDate,
  User
} from "src/api";
import { IOpenAlgorithm, IStoreState } from "src/store";
import { nodeTitleTextPlain } from "src/utilities";

export interface INodeCommentsProps {
  error?: Error;
  className?: string;
  commentKindFilter?: CommentKind;
  node?: AlgoNode;
  openAlgorithm: IOpenAlgorithm;
  currentUser?: User;
  addCommentRequest: typeof addComment;
  updateCommentRequest: typeof updateComment;
  onClose?: (node: AlgoNode) => void;
  width?: number;
  updateInProgressComment: typeof updateInProgressComment;
}

interface INodeCommentsState {
  commentTarget?: AlgoNode | Comment;
  commentText: string;
  commentType?: CommentKind;
  commentBeingEdited?: Comment;
  savingComment: boolean;
}

class NodeCommentsComponent extends React.PureComponent<
  INodeCommentsProps,
  INodeCommentsState
> {
  constructor(props: INodeCommentsProps) {
    super(props);
    this.state = {
      commentText: this.props.openAlgorithm.currentComment || "",
      savingComment: false
    };
  }

  public componentDidUpdate() {
    const { savingComment } = this.state;
    const {
      error,
      openAlgorithm: { currentComment },
      node,
      onClose
    } = this.props;

    if (savingComment) {
      if (error || !currentComment) {
        this.setState({ savingComment: false, commentTarget: undefined });
        if (!error && onClose && node) {
          onClose(node);
        }
      }
    }
  }

  public render() {
    const {
      className,
      commentKindFilter,
      currentUser,
      node,
      onClose
    } = this.props;
    const { commentTarget } = this.state;
    if (!node) {
      return null;
    }

    const commentRenderer =
      commentTarget && commentTarget instanceof AlgoNode
        ? this.renderTextInput()
        : this.renderComments(
            node.comments
              .filter(c =>
                commentKindFilter ? c.kind === commentKindFilter : true
              )
              .sort((a, b) => b.createdAt.getTime() - a.createdAt.getTime()),
            commentTarget,
            currentUser
          );

    return (
      <article className={`h-auto ${className} dit w-100 pb2`}>
        <section className="pa2 br3 flex flex-column justify-between h-100 w-100">
          <section className="h-auto w-100">
            {onClose && this.renderHeader()}
            {commentRenderer}
          </section>
          {this.renderButtons(node)}
        </section>
      </article>
    );
  }

  private renderHeader = () => {
    const { node, onClose } = this.props;
    const { savingComment, commentTarget } = this.state;

    if (!node) {
      return null;
    }

    const handleClose = () => {
      if (onClose) {
        onClose(node);
      }
    };

    return (
      <section className="flex flex-row justify-between items-start pa2">
        <section>
          <H4>{commentTarget ? "New comment for" : "Comments for: "}</H4>
          <H5>{nodeTitleTextPlain(node)}</H5>
        </section>
        <Button
          icon={IconNames.CROSS}
          onClick={handleClose}
          minimal={true}
          disabled={savingComment}
        />
      </section>
    );
  };

  private renderButtons = (context: AlgoNode | Comment) => {
    const {
      node,
      commentKindFilter,
      currentUser,
      addCommentRequest,
      openAlgorithm,
      updateCommentRequest
    } = this.props;
    const {
      commentTarget,
      commentBeingEdited,
      commentType,
      savingComment,
      commentText
    } = this.state;

    if (!node) {
      return null;
    }

    const handleSaveComment = () => {
      if (commentText && commentType) {
        const comment = new Comment();
        comment.kind = commentType;
        comment.nodeId = node.id;
        comment.content = commentText;
        comment.author = currentUser;
        comment.algorithmId = openAlgorithm.algorithm.id;

        if (commentTarget && commentTarget instanceof Comment) {
          comment.linkedRefId = commentTarget.id;
          comment.linkedRefType = CommentRefType.Comment;
        } else {
          comment.linkedRefId = node.id;
          comment.linkedRefType = CommentRefType.Node;
        }

        updateInProgressComment({ details: openAlgorithm, commentText });
        addCommentRequest({
          algoId: openAlgorithm.algorithm.id,
          comment,
          nodeId: node.id
        });
        this.setState({ savingComment: true });
      }
    };

    const handleUpdateComment = () => {
      if (commentBeingEdited) {
        commentBeingEdited.content = commentText;
        updateCommentRequest({
          algoId: openAlgorithm.algorithm.id,
          comment: commentBeingEdited,
          nodeId: node.id
        });
        this.setState({ savingComment: true });
      }
    };

    const cancelNewComment = () => {
      this.setState({ commentTarget: undefined });
      updateInProgressComment({ details: openAlgorithm, commentText: "" });
    };

    const addNewComment = () => {
      this.setState({
        commentTarget: context,
        commentText: "",
        commentType: CommentKind.Comment
      });
    };

    const addNewNote = () => {
      this.setState({
        commentTarget: context,
        commentText: "",
        commentType: CommentKind.Note
      });
    };

    const commentButton = (!commentKindFilter ||
      commentKindFilter === CommentKind.Comment) && (
      <Button
        title={"New Comment"}
        text={"New Comment"}
        onClick={addNewComment}
      />
    );
    const noteButton = (!commentKindFilter ||
      commentKindFilter === CommentKind.Note) && (
      <Button title={"New Note"} text={"New Note"} onClick={addNewNote} />
    );

    return commentTarget ? (
      <ButtonGroup>
        <Button
          title="Save"
          text={`Save ${commentType === CommentKind.Note ? "Note" : "Comment"}`}
          onClick={commentBeingEdited ? handleUpdateComment : handleSaveComment}
          disabled={savingComment}
        />
        <Button
          title="Cancel"
          text="Cancel"
          onClick={cancelNewComment}
          disabled={savingComment}
        />
      </ButtonGroup>
    ) : (
      <ButtonGroup>
        {commentButton}
        {noteButton}
      </ButtonGroup>
    );
  };

  private renderComments = (
    comments: Comment[],
    commentTarget?: AlgoNode | Comment,
    currentUser?: User,
    indentLevel?: number
  ) => {
    const { commentKindFilter } = this.props;

    return comments.map(c => {
      const avatarUrl = c.author && c.author.avatar && c.author.avatar.thumb;
      const replies = c.comments;

      const addReply = () => {
        this.setState({
          commentBeingEdited: undefined,
          commentTarget: c,
          commentText: "",
          commentType: c.kind || commentKindFilter || CommentKind.Comment
        });
      };

      const handleEditComment = () => {
        this.setState({
          commentBeingEdited: c,
          commentTarget: commentTarget || this.props.node,
          commentText: c.content
        });
      };

      let indent = "";
      if (indentLevel) {
        switch (indentLevel) {
          case 1:
            indent = "pl3";
        }
      }
      // For a reply, only show the context
      const isReplying =
        this.state.commentTarget && this.state.commentTarget instanceof Comment;
      const thisIsParentComment = commentTarget && commentTarget.id === c.id;
      const parentComment = isReplying && !thisIsParentComment;

      if (parentComment) {
        return null;
      }

      const avatar = avatarUrl ? (
        <img alt="avatar" className="h2 br-100 pointer" src={avatarUrl} />
      ) : (
        <Icon
          className="h2 ma1"
          icon={IconNames.USER}
          iconSize={Icon.SIZE_LARGE}
        />
      );

      const authorText = (comment: Comment) => (
        <div className="flex">
          <H6>{fullName(comment.author)}</H6>
          <p>{` - ${moment(comment.createdAt).format("MMMM Do, YYYY")}`}</p>
        </div>
      );
      const editOrReplyButtons =
        currentUser && c.author && currentUser.id === c.author.id ? (
          <Button
            small={true}
            title="Edit"
            text="Edit"
            onClick={handleEditComment}
          />
        ) : (
          !indent &&
          !thisIsParentComment && (
            <Button
              small={true}
              title="Reply"
              text="Reply"
              onClick={addReply}
            />
          )
        );
      const html = DOMPurify.sanitize(marked(c.content));

      const replyRows = (rpls: Comment[]) =>
        this.renderComments(
          sortByCreatedDate(rpls.filter(r => r.kind === c.kind)) || [],
          c,
          currentUser,
          1
        );

      return (
        <section key={c.id} className={`flex flex-column pb2 ${indent}`}>
          <section className="flex items-start">
            {avatar}
            <section className="flex flex-column pa2 bp1 tl w-100">
              <section className="flex w-100 justify-between items-start">
                {c.author && authorText(c)}
                {editOrReplyButtons}
              </section>
              <p dangerouslySetInnerHTML={{ __html: html }} />
              {isReplying && this.renderTextInput()}
            </section>
          </section>
          {replies && replyRows(replies)}
        </section>
      );
    });
  };

  private renderTextInput = () => {
    const style: React.CSSProperties = {
      backgroundColor: "var(--edit-bg-grey)",
      borderRadius: "5px",
      color: "var(--charcoal-grey)",
      minHeight: 100,
      resize: "vertical"
    };
    const body = this.state.savingComment ? (
      <Spinner />
    ) : (
      <TextArea
        style={style}
        fill={true}
        large={true}
        intent={Intent.PRIMARY}
        onChange={this.handleCommentChange}
        value={this.state.commentText}
      />
    );
    return <section className="mb2 br3 bg-black-10">{body}</section>;
  };

  private handleCommentChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    this.setState({ commentText: e.target.value });
  };
}

const mapStateToProps = ({ algoStore, userStore }: IStoreState) => {
  return {
    currentUser: userStore.loggedInUser,
    error: algoStore.error
  };
};

export const NodeComments = connect(mapStateToProps, {
  addCommentRequest: addComment,
  updateCommentRequest: updateComment,
  updateInProgressComment
})(NodeCommentsComponent);
