import { IconNames } from "@blueprintjs/icons";
import Konva from "konva";

import {
  IAlgoNodeRenderProps,
  IRenderedNode,
  createAnchorPoint,
  kCornerRadius,
  kBackgroundShadow,
  kOrange,
  kPageWidth,
  dragFunc,
  kCharcoalGray,
  kPageNodeMinHeight,
  createAlgoNode,
  kPageNodeBottomMargin
} from ".";
import {
  shouldShowSelection,
  createTextArea,
  createBlueprintIcon,
  createAlgoLinkVisual
} from "./utilities";
import { AlgoNodeType } from "src/api";

export const createPageNode = (
  props: IAlgoNodeRenderProps
): IRenderedNode | undefined => {
  const { nodeId, algoNodes } = props;
  const allNodes = algoNodes();

  const node = allNodes[nodeId];
  if (!node) {
    return undefined;
  }

  const subOuts = {};
  const { x, y } = node;
  let height = 0;
  const fill = "white";
  const textColour = kCharcoalGray;
  const anchorYoffset = 30;

  const containerGroup: Konva.Group = new Konva.Group({
    dragBoundFunc: dragFunc(props, () => containerGroup),
    draggable: true,
    id: `${node.id}=group`,
    x,
    y
  });

  const showSelection = shouldShowSelection(props);

  // Text
  const titleText = createTextArea({
    fontSize: 14,
    fontStyle: "bold",
    id: `${node.id}=titleText`,
    text: node.cachedTitle.trim(),
    textColour,
    verticalAlign: "middle",
    width: kPageWidth - 85,
    x: 30,
    y: 4
  });

  const titleTextAreaHeight = titleText.getHeight();
  height += titleTextAreaHeight;
  containerGroup.add(titleText);
  const textYLoc = Math.max(height, anchorYoffset + 10);
  // Adjust Title to full height available.
  const diff = anchorYoffset + 20 - height;
  if (diff > 0) {
    titleText.height(titleTextAreaHeight + diff);
  }

  if (node.cachedRest.length > 0) {
    const textBox = createTextArea({
      id: `${node.id}=mainText`,
      text: node.cachedRest,
      textColour,
      width: kPageWidth - 10,
      x: 5,
      y: textYLoc
    });
    height = textYLoc + textBox.getHeight();
    containerGroup.add(textBox);
  }

  // Type icon
  containerGroup.add(
    createBlueprintIcon({
      colour: textColour,
      id: `${node.id}=typeIcon`,
      name: node.icon(),
      scale: 1.2,
      x: 15,
      y: anchorYoffset - 10
    })
  );

  // Icons - these are 16 wide at 1x
  if (node.hasComments()) {
    containerGroup.add(
      createBlueprintIcon({
        colour: textColour,
        id: `${node.id}=commentIcon`,
        name: IconNames.COMMENT,
        x: kPageWidth - 60,
        y: anchorYoffset - 10
      })
    );
  }
  if (node.hasNotes()) {
    containerGroup.add(
      createBlueprintIcon({
        colour: textColour,
        id: `${node.id}=notesIcon`,
        name: IconNames.ANNOTATION,
        x: kPageWidth - 35,
        y: anchorYoffset - 10
      })
    );
  }

  // Any contained nodes
  const containedNodes = node.contained(allNodes);

  containedNodes.forEach(nt => {
    if (nt.node) {
      height += nt.node.isDecision() ? 40 : 20;
      const objectProps: IAlgoNodeRenderProps = {
        ...props,
        nodeId: nt.node.id,
        x: 15,
        y: height
      };
      const konvaObject = createAlgoNode(objectProps, nt.path);
      if (konvaObject) {
        containerGroup.add(konvaObject.group);
        height += konvaObject.height;
      }
    }
  });

  height += kPageNodeBottomMargin;
  height = Math.max(height, kPageNodeMinHeight);
  // Background for the main node
  const bgRect = new Konva.Rect({
    cornerRadius: kCornerRadius,
    fill,
    height,
    id: `${node.id}=bg`,
    ...kBackgroundShadow,
    stroke: showSelection ? kOrange : undefined,
    strokeWidth: showSelection ? 4 : 1,
    width: kPageWidth
  });
  containerGroup.add(bgRect);
  bgRect.moveToBottom();
  containerGroup.offsetY(anchorYoffset);

  // Anchor points, on top of everything else.
  const apIn = createAnchorPoint({
    id: `${node.id}=in`,
    y: anchorYoffset
  });
  containerGroup.add(apIn);

  let apOut;
  if (node.kind !== AlgoNodeType.terminal) {
    apOut = createAnchorPoint({
      id: `${node.id}=out`,
      x: kPageWidth,
      y: anchorYoffset
    });
    containerGroup.add(apOut);
  } else {
    const c = new Konva.Circle({
      fill: "red",
      radius: 10,
      x: kPageWidth,
      y: anchorYoffset
    });
    containerGroup.add(c);
  }

  const target = node.targets(allNodes)[0];
  if (target && target.path.targetAlgorithmId) {
    createAlgoLinkVisual({
      container: containerGroup,
      height: anchorYoffset + 30,
      idIcon: `${node.id}=algo=${target.path.targetAlgorithmId}`,
      idLine: `${nodeId}=algoLink=${target.path.id}`,
      target,
      xOffset: kPageWidth
    });
  }

  return {
    anchorIn: apIn,
    anchorOut: apOut,
    group: containerGroup,
    height,
    links: {},
    subOuts
  };
};
