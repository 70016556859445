import {
  Button,
  Classes,
  Dialog,
  FormGroup,
  InputGroup,
  Intent,
  Spinner
} from "@blueprintjs/core";

import { push } from "connected-react-router";
import * as React from "react";
import { connect } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router-dom";

import { login, resetPassword, resetStore } from "src/actions";
import { User } from "src/api";
import { kRegisterUrlKey } from "src/config/routes";
import { IStoreState } from "src/store";
import { IAuthData, LoginForm } from "./user/login-form";

import busyDoctors from "src/resources/doctors-bg.jpg";
import logo from "src/resources/openxmed-logo-orange.svg";

interface ILoginDispatchProps {
  clearError: typeof resetStore;
  login: typeof login.request;
  push: typeof push;
  resetPassword: typeof resetPassword.request;
}

interface ILoginProps extends RouteComponentProps {
  error?: Error;
  resetSucceeded: boolean;
  user?: User;
}

type IFullLoginProps = ILoginDispatchProps & ILoginProps;

interface ILoginState {
  showForgotPassword: boolean;
  isBusy: boolean;
  resetSent: boolean;
  email: string;
  password: string;
}

class LoginPageComponent extends React.PureComponent<
  IFullLoginProps,
  ILoginState
> {
  constructor(props: IFullLoginProps) {
    super(props);
    this.state = {
      email: "",
      isBusy: false,
      password: "",
      resetSent: false,
      showForgotPassword: false
    };
  }

  public componentDidUpdate() {
    if (this.state.isBusy) {
      if (this.props.error) {
        this.setState({ isBusy: false });
      } else if (this.props.resetSucceeded) {
        this.setState({ isBusy: false });
      }
    }
  }

  public render() {
    const { email, isBusy, showForgotPassword } = this.state;
    const { error, resetSucceeded } = this.props;

    const errorMessage = showForgotPassword
      ? undefined
      : error && error.message;

    const body =
      isBusy && !showForgotPassword ? (
        <Spinner className="mt4" intent={Intent.PRIMARY} size={50} />
      ) : (
        <>
          <LoginForm
            errorMessage={errorMessage}
            onSubmit={this.login}
            handleForgotPassword={this.handleForgotPassword}
            initialValues={{ email }}
          />
          <p className="white pv2">
            Need an Openxmed account?{" "}
            <span
              className="pointer"
              role="button"
              tabIndex={0}
              style={{ color: "var(--app-orange)" }}
              onClick={this.register}
              onKeyPress={this.register}
            >
              Create an account
            </span>
          </p>
        </>
      );

    const dialogBody = isBusy ? (
      <Spinner intent={Intent.PRIMARY} size={50} />
    ) : (
      <FormGroup
        style={{ color: "var(--app-orange)" }}
        label="Email address:"
        labelFor="email-input"
      >
        <InputGroup
          id="email-input"
          name="email"
          placeholder={email}
          type="email"
          value={email}
          onChange={this.handleEmailUpdate}
        />
      </FormGroup>
    );

    const resetText =
      resetSucceeded && "Please check your email inbox to reset your password";

    const errorComponent = error && error.message.length > 0 && (
      <p className="mt2 orange">{error.message}</p>
    );

    return (
      <article className="flex vh-100 overflow-hidden">
        <section
          className="mw5 w-100-s w-40-ns vh-100 pa3"
          style={{ flexShrink: 0 }}
        >
          <img
            className="ma1 mb3"
            src={logo}
            alt="logo"
            style={{ height: 30 }}
          />
          {body}
        </section>
        <section className="w-auto-ns dn di-ns vh-100">
          <img
            alt=""
            style={{ objectFit: "cover", height: "100vh", width: "100vw" }}
            src={busyDoctors}
          />
        </section>
        <Dialog
          className={Classes.DARK}
          title={isBusy ? "Loading..." : "Request password reset"}
          isOpen={showForgotPassword}
          isCloseButtonShown={!isBusy}
          onClose={this.hideForgotPasswordDialog}
          canEscapeKeyClose={!isBusy}
          canOutsideClickClose={!isBusy}
        >
          <div className={Classes.DIALOG_BODY}>
            {dialogBody}
            {errorComponent}
          </div>
          <div className={Classes.DIALOG_FOOTER}>
            <Button
              className="mr2"
              disabled={isBusy}
              intent={Intent.PRIMARY}
              onClick={this.requestPasswordReset}
            >
              Request
            </Button>
            {resetText}
          </div>
        </Dialog>
      </article>
    );
  }

  private login = async (data: IAuthData) => {
    const { email, password } = data;

    this.props.clearError();
    this.setState({ isBusy: true, email });
    this.props.login({ email, password });
  };

  private handleForgotPassword = () => {
    this.setState({ showForgotPassword: true });
  };

  private hideForgotPasswordDialog = () => {
    this.setState({
      showForgotPassword: false
    });
  };

  private handleEmailUpdate = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ email: e.target.value });
  };

  private requestPasswordReset = async () => {
    const { email } = this.state;

    this.props.clearError();
    this.setState({ isBusy: true });
    this.props.resetPassword({ email });
  };

  private register = () => {
    this.props.push(kRegisterUrlKey);
  };
}

const mapStateToProps = ({ userStore }: IStoreState) => ({
  error: userStore.error,
  resetSucceeded: userStore.resetSucceeded,
  user: userStore.loggedInUser
});

export const LoginPage = withRouter(
  connect(mapStateToProps, {
    clearError: () => resetStore(),
    login: login.request,
    push,
    resetPassword: resetPassword.request
  })(LoginPageComponent)
);
