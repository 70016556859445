import {
  Button,
  Classes,
  Icon,
  Menu,
  MenuItem,
  Popover,
  Position
} from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import { push } from "connected-react-router";
import * as React from "react";
import { connect } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router-dom";

import {
  getAlgorithmSearchResults,
  logout,
  setLeftMenuState
} from "src/actions";
import { AlgorithmSearchResult, User, ZxLabelType } from "src/api";
import {
  ConnectedBreadcrumbs,
  CssSize,
  KeywordSuggest,
  PushEnablerButton
} from "src/components";

import {
  kLibraryRootUrl,
  kProfileUrlKey,
  kSearchUrlKey,
  kUserSearchUrlKey,
  kUsersRootUrl
} from "src/config/routes";
import { IStoreState } from "src/store";
import { LeftMenu } from ".";

export interface IPageHeaderProps extends RouteComponentProps {
  cssSize: CssSize;
  leftMenuOpen: boolean;
  logout: typeof logout;
  push: typeof push;
  searchAlgo: typeof getAlgorithmSearchResults.request;
  searchResults: AlgorithmSearchResult[];
  setLeftMenuState: typeof setLeftMenuState;
  user?: User;
}

interface IPageHeaderState {
  profileMenuOpen: boolean;
  breadcrumbWidth?: number;
}

class PageHeaderComponent extends React.Component<
  IPageHeaderProps,
  IPageHeaderState
> {
  constructor(props: IPageHeaderProps) {
    super(props);
    this.state = {
      profileMenuOpen: false
    };
  }

  public render() {
    const { cssSize, leftMenuOpen } = this.props;
    const navigateToLibraryRoot = () => {
      this.props.push(kLibraryRootUrl);
    };

    const searchMode = this.props.location.pathname.includes(kSearchUrlKey);
    const closeMenu = () => this.props.setLeftMenuState(false);
    const toggleMenuState = () => {
      this.props.setLeftMenuState(!leftMenuOpen);
    };
    const popoverMenu = cssSize === 0 && (
      <Popover
        className={`${Classes.DARK} pa2`}
        hasBackdrop={true}
        isOpen={leftMenuOpen}
        position={Position.TOP_RIGHT}
        canEscapeKeyClose={true}
        onClose={closeMenu}
      >
        <Button
          icon={<Icon icon={IconNames.MENU} color="black" />}
          active={leftMenuOpen}
          onClick={toggleMenuState}
          minimal={true}
        />
        <LeftMenu
          className={`${Classes.DARK} ${Classes.POPOVER_DISMISS}`}
          cssSize={cssSize}
          onClick={closeMenu}
          onMenuToggleButton={toggleMenuState}
          menuOpen={leftMenuOpen}
        />
      </Popover>
    );
    const icon = searchMode ? (
      <Icon
        className="pa2"
        icon={IconNames.SEARCH}
        iconSize={Icon.SIZE_LARGE}
      />
    ) : (
      <button
        className="mh2 material-icons pointer"
        onClick={navigateToLibraryRoot}
        style={{ border: "none" }}
      >
        home
      </button>
    );

    return (
      <section
        className="pr2 flex items-center zx-bg-header-grey"
        style={{ flexShrink: 0 }}
      >
        <section className="flex items-center flex-auto">
          {popoverMenu}
          {icon}
          <ConnectedBreadcrumbs />
        </section>
        <section className="flex items-center">
          {this.renderSearch()}
          <PushEnablerButton />
          {this.renderProfileOptions()}
        </section>
      </section>
    );
  }

  private renderSearch = () => {
    const currentLocation = this.props.location.pathname;
    const handleSearchTerm = (term?: string) => {
      if (term) {
        // const escapedTerm = term.replace(/[.*+?^${}()|[\]\\]/g, "\\$&"); // $& is the complete matched string
        const strippedTerm = term.replace(/\\/g, "");
        if (currentLocation.startsWith(kUsersRootUrl)) {
          this.props.push(`${kUserSearchUrlKey}/${strippedTerm}`);
        } else {
          this.props.push(`${kSearchUrlKey}/${strippedTerm}`);
        }
      }
    };

    return (
      <React.Fragment>
        <div className="dn db-ns">
          <KeywordSuggest
            className="ma2"
            leftIcon={IconNames.SEARCH}
            onChange={handleSearchTerm}
            type={ZxLabelType.KEYWORDS}
          />
        </div>
        <div className="dn-ns mh1">
          <Popover className="dn-ns" minimal={true}>
            <Button icon={IconNames.SEARCH} minimal={true} />
            <KeywordSuggest
              className="ma2 dn-ns"
              leftIcon={IconNames.SEARCH}
              onChange={handleSearchTerm}
              type={ZxLabelType.KEYWORDS}
            />
          </Popover>
        </div>
      </React.Fragment>
    );
  };

  private renderProfileOptions = () => {
    const { cssSize } = this.props;
    const { profileMenuOpen } = this.state;

    const handleOpen = () => {
      this.setState({ profileMenuOpen: true });
    };
    const handleClose = () => {
      this.setState({ profileMenuOpen: false });
    };
    const handleMenuClick = () => {
      this.setState({ profileMenuOpen: !this.state.profileMenuOpen });
    };

    const menuContent = (
      <Menu onClick={handleMenuClick} className="">
        {this.renderDefaultMenu()}
      </Menu>
    );
    return cssSize > 1 ? (
      this.profileAndLogoutButton()
    ) : (
      <Popover
        key="menu"
        onClosed={handleClose}
        onOpened={handleOpen}
        minimal={true}
        position={Position.BOTTOM_RIGHT}
        content={menuContent}
      >
        <Button active={profileMenuOpen} icon={IconNames.COG} minimal={true} />
      </Popover>
    );
  };

  private goToProfile = () => this.props.push(kProfileUrlKey);
  private openChat = () =>
    window.open("https://messaging.openxmed.com", "_blank");
  private openHelp = () =>
    window.open("https://www.openxmed.com/onboarding", "_blank");

  private profileAndLogoutButton = () => (
    <span key="signout">
      <Button
        style={{ color: "var(--charcoal-grey)" }}
        onClick={this.goToProfile}
        onKeyPress={this.goToProfile}
        minimal={true}
        text="Profile"
      />
      •
      <Button
        style={{ color: "var(--charcoal-grey)" }}
        onClick={this.openHelp}
        onKeyPress={this.openHelp}
        minimal={true}
        text="Help"
      />
      •
      <Button
        style={{ color: "var(--charcoal-grey)" }}
        onClick={this.openChat}
        onKeyPress={this.openChat}
        minimal={true}
        text="Chat"
      />
      •
      <Button
        style={{ color: "var(--charcoal-grey)" }}
        onClick={this.logout}
        onKeyPress={this.logout}
        minimal={true}
        text="Sign Out"
      />
    </span>
  );

  private renderDefaultMenu = () => {
    return [
      <MenuItem key="help" text="Help" onClick={this.openHelp} />,
      <MenuItem key="chat" text="Chat" onClick={this.openChat} />,
      <MenuItem key="profile" text="Profile..." onClick={this.goToProfile} />,
      <MenuItem key="logout" text="Sign out" onClick={this.logout} />
    ];
  };

  private logout = () => {
    this.props.logout();
  };
}

const mapStateToProps = ({ userStore, algoStore, uiStore }: IStoreState) => {
  return {
    leftMenuOpen: uiStore.leftMenuOpen,
    searchResults: algoStore.searchResults,
    user: userStore.loggedInUser
  };
};

export const PageHeader = withRouter(
  connect(mapStateToProps, {
    logout,
    push,
    searchAlgo: getAlgorithmSearchResults.request,
    setLeftMenuState
  })(PageHeaderComponent)
);
