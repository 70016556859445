import { Exclude, Expose, Transform, Type } from "class-transformer";
import { Dictionary } from "lodash";
import marked from "marked";
import { AlgoNodePath, Comment, CommentKind, notEmpty, PathType } from "..";

import { IconName } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import { PathNodeTypes } from "src/actions";
import { moveIndex, nodeTextPartsPlain } from "src/utilities";
import { BaseModel, kBaseModelOmissions } from "./object-base";
import { Media } from "./media";

export enum AlgoNodeType {
  entryPoint = "ENTRYPOINT",
  singleSelect = "SELECT",
  multiSelect = "MULTISELECT",
  choice = "CHOICE",
  intermediate = "INTERMEDIATE",
  image = "IMAGE",
  page = "PAGE",
  sharedText = "SHARED_TEXT",
  terminal = "TERMINUS",
  varDecision = "VARIABLE_DECISION",
  varInput = "VARIABLE_INPUT",
  varProcessor = "VARIABLE_PROCESSOR",
  unknown = ""
}

export interface INodeTypeLookup {
  type: AlgoNodeType;
  label: string;
}

export enum AlgoNodeInfoType {
  info,
  meds,
  defs,
  refs
}

export interface INodeInfoTypeLookup {
  type: AlgoNodeInfoType;
  label: string;
}

export const kConnectableNodeTypes = [
  AlgoNodeType.singleSelect,
  AlgoNodeType.multiSelect,
  AlgoNodeType.intermediate,
  AlgoNodeType.terminal,
  AlgoNodeType.varInput,
  AlgoNodeType.varDecision,
  AlgoNodeType.varProcessor,
  AlgoNodeType.page
];

export function textForNodeType(type: AlgoNodeType) {
  switch (type) {
    case AlgoNodeType.singleSelect:
      return "Single-select";
    case AlgoNodeType.multiSelect:
      return "Multi-select";
    case AlgoNodeType.intermediate:
      return "Intermediate";
    case AlgoNodeType.terminal:
      return "Terminal";
    case AlgoNodeType.choice:
      return "Option";
    case AlgoNodeType.varInput:
      return "Variable Input";
    case AlgoNodeType.varProcessor:
      return "Variable Processor";
    case AlgoNodeType.varDecision:
      return "Variable Decision";
    case AlgoNodeType.page:
      return "Page";
    case AlgoNodeType.image:
      return "Image";
    case AlgoNodeType.sharedText:
      return "Text";
  }
  return "";
}

export function visibleNodeTypes(): INodeTypeLookup[] {
  return kConnectableNodeTypes.map(t => ({
    label: textForNodeType(t),
    type: t
  }));
}

export function textForNodeInfoType(type: AlgoNodeInfoType) {
  switch (type) {
    case AlgoNodeInfoType.defs:
      return "Definitions";
    case AlgoNodeInfoType.info:
      return "General information";
    case AlgoNodeInfoType.meds:
      return "Medications";
    case AlgoNodeInfoType.refs:
      return "References";
  }
}

export function nodeInfoTypes(): INodeInfoTypeLookup[] {
  return [
    AlgoNodeInfoType.info,
    AlgoNodeInfoType.defs,
    AlgoNodeInfoType.refs,
    AlgoNodeInfoType.meds
  ].map(t => ({
    label: textForNodeInfoType(t),
    type: t
  }));
}

export function pathOrderSort(lhs: AlgoNodePath, rhs: AlgoNodePath): number {
  if (lhs.displayOrder === rhs.displayOrder) {
    return 0;
  }
  return lhs.displayOrder < rhs.displayOrder ? -1 : 1;
}

export interface IAlgoNodeTarget {
  node: AlgoNode | undefined;
  path: AlgoNodePath;
}

export const kAlgoNodeKeyOmissions = [
  ...kBaseModelOmissions,
  "comments",
  "paths",
  "cachedTitle",
  "cachedQuestion",
  "cachedRest"
];

export class AlgoNode extends BaseModel {
  @Transform(s => s || "")
  public algorithmId = "";
  @Transform(s => s || "")
  public title = "New Node";

  @Exclude()
  public cachedMainTitle = "";
  @Exclude()
  public cachedTitle = "";
  @Exclude()
  public cachedRest = "";

  public kind: AlgoNodeType = AlgoNodeType.unknown;

  @Expose({ name: "coordsX" })
  @Transform(value => Math.round(value), { toPlainOnly: true })
  public x = 0;
  @Expose({ name: "coordsY" })
  @Transform(value => Math.round(value), { toPlainOnly: true })
  public y = 0;

  public weight = 0;

  @Type(() => Comment)
  public comments: Comment[] = [];

  public info?: string;
  public references: string[] = [];
  public dosages?: string;
  public definitions?: string;

  @Type(() => Media)
  public image?: Media;

  @Exclude({ toPlainOnly: true })
  @Type(() => AlgoNodePath)
  public paths: AlgoNodePath[] = []; // Array of targets to link to - path objects

  constructor(
    kind: AlgoNodeType,
    algorithmId: string,
    parentType?: AlgoNodeType
  ) {
    super();
    this.kind = kind;
    this.algorithmId = algorithmId;

    switch (kind) {
      case AlgoNodeType.choice:
        if (parentType === AlgoNodeType.varInput) {
          this.title = "Select variable...";
        } else {
          this.title = "New Choice";
        }
        break;

      case AlgoNodeType.singleSelect:
        this.title = "New Single-Select\nQuestion goes here\nDetails go here";
        break;

      case AlgoNodeType.multiSelect:
        this.title = "New Multi-Select\nQuestion goes here\nDetails go here";
        break;

      case AlgoNodeType.intermediate:
        this.title = "New Intermediate";
        break;

      case AlgoNodeType.terminal:
        this.title = "New Terminal";
        break;

      case AlgoNodeType.varInput:
        this.title = "New Variable Input";
        break;

      case AlgoNodeType.varProcessor:
        this.title = "New Variable Processor";
        break;

      case AlgoNodeType.varDecision:
        this.title = "New Variable-based Decision";
        break;

      case AlgoNodeType.page:
        this.title = "Page Title\nNew Page\nOptional Text";
        break;

      case AlgoNodeType.image:
        this.title = "New Image";
        break;

      case AlgoNodeType.sharedText:
        this.title = "New Shared text here";
        break;
    }
  }

  //////////////////////////////////////////////////////////////////////////////
  // Nothing below here should be exposed in JSON
  //////////////////////////////////////////////////////////////////////////////
  // Useful data extraction funcs
  @Exclude()
  public hasInfo() {
    if (
      (this.info && this.info.length > 0) ||
      this.references.length > 0 ||
      this.dosages ||
      this.definitions
    ) {
      return true;
    }
    return false;
  }

  @Exclude()
  public hasNotes() {
    return this.comments.filter(c => c.kind === CommentKind.Note).length > 0;
  }

  @Exclude()
  public hasComments() {
    return this.comments.filter(c => c.kind === CommentKind.Comment).length > 0;
  }

  @Exclude()
  public options(allNodes: Dictionary<AlgoNode>): IAlgoNodeTarget[] {
    if (allNodes) {
      const orderedTargets = this.forwardLinks()
        .sort(pathOrderSort)
        .filter(path => {
          if (path.childId) {
            const childNode = allNodes[path.childId];
            if (childNode && childNode.kind === AlgoNodeType.choice) {
              return true;
            }
          }
          return false;
        });

      return orderedTargets.map(path => {
        return {
          node: allNodes[path.childId || ""],
          path
        };
      });
    }
    return [];
  }

  /**
   * Links an option to a node
   * @param nodeId The id of the choice node (already created)
   * @param order The visual order. Conflicts cause existing to move down
   */
  @Exclude()
  public addOption(
    node: AlgoNode,
    order: number | undefined,
    algoNodes: Dictionary<AlgoNode>
  ): AlgoNodePath {
    const sortedOptions = this.options(algoNodes);
    let nextOrder;
    if (order) {
      nextOrder = order;
      const pathsToMove = sortedOptions.filter(
        nodePath => nodePath.path.displayOrder >= order
      );
      pathsToMove.forEach(nodePath => (nodePath.path.displayOrder += 1));
    } else {
      const lastPath = sortedOptions.pop();
      nextOrder = lastPath ? lastPath.path.displayOrder + 1 : 1;
    }

    const newPath = new AlgoNodePath(this.id, node.id, nextOrder);
    this.paths.push(newPath);
    node.addPath(newPath);

    return newPath;
  }

  @Exclude()
  public targets(allNodes: Dictionary<AlgoNode>): IAlgoNodeTarget[] {
    const kind = this.kind;
    const orderedTargets = this.forwardLinks()
      .filter(l => l.pathType === PathType.link)
      .sort(pathOrderSort)
      .filter(p => {
        if ([AlgoNodeType.varProcessor].includes(kind)) {
          // Processor targets have no paramsJson
          if (p.paramsJson) {
            return false;
          }
        }
        return true;
      })
      .filter(path => {
        if (!path.childId) {
          // These are locally-unconnected paths
          return true;
        } else {
          const childNode = allNodes[path.childId];
          if (childNode) {
            if (childNode.kind !== AlgoNodeType.choice) {
              return true;
            }
          } else {
            if (kind !== AlgoNodeType.singleSelect || path.targetAlgorithmId) {
              return true;
            }
          }
        }
        return false;
      });

    return orderedTargets.map(path => {
      return {
        node: path.childId ? allNodes[path.childId] : undefined,
        path
      };
    });
  }

  @Exclude()
  public calcs(allNodes: Dictionary<AlgoNode>) {
    return this.forwardLinks()
      .filter(l => l.paramsJson)
      .sort(pathOrderSort)
      .map(p => ({
        node: p.childId ? allNodes[p.childId] : undefined,
        path: p
      }));
  }

  @Exclude()
  public contained(allNodes: Dictionary<AlgoNode>) {
    return this.forwardLinks()
      .filter(l => l.pathType === PathType.contained)
      .sort(pathOrderSort)
      .map(p => ({
        node: p.childId ? allNodes[p.childId] : undefined,
        path: p
      }));
  }

  @Exclude()
  public isContained() {
    return (
      this.backwardLinks().filter(l => l.pathType === PathType.contained)
        .length > 0
    );
  }

  @Exclude()
  public isContainable() {
    return [
      AlgoNodeType.varInput,
      AlgoNodeType.varProcessor,
      AlgoNodeType.image,
      AlgoNodeType.sharedText
    ].includes(this.kind);
  }

  @Exclude()
  public containerId() {
    const backLinks = this.backwardLinks();
    if (this.kind === AlgoNodeType.choice) {
      const parent = backLinks.map(nodePath => nodePath.parentId)[0];
      if (parent) {
        return parent;
      }
    }
    const container = backLinks.filter(
      l => l.pathType === PathType.contained
    )[0];
    if (container) {
      return container.parentId;
    }
    // Not contained, is its own container.
    return this.id;
  }

  @Exclude()
  public renderingContainerId(allNodes: Dictionary<AlgoNode>) {
    let parentId = this.containerId();
    let parentsParentId = parentId;
    do {
      parentId = parentsParentId;
      const parent = allNodes[parentId];
      if (parent) {
        parentsParentId = parent.containerId();
      }
    } while (parentsParentId !== parentId);

    return parentsParentId;
  }

  @Exclude()
  public forwardLinks(): AlgoNodePath[] {
    return this.paths.filter(path => path.parentId === this.id);
  }

  @Exclude()
  public forwardLinkNodeIds(): string[] {
    return this.forwardLinks()
      .sort(pathOrderSort)
      .map(link => link.childId)
      .filter(notEmpty);
  }

  @Exclude()
  public backwardLinks(): AlgoNodePath[] {
    return this.paths.filter(path => path.childId === this.id);
  }

  @Exclude()
  public backwardLinkNodeIds(): string[] {
    return this.backwardLinks()
      .sort(pathOrderSort)
      .map(link => link.parentId);
  }

  /**
   * This is used to create a child node and link it to a parent in the node
   * create call to the server. If we don't, we can't make options as the
   * server owns the IDs.
   */
  @Exclude()
  public path():
    | { parentId: string; refId: string; displayOrder: number }
    | undefined {
    const backwardLinks = this.backwardLinks();

    if (backwardLinks && backwardLinks.length === 1) {
      const parentLink = backwardLinks[0];
      return {
        displayOrder: parentLink.displayOrder,
        parentId: parentLink.parentId,
        refId: parentLink.id
      };
    }
    return undefined;
  }

  @Exclude()
  public addPath(path: AlgoNodePath) {
    let alreadyPresent = false;
    if (path && this.paths) {
      this.paths.forEach(existing => {
        if (path.id === existing.id) {
          alreadyPresent = true;
        }
      });
    }

    if (alreadyPresent === false) {
      this.paths.push(path);
    }
  }

  @Exclude()
  public removePath(pathId: string) {
    this.paths.forEach((path, index, paths) => {
      if (path.id === pathId) {
        paths.splice(index, 1);
      }
    });
  }

  @Exclude()
  public isDecision() {
    return [
      AlgoNodeType.page,
      AlgoNodeType.singleSelect,
      AlgoNodeType.multiSelect
    ].includes(this.kind);
  }

  @Exclude()
  public removePathToId(nodeId: string, forward: boolean): AlgoNodePath[] {
    let indexOfObject;
    const affectedPaths: AlgoNodePath[] = [];

    this.paths.forEach((link, i) => {
      const comparator = forward === true ? link.childId : link.parentId;
      if (comparator === nodeId) {
        indexOfObject = i;
      }
    });

    if (indexOfObject) {
      affectedPaths.push(...this.paths.splice(indexOfObject, 1));
    }
    return affectedPaths;
  }

  @Exclude()
  public unlink(allNodes: Dictionary<AlgoNode>): AlgoNode[] {
    const otherAffectedNodes: AlgoNode[] = [];

    this.forwardLinks().forEach(p => {
      if (p.childId) {
        const child = allNodes[p.childId];
        if (child) {
          child.removePathToId(this.id, false);
          otherAffectedNodes.push(child);
        }
      }
    });
    this.backwardLinks().forEach(p => {
      const parent = allNodes[p.parentId];
      if (parent) {
        parent.removePathToId(this.id, true);
        otherAffectedNodes.push(parent);
        parent.updateDisplayOrders(allNodes);
      }
    });
    this.updateDisplayOrders(allNodes);
    return otherAffectedNodes;
  }

  @Exclude()
  public reOrderPaths(
    oldIndex: number,
    newIndex: number,
    type: PathNodeTypes,
    algoNodes: Dictionary<AlgoNode>
  ) {
    let items;
    switch (type) {
      case "outputs":
        items = this.targets(algoNodes);
        break;
      case "calcs":
        items = this.calcs(algoNodes);
        break;
      case "contained":
        items = this.contained(algoNodes);
        break;
      case "choices":
        items = this.options(algoNodes);
        break;
      default:
      // none
    }

    if (items) {
      moveIndex(oldIndex, newIndex, items);
      items.forEach((t, i) => (t.path.displayOrder = i + 1));
      return items;
    }
    return items;
  }

  @Exclude()
  public updateDisplayOrders(algoNodes: Dictionary<AlgoNode>) {
    if (this.kind === AlgoNodeType.multiSelect) {
      // update targets
      this.targets(algoNodes).forEach((t, i) => {
        t.path.displayOrder = i;
      });
    }
    this.options(algoNodes).forEach((v, i) => {
      v.path.displayOrder = i;
    });
  }

  @Exclude()
  public asHtml(): string {
    return marked(this.title);
  }

  @Exclude()
  public infoAsHtml(): string {
    let htmlString = "";
    if (this.info && this.info.length > 0) {
      const info = "<h4>Info</h4>" + marked(this.info);
      htmlString += info;
    }
    if (this.references && this.references.length > 0) {
      const refs =
        "<br><h4>References</h4>" + marked(this.references.join(" "));
      htmlString += refs;
    }
    if (this.dosages && this.dosages.length > 0) {
      const dosages =
        "<br><h4>Medication Information</h4>" + marked(this.dosages);
      htmlString += dosages;
    }
    if (this.definitions && this.definitions.length > 0) {
      const defs = "<br><h4>Definitions</h4>" + marked(this.definitions);
      htmlString += defs;
    }

    return htmlString;
  }

  @Exclude()
  public icon(): IconName | undefined {
    switch (this.kind) {
      case AlgoNodeType.intermediate:
        return IconNames.GIT_COMMIT;
      case AlgoNodeType.multiSelect:
        return IconNames.TICK_CIRCLE;
      case AlgoNodeType.singleSelect:
        return IconNames.CIRCLE_ARROW_RIGHT;
      case AlgoNodeType.terminal:
        return IconNames.FLOW_END;
      case AlgoNodeType.varInput:
        return IconNames.MANUALLY_ENTERED_DATA;
      case AlgoNodeType.varProcessor:
        return IconNames.CALCULATOR;
      case AlgoNodeType.varDecision:
        return IconNames.GIT_BRANCH;
      case AlgoNodeType.page:
        return IconNames.DOCUMENT;
      case AlgoNodeType.image:
        return IconNames.MEDIA;
      case AlgoNodeType.sharedText:
        return IconNames.LABEL;
    }
    return undefined;
  }

  @Exclude()
  public nextDisplayOrder(algoNodes: Dictionary<AlgoNode>) {
    let lookupTargets;
    switch (this.kind) {
      case AlgoNodeType.varDecision:
      case AlgoNodeType.varProcessor:
        lookupTargets = this.calcs(algoNodes);
        break;

      case AlgoNodeType.singleSelect:
      case AlgoNodeType.varInput:
        lookupTargets = this.options(algoNodes);
        break;

      case AlgoNodeType.page:
      case AlgoNodeType.terminal:
        lookupTargets = this.contained(algoNodes);
        break;

      default:
        lookupTargets = this.targets(algoNodes);
        break;
    }

    if (lookupTargets.length > 0) {
      return lookupTargets[lookupTargets.length - 1].path.displayOrder + 1;
    }
    return 1;
  }

  @Exclude()
  public equals(other?: AlgoNode) {
    if (
      !other ||
      other.title !== this.title ||
      other.hasComments() !== this.hasComments() ||
      other.kind !== this.kind ||
      other.x !== this.x ||
      other.y !== this.y
    ) {
      return false;
    }
    return true;
  }

  @Exclude()
  public setCachedText() {
    const nodeTextParts = nodeTextPartsPlain(this);
    this.cachedTitle = nodeTextParts.title.trim();
    this.cachedMainTitle = nodeTextParts.mainTitle.trim();
    const theRestText = nodeTextParts.theRest.trim();

    if (this.isContained()) {
      // Limit to 3 lines only
      const lineLength = 45;
      let layoutLength = 0;
      const layoutCap = 3 * lineLength;
      this.cachedRest = theRestText
        .split("\n")
        .reduce((prev, current, index, array) => {
          if (layoutLength > layoutCap || index > 2) {
            return prev;
          }
          let value = prev + "\n" + current;

          const lengthForTruncation = Math.max(value.length + 1, lineLength);
          if (
            layoutLength + lengthForTruncation > layoutCap ||
            (index === 2 && array.length > 2)
          ) {
            // Truncate
            if (current.length < lineLength) {
              value = prev + "\n" + current + "...";
            } else {
              value =
                prev +
                "\n" +
                current.slice(0, layoutCap - layoutLength) +
                "...";
            }
          }
          layoutLength += lengthForTruncation;
          return value;
        }, "");
    } else {
      this.cachedRest = nodeTextParts.theRest;
    }
    return this;
  }
}
