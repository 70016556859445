import { Button, Label, MenuItem } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import { ItemRenderer, Select } from "@blueprintjs/select";
import * as React from "react";

import { UserRole, userRoleString } from "src/api";

interface IApprovedUserRoleSelectProps {
  className?: string;
  disabled: boolean;
  onChange: (value: UserRole) => void;
  value?: UserRole;
}

const RoleSelect = Select.ofType<UserRole>();

export class ApprovedUserRoleSelect extends React.PureComponent<
  IApprovedUserRoleSelectProps
> {
  public render() {
    const { disabled, value } = this.props;
    return (
      <div className={`${this.props.className} pb2`}>
        <Label style={{ marginBottom: 0 }}>
          <span className="ttc">User Role</span>
        </Label>
        <RoleSelect
          disabled={disabled}
          filterable={false}
          popoverProps={{ minimal: true }}
          items={[UserRole.Verified, UserRole.Author]}
          itemRenderer={this.itemRenderer}
          onItemSelect={this.selectionHandler}
          inputProps={{ disabled }}
        >
          <Button
            text={value ? userRoleString(value) : "Select..."}
            rightIcon={IconNames.CARET_DOWN}
          />
        </RoleSelect>
      </div>
    );
  }

  private itemRenderer: ItemRenderer<UserRole> = (value, { handleClick }) => {
    return (
      <MenuItem
        active={value === this.props.value}
        key={value}
        onClick={handleClick}
        text={userRoleString(value)}
      />
    );
  };

  private selectionHandler = (i: UserRole) => {
    this.props.onChange(i);
  };
}
