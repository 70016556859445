import { arraysEqual } from "@blueprintjs/core/lib/esm/common/utils";
import { Exclude, plainToClass, Type } from "class-transformer";
import { ClassType } from "class-transformer/ClassTransformer";
import { Dictionary } from "lodash";
import { v4 as uuidv4 } from "uuid";

import { notEmpty } from "..";
import { IXmedObjectInfo } from "../json-serialization";
import { sortAlphabetically } from "src/utilities";

export const kBaseModelOmissions = ["cachedAt", "createdAt", "updatedAt"];

export abstract class BaseModel {
  public id: string = uuidv4();
  public refId?: string;

  @Exclude({ toPlainOnly: true })
  @Type(() => Date)
  public createdAt: Date = new Date();

  @Exclude({ toPlainOnly: true })
  @Type(() => Date)
  public updatedAt: Date = new Date();

  public archived = false;
}

export const updateArray = <T extends { id: string }>(
  array: T[] = [],
  updated?: T
) => {
  if (updated) {
    const algoIndex = array.findIndex(value => value.id === updated.id);
    if (algoIndex > -1) {
      return array.map((item, index) => (index !== algoIndex ? item : updated));
    } else {
      const newArray = array.slice();
      newArray.push(updated);
      return newArray;
    }
  }
  return array;
};

export const removeFromArray = <T extends { id: string }>(
  array: T[],
  id: string
) => {
  return array.filter(a => a.id !== id);
};

export const dateSort = <T extends BaseModel>(
  a: T,
  b: T,
  ascending = false
) => {
  return ascending
    ? a.createdAt.getTime() - b.createdAt.getTime()
    : b.createdAt.getTime() - a.createdAt.getTime();
};

export const sortByCreatedDate = <T extends BaseModel>(
  array?: T[],
  ascending = false
) => {
  if (array) {
    return array.sort((a, b) => dateSort(a, b, ascending));
  }
  return undefined;
};

export const objectFromPayload = <O extends BaseModel>(
  { attributes, id }: IXmedObjectInfo,
  type: ClassType<O>
): O | undefined => {
  if (attributes) {
    const object = plainToClass<O, object>(type, { ...attributes, id });
    return object;
  }
  return undefined;
};

export const arrayFromPayload = <T extends BaseModel>(
  objectArray: IXmedObjectInfo[],
  type: ClassType<T>
): T[] => {
  return objectArray.map(a => objectFromPayload<T>(a, type)).filter(notEmpty);
};

export const dictionaryFromPayload = <T extends BaseModel>(
  objectArray: IXmedObjectInfo[],
  type: ClassType<T>
): Dictionary<T> => {
  const objects = {};
  objectArray.forEach(payload => {
    const obj = objectFromPayload<T>(payload, type);
    if (obj) {
      objects[obj.id] = obj;
    }
  });
  return objects;
};

export const arrayKeyIsEqual = <T extends BaseModel[]>(a: T, b: T) => {
  if (a.length !== b.length) {
    return false;
  }

  return a.reduce<boolean>(
    (prev, cur, index) => (prev ? cur.id === b[index].id : prev),
    true
  );
};

export const datesAreEqual = (a?: Date, b?: Date) => {
  if ((!a && b) || (a && !b)) {
    return false;
  }
  if (a && b && a.getTime() !== b.getTime()) {
    return false;
  }
  return true;
};

export const baseArraysAreEqual = <T extends BaseModel>(as: T[], bs: T[]) => {
  const sortedA = as.sort((a, b) => sortAlphabetically(a.id, b.id));
  const sortedB = bs.sort((a, b) => sortAlphabetically(a.id, b.id));
  return arraysEqual(sortedA, sortedB, (a, b) => a.id === b.id);
};
