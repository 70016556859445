import { Classes, H3, Intent, Spinner } from "@blueprintjs/core";
import * as Sentry from "@sentry/browser";
import * as React from "react";
import { connect } from "react-redux";
import {
  Route,
  RouteComponentProps,
  Switch,
  withRouter
} from "react-router-dom";

import "@blueprintjs/core/lib/css/blueprint.css";
import "@blueprintjs/datetime/lib/css/blueprint-datetime.css";
import "@blueprintjs/icons/lib/css/blueprint-icons.css";
import "animate.css/animate.css";
import "normalize.css/normalize.css";
import "tachyons/css/tachyons.css";
import "react-image-lightbox/style.css";

import "./App.css";

import {
  kCommunityRootUrl,
  kFirebaseHandlerUrlKey,
  kLibraryRootUrl,
  kModerationMenuUrlKey,
  kProfileUrlKey,
  kRegisterUrlKey,
  kSearchUrlKey,
  kStudiesUrlKey,
  kUserSearchUrlKey,
  kUsersRootUrl,
  kVariablesMenuUrlKey,
  kStudyUrlKey
} from "src/config/routes";
import {
  fullName,
  RolePriviledge,
  User,
  userIsAdmin,
  userPermitted
} from "./api";
import { configureBlueprint } from "./config/blueprintjs";

import {
  BrowserUnsupported,
  CssSize,
  NotificationToaster,
  MediaLightbox,
  ErrorPage
} from "src/components";
import {
  CommunityPage,
  FirebaseHandler,
  InsufficientAccess,
  LeftMenu,
  LibraryPage,
  LoginPage,
  PageHeader,
  ProfilePage,
  Register,
  SearchResultsPage
} from "./pages";

import { Redirect } from "react-router";
import { setCssSize, setLeftMenuState } from "./actions";
import {
  EditStudy,
  Moderations,
  StudyAdmin,
  UserAdministration,
  VariableAuthoringPage
} from "./pages/admin";

import { IStoreState } from "./store";
import { StudyDashboard } from "./pages/studies";

configureBlueprint();

type IAppProps = RouteComponentProps<{}>;

interface IAppInjectedProps {
  cssSize: CssSize;
  menuOpen: boolean;
  userCreds?: firebase.auth.UserCredential;
  user?: User;
}

interface IAppDispatchProps {
  setCssSize: typeof setCssSize;
  setLeftMenuState: typeof setLeftMenuState;
}

declare type AppProps = IAppProps & IAppInjectedProps & IAppDispatchProps;

interface IAppState {
  jsError?: { error: any; info: any };
}

class App extends React.Component<AppProps, IAppState> {
  constructor(props: AppProps) {
    super(props);
    this.state = {};
  }

  public componentDidUpdate(oldProps: AppProps) {
    const { cssSize, user } = this.props;

    Sentry.configureScope(scope => {
      if (user) {
        scope.setUser({
          email: user.email,
          id: user.id,
          regions: user.regions,
          role: user.role,
          username: fullName(user)
        });
      } else {
        scope.setUser({ username: "No User" });
      }
    });

    if (oldProps.cssSize !== cssSize) {
      this.props.setLeftMenuState(cssSize > 1 ? true : false);
    }
  }

  public componentDidMount() {
    window.addEventListener("resize", this.resizeHandler);
    this.resizeHandler();
  }

  public componentWillUnmount() {
    window.removeEventListener("resize", this.resizeHandler);
  }

  public componentDidCatch(error: any, info: any) {
    this.setState({ jsError: { error, info } });
  }

  public render() {
    const { user } = this.props;
    const { jsError } = this.state;

    const renderer = user ? (
      <React.Fragment>{this.renderWithUser(user)}</React.Fragment>
    ) : (
      <React.Fragment>{this.renderNoUser()}</React.Fragment>
    );

    if (jsError) {
      Sentry.captureException(jsError.error);
      return <ErrorPage error={jsError.error} info={jsError.info} />;
    }

    return this.browserSupported() ? (
      <Switch>
        <Route path={kFirebaseHandlerUrlKey} component={FirebaseHandler} />
        {renderer}
      </Switch>
    ) : (
      <BrowserUnsupported />
    );
  }

  private browserSupported = () => {
    try {
      if (!window.fetch) {
        return false;
      }
      new AbortController();
    } catch (e) {
      return false;
    }
    return true;
  };

  private renderNoUser = () => {
    return this.props.userCreds ? (
      this.busyLoading()
    ) : (
      <Switch>
        <Route exact={true} path={kRegisterUrlKey} component={Register} />
        <Route path="/" component={LoginPage} />
      </Switch>
    );
  };

  private renderWithUser = (user: User) => {
    const { cssSize, menuOpen } = this.props;
    const isAdmin = userIsAdmin(user);
    const roles = user.systemRoles || [];
    const toggleMenuOpenState = () => this.props.setLeftMenuState(!menuOpen);

    if (!userPermitted(user)) {
      return <InsufficientAccess />;
    } else {
      const LibraryPageWrapper = () => <LibraryPage cssSize={cssSize} />;

      const leftMenuPanel = (
        <LeftMenu
          cssSize={cssSize}
          menuOpen={menuOpen}
          onMenuToggleButton={toggleMenuOpenState}
        />
      );

      return (
        <article className="flex flex-row overflow-hidden vh-100">
          {cssSize > 0 && leftMenuPanel}
          <section className="bg-light-gray flex flex-column justify-between flex-auto">
            <section className=" flex flex-column flex-auto">
              <PageHeader cssSize={cssSize} />
              <NotificationToaster />
              <MediaLightbox />
              <section className="bg-light-gray flex flex-auto">
                <Switch>
                  <Route path={kLibraryRootUrl} render={LibraryPageWrapper} />
                  <Route
                    path={`${kCommunityRootUrl}/:userId?`}
                    component={CommunityPage}
                  />
                  <Route
                    path={`${kSearchUrlKey}/:query`}
                    component={SearchResultsPage}
                  />
                  <Route path={kProfileUrlKey} component={ProfilePage} />
                  <Route
                    path={`${kStudyUrlKey}/:studyId?`}
                    component={StudyDashboard}
                  />
                  {this.adminRoutes(isAdmin, roles)}
                  <Redirect to={kLibraryRootUrl} />
                </Switch>
              </section>
            </section>
          </section>
        </article>
      );
    }
  };

  private adminRoutes(isAdmin: boolean, roles: RolePriviledge[]) {
    return [
      (isAdmin || roles.includes(RolePriviledge.Moderate)) && (
        <Route key={1} path={kModerationMenuUrlKey} component={Moderations} />
      ),
      (isAdmin || roles.includes(RolePriviledge.AlterUser)) && (
        <Route key={2} path={kUsersRootUrl} component={UserAdministration} />
      ),
      (isAdmin || roles.includes(RolePriviledge.AlterUser)) && (
        <Route
          key={3}
          path={`${kUserSearchUrlKey}/:query`}
          component={UserAdministration}
        />
      ),
      isAdmin && (
        <Route
          key={4}
          path={`${kVariablesMenuUrlKey}/:tabId?`}
          component={VariableAuthoringPage}
        />
      ),
      (isAdmin || roles.includes(RolePriviledge.StudyAdmin)) && (
        <Route
          key={5}
          path={`${kStudiesUrlKey}/:studyId`}
          component={EditStudy}
        />
      ),
      (isAdmin || roles.includes(RolePriviledge.StudyAdmin)) && (
        <Route key={6} path={`${kStudiesUrlKey}`} component={StudyAdmin} />
      )
    ];
  }

  private busyLoading = () => {
    return (
      <article className="vh-100 dt w-100">
        <div className="dtc v-mid tc white ph3 ph4-l fadeOut">
          <H3 className={Classes.DARK}>Please wait...</H3>
          <Spinner
            className="tc"
            intent={Intent.PRIMARY}
            size={Spinner.SIZE_LARGE}
          />
        </div>
      </article>
    );
  };

  private resizeHandler = () => {
    const cssSize = this.calculateSize();
    if (cssSize !== this.props.cssSize) {
      this.props.setCssSize(cssSize);
    }
  };

  private calculateSize = () => {
    let cssSize: CssSize = 2;

    if (window.innerWidth > 959) {
      cssSize = 2;
    } else if (window.innerWidth > 600) {
      cssSize = 1;
    } else {
      cssSize = 0;
    }
    return cssSize;
  };
}

const mapStateToProps = (state: IStoreState) => ({
  cssSize: state.uiStore.cssSize,
  firebaseCreds: state.userStore.firebase.userCreds,
  menuOpen: state.uiStore.leftMenuOpen,
  user: state.userStore.loggedInUser
});

export default withRouter(
  connect(mapStateToProps, { setCssSize, setLeftMenuState })(App)
);
