import { Epic } from "redux-observable";
import { interval, of } from "rxjs";
import {
  debounce,
  filter,
  flatMap,
  ignoreElements,
  map,
  switchMap,
  take
} from "rxjs/operators";
import { ActionType, isActionOf } from "typesafe-actions";

import { Dictionary } from "lodash";
import {
  createBackendComment,
  createBackendNode,
  createBackendPath,
  deleteBackendComment,
  deleteBackendNode,
  deleteBackendPath,
  processQueue,
  updateBackendAlgorithm,
  updateBackendComment,
  updateBackendNode,
  updateBackendPath
} from "src/actions";

import {
  actionsForEventQueue,
  ElementState,
  IEventQueueElement
} from "src/reducers";
import { IOpenAlgorithm } from "src/store";
import { handleAsyncTaggedAction } from ".";

const queuesFromStore = (
  openAlgorithms: IOpenAlgorithm[]
): Dictionary<IEventQueueElement[]> => {
  const queues = {};
  if (openAlgorithms) {
    openAlgorithms.forEach(oa => {
      if (oa.eventQueue.length > 0) {
        queues[oa.algorithm.id] = oa.eventQueue;
      }
    });
  }
  return queues;
};

export const queueNextEventQueue: Epic = (action$, state$) =>
  state$.pipe(
    map(s => queuesFromStore(s.algoStore.openAlgorithms)),
    debounce(() => interval(1000)),
    map((algoQueue: Dictionary<IEventQueueElement[]>) => {
      const actions: ActionType<any> = [];
      Object.keys(algoQueue).forEach(algoId => {
        const queue = algoQueue[algoId];
        if (
          queue.length > 0 &&
          queue.filter(e => e.state === ElementState.running).length === 0 && // None running
          queue.filter(e => e.error).length === 0 // No errors
        ) {
          actions.push(processQueue(algoId));
        }
      });
      return actions;
    }),
    flatMap(ops => (ops.length > 0 ? ops : of(ignoreElements)))
  );

export const processQueueEvent: Epic = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(processQueue)),
    switchMap(a =>
      state$.pipe(
        map(s => queuesFromStore(s.algoStore.openAlgorithms)),
        take(1),
        filter<Dictionary<IEventQueueElement[]>>(Boolean),
        map((algoQueue: Dictionary<IEventQueueElement[]>) => {
          const queue = algoQueue[a.payload];
          if (queue) {
            const operations = actionsForEventQueue(queue);
            if (operations.length > 0) {
              return operations;
            }
          }
          return undefined;
        }),
        flatMap(ops => (ops ? of(...ops) : of(ignoreElements)))
      )
    )
  );

/**
 * Handle node creation on the backend
 */
export const createNewAlgoNode: Epic = (action$, state$) =>
  handleAsyncTaggedAction(action$, state$, createBackendNode);

export const updateAlgoNode: Epic = (action$, state$) =>
  handleAsyncTaggedAction(action$, state$, updateBackendNode);

export const deleteAlgoNode: Epic = (action$, state$) =>
  handleAsyncTaggedAction(action$, state$, deleteBackendNode);

export const createNewAlgoPath: Epic = (action$, state$) =>
  handleAsyncTaggedAction(action$, state$, createBackendPath);

export const updateAlgoPath: Epic = (action$, state$) =>
  handleAsyncTaggedAction(action$, state$, updateBackendPath);

export const deleteAlgoPath: Epic = (action$, state$) =>
  handleAsyncTaggedAction(action$, state$, deleteBackendPath);

export const updateAlgo: Epic = (action$, state$) =>
  handleAsyncTaggedAction(action$, state$, updateBackendAlgorithm);

export const createComment: Epic = (action$, state$) =>
  handleAsyncTaggedAction(action$, state$, createBackendComment);

export const updateComment: Epic = (action$, state$) =>
  handleAsyncTaggedAction(action$, state$, updateBackendComment);

export const deleteComment: Epic = (action$, state$) =>
  handleAsyncTaggedAction(action$, state$, deleteBackendComment);
