import { Button } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import * as React from "react";
import { SortEnd } from "react-sortable-hoc";

import {
  createNode,
  createPath,
  reorderPaths,
  setEditState
} from "src/actions";
import { AlgoNode, AlgoNodeType, IAlgoNodeTarget } from "src/api";
import { CollapsiblePanel } from "src/components/collapsible-panel";
import { IOpenAlgorithm } from "src/store";
import { SortableChoices } from "./choice-option";
import { SortableOutputs } from "./multichoice-output";
import { SortableCalculations } from "./node-var-calculation";

export interface INodeEditChoicesPanelProps {
  createNode: typeof createNode;
  createPath: typeof createPath;
  node?: AlgoNode;
  openAlgorithm: IOpenAlgorithm;
  reorderPaths: typeof reorderPaths;
  setEditState: typeof setEditState;
  updateNode: (node: AlgoNode) => void;
}

export class NodeEditChoicesPanel extends React.PureComponent<
  INodeEditChoicesPanelProps
> {
  public render() {
    const { node, openAlgorithm } = this.props;
    const {
      algoNodes,
      algorithm: { id, variables, localVars },
      editingState: { optionsExpanded, outputsExpanded, selectedPathId }
    } = openAlgorithm;

    if (!node) {
      return null;
    }

    const toggleChoices = (isExpanded: boolean) =>
      this.props.setEditState({
        algoId: id,
        state: { optionsExpanded: !isExpanded }
      });

    const toggleOutputs = (isExpanded: boolean) =>
      this.props.setEditState({
        algoId: id,
        state: { outputsExpanded: !isExpanded }
      });

    const items = node.options(openAlgorithm.algoNodes).map(choice => ({
      choice,
      openAlgorithm,
      parentNode: openAlgorithm.algoNodes[choice.path.parentId]
    }));

    const choices = (addVisible: boolean) => {
      const buttonStyle: React.CSSProperties = {
        visibility: addVisible ? "visible" : "hidden"
      };

      const sc = (
        <SortableChoices
          key={1}
          items={items}
          lockAxis="y"
          lockToContainerEdges={true}
          onSortEnd={this.handleChoiceSort}
          useDragHandle={true}
        />
      );
      const addButton = (
        <Button
          key={2}
          icon={IconNames.ADD}
          minimal={true}
          onClick={this.createNewChoice}
          style={buttonStyle}
          text={
            node.kind === AlgoNodeType.varInput ? "Add Variable" : "Add Option"
          }
        />
      );

      return [sc, addButton];
    };

    const showChoices = [
      AlgoNodeType.singleSelect,
      AlgoNodeType.varInput
    ].includes(node.kind);
    const showOutputs = [
      AlgoNodeType.varDecision,
      AlgoNodeType.multiSelect
    ].includes(node.kind);

    const options = node.options(algoNodes);
    const combinedVariables = localVars
      ? [...variables, ...Object.values(localVars)]
      : variables;

    const unusedVariableCount =
      combinedVariables.filter(v => !v.output).length - options.length;
    const showAdd =
      node.kind === AlgoNodeType.varInput ? unusedVariableCount > 0 : true;

    const targets = node
      .targets(openAlgorithm.algoNodes)
      .filter(nt => (selectedPathId ? nt.path.id === selectedPathId : true));

    const optionsPanel = (
      <CollapsiblePanel
        title="Options"
        isExpanded={optionsExpanded}
        onClick={toggleChoices}
      >
        {choices(showAdd)}
      </CollapsiblePanel>
    );

    const outputs = (
      <>
        <SortableOutputs
          items={targets.map(target => ({ target, openAlgorithm }))}
          lockAxis="y"
          lockToContainerEdges={true}
          onSortEnd={this.handleOutputSort}
          useDragHandle={true}
        />
        <Button
          icon={IconNames.ADD}
          minimal={true}
          onClick={this.createNewOutput}
          text="Add Output"
        />
      </>
    );

    const itemMapper = (target: IAlgoNodeTarget) => ({
      openAlgorithm,
      parentNode: node,
      path: target.path
    });

    const calcs = (
      <>
        <SortableCalculations
          items={targets.map(itemMapper)}
          lockAxis="y"
          lockToContainerEdges={true}
          onSortEnd={this.handleOutputSort}
          useDragHandle={true}
        />
        <Button
          icon={IconNames.ADD}
          minimal={true}
          onClick={this.createNewCalc}
          text="Add Output"
        />
      </>
    );

    const secondSection = (
      <CollapsiblePanel
        title="Outputs"
        isExpanded={outputsExpanded}
        onClick={toggleOutputs}
      >
        {node.kind === AlgoNodeType.multiSelect && outputs}
        {node.kind === AlgoNodeType.varDecision && calcs}
      </CollapsiblePanel>
    );
    return (
      <section key={node.id} className="w-100">
        {showChoices && choices(showAdd)}
        {node.kind === AlgoNodeType.multiSelect && optionsPanel}
        {showOutputs && secondSection}
      </section>
    );
  }

  private createNewCalc = () => {
    const {
      node,
      openAlgorithm: { algorithm }
    } = this.props;
    if (node) {
      this.props.createPath({
        algoId: algorithm.id,
        nodeId: node.id,
        varDetails: [{}]
      });
    }
  };

  private createNewChoice = () => {
    const {
      node,
      openAlgorithm: { algorithm }
    } = this.props;
    if (node) {
      const details = {
        algoId: algorithm.id,
        kind: AlgoNodeType.choice,
        parentId: node.id
      };
      this.props.createNode(details);
    }
  };

  private createNewOutput = () => {
    const {
      node,
      openAlgorithm: { algorithm }
    } = this.props;

    if (node) {
      this.props.createPath({
        algoId: algorithm.id,
        nodeId: node.id
      });
    }
  };

  private handleChoiceSort = (sort: SortEnd) => {
    const {
      openAlgorithm: {
        algorithm: { id }
      },
      node
    } = this.props;
    const { newIndex, oldIndex } = sort;

    if (node && newIndex !== oldIndex) {
      this.props.reorderPaths({
        algoId: id,
        newIndex,
        nodeId: node.id,
        oldIndex,
        type: "choices"
      });
    }
  };

  private handleOutputSort = (sort: SortEnd) => {
    const {
      openAlgorithm: {
        algorithm: { id }
      },
      node
    } = this.props;
    const { newIndex, oldIndex } = sort;

    if (node && newIndex !== oldIndex) {
      this.props.reorderPaths({
        algoId: id,
        newIndex,
        nodeId: node.id,
        oldIndex,
        type: "outputs"
      });
    }
  };
}
