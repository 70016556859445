import { Button, Classes, Dialog, Label, TagInput } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import { Dictionary, keyBy } from "lodash";
import * as React from "react";
import { connect } from "react-redux";
import { v4 as uuidv4 } from "uuid";

import { createParameter, updateParameter } from "src/actions";
import { Parameter, ParameterUnit } from "src/api";
import { ParameterTypeSelect, UnitSuggest } from "src/components";
import {
  IOpenAlgorithm,
  IStoreState,
  IVariableDefinition,
  ParameterType
} from "src/store";

interface INewVariableDialogProps {
  isOpen: boolean;
  onClose: (parameter?: Parameter) => void;
  onNewUnit?: (unit: ParameterUnit) => void;
  openAlgo?: IOpenAlgorithm; // Setting this allows local variables to be created / edited.
  paramToShow?: Parameter;
}

interface INewVariableDialogInjectedProps {
  error?: Error;
  params: Dictionary<Parameter>;
}

interface INewVariableDialogDispatchProps {
  createParameter: typeof createParameter.request;
  updateParameter: typeof updateParameter.request;
}

interface INewVariableDialogState {
  busy: boolean;
  groupName: string;
  mergedParams: Dictionary<Parameter>; // Contains all the params after combining with optional algo-local ones.
  parameter: Parameter;
  validationError?: string;
}

type NewVariableDialogComponentProps = INewVariableDialogProps &
  INewVariableDialogInjectedProps &
  INewVariableDialogDispatchProps;

class NewVariableDialogComponent extends React.PureComponent<
  NewVariableDialogComponentProps,
  INewVariableDialogState
> {
  constructor(props: NewVariableDialogComponentProps) {
    super(props);

    const { openAlgo, params, paramToShow } = props;
    const algoLocalVars = openAlgo
      ? keyBy(openAlgo.algorithm.localVars, "id")
      : undefined;
    this.state = {
      busy: false,
      groupName: (paramToShow && paramToShow.title) || "",
      mergedParams: { ...params, ...algoLocalVars },
      parameter: paramToShow || new Parameter()
    };
  }

  public componentDidUpdate() {
    const { busy, parameter } = this.state;
    const { error, params, paramToShow } = this.props;

    // TODO: update the merged parameters

    if (busy) {
      if (params[parameter.id]) {
        // Added parameter
        this.closeDialog();
      } else if (error) {
        this.setState({ busy: false });
      }
    } else if (paramToShow && paramToShow.id !== parameter.id) {
      this.setState({ parameter: paramToShow, groupName: paramToShow.title });
    }
  }

  public render() {
    const { isOpen, paramToShow } = this.props;
    const { parameter } = this.state;
    const inUse = (paramToShow && paramToShow.inUse) || false;

    return (
      <Dialog
        icon={IconNames.VARIABLE}
        style={{ maxHeight: "50vh", minWidth: "75vw", maxWidth: "95vw" }}
        title={parameter ? parameter.title : "New Variable"}
        isOpen={isOpen}
        onClose={this.closeDialog}
      >
        {this.renderBody(inUse)}
        {this.renderFooter(inUse)}
      </Dialog>
    );
  }

  private closeDialog = async (e?: React.SyntheticEvent<HTMLElement>) => {
    const { parameter } = this.state;
    this.props.onClose(
      e && e.currentTarget.id === "cancel"
        ? undefined
        : this.props.openAlgo
        ? { ...parameter, algoLocal: true }
        : parameter
    );

    this.setState({
      busy: false,
      groupName: "",
      parameter: new Parameter(),
      validationError: undefined
    });
  };

  private renderFooter = (disabled: boolean) => {
    const { error } = this.props;
    const { validationError } = this.state;

    const validatorErrorRenderer = (vE: string) => (
      <span className="mt2 orange ph2">{vE}</span>
    );
    const errorRenderer = (e: Error) => (
      <span className="mt2 orange ph2">{e.message}</span>
    );

    return (
      <div className={`${Classes.DIALOG_FOOTER} flex flex-row justify-end`}>
        {validationError && validatorErrorRenderer(validationError)}
        {error && errorRenderer(error)}
        {!disabled && <Button text="Save" onClick={this.validateAndSave} />}
        <Button
          id="cancel"
          text={disabled ? "Close" : "Cancel"}
          onClick={this.closeDialog}
        />
      </div>
    );
  };

  private renderBody = (disabled: boolean) => {
    const { groupName, parameter } = this.state;
    const { detailsJson: variableComponents } = parameter;

    const addNewComponent = () =>
      this.setState({
        parameter: {
          ...parameter,
          detailsJson: [...variableComponents, { name: "" }]
        }
      });

    const updateGroupName = (e: React.ChangeEvent<HTMLInputElement>) => {
      this.setState({
        groupName: e.currentTarget.value
      });
    };

    const groupNameRenderer = (
      gn: string,
      updateGn: (e: React.ChangeEvent<HTMLInputElement>) => void
    ) => {
      if (variableComponents.length > 1) {
        return (
          <section className="flex flex-row w-100">
            <Label className="pa1">
              Variable Group name
              <input
                disabled={disabled}
                className={`${Classes.INPUT}`}
                value={gn}
                onChange={updateGn}
              />
            </Label>
          </section>
        );
      }
      return null;
    };

    return (
      <section className={`${Classes.DIALOG_BODY} overflow-y-auto`}>
        Define variable information
        {groupNameRenderer(groupName, updateGroupName)}
        {variableComponents.map((vc, i) =>
          this.renderVariable(vc, i, disabled)
        )}
        <Button
          disabled={disabled}
          onClick={addNewComponent}
          text="New Component"
          small={true}
        />
      </section>
    );
  };

  private renderVariable = (
    varDef: IVariableDefinition,
    index: number,
    disabled: boolean
  ) => {
    const { parameter } = this.state;
    const { detailsJson: variableComponents } = parameter;

    const updateText = (e: React.ChangeEvent<HTMLInputElement>) =>
      this.setState({
        parameter: {
          ...parameter,
          detailsJson: variableComponents.map((item, i) =>
            index !== i ? item : { ...item, name: e.currentTarget.value }
          )
        }
      });

    const updateType = (type: ParameterType) =>
      this.setState({
        parameter: {
          ...parameter,
          detailsJson: variableComponents.map((item, i) =>
            index !== i ? item : { ...item, type }
          )
        }
      });

    const rowRenderer = (v: IVariableDefinition, idx: number) => {
      switch (varDef.type) {
        case "discrete":
          return this.listTypeRowRenderer(v, idx, disabled);
        case "numeric":
          return this.numericTypeRowRenderer(v, idx, disabled);
      }
      return null;
    };

    return (
      <section className="flex flex-row w-100" key={index}>
        <Label className="pa1">
          Variable Name
          <input
            disabled={disabled}
            className={`${Classes.INPUT}`}
            value={varDef.name}
            onChange={updateText}
          />
        </Label>
        <Label className="pa1">
          Type
          <ParameterTypeSelect
            disabled={disabled}
            value={varDef.type}
            onChange={updateType}
          />
        </Label>
        {rowRenderer(varDef, index)}
      </section>
    );
  };

  private listTypeRowRenderer = (
    varDef: IVariableDefinition,
    index: number,
    disabled: boolean
  ) => {
    const { parameter } = this.state;
    const { detailsJson: variableComponents } = parameter;

    const updateValues = (discreteValues: React.ReactNode[]) => {
      const updatedValues = discreteValues.map(v => {
        if (v && v.hasOwnProperty("props")) {
          const props = v["props"];
          return { id: props.id, value: props.children };
        }
        return { id: uuidv4(), value: v };
      });

      this.setState({
        parameter: {
          ...parameter,
          detailsJson: variableComponents.map((item, i) =>
            index !== i ? item : { ...item, discreteValues: updatedValues }
          )
        }
      });
    };

    const values = (varDef.discreteValues || []).map(v => {
      const key = v.id || uuidv4();
      return (
        <span id={key} key={key}>
          {v.id ? v.value : v}
        </span>
      );
    });

    return (
      <section className="pa1">
        Values (enter delimited)
        <TagInput
          disabled={disabled}
          className="ma1 w-100"
          onChange={updateValues}
          values={values}
        />
      </section>
    );
  };

  private numericTypeRowRenderer = (
    varDef: IVariableDefinition,
    index: number,
    disabled: boolean
  ) => {
    const { onNewUnit } = this.props;
    const { parameter } = this.state;
    const { detailsJson: variableComponents } = parameter;

    const updateRange = (e: React.ChangeEvent<HTMLInputElement>) =>
      this.setState({
        parameter: {
          ...parameter,
          detailsJson: variableComponents.map((item, i) =>
            index !== i ? item : { ...item, range: e.currentTarget.checked }
          )
        }
      });

    const updateMin = (e: React.ChangeEvent<HTMLInputElement>) =>
      this.setState({
        parameter: {
          ...parameter,
          detailsJson: variableComponents.map((item, i) =>
            index !== i
              ? item
              : { ...item, min: parseFloat(e.currentTarget.value) }
          )
        }
      });

    const updateMax = (e: React.ChangeEvent<HTMLInputElement>) =>
      this.setState({
        parameter: {
          ...parameter,
          detailsJson: variableComponents.map((item, i) =>
            index !== i
              ? item
              : { ...item, max: parseFloat(e.currentTarget.value) }
          )
        }
      });

    const updateInitial = (e: React.ChangeEvent<HTMLInputElement>) =>
      this.setState({
        parameter: {
          ...parameter,
          detailsJson: variableComponents.map((item, i) =>
            index !== i
              ? item
              : { ...item, initial: parseFloat(e.currentTarget.value) }
          )
        }
      });

    const updateUnits = (unit: ParameterUnit) =>
      this.setState({
        parameter: {
          ...parameter,
          detailsJson: variableComponents.map((item, i) =>
            index !== i ? item : { ...item, unit }
          )
        }
      });

    const handleNewUnit = (unit: ParameterUnit) => {
      updateUnits(unit);
      if (onNewUnit) {
        onNewUnit(unit);
      }
    };

    return (
      <section className="flex flex-row flex-auto">
        <Label className="pa1">
          Units
          <UnitSuggest
            disabled={disabled}
            onChange={updateUnits}
            value={varDef.unit}
            onCreate={onNewUnit ? handleNewUnit : undefined}
          />
        </Label>
        <Label className="pa1">
          Initial
          <input
            disabled={disabled}
            className={`${Classes.INPUT}`}
            onChange={updateInitial}
            type="number"
            value={varDef.initial === undefined ? "" : varDef.initial}
          />
        </Label>
        <div className="pa1 flex flex-row items-center">
          <span className="pa1">Range?</span>
          <input
            disabled={disabled}
            checked={varDef.range || false}
            className="ma1"
            onChange={updateRange}
            type="checkbox"
          />
        </div>
        <Label className="pa1">
          Min
          <input
            disabled={disabled}
            className={`${Classes.INPUT}`}
            onChange={updateMin}
            type="number"
            value={varDef.min === undefined ? "" : varDef.min}
          />
        </Label>
        <Label className="pa1">
          Max
          <input
            disabled={disabled}
            className={`${Classes.INPUT}`}
            onChange={updateMax}
            type="number"
            value={varDef.max === undefined ? "" : varDef.max}
          />
        </Label>
      </section>
    );
  };

  private validateAndSave = () => {
    const { groupName, parameter } = this.state;
    const { detailsJson } = parameter;
    const { openAlgo, params } = this.props;

    let validationError: string | undefined;
    if (detailsJson.length > 1) {
      if (groupName.length < 3) {
        validationError = "Group name must be longer than 3 letters";
      }
    }
    if (!validationError) {
      detailsJson.forEach(vc => {
        if (!validationError) {
          if (vc.name.length < 3) {
            validationError = "Variable names must be longer than 3 letters";
          } else if (!vc.type) {
            validationError = "Make sure all components have a type ";
          } else {
            switch (vc.type) {
              case "discrete":
                if (!vc.discreteValues || vc.discreteValues.length === 0) {
                  validationError = "Make sure all list types have values";
                }
                break;

              case "numeric":
                if (!vc.unit) {
                  validationError = "Make sure all numeric types have units";
                } else if (vc.range) {
                  if (vc.min === undefined) {
                    validationError =
                      "Make sure all ranges have min values set";
                  } else if (vc.max === undefined) {
                    validationError =
                      "Make sure all ranges have max values set";
                  }
                }
                break;
            }
          }
        }
      });
    }

    if (validationError) {
      this.setState({ validationError });
    } else {
      parameter.title =
        detailsJson.length > 1 ? groupName : detailsJson[0].name;

      if (openAlgo) {
        this.closeDialog();
      } else {
        // Store global variable
        if (this.props.paramToShow && params[parameter.id]) {
          this.props.updateParameter(parameter);
        } else {
          this.props.createParameter(parameter);
        }
        this.setState({ busy: true });
      }
    }
  };
}

const mapStateToProps = ({ parameterStore }: IStoreState) => {
  return {
    error: parameterStore.error,
    params: parameterStore.params
  };
};

export const EditVariableDialog = connect(mapStateToProps, {
  createParameter: createParameter.request,
  updateParameter: updateParameter.request
})(NewVariableDialogComponent);
