import DOMPurify from "dompurify";
import marked from "marked";
import * as React from "react";

import { AlgoNode } from "src/api";

export interface INodeInfoProps {
  width?: number;
  node: AlgoNode;
}

export class NodeInfo extends React.PureComponent<INodeInfoProps> {
  public render() {
    const { node, width } = this.props;
    if (!node.info) {
      return null;
    }

    const infoHtml = DOMPurify.sanitize(marked(node.info));
    const style: React.CSSProperties = {
      overflow: "auto",
      position: "relative"
    };
    if (width) {
      style.width = width;
    }

    const handleMouseDown = (e: React.MouseEvent) => {
      if (e.currentTarget.scrollLeft > 0) {
        e.stopPropagation();
      }
    };

    return (
      <p
        style={style}
        onMouseDown={handleMouseDown}
        dangerouslySetInnerHTML={{ __html: infoHtml }}
      />
    );
  }
}
