import { combineEpics } from "redux-observable";
import * as algoEpics from "./algo-handling";
import * as authEpics from "./auth-handling";
import * as queueEpics from "./event-queue-handling";
import * as navigationLoader from "./navigation-loader";
import * as storeEpics from "./store-cleanup";
import * as studyEpics from "./studies-handling";
import * as uiEpics from "./ui-handling";
import * as userEpics from "./user-handling";

export const rootEpic = combineEpics(
  authEpics.firebaseLogout,
  authEpics.loginAction,
  authEpics.resetPasswordRequest,
  authEpics.updateFirebaseToken,
  authEpics.signUpNewUser,
  authEpics.renewFirebaseToken,
  authEpics.registerForPushHandler,
  authEpics.handlePushRegistration,

  storeEpics.resetUserErrorOnRehydrate,

  algoEpics.handleCreateAlgo,
  algoEpics.handleAlgoRevisions,
  algoEpics.retrieveAlgoListFromApi,
  algoEpics.retrieveTopicListFromApi,
  algoEpics.retrieveAlgosByTopicFromApi,
  algoEpics.retrieveAlgorithm,
  algoEpics.retrieveCommentsForAlgorithm,
  algoEpics.searchAlgorithms,
  algoEpics.handleSaveAlgostate,
  algoEpics.retrieveAlgosByUserFromApi,
  algoEpics.syntaxCheckerTrigger,
  algoEpics.handleDeleteAlgo,
  algoEpics.handleUpdateAlgoStatus,
  algoEpics.loadRelatedAlgorithms,
  algoEpics.handleNodeImage,

  studyEpics.handleStudyRetrieve,
  studyEpics.handleParameterRetrieve,
  studyEpics.handleParameterCreate,
  studyEpics.handleParameterUpdate,
  studyEpics.handleParameterDelete,
  studyEpics.handleUnitRetrieve,
  studyEpics.handleUnitCreate,
  studyEpics.handleUnitUpdate,
  studyEpics.handleUnitDelete,
  studyEpics.handleStudyCreate,
  studyEpics.handleStudyUpdate,
  studyEpics.handleStudyDelete,
  studyEpics.handlePatientCreate,
  studyEpics.handlePatientRetrieveRequest,
  studyEpics.handlePatientRetrieve,
  studyEpics.handlePatientStudyAssign,
  studyEpics.handlePatientStudyRemove,
  studyEpics.handleNavigationForActiveStudy,
  studyEpics.handleStudyPatientSearch,
  studyEpics.handleVisitCreate,

  queueEpics.queueNextEventQueue,
  queueEpics.processQueueEvent,
  queueEpics.updateAlgo,
  queueEpics.createNewAlgoNode,
  queueEpics.updateAlgoNode,
  queueEpics.deleteAlgoNode,
  queueEpics.createNewAlgoPath,
  queueEpics.updateAlgoPath,
  queueEpics.deleteAlgoPath,
  queueEpics.createComment,
  queueEpics.updateComment,
  queueEpics.deleteComment,

  userEpics.executeGetSelf,

  userEpics.executeUpdateUser,
  userEpics.executeSetUserRole,
  userEpics.executeGetUsers,
  userEpics.executeGetUser,
  userEpics.executeRetrieveEvents,
  userEpics.executePostAvatar,
  userEpics.executeGetAlgoFaves,
  userEpics.executeSetAlgoFaves,

  uiEpics.redirectOnAlgoClose,
  uiEpics.updateBreadcrumbs,
  uiEpics.openOnAlgoCreate,
  uiEpics.loadLinkedAlgo,

  navigationLoader.errorRedirector,
  navigationLoader.navigationLoader
);
export default rootEpic;
