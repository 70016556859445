import * as React from "react";

import openXmedText from "src/resources/openxmed-white.svg";
import logo from "src/resources/logo.svg";

export const ErrorPage: React.SFC<{ error: Error; info: string }> = () => {
  return (
    <article className="flex flex-auto justify-center pt4">
      <div style={{ width: "300px" }}>
        <div className="flex flex-row pa1">
          <img className="" src={logo} alt="logo" />
          <img
            className="pb2"
            src={openXmedText}
            alt="openxmed"
            style={{ height: 70 }}
          />
        </div>
        <div className="white pa1 pt2">
          We’re sorry, but Openxmed has encountered an unexpected error. It
          doesn’t appear to have affected your data, but we have been notified
          and will be looking into this. Please reload and try again.
        </div>
      </div>
    </article>
  );
};
