import { arraysEqual } from "@blueprintjs/core/lib/esm/common/utils";
import { Exclude, Transform, Type } from "class-transformer";
import { Dictionary, keyBy, omit } from "lodash";

import { sortAlphabetically, compareArraysById } from "src/utilities";
import { Algorithm } from "./algorithm";
import { Label } from "./label";
import { BaseModel, datesAreEqual, baseArraysAreEqual } from "./object-base";
import { Parameter } from "./parameter";
import { RolePriviledge, User } from "./user";
import { Visit } from "./visit";
import { Patient } from "..";

export interface IStudyUsersRolesSplit {
  studyAdmins: User[];
  viewOnlyUsers: User[];
  dataEntryUsers: User[];
}

export enum StudyStatus {
  draft = "DRAFT",
  awaitingReview = "WAITING_FOR_REVIEW",
  approved = "APPROVED"
}

export const studyStatusString = (status: StudyStatus): string => {
  switch (status) {
    case StudyStatus.draft:
      return "Draft";
    case StudyStatus.awaitingReview:
      return "Awaiting Review";
    case StudyStatus.approved:
      return "Approved";
    default:
      return `${status}`;
  }
};

// Short version to return with user profiles - cached.
export interface IStudyInfo {
  id: string;
  title: string;
}

/// Because class-transformer is broken... https://github.com/typestack/routing-controllers/issues/200
export const stripFieldsForStudy = (study: Study) =>
  omit(
    study,
    "splitStudyUsersByRole",
    "setStudyUsers",
    "needsUpdate",
    "mergeStudyUserTypes",
    "changedPatients"
  );

export class Study extends BaseModel {
  @Type(() => User)
  public creator?: User;

  @Transform(s => s || "")
  public title = "";

  public status: StudyStatus = StudyStatus.draft;

  @Type(() => Visit)
  public visits?: Visit[];

  @Type(() => Algorithm)
  public algorithms: Algorithm[] = [];

  @Type(() => Label)
  public regions: Label[] = [];

  @Type(() => Date)
  public startDate?: Date;

  @Type(() => Date)
  public endDate?: Date;

  @Type(() => User)
  public users?: User[];

  @Type(() => Parameter)
  public variables?: Parameter[];

  @Type(() => Patient)
  public patients?: Patient[] = [];

  @Exclude()
  public splitStudyUsersByRole = () => {
    let viewOnlyUsers: User[] = [];
    let dataEntryUsers: User[] = [];
    let studyAdmins: User[] = [];

    if (this.users) {
      viewOnlyUsers = this.users.filter(
        u => u.studyRoles && u.studyRoles.includes(RolePriviledge.StudyView)
      );
      dataEntryUsers = this.users.filter(
        u =>
          u.studyRoles && u.studyRoles.includes(RolePriviledge.StudyDataEntry)
      );

      studyAdmins = this.users.filter(
        u => u.studyRoles && u.studyRoles.includes(RolePriviledge.StudyAdmin)
      );
    }

    return {
      dataEntryUsers,
      studyAdmins,
      viewOnlyUsers
    };
  };

  @Exclude()
  public mergeStudyUserTypes = ({
    viewOnlyUsers,
    dataEntryUsers,
    studyAdmins
  }: IStudyUsersRolesSplit) => {
    const userDict: Dictionary<User> = keyBy(
      viewOnlyUsers.map(u => ({
        ...u,
        studyRoles: [RolePriviledge.StudyView]
      })),
      "id"
    );

    dataEntryUsers.forEach(u => {
      const existing = userDict[u.id];
      if (existing) {
        if (existing.studyRoles) {
          existing.studyRoles = [
            ...existing.studyRoles,
            RolePriviledge.StudyDataEntry
          ];
        } else {
          existing.studyRoles = [RolePriviledge.StudyDataEntry];
        }
      } else {
        userDict[u.id] = {
          ...u,
          studyRoles: [RolePriviledge.StudyDataEntry]
        };
      }
    });

    studyAdmins.forEach(u => {
      const existing = userDict[u.id];
      if (existing) {
        if (existing.studyRoles) {
          existing.studyRoles = [
            ...existing.studyRoles,
            RolePriviledge.StudyAdmin
          ];
        } else {
          existing.studyRoles = [RolePriviledge.StudyAdmin];
        }
      } else {
        userDict[u.id] = {
          ...u,
          studyRoles: [RolePriviledge.StudyAdmin]
        };
      }
    });
    return Object.values(userDict);
  };

  @Exclude()
  public needsUpdate = (newData: Study) => {
    if (
      !datesAreEqual(newData.endDate, this.endDate) ||
      !datesAreEqual(newData.startDate, this.startDate) ||
      newData.title !== this.title ||
      !this.usersAndRolesAreEqual(newData.users, this.users) ||
      !baseArraysAreEqual(newData.algorithms, this.algorithms) ||
      !baseArraysAreEqual(newData.variables || [], this.variables || [])
    ) {
      return true;
    }
    return false;
  };

  @Exclude()
  public changedPatients = (newData: Study) => {
    return compareArraysById(this.patients || [], newData.patients || []);
  };

  @Exclude()
  public editableByUser = (user?: User) => {
    if (user) {
      if (this.creator && this.creator.id === user.id) {
        return true;
      }
      const userRoles = this.splitStudyUsersByRole();
      if (userRoles.studyAdmins.find(u => u.id === user.id)) {
        return true;
      }
    }
    return false;
  };

  private usersAndRolesAreEqual(as?: User[], bs?: User[]) {
    if (as === bs) {
      return true;
    }

    if (!as || !bs) {
      return false;
    }

    if (as.length !== bs.length) {
      return false;
    }

    const sortedA = as.sort((c, d) => sortAlphabetically(c.id, d.id));
    const sortedB = bs.sort((c, d) => sortAlphabetically(c.id, d.id));
    return sortedA.reduce<boolean>((prev, cur, index) => {
      if (!prev) {
        return prev;
      }
      const next = sortedB[index];
      if (cur.id === next.id) {
        if (cur.studyRoles && next.studyRoles) {
          const e = arraysEqual(cur.studyRoles, next.studyRoles);
          return e;
        }
      }
      return false;
    }, true);
  }
}
