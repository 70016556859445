import {
  Alert,
  Button,
  Classes,
  Icon,
  Intent,
  Menu,
  MenuDivider,
  MenuItem,
  Popover,
  PopoverInteractionKind,
  Position,
  Spinner,
  PopoverPosition
} from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import { clone, Dictionary } from "lodash";
import * as React from "react";
import { connect } from "react-redux";

import {
  clearQueueErrors,
  createNode,
  removeNodes,
  setEditState
} from "src/actions";
import {
  AlgoNodeType,
  Algorithm,
  CommentKind,
  notEmpty,
  User,
  AlgoNode
} from "src/api";
import {
  AlgoNotes,
  SharedTextSelector,
  AlgorithmVersionsMenu
} from "src/components";
import {
  AlgoPanelFocus,
  EditTabType,
  IOpenAlgorithm,
  IStoreState
} from "src/store";
import { AlgorithmApprovalControl, AlgorithmValidationReport } from ".";
import { AlgoErrorSeverity, IAlgoError } from "./validation";

export interface IEditToolbarProps {
  disabled: boolean;
  openAlgorithm: IOpenAlgorithm;
}

interface IEditToolbarInjectedProps {
  currentUser?: User;
}

interface IEditToolbarDispatchProps {
  clearQueueErrors: typeof clearQueueErrors;
  createNode: typeof createNode;
  removeNodes: typeof removeNodes;
  setEditState: typeof setEditState;
}

type EditToolbarProps = IEditToolbarProps &
  IEditToolbarInjectedProps &
  IEditToolbarDispatchProps;
type MenuItemType = { onClick: () => void; text: string };

interface IEditToolbarState {
  deleteWarnOpen: boolean;
  errorDetailsOpen: boolean;
  errorIndexFocus: number;
  imageSubmenuOpen: boolean;
  notesPopupOpen: boolean;
  queueErrorsOpen: boolean;
  versionMenuOpen: boolean;
}

class EditToolbarComponent extends React.PureComponent<
  EditToolbarProps,
  IEditToolbarState
> {
  constructor(props: EditToolbarProps) {
    super(props);
    this.state = {
      deleteWarnOpen: false,
      errorDetailsOpen: false,
      errorIndexFocus: 0,
      imageSubmenuOpen: false,
      notesPopupOpen: false,
      queueErrorsOpen: false,
      versionMenuOpen: false
    };
  }

  public render() {
    const { disabled, openAlgorithm } = this.props;
    const { notesPopupOpen, versionMenuOpen } = this.state;

    const {
      algoNodes,
      algorithm,
      eventQueue,
      editingState: {
        checkerOpen,
        algoInfoPanel,
        selectedNodeIds,
        zoom,
        validationErrors
      }
    } = openAlgorithm;

    const { localVars, variables } = algorithm;
    const combinedVariables = localVars
      ? [...variables, ...Object.values(localVars)]
      : variables;
    const disableAlgoButtons =
      algoInfoPanel === "info" ||
      algoInfoPanel === "refs" ||
      algoInfoPanel === "vars";

    const queueErrors = eventQueue
      .filter(e => e.error)
      .map(e => e.error)
      .filter(notEmpty);

    const syntaxIssues = validationErrors
      ? validationErrors.errors.concat(...validationErrors.warnings)
      : [];

    const deletableNodes = this.deletableNodes();
    const deletableCount = Object.keys(deletableNodes).length;

    const handleShowQueueErrors = () => {
      this.setState({ queueErrorsOpen: true });
    };

    const handleOpenInfo = () => {
      this.props.setEditState({
        algoId: algorithm.id,
        state: {
          algoInfoPanel: !(algoInfoPanel === "info" || algoInfoPanel === "refs")
            ? "info"
            : undefined
        }
      });
    };

    const toggleVars = () => {
      this.props.setEditState({
        algoId: algorithm.id,
        state: {
          algoInfoPanel: algoInfoPanel !== "vars" ? "vars" : undefined
        }
      });
    };

    const toggleVerifier = () => {
      this.props.setEditState({
        algoId: algorithm.id,
        state: {
          checkerOpen: !checkerOpen
        }
      });
    };
    const handleDelete = () => this.setState({ deleteWarnOpen: true });

    const commentsCount = algorithm.comments.filter(
      c => c.kind === CommentKind.Note
    ).length;
    const commentsCountString = `${commentsCount || ""}`;
    const togglePopover = () => {
      if (commentsCount > 0) {
        this.setState({ notesPopupOpen: !notesPopupOpen });
      }
    };
    const queueErrorDetails = queueErrors.length > 0 && (
      <span className="yellow pt2 mh1">{queueErrors[0].message}</span>
    );
    const showErrorsButton = queueErrors.length > 0 && (
      <Button
        className="mh1"
        icon={<Icon icon={IconNames.WARNING_SIGN} color="yellow" />}
        onClick={handleShowQueueErrors}
      />
    );
    const savingSpinner = queueErrors.length === 0 && eventQueue.length > 0 && (
      <section className="flex items-center ph2">
        <Spinner
          size={Spinner.SIZE_SMALL}
          value={1 / (eventQueue.length + 1)}
        />
        <span className="ph2">
          Saving ({Math.floor(100 / (eventQueue.length + 1))}%)
        </span>
      </section>
    );
    const errorColor =
      syntaxIssues.length === 0
        ? undefined
        : validationErrors && validationErrors.errors.length > 0
        ? "red"
        : "orange";
    const errorIcon = <Icon icon={IconNames.ENDORSED} color={errorColor} />;

    const firstSelection = Object.keys(selectedNodeIds).filter(
      k => selectedNodeIds[k] === 1
    )[0];

    let containerSelected = false;
    let enableSharedTextOptions = false;

    let primarySelection;

    if (firstSelection) {
      primarySelection = algoNodes[firstSelection];
      if (primarySelection) {
        if (
          [AlgoNodeType.page, AlgoNodeType.terminal].includes(
            primarySelection.kind
          )
        ) {
          containerSelected = true;
        }
        if (
          [AlgoNodeType.page, AlgoNodeType.terminal].includes(
            primarySelection.kind
          )
        ) {
          enableSharedTextOptions = true;
        }
      }
    }
    const dropdownList = this.renderNewNodeDropdown(
      disabled || disableAlgoButtons,
      combinedVariables.length > 0,
      containerSelected,
      enableSharedTextOptions,
      openAlgorithm,
      primarySelection
    );
    const closePopup = () => this.setState({ notesPopupOpen: false });

    const toggleVersionMenu = (nextState: boolean) =>
      this.setState({ versionMenuOpen: nextState });

    return (
      <section
        className={`${Classes.DARK} zx-bg-charcoal-grey br2 pv1 ph2 flex flex-auto flex-column zx-shadow`}
        style={{ flexGrow: 0, flexShrink: 0, zIndex: 50 }}
      >
        <section className="flex justify-between flex-auto items-center flex-wrap pb1">
          {this.renderDeleteNodeConfirmDialog(algorithm, deletableNodes)}
          {this.renderQueueConfirmDialog(queueErrors)}
          <section className="flex flex-auto pt1">
            <section className="pr2 flex">
              <Button
                active={algoInfoPanel === "info" || algoInfoPanel === "refs"}
                disabled={disabled}
                className="mh1"
                icon={IconNames.INFO_SIGN}
                onClick={handleOpenInfo}
              />
              <Popover
                hasBackdrop={true}
                onClose={closePopup}
                position={PopoverPosition.BOTTOM_RIGHT}
                isOpen={notesPopupOpen}
              >
                <Button
                  active={notesPopupOpen}
                  disabled={commentsCount === 0}
                  className="mh1"
                  icon={IconNames.ANNOTATION}
                  onClick={togglePopover}
                  rightIcon={commentsCount > 0 && IconNames.CARET_DOWN}
                  text={commentsCountString}
                />
                <AlgoNotes algo={algorithm} className="pa2" />
              </Popover>

              <Button
                active={algoInfoPanel === "vars"}
                className="mh1"
                icon={IconNames.VARIABLE}
                onClick={toggleVars}
              />
            </section>
            {dropdownList}
            {this.renderZoomButtons(zoom, disableAlgoButtons)}
            <Button
              className="mh1"
              disabled={deletableCount === 0 || disableAlgoButtons}
              icon={IconNames.TRASH}
              onClick={handleDelete}
            />
            {savingSpinner}
            {showErrorsButton}
            {queueErrorDetails}
          </section>
          <section className="flex pt1">
            <Popover
              disabled={disabled}
              popoverClassName="h6"
              hasBackdrop={true}
              isOpen={versionMenuOpen}
              interactionKind={PopoverInteractionKind.CLICK}
              minimal={true}
              onInteraction={toggleVersionMenu}
              position={PopoverPosition.BOTTOM_RIGHT}
            >
              <Button
                active={versionMenuOpen}
                className="mh2"
                disabled={disabled}
                icon={IconNames.BOX}
                rightIcon={IconNames.CHEVRON_DOWN}
              />
              <AlgorithmVersionsMenu
                algo={algorithm}
                visible={versionMenuOpen}
              />
            </Popover>
            <Button
              active={checkerOpen}
              className="mh1"
              icon={errorIcon}
              onClick={toggleVerifier}
            />
            <AlgorithmApprovalControl openAlgorithm={openAlgorithm} />
          </section>
        </section>
        {checkerOpen && this.renderSyntaxPanel(syntaxIssues)}
      </section>
    );
  }

  private closePopover = () => this.setState({ errorDetailsOpen: false });
  private handleSelectedError = (algoError: IAlgoError) => {
    const {
      openAlgorithm: { algorithm, algoNodes }
    } = this.props;
    const { objectId, objectType, pathId, targetPanel } = algoError;

    if (objectId) {
      if (objectType === "nodes") {
        const node = algoNodes[objectId];
        if (node) {
          const container = algoNodes[node.containerId()];
          this.props.setEditState({
            algoId: algorithm.id,
            state: {
              algoInfoPanel: undefined,
              panelFocus: (targetPanel as EditTabType) || "attrs",
              selectedPathId: pathId,
              selectedNodeIds: { [objectId]: pathId ? 2 : 1 },
              x: -container.x + 50,
              y: -container.y + 100,
              zoom: 1
            }
          });
        }
      } else if (objectType === "algorithms") {
        this.props.setEditState({
          algoId: algorithm.id,
          state: {
            algoInfoPanel: (targetPanel as AlgoPanelFocus) || "info"
          }
        });
      }
      this.closePopover();
    }
  };

  private renderSyntaxPanel = (issues: IAlgoError[]) => {
    const { errorDetailsOpen, errorIndexFocus } = this.state;
    const { disabled, openAlgorithm } = this.props;

    const togglePopover = (nextState: boolean) =>
      this.setState({ errorDetailsOpen: nextState });

    const focusedError = issues[errorIndexFocus];

    const goToError = () =>
      disabled ? undefined : this.handleSelectedError(focusedError);
    const nextError = () => {
      const nextErrorIndexFocus = Math.min(
        errorIndexFocus + 1,
        issues.length - 1
      );
      this.setState({ errorIndexFocus: nextErrorIndexFocus });
      this.handleSelectedError(issues[nextErrorIndexFocus]);
    };

    const previousError = () => {
      const prevErrorIndexFocus = Math.max(errorIndexFocus - 1, 0);
      this.setState({ errorIndexFocus: prevErrorIndexFocus });
      this.handleSelectedError(issues[prevErrorIndexFocus]);
    };

    const isError = focusedError
      ? focusedError.severity === AlgoErrorSeverity.Error
      : false;
    const issuesRendered = issues.length > 0 && (
      <div className="flex flex-row pt2">
        <span className="zx-charcoal-grey-2 mr2">
          {errorIndexFocus + 1} of {issues.length}
        </span>
        <Icon
          icon={isError ? IconNames.ERROR : IconNames.WARNING_SIGN}
          className={`mr2 ${isError ? "red" : "yellow"}`}
          iconSize={14}
        />
        <span className="pointer" onClick={goToError}>
          {focusedError.issueDetails}
        </span>
      </div>
    );

    return (
      <section className="pb1 flex flex-row items-start">
        <Button
          className="mh1"
          disabled={disabled || errorIndexFocus === 0}
          icon={IconNames.DOUBLE_CHEVRON_LEFT}
          onClick={previousError}
        />
        <Button
          className=""
          disabled={disabled || errorIndexFocus >= issues.length - 1}
          icon={IconNames.DOUBLE_CHEVRON_RIGHT}
          onClick={nextError}
        />
        <Popover
          disabled={disabled}
          popoverClassName="h6"
          hasBackdrop={true}
          position="top"
          isOpen={errorDetailsOpen}
          interactionKind={PopoverInteractionKind.CLICK}
          onInteraction={togglePopover}
        >
          <Button
            active={errorDetailsOpen}
            className="mh2"
            disabled={disabled}
            icon={IconNames.CARET_DOWN}
          />
          <AlgorithmValidationReport
            errorSelectHandler={this.handleSelectedError}
            handleClose={this.closePopover}
            openAlgorithm={openAlgorithm}
            setEditState={this.props.setEditState}
          />
        </Popover>
        {issuesRendered}
      </section>
    );
  };

  private renderNewNodeDropdown = (
    disabled: boolean,
    varsActive: boolean,
    containerSelected: boolean,
    enableSharedText: boolean,
    openAlgorithm: IOpenAlgorithm,
    primarySelection?: AlgoNode
  ) => {
    const { imageSubmenuOpen } = this.state;

    const clickMapper = (type?: AlgoNodeType) => () =>
      type ? this.createNewNode(type) : undefined;

    const menuItems: Array<MenuItemType | null | JSX.Element> = [
      {
        onClick: clickMapper(AlgoNodeType.page),
        text: "Page Container"
      },
      {
        onClick: clickMapper(AlgoNodeType.intermediate),
        text: "Intermediate Node"
      },
      {
        onClick: clickMapper(AlgoNodeType.terminal),
        text: "Terminal Node"
      }
    ];

    if (containerSelected) {
      menuItems.push(
        ...[
          null,
          {
            onClick: clickMapper(AlgoNodeType.image),
            text: "Image in Selected Node"
          }
        ]
      );
      if (enableSharedText) {
        const toggleOpen = () =>
          this.setState({ imageSubmenuOpen: !imageSubmenuOpen });
        const open = () =>
          !imageSubmenuOpen && this.setState({ imageSubmenuOpen: true });

        const sharedTextMenuOption = primarySelection && (
          <MenuItem
            key="newSharedText"
            text="Shared Text in Selected Node"
            onClick={toggleOpen}
            onPointerEnter={open}
            popoverProps={{ isOpen: imageSubmenuOpen }}
          >
            <SharedTextSelector
              openAlgorithm={openAlgorithm}
              node={primarySelection}
            />
          </MenuItem>
        );
        if (sharedTextMenuOption) {
          menuItems.push(sharedTextMenuOption);
        }
      }
    }

    if (varsActive) {
      const varOptions = [
        null,
        {
          onClick: clickMapper(AlgoNodeType.varInput),
          text: "Variable Input node"
        },
        {
          onClick: clickMapper(AlgoNodeType.varProcessor),
          text: "Variable Processing node"
        },
        {
          onClick: clickMapper(AlgoNodeType.varDecision),
          text: "Variable Decision node"
        }
      ];
      menuItems.push(...varOptions);
    }

    menuItems.push(
      ...[
        null,
        {
          onClick: clickMapper(AlgoNodeType.singleSelect),
          text: "Single-Choice Question"
        },
        {
          onClick: clickMapper(AlgoNodeType.multiSelect),
          text: "Multi-Choice Question"
        }
      ]
    );

    const closeSubmenus = () =>
      imageSubmenuOpen && this.setState({ imageSubmenuOpen: false });
    const itemRenderer = (
      menuItem: MenuItemType | null | JSX.Element,
      i: number
    ) => {
      if (menuItem === null) {
        return <MenuDivider key={i} />;
      } else if ((menuItem as JSX.Element).props) {
        return menuItem;
      } else {
        const details = menuItem as MenuItemType;
        return (
          <MenuItem
            key={i}
            onClick={details.onClick}
            text={details.text}
            onPointerEnter={closeSubmenus}
          />
        );
      }
    };

    const menu = <Menu>{menuItems.map(itemRenderer)}</Menu>;
    const resetSubmenu = () => this.setState({ imageSubmenuOpen: false });

    return (
      <section className="ph2 ">
        <Popover
          disabled={disabled}
          minimal={true}
          position={Position.TOP_LEFT}
          content={menu}
          onClose={resetSubmenu}
        >
          <Button
            disabled={disabled}
            text="New"
            rightIcon={IconNames.CARET_DOWN}
          />
        </Popover>
      </section>
    );
  };

  private renderZoomButtons = (zoom: number, disabled: boolean) => {
    const zoomIn = () => this.setZoom(Math.max(Math.min(zoom * 1.2, 1.2), 0.1));
    const zoomOut = () =>
      this.setZoom(Math.max(Math.min(zoom / 1.2, 1.2), 0.1));
    const sizeToFit = () => this.setZoom(-1);

    return (
      <section className="flex ph2">
        <Button
          disabled={disabled}
          className="mh1"
          icon={IconNames.ZOOM_IN}
          onClick={zoomIn}
        />
        <Button
          disabled={disabled}
          className="mh1"
          icon={IconNames.ZOOM_OUT}
          onClick={zoomOut}
        />
        <Button
          disabled={disabled}
          className="mh1"
          icon={IconNames.LOCATE}
          onClick={sizeToFit}
        />
      </section>
    );
  };

  private renderDeleteNodeConfirmDialog = (
    algorithm: Algorithm,
    deletableNodes: Dictionary<number>
  ) => {
    const { deleteWarnOpen } = this.state;
    const deletableCount = Object.keys(deletableNodes).length;

    const handleCancelDelete = () => this.setState({ deleteWarnOpen: false });
    const handleConfirmDelete = () => {
      this.props.removeNodes({
        algoId: algorithm.id,
        nodeIds: deletableNodes
      });
      this.setState({ deleteWarnOpen: false });
    };

    return (
      <Alert
        canEscapeKeyCancel={true}
        canOutsideClickCancel={true}
        cancelButtonText="Cancel"
        onCancel={handleCancelDelete}
        confirmButtonText="Delete"
        onConfirm={handleConfirmDelete}
        isOpen={deleteWarnOpen}
        icon={IconNames.TRASH}
        intent={Intent.DANGER}
      >
        <p>
          This action will permanently remove
          {deletableCount === 1 ? " 1 node" : ` ${deletableCount} nodes`} from
          the algorithm. Are you sure?
        </p>
      </Alert>
    );
  };

  private renderQueueConfirmDialog = (queueErrors: Error[]) => {
    const { queueErrorsOpen } = this.state;

    const handleClose = () => this.setState({ queueErrorsOpen: false });
    const handleConfirm = () =>
      this.props.clearQueueErrors(this.props.openAlgorithm.algorithm.id);

    const queueErrorMapper = (e: Error, i: number) => (
      <li key={i}>{e && `${e.name}: ${e.message}`}</li>
    );

    return (
      <Alert
        canEscapeKeyCancel={true}
        canOutsideClickCancel={true}
        confirmButtonText="Reset algorithm"
        onClose={handleClose}
        onConfirm={handleConfirm}
        isOpen={queueErrorsOpen}
        icon={IconNames.WARNING_SIGN}
        intent={Intent.WARNING}
      >
        <p>
          There has been an error communicating with the server. Further
          operations will not be possible at this time. The errors are:
        </p>
        <ul>{queueErrors.map(queueErrorMapper)}</ul>
      </Alert>
    );
  };

  private deletableNodes = () => {
    const {
      openAlgorithm: {
        algoNodes,
        editingState: { selectedNodeIds }
      }
    } = this.props;
    const filteredSelection = clone(selectedNodeIds);
    const selectedNodes = Object.keys(selectedNodeIds)
      .map(id => algoNodes[id])
      .filter(notEmpty);

    // Remove choice nodes
    selectedNodes
      .filter(n => [AlgoNodeType.choice].includes(n.kind))
      .forEach(c => delete filteredSelection[c.containerId()]);

    // Remove any container nodes from the selection too,
    selectedNodes
      .filter(n => n.isContained())
      .forEach(n => {
        if (n.kind === AlgoNodeType.sharedText) {
          delete filteredSelection[n.id];
        }
        // Remove any backlinked object from the selection
        n.backwardLinkNodeIds().forEach(id => delete filteredSelection[id]);
      });

    return filteredSelection;
  };

  private setZoom(zoom: number) {
    this.props.setEditState({
      algoId: this.props.openAlgorithm.algorithm.id,
      state: {
        zoom
      }
    });
  }

  private createNewNode = (type: AlgoNodeType) => {
    const {
      openAlgorithm: {
        algoNodes,
        editingState: { selectedNodeIds }
      }
    } = this.props;
    const nodeIds = Object.keys(algoNodes);
    const firstSelection = Object.keys(selectedNodeIds).filter(
      k => selectedNodeIds[k] === 1
    )[0];

    let parentId;
    let contained = false;

    if (
      nodeIds.length === 1 &&
      algoNodes[nodeIds[0]].kind === AlgoNodeType.entryPoint
    ) {
      // Link first node to the start point
      parentId = nodeIds[0];
    } else if (firstSelection) {
      const selectedNode = algoNodes[firstSelection];
      if (selectedNode) {
        if (
          [AlgoNodeType.page, AlgoNodeType.terminal].includes(selectedNode.kind)
        ) {
          parentId = firstSelection;
          contained = true;
        }
      }
    }

    this.props.createNode({
      algoId: this.props.openAlgorithm.algorithm.id,
      kind: type,
      parentId,
      contained
    });
  };
}

const mapStateToProps = ({ userStore }: IStoreState) => {
  return {
    currentUser: userStore.loggedInUser
  };
};

export const EditToolbar = connect(mapStateToProps, {
  setEditState,
  createNode,
  removeNodes,
  clearQueueErrors
})(EditToolbarComponent);
