import { MenuItem, MenuDivider, Menu, InputGroup } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import * as React from "react";
import { connect } from "react-redux";

import { AlgoNode, Algorithm, AlgoNodeType, PathType } from "src/api";
import { nodeTitleTextPlain, sortAlphabetically } from "src/utilities";
import { IOpenAlgorithm } from "src/store";
import { createNode, createPath } from "src/actions";

interface ISharedTextSelectorProps {
  className?: string;
  node: AlgoNode;
  onChange?: (value: AlgoNode, created: boolean) => void;
  openAlgorithm: IOpenAlgorithm;
}

interface ISharedTextSelectorDispatchProps {
  createNode: typeof createNode;
  createPath: typeof createPath;
}

type SharedTextSelectorProps = ISharedTextSelectorProps &
  ISharedTextSelectorDispatchProps;
interface ISharedTextSelectorState {
  items: AlgoNode[];
  query?: string;
}

class SharedTextSelectorComponent extends React.PureComponent<
  SharedTextSelectorProps,
  ISharedTextSelectorState
> {
  constructor(props: SharedTextSelectorProps) {
    super(props);

    this.state = {
      items: []
    };
  }

  public componentDidMount() {
    const {
      openAlgorithm: { algorithm },
      node
    } = this.props;
    this.searchExisting("", algorithm, node);
  }

  public componentDidUpdate(prevProps: ISharedTextSelectorProps) {
    const {
      openAlgorithm: { algorithm },
      node
    } = this.props;
    if (prevProps.node.id !== node.id) {
      this.searchExisting("", algorithm, node);
    }
  }

  public render() {
    const { className, node, openAlgorithm } = this.props;
    const { algorithm } = openAlgorithm;

    const { items, query } = this.state;
    const handleChange = (e: React.FormEvent<HTMLInputElement>) => {
      const newQuery = e.currentTarget.value;
      this.setState({ query: newQuery });
      this.searchExisting(newQuery, algorithm, node);
    };

    const handleCreate = () => {
      const newNode = new AlgoNode(AlgoNodeType.sharedText, algorithm.id);
      this.selectionHandler(newNode, true);
    };

    const createButton = (
      <MenuItem
        disabled={!query || query.length === 0}
        key="create"
        onClick={handleCreate}
        text="New Shared Text"
      />
    );
    const filteredItems = query && query.length > 0 ? items : items.slice(0, 5);

    return (
      <div className={`${className} pa1`}>
        <div className={`${className} zx-bg-charcoal-grey br2`}>
          <InputGroup
            className="ma1 mh2 br2"
            leftIcon={IconNames.SEARCH}
            onChange={handleChange}
          />
          <Menu>
            {filteredItems.map(this.itemRenderer)}
            {items.length > 0 && <MenuDivider />}
            {createButton}
          </Menu>
        </div>
      </div>
    );
  }

  private itemRenderer = (value: AlgoNode) => {
    const handleClick = () => this.selectionHandler(value);

    return (
      <MenuItem
        key={value.id}
        onClick={handleClick}
        text={`${nodeTitleTextPlain(value)}`}
      />
    );
  };

  private selectionHandler = (n: AlgoNode, created = false) => {
    const { node, onChange, openAlgorithm } = this.props;
    const {
      algorithm: { id }
    } = openAlgorithm;

    if (onChange) {
      onChange(n, created);
    }
    if (created) {
      this.props.createNode({
        algoId: id,
        contained: true,
        displayOrder: node.nextDisplayOrder(openAlgorithm.algoNodes),
        kind: AlgoNodeType.sharedText,
        parentId: node.id
      });
    } else {
      this.props.createPath({
        algoId: openAlgorithm.algorithm.id,
        childId: n.id,
        linkType: PathType.contained,
        nodeId: node.id
      });
    }
  };

  private searchExisting = (
    query: string,
    algorithm: Algorithm,
    node: AlgoNode
  ) => {
    const matching = algorithm.nodes
      .filter(n => n.kind === AlgoNodeType.sharedText)
      .filter(n =>
        query.length === 0
          ? true
          : n.title.toLocaleLowerCase().includes(query.toLocaleLowerCase())
      );

    this.setState({
      items: matching.sort((a, b) =>
        sortAlphabetically(
          a.title.toLocaleLowerCase(),
          b.title.toLocaleLowerCase()
        )
      )
    });
  };
}

export const SharedTextSelector = connect(undefined, {
  createNode,
  createPath
})(SharedTextSelectorComponent);
