import { Icon } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import * as React from "react";
import { SortableHandle } from "react-sortable-hoc";

export * from "./algorithm-edit";
export * from "./algorithm-validation-report";
export * from "./edit-toolbar";
export * from "./node-edit-attrs-panel";
export * from "./node-edit-choices-panel";
export * from "./node-edit-info-panel";
export * from "./node-edit-rules-panel";
export * from "./node-edit-notes-panel";
export * from "./node-edit-calculations-panel";
export * from "./node-edit-contained-panel";
export * from "./choice-option";
export * from "./page-contained-node";
export * from "./algo-info-editor";
export * from "./algo-edit-form";
export * from "./algo-vars-editor";
export * from "./node-var-calculation";
export * from "./algorithm-approval-control";
export * from "./shared-node-edit-dialog";

export const SortHandle = SortableHandle(() => (
  <Icon icon={IconNames.DRAG_HANDLE_VERTICAL} />
));
