import { Button, MenuItem } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import { ItemRenderer, Select } from "@blueprintjs/select";
import * as React from "react";

import { arrayKeyIsEqual, Parameter, ParameterWithIndex } from "src/api";

interface IParameterTypeSelectProps {
  className?: string;
  disabled?: boolean;
  hideGroups?: boolean;
  onChange?: (value: Parameter, valueIndex: number | undefined) => void;
  items: Parameter[];
  value?: ParameterWithIndex;
}

const TypeSelect = Select.ofType<ParameterWithIndex>();

interface IParameterTypeSelectState {
  listItems: ParameterWithIndex[];
}

const groupedParameters = (params: Parameter[], showGroups = true) => {
  const items: ParameterWithIndex[] = [];
  params.forEach(p => {
    if (p.detailsJson) {
      if (p.detailsJson.length === 1) {
        items.push({ ...p, valueIndex: 0 });
      } else {
        if (showGroups) {
          items.push({ ...p, valueIndex: undefined });
        }
        p.detailsJson.forEach((_, i) => {
          items.push({ ...p, valueIndex: i });
        });
      }
    }
  });
  return items;
};

export class ParameterSelect extends React.PureComponent<
  IParameterTypeSelectProps,
  IParameterTypeSelectState
> {
  constructor(props: IParameterTypeSelectProps) {
    super(props);
    this.state = {
      listItems: groupedParameters(props.items, !props.hideGroups)
    };
  }

  public componentDidUpdate(oldProps: IParameterTypeSelectProps) {
    const { items } = this.props;
    if (!arrayKeyIsEqual(items, oldProps.items)) {
      this.setState({
        listItems: groupedParameters(items, !this.props.hideGroups)
      });
    }
  }

  public render() {
    const { disabled, value } = this.props;
    const { listItems } = this.state;

    return (
      <div className={`${this.props.className}`}>
        <TypeSelect
          className="flex flex-row flex-auto"
          disabled={disabled}
          filterable={false}
          popoverProps={{ minimal: true }}
          items={listItems}
          itemRenderer={this.itemRenderer}
          onItemSelect={this.selectionHandler}
          inputProps={{ disabled }}
        >
          <Button
            fill={true}
            alignText={"left"}
            text={value ? this.titleText(value) : "Select..."}
            rightIcon={IconNames.CARET_DOWN}
          />
        </TypeSelect>
      </div>
    );
  }

  private titleText = (value: ParameterWithIndex) => {
    const { detailsJson } = value;

    return detailsJson.length > 1 && value.valueIndex !== undefined
      ? `${value.title} - ${detailsJson[value.valueIndex].name}`
      : value.title;
  };

  private itemRenderer: ItemRenderer<ParameterWithIndex> = (
    value,
    { handleClick, modifiers: { disabled } }
  ) => {
    const text = this.titleText(value);

    return (
      <MenuItem
        disabled={disabled}
        key={`${value.id}-${value.valueIndex}`}
        onClick={handleClick}
        text={text}
      />
    );
  };

  private selectionHandler = (value: ParameterWithIndex) => {
    const { onChange } = this.props;
    if (onChange) {
      onChange(value, value.valueIndex);
    }
  };
}
