import { push } from "connected-react-router";
import { Epic } from "redux-observable";
import {
  filter,
  map,
  flatMap,
  debounce,
  switchMap,
  ignoreElements,
  take
} from "rxjs/operators";
import { of, interval } from "rxjs";
import { isActionOf } from "typesafe-actions";

import {
  createParameter,
  createParameterUnit,
  createStudy,
  deleteParameter,
  deleteParameterUnit,
  deleteStudy,
  retrieveParameters,
  retrieveStudies,
  retrieveUnits,
  updateParameter,
  updateParameterUnit,
  updateStudy,
  createPatient,
  assignPatientToStudy,
  removePatientFromStudy,
  setUserActiveStudy,
  searchStudyPatients,
  createStudyVisit,
  getPatient,
  retrievePatient
} from "src/actions";
import { handleAsyncAction, handleAsyncTaggedAction } from ".";
import { kLibraryRootUrl, kStudyUrlKey } from "src/config/routes";
import { IStoreState } from "src/store";

export const handleParameterRetrieve: Epic = (action$, state$) =>
  handleAsyncAction(action$, state$, retrieveParameters);
export const handleParameterCreate: Epic = (action$, state$) =>
  handleAsyncAction(action$, state$, createParameter);
export const handleParameterUpdate: Epic = (action$, state$) =>
  handleAsyncAction(action$, state$, updateParameter);
export const handleParameterDelete: Epic = (action$, state$) =>
  handleAsyncTaggedAction(action$, state$, deleteParameter);

export const handleUnitRetrieve: Epic = (action$, state$) =>
  handleAsyncAction(action$, state$, retrieveUnits);
export const handleUnitCreate: Epic = (action$, state$) =>
  handleAsyncAction(action$, state$, createParameterUnit);
export const handleUnitUpdate: Epic = (action$, state$) =>
  handleAsyncAction(action$, state$, updateParameterUnit);
export const handleUnitDelete: Epic = (action$, state$) =>
  handleAsyncTaggedAction(action$, state$, deleteParameterUnit);

export const handleStudyRetrieve: Epic = (action$, state$) =>
  handleAsyncAction(action$, state$, retrieveStudies);

export const handleStudyCreate: Epic = (action$, state$) =>
  handleAsyncAction(action$, state$, createStudy);

export const handleStudyUpdate: Epic = (action$, state$) =>
  handleAsyncAction(action$, state$, updateStudy);

export const handleStudyDelete: Epic = (action$, state$) =>
  handleAsyncAction(action$, state$, deleteStudy);

export const handlePatientCreate: Epic = (action$, state$) =>
  handleAsyncAction(action$, state$, createPatient);

export const handlePatientRetrieveRequest: Epic = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(retrievePatient)),
    debounce(() => interval(1000)),
    switchMap(_ =>
      state$.pipe(
        map((s: IStoreState) => Object.keys(s.studiesStore.requestedPatients)),
        take(1),
        map(reqIds => reqIds.map(id => getPatient.request(id))),
        flatMap(ops => {
          return ops ? of(...ops) : of(ignoreElements);
        })
      )
    )
  );

export const handlePatientRetrieve: Epic = (action$, state$) =>
  handleAsyncAction(action$, state$, getPatient);

export const handlePatientStudyAssign: Epic = (action$, state$) =>
  handleAsyncAction(action$, state$, assignPatientToStudy);

export const handlePatientStudyRemove: Epic = (action$, state$) =>
  handleAsyncAction(action$, state$, removePatientFromStudy);

export const handleNavigationForActiveStudy: Epic = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(setUserActiveStudy)),
    map(a =>
      of(push(a.payload ? `${kStudyUrlKey}/${a.payload}` : kLibraryRootUrl))
    ),
    flatMap(op => op)
  );

export const handleStudyPatientSearch: Epic = (action$, state$) =>
  handleAsyncAction(action$, state$, searchStudyPatients);

export const handleVisitCreate: Epic = (action$, state$) =>
  handleAsyncAction(action$, state$, createStudyVisit);
