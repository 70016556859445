import { Exclude, Type } from "class-transformer";
import { omit } from "lodash";
import { Comment, Label, Media, IStudyInfo } from "..";
import { Device } from "./device";
import { BaseModel } from "./object-base";

export enum UserRole {
  Disallowed = "DISALLOWED",
  Basic = "BASIC",
  Verified = "PARTICIPANT",
  StudyOnly = "STUDY_ONLY",
  Author = "AUTHOR",
  Editor = "EDITOR",
  Administrator = "ADMIN",
  Unknown = "Unknown"
}

export enum RolePriviledge {
  AlterUser = 1,
  Moderate = 2,
  StudyAdmin = 3,
  StudyDataEntry = 4,
  StudyView = 5
}

export enum NotificationSettings {
  AlgoEditDaily = 1,
  AlgoEditWeekly = 2,
  CommentsImmediate = 3,
  CommentsDaily = 4,
  CommentsWeekly = 5,
  AlgoEditorialImmediate = 6,
  AlgoEditorialDaily = 7,
  AlgoEditorialWeekly = 8
}

export const kSelectablePrivileges = [
  RolePriviledge.AlterUser,
  RolePriviledge.Moderate,
  RolePriviledge.StudyAdmin
];

export function systemPrivilegeString(priv: RolePriviledge) {
  switch (priv) {
    case RolePriviledge.AlterUser:
      return "Approve / Block Users";
    case RolePriviledge.Moderate:
      return "Comment Moderator";
    case RolePriviledge.StudyAdmin:
      return "Administer Studies";
    default:
      return "Unknown Privilege";
  }
}

export const kSelectableUserRoles = [
  UserRole.Disallowed,
  UserRole.Verified,
  UserRole.Author,
  UserRole.StudyOnly,
  UserRole.Administrator
];

export enum FriendAction {
  Invited = "INVITED",
  Connected = "CONNECTED"
}

export type ZxUserType = "authors" | "editors" | "all";

export const lastNameSort = (a: User, b: User, ascending = true) => {
  if (a.lastName.trim().toLowerCase() > b.lastName.trim().toLowerCase()) {
    return ascending ? 1 : -1;
  }

  if (a.lastName.trim().toLowerCase() < b.lastName.trim().toLowerCase()) {
    return ascending ? -1 : 1;
  }
  return 0;
};

export function userRoleString(role: UserRole): string {
  switch (role) {
    case UserRole.Administrator:
      return "Administrator";
    case UserRole.Editor:
      return "Editor";
    case UserRole.Author:
      return "Algorithm Author";
    case UserRole.Verified:
      return "Algorithm Consumer";
    case UserRole.StudyOnly:
      return "Study User Only";
    case UserRole.Basic:
      return "Unverified User";
    case UserRole.Disallowed:
      return "Blocked";

    default:
      return role.toString();
  }
}

export function sortUsersByLastName(users: User[]) {
  return users.sort(lastNameSort);
}

export class User extends BaseModel {
  public email = "";

  public firstName = "";
  public middleName = "";
  public lastName = "";
  public avatar?: Media;

  public role: UserRole = UserRole.Disallowed;
  public roleReason?: string;
  public systemRoles?: RolePriviledge[];
  public notificationSettings?: NotificationSettings[];

  public username = "";
  public designation?: string;
  public title = "";
  public city = "";
  public region = "";
  public country = "";
  public phone = "";

  public biography = "";
  public interests = "";

  @Type(() => Date)
  public dob?: Date;

  @Type(() => Label)
  public medicalSpecialties: Label[] = [];

  @Type(() => Label)
  public medicalSchools: Label[] = [];

  @Type(() => Label)
  public regions: Label[] = [];
  public regionsRestricted = false;

  @Exclude({ toPlainOnly: true }) public commentsCount = 0;
  @Exclude({ toPlainOnly: true }) public algorithmsCount = 0;
  @Exclude({ toPlainOnly: true }) public friendsCount = 0;

  // ID / title of studies to which this user is included
  @Exclude({ toPlainOnly: true }) public activeStudyInfo?: IStudyInfo[];

  @Exclude({ toPlainOnly: true })
  @Type(() => Comment)
  public comments?: Comment[]; // Populated as needed

  @Exclude({ toPlainOnly: true })
  @Type(() => User)
  public friends?: User[];

  public status?: FriendAction;
  public invitedId?: string; // UUID of who was invited (one person must be invited, other invitee)

  @Exclude({ toPlainOnly: true })
  @Type(() => Device)
  public devices: Device[] = [];

  public studyRoles?: RolePriviledge[];
}
/// Because class-transformer is broken... https://github.com/typestack/routing-controllers/issues/200
export const stripFieldsForUser = (user: User) =>
  omit(
    user,
    "friends",
    "status",
    "invitedId",
    "devices",
    "comments",
    "commentsCount",
    "algorithmsCount",
    "friendsCount",
    "activeStudyInfo"
  );

export const userIsContributor = (user: User | undefined) => {
  if (
    user &&
    user.archived === false &&
    (user.role === UserRole.Administrator ||
      user.role === UserRole.Author ||
      user.role === UserRole.Editor)
  ) {
    return true;
  }
  return false;
};

export const userPermitted = (user: User): boolean => {
  if (userIsContributor(user) || user.role === UserRole.Verified) {
    return true;
  }
  return false;
};

export const userIsAdmin = (user?: User): boolean => {
  return (user && user.role === UserRole.Administrator) || false;
};

export const fullName = (user?: { firstName: string; lastName: string }) =>
  user ? `${user.firstName} ${user.lastName}` : "";

export const shortName = (user?: { firstName: string; lastName: string }) =>
  user ? `${user.firstName[0]}. ${user.lastName}` : "";

export const userLocationString = (user: User) => {
  const val = user.city;

  return val;
};

export const userMatchFilter = (u: User, query: string) => {
  const words = query.split(" ");
  let i = 0;
  while (i < words.length) {
    const searcher = new RegExp(words[i], "i");
    if (u.firstName.search(searcher) > -1 || u.lastName.search(searcher) > -1) {
      return true;
    }
    i++;
  }
  return false;
};

export const occupationSort = (a: User, b: User, ascending = true) => {
  const occA = a.title || "";
  const occB = b.title || "";
  if (occA > occB) {
    return ascending ? 1 : -1;
  }

  if (occA < occB) {
    return ascending ? -1 : 1;
  }
  return 0;
};
