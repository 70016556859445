import DOMPurify from "dompurify";
import marked from "marked";
import * as React from "react";

import { AlgoNode } from "src/api";

export interface INodeRefsProps {
  node: AlgoNode;
  width?: number;
}

export class NodeRefs extends React.PureComponent<INodeRefsProps> {
  public render() {
    const { node, width } = this.props;
    if (!node.references) {
      return null;
    }

    return <section>{this.renderRefs(node.references, width)}</section>;
  }
  private renderRefs = (refs: string[], width?: number) => {
    return refs.map((r, index) => (
      <p
        key={index}
        style={width ? { width } : undefined}
        dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(marked(r)) }}
      />
    ));
  };
}
