import { cloneDeep, keyBy, NumericDictionary, omit } from "lodash";

import {
  AlgoNode,
  AlgoNodeInfoType,
  AlgoNodeType,
  Algorithm,
  AlgorithmStatus,
  AlgoState,
  CardAttributes,
  decisionNodesForDisplay,
  nextDecisionNodePathForNode,
  nonDecisionPathToNodeWithChoices,
  evaluateVariableConditions,
  User
} from "src/api";
import { IOpenAlgorithm, ISelectionData, IVariableValue } from "src/store";

const defaultVarValues = (algo: Algorithm) => {
  const { localVars, variables } = algo;
  const combinedVariables = localVars
    ? [...variables, ...Object.values(localVars)]
    : variables;

  const variableDefaults: IVariableValue[] = combinedVariables.map(param => {
    const definition = param.detailsJson;
    const values: NumericDictionary<boolean | number | string | undefined> = {};

    definition.forEach((varPart, index) => {
      const initial = varPart.initial;
      if (!(initial === undefined || initial === null)) {
        values[index] = initial;
        return;
      }

      // Make some values in case.
      switch (varPart.type) {
        case "boolean":
          values[index] = false;
          break;

        case "discrete":
          values[index] = varPart.discreteValues
            ? varPart.discreteValues[0].id
            : undefined;
          break;

        case "numeric":
          // Only default numeric vals if they are outputs.
          if (param.output) {
            values[index] = 0;
          }
        // fallthrough intentional
        default:
          values[index] = undefined;
      }
    });

    return {
      values,
      variableId: param.id
    };
  });

  return keyBy(variableDefaults, d => d.variableId);
};

export const createSectionData = (
  decisionNode: AlgoNode,
  openAlgo: IOpenAlgorithm,
  updatedDecisions: ISelectionData[],
  column: number,
  newTarget?: AlgoNode
) => {
  const { algorithm } = openAlgo;
  const sectionInfo: CardAttributes[] = [];
  let decisionIndex = 0;
  const allAlgoNodes = keyBy(algorithm.nodes, n => n.id);
  const preceedingNodes = nonDecisionPathToNodeWithChoices(
    decisionNode,
    openAlgo,
    openAlgo.algoState.decisionsJson
  );

  let row = 0;
  preceedingNodes.forEach(n => {
    sectionInfo.push(new CardAttributes({ row, column }, n, allAlgoNodes));
    row++;
  });

  // Add decision node
  const dNodeAttrs = new CardAttributes(
    { row, column },
    decisionNode,
    allAlgoNodes
  );
  dNodeAttrs.expanded = true;
  const targets = decisionNode.targets(allAlgoNodes);
  let decisionNodeTarget: AlgoNode | undefined;
  if (targets.length === 1) {
    decisionNodeTarget = targets[0].node;
  }
  dNodeAttrs.nextTarget = decisionNodeTarget;
  sectionInfo.push(dNodeAttrs);
  decisionIndex = row;
  row++;

  // Add multi-select answers
  if (decisionNode.kind === AlgoNodeType.multiSelect) {
    decisionNode.targets(allAlgoNodes).forEach(nt => {
      const {
        node: targetNode,
        path: { low, high }
      } = nt;
      if (targetNode) {
        const location = { row, column };
        const multiSelectTargetAttrs = new CardAttributes(
          location,
          targetNode,
          allAlgoNodes
        );
        if (newTarget && newTarget.id === targetNode.id) {
          multiSelectTargetAttrs.selected = true;
        }
        multiSelectTargetAttrs.multiOptionRange =
          low === high ? `${low}:` : `${low} - ${high}:`;
        multiSelectTargetAttrs.notNextTargetIp = location;

        if (
          [AlgoNodeType.singleSelect, AlgoNodeType.multiSelect].includes(
            targetNode.kind
          )
        ) {
          multiSelectTargetAttrs.nextTarget = targetNode;
        } else {
          const nodePath = nextDecisionNodePathForNode(
            targetNode,
            openAlgo,
            updatedDecisions
          );
          multiSelectTargetAttrs.nextTarget = nodePath && nodePath.pop();
        }

        sectionInfo.push(multiSelectTargetAttrs);
        row++;
      }
    });
  }

  if ([AlgoNodeType.page, AlgoNodeType.terminal].includes(decisionNode.kind)) {
    // Add all the contained components and set their target to be the
    // page node target

    const containedNodes = decisionNode.contained(allAlgoNodes);
    containedNodes.forEach(cn => {
      const { node: targetNode, path } = cn;
      if (targetNode) {
        let showTarget = true;
        if (path.paramsJson && path.paramsJson.length > 0) {
          const passesCriteria = evaluateVariableConditions(
            openAlgo,
            cn,
            updatedDecisions
          );
          showTarget = passesCriteria;
        }

        if (showTarget) {
          const location = { row, column };
          const containee = new CardAttributes(
            location,
            targetNode,
            allAlgoNodes
          );
          containee.compactLayout = true;
          containee.nextTarget = decisionNodeTarget;
          sectionInfo.push(containee);
          row++;
        }
      }
    });
  }

  return { sectionInfo, decisionIndex };
};

export const createOrUpdateOpenAlgoData = (
  loggedInUser: User,
  algo: Algorithm,
  openAlgorithm?: IOpenAlgorithm,
  clearSelection = false,
  removedIds: string[] = []
): IOpenAlgorithm => {
  let decisionNodes: AlgoNode[] = [];
  const decisionNodeIndicies: number[] = [];
  const sectionNodes: CardAttributes[][] = [];
  const currentPage = 0;

  const algorithm = cloneDeep(algo);

  let sectionIndex = 0;
  decisionNodes = decisionNodesForDisplay(algorithm);
  let openAlgoData: IOpenAlgorithm;
  if (!openAlgorithm) {
    let editModeActive = false;
    if (algo.canBeEditedBy(loggedInUser)) {
      if (algorithm.status !== AlgorithmStatus.published) {
        editModeActive = true;
      }
    }

    openAlgoData = {
      algoNodes: keyBy(
        algorithm.nodes.map(n => n.setCachedText()),
        n => n.id
      ),
      algoState: new AlgoState(),
      algorithm,
      currentPage,
      decisionNodeIndicies,
      decisionNodes,
      dirty: false,
      editModeActive,
      editingState: {
        checkerOpen: false,
        infoPanelFocus: AlgoNodeInfoType.info,
        optionsExpanded: true,
        outputsExpanded: true,
        panelFocus: "attrs",
        selectedNodeIds: {},
        x: 0,
        y: 0,
        zoom: -1
      },
      eventQueue: [],
      firstNavigation: true,
      sectionNodes,
      variableValues: defaultVarValues(algo)
    };
  } else {
    const updatedSelection = clearSelection
      ? {}
      : omit(openAlgorithm.editingState.selectedNodeIds, removedIds);
    openAlgoData = {
      ...openAlgorithm,
      algoNodes: keyBy(
        algorithm.nodes.map(n => n.setCachedText()),
        n => n.id
      ),
      algorithm,
      decisionNodeIndicies,
      decisionNodes,
      editingState: {
        ...openAlgorithm.editingState,
        selectedNodeIds: updatedSelection
      },
      sectionNodes
    };

    // // Recover first state, or create new.
    // let state = openAlgorithm.algoState;
    // if (!state) {
    //   if (algorithm.algoStates.length > 0) {
    //     // TODO: Take a specific one.
    //     state = algorithm.algoStates[0];
    //   }
    // }
    // openAlgoData.algoState = state;
  }

  for (const dn of decisionNodes) {
    const { sectionInfo, decisionIndex } = createSectionData(
      dn,
      openAlgoData,
      openAlgoData.algoState.decisionsJson,
      sectionIndex
    );
    sectionNodes.push(sectionInfo);
    decisionNodeIndicies.push(decisionIndex);
    sectionIndex++;
  }
  return openAlgoData;
};
