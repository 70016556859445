import { Card, H6, Icon } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import * as React from "react";

import { fullName, User } from "src/api";

interface IUserCardProps {
  className?: string;
  loggedInUser?: User;
  onClick?: (user: User) => void;
  user: User;
}

export const UserCard: React.SFC<IUserCardProps> = props => {
  const { className, onClick, user } = props;
  const avatarUrl = user.avatar && user.avatar.thumb;

  const handleClick = onClick ? () => onClick(user) : undefined;

  const avatar = avatarUrl ? (
    <img
      alt="avatar"
      className="h3 w3 br-100 pointer"
      draggable={false}
      src={avatarUrl}
    />
  ) : (
    <Icon className="h3 w3" icon={IconNames.USER} iconSize={64} />
  );

  return (
    <Card
      key={user.id}
      id={user.id}
      className={`${onClick ? "pointer" : ""} flex ma2 ${className}`}
      onClick={handleClick}
    >
      {avatar}
      <div className="flex flex-column pa2 tl">
        <H6>{fullName(user)}</H6>
        <p>{user.title}</p>
      </div>
    </Card>
  );
};
