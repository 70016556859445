import { cloneDeep } from "lodash";

import { IChangeAlgoCommentPayload } from "src/actions";
import { Comment, CommentRefType, updateArray, User } from "src/api";
import { IAlgoStore, IOpenAlgorithm } from "src/store";
import { updatedOpenAlgoArray } from ".";

export const handleCommentChange = (
  payload: IChangeAlgoCommentPayload,
  openAlgorithms: IOpenAlgorithm[]
) => {
  return openAlgorithms.map(item => {
    if (item.algorithm.id !== payload.details.algorithm.id) {
      return item;
    }

    return {
      ...item,
      currentComment: payload.commentText
    };
  });
};

export const insertOrUpdateComment = (
  comment: Comment,
  state: IAlgoStore,
  loggedInUser: User
): IAlgoStore => {
  if (comment.linkedRefId) {
    let affectedNodeIndex = -1;
    let affectedCommentIndex = -1;

    // Find what needs changing...
    const openAlgorithm = state.openAlgorithms.find(a => {
      affectedNodeIndex = a.algorithm.nodes.findIndex(n => {
        switch (comment.linkedRefType) {
          case CommentRefType.Comment:
            affectedCommentIndex = n.comments.findIndex(
              c => c.id === comment.linkedRefId
            );
            return affectedCommentIndex > -1;

          case CommentRefType.Node:
          default:
            return n.id === comment.linkedRefId;
        }
      });
      return affectedNodeIndex > -1;
    });

    // Make the change
    if (openAlgorithm) {
      const algorithm = cloneDeep(openAlgorithm.algorithm);
      const nodes = algorithm.nodes;

      if (nodes && affectedNodeIndex > -1) {
        const node = nodes[affectedNodeIndex];

        if (affectedCommentIndex > -1) {
          // Handle reply insertion
          const affectedComment = node.comments[affectedCommentIndex];
          if (affectedComment && affectedComment.comments) {
            affectedComment.comments = updateArray(
              affectedComment.comments,
              comment
            );
          }
          // Handle node comment insertion
          node.comments = updateArray(node.comments, affectedComment);
        } else {
          // Handle node comment insertion
          node.comments = updateArray(node.comments, comment);
        }
        algorithm.nodes = updateArray(algorithm.nodes, node);

        return {
          ...state,
          openAlgorithms: updatedOpenAlgoArray({
            createOnly: false,
            loggedInUser,
            openAlgos: state.openAlgorithms,
            updatedAlgo: algorithm,
            updatedNodes: { [node.id]: node }
          })
        };
      }
    }
  }
  return state;
};
