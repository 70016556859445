/* eslint-disable @typescript-eslint/no-use-before-define */
import { Dictionary, flatMap, keyBy } from "lodash";

import { IOpenAlgorithm, ISelectionData, IVariableValue } from "src/store";
import { variableValue } from "..";
import {
  AlgoNode,
  AlgoNodeType,
  IAlgoNodeTarget,
  pathOrderSort
} from "./algo-node";
import { VariableOperator } from "./algo-variables";
import { Algorithm } from "./algorithm";
import { nodeTitleTextPlain } from "src/utilities";

const pageNodes = [
  AlgoNodeType.singleSelect,
  AlgoNodeType.multiSelect,
  AlgoNodeType.varInput,
  AlgoNodeType.page
];

export const nextDecisionNodePathForNode = (
  node: AlgoNode,
  openAlgo: IOpenAlgorithm,
  updatedDecisions: ISelectionData[]
) => {
  // Traverse the forward path to find the next target
  const pathNodes: AlgoNode[] = [node];
  const fixedPath = fixedPathFromNode(
    node,
    openAlgo,
    updatedDecisions,
    pathNodes
  );

  const lastNode =
    fixedPath && fixedPath.length > 0 && fixedPath[fixedPath.length - 1];
  if (
    lastNode &&
    [
      ...pageNodes,
      AlgoNodeType.varDecision,
      AlgoNodeType.varProcessor,
      AlgoNodeType.terminal
    ].includes(lastNode.kind)
  ) {
    return fixedPath;
  }
  return undefined;
};

export const decisionNodesForDisplay = (algorithm: Algorithm) => {
  const fnd = nodesForDisplay(algorithm).filter(a =>
    pageNodes.includes(a.kind)
  );
  return fnd;
};

const nodesForDisplay = (algorithm: Algorithm) => {
  const startNodes = algorithm.nodes.filter(
    n => n.kind === AlgoNodeType.entryPoint
  );

  let displayNodes: AlgoNode[] = [];

  for (const entry of startNodes) {
    displayNodes = addNodeAndTargetsOf(entry, algorithm, displayNodes);
  }
  return displayNodes;
};

const addNodeAndTargetsOf = (
  node: AlgoNode | undefined,
  algorithm: Algorithm,
  nodeArray: AlgoNode[]
) => {
  const allAlgoNodes = keyBy(algorithm.nodes, n => n.id);
  let nodes = nodeArray;
  if (node) {
    if (!nodes.includes(node)) {
      nodes.push(node);

      switch (node.kind) {
        case AlgoNodeType.singleSelect:
          const optionsForSingleSelect = node.options(allAlgoNodes);

          optionsForSingleSelect.forEach(
            o =>
              o.node &&
              o.node.targets(allAlgoNodes).forEach(ot => {
                nodes = addNodeAndTargetsOf(ot.node, algorithm, nodes);
              })
          );
          break;

        default:
          for (const target of node.targets(allAlgoNodes)) {
            nodes = addNodeAndTargetsOf(target.node, algorithm, nodes);
          }
      }
    }
  }
  return nodes;
};

export const nonDecisionPathToNodeWithChoices = (
  node: AlgoNode,
  openAlgo: IOpenAlgorithm,
  choices: ISelectionData[]
) => {
  const decisionPathNodes = fixedPathToNode(node, openAlgo, [], choices);

  return decisionPathNodes.reverse();
};

const fixedPathFromNode = (
  node: AlgoNode,
  openAlgo: IOpenAlgorithm,
  updatedDecisions: ISelectionData[],
  pathNodes: AlgoNode[]
) => {
  const { algoNodes } = openAlgo;

  if (pageNodes.includes(node.kind)) {
    pathNodes.push(node);
    return pathNodes;
  }
  const nodeTargets = node.targets(algoNodes);
  if (
    [AlgoNodeType.varDecision, AlgoNodeType.varProcessor].includes(node.kind)
  ) {
    pathNodes = pathFromVarDecisionNode(
      node,
      openAlgo,
      updatedDecisions,
      pathNodes
    );
  } else {
    nodeTargets.forEach(nt => {
      if (nt.node) {
        pathNodes.push(nt.node);
        if (nt.node.kind === AlgoNodeType.intermediate) {
          pathNodes = fixedPathFromNode(
            nt.node,
            openAlgo,
            updatedDecisions,
            pathNodes
          );
        }
      }
    });
  }
  return pathNodes;
};

const pathFromVarDecisionNode = (
  node: AlgoNode,
  openAlgo: IOpenAlgorithm,
  updatedDecisions: ISelectionData[],
  pathArray: AlgoNode[]
) => {
  const target = targetForDecisionNode(node, openAlgo, updatedDecisions);
  if (target && target.node) {
    pathArray.push(target.node);
  }
  return pathArray;
};

const targetForDecisionNode = (
  node: AlgoNode,
  openAlgo: IOpenAlgorithm,
  updatedDecisions: ISelectionData[]
) => {
  const { algoNodes } = openAlgo;
  let target: IAlgoNodeTarget | undefined;
  // Work through the decision criteria in order and return when we have something
  const nodeTargets = node.targets(algoNodes);

  let nextTargetSet = false;
  nodeTargets.forEach(nt => {
    if (nt.node && !nextTargetSet) {
      const passesCriteria = evaluateVariableConditions(
        openAlgo,
        nt,
        updatedDecisions
      );
      if (passesCriteria) {
        // Go to this target.
        target = nt;
        nextTargetSet = true;
      }
    }
  });
  return target;
};

const traversePath = (
  n: AlgoNode,
  branchNodes: AlgoNode[],
  openAlgo: IOpenAlgorithm,
  userChoices: ISelectionData[]
) => {
  if (!branchNodes.includes(n)) {
    branchNodes.push(n);
    return fixedPathToNode(n, openAlgo, branchNodes, userChoices);
  }
  return branchNodes;
};

export const fixedPathToNode = (
  node: AlgoNode,
  openAlgo: IOpenAlgorithm,
  nodes: AlgoNode[],
  userChoices: ISelectionData[]
): AlgoNode[] => {
  const { algoNodes } = openAlgo;
  const nodeSources = backwardLinkedNodes(node, algoNodes);
  let pathNodes = nodes;

  if (nodeSources.length === 1) {
    const n = nodeSources[0];
    if (n.kind === AlgoNodeType.intermediate) {
      pathNodes = traversePath(n, pathNodes, openAlgo, userChoices);
    }
  } else {
    // Check to see if we can figure out which one to show based on whether a choice was made
    for (const possibleSource of nodeSources) {
      let possibleBranch: AlgoNode[] = [];
      if (possibleSource && possibleSource.kind === AlgoNodeType.intermediate) {
        possibleBranch = traversePath(
          possibleSource,
          possibleBranch,
          openAlgo,
          userChoices
        );
      }

      if (possibleBranch.length > 0) {
        let includeBranch = false;
        const startNode = possibleBranch.reverse()[0];
        for (let parent of parentsOf(startNode, algoNodes)) {
          // Need the question node for a choice
          if (parent && parent.kind === AlgoNodeType.choice) {
            parent = containerOf(parent, algoNodes);
          }

          if (parent) {
            // Branches must have a single entry from the parent and be selected
            const parentChoices = userChoices.find(c => c.nodeId === parent.id);
            if (parentChoices) {
              const { targetNode } = evaluateSelection(
                parent,
                parentChoices.selection,
                openAlgo,
                userChoices
              );
              if (targetNode && targetNode.id === startNode.id) {
                includeBranch = true;
              }
            }
          }
          if (includeBranch) {
            pathNodes = pathNodes.concat(possibleBranch.reverse());
            return pathNodes;
          }
        }
      }
    }
  }
  return pathNodes;
};

const backwardLinkedNodes = (
  node: AlgoNode,
  algorithmNodes: Dictionary<AlgoNode>
) => {
  let nodes: AlgoNode[] = [];

  const backLinks = node
    .backwardLinks()
    .sort(pathOrderSort)
    .map(path => algorithmNodes[path.parentId]);

  if (backLinks.length > 0) {
    nodes = backLinks;
  }
  return nodes;
};

const parentsOf = (node: AlgoNode, algorithmNodes: Dictionary<AlgoNode>) => {
  return node.backwardLinkNodeIds().map(id => algorithmNodes[id]);
};

const containerOf = (node: AlgoNode, algorithmNodes: Dictionary<AlgoNode>) => {
  const containerId = node.containerId();
  return algorithmNodes[containerId];
};

export interface ITargetEvaluation {
  targetNode?: AlgoNode;
  targetAlgorithmId?: string | null;
  updatedDecisions: ISelectionData[];
}

export const evaluateSelection = (
  node: AlgoNode,
  selection: string[],
  openAlgo: IOpenAlgorithm,
  decisions: ISelectionData[]
): ITargetEvaluation => {
  const { algoNodes } = openAlgo;
  // tslint:disable-next-line:no-console
  console.log(
    `\nProcessing ${node.kind}: "${nodeTitleTextPlain(node)}" id:${node.id}`
  );

  switch (node.kind) {
    case AlgoNodeType.page: {
      // Store a dummy value for the page so we can unwind to it (in the case of a next button)
      let updatedDecisions = updateDecisions(decisions, {
        selection: [node.id],
        nodeId: node.id
      });

      const contained = node.contained(algoNodes);
      let evaluation;
      contained.forEach(ct => {
        if (ct.node) {
          evaluation = evaluateSelection(
            ct.node,
            selection,
            openAlgo,
            updatedDecisions
          );
          updatedDecisions = evaluation.updatedDecisions;
        }
      });
      const targetNode: AlgoNode | undefined = node;
      let targetAlgorithmId;

      // Finally go to the target of the page node and return
      // BUT NOT NOW BECAUSE COLM WANTS TO CLICK THROUGH PAGES
      // const pageTarget = node.targets(algoNodes)[0];
      // if (pageTarget) {
      //   targetNode = pageTarget.node;
      //   targetAlgorithmId = pageTarget.path.targetAlgorithmId;
      // }
      const pageNodeDecisionIdx = updatedDecisions.findIndex(
        d => d.nodeId === node.id
      );
      if (pageNodeDecisionIdx > -1) {
        const pageNodeSelection = updatedDecisions.splice(
          pageNodeDecisionIdx,
          1
        );
        updatedDecisions.push(pageNodeSelection[0]);
      }
      return { updatedDecisions, targetNode, targetAlgorithmId };
    }

    case AlgoNodeType.varProcessor:
      return evaluateProcessorNode(node, openAlgo, decisions);

    case AlgoNodeType.varDecision:
      return evaluateVarDecisionNode(node, openAlgo, decisions);

    case AlgoNodeType.multiSelect:
      return evaluateMulipleSelection(node, selection, openAlgo, decisions);

    case AlgoNodeType.varInput:
      return evaluateVarInputNode(node, openAlgo, decisions);

    default:
      if (selection.length > 0) {
        const options = node
          .options(algoNodes)
          .filter(n => n.node && n.node.id === selection[0])
          .map(c => c.node && c.node.targets(algoNodes));

        if (options.length > 0) {
          const allOptions = flatMap(options);
          const target = allOptions[0];
          if (target) {
            return {
              targetAlgorithmId: target.path.targetAlgorithmId,
              targetNode: target.path.targetAlgorithmId
                ? undefined
                : target.node,
              updatedDecisions: decisions
            };
          }
        }
      }
  }
  return { updatedDecisions: decisions };
};

export const updateDecisions = (
  decisions: ISelectionData[],
  vals: Partial<ISelectionData> & {
    nodeId: string;
  }
): ISelectionData[] => {
  const foundIndex = decisions.findIndex(value => value.nodeId === vals.nodeId);
  if (foundIndex > -1) {
    return decisions.map((item, index) =>
      index !== foundIndex ? item : { ...item, ...vals }
    );
  } else {
    const newVals = decisions.slice();
    const variableValues = vals.variableValues || {};
    const vars = { ...vals, nodeId: vals.nodeId, variableValues };
    newVals.push({ selection: [], ...vars });
    return newVals;
  }
};

export const unwindDecisions = (
  decisions: ISelectionData[],
  toNodeId: string
): ISelectionData[] => {
  const foundIndex = decisions.findIndex(value => value.nodeId === toNodeId);
  if (foundIndex === -1) {
    return decisions;
  }
  return decisions.slice(0, foundIndex);
};

const evaluateVarInputNode = (
  node: AlgoNode,
  openAlgo: IOpenAlgorithm,
  updatedDecisions: ISelectionData[]
) => {
  const { algoNodes } = openAlgo;

  let targetNode: AlgoNode | undefined;
  // This is always the first target.
  if (node.isContained()) {
    const container = algoNodes[node.containerId()];
    const containerTargets = container.targets(algoNodes);
    targetNode = containerTargets[0] ? containerTargets[0].node : undefined;
  } else {
    const targets = node.targets(algoNodes);
    targetNode = targets[0] ? targets[0].node : undefined;
  }
  return {
    targetNode,
    updatedDecisions
  };
};

const evaluateProcessorNode = (
  node: AlgoNode,
  openAlgo: IOpenAlgorithm,
  decisionsJson: ISelectionData[]
): ITargetEvaluation => {
  const {
    algorithm: { localVars, variables }
  } = openAlgo;
  const { algoNodes } = openAlgo;
  const updatedDecisions = decisionsJson;

  // This is always the first target.
  const targets = node
    .targets(algoNodes)
    .filter(t => t.path.childId || t.path.targetAlgorithmId);
  let targetNode;
  let targetAlgorithmId;
  if (targets[0]) {
    targetNode = targets[0].node;
    targetAlgorithmId = targets[0].path.targetAlgorithmId;
  }

  const calcs = node.calcs(algoNodes);
  const variableChanges: Dictionary<IVariableValue> = {};

  const combinedVariables = localVars
    ? [...variables, ...Object.values(localVars)]
    : variables;

  calcs.forEach(nt => {
    const varDeets = nt.path.paramsJson;
    if (varDeets) {
      const target = varDeets.filter(v => v.resultDetails)[0];

      if (target && target.resultDetails) {
        const targetId = target.id;
        const affectedVar = combinedVariables.find(v => v.id === targetId);

        if (affectedVar) {
          // Work through the criteria to see if this applies.
          const shouldApply = evaluateVariableConditions(
            openAlgo,
            nt,
            updatedDecisions
          );

          if (shouldApply) {
            const targetGroup = target.groupNumber || 0;
            // Grab existing value(s) for variable
            let existingValue = variableChanges[affectedVar.id];
            if (!existingValue) {
              existingValue = variableValue(
                decisionsJson,
                affectedVar.id,
                node.id
              )[0].value;
            }
            const oper = target.resultDetails.operator;
            if (target.resultDetails.values) {
              const val = target.resultDetails.values[targetGroup];
              const existingVariableValue = existingValue.values[targetGroup];
              // Make the change
              // tslint:disable:no-console
              console.log(`  Need to ${oper} ${val} to ${affectedVar.title}`);
              let newValue = existingVariableValue;
              switch (oper) {
                case VariableOperator.add:
                  newValue =
                    (existingVariableValue as number) + (val as number);
                  break;

                case VariableOperator.subtract:
                  newValue =
                    (existingVariableValue as number) - (val as number);
                  break;

                case VariableOperator.set:
                default:
                  newValue = val;
              }

              console.log(
                `    Value changed from ${existingVariableValue} --> ${newValue}`
              );
              const changed = variableChanges[affectedVar.id];

              variableChanges[affectedVar.id] = {
                values: {
                  ...(changed ? changed.values : undefined),
                  [targetGroup]: newValue
                },
                variableId: affectedVar.id
              };
            }
          }
        }
      }
    }
  });

  if (Object.keys(variableChanges).length > 0) {
    return {
      targetAlgorithmId,
      targetNode,
      updatedDecisions: updateDecisions(updatedDecisions, {
        nodeId: node.id,
        variableValues: { ...variableChanges }
      })
    };
  }
  return { targetNode, targetAlgorithmId, updatedDecisions };
};

export const evaluateVariableConditions = (
  openAlgo: IOpenAlgorithm,
  { path }: IAlgoNodeTarget,
  decisionsJson: ISelectionData[]
) => {
  const {
    algoNodes,
    algorithm: { localVars, variables }
  } = openAlgo;

  const node = algoNodes[path.parentId];
  let passesCriteria = false;
  const combinedVariables = localVars
    ? [...variables, ...Object.values(localVars)]
    : variables;
  const varDeets = path.paramsJson;

  if (varDeets) {
    const functions = varDeets.filter(v => !v.resultDetails);

    // Work through the criteria to see if this applies.
    passesCriteria = true;
    functions.forEach(criteria => {
      if (passesCriteria) {
        // This sets the loop to be a logical AND of the criteria
        // Make sure all the criteria are fulfilled before applying the action
        if (criteria.operatorDetails) {
          const { operator, values: criteriaValues } = criteria.operatorDetails;
          const testVar = combinedVariables.find(v => v.id === criteria.id);

          if (testVar) {
            // Get value of the testVar from the algo
            const varVal = variableValue(decisionsJson, testVar.id, node.id)[0];
            if (varVal !== undefined) {
              const testVarVals: IVariableValue = varVal.value;
              if (testVarVals.values) {
                const varPart = criteria.groupNumber || 0;
                const testVals = testVarVals.values;
                const val = testVals[varPart];
                const criterionVal = criteriaValues[varPart];

                if (val === undefined) {
                  passesCriteria = false;
                } else {
                  switch (operator) {
                    case VariableOperator.between:
                      // These have to be ordered numeric values
                      const vals = (Object.values(criteriaValues).map(
                        v => v[varPart]
                      ) as number[]).sort((a, b) => a - b);

                      console.log(
                        ` Testing ${val} between ${vals[0]} and ${vals[1]}`
                      );
                      if (val >= vals[0] && val <= vals[1]) {
                        passesCriteria = true;
                      } else {
                        passesCriteria = false;
                      }
                      break;

                    case VariableOperator.isEqualTo:
                      console.log(
                        ` Testing ${val} equal to ${criterionVal[0]}`
                      );
                      if (criterionVal[0] === val) {
                        passesCriteria = true;
                      } else {
                        passesCriteria = false;
                      }
                      break;

                    case VariableOperator.greaterThan: {
                      const crit = criterionVal[0];
                      console.log(
                        ` Testing ${val} greater than ${criterionVal[0]}`
                      );
                      passesCriteria = crit !== undefined ? val > crit : false;
                      break;
                    }

                    case VariableOperator.lessThan: {
                      const crit = criterionVal[0];
                      console.log(
                        ` Testing ${val} less than ${criterionVal[0]}`
                      );
                      passesCriteria = crit !== undefined ? val < crit : false;
                      break;
                    }
                  }
                }
              } else {
                console.error(
                  `Could not find variable value for ${testVar.title}`
                );
                passesCriteria = false;
              }
            } else {
              console.error(`Value for variable ${testVar.id} is undefined`);
            }
          } else {
            console.error(`Could not find variable for ${criteria.id}`);
            passesCriteria = false;
          }
        } else {
          passesCriteria = false;
        }
      }
    });
  }
  console.log(`Outcome: ${passesCriteria}`);
  return passesCriteria;
};

const evaluateVarDecisionNode = (
  node: AlgoNode,
  openAlgo: IOpenAlgorithm,
  decisions: ISelectionData[]
): ITargetEvaluation => {
  const nodeTarget = targetForDecisionNode(node, openAlgo, decisions);
  // Add the target to the selection
  let updatedDecisions;
  if (nodeTarget && nodeTarget.node) {
    updatedDecisions = updateDecisions(decisions, {
      nodeId: node.id,
      selection: [nodeTarget.node.id],
      variableValues: {}
    });
  }

  return {
    targetAlgorithmId: nodeTarget
      ? nodeTarget.path.targetAlgorithmId
      : undefined,
    targetNode: nodeTarget ? nodeTarget.node : undefined,
    updatedDecisions: updatedDecisions || decisions
  };
};

const evaluateMulipleSelection = (
  node: AlgoNode,
  selection: string[],
  openAlgo: IOpenAlgorithm,
  selections: ISelectionData[]
): ITargetEvaluation => {
  const { algoNodes } = openAlgo;

  let score = 0;
  for (const choiceId of selection) {
    const choiceNode = algoNodes[choiceId];
    if (choiceNode) {
      score += choiceNode.weight;
    }
  }

  const tNodes = node
    .targets(algoNodes)
    .filter(t => t.path.low <= score)
    .filter(t => t.path.high >= score)
    .map(t => t.node);

  if (tNodes.length > 0) {
    return {
      targetNode: tNodes[0],
      updatedDecisions: selections
    };
  }
  return { updatedDecisions: selections };
};
