import { Alert, Button, Intent, ProgressBar } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import * as React from "react";
import { Dictionary } from "lodash";
import { connect } from "react-redux";

import {
  removeNodes,
  updatePath,
  linkNodes,
  setNodeImage,
  clearUiError
} from "src/actions";
import { AlgoNode, AlgoNodeType, Algorithm } from "src/api";
import {
  EditLabeledTextField,
  MarkdownTextarea,
  NodeTargetRenderer
} from "src/components";
import { IOpenAlgorithm, IStoreState } from "src/store";
import { nodeTitleTextPlain } from "src/utilities";
import { ChoiceOption } from ".";

export interface INodeEditAttributesPanelProps {
  allowTextAreaResize?: boolean;
  disableDelete?: boolean;
  openAlgorithm: IOpenAlgorithm;
  node?: AlgoNode;
  removeNodes: typeof removeNodes;
  updateNodeText: (node: AlgoNode) => void;
}

interface INodeEditAttributesPanelInjectedProps {
  error?: Error;
  mediaUploads: Dictionary<File>;
  otherAlgorithms: Algorithm[];
}

interface INodeEditAttributesPanelDispatchProps {
  clearUiError: typeof clearUiError;
  linkNodes: typeof linkNodes;
  setNodeImage: typeof setNodeImage.request;
  updatePath: typeof updatePath;
}

interface INodeEditAttributesPanelState {
  busy: boolean;
  error?: Error;
  file?: File;
  imageUrl?: string;
  showDeleteConfirm: boolean;
}

type NodeEditAttributesPanelProps = INodeEditAttributesPanelProps &
  INodeEditAttributesPanelInjectedProps &
  INodeEditAttributesPanelDispatchProps;
class NodeEditAttributesPanelComponent extends React.PureComponent<
  NodeEditAttributesPanelProps,
  INodeEditAttributesPanelState
> {
  constructor(props: NodeEditAttributesPanelProps) {
    super(props);
    this.state = {
      busy: false,
      showDeleteConfirm: false
    };
    this.handlePropUpdate({} as NodeEditAttributesPanelProps);
  }

  public componentDidUpdate(oldProps: NodeEditAttributesPanelProps) {
    this.handlePropUpdate(oldProps);
  }

  private handlePropUpdate(oldProps: NodeEditAttributesPanelProps) {
    const { error, mediaUploads, node } = this.props;
    const { busy, error: stateError } = this.state;

    if (!(error || stateError)) {
      if (node) {
        const inProgressUpload = mediaUploads[node.id];

        if (inProgressUpload) {
          if (!oldProps.node || oldProps.node.id !== node.id) {
            // New node, set up
            const imageUrl = window.URL.createObjectURL(inProgressUpload);
            this.setState({ busy: true, file: inProgressUpload, imageUrl });
          } else {
            if (!busy) {
              // Now busy
              this.setState({ busy: true, error: undefined });
            }
          }
        } else {
          if (busy) {
            // we have no inProgress, and we were busy - so we must be done.
            this.setState({
              busy: false,
              file: undefined,
              imageUrl: undefined
            });
          }
        }
      } else {
        // No node, so reset
        if (busy || stateError) {
          this.setState({
            busy: false,
            error: undefined,
            file: undefined,
            imageUrl: undefined
          });
        }
      }
    } else {
      if (busy) {
        if (error) {
          // Transfer store error to local state
          this.setState({ busy: false, error });
          this.props.clearUiError();
        }
      }
    }
  }

  public render() {
    const {
      allowTextAreaResize,
      disableDelete,
      error: netError,
      node,
      openAlgorithm,
      otherAlgorithms
    } = this.props;
    const {
      showDeleteConfirm,
      imageUrl: newImageUrl,
      error,
      busy
    } = this.state;

    if (!node) {
      return null;
    }

    const { algoNodes } = openAlgorithm;
    const isDecision = node.isDecision();
    const isChoice = node.kind === AlgoNodeType.choice;
    const isImage = node.kind === AlgoNodeType.image;
    const isSharedText = node.kind === AlgoNodeType.sharedText;

    const image = newImageUrl || (node.image && node.image.thumb);

    const showTargetSelector =
      [
        AlgoNodeType.varProcessor,
        AlgoNodeType.varInput,
        AlgoNodeType.intermediate,
        AlgoNodeType.page
      ].includes(node.kind) && !node.isContained();

    const hasExtendedText = [
      AlgoNodeType.singleSelect,
      AlgoNodeType.multiSelect,
      AlgoNodeType.intermediate,
      AlgoNodeType.terminal,
      AlgoNodeType.sharedText,
      AlgoNodeType.page
    ].includes(node.kind);
    let choice;
    if (isChoice) {
      const parent = algoNodes[node.containerId()];
      if (parent) {
        choice = parent
          .options(algoNodes)
          .find(o => (o.node ? o.node.id === node.id : false));
      }
    }

    const lines = node.title.split("\n");
    const restOfText = lines.slice(isDecision ? 2 : 1).join("\n");
    const cancelDelete = () => this.setState({ showDeleteConfirm: false });
    const title = nodeTitleTextPlain(node);

    const target = node.targets(algoNodes).filter(p => !p.path.paramsJson)[0];

    const labelText = isSharedText
      ? "Shared Text Name"
      : isImage
      ? "Caption"
      : "Node Title";

    const titleOrChoice = choice ? (
      <ChoiceOption
        choice={choice}
        minimal={true}
        openAlgorithm={openAlgorithm}
        parentNode={algoNodes[choice.path.parentId]}
      />
    ) : (
      <EditLabeledTextField
        label={labelText}
        onChange={this.handleTitleChange}
        value={lines[0] || ""}
      />
    );

    const questionText = isDecision && (
      <EditLabeledTextField
        label={"Question Text"}
        onChange={this.handleQuestionChange}
        value={lines[1] || ""}
      />
    );

    const markdownArea = hasExtendedText && (
      <MarkdownTextarea
        allowResize={allowTextAreaResize}
        label={isSharedText ? "Shared Text" : "Node Text"}
        onChange={this.changeText}
        value={restOfText}
        enableRTE={true}
      />
    );

    const targetSection = showTargetSelector && (
      <div className="pv2">
        <NodeTargetRenderer
          parent={node}
          target={target}
          openAlgorithm={openAlgorithm}
          otherAlgorithms={otherAlgorithms}
          updatePath={this.props.updatePath}
          linkNodes={this.props.linkNodes}
        />
      </div>
    );

    const fileInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      const newFile = e.target.files && e.target.files[0];
      if (newFile) {
        if (newFile.type.match(RegExp("/*image/*"))) {
          const imageUrl = window.URL.createObjectURL(newFile);
          this.setState({ file: newFile, imageUrl, error: undefined });
        } else {
          this.setState({
            file: undefined,
            imageUrl: undefined,
            error: new Error("File must be of image type")
          });
        }
      }
    };

    const fileInputStyle: React.CSSProperties = {
      textAlign: "center",
      opacity: 0,
      position: "absolute",
      height: "100%",
      width: "100%",
      top: 0,
      left: 0
    };
    const combinedError = netError || error;
    const errorDisplay = combinedError && (
      <p className="mt2 orange">{combinedError.message}</p>
    );

    const buttonText = newImageUrl
      ? combinedError
        ? "Retry"
        : "Upload"
      : "Select";

    const imageSection = isImage && (
      <div className="pv2 w-100 flex flex-auto flex-column overflow-x-hidden">
        <p className="ttu zx-edit-grey">Image</p>
        <img
          alt=""
          className="w-100 mw5 pb2"
          draggable={false}
          src={image}
          style={{ objectFit: "scale-down" }}
        />
        {busy && <ProgressBar className="mv1" intent={Intent.SUCCESS} />}
        <div className="w-100 flex flex-row pb1">
          <div className="w-50 relative">
            <Button
              disabled={busy}
              fill={true}
              alignText="center"
              text="Browse..."
            >
              <input
                disabled={busy}
                type="file"
                accept="image/*"
                onChange={fileInputChange}
                style={fileInputStyle}
              />
            </Button>
          </div>
          <div className="w-50 pl1">
            <Button
              className="flex"
              disabled={newImageUrl ? false : true}
              fill={true}
              loading={busy}
              intent={Intent.SUCCESS}
              onClick={this.doUpload}
              text={buttonText}
            />
          </div>
        </div>
        {errorDisplay}
      </div>
    );

    const deleteButton = !disableDelete && (
      <Button
        alignText="center"
        className="mv2"
        icon={IconNames.TRASH}
        intent={Intent.DANGER}
        text="Delete"
        title="Delete"
        onClick={this.confirmDelete}
      />
    );

    return (
      <section className="flex flex-column flex-auto w-100">
        <Alert
          canEscapeKeyCancel={true}
          canOutsideClickCancel={true}
          cancelButtonText="Cancel"
          onCancel={cancelDelete}
          confirmButtonText="Delete"
          onConfirm={this.handleDelete}
          isOpen={showDeleteConfirm}
          icon={IconNames.TRASH}
          intent={Intent.DANGER}
        >
          <p>Are you sure you wish to delete &quot;{title.trim()}&quot;?</p>
        </Alert>
        {titleOrChoice}
        {imageSection}
        {questionText}
        {markdownArea}
        {targetSection}
        {deleteButton}
      </section>
    );
  }

  private doUpload = () => {
    const { busy, file } = this.state;
    const { node } = this.props;
    if (!busy && file && node) {
      this.props.setNodeImage({ nodeId: node.id, imageData: file });
      this.setState({ error: undefined });
    }
  };

  private confirmDelete = () => this.setState({ showDeleteConfirm: true });
  private handleDelete = () => {
    const {
      node,
      openAlgorithm: {
        algorithm: { id }
      }
    } = this.props;
    if (node) {
      this.props.removeNodes({
        algoId: id,
        nodeIds: { [node.id]: 1 }
      });
      this.setState({ showDeleteConfirm: false });
    }
  };

  private changeText = (newText: string) => {
    const { node } = this.props;
    if (node) {
      const lines = node.title.split("\n");
      const restOffset = node.isDecision() ? 2 : 1;

      if (lines.length > restOffset) {
        lines.splice(
          restOffset,
          lines.length - restOffset,
          ...newText.split("\n")
        );
      } else {
        if (restOffset - 1 > lines.length) {
          lines.push("");
        }
        if (restOffset > lines.length) {
          lines.push("");
        }
        lines.push(newText);
      }
      node.title = lines.join("\n");
      this.props.updateNodeText(node);
    }
  };

  private handleTitleChange = (newTitle: string) => {
    const { node } = this.props;
    if (node) {
      const lines = node.title.split("\n");
      if (lines.length > 0) {
        lines[0] = newTitle;
      } else {
        lines.push(newTitle);
      }
      node.title = lines.join("\n");
      this.props.updateNodeText(node);
    }
  };

  private handleQuestionChange = (newQuestion: string) => {
    const { node } = this.props;
    if (node) {
      const lines = node.title.split("\n");
      if (lines.length > 1) {
        lines[1] = newQuestion;
      } else {
        if (lines.length === 0) {
          lines.push("");
        }
        lines.push(newQuestion);
      }
      node.title = lines.join("\n");
      this.props.updateNodeText(node);
    }
  };
}

const mapStateToProps = (
  { algoStore, uiStore }: IStoreState,
  props: INodeEditAttributesPanelProps
): INodeEditAttributesPanelInjectedProps => {
  const otherAlgorithms = Object.values(algoStore.allAlgorithms).filter(
    a => a.id !== props.openAlgorithm.algorithm.id
  );

  return {
    error: uiStore.error,
    mediaUploads: uiStore.mediaUploads,
    otherAlgorithms
  };
};

export const NodeEditAttributesPanel = connect(mapStateToProps, {
  clearUiError,
  linkNodes,
  updatePath,
  setNodeImage: setNodeImage.request
})(NodeEditAttributesPanelComponent);
