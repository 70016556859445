import {
  IconName,
  MenuItem,
  Spinner,
  IInputGroupProps
} from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import { ItemRenderer, Suggest } from "@blueprintjs/select";
import { Dictionary } from "lodash";
import * as React from "react";
import { connect } from "react-redux";

import { retrieveUnits } from "src/actions";
import {
  dictionaryFromPayload,
  makeApiRequest,
  ParameterUnit,
  requestConfigForAction,
  IXmedObjectInfo
} from "src/api";
import { IStoreState } from "src/store";
import { highlightText } from "src/utilities";

interface IUnitSuggestProps {
  disabled?: boolean;
  className?: string;
  leftIcon?: IconName;
  onChange: (unit: ParameterUnit) => void;
  onCreate?: (unit: ParameterUnit) => void;
  value?: ParameterUnit;
}

interface IUnitSuggestInjectedProps {
  token?: string;
  units: Dictionary<ParameterUnit>;
}

interface IUnitSuggestState {
  error?: Error;
  items: ParameterUnit[];
  loading: boolean;
  query?: string;
}

type UnitSuggestComponentProps = IUnitSuggestProps & IUnitSuggestInjectedProps;
class UnitSuggestComponent extends React.PureComponent<
  UnitSuggestComponentProps,
  IUnitSuggestState
> {
  constructor(props: UnitSuggestComponentProps) {
    super(props);

    this.state = {
      items: props.value ? [props.value] : [],
      loading: false,
      query: ""
    };
  }

  public componentDidUpdate(prevProps: UnitSuggestComponentProps) {
    const { units, value } = this.props;
    if (units !== prevProps.units || (value && value !== prevProps.value)) {
      const items = this.mergeResults(
        units,
        value ? { [value.id]: value } : undefined
      );
      this.setState({ items });
    }
  }

  public render() {
    const { className, disabled, leftIcon, onCreate, value } = this.props;
    const { items } = this.state;

    const popoverProps = {
      minimal: true,
      usePortal: true
    };
    const inputProps: IInputGroupProps = {
      className,
      leftIcon,
      placeholder: "Search"
    };

    return (
      <Suggest<ParameterUnit>
        disabled={disabled}
        items={items}
        createNewItemFromQuery={this.handleCreate}
        createNewItemRenderer={onCreate ? this.createRenderer : undefined}
        noResults={this.renderNoResults()}
        itemRenderer={this.itemRenderer}
        inputValueRenderer={this.inputValueRenderer}
        onItemSelect={this.handleSelect}
        selectedItem={value}
        onQueryChange={this.getLabels}
        popoverProps={popoverProps}
        openOnKeyDown={false}
        resetOnQuery={false}
        resetOnClose={false}
        inputProps={inputProps}
      />
    );
  }

  private handleCreate = (term: string) => {
    const newUnit = new ParameterUnit(term);
    return newUnit;
  };

  private renderNoResults = () => {
    const { loading } = this.state;
    return loading ? <Spinner size={Spinner.SIZE_SMALL} /> : null;
  };

  private handleSelect = (item: ParameterUnit) => this.props.onChange(item);

  private inputValueRenderer = (item: ParameterUnit) =>
    item.shortName || item.name;

  private itemRenderer: ItemRenderer<ParameterUnit> = (
    { id, name },
    { handleClick, modifiers: { active, disabled, matchesPredicate }, query }
  ) => {
    if (!matchesPredicate) {
      return null;
    }

    return (
      <MenuItem
        active={active}
        disabled={disabled}
        key={id}
        onClick={handleClick}
        text={highlightText(name, query)}
      />
    );
  };

  private createRenderer = (query: string, active: boolean) => {
    const { items } = this.state;
    const { onCreate } = this.props;
    const createNew = () => {
      const newUnit = new ParameterUnit(query);
      if (onCreate) {
        onCreate(newUnit);
      }
      this.setState({ items: [newUnit, ...items] });
    };
    return (
      <MenuItem
        active={active}
        icon={IconNames.ADD}
        onClick={createNew}
        text={`Create new unit type '${query}'`}
      />
    );
  };

  private getLabels = async (query: string) => {
    const { token } = this.props;

    if (query.length === 0) {
      this.setState({ items: [], query });
    } else {
      this.setState({ loading: true, query });
      try {
        const response = await makeApiRequest(
          requestConfigForAction(
            retrieveUnits.request({ query, skip: 0, take: 50 }),
            token
          )
        );
        if (response.apiResponse.data) {
          const results = dictionaryFromPayload(
            response.apiResponse.data as IXmedObjectInfo[],
            ParameterUnit
          );
          const items = this.mergeResults(results, this.props.units).filter(
            i =>
              i.name.toLowerCase().includes(query.toLowerCase()) ||
              i.shortName.toLowerCase().includes(query.toLowerCase())
          );
          this.setState({ items, loading: false });
        }
      } catch (error) {
        this.setState({ loading: false, error });
      }
    }
  };

  private mergeResults = (
    setA: Dictionary<ParameterUnit>,
    setB?: Dictionary<ParameterUnit>
  ): ParameterUnit[] => {
    const results: ParameterUnit[] = Object.values(
      setB ? { ...setA, ...setB } : setA
    );

    results.sort((a, b) => {
      const unitA = a.name.toLowerCase();
      const unitB = b.name.toLowerCase();
      if (unitA > unitB) {
        return 1;
      }
      if (unitA < unitB) {
        return -1;
      }
      return 0;
    });
    return results;
  };
}

const mapStateToProps = ({
  userStore,
  parameterStore
}: IStoreState): IUnitSuggestInjectedProps => {
  return {
    token: userStore.authToken,
    units: parameterStore.units
  };
};

export const UnitSuggest = connect(mapStateToProps, {})(UnitSuggestComponent);
