import {
  Alignment,
  Button,
  Classes,
  H6,
  ICardProps,
  Icon,
  Intent,
  IconName
} from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import { Dictionary } from "lodash";
import * as React from "react";
import AnimateHeight from "react-animate-height";

import DOMPurify from "dompurify";
import {
  changeAlgoVar,
  IChangeVarPayload,
  setLightboxSource
} from "src/actions";
import {
  AlgoNode,
  AlgoNodeInfoType,
  AlgoNodeType,
  Algorithm,
  CardAttributes,
  commentCountForNode,
  IAlgoNodeTarget,
  Parameter,
  valsToArrayResolvingDiscretes,
  CommentKind,
  AlgorithmStatus
} from "src/api";
import { ParameterInput } from "src/components";
import { InfoTabType, IOpenAlgorithm, ISelectionData } from "src/store";
import {
  nodeTitleTextHtml,
  nodeTitleTextPlain,
  sortAlphabetically
} from "src/utilities";
import { NodeInfoPane } from "./node-info-pane";

export interface INodeCardProps extends ICardProps {
  allAlgorithms?: Dictionary<Algorithm>;
  className?: string;
  changeAlgoVar: typeof changeAlgoVar;
  column: number;
  handleClick: (node: AlgoNode) => void;
  handleComments: (node: AlgoNode) => void;
  handleInfo: (node: AlgoNode, focus: InfoTabType) => void;
  setLightboxSource: typeof setLightboxSource;
  isLast: boolean;
  nodeDetails: CardAttributes;
  openAlgo: IOpenAlgorithm;
  row: number;
  selection?: ISelectionData;
  width?: number;
}

export class NodeCard extends React.PureComponent<INodeCardProps> {
  public frame: ClientRect | DOMRect | null = null;

  public render() {
    const {
      className,
      column,
      isLast,
      nodeDetails: { compactLayout, multiOptionRange, node },
      row,
      style,
      width
    } = this.props;

    const hideCardBorder = multiOptionRange || node.isContained();
    const nodeStyle = !hideCardBorder
      ? {
          borderBottomLeftRadius: 0,
          borderBottomRightRadius: 0,
          borderTopLeftRadius: 10,
          borderTopRightRadius: 10
        }
      : undefined;

    const refHandler = (ref: HTMLElement | null) => {
      if (ref) {
        this.frame = ref.getBoundingClientRect();
      }
    };
    const styleProps = {
      ...style,
      ...nodeStyle,
      width
    };
    const combinedClass = () =>
      `overflow-hidden ${className} pr1 pl3 ${
        hideCardBorder ? "pv1" : "zx-card-shadow"
      } ${compactLayout ? "" : "pt3"}`;

    const multiOption = multiOptionRange
      ? this.renderMultiOption(this.props)
      : this.renderCard(this.props);

    return (
      <article
        id={`${column}-${row}`}
        ref={refHandler}
        className={combinedClass()}
        style={styleProps}
      >
        {multiOption}
        {isLast && <div style={{ height: "300px" }} />}
      </article>
    );
  }

  private renderMultiOption = (props: INodeCardProps) => {
    const {
      openAlgo: algorithm,
      allAlgorithms,
      className,
      nodeDetails: { node, multiOptionRange, selected },
      handleClick
    } = props;

    const weightingLabel = () => <span>{multiOptionRange}</span>;
    const onClick = () => {
      handleClick(node);
    };

    const text =
      node.algorithmId === algorithm.algorithm.id
        ? nodeTitleTextPlain(node)
        : `Go to algorithm '${allAlgorithms &&
            allAlgorithms[node.algorithmId].title}`;

    return (
      <section>
        <div className="pv1 flex justify-between items-start">
          <Button
            className="mw6 w-80 zx-wrap-text"
            disabled={!selected}
            alignText={Alignment.LEFT}
            text={text}
            onClick={onClick}
            rightIcon={this.caretIcon(false)}
            icon={weightingLabel()}
          />
          {this.renderInfoCommentsButtons(node, className)}
        </div>
        {this.renderInfoPane(node, algorithm, className)}
      </section>
    );
  };

  private renderInfoPane = (
    node: AlgoNode,
    openAlgorithm: IOpenAlgorithm,
    className?: string
  ) => {
    const { activeInfoNodeId, infoNodePanelFocus } = openAlgorithm;
    const handleTabChange = (newTab: InfoTabType) => {
      if (newTab === "comments") {
        this.props.handleComments(node);
      } else {
        this.props.handleInfo(node, newTab);
      }
    };

    const isVisible = activeInfoNodeId && node.id === activeInfoNodeId;

    const classes = `${
      className && className.includes("bg-white") ? "" : Classes.DARK
    }`;

    return (
      <AnimateHeight duration={300} height={isVisible ? "auto" : 0}>
        <NodeInfoPane
          className={classes}
          width={this.props.width}
          node={node}
          focus={infoNodePanelFocus}
          openAlgorithm={openAlgorithm}
          onTabChange={handleTabChange}
        />
      </AnimateHeight>
    );
  };

  private renderCard = ({
    nodeDetails: { compactLayout, node, expanded, nextTarget },
    openAlgo,
    className,
    handleClick
  }: INodeCardProps) => {
    const { activeInfoNodeId, algoNodes } = openAlgo;
    const showTitle = node.kind !== AlgoNodeType.sharedText;

    // Always expand if they are looking at comments / info on a node or is a text node
    const renderExpanded =
      !showTitle ||
      expanded ||
      (activeInfoNodeId !== undefined && node.id === activeInfoNodeId);

    const { titleHtml, theRestHtml } = nodeTitleTextHtml(node);

    const expandable =
      theRestHtml.length > 1 &&
      ![
        AlgoNodeType.page,
        AlgoNodeType.singleSelect,
        AlgoNodeType.multiSelect
      ].includes(node.kind);

    const onClick = (e: React.MouseEvent) => {
      e.preventDefault();
      e.stopPropagation();
      if (node.kind !== AlgoNodeType.multiSelect) {
        handleClick(node);
      }
    };

    const restHtmlSani = DOMPurify.sanitize(theRestHtml);
    const titleHtmlSani = DOMPurify.sanitize(titleHtml);

    const nodeTitle = showTitle && (
      <div
        className={`${compactLayout ? "" : "pb2"} pr1 f6 ttu`}
        dangerouslySetInnerHTML={{ __html: titleHtmlSani }}
      />
    );

    const combinedClasses = `${
      className && className.includes("bg-white") ? "" : Classes.DARK
    } ${expandable && "pointer"} flex justify-between items-start w-100`;

    const hasChoiceSection = [
      AlgoNodeType.singleSelect,
      AlgoNodeType.multiSelect
    ].includes(node.kind);
    const imageStyle: React.CSSProperties = {
      width: 150,
      height: 150,
      objectFit: "scale-down"
    };

    const showLightbox = () => {
      if (node.image) {
        this.props.setLightboxSource({
          caption: node.title,
          media: node.image
        });
      }
    };
    const renderNextButton =
      node.kind === AlgoNodeType.page &&
      node
        .contained(algoNodes)
        .filter(n => n.node && n.node.kind === AlgoNodeType.varInput).length ===
        0;

    const nodeImage = node.image && (
      <img
        alt=""
        onClick={showLightbox}
        src={node.image.thumb}
        style={imageStyle}
      />
    );

    const choicesSection = hasChoiceSection && (
      <section className="pb4">{this.renderChoices(this.props)}</section>
    );
    const inputs =
      node.kind === AlgoNodeType.varInput && this.renderVarInputs(this.props);
    const answers = node.kind === AlgoNodeType.multiSelect && (
      <H6 className="ttu">Answers</H6>
    );
    const variableValues =
      node.kind === AlgoNodeType.terminal &&
      this.renderVariableValues(node, openAlgo);
    const nextButton =
      renderNextButton &&
      this.renderNextButton(true, handleClick, node, nextTarget);

    return (
      <section
        className={`${compactLayout ? "zx-compact-card-layout" : "mv2"}`}
      >
        <div
          onClick={expandable ? onClick : undefined}
          className={combinedClasses}
        >
          {nodeTitle}
          {this.renderInfoCommentsButtons(node, className)}
        </div>
        {nodeImage}
        <AnimateHeight duration={300} height={renderExpanded ? "auto" : 0}>
          <p
            className={`${
              compactLayout ? "zx-compact-card-layout" : "pb2"
            } overflow-hidden pr2`}
            dangerouslySetInnerHTML={{ __html: restHtmlSani }}
          />
        </AnimateHeight>

        {this.renderInfoPane(node, openAlgo, className)}
        {choicesSection}
        {inputs}
        {answers}
        {variableValues}
        {nextButton}
      </section>
    );
  };

  private renderVariableValues = (node: AlgoNode, openAlgo: IOpenAlgorithm) => {
    const {
      algorithm: { localVars, variables },
      algoState: { decisionsJson }
    } = openAlgo;

    const combinedVariables = localVars
      ? [...variables, ...Object.values(localVars)]
      : variables;
    const outputVars = combinedVariables
      .filter(v => v.output === true)
      .sort((a, b) => sortAlphabetically(a.title, b.title));

    if (outputVars.length === 0) {
      return null;
    }
    const rendererMapper = (param: Parameter, idx: number) => {
      const vals = valsToArrayResolvingDiscretes(param, decisionsJson);

      return (
        <div key={idx} className="flex flex-row">
          <strong>{param.title}</strong>
          <span className="pl2">{vals.values.join(" / ")}</span>
        </div>
      );
    };

    return <section>{outputVars.map(rendererMapper)}</section>;
  };

  private renderInfoCommentsButtons = (node: AlgoNode, className?: string) => {
    if ([AlgoNodeType.sharedText].includes(node.kind)) {
      return null;
    }

    const {
      handleComments,
      handleInfo,
      openAlgo: { algorithm, infoNodePanelFocus, activeInfoNodeId }
    } = this.props;
    const onClick = (e: React.MouseEvent<HTMLElement>) => {
      e.stopPropagation();
      handleComments(node);
    };
    const onInfo = (e: React.MouseEvent<HTMLElement>) => {
      e.stopPropagation();
      handleInfo(node, AlgoNodeInfoType.info);
    };

    const icon = (name: IconName) => {
      const iconColor =
        className && className.includes("bg-white") ? undefined : "white";
      return <Icon icon={name} color={iconColor} />;
    };
    const infoActive =
      node.id === activeInfoNodeId && infoNodePanelFocus !== "comments";

    const infoButton = (
      <Button
        style={{ flexShrink: 0, marginTop: -6 }}
        minimal={true}
        active={infoActive}
        text=""
        title="Info"
        icon={icon(IconNames.INFO_SIGN)}
        onClick={onInfo}
      />
    );

    const commentType = [
      AlgorithmStatus.published,
      AlgorithmStatus.superceded
    ].includes(algorithm.status)
      ? CommentKind.Comment
      : CommentKind.Note;

    const commentsActive =
      node.id === activeInfoNodeId && infoNodePanelFocus === "comments";

    return (
      <section className="flex">
        {node.hasInfo() && infoButton}
        <Button
          style={{ flexShrink: 0, marginTop: -6 }}
          minimal={true}
          active={commentsActive}
          text={`${commentCountForNode(node, commentType)}`}
          title="Comments"
          icon={icon(IconNames.COMMENT)}
          onClick={onClick}
        />
      </section>
    );
  };

  private renderChoices = (props: INodeCardProps) => {
    const {
      nodeDetails: { options }
    } = props;

    return (
      <section className="flex flex-column w-100">
        {options.map(o => this.renderOption(o, props))}
      </section>
    );
  };

  private renderOption = (
    o: IAlgoNodeTarget,
    {
      openAlgo: algorithm,
      className,
      nodeDetails: { node },
      handleClick,
      selection
    }: INodeCardProps
  ) => {
    const on = o.node;
    if (!on) {
      return;
    }

    const onClick = (e: React.MouseEvent<HTMLElement>) => {
      e.stopPropagation();
      e.preventDefault();
      handleClick(on);
    };
    const selected =
      selection && selection.selection.includes(on.id) ? true : false;

    const iconSelector = () => {
      if (node.kind === AlgoNodeType.multiSelect) {
        return (
          <Icon
            intent={selected ? Intent.PRIMARY : Intent.NONE}
            icon={selected ? IconNames.TICK_CIRCLE : IconNames.CIRCLE}
          />
        );
      }
      return undefined;
    };

    const caretIcon = () =>
      node.kind !== AlgoNodeType.multiSelect ? (
        this.caretIcon(selected)
      ) : (
        <span>{on.weight}</span>
      );

    return (
      <section key={on.id}>
        <div className="pv1 flex justify-between items-start">
          <Button
            className="mw6 w-80 zx-wrap-text"
            active={node.kind === AlgoNodeType.singleSelect ? selected : false}
            intent={selected ? Intent.SUCCESS : Intent.NONE}
            fill={true}
            alignText={Alignment.LEFT}
            text={nodeTitleTextPlain(on)}
            onClick={onClick}
            rightIcon={caretIcon()}
            icon={iconSelector()}
          />
          {this.renderInfoCommentsButtons(on, className)}
        </div>
        {this.renderInfoPane(on, algorithm, className)}
      </section>
    );
  };

  private renderVarInputs = (props: INodeCardProps) => {
    const {
      handleClick,
      nodeDetails: { nextTarget, node, options },
      openAlgo: {
        algorithm: { variables, localVars },
        variableValues
      }
    } = props;

    const combinedVariables = localVars
      ? [...variables, ...Object.values(localVars)]
      : variables;

    const isValid =
      options.filter(o => {
        const deets = o.path.paramsJson && o.path.paramsJson[0];
        if (deets && deets.id) {
          const val = variableValues[deets.id];
          if (val) {
            if (deets.groupNumber !== undefined) {
              const sub = val.values[deets.groupNumber];
              if (sub !== undefined) {
                return false;
              }
            } else {
              const param = combinedVariables[deets.id];
              if (param && param.paramsJson.length > 1) {
                return Object.keys(val.values).reduce<boolean>((prev, curr) => {
                  if (!prev) {
                    return !(val.values[curr] !== undefined);
                  }
                  return prev;
                }, false);
              } else {
                // GroupNumber inferred to 0
                const sub = val.values[0];
                if (sub !== undefined) {
                  return false;
                }
              }
            }
          }
        }
        return true;
      }).length === 0;

    return (
      <section className="flex flex-column w-100">
        {options.map(o => this.renderVarInput(o, props, combinedVariables))}
        {this.renderNextButton(isValid, handleClick, node, nextTarget)}
      </section>
    );
  };

  private renderNextButton = (
    isValid: boolean,
    handleClick: (node: AlgoNode) => void,
    node: AlgoNode,
    nextTarget?: AlgoNode
  ) => {
    const onClick = (e: React.MouseEvent<HTMLElement>) => {
      e.stopPropagation();
      e.preventDefault();
      handleClick(node);
    };
    const nextButton = nextTarget && (
      <Button disabled={!isValid} text="Next" onClick={onClick} />
    );

    return <div className="ph2 flex flex-row justify-end">{nextButton}</div>;
  };

  private renderVarInput = (
    varIn: IAlgoNodeTarget,
    { openAlgo, className }: INodeCardProps,
    combinedVariables: Parameter[]
  ) => {
    const {
      node,
      path: { paramsJson }
    } = varIn;
    const { algorithm, variableValues } = openAlgo;

    if (!paramsJson || !paramsJson[0] || !node) {
      return null;
    }
    const varId = paramsJson[0].id;

    const parameter = combinedVariables.find(v => v.id === varId);
    if (!parameter) {
      return null;
    }
    const parameterIndex = paramsJson[0].groupNumber;

    const handleChange = (details: IChangeVarPayload) => {
      this.props.changeAlgoVar({ ...details, algoId: algorithm.id });
    };

    const storedVals = variableValues[parameter.id];
    const values = storedVals ? storedVals.values : {};
    const parameterExplainText =
      varIn.node?.title && varIn.node.title.trim().length > 0 ? (
        <div className="pt2">{varIn.node.title}</div>
      ) : (
        undefined
      );

    return (
      <section key={node.id}>
        <div className="flex justify-between items-start">
          <div className="flex flex-column flex-auto">
            {parameterExplainText}
            <ParameterInput
              className={`${className} w-80 mw6 zx-wrap-text`}
              parameter={parameter}
              parameterIndex={parameterIndex}
              onChange={handleChange}
              showTitle={true}
              showUnits={true}
              values={values}
            />
          </div>
          {this.renderInfoCommentsButtons(node, className)}
        </div>
        {this.renderInfoPane(node, openAlgo, className)}
      </section>
    );
  };

  private caretIcon = (selected: boolean) => (
    <Icon
      icon={IconNames.CARET_RIGHT}
      color={selected ? "white" : "var(--app-orange)"}
      iconSize={Icon.SIZE_LARGE}
    />
  );
}
