import { Button, Classes, Dialog, Intent } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import * as React from "react";
import { Dictionary } from "lodash";
import { connect } from "react-redux";

import { IStoreState } from "src/store";
import { Study, User, updateArray, removeFromArray, Parameter } from "src/api";
import { clearStudyError } from "src/actions";
import { ParameterSuggest } from "src/components";

interface IAddParametersDialogProps {
  isOpen: boolean;
  study: Study;
  onClose: (parameters?: Parameter[]) => void;
}

interface IAddParametersDialogInjectedProps {
  error?: Error;
  loggedInUser?: User;
  studies: Dictionary<Study>;
}

interface IAddParametersDispatchProps {
  clearStudyError: typeof clearStudyError;
}

interface IAddParametersDialogComponentState {
  paramsToAdd: Parameter[];
  busy: boolean;
}

type AddParametersDialogProps = IAddParametersDialogProps &
  IAddParametersDialogInjectedProps &
  IAddParametersDispatchProps;

class AddParametersDialogComponent extends React.PureComponent<
  AddParametersDialogProps,
  IAddParametersDialogComponentState
> {
  constructor(props: AddParametersDialogProps) {
    super(props);

    this.state = {
      paramsToAdd: [],
      busy: false
    };
    this.props.clearStudyError();
  }

  public componentDidUpdate(prevProps: AddParametersDialogProps) {
    const { isOpen } = this.props;

    if (!prevProps.isOpen && isOpen) {
      this.setState({ paramsToAdd: [] });
    }
  }

  public render() {
    const { isOpen } = this.props;

    return (
      <Dialog
        icon={IconNames.VARIABLE}
        title="Add Study Variables"
        isOpen={isOpen}
        onClose={this.closeDialog}
      >
        {this.renderBody()}
        {this.renderFooter()}
      </Dialog>
    );
  }

  private renderFooter = () => {
    const { error } = this.props;

    const errorRenderer = (e: Error) => (
      <span className="mh2 orange">{e.message}</span>
    );
    const handleCancel = () => this.props.onClose();
    const handleAdd = () => this.props.onClose(this.state.paramsToAdd);

    return (
      <div className={`${Classes.DIALOG_FOOTER}`}>
        {error && errorRenderer(error)}
        <div className="flex flex-row justify-between pt2">
          <Button id="cancel" text="Cancel" onClick={handleCancel} />
          <Button
            disabled={this.addButtonDisabled()}
            intent={Intent.SUCCESS}
            text="Add to Study"
            onClick={handleAdd}
          />
        </div>
      </div>
    );
  };

  private addButtonDisabled = () => this.state.paramsToAdd.length === 0;

  private renderBody = () => {
    const { paramsToAdd } = this.state;

    const handleChange = (algo: Parameter) => {
      this.setState({ paramsToAdd: updateArray(this.state.paramsToAdd, algo) });
    };

    return (
      <section className={`${Classes.DIALOG_BODY} overflow-y-auto`}>
        Select variables to add to the study:
        <div className="w-50 pv2">
          <ParameterSuggest
            className="br2 zx-input-blue-border"
            value={undefined}
            onChange={handleChange}
          />
        </div>
        <section className="w-100 flex b bb b--gray pb1 mb1">
          <div className="fl w-50 pt2 pa1">Name</div>
          <div className="fl w-50 pt2 pa1">Type</div>
        </section>
        <div>{paramsToAdd.map(this.renderAlgoRow)}</div>
      </section>
    );
  };

  private renderAlgoRow = (param: Parameter, index: number) => {
    const removeRef = () => {
      this.setState({
        paramsToAdd: removeFromArray(this.state.paramsToAdd, param.id)
      });
    };

    return (
      <div key={index} className="flex flex-row w-100">
        <div className="fl w-50 pa1">{param.title}</div>
        <div className="fl w-50 pa1 flex flex-row justify-between">
          <div>
            {param.detailsJson.length !== 1
              ? "Group"
              : param.detailsJson[0].type}
          </div>
          <Button icon={IconNames.REMOVE} minimal={true} onClick={removeRef} />
        </div>
      </div>
    );
  };

  private closeDialog = async () => {
    this.props.onClose(this.state.paramsToAdd);
  };
}

const mapStateToProps = ({ studiesStore, userStore }: IStoreState) => {
  return {
    error: studiesStore.error,
    loggedInUser: userStore.loggedInUser,
    studies: studiesStore.studies
  };
};

export const AddParametersDialog = connect(mapStateToProps, {
  clearStudyError
})(AddParametersDialogComponent);
