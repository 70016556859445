import DOMPurify from "dompurify";
import marked from "marked";
import moment from "moment";
import * as React from "react";

import { AlgoNode } from "src/api";

type NodeTextParts = {
  mainTitle: string;
  title: string;
  theRest: string;
};

DOMPurify.setConfig({ ADD_ATTR: ["target"], USE_PROFILES: { html: true } });
// DOMPurify.addHook("uponSanitizeElement", (node, data) => {
//   if (node.tagName === "table") {
//     node.setAttribute("cellSpacing", "0");
//     node.setAttribute("border", "1");
//   }
//   return node;
// });

const customRenderer = new marked.Renderer();
// Force HTML links to fresh page.
customRenderer.link = (href: string, title: string, text: string) => {
  return `<a target="_blank" href="${href}">${text}</a>`;
};

marked.setOptions({
  gfm: true,
  renderer: customRenderer
});

export const nodeTitleTextParts = (node: AlgoNode): NodeTextParts => {
  const lines = node.title.split("\n");
  let title = lines[0] || "";
  const secondLine = lines.length > 1 ? lines[1] : undefined;
  const isDecision = node.isDecision();
  const firstLineLength = lines[0] ? lines[0].length + 1 : 0;
  const secondLineLength = lines[1] ? lines[1].length + 1 : 0;
  const theRest = node.title.slice(
    isDecision ? firstLineLength + secondLineLength : firstLineLength
  );

  let mainTitle = "Please add a title for this node";
  if (isDecision && lines.length > 0) {
    mainTitle = title;
    title = secondLine ? secondLine : "Please add a node question";
  }

  return { title, mainTitle, theRest };
};

const htmlEscapeToText = (text: string) => {
  return text.replace(new RegExp("&.*?;", "g"), matchedText => {
    if (matchedText.match(/amp/)) {
      return "&";
    }
    if (matchedText.match(/gt/)) {
      return ">";
    }
    if (matchedText.match(/lt/)) {
      return "<";
    }

    const charCode = matchedText.match(/[0-9]+/);
    if (charCode && charCode.length > 0) {
      return String.fromCharCode(parseInt(charCode[0], 10));
    }
    return matchedText;
  });
};

export const markdownStripper = () => {
  const kListTag = "<zx-li>";
  const renderer = new marked.Renderer();
  renderer.code = text => text;
  renderer.blockquote = text => htmlEscapeToText(text);
  renderer.html = text => text;
  renderer.heading = text => htmlEscapeToText(text);
  renderer.hr = () => "\n\n";
  renderer.list = (text, ordered, start) => {
    let i = start;
    do {
      text = text.replace(kListTag, `${ordered ? `${i}.` : "•"}`);
      i++;
    } while (text.indexOf(kListTag) > -1);
    return htmlEscapeToText(text + "\n");
  };
  renderer.listitem = itemText => htmlEscapeToText(`\n${kListTag} ${itemText}`);
  renderer.paragraph = text => "\n" + htmlEscapeToText(text);
  // renderer.table = (header, body): string => "";
  // renderer.tablerow = (content): string => "";
  // renderer.tablecell = (content, { header, align }): string => "";
  renderer.strong = text => htmlEscapeToText(text);
  renderer.em = text => htmlEscapeToText(text);
  renderer.codespan = text => htmlEscapeToText(text);
  renderer.br = () => "\t\n";
  renderer.del = text => htmlEscapeToText(text);
  renderer.link = (href, title, text) => text;
  renderer.image = () => "(image)";
  renderer.text = text => htmlEscapeToText(text);

  return renderer;
};

export const nodeTextPartsPlain = (node: AlgoNode): NodeTextParts => {
  const parts = nodeTitleTextParts(node);
  return {
    mainTitle: marked(parts.mainTitle, {
      renderer: markdownStripper()
    }),
    theRest: marked(parts.theRest, {
      renderer: markdownStripper()
    }),
    title: marked(parts.title, {
      renderer: markdownStripper()
    })
  };
};

export const nodeTitleTextHtml = (node: AlgoNode) => {
  const { title, theRest } = nodeTitleTextParts(node);

  return { titleHtml: marked(title), theRestHtml: marked(theRest) };
};

export const nodeTitleTextPlain = (node: AlgoNode) => {
  const { title } = nodeTitleTextParts(node);
  return marked(title, {
    renderer: markdownStripper()
  });
};

export const nodeDecisionTextPlain = (node: { title: string }) => {
  const decisionTitle = node.title.split("\n", 1)[0];
  return marked(decisionTitle, {
    renderer: markdownStripper()
  });
};

export const stripMd = (text?: string) => {
  if (!text) {
    return "";
  }

  return marked(text, {
    renderer: markdownStripper()
  });
};

export const highlightText = (text: string, query: string) => {
  const escapeRegExpChars = (t: string) => {
    // ESLINT gets it wrong...
    // eslint-disable-next-line no-useless-escape
    return t.replace(/([.*+?^=!:${}()|\[\]\/\\])/g, "\\$1");
  };
  let lastIndex = 0;

  const words = query
    .split(/\s+/)
    .filter(word => word.length > 0)
    .map(escapeRegExpChars);
  if (words.length === 0) {
    return [text];
  }
  const regexp = new RegExp(words.join("|"), "gi");
  const tokens: React.ReactNode[] = [];
  while (true) {
    const match = regexp.exec(text);
    if (!match) {
      break;
    }
    const length = match[0].length;
    const before = text.slice(lastIndex, regexp.lastIndex - length);
    if (before.length > 0) {
      tokens.push(before);
    }
    lastIndex = regexp.lastIndex;
    tokens.push(<strong key={lastIndex}>{match[0]}</strong>);
  }
  const rest = text.slice(lastIndex);
  if (rest.length > 0) {
    tokens.push(rest);
  }
  return tokens;
};

export const truncateString = (value: string, characterCount = 40) => {
  if (value.length > characterCount) {
    return value.slice(0, characterCount) + "...";
  } else {
    return value;
  }
};

export const sortAlphabetically = (a: string, b: string) => {
  const al = a.toLocaleLowerCase();
  const bl = b.toLocaleLowerCase();
  if (al === bl) {
    return 0;
  } else if (al > bl) {
    return 1;
  }
  return -1;
};

export const dateRenderer = (d: Date) => moment(d).format("MMM Do YYYY");
export const dateParser = (s: string) => moment(s, "MMM Do YYYY").toDate();
