import { Button, H4, Icon, Tab, Tabs } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import * as React from "react";
import { connect } from "react-redux";
import { destroy, getFormMeta, isDirty, submit } from "redux-form";

import { arraysEqual } from "@blueprintjs/core/lib/esm/common/utils";
import { setEditState, updateAlgo } from "src/actions";
import { Algorithm, arrayKeyIsEqual } from "src/api";
import { AlgoPanelFocus, IOpenAlgorithm, IStoreState } from "src/store";
import { AlgoEditForm } from "./algo-edit-form";
import { AlgoEditRefsForm } from "./algo-edit-refs-form";

export interface IAlgoEditorProps {
  openAlgorithm: IOpenAlgorithm;
}

interface IAlgoEditorInjectedProps {
  dirty: boolean;
  focusedField?: string;
}
interface IAlgoEditorDispatchProps {
  setEditState: typeof setEditState;
  submit: typeof submit;
  updateAlgo: typeof updateAlgo;
}

interface IAlgoEditorState {
  focusedPanel: AlgoPanelFocus;
}

type AlgoInfoEditorProps = IAlgoEditorProps &
  IAlgoEditorInjectedProps &
  IAlgoEditorDispatchProps;

class AlgoInfoEditorComponent extends React.PureComponent<
  AlgoInfoEditorProps,
  IAlgoEditorState
> {
  constructor(props: AlgoInfoEditorProps) {
    super(props);
    const {
      openAlgorithm: {
        editingState: { algoInfoPanel }
      }
    } = props;

    this.state = {
      focusedPanel: algoInfoPanel || "info"
    };
  }

  public componentDidUpdate(oldProps: AlgoInfoEditorProps) {
    const {
      openAlgorithm: {
        editingState: { algoInfoPanel }
      }
    } = this.props;
    const {
      openAlgorithm: {
        editingState: { algoInfoPanel: oldPanelState }
      }
    } = oldProps;
    const { focusedPanel } = this.state;

    if (
      algoInfoPanel &&
      algoInfoPanel !== oldPanelState &&
      focusedPanel !== algoInfoPanel
    ) {
      this.setState({ focusedPanel: algoInfoPanel });
    }
  }

  public render() {
    const { openAlgorithm, focusedField } = this.props;
    const { focusedPanel } = this.state;

    const noop = () => {
      // Handled in this.handleSubmit
    };

    const handleTabChange = (newTab: AlgoPanelFocus) => {
      this.setState({ focusedPanel: newTab });
    };

    const infoPanel = (
      <AlgoEditForm
        algorithm={openAlgorithm.algorithm}
        className="pa2"
        form={openAlgorithm.algorithm.id}
        destroyOnUnmount={false}
        initialValues={openAlgorithm.algorithm}
        onSubmit={noop}
      />
    );
    const refsPanel = (
      <AlgoEditRefsForm
        className="pa2"
        focusedField={focusedField}
        form={openAlgorithm.algorithm.id}
        destroyOnUnmount={false}
        initialValues={openAlgorithm.algorithm}
        onSubmit={this.handleSubmit}
        openAlgorithm={openAlgorithm}
        setEditState={this.props.setEditState}
      />
    );

    return (
      <article className="flex flex-column flex-auto animated fadeIn faster zx-shadow bg-white mt1 pa2 br2 overflow-y-auto">
        <div
          className="flex flex-row justify-between pa2"
          style={{ flexShrink: 0 }}
        >
          <div className="flex flex-row">
            <Icon
              className="mr2"
              iconSize={Icon.SIZE_LARGE}
              icon={IconNames.INFO_SIGN}
            />
            <H4>Algorithm Info</H4>
          </div>
          <Button
            icon={IconNames.FLOPPY_DISK}
            text="Save"
            minimal={true}
            onClick={this.handleClose}
          />
        </div>
        <Tabs
          className="ph2 flex-auto"
          selectedTabId={focusedPanel}
          onChange={handleTabChange}
        >
          <Tab id="info" title="Info" panel={infoPanel} />
          <Tab title="References" id="refs" panel={refsPanel} />
        </Tabs>
      </article>
    );
  }

  private algoChangesExist = (updated: Algorithm) => {
    const {
      openAlgorithm: { algorithm }
    } = this.props;

    const {
      title,
      summary,
      synopsis,
      epidemiology,
      keywords,
      authors,
      editors,
      references,
      regions,
      medicalSpecialties,
      variables
    } = algorithm;

    if (
      title !== updated.title ||
      summary !== updated.summary ||
      synopsis !== updated.synopsis ||
      epidemiology !== updated.epidemiology
    ) {
      return true;
    }

    if (
      !arrayKeyIsEqual(keywords, updated.keywords) ||
      !arrayKeyIsEqual(medicalSpecialties, updated.medicalSpecialties) ||
      !arrayKeyIsEqual(authors, updated.authors) ||
      !arrayKeyIsEqual(editors, updated.editors) ||
      !arraysEqual(references, updated.references) ||
      !arrayKeyIsEqual(regions, updated.regions) ||
      !arrayKeyIsEqual(variables, updated.variables)
    ) {
      return true;
    }

    return false;
  };

  private handleSubmit = (updated: Algorithm) => {
    const { dirty, openAlgorithm } = this.props;

    if (dirty && this.algoChangesExist(updated)) {
      // Push the changes into redux
      this.props.updateAlgo(updated);
    }

    // Cleanup the form
    destroy(openAlgorithm.algorithm.id);
    this.props.setEditState({
      algoId: openAlgorithm.algorithm.id,
      state: { algoInfoPanel: undefined }
    });
  };

  private handleClose = () => {
    const { openAlgorithm } = this.props;

    // Submit the redux-form
    this.props.submit(openAlgorithm.algorithm.id);
  };
}

const mapStateToProps = (state: IStoreState, props: IAlgoEditorProps) => {
  const { openAlgorithm } = props;
  const dirty = isDirty(`${openAlgorithm.algorithm.id}`)(state);
  const formMeta = getFormMeta(`${openAlgorithm.algorithm.id}`)(state);

  let focusedField;
  const checker = (k: string, val: any) => {
    if (val && val.active === true) {
      focusedField = k;
    }
  };

  Object.keys(formMeta).forEach(k => {
    const val = formMeta[k];
    if (val.constructor === Array) {
      val.forEach((v: any, j: number) => {
        checker(`${k}${j}`, v);
      });
    } else {
      checker(k, val);
    }
  });

  return {
    dirty,
    focusedField,
    formMeta
  };
};

export const AlgoInfoEditor = connect(mapStateToProps, {
  setEditState,
  submit,
  updateAlgo
})(AlgoInfoEditorComponent);
