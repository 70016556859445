import { Button, Label, MenuItem } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import { ItemRenderer, Select } from "@blueprintjs/select";
import * as React from "react";

import {
  AlgoNodeType,
  INodeTypeLookup,
  textForNodeType,
  visibleNodeTypes
} from "src/api";

export interface INodeTypeSelectProps {
  disabled?: boolean;
  onChange: (value: AlgoNodeType) => void;
  value: AlgoNodeType;
}

const NodeTypeSelectComponent = Select.ofType<INodeTypeLookup>();

export class NodeTypeSelect extends React.PureComponent<INodeTypeSelectProps> {
  public render() {
    const { disabled, value } = this.props;
    const items = visibleNodeTypes().filter(i => i.type !== value);

    return (
      <Label className="w-100">
        <p className="ttu zx-edit-grey">Node Type</p>
        <NodeTypeSelectComponent
          filterable={false}
          items={items}
          itemRenderer={this.itemRenderer}
          onItemSelect={this.onSelect}
          popoverProps={{ minimal: true }}
        >
          <Button
            disabled={disabled}
            rightIcon={IconNames.CARET_DOWN}
            text={textForNodeType(value)}
          />
        </NodeTypeSelectComponent>
      </Label>
    );
  }

  private onSelect = (item: INodeTypeLookup) => {
    this.props.onChange(item.type);
  };

  private itemRenderer: ItemRenderer<INodeTypeLookup> = (
    { label, type },
    { handleClick, modifiers }
  ) => (
    <MenuItem key={type} {...modifiers} text={label} onClick={handleClick} />
  );
}
