import { Button } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import * as React from "react";
import { change, formValueSelector } from "redux-form";
import { connect } from "react-redux";
import { cloneDeep, Dictionary } from "lodash";

import {
  Algorithm,
  Parameter,
  removeFromArray,
  Study,
  updateArray,
  StudyStatus
} from "src/api";
import { IStoreState } from "src/store";
import { AddParametersDialog } from ".";
import { sortAlphabetically } from "src/utilities";

type ParamWithAlgos = { param: Parameter; algos: Algorithm[] };

interface IEditStudyVariablesProps {
  className?: string;
  study: Study;
}

interface IEditStudyVariablesInjectedProps {
  algoVars: ParamWithAlgos[];
  variables: Parameter[];
}

interface IEditStudyVariablesDispatchProps {
  changeValues: typeof change;
}

interface IEditStudyVariablesState {
  addVarsDialogOpen: boolean;
}

type EditStudyVariablesProps = IEditStudyVariablesProps &
  IEditStudyVariablesInjectedProps &
  IEditStudyVariablesDispatchProps;

class EditStudyVariableComponent extends React.PureComponent<
  EditStudyVariablesProps,
  IEditStudyVariablesState
> {
  constructor(props: EditStudyVariablesProps) {
    super(props);

    this.state = {
      addVarsDialogOpen: false
    };
  }
  public render() {
    const { algoVars, variables, study } = this.props;
    const { addVarsDialogOpen } = this.state;
    const handleClose = (params?: Parameter[]) => {
      if (params) {
        let updated = cloneDeep(study.variables) || [];
        params.forEach(p => (updated = updateArray(updated, p)));
        this.props.changeValues(study.id, "variables", updated);
      }
      this.setState({ addVarsDialogOpen: false });
    };

    return (
      <section>
        <AddParametersDialog
          isOpen={addVarsDialogOpen}
          onClose={handleClose}
          study={study}
        />
        {this.renderVariables(study, variables, algoVars)}
      </section>
    );
  }

  private renderVariables = (
    study: Study,
    parameters: Parameter[],
    algoVars: ParamWithAlgos[]
  ) => {
    const openDialog = () => this.setState({ addVarsDialogOpen: true });
    const hasVars = parameters.length + algoVars.length > 0;

    const editable = study.status !== StudyStatus.approved;
    const editButton = editable && (
      <Button
        minimal={true}
        rightIcon={IconNames.ADD}
        text="Add variables"
        onClick={openDialog}
      />
    );
    return (
      <section>
        <section className="w-100 flex b bb b--gray pb1 mb1">
          <div className="fl w-40 pt2 pa1">Name</div>
          <div className="fl w-20 pt2 pa1">Type</div>
          <div className="fl w-40 flex justify-between">
            <span className="pa1 pt2">Used in</span>
            {editButton}
          </div>
        </section>
        {!hasVars && <span className="zx-blue">No variables added yet</span>}
        {algoVars.length > 0 && (
          <section>{this.renderAlgoVarRows(algoVars, parameters)}</section>
        )}
        {parameters.length > 0 && (
          <section>{this.renderVarRows(study, parameters)}</section>
        )}
      </section>
    );
  };

  private renderVarRows = (study: Study, parameters: Parameter[]) => {
    const {
      study: { id }
    } = this.props;

    return parameters.map(a => {
      const removeRow = () => {
        const updated = removeFromArray(parameters, a.id);
        this.props.changeValues(id, "variables", updated);
      };
      return this.renderVarRow(study, a, removeRow);
    });
  };

  private renderVarRow = (
    study: Study,
    param: Parameter,
    removeParam?: () => void
  ) => {
    const isGroup = param.detailsJson.length !== 1;
    const editable = study.status !== StudyStatus.approved && removeParam;

    return (
      <div key={param.id} className="flex flex-row w-100">
        <div className="fl w-40 pa1">{param.title}</div>
        <div className="fl w-20 pa1">
          {isGroup ? "Group" : param.detailsJson[0].type}
        </div>
        <div className="fl w-40 flex justify-between">
          <div className="pa1">Study Direct</div>
          {editable && (
            <Button
              icon={IconNames.REMOVE}
              minimal={true}
              onClick={removeParam}
            />
          )}
        </div>
      </div>
    );
  };

  private renderAlgoVarRows = (
    algoVars: ParamWithAlgos[],
    parameters: Parameter[]
  ) => {
    return algoVars.map(av => {
      const param = av.param;
      const isGroup = param.detailsJson.length !== 1;
      return (
        <div key={av.param.id} className="flex flex-row w-100">
          <div className="fl w-40 pa1">{param.title}</div>
          <div className="fl w-20 pa1">
            {isGroup ? "Group" : param.detailsJson[0].type}
          </div>
          <div className="fl w-40 flex justify-between">
            <div className="pa1">{av.algos.map(a => a.title).join(", ")}</div>
          </div>
        </div>
      );
    });
  };
}

const mapStateToProps = (
  state: IStoreState,
  { study }: IEditStudyVariablesProps
) => {
  const selector = formValueSelector(study.id);
  const variables = ((selector(state, "variables") ||
    []) as Parameter[]).sort((a, b) => sortAlphabetically(a.title, b.title));

  const varsByAlgo: Dictionary<ParamWithAlgos> = {};
  study.algorithms.forEach(a => {
    a.variables.forEach(v => {
      const varByAlgo = varsByAlgo[v.id];
      if (varByAlgo) {
        varByAlgo.algos.push(a);
      } else {
        varsByAlgo[v.id] = { param: v, algos: [a] };
      }
    });
  });

  const algoVars = Object.values(varsByAlgo).sort((a, b) =>
    sortAlphabetically(a.param.title, b.param.title)
  );

  return {
    algoVars,
    variables
  };
};

export const EditStudyVariables = connect(mapStateToProps, {
  changeValues: change
})(EditStudyVariableComponent);
