import {
  connectRouter,
  RouterState,
  LocationChangeAction
} from "connected-react-router";
import { History } from "history";
import { combineReducers, Reducer, AnyAction } from "redux";
import { reducer as formReducer } from "redux-form";

import { algorithmReducer } from "./algorithm-reducer";
import { eventsReducer } from "./events-reducer";
import { interfaceReducer } from "./interface-reducer";
import { parametersReducer } from "./parameters-reducer";
import { studiesReducer } from "./studies-reducer";
import { userReducer } from "./user-reducer";
import { usersReducer } from "./users-reducer";
import {
  IAlgoStore,
  IEventsStore,
  IUserStore,
  IUsersStore,
  IStudiesStore,
  IParamsStore
} from "src/store";

export * from "./algorithm-updaters/event-queue";

export type IReducers = {
  algoStore: Reducer<IAlgoStore, AnyAction>;
  eventsStore: Reducer<IEventsStore, AnyAction>;
  form: typeof formReducer;
  parameterStore: Reducer<IParamsStore, AnyAction>;
  router: Reducer<RouterState, LocationChangeAction>;
  studiesStore: Reducer<IStudiesStore, AnyAction>;
  uiStore: Reducer<IEventsStore, AnyAction>;
  userStore: Reducer<IUserStore, AnyAction>;
  usersStore: Reducer<IUsersStore, AnyAction>;
};

const createRootReducer = (history: History) =>
  combineReducers<any, any>({
    algoStore: algorithmReducer,
    eventsStore: eventsReducer,
    form: formReducer, // Enable redux-form
    parameterStore: parametersReducer,
    router: connectRouter(history),
    studiesStore: studiesReducer,
    uiStore: interfaceReducer,
    userStore: userReducer,
    usersStore: usersReducer
  });

export default createRootReducer;
