import { Transform, Type } from "class-transformer";
import { cloneDeep } from "lodash";

import { ISelectionData, IVariableDefinition, IVariableValue } from "src/store";
import { notEmpty } from "src/utilities";
import { Algorithm } from "./algorithm";
import { BaseModel } from "./object-base";
import { AlgoNode } from "./algo-node";

export type ParameterWithIndex = Parameter & { valueIndex: number | undefined };

export class Parameter extends BaseModel {
  public creatorId?: string;

  public title = "";

  @Transform(s => s || [{ name: "", type: "boolean" }])
  public detailsJson: IVariableDefinition[] = [{ name: "", type: "boolean" }];

  public approved = false;
  public inUse = false;

  // Context variables
  public output?: boolean;
  public algoLocal?: boolean;

  @Type(() => Algorithm)
  public algorithms?: Algorithm[];

  constructor(title?: string) {
    super();
    if (title) {
      this.title = title;
    }
  }
}

export const variableValue = (
  decisionsJson: ISelectionData[],
  varId: string,
  notNodeId?: string,
  forNodeId?: string
): Array<{ nodeId: string; value: IVariableValue }> => {
  return cloneDeep(
    decisionsJson
      .slice()
      .reverse()
      .map(vu => {
        const value = vu.variableValues[varId];
        return value ? { nodeId: vu.nodeId, value } : undefined;
      })
      .filter(notEmpty)
      .filter(v => v.nodeId !== notNodeId)
      .filter(v => (forNodeId ? v.nodeId === forNodeId : true))
  );
};

export const shortUnitStrings = (param: Parameter) => {
  return param.detailsJson.map(d => (d.unit ? d.unit.shortName : ""));
};

export const valsToArrayResolvingDiscretes = (
  param: Parameter,
  decisionsJson: ISelectionData[],
  notForNode?: AlgoNode
) => {
  // Grab the details for the node shown
  const stored = variableValue(
    decisionsJson,
    param.id,
    undefined,
    notForNode ? notForNode.id : undefined
  )[0];
  if (!stored) {
    return { param, values: [] };
  }

  if (param && param.detailsJson) {
    Object.keys(stored.value.values).forEach(k => {
      const numericKey = parseInt(k, 10);
      const valOfKey = stored.value.values[numericKey];
      if (valOfKey) {
        const paramDetails = param.detailsJson[numericKey];
        if (paramDetails && paramDetails.discreteValues) {
          const valDetails = paramDetails.discreteValues.find(
            dv => dv.id === valOfKey
          );
          if (valDetails) {
            stored.value.values[k] = valDetails.value;
          }
        }
      }
    });
  }

  const storedVals = stored.value.values;
  const highestIndex = Object.keys(storedVals).reduce<number>((prev, cur) => {
    const current = parseInt(cur, 10);
    if (current > prev) {
      return current;
    }
    return prev;
  }, 0);
  const values = [];
  let i = 0;
  const unitsStrings = shortUnitStrings(param);
  while (i < highestIndex + 1) {
    let value = storedVals[i];
    if (value === true) {
      value = "Yes";
    } else if (value === false) {
      value = "No";
    }
    values.push(
      value !== undefined
        ? `${
            param.detailsJson.length > 1 ? `${param.detailsJson[i].name}: ` : ""
          } ${value} ${unitsStrings[i]}`
        : ""
    );
    i++;
  }
  return { param, values };
};
