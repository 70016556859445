import {
  IconName,
  MenuItem,
  Spinner,
  IInputGroupProps
} from "@blueprintjs/core";
import { ItemRenderer, Suggest } from "@blueprintjs/select";
import * as React from "react";
import { connect } from "react-redux";

import { searchLabels } from "src/actions";
import {
  arrayFromPayload,
  createNewLabel,
  Label,
  Label as LabelData,
  makeApiRequest,
  requestConfigForAction,
  ZxLabelType,
  IXmedObjectInfo,
  filterLabels
} from "src/api";
import { IStoreState } from "src/store";
import { highlightText } from "src/utilities";

export interface IKeywordSuggestProps {
  className?: string;
  leftIcon?: IconName;
  onChange: (term?: string) => void;
  token?: string;
  type: ZxLabelType;
}

interface IKeywordSuggestState {
  abortController?: AbortController;
  error?: Error;
  items: LabelData[];
  loading: boolean;
  selectedItem?: LabelData;
  query?: string;
}

class KeywordSuggestComponent extends React.PureComponent<
  IKeywordSuggestProps,
  IKeywordSuggestState
> {
  private debouncer: NodeJS.Timeout | undefined;

  constructor(props: IKeywordSuggestProps) {
    super(props);

    this.state = {
      items: [],
      loading: false,
      query: ""
    };
  }

  public render() {
    const { className, leftIcon } = this.props;
    const { items, selectedItem } = this.state;

    const popoverProps = {
      minimal: true,
      usePortal: true
    };

    const inputProps: IInputGroupProps &
      React.InputHTMLAttributes<HTMLInputElement> = {
      className,
      leftIcon: leftIcon || false,
      placeholder: "Search",
      round: true,
      style: {
        backgroundColor: "var(--search-highlight)"
      }
    };

    return (
      <Suggest<LabelData>
        items={items}
        noResults={this.renderNoResults()}
        itemRenderer={this.itemRenderer}
        inputValueRenderer={this.inputValueRenderer}
        onItemSelect={this.handleSelect}
        selectedItem={selectedItem}
        onQueryChange={this.getLabelsDebounced}
        popoverProps={popoverProps}
        openOnKeyDown={false}
        resetOnQuery={false}
        resetOnClose={false}
        inputProps={inputProps}
      />
    );
  }

  private renderNoResults = () => {
    const { loading } = this.state;
    return loading ? <Spinner size={Spinner.SIZE_SMALL} /> : null;
  };

  private handleSelect = (
    item: LabelData,
    event?: React.SyntheticEvent<HTMLElement>
  ) => {
    // For some reason, we don't get the right value through the item from BP
    if (event && event.nativeEvent instanceof KeyboardEvent) {
      const { query } = this.state;
      this.props.onChange(query);
      this.setState({ query, selectedItem: undefined });
    } else {
      this.props.onChange(item.title);
      this.setState({ query: item.title, selectedItem: item });
    }
  };

  private inputValueRenderer = () => this.state.query || "";

  private itemRenderer: ItemRenderer<LabelData> = (
    { id, title },
    { handleClick, modifiers: { active, disabled, matchesPredicate }, query }
  ) => {
    if (!matchesPredicate) {
      return null;
    }

    return (
      <MenuItem
        active={active}
        disabled={disabled}
        key={id}
        onClick={handleClick}
        text={highlightText(title, query)}
      />
    );
  };

  private getLabelsDebounced = (query: string) => {
    if (this.debouncer) {
      clearTimeout(this.debouncer);
    }
    const { items } = this.state;
    const newItems = filterLabels(items, query);
    if (query.length > 0) {
      this.debouncer = setTimeout(() => this.getLabels(query), 600);
      this.setState({ loading: true, items: newItems });
    } else {
      this.setState({ loading: false, items: [] });
    }
  };

  private getLabels = async (query: string) => {
    const { token } = this.props;
    const { abortController: prevController } = this.state;

    const dummyLabel = createNewLabel(query, ZxLabelType.KEYWORDS);
    const results = [dummyLabel];

    if (prevController) {
      prevController.abort();
    }

    if (query.length === 0) {
      this.setState({ items: [], query });
    } else {
      const newController = new AbortController();
      this.setState({
        abortController: newController,
        error: undefined,
        loading: true,
        query
      });

      try {
        const { type } = this.props;
        const response = await makeApiRequest(
          requestConfigForAction(
            searchLabels.request({ type, searchQuery: query }),
            token,
            newController.signal
          )
        );
        if (response.apiResponse.data) {
          const labels = arrayFromPayload(
            response.apiResponse.data as IXmedObjectInfo[],
            Label
          ).sort((a, b) => {
            const labelA = a.title.toLowerCase();
            const labelB = b.title.toLowerCase();
            if (labelA > labelB) {
              return 1;
            }
            if (labelA < labelB) {
              return -1;
            }
            return 0;
          });
          this.setState({
            abortController: undefined,
            items: results.concat(labels),
            loading: false
          });
        }
      } catch (error) {
        this.setState({ abortController: undefined, loading: false, error });
      }
    }
  };
}

const mapStateToProps = ({ userStore }: IStoreState) => {
  return {
    token: userStore.authToken
  };
};

export const KeywordSuggest = connect(
  mapStateToProps,
  {}
)(KeywordSuggestComponent);
