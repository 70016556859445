import { Type } from "class-transformer";

import { User } from "..";
import { BaseModel } from "./object-base";
import { UserRole } from "./user";

export enum EventCategory {
  Algorithm = "algorithms",
  Comment = "comments",
  Misc = "misc",
  Node = "nodes",
  Path = "paths",
  User = "users",
  Undefined = ""
}

export enum EventType {
  UserInvited = "users-invite",
  UserCreated = "users-create",
  UserEmailVerified = "users-email-verified",
  UserVerified = "users-verified",
  UserUpdated = "users-update",
  UserBlocked = "users-blocked",
  UserFriendRequestConnection = "users-friends-request-connection",
  Other = ""
}

export const eventTypeString = (e: EventType): string => {
  switch (e) {
    case EventType.UserBlocked:
      return "Blocked";
    case EventType.UserCreated:
      return "Created";
    case EventType.UserUpdated:
      return "Updated";
    case EventType.UserVerified:
      return "Verified";

    default:
      return e.toString();
  }
};

export class Event extends BaseModel {
  @Type(() => User)
  public author?: User;
  public refId = "";
  public refType = "";
  public sourceRefId = "";
  public category: EventCategory = EventCategory.Undefined;
  @Type(() => Date)
  public temporal?: Date;

  public kind: EventType = EventType.Other;
  public seen = false;
  public notify = false;
  public thumb?: string;
  public content?: {
    kind: string;
    title: string;
    updatedAt: Date;
    newRole?: UserRole;
  };
}
