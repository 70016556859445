import { Alert, Button, ButtonGroup, Intent } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import { clone } from "lodash";
import * as React from "react";
import { connect } from "react-redux";
import { SortableContainer, SortableElement } from "react-sortable-hoc";

import { removePath, updatePath, linkNodes } from "src/actions";
import {
  AlgoNode,
  AlgoNodePath,
  Algorithm,
  AlgoVariableType,
  detailsForVariableOperator,
  IAlgoNodeTarget,
  VariableOperator,
  VariableType
} from "src/api";
import {
  NodeActionTypeSelect,
  NodeTargetRenderer,
  VariableOperatorSelect,
  VariableTypeSelect
} from "src/components";
import { IOpenAlgorithm, IStoreState } from "src/store";
import { SortHandle } from ".";

export interface IMultichoiceOutputProps {
  target: IAlgoNodeTarget;
  openAlgorithm: IOpenAlgorithm;
}

interface IMultichoiceOutputInjectedProps {
  otherAlgorithms: Algorithm[];
}

interface IMultichoiceOutputDispatchProps {
  linkNodes: typeof linkNodes;
  updatePath: typeof updatePath;
  removePath: typeof removePath;
}

interface IMultichoiceOutputState {
  deleteWarnOpen: boolean;
  variableOperator: VariableOperator;
}

type MultichoiceOutputComponentProps = IMultichoiceOutputProps &
  IMultichoiceOutputInjectedProps &
  IMultichoiceOutputDispatchProps;

class MultichoiceOutputComponent extends React.PureComponent<
  MultichoiceOutputComponentProps,
  IMultichoiceOutputState
> {
  constructor(props: MultichoiceOutputComponentProps) {
    super(props);

    const {
      target: { path }
    } = props;

    this.state = {
      deleteWarnOpen: false,
      variableOperator:
        path.high === path.low
          ? VariableOperator.isEqualTo
          : VariableOperator.between
    };
  }

  public render() {
    const { target, openAlgorithm, otherAlgorithms } = this.props;
    const { algoNodes } = openAlgorithm;
    const { deleteWarnOpen } = this.state;

    const handleConfirm = () => this.setState({ deleteWarnOpen: true });
    const handleCancelDelete = () => this.setState({ deleteWarnOpen: false });

    return (
      <section
        key={target.path.id}
        className="flex flex-row item-start pb2 zx-bg-charcoal-grey"
      >
        <Alert
          canEscapeKeyCancel={true}
          canOutsideClickCancel={true}
          cancelButtonText="Cancel"
          onCancel={handleCancelDelete}
          confirmButtonText="Delete"
          onConfirm={this.handleRemove}
          isOpen={deleteWarnOpen}
          icon={IconNames.TRASH}
          intent={Intent.DANGER}
        >
          <p>Are you sure you wish to remove this output?</p>
        </Alert>
        <SortHandle />
        <div className="flex flex-column flex-auto">
          <div className="flex flex-row items-start justify-between pb1">
            <p className="flex flex-auto yellow pl1">
              if the following are true
            </p>
            <ButtonGroup>
              <Button
                icon={IconNames.REMOVE}
                minimal={true}
                onClick={handleConfirm}
              />
            </ButtonGroup>
          </div>
          {this.renderOutputActions(target, openAlgorithm)}
          <p className="flex flex-auto yellow pl1">then</p>
          <NodeTargetRenderer
            parent={algoNodes[target.path.parentId]}
            target={target}
            openAlgorithm={openAlgorithm}
            otherAlgorithms={otherAlgorithms}
            updatePath={this.props.updatePath}
            linkNodes={this.props.linkNodes}
          />
        </div>
      </section>
    );
  }

  private renderOutputActions = (
    { path }: IAlgoNodeTarget,
    openAlgorithm: IOpenAlgorithm
  ) => {
    const parent = openAlgorithm.algoNodes[path.parentId];
    const variableType = AlgoVariableType.weight;
    const { variableOperator } = this.state;

    const unusedVariableCount = openAlgorithm.algorithm.variables.length;

    const outputAction = this.renderOutputActionHandler(
      path,
      parent,
      openAlgorithm,
      {
        variableOperator,
        variableType
      }
    );

    return (
      <div className="flex flex-row justify-between items-start pb2">
        <div className="flex flex-row flex-auto">
          <NodeActionTypeSelect
            className="pr1"
            nodeType={parent.kind}
            variableActions={unusedVariableCount > 0}
          />
          {outputAction}
        </div>
        <ButtonGroup style={{ visibility: "hidden" }}>
          <Button icon={IconNames.REMOVE} minimal={true} />
        </ButtonGroup>
      </div>
    );
  };

  private renderOutputActionHandler = (
    path: AlgoNodePath,
    parent: AlgoNode,
    openAlgorithm: IOpenAlgorithm,
    {
      variableType,
      variableOperator
    }: { variableType: AlgoVariableType; variableOperator: VariableOperator }
  ) => {
    const operatorDetails = detailsForVariableOperator(variableOperator);
    const values = [path.low, path.high];
    const updaters = [
      (e: React.ChangeEvent<HTMLInputElement>) => {
        this.updatePath({ low: parseInt(e.target.value, 10) });
      },
      (e: React.ChangeEvent<HTMLInputElement>) => {
        this.updatePath({ high: parseInt(e.target.value, 10) });
      }
    ];

    const handleOperatorChange = (opType: VariableOperator) => {
      if (opType === VariableOperator.isEqualTo) {
        this.updatePath({ low: path.low, high: path.low });
      } else if (opType === VariableOperator.between) {
        if (path.low !== path.high) {
          this.updatePath({ low: path.low });
        } else {
          this.updatePath({ low: path.low, high: path.high + 1 });
        }
      }
      this.setState({ variableOperator: opType });
    };

    const operatorDetailsMapper = (
      details: { constraint: VariableType; type: string },
      i: number
    ) => {
      const detailsText = i > 0 && operatorDetails.text[i] && (
        <span className="pl2 pt1">{operatorDetails.text[i]}</span>
      );

      return (
        <div key={i} className="flex flex-row flex-auto">
          {detailsText}
          <input
            key={i}
            className="ml2 zx-input-edit flex-auto"
            onChange={updaters[i]}
            type={details.type}
            value={values[i]}
          />
        </div>
      );
    };

    return (
      <div className="flex flex-column flex-auto ">
        <VariableTypeSelect className="flex" value={variableType} />
        <div className="flex flex-row items-center flex-auto pt1">
          <VariableOperatorSelect
            decisionEnabled={true}
            onChange={handleOperatorChange}
            value={variableOperator}
            variableType={variableType}
          />
          <div className="flex flex-row flex-auto">
            {operatorDetails.inputs.map(operatorDetailsMapper)}
          </div>
        </div>
      </div>
    );
  };

  private updatePath = (details: {
    targetId?: string;
    low?: number;
    high?: number;
    otherAlgoNode?: AlgoNode;
  }) => {
    const {
      target: { path },
      openAlgorithm: { algorithm }
    } = this.props;
    const { high, low, otherAlgoNode, targetId } = details;
    const updatedPath = clone(path);
    if (targetId) {
      updatedPath.childId = targetId;
    }
    if (high !== undefined) {
      updatedPath.high = high;
    }
    if (low !== undefined) {
      updatedPath.low = low;
    }
    if (otherAlgoNode) {
      updatedPath.targetAlgorithmId = otherAlgoNode.algorithmId;
    }
    this.props.updatePath({
      algoId: algorithm.id,
      otherAlgoId: otherAlgoNode ? otherAlgoNode.id : undefined,
      path: updatedPath
    });
  };

  private handleRemove = () => {
    const {
      target: { path },
      openAlgorithm: { algorithm }
    } = this.props;

    this.props.removePath({ algoId: algorithm.id, path });
  };
}

const mapStateToProps = (
  { algoStore }: IStoreState,
  props: IMultichoiceOutputProps
): IMultichoiceOutputInjectedProps => {
  const otherAlgorithms = Object.values(algoStore.allAlgorithms).filter(
    a => a.id !== props.openAlgorithm.algorithm.id
  );

  return {
    otherAlgorithms
  };
};

export const MultichoiceOutput = connect(mapStateToProps, {
  updatePath,
  removePath,
  linkNodes
})(MultichoiceOutputComponent);

export const SortableOutput = SortableElement(
  ({ props }: { props: IMultichoiceOutputProps }) => (
    <MultichoiceOutput {...props} />
  )
);

export const SortableOutputs = SortableContainer(
  ({ items }: { items: IMultichoiceOutputProps[] }) => {
    const wrapper = (props: any, i: number) => (
      <SortableOutput key={props.target.path.id} index={i} props={props} />
    );
    return <div>{items.map(wrapper)}</div>;
  }
);
