import { Button, Classes, Dialog, Intent } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import * as React from "react";
import { Dictionary } from "lodash";
import { connect } from "react-redux";

import { IStoreState } from "src/store";
import { Algorithm, Study, User, updateArray, removeFromArray } from "src/api";
import { clearStudyError } from "src/actions";
import { AlgorithmSelect } from "src/components";

interface IAddAlgorithmDialogProps {
  isOpen: boolean;
  study: Study;
  onClose: (algorithms?: Algorithm[]) => void;
}

interface IAddAlgorithmDialogInjectedProps {
  error?: Error;
  loggedInUser?: User;
  studies: Dictionary<Study>;
}

interface IAddAlgorithmDispatchProps {
  clearStudyError: typeof clearStudyError;
}

interface IAddAlgorithmDialogComponentState {
  algosToAdd: Algorithm[];
  busy: boolean;
}

type AddAlgorithmDialogProps = IAddAlgorithmDialogProps &
  IAddAlgorithmDialogInjectedProps &
  IAddAlgorithmDispatchProps;

class AddAlgorithmDialogComponent extends React.PureComponent<
  AddAlgorithmDialogProps,
  IAddAlgorithmDialogComponentState
> {
  constructor(props: AddAlgorithmDialogProps) {
    super(props);

    this.state = {
      algosToAdd: [],
      busy: false
    };
    this.props.clearStudyError();
  }

  public componentDidUpdate(prevProps: AddAlgorithmDialogProps) {
    const { isOpen } = this.props;

    if (!prevProps.isOpen && isOpen) {
      this.setState({ algosToAdd: [] });
    }
  }

  public render() {
    const { isOpen } = this.props;

    return (
      <Dialog
        icon={IconNames.LAYERS}
        title="Add Algorithms to Study"
        isOpen={isOpen}
        onClose={this.closeDialog}
      >
        {this.renderBody()}
        {this.renderFooter()}
      </Dialog>
    );
  }

  private renderFooter = () => {
    const { error } = this.props;

    const errorRenderer = (e: Error) => (
      <span className="mh2 orange">{e.message}</span>
    );
    const handleCancel = () => this.props.onClose();
    const handleAdd = () => this.props.onClose(this.state.algosToAdd);

    return (
      <div className={`${Classes.DIALOG_FOOTER}`}>
        {error && errorRenderer(error)}
        <div className="flex flex-row justify-between pt2">
          <Button id="cancel" text="Cancel" onClick={handleCancel} />
          <Button
            disabled={this.addButtonDisabled()}
            intent={Intent.SUCCESS}
            text="Add to Study"
            onClick={handleAdd}
          />
        </div>
      </div>
    );
  };

  private addButtonDisabled = () => this.state.algosToAdd.length === 0;

  private renderBody = () => {
    const { algosToAdd } = this.state;

    const handleChange = (algo: Algorithm) => {
      this.setState({ algosToAdd: updateArray(this.state.algosToAdd, algo) });
    };

    return (
      <section className={`${Classes.DIALOG_BODY} overflow-y-auto`}>
        Select algorithms to add to the study:
        <div className="w-50 pv2">
          <AlgorithmSelect
            className="br2 zx-input-blue-border"
            value={null}
            onChange={handleChange}
          />
        </div>
        <section className="w-100 flex b bb b--gray pb1 mb1">
          <div className="fl w-50 pt2 pa1">Name</div>
          <div className="fl w-50 pt2 pa1">Topic</div>
        </section>
        <div>{algosToAdd.map(this.renderAlgoRow)}</div>
      </section>
    );
  };

  private renderAlgoRow = (algo: Algorithm, index: number) => {
    const removeRef = () => {
      this.setState({
        algosToAdd: removeFromArray(this.state.algosToAdd, algo.id)
      });
    };

    return (
      <div key={index} className="flex flex-row w-100">
        <div className="fl w-50 pa1">{algo.title}</div>
        <div className="fl w-50 pa1 flex flex-row justify-between">
          <div>{algo.medicalSpecialties.map(s => s.topic).join(", ")}</div>
          <Button icon={IconNames.REMOVE} minimal={true} onClick={removeRef} />
        </div>
      </div>
    );
  };

  private closeDialog = async () => {
    this.props.onClose(this.state.algosToAdd);
  };
}

const mapStateToProps = ({ studiesStore, userStore }: IStoreState) => {
  return {
    error: studiesStore.error,
    loggedInUser: userStore.loggedInUser,
    studies: studiesStore.studies
  };
};

export const AddAlgorithmDialog = connect(mapStateToProps, { clearStudyError })(
  AddAlgorithmDialogComponent
);
