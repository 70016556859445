import { PayloadAction } from "typesafe-actions";

import { classToPlain, serialize } from "class-transformer";
import { omit } from "lodash";

import {
  ICreateAlgorithmPayload,
  IGetAlgorithmParams,
  ILibraryQuery,
  IPagedLibraryQuery,
  IPageParameters
} from "src/actions";
import { IOpenAlgorithm } from "src/store";
import { getRequestInit, kAlgoKeyOmissions, kAlgorithms, serverRoot } from ".";
import { Algorithm } from ".";
import { kTopics, postRequestInit, putRequestInit } from "./endpoints";

export const createAlgorithmConfig = (
  action: PayloadAction<"createAlgorithm/REQUEST", ICreateAlgorithmPayload>,
  token: string | undefined,
  signal: AbortSignal | null
) => {
  const { algorithm, baseAlgo, newRevisionMessage } = action.payload;
  const payload = omit(
    classToPlain<Algorithm>(algorithm),
    kAlgoKeyOmissions
  ) as Partial<Algorithm> & { newRevisionMessage: string };

  if (newRevisionMessage) {
    payload.newRevisionMessage = newRevisionMessage;
  }
  const algoBody = JSON.stringify(payload);

  let url = `${serverRoot}/${kAlgorithms}`;

  if (baseAlgo) {
    url += `?baseId=${baseAlgo.id}`;
  }

  return {
    config: { ...postRequestInit(algoBody, token), signal },
    url
  };
};

const buildLibraryParams = ({
  params,
  url
}: {
  params: ILibraryQuery & Partial<IPageParameters>;
  url: string;
}) => {
  const { medicalSpecialtyIds, skip, take, status, userId } = params;

  let needsStart = true;
  if (skip && take) {
    needsStart = false;
    url += `?skip=${skip}&take=${take}`;
  }

  if (status && status.length) {
    status.forEach(s => (url += `${needsStart ? "?" : "&"}status=${s}`));
    needsStart = false;
  }

  if (medicalSpecialtyIds && medicalSpecialtyIds.length) {
    medicalSpecialtyIds.forEach(
      m => (url += `${needsStart ? "?" : "&"}medicalSpecialtyIds=${m.id}`)
    );
    needsStart = false;
  }

  if (userId) {
    url += `${needsStart ? "?" : "&"}userId=${userId}`;
    needsStart = false;
  }
  return url;
};

export const retrieveAlgoListParams = (
  action: PayloadAction<"retrieveAlgoList/REQUEST", IPagedLibraryQuery>,
  token?: string
) => {
  const url = `${serverRoot}/${kAlgorithms}`;

  return {
    config: getRequestInit(token),
    url: buildLibraryParams({ params: action.payload, url })
  };
};

export const getTopicListParams = (
  action: PayloadAction<"getTopics/REQUEST", ILibraryQuery>,
  token?: string
) => {
  const url = `${serverRoot}/${kAlgorithms}/${kTopics}`;
  return {
    config: getRequestInit(token),
    url: buildLibraryParams({ params: action.payload, url })
  };
};

export const getAlgorithmParams = (
  action: PayloadAction<"getAlgorithm/REQUEST", IGetAlgorithmParams>,
  token: string | undefined,
  signal: AbortSignal | null
) => {
  return {
    config: { ...getRequestInit(token), signal },
    url: `${serverRoot}/${kAlgorithms}/${action.payload.id}${
      action.payload.includeNodes ? "/nodes" : ""
    }${action.payload.allowOld ? "?allowOld=true" : ""}`
  };
};

export const saveAlgoStateParams = (
  action: PayloadAction<"saveAlgoState/REQUEST", IOpenAlgorithm>,
  token: string | undefined,
  signal: AbortSignal | null
) => {
  const openAlgo = action.payload;

  return {
    config: {
      ...putRequestInit(serialize(action.payload.algoState), token),
      signal
    },
    url: `${serverRoot}/${kAlgorithms}/${openAlgo.algorithm.id}/state`
  };
};
