import { push } from "connected-react-router";
import * as React from "react";
import { connect } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router-dom";

import { User } from "src/api";
import { IStoreState } from "src/store";

export interface IModerateProps extends RouteComponentProps {
  loggedInUser?: User;
  push: typeof push;
}

class ModerateComponent extends React.PureComponent<IModerateProps> {
  public render() {
    return (
      <section className="pa2 w-100 h-100">
        <p>Nothing to see here yet</p>
      </section>
    );
  }
}

const mapStateToProps = ({ userStore }: IStoreState) => {
  return {
    loggedInUser: userStore.loggedInUser
  };
};

export const Moderations = withRouter(
  connect(mapStateToProps, {
    push
  })(ModerateComponent)
);
