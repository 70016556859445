import { push } from "connected-react-router";
import * as React from "react";
import { connect } from "react-redux";

import { retrieveAlgoList } from "src/actions";
import { Algorithm, AlgorithmStatus, User } from "src/api";
import { AlgoTitleTag } from "src/components";
import { kLibraryRootUrl } from "src/config/routes";
import { IStoreState } from "src/store";

interface IUserProfileAlgosProps {
  user: User;
}

interface IUserProfileAlgosInjectedProps {
  busy: boolean;
  error?: Error;
  usersAlgos: Algorithm[];
}

interface IUserProfileAlgosDispatchProps {
  getAlgos: typeof retrieveAlgoList.request;
  push: typeof push;
}

type UserProfileAlgosProps = IUserProfileAlgosProps &
  IUserProfileAlgosInjectedProps &
  IUserProfileAlgosDispatchProps;

class UserProfileAlgosComponent extends React.PureComponent<
  UserProfileAlgosProps
> {
  public componentDidMount() {
    const { getAlgos, user } = this.props;

    getAlgos({
      skip: 0,
      take: 50,
      userId: user.id
    });
  }

  public render() {
    const { usersAlgos } = this.props;
    if (usersAlgos.length === 0) {
      return <p>No algorithms!</p>;
    }
    const algoMapper = (a: Algorithm) => (
      <AlgoTitleTag
        algo={a}
        key={a.id}
        selectAlgo={this.selectAlgo}
        showStatus={false}
      />
    );
    return <section>{usersAlgos.map(algoMapper)}</section>;
  }

  private selectAlgo = (topicId: string, algoId: string) => {
    this.props.push(`${kLibraryRootUrl}/${topicId}/${algoId}`);
  };
}

const mapStateToProps = (
  { algoStore, uiStore, userStore }: IStoreState,
  { user }: IUserProfileAlgosProps
) => {
  const allAlgos = Object.values(algoStore.allAlgorithms);
  const usersAlgos = allAlgos
    .filter(a => a.status === AlgorithmStatus.published)
    .filter(a => {
      return (
        a.authors.find(au => au.id === user.id) ||
        a.editors.find(ed => ed.id === user.id)
      );
    });

  return {
    busy: uiStore.library.libraryLoadersCount > 0,
    error: uiStore.error,
    loggedInUser: userStore.loggedInUser,
    usersAlgos
  };
};

export const UserProfileAlgos = connect(mapStateToProps, {
  getAlgos: retrieveAlgoList.request,
  push
})(UserProfileAlgosComponent);
