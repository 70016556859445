import { ActionsObservable, StateObservable } from "redux-observable";
import { from, of } from "rxjs";
import { catchError, concatMap, filter, map, take } from "rxjs/operators";
import { isActionOf } from "typesafe-actions";

import {
  ITaggedAlgoRequestPayload,
  ITaggedCommentRequestPayload,
  ITaggedNodeRequestPayload,
  ITaggedParameterPayload,
  ITaggedPathRequestPayload,
  ITaggedUnitPayload,
  ReduxAction
} from "src/actions";
import {
  IOpenxmedApiFailureResponse,
  IOpenxmedApiSuccessResponse,
  IOpenxmedApiTaggedSuccessResponse,
  makeApiRequest,
  requestConfigForAction
} from "src/api";
import { IStoreState } from "src/store";

export * from "./create-root-epic";

export const handleAsyncAction = <
  T extends {
    request: (args: any) => ReduxAction;
    success: (arg0: IOpenxmedApiSuccessResponse) => ReduxAction;
    failure: (arg0: Error) => ReduxAction;
  }
>(
  action$: ActionsObservable<ReduxAction>,
  state$: StateObservable<IStoreState>,
  asyncAction: T
) =>
  action$.pipe(
    filter(isActionOf(asyncAction.request)),
    concatMap(action =>
      state$.pipe(
        // Wait for a valid token...
        map(() => state$.value.userStore.authToken),
        filter<string | undefined>(Boolean),
        take(1),
        concatMap(token =>
          from(makeApiRequest(requestConfigForAction(action, token))).pipe(
            map(response => asyncAction.success(response)),
            catchError(error => of(asyncAction.failure(error)))
          )
        )
      )
    )
  );

// export const handleAsyncDeleteAction = <
//   T extends {
//     request: (args: any) => ReduxAction;
//     success: (arg0: DeleteResponsePacket) => ReduxAction;
//     failure: (arg0: Error) => ReduxAction;
//   }
// >(
//   action$: ActionsObservable<ReduxAction>,
//   state$: StateObservable<IStoreState>,
//   asyncAction: T
// ) =>
//   action$.pipe(
//     filter(isActionOf(asyncAction.request)),
//     concatMap(action =>
//       state$.pipe(
//         // Wait for a valid token...
//         map(() => state$.value.userStore.authToken),
//         filter(Boolean),
//         take(1),
//         concatMap(token =>
//           from(makeApiRequest(requestConfigForAction(action, token))).pipe(
//             map(response => asyncAction.success(response)),
//             catchError(error => of(asyncAction.failure(error)))
//           )
//         )
//       )
//     )
//   );

declare type PayloadTypes = ITaggedNodeRequestPayload &
  ITaggedAlgoRequestPayload &
  ITaggedCommentRequestPayload &
  ITaggedPathRequestPayload &
  ITaggedParameterPayload &
  ITaggedUnitPayload;

export const handleAsyncTaggedAction = <
  T extends {
    request: (arg0: PayloadTypes) => any;
    success: (arg0: IOpenxmedApiTaggedSuccessResponse) => ReduxAction;
    failure: (arg0: IOpenxmedApiFailureResponse) => ReduxAction;
  }
>(
  action$: ActionsObservable<ReduxAction>,
  state$: StateObservable<IStoreState>,
  asyncAction: T
) =>
  action$.pipe(
    filter(isActionOf(asyncAction.request)),
    concatMap(action =>
      state$.pipe(
        // Wait for a valid token...
        map(() => state$.value.userStore.authToken),
        filter<string | undefined>(Boolean),
        take(1),
        concatMap(token => {
          const {
            payload: { tag }
          } = action;
          const request = requestConfigForAction(action, token);
          return from(makeApiRequest(request)).pipe(
            map(response => asyncAction.success({ ...response, tag })),
            catchError(error =>
              of(
                asyncAction.failure({
                  error,
                  request,
                  tag
                })
              )
            )
          );
        })
      )
    )
  );
