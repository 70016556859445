import {
  Alignment,
  Button,
  HTMLSelect,
  Icon,
  Intent,
  NumericInput
} from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import { NumericDictionary } from "lodash";
import * as React from "react";

import { IChangeVarPayload } from "src/actions";
import { Parameter } from "src/api";
import { IVariableDefinition } from "src/store";

interface IParameterInputProps {
  className?: string;
  editMode?: boolean;
  showTitle: boolean;
  showUnits?: boolean;
  parameter: Parameter;
  parameterIndex?: number;
  onChange: (details: IChangeVarPayload) => void;
  values: NumericDictionary<number | boolean | string | undefined>;
}

export class ParameterInput extends React.PureComponent<IParameterInputProps> {
  public render() {
    const {
      className,
      editMode,
      showTitle,
      showUnits,
      parameter,
      parameterIndex,
      values
    } = this.props;
    const { detailsJson } = parameter;

    const inputRenderer = (varDetails: IVariableDefinition, index: number) => {
      switch (varDetails.type) {
        case "numeric":
          return this.renderNumericInput(
            showTitle,
            showUnits,
            parameter,
            index,
            values as NumericDictionary<number>,
            editMode
          );
        case "boolean":
          return this.renderBooleanInput(
            showTitle,
            parameter,
            index,
            values as NumericDictionary<boolean>,
            editMode
          );
        case "discrete":
          return this.renderListInput(
            showTitle,
            parameter,
            index,
            values as NumericDictionary<string>,
            editMode
          );
        default:
          return null;
      }
    };

    const combinedClass = `pv1 flex flex-column flex-auto ${
      className ? className : ""
    }`;
    const titleRenderer = detailsJson.length > 1 && showTitle && (
      <span className="ttu f5 zx-blue">{parameter.title}</span>
    );
    const inputRendererWrapper =
      parameterIndex === undefined
        ? detailsJson.map(inputRenderer)
        : inputRenderer(detailsJson[parameterIndex], parameterIndex);

    return (
      <section className={combinedClass} onMouseDown={this.stopPropagation}>
        {titleRenderer}
        {inputRendererWrapper}
      </section>
    );
  }

  private renderNumericInput = (
    showTitle: boolean,
    showUnits = false,
    parameter: Parameter,
    index: number,
    values: NumericDictionary<number>,
    editMode = false
  ) => {
    const { detailsJson } = parameter;
    const { name, unit, min, max, initial } = detailsJson[index];
    const unitsText = unit && (unit.shortName || unit.name);
    const value = values ? values[index] : initial;

    const reportChange = (newValue: number, valStr: string) => {
      if (parseFloat(valStr) !== value) {
        if (valStr === "") {
          this.props.onChange({
            index,
            paramId: parameter.id,
            value: undefined
          });
        } else {
          this.props.onChange({
            index,
            paramId: parameter.id,
            value: newValue
          });
        }
      }
    };

    const titleRenderer = (title: string) => (
      <span className={`${detailsJson.length === 1 ? "ttu f5 zx-blue" : ""}`}>
        {title}
      </span>
    );

    const controlRenderer = editMode ? (
      <div
        className="flex flex-row items-center"
        onMouseDown={this.stopPropagation}
      >
        {showTitle && `${name}: `}
        <NumericInput
          buttonPosition="none"
          className="mh2"
          min={min}
          max={max}
          onValueChange={reportChange}
          style={{ width: 60 }}
          value={value === null ? 0 : value}
        />
        {showUnits && unitsText}
      </div>
    ) : (
      <div className="flex flex-column flex-auto mt2">
        <div className="flex flex-row">
          {showTitle && titleRenderer(name)}
          {showUnits && <span className="ml1 i">{`(${unitsText})`}</span>}
        </div>
        <NumericInput
          buttonPosition="none"
          className="mh2 flex b bb b--gray pb1"
          fill={true}
          min={min}
          max={max}
          onValueChange={reportChange}
          value={value === null ? 0 : value}
        />
      </div>
    );
    return (
      <section className={"flex flew-column"} key={index}>
        {controlRenderer}
      </section>
    );
  };

  private renderBooleanInput = (
    showTitle: boolean,
    parameter: Parameter,
    index: number,
    values: NumericDictionary<boolean>,
    editMode = false
  ) => {
    const { detailsJson } = parameter;
    const { name } = detailsJson[index];
    const value = (values && values[index]) || false;

    const onClick = (e: React.MouseEvent<HTMLElement>) => {
      e.stopPropagation();
      e.preventDefault();

      this.props.onChange({
        index,
        paramId: parameter.id,
        value: !value
      });
    };

    const reportSelectChange: React.ChangeEventHandler<HTMLSelectElement> = e => {
      this.props.onChange({
        index,
        paramId: parameter.id,
        value: e.currentTarget.value === "True" ? true : false
      });
    };

    const iconSelector = () => (
      <Icon
        intent={value ? Intent.PRIMARY : Intent.NONE}
        icon={value ? IconNames.TICK_CIRCLE : IconNames.CIRCLE}
      />
    );

    const controlRenderer = editMode ? (
      <div>
        {showTitle && `${name || parameter.title}: `}
        <HTMLSelect
          className="mh2"
          options={["True", "False"]}
          onChange={reportSelectChange}
          value={value ? "True" : "False"}
        />
      </div>
    ) : (
      <Button
        className="flex flex-auto mr2"
        intent={value ? Intent.SUCCESS : Intent.NONE}
        alignText={Alignment.LEFT}
        text={name || parameter.title}
        onClick={onClick}
        icon={iconSelector()}
      />
    );

    return (
      <section
        className={"flex flew-row flex-auto items-center"}
        key={index}
        onMouseDown={this.stopPropagation}
      >
        {controlRenderer}
      </section>
    );
  };

  private renderListInput = (
    showTitle: boolean,
    parameter: Parameter,
    index: number,
    values: NumericDictionary<string>,
    editMode = false
  ) => {
    const { detailsJson } = parameter;
    const { discreteValues, name } = detailsJson[index];
    const value = values ? values[index] : 0; // Index into the list of vals

    if (!discreteValues) {
      return null;
    }

    const titleRenderer = (title: string) => (
      <span className={`${detailsJson.length === 1 ? "ttu f5 zx-blue" : ""}`}>
        {title}
      </span>
    );
    const reportChange: React.ChangeEventHandler<HTMLSelectElement> = e => {
      const selectedElement = discreteValues[e.currentTarget.selectedIndex];
      if (selectedElement) {
        this.props.onChange({
          index,
          paramId: parameter.id,
          value: selectedElement.id
        });
      }
    };

    const enumValue = discreteValues.find(dv => dv.id === value);
    return (
      <section
        className={`flex ${editMode ? "flew-row" : "flex-column"} flex-auto`}
        key={index}
        onMouseDown={this.stopPropagation}
      >
        {showTitle && titleRenderer(name)}
        <HTMLSelect
          className={`mh2 flex flex-auto ${editMode ? "" : "mt1"}`}
          options={discreteValues}
          onChange={reportChange}
          value={enumValue && enumValue.value}
        />
      </section>
    );
  };
  // This is necessary to fix Safari, where the events are dumped through.
  private stopPropagation = (e: React.MouseEvent) => e.stopPropagation();
}
