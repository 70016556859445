import {
  InputGroup,
  MenuDivider,
  MenuItem,
  Menu,
  Spinner
} from "@blueprintjs/core";
import * as React from "react";
import { connect } from "react-redux";
import { Suggest } from "@blueprintjs/select";

import { searchStudyPatients } from "src/actions";
import {
  arrayFromPayload,
  makeApiRequest,
  requestConfigForAction,
  Patient,
  fullName,
  Study,
  IXmedObjectInfo
} from "src/api";
import { IStoreState } from "src/store";
import { IconNames } from "@blueprintjs/icons";

const PatientSuggest = Suggest.ofType<Patient>();

export interface IPatientNameSelectProps {
  className?: string;
  disabled?: boolean;
  onChange: (value: Patient) => void;
  simpleInterface?: boolean;
  study: Study;
  value?: Patient;
}

interface IPatientNameSelectInjectedProps {
  token?: string;
}

interface IPatientNameSelectState {
  searchResults: Patient[];
  error?: Error;
  loading: boolean;
  query: string;
}

type PatientNameSelectComponentProps = IPatientNameSelectProps &
  IPatientNameSelectInjectedProps;

class PatientNameSelectComponent extends React.PureComponent<
  PatientNameSelectComponentProps,
  IPatientNameSelectState
> {
  constructor(props: PatientNameSelectComponentProps) {
    super(props);
    this.state = {
      loading: false,
      query: "",
      searchResults: []
    };
  }

  public render() {
    const { className, simpleInterface, study, value } = this.props;
    const { error, loading, query, searchResults: items } = this.state;
    const handleChange = (e: React.FormEvent<HTMLInputElement>) => {
      this.doApiPatientSearch(e.currentTarget.value, study.id);
    };

    const popoverProps = {
      minimal: true,
      popoverClassName: "zx-scrolling-popover"
    };

    const placeholder = items.length === 0 && !query && (
      <span className="pa1 gray">Search for a user by name</span>
    );
    const noMatchText = items.length === 0 && query && (
      <span className="pa1 gray">No matches</span>
    );
    const errorText = error && (
      <p className="pa1 pt2 zx-orange">{error.message}</p>
    );
    const doQuery = (q: string) => this.doApiPatientSearch(q, study.id);

    if (simpleInterface) {
      return (
        <div className={`${className} pa1`}>
          <PatientSuggest
            items={items}
            inputValueRenderer={this.inputValueRenderer}
            itemRenderer={this.itemRenderer}
            onItemSelect={this.selectionHandler}
            onQueryChange={doQuery}
            popoverProps={popoverProps}
            selectedItem={value}
          />
        </div>
      );
    }

    return (
      <div className={`${className} pa1 ph3 zx-bg-charcoal-grey br2`}>
        <InputGroup leftIcon={IconNames.SEARCH} onChange={handleChange} />
        <Menu>
          <MenuDivider />
          {loading && <Spinner size={Spinner.SIZE_SMALL} />}
          {!loading && items.map(this.itemRenderer)}
          {!loading && placeholder}
          {!loading && noMatchText}
          {errorText}
        </Menu>
      </div>
    );
  }

  private inputValueRenderer = (item: Patient) => fullName(item);

  private itemRenderer = (value: Patient) => {
    const handleClick = () => this.selectionHandler(value);

    return (
      <MenuItem
        key={value.id}
        onClick={handleClick}
        text={`${fullName(value)}`}
      />
    );
  };

  private selectionHandler = (i: Patient) => {
    const { onChange } = this.props;
    if (onChange) {
      onChange(i);
    }
  };

  private doApiPatientSearch = async (query: string, studyId: string) => {
    if (query.length === 0) {
      this.setState({ searchResults: [], query, error: undefined });
    } else {
      if (query.length > 0) {
        this.setState({ loading: true, query, error: undefined });
        try {
          const response = await makeApiRequest(
            requestConfigForAction(
              searchStudyPatients.request({
                nameQuery: query,
                studyId: studyId
              }),
              this.props.token
            )
          );
          if (response.apiResponse.data) {
            const patients = arrayFromPayload(
              response.apiResponse.data as IXmedObjectInfo[],
              Patient
            );
            this.setState({
              loading: false,
              searchResults: [...patients]
            });
          }
        } catch (error) {
          this.setState({ loading: false, error });
        }
      }
    }
  };
}

const mapStateToProps = ({
  userStore
}: IStoreState): IPatientNameSelectInjectedProps => {
  return {
    token: userStore.authToken
  };
};

export const PatientNameSelect = connect(
  mapStateToProps,
  {}
)(PatientNameSelectComponent);
