import * as React from "react";
import { Field, InjectedFormProps, reduxForm } from "redux-form";

import { Algorithm } from "src/api";
import {
  FormField,
  renderAuthors,
  renderEditors,
  renderKeywords,
  renderMedSpecialties,
  renderRegions
} from "src/components";

export interface IAlgoEditFormProps {
  algorithm: Algorithm;
  className?: string;
}

class AlgoEditFormComponent extends React.PureComponent<
  InjectedFormProps<Algorithm, IAlgoEditFormProps> & IAlgoEditFormProps
> {
  private firstNameField = React.createRef<HTMLInputElement>();

  public componentDidMount() {
    if (this.firstNameField.current) {
      this.firstNameField.current.focus();
    }
  }

  public render() {
    const { algorithm, className, handleSubmit } = this.props;

    const titleField =
      algorithm.version > 1 ? null : (
        <Field
          className="zx-input-blue-border"
          component={FormField}
          inputRef={this.firstNameField}
          label="Algorithm Title"
          name="title"
          type="text"
        />
      );

    return (
      <form
        className={`pa2 w-100 flex flex-column ${className}`}
        onSubmit={handleSubmit}
      >
        {titleField}
        <Field
          className="zx-input-blue-border"
          name="keywords"
          label="Keywords"
          component={renderKeywords}
        />
        <Field
          className="zx-input-blue-border"
          name="medicalSpecialties"
          label="Medical Specialties"
          component={renderMedSpecialties}
        />
        <Field
          className="zx-input-blue-border"
          name="regions"
          label="Region Restrictions"
          component={renderRegions}
        />
        <Field
          className="zx-input-blue-border"
          name="summary"
          label="Summary"
          type="textarea"
          component={FormField}
        />
        <Field
          className="zx-input-blue-border"
          name="synopsis"
          label="Synopsis"
          type="textarea"
          component={FormField}
        />
        <Field
          className="zx-input-blue-border"
          name="epidemiology"
          label="Epidemiology"
          type="textarea"
          component={FormField}
        />
        <Field
          className="zx-input-blue-border"
          name="editors"
          label="Reviewers"
          component={renderEditors}
          specialties={algorithm.medicalSpecialties}
        />
        <Field
          className="zx-input-blue-border"
          name="authors"
          label="Collaborators"
          component={renderAuthors}
        />
      </form>
    );
  }
}

export const AlgoEditForm = reduxForm<Algorithm, IAlgoEditFormProps>({})(
  AlgoEditFormComponent
);
