import { Dictionary, range } from "lodash";

import { AlgoNode, textForNodeType, AlgoNodePath } from "src/api";
import { nodeTitleTextPlain, notEmpty, truncateString } from "src/utilities";
import { createValidationIssue, IAlgoError } from "./algorithm-validator";

const getCombinations = <T>(values: T[], depth = 0): T[][] => {
  if (values.length === 0) {
    return [[]];
  }

  if (values.length === 1) {
    return [[], values];
  }
  if (depth > 50) {
    // tslint:disable-next-line:no-console
    console.log("Infite recursion (loop) in node combo finder");
    throw new Error("Recursion limit exceeded");
  }

  const nextLayer = getCombinations(values.slice(1), depth + 1);
  return [...nextLayer.map(vals => [values[0]].concat(vals)), ...nextLayer];
};

export const verifyMultiChoiceWeights = (
  mc: AlgoNode,
  algoNodes: Dictionary<AlgoNode>
): IAlgoError[] => {
  const errors: IAlgoError[] = [];
  const choices = mc
    .options(algoNodes)
    .map(o => o.node)
    .filter(notEmpty);

  let rangeMin = 0;
  let rangeMax = 0;

  let zeroWeightedChoice: AlgoNode | undefined;

  const choiceCombinations = getCombinations(choices);
  const possibleSelections: Dictionary<AlgoNode[]> = {};
  const valuesCovered: Dictionary<AlgoNodePath> = {};

  choiceCombinations.forEach(cc => {
    const selectionScore = cc.reduce((p, n, i, c) => p + c[i].weight, 0);
    possibleSelections[selectionScore] = cc;
    rangeMin = Math.min(rangeMin, selectionScore);
    rangeMax = Math.max(rangeMax, selectionScore);
  });

  // Weighting of zero resets the others, there can be only one of those.
  choices.forEach(c => {
    if (zeroWeightedChoice) {
      errors.push(
        createValidationIssue(
          `Node '${truncateString(
            nodeTitleTextPlain(c)
          )}' and node ${truncateString(
            nodeTitleTextPlain(zeroWeightedChoice)
          )} both have weights of 0. There can be only one choice with a weighting of 0.`,
          c.id,
          "nodes",
          undefined,
          "choices"
        )
      );
    } else if (c.weight === 0) {
      zeroWeightedChoice = c;
    }
  });

  // Check targets for all ranges covered.
  mc.targets(algoNodes).forEach(t => {
    range(t.path.low, t.path.high + 1).forEach(tVal => {
      if (!valuesCovered[tVal]) {
        valuesCovered[tVal] = t.path;
      } else {
        // Something else took it out already
        createValidationIssue(
          `Node '${t.node &&
            truncateString(
              nodeTitleTextPlain(t.node)
            )}' overlaps with another possibility
           for target value ${tVal}`,
          t.node && t.node.id,
          "nodes",
          undefined,
          "choices"
        );
      }
    });
    const rangeString =
      t.path.low === t.path.high
        ? `value (${t.path.low})`
        : `range (${t.path.low}-${t.path.high})`;
    if (t.path.high < rangeMin) {
      errors.push(
        createValidationIssue(
          `Node '${t.node &&
            truncateString(
              nodeTitleTextPlain(t.node)
            )}' handles a ${rangeString} less than the minimum possible selection ${rangeMin}`,
          mc.id,
          "nodes",
          undefined,
          "choices"
        )
      );
    }
    if (t.path.low > rangeMax) {
      errors.push(
        createValidationIssue(
          `Node '${t.node &&
            truncateString(
              nodeTitleTextPlain(t.node)
            )}' handles a ${rangeString} higher than the maximum possible selection ${rangeMax}`,
          mc.id,
          "nodes",
          undefined,
          "choices"
        )
      );
    }
  });

  const stringifyRange = (x: number, y: number): string => {
    return x === y ? `${x}` : `${x}-${y}`;
  };

  const missingRanges = (
    values: number[],
    lowerBound: number,
    upperBound: number
  ): string[] => {
    const output: string[] = [];
    let next = lowerBound;

    for (const val of values) {
      if (val < next) {
        continue;
      }

      if (val === next) {
        next++;
        continue;
      }

      output.push(stringifyRange(next, val - 1));
      next = val + 1;
    }

    if (next <= upperBound) {
      output.push(stringifyRange(next, upperBound));
    }
    return output;
  };

  const coveredValueKeys = Object.keys(valuesCovered);
  const uncoveredValues = missingRanges(
    coveredValueKeys.map(v => parseInt(v, 10)),
    rangeMin,
    rangeMax
  );
  let unConnectedPathId: string | undefined;

  coveredValueKeys.forEach(k => {
    if (!unConnectedPathId) {
      const path = valuesCovered[k];
      if (path && !(path.childId || path.targetAlgorithmId)) {
        unConnectedPathId = path.id;
      }
    }
  });

  if (unConnectedPathId) {
    errors.push(
      createValidationIssue(
        `${textForNodeType(mc.kind)} node '${truncateString(
          nodeTitleTextPlain(mc)
        )}' is not fully connected`,
        mc.id,
        "nodes",
        undefined,
        "choices",
        unConnectedPathId
      )
    );
  }
  if (uncoveredValues.length > 0) {
    errors.push(
      createValidationIssue(
        `${textForNodeType(mc.kind)} node '${truncateString(
          nodeTitleTextPlain(mc)
        )}' does not handle all possible selections. Value(s)
        ${uncoveredValues.join(", ")} need to be covered`,
        mc.id,
        "nodes",
        undefined,
        "choices"
      )
    );
  }

  return errors;
};
