import { sortBy, toPairs } from "lodash";

import { IChangeAlgoPagePayload } from "src/actions";
import {
  ConsumePanelFocusType,
  IEditingState,
  IOpenAlgorithm
} from "src/store";
import { AlgoNodeType } from "src/api";

export const updatedOpenAlgoArrayWithPanelFocus = (
  openAlgos: IOpenAlgorithm[],
  algoId: string,
  focus: ConsumePanelFocusType,
  firstNavigation?: boolean
) => {
  const algoIndex = openAlgos.findIndex(v => v.algorithm.id === algoId);
  if (algoIndex > -1) {
    return openAlgos.map((item, index) => {
      if (index !== algoIndex) {
        return item;
      }
      const obj = { ...item, consumePanelFocus: focus };
      if (firstNavigation !== undefined) {
        obj.firstNavigation = firstNavigation;
      }

      return obj;
    });
  }
  return openAlgos;
};

export const handleChangePage = (
  payload: IChangeAlgoPagePayload,
  openAlgorithms: IOpenAlgorithm[]
) => {
  return openAlgorithms.map(item => {
    if (item.algorithm.id !== payload.details.algorithm.id) {
      return item;
    }
    const pageCount = item.algorithm ? item.sectionNodes.length : 0;
    return {
      ...item,
      currentPage: Math.max(0, Math.min(pageCount - 1, payload.page))
    };
  });
};

export const toggleEditForAlgo = (
  algoId: string,
  openAlgos: IOpenAlgorithm[]
) => {
  const algoIndex = openAlgos.findIndex(a => a.algorithm.id === algoId);
  if (algoIndex > -1) {
    const updated = { ...openAlgos[algoIndex] };
    updated.editModeActive = !updated.editModeActive;
    return openAlgos.map((item, index) =>
      index !== algoIndex ? item : updated
    );
  }
  return openAlgos;
};

export const setEditStateForAlgo = (
  algoId: string,
  nextEditState: Partial<IEditingState>,
  openAlgos: IOpenAlgorithm[]
) => {
  const algoIndex = openAlgos.findIndex(a => a.algorithm.id === algoId);
  if (algoIndex > -1) {
    const {
      algoNodes,
      editingState: {
        outputsExpanded: oldOutputsExpanded,
        panelFocus: oldPanelFocus,
        selectedPathId: oldSelectedPathId,
        selectedNodeIds: oldSelection
      }
    } = openAlgos[algoIndex];

    let panelFocus = nextEditState.panelFocus || oldPanelFocus;
    let outputsExpanded =
      nextEditState.outputsExpanded === undefined
        ? oldOutputsExpanded
        : nextEditState.outputsExpanded;

    // Move panel focus if not available
    if (
      ["choices", "calcs", "contained", "info"].includes(panelFocus) &&
      nextEditState.selectedNodeIds
    ) {
      // Move the edit panel focus if
      const prevSelectionNodeId = sortBy(toPairs(oldSelection), 1).pop();

      const nextSelectedNodeId = sortBy(
        toPairs(nextEditState.selectedNodeIds),
        1
      ).pop();

      if (prevSelectionNodeId) {
        if (nextSelectedNodeId) {
          const nextSelectedNode = algoNodes[nextSelectedNodeId[0]];
          const prevSelectedNode = algoNodes[prevSelectionNodeId[0]];

          if (nextSelectedNode.kind !== prevSelectedNode.kind) {
            panelFocus = "attrs";
          }
        } else {
          // Reset on no selection
          panelFocus = "attrs";
        }
      }
    }

    if (
      nextEditState.selectedPathId &&
      nextEditState.selectedPathId !== oldSelectedPathId
    ) {
      const nextSelectedNodeId = sortBy(
        toPairs(nextEditState.selectedNodeIds),
        1
      ).pop();
      const nextSelectedNode =
        nextSelectedNodeId && algoNodes[nextSelectedNodeId[0]];
      if (
        nextSelectedNode &&
        nextSelectedNode.kind === AlgoNodeType.multiSelect
      ) {
        // Highlighted a multi-select path, so...
        panelFocus = "choices";
        outputsExpanded = true;
      }
    }

    // Apply changes
    return openAlgos.map((item, index) =>
      index !== algoIndex
        ? item
        : {
            ...item,
            editingState: {
              ...item.editingState,
              ...nextEditState,
              outputsExpanded,
              panelFocus
            }
          }
    );
  }
  return openAlgos;
};
