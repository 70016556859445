import {
  Button,
  Classes,
  MenuDivider,
  Popover,
  PopoverPosition
} from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import * as React from "react";
import { SortEnd } from "react-sortable-hoc";

import {
  createPath,
  reorderPaths,
  setEditState,
  createNode
} from "src/actions";
import { AlgoNode, AlgoNodeType } from "src/api";
import { IOpenAlgorithm } from "src/store";
import { SortablePageNodes, IPageNodeProps } from ".";
import { SharedTextSelector } from "src/components";

interface INodeEditContainedPanelProps {
  createNode: typeof createNode;
  createPath: typeof createPath;
  node?: AlgoNode;
  openAlgorithm: IOpenAlgorithm;
  reorderPaths: typeof reorderPaths;
  setEditState: typeof setEditState;
  updateNode: (node: AlgoNode) => void;
}

interface INodeEditContainedPanelState {
  addOpen: boolean;
}

export class NodeEditContainedPanel extends React.PureComponent<
  INodeEditContainedPanelProps,
  INodeEditContainedPanelState
> {
  constructor(props: INodeEditContainedPanelProps) {
    super(props);
    this.state = {
      addOpen: false
    };
  }

  public render() {
    const { node, openAlgorithm } = this.props;
    const { addOpen } = this.state;

    if (!node) {
      return null;
    }
    const items: IPageNodeProps[] = node
      .contained(openAlgorithm.algoNodes)
      .map(n => ({
        openAlgorithm,
        link: n
      }));

    const closeMenu = () => this.setState({ addOpen: false });
    const toggleMenu = () => this.setState({ addOpen: !addOpen });

    const createImage = () => {
      this.props.createNode({
        algoId: this.props.openAlgorithm.algorithm.id,
        kind: AlgoNodeType.image,
        parentId: node.id,
        contained: true
      });
      closeMenu();
    };

    return (
      <section key={node.id} className="w-100">
        <SortablePageNodes
          items={items}
          lockAxis="y"
          lockToContainerEdges={true}
          onSortEnd={this.handleChoiceSort}
          useDragHandle={true}
        />
        <Popover
          className={Classes.DARK}
          isOpen={addOpen}
          onClose={closeMenu}
          position={PopoverPosition.BOTTOM_LEFT}
          minimal={true}
        >
          <Button
            icon={IconNames.ADD}
            onClick={toggleMenu}
            rightIcon={IconNames.CARET_DOWN}
            text="Add"
          />
          <div>
            <div
              className={`${Classes.MENU_ITEM} ma1 mh2 pointer`}
              onClick={createImage}
            >
              Image
            </div>
            <MenuDivider />
            <div className={"pa2 ma1 mh2"}>Shared Text</div>
            <SharedTextSelector openAlgorithm={openAlgorithm} node={node} />
          </div>
        </Popover>
      </section>
    );
  }

  private handleChoiceSort = (sort: SortEnd) => {
    const {
      openAlgorithm: {
        algorithm: { id }
      },
      node
    } = this.props;
    const { newIndex, oldIndex } = sort;

    if (node && newIndex !== oldIndex) {
      this.props.reorderPaths({
        algoId: id,
        newIndex,
        nodeId: node.id,
        oldIndex,
        type: "contained"
      });
    }
  };
}
