import { Type } from "class-transformer";
import { Algorithm, Comment, User } from "..";
import { BaseModel } from "./object-base";

export class Moderation extends BaseModel {
  @Type(() => Comment)
  public comment?: Comment;

  @Type(() => Algorithm)
  public algorithm?: Algorithm;

  @Type(() => User)
  public moderator?: User;

  public message = "";
  public flags = 0;
  public flagged = false;

  @Type(() => Date)
  public flaggedAt?: Date;
}
