import { Button, MenuItem } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import { ItemPredicate, ItemRenderer, Suggest } from "@blueprintjs/select";
import * as React from "react";
import { connect } from "react-redux";

import { searchAlgoStarts } from "src/actions";
import {
  Algorithm,
  arrayFromPayload,
  arrayKeyIsEqual,
  makeApiRequest,
  requestConfigForAction,
  IXmedObjectInfo
} from "src/api";
import { IStoreState } from "src/store";

export interface IAlgorithmSelectProps {
  className?: string;
  disabled?: boolean;
  localAlgos?: Algorithm[];
  value: Algorithm | undefined | null;
  onChange?: (value: Algorithm) => void;
}

interface IAlgorithmSelectInjectedProps {
  token?: string;
}

interface IAlgorithmSelectState {
  searchResults: Algorithm[];
  error?: Error;
  loading: boolean;
  query: string;
}

type AlgorithmSelectComponentProps = IAlgorithmSelectProps &
  IAlgorithmSelectInjectedProps;

const AlgoSelect = Suggest.ofType<Algorithm>();

class AlgorithmSelectComponent extends React.PureComponent<
  AlgorithmSelectComponentProps,
  IAlgorithmSelectState
> {
  constructor(props: AlgorithmSelectComponentProps) {
    super(props);
    this.state = {
      loading: false,
      query: "",
      searchResults: props.localAlgos ? [...props.localAlgos] : []
    };
  }

  public componentDidUpdate(prevProps: AlgorithmSelectComponentProps) {
    const { localAlgos } = this.props;
    const { localAlgos: oldLocalAlgos } = prevProps;

    if (
      localAlgos &&
      oldLocalAlgos &&
      !arrayKeyIsEqual(localAlgos, oldLocalAlgos)
    ) {
      this.setState({
        searchResults: [...localAlgos, ...this.state.searchResults]
      });
    }
  }

  public render() {
    const { disabled, value } = this.props;
    const { searchResults } = this.state;
    const popoverProps = {
      minimal: true,
      popoverClassName: "zx-scrolling-popover"
    };
    const items =
      searchResults.length > 0
        ? searchResults
        : value
        ? [value]
        : searchResults;

    return (
      <div className={`${this.props.className}`}>
        <AlgoSelect
          disabled={disabled}
          popoverProps={popoverProps}
          items={items}
          itemPredicate={this.filterResults}
          itemRenderer={this.itemRenderer}
          inputValueRenderer={this.inputValueRenderer}
          onItemSelect={this.selectionHandler}
          onQueryChange={this.doApiAlgoSearch}
          inputProps={{ disabled }}
          resetOnClose={true}
          selectedItem={value}
        >
          <Button
            disabled={disabled}
            text={(value && value.title) || "Choose algorithm"}
            rightIcon={IconNames.CARET_DOWN}
          />
        </AlgoSelect>
      </div>
    );
  }

  private itemRenderer: ItemRenderer<Algorithm> = (
    value,
    { handleClick, modifiers: { disabled } }
  ) => {
    return (
      <MenuItem
        active={value === this.props.value}
        disabled={disabled}
        key={value.id}
        onClick={handleClick}
        text={value.title}
      />
    );
  };

  private inputValueRenderer = (item: Algorithm) => item.title;

  private selectionHandler = (i: Algorithm) => {
    const { onChange } = this.props;
    if (onChange) {
      onChange(i);
    }
  };

  private doApiAlgoSearch = async (query: string) => {
    if (query.length === 0) {
      this.setState({ searchResults: [], query });
    } else {
      this.setState({ loading: true, query, error: undefined });
      try {
        const response = await makeApiRequest(
          requestConfigForAction(
            searchAlgoStarts.request(query),
            this.props.token
          )
        );
        if (response.apiResponse.data) {
          const algos = arrayFromPayload(
            response.apiResponse.data as IXmedObjectInfo[],
            Algorithm
          );
          this.setState({
            loading: false,
            searchResults: [...(this.props.localAlgos || []), ...algos]
          });
        }
      } catch (error) {
        this.setState({ loading: false, error });
      }
    }
  };

  private filterResults: ItemPredicate<Algorithm> = (
    query,
    algo,
    index,
    exactMatch
  ) => {
    const normalizedTitle = algo.title.toLowerCase();
    const normalizedQuery = query.toLowerCase();

    if (exactMatch) {
      return normalizedTitle === normalizedQuery;
    } else {
      return algo.title.toLowerCase().indexOf(normalizedQuery) >= 0;
    }
  };
}

const mapStateToProps = ({
  userStore
}: IStoreState): IAlgorithmSelectInjectedProps => {
  return {
    token: userStore.authToken
  };
};

export const AlgorithmSelect = connect(
  mapStateToProps,
  {}
)(AlgorithmSelectComponent);
