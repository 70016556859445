import { Icon, Tag } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import * as React from "react";

import { Algorithm, AlgorithmStatus, algoStatusString } from "src/api";

interface IAlgoTitleTagProps {
  algo: Algorithm;
  showStatus?: boolean;
  selectAlgo: (topicId: string, algoId: string) => void;
}

export const algoIconForStatus = (status: AlgorithmStatus): JSX.Element => {
  let colour;
  switch (status) {
    case AlgorithmStatus.draft:
      colour = "var(--disabled-button-grey)";
      break;

    case AlgorithmStatus.awaitingReview:
    case AlgorithmStatus.inReview:
    case AlgorithmStatus.awaitingFormatting:
    case AlgorithmStatus.inFormatting:
      colour = "var(--app-orange)";
      break;

    case AlgorithmStatus.superceded:
    case AlgorithmStatus.approved:
    case AlgorithmStatus.published:
    default:
      colour = "var(--app-blue)";
  }
  return <Icon icon={IconNames.LAYER} color={colour} />;
};

export const AlgoTitleTag: React.SFC<IAlgoTitleTagProps> = ({
  algo,
  showStatus = true,
  selectAlgo
}: IAlgoTitleTagProps) => {
  const handleSelect = () => {
    selectAlgo(
      algo.medicalSpecialties && algo.medicalSpecialties.length > 0
        ? algo.medicalSpecialties[0].id
        : "undefined",
      algo.id
    );
  };

  return (
    <Tag
      style={{ background: "transparent", color: "var(--charcoal-grey)" }}
      className={"w-100 mv1 ph3"}
      key={algo.id}
      id={algo.id}
      icon={algoIconForStatus(algo.status)}
      interactive={true}
      multiline={true}
      onClick={handleSelect}
    >
      {algo.title}
      {showStatus && <span>{` - (${algoStatusString(algo.status)})`}</span>}
    </Tag>
  );
};
