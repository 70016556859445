import * as Sentry from "@sentry/browser";
import { Epic } from "redux-observable";
import { from, interval, of } from "rxjs";
import {
  catchError,
  debounce,
  filter,
  flatMap,
  ignoreElements,
  map,
  switchMap,
  take
} from "rxjs/operators";
import { ActionType, isActionOf } from "typesafe-actions";

import {
  createAlgorithm,
  deleteAlgorithm,
  getAlgorithm,
  getAlgorithmSearchResults,
  getAlgosByTopic,
  getCommentsForAlgorithm,
  getMyAlgos,
  getTopics,
  retrieveAlgoList,
  saveAlgoState,
  searchAlgoStarts,
  setEditState,
  updateAlgorithmStatus,
  setNodeImage,
  getAlgorithmRevisions
} from "src/actions";
import {
  Algorithm,
  makeApiRequest,
  objectFromPayload,
  requestConfigForAction
} from "src/api";

import {
  AlgoErrorSeverity,
  IAlgoError,
  validateAlgorithm
} from "src/pages/algorithms/editing/validation";
import { IOpenAlgorithm } from "src/store";
import { asyncForEach, sortAlphabetically } from "src/utilities";
import { handleAsyncAction } from ".";

const algosBeingEdited = (openAlgorithms: IOpenAlgorithm[]): IOpenAlgorithm[] =>
  openAlgorithms.filter(oa => oa.editModeActive);

const errorArraysAreEqual = (a: IAlgoError[], b: IAlgoError[]) => {
  const sortedA = a.sort((c, d) =>
    sortAlphabetically(c.issueDetails, d.issueDetails)
  );
  const sortedB = b.sort((c, d) =>
    sortAlphabetically(c.issueDetails, d.issueDetails)
  );
  if (
    sortedA.length !== sortedB.length ||
    sortedA.filter((v, i) => v.issueDetails !== sortedB[i].issueDetails)
      .length > 0
  ) {
    return false;
  }

  return true;
};

export const syntaxCheckerTrigger: Epic = (action$, state$) =>
  state$.pipe(
    map(s => algosBeingEdited(s.algoStore.openAlgorithms)),
    debounce(() => interval(3000)),
    flatMap(async openAlgorithms => {
      const actions: ActionType<any> = [];
      await asyncForEach(openAlgorithms, async oa => {
        try {
          const validationErrors = await validateAlgorithm(oa);
          const errors = validationErrors.filter(
            e => e.severity === AlgoErrorSeverity.Error
          );
          const warnings = validationErrors.filter(
            e => e.severity === AlgoErrorSeverity.Warning
          );
          if (
            !oa.editingState.validationErrors ||
            !errorArraysAreEqual(
              errors,
              oa.editingState.validationErrors.errors
            ) ||
            !errorArraysAreEqual(
              warnings,
              oa.editingState.validationErrors.warnings
            )
          ) {
            actions.push(
              setEditState({
                algoId: oa.algorithm.id,
                state: { validationErrors: { errors, warnings } }
              })
            );
          }
        } catch (error) {
          // tslint:disable-next-line:no-console
          console.error(error);
          // Log to sentry
          Sentry.withScope(() => {
            Sentry.captureException(error);
          });
        }
      });
      return actions;
    }),
    flatMap(ops => (ops.length > 0 ? ops : of(ignoreElements)))
  );

/**
 * Handle the request to load the users' algorithms
 */
export const retrieveAlgosByUserFromApi: Epic = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(getMyAlgos.request)),
    switchMap(() =>
      state$.pipe(
        // Wait for a valid token...
        map(() => [
          state$.value.userStore.loggedInUser,
          state$.value.userStore.authToken
        ]),
        filter(value => value[0] && value[1]),
        take(1),
        switchMap(values =>
          from(
            makeApiRequest(
              requestConfigForAction(
                getMyAlgos.request(values[0].id),
                values[1]
              )
            )
          ).pipe(
            map(response => getMyAlgos.success(response)),
            catchError(error => of(getMyAlgos.failure(error)))
          )
        )
      )
    )
  );

/**
 * Loads any linked algorithms so that we can reference them in the system
 *
 */
export const loadRelatedAlgorithms: Epic = action$ =>
  action$.pipe(
    filter(isActionOf(getAlgorithm.success)),
    map(action => {
      const linkedAlgoIds: string[] = [];
      const algo = objectFromPayload(
        action.payload.apiResponse.data,
        Algorithm
      );
      if (algo && algo.nodes) {
        algo.nodes.forEach(n =>
          n.paths.forEach(p => {
            if (p.targetAlgorithmId) {
              linkedAlgoIds.push(p.targetAlgorithmId);
            }
          })
        );
      }
      return linkedAlgoIds;
    }),
    flatMap(ids =>
      ids.length > 0
        ? ids.map(id => getAlgorithm.request({ id, includeNodes: true }))
        : of(ignoreElements)
    )
  );

/**
 * Handle the request to load the algo list from the backend
 */
export const retrieveAlgoListFromApi: Epic = (action$, state$) =>
  handleAsyncAction(action$, state$, retrieveAlgoList);

/**
 * Handle the request to load the topic list from the backend
 */
export const retrieveTopicListFromApi: Epic = (action$, state$) =>
  handleAsyncAction(action$, state$, getTopics);

/**
 * Handle the request to load the topic algorithms
 */
export const retrieveAlgosByTopicFromApi: Epic = (action$, state$) =>
  handleAsyncAction(action$, state$, getAlgosByTopic);

/**
 * Handle the request to get an algorithm
 */
export const retrieveAlgorithm: Epic = (action$, state$) =>
  handleAsyncAction(action$, state$, getAlgorithm);

/**
 * Handle the request for comments
 */
export const retrieveCommentsForAlgorithm: Epic = (action$, state$) =>
  handleAsyncAction(action$, state$, getCommentsForAlgorithm);

/**
 * Handle the request to get an algorithm
 */
export const searchAlgorithms: Epic = (action$, state$) =>
  handleAsyncAction(action$, state$, getAlgorithmSearchResults);

export const searchAlgorithmStarts: Epic = (action$, state$) =>
  handleAsyncAction(action$, state$, searchAlgoStarts);

export const handleSaveAlgostate: Epic = (action$, state$) =>
  handleAsyncAction(action$, state$, saveAlgoState);

/**
 * Handle creating an algorithm
 */
export const handleCreateAlgo: Epic = (action$, state$) =>
  handleAsyncAction(action$, state$, createAlgorithm);

export const handleAlgoRevisions: Epic = (action$, state$) =>
  handleAsyncAction(action$, state$, getAlgorithmRevisions);

export const handleUpdateAlgoStatus: Epic = (action$, state$) =>
  handleAsyncAction(action$, state$, updateAlgorithmStatus);

export const handleDeleteAlgo: Epic = (action$, state$) =>
  handleAsyncAction(action$, state$, deleteAlgorithm);

export const handleNodeImage: Epic = (action$, state$) =>
  handleAsyncAction(action$, state$, setNodeImage);
