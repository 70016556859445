import { connect } from "react-redux";
import * as React from "react";
import Lightbox from "react-image-lightbox";

import { IStoreState } from "src/store";
import { Media } from "src/api";
import { clearLightboxSource } from "src/actions";

interface IMediaLightBoxInjectedProps {
  media?: { caption: string; media: Media };
}

interface IMediaLightBoxDispatchProps {
  clearLightboxSource: typeof clearLightboxSource;
}

export class MediaLightboxComponent extends React.PureComponent<
  IMediaLightBoxInjectedProps & IMediaLightBoxDispatchProps
> {
  public render() {
    const { media } = this.props;
    const handleClose = () => {
      this.props.clearLightboxSource();
    };
    if (!media) {
      return null;
    }
    return <Lightbox mainSrc={media.media.url} onCloseRequest={handleClose} />;
  }
}

const mapStateToProps = ({ uiStore }: IStoreState) => {
  return {
    media: uiStore.mediaLightboxSource
  };
};

export const MediaLightbox = connect(mapStateToProps, { clearLightboxSource })(
  MediaLightboxComponent
);
