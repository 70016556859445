import DOMPurify from "dompurify";
import marked from "marked";
import * as React from "react";

import { AlgoNode } from "src/api";

export interface INodeDefsProps {
  node: AlgoNode;
  width?: number;
}

export class NodeDefs extends React.PureComponent<INodeDefsProps> {
  public render() {
    const { node, width } = this.props;
    if (!node.definitions) {
      return null;
    }

    const html = DOMPurify.sanitize(marked(node.definitions));
    return (
      <section>
        <p
          style={width ? { width } : undefined}
          dangerouslySetInnerHTML={{ __html: html }}
        />
      </section>
    );
  }
}
