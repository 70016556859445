import { Type } from "class-transformer";
import { Media, User } from "..";
import { BaseModel } from "./object-base";

export const ZxLabelType = {
  KEYWORDS: "keyword" as "keyword",
  REGIONS: "region" as "region",
  SCHOOLS: "medical-school" as "medical-school",
  SPECIALTIES: "medical-specialty" as "medical-specialty"
};
export type ZxLabelType = typeof ZxLabelType[keyof typeof ZxLabelType];

export class Label extends BaseModel {
  public category = "";
  public title = "";
  public topic = "";
  public avatar?: Media;
  public algoCount?: number;

  @Type(() => User)
  public createdBy?: User;
}

export function createNewLabel(title: string, category: ZxLabelType): Label {
  return {
    ...new Label(),
    category,
    title
  };
}

export const specialtyName = (specialty: Label) => {
  if (specialty.topic) {
    return `${specialty.topic} (${specialty.title})`;
  } else {
    return specialty.title;
  }
};

export const filterLabels = (labels: Label[], query: string) => {
  return labels.filter(
    l =>
      l.title.toLowerCase().includes(query.toLowerCase().trim()) ||
      (l.topic && l.topic.toLowerCase().includes(query.toLowerCase().trim()))
  );
};
