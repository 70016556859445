import { Type } from "class-transformer";
import { Dictionary, forOwn } from "lodash";
import { Algorithm, IXmedObjectInfo, User } from "..";
import { AlgoNode } from "./algo-node";
import { BaseModel, objectFromPayload } from "./object-base";

export enum CommentRefType {
  Algorithm = "algorithms",
  Node = "nodes",
  Comment = "comments",
  Unknown = ""
}

export enum CommentKind {
  Comment = "comments",
  Note = "notes",
  Unknown = ""
}

export class Comment extends BaseModel {
  public nodeId = "";
  public algorithmId = "";
  public commentId = "";
  public content = "";
  public title = "";
  public kind: CommentKind = CommentKind.Unknown;
  public hidden = false;

  public linkedRefId = "";
  public linkedRefType: CommentRefType = CommentRefType.Unknown;

  public countThumbsUp = 0;
  public countThumbsDown = 0;

  @Type(() => Comment)
  public comments?: Comment[];

  @Type(() => User)
  public author?: User;
  @Type(() => Algorithm)
  public parentAlgorithm?: Algorithm;
}

export const commentCountForNode = (
  node: AlgoNode,
  type = CommentKind.Comment
) => {
  return node.comments.filter(c => c.kind === type).filter(c => !c.hidden)
    .length;
};

export function algoTitleForComment(
  comment: Comment,
  algorithms: Dictionary<Algorithm>
): string | undefined {
  let algo;

  if (comment.parentAlgorithm) {
    algo = comment.parentAlgorithm;
  } else {
    if (comment.linkedRefType === CommentRefType.Algorithm) {
      algo = algorithms[comment.linkedRefType];
    } else if (comment.linkedRefType === CommentRefType.Node) {
      forOwn(algorithms, algorithm => {
        if (algorithms.nodes) {
          const index = algorithm.nodes.findIndex(
            node => node.id === comment.linkedRefId
          );
          if (index > -1) {
            algo = algorithm;
            return false;
          }
        }
        return true;
      });
    }
  }

  if (algo) {
    return algo.title;
  }

  return undefined;
}

export function commentFromPayload(
  payload: IXmedObjectInfo
): Comment | undefined {
  const comment = objectFromPayload(payload, Comment);
  if (comment) {
    if (comment.linkedRefType === CommentRefType.Node) {
      comment.nodeId = comment.linkedRefId;
    } else if (comment.linkedRefType === CommentRefType.Algorithm) {
      comment.algorithmId = comment.linkedRefId;
    }
    return comment;
  }
  return undefined;
}

export function commentsFromPayload(objectArray: IXmedObjectInfo[]): Comment[] {
  const comments: Comment[] = [];
  objectArray.forEach(payload => {
    const comment = commentFromPayload(payload);
    if (comment) {
      comments.push(comment);
    }
  });
  return comments;
}
