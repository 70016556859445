import {
  Alignment,
  Button,
  Classes,
  Icon,
  Position,
  Tooltip
} from "@blueprintjs/core";
import { IconName, IconNames } from "@blueprintjs/icons";
import { faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { push } from "connected-react-router";
import * as React from "react";
import { connect } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router-dom";

import {
  closeAlgorithm,
  kAlgorithmIdBreadcrumbLocation,
  setNewAlgoDialogState,
  setUserActiveStudy
} from "src/actions";
import {
  Algorithm,
  User,
  userIsAdmin,
  userIsContributor,
  IStudyInfo
} from "src/api";
import {
  adminMenuOptionsForUser,
  IMenuOption,
  kLibraryRootUrl,
  kSearchUrlKey,
  NavigationMenuOptions
} from "src/config/routes";
import { IBreadcrumbLocation, IOpenAlgorithm, IStoreState } from "src/store";

import {
  algoIconForStatus,
  CssSize,
  NewAlgorithmDialog,
  StudySelect
} from "src/components";
import logo from "src/resources/openxmed-logo-orange.svg";
import { PasswordVerifyDialog } from "./studies";

export const kLeftMenuWidthOpen = 280;
export const kLeftMenuWidthClosed = 46;

interface ILeftMenuProps extends RouteComponentProps {
  className?: string;
  cssSize: CssSize;
  onClick?: () => void;
  menuOpen: boolean;
  onMenuToggleButton: () => void;
}

interface ILeftMenuInjectedProps {
  breadCrumbs: IBreadcrumbLocation[];
  openAlgorithms: IOpenAlgorithm[];
  openStudyId?: string;
  recentSearches: string[];
  user?: User;
}

interface ILeftMenuDispatchProps {
  closeAlgorithm: typeof closeAlgorithm;
  push: typeof push;
  setNewAlgoDialogState: typeof setNewAlgoDialogState;
  setUserActiveStudy: typeof setUserActiveStudy;
}

interface ILeftMenuState {
  targetStudyIdAfterConfirm?: IStudyInfo;
}

type LeftMenuPanelProps = ILeftMenuProps &
  ILeftMenuInjectedProps &
  ILeftMenuDispatchProps;

class LeftMenuPanel extends React.PureComponent<
  LeftMenuPanelProps,
  ILeftMenuState
> {
  constructor(props: LeftMenuPanelProps) {
    super(props);
    this.state = {};
  }

  public render() {
    const {
      cssSize,
      className,
      menuOpen,
      onMenuToggleButton,
      openStudyId,
      user
    } = this.props;
    const { targetStudyIdAfterConfirm } = this.state;

    if (!user) {
      return null;
    } else {
      const cssStyle = {
        flexGrow: 0,
        flexShrink: 0,
        width: menuOpen ? kLeftMenuWidthOpen : kLeftMenuWidthClosed
      };
      const cssClass = `${className} ${
        cssSize > 0 ? "vh-100" : ""
      } ph2 flex flex-column items-start justify-between ${Classes.DARK}`;
      const divClass = `flex ${
        cssSize > 0 ? "flex-row-reverse" : ""
      } items-start justify-between`;
      const menuToggleButton = (
        <Button
          className={"mt1 mb3"}
          icon={
            menuOpen
              ? IconNames.DOUBLE_CHEVRON_LEFT
              : IconNames.DOUBLE_CHEVRON_RIGHT
          }
          minimal={true}
          onClick={onMenuToggleButton}
          small={true}
        />
      );
      const adminMenu =
        (userIsAdmin(user) ||
          (user.systemRoles && user.systemRoles.length > 0)) &&
        this.renderAdminMenu();

      const handlePasswordVerify = (studyInfo?: IStudyInfo) => {
        this.props.setUserActiveStudy(studyInfo ? studyInfo.id : undefined);
        this.setState({ targetStudyIdAfterConfirm: undefined });
      };

      return (
        <section style={cssStyle} className={cssClass}>
          <PasswordVerifyDialog
            isOpen={targetStudyIdAfterConfirm !== undefined}
            study={targetStudyIdAfterConfirm}
            onClose={handlePasswordVerify}
          />
          <div className="flex flex-column w-100">
            <div className={divClass}>
              {cssSize > 0 && menuToggleButton}
              <img
                style={{ visibility: menuOpen ? undefined : "hidden" }}
                src={logo}
                className="pl2 pt1 mt2 mb1"
                alt="logo"
              />
            </div>
            {this.renderMainMenu()}
            {!openStudyId && this.renderAlgoSection()}
            {!openStudyId && this.renderRecentSearches()}
          </div>
          {adminMenu}
        </section>
      );
    }
  }

  private renderMainMenu = () => {
    const { location, menuOpen, onClick, openStudyId, user } = this.props;

    const handleChange = (studyInfo?: IStudyInfo) => {
      this.setState({ targetStudyIdAfterConfirm: studyInfo });
      if (!studyInfo) {
        this.props.setUserActiveStudy(undefined);
      }
      if (onClick) onClick();
    };

    return (
      <section className="pt2 pb3 flex-auto">
        <StudySelect
          className="mb1 zx-left-menu-button"
          onChange={handleChange}
          location={location.pathname}
          showText={menuOpen}
          studyId={openStudyId}
          studyInfo={user && user.activeStudyInfo}
        />
        {!openStudyId && NavigationMenuOptions.map(o => this.renderButton(o))}
      </section>
    );
  };

  private renderAlgoSection = () => {
    const {
      cssSize,
      openAlgorithms,
      breadCrumbs,
      menuOpen,
      onClick,
      user
    } = this.props;

    const kNewAlgoButton = "newAlgoButton";
    const showDialog = () => this.props.setNewAlgoDialogState({ open: true });

    const canCreateAlgo = userIsContributor(user) && cssSize > 0;
    if (openAlgorithms.length > 0 || canCreateAlgo) {
      const addButton = () => (
        <Button
          className="mb1 mt3 zx-left-menu-button"
          key={kNewAlgoButton}
          alignText={Alignment.LEFT}
          icon={this.renderIcon(IconNames.ADD)}
          style={{ border: "1px lightGray dashed" }}
          text={menuOpen && "New Algorithm..."}
          title="Create a new algorithm"
          fill={true}
          onClick={showDialog}
        />
      );

      const algoMapper = (a: IOpenAlgorithm) => {
        const topicId =
          a.algorithm.medicalSpecialties.length > 0
            ? a.algorithm.medicalSpecialties[0].id
            : "undefined";
        const clickHandler = () => {
          if (onClick) {
            onClick();
          }
          this.props.push(`${kLibraryRootUrl}/${topicId}/${a.algorithm.id}`);
        };
        const isActive = () => {
          if (breadCrumbs.length > kAlgorithmIdBreadcrumbLocation) {
            const related = breadCrumbs[kAlgorithmIdBreadcrumbLocation]
              .relatedObject as Algorithm;
            if (related) {
              return a.algorithm.id === related.id;
            }
          }
          return false;
        };
        const closeAlgo = (e: React.MouseEvent<HTMLElement>) => {
          e.stopPropagation();
          if (onClick) {
            onClick();
          }
          this.props.closeAlgorithm(a.algorithm.id);
        };

        const closeButton = () => (
          <div onClick={closeAlgo}>
            <FontAwesomeIcon icon={faTimesCircle} />
          </div>
        );
        const button = () => (
          <Button
            active={isActive()}
            className="mb1 zx-left-menu-button"
            key={a.algorithm.id}
            alignText={Alignment.LEFT}
            icon={algoIconForStatus(a.algorithm.status)}
            rightIcon={menuOpen && closeButton()}
            text={menuOpen && a.algorithm.title}
            title={a.algorithm.title}
            fill={true}
            onClick={clickHandler}
          />
        );
        if (menuOpen) {
          return button();
        } else {
          return this.toolTipIt(a.algorithm.title, a.algorithm.id, button());
        }
      };
      const createAlgoButton =
        canCreateAlgo && menuOpen
          ? addButton()
          : this.toolTipIt(
              "Create new algorithm...",
              kNewAlgoButton,
              addButton()
            );

      return (
        <section className="pb3">
          <NewAlgorithmDialog />
          {this.renderMenuTitle("Algorithms")}
          {openAlgorithms.map(algoMapper)}
          {createAlgoButton}
        </section>
      );
    }
    return null;
  };

  private toolTipIt = (content: string, key: string, child: JSX.Element) => {
    return (
      <Tooltip content={content} key={key} position={Position.RIGHT}>
        {child}
      </Tooltip>
    );
  };

  private renderRecentSearches = () => {
    const { menuOpen, onClick, recentSearches } = this.props;

    if (recentSearches.length > 0) {
      const searchMapper = (a: string) => {
        const clickHandler = () => {
          if (onClick) {
            onClick();
          }
          this.props.push(`${kSearchUrlKey}/${a}`);
        };
        const isActive = () => {
          return this.props.location.pathname === `${kSearchUrlKey}/${a}`;
        };

        const button = () => (
          <Button
            active={isActive()}
            className="mb1 zx-left-menu-button"
            key={a}
            alignText={Alignment.LEFT}
            icon={this.renderIcon(IconNames.SEARCH)}
            text={a}
            title={`Search - ${a}`}
            fill={true}
            onClick={clickHandler}
          />
        );

        if (menuOpen) {
          return button();
        } else {
          return (
            <Tooltip
              content={`Search - ${a}`}
              key={a}
              position={Position.RIGHT}
            >
              {button()}
            </Tooltip>
          );
        }
      };
      return (
        <section className="pb3 flex-auto">
          {this.renderMenuTitle("Recent Searches")}
          {recentSearches.map(searchMapper)}
        </section>
      );
    }
    return null;
  };

  private renderMenuTitle = (text: string) => {
    return (
      <p
        className={"ttu pl2 mb1 zx-blue-extra-light"}
        style={{ visibility: this.props.menuOpen ? undefined : "hidden" }}
      >
        {this.props.menuOpen ? text : text.slice(0, 1)}
      </p>
    );
  };

  private renderAdminMenu = () => {
    return (
      <section className="pb3 flex flex-column w-100">
        {this.renderMenuTitle("Administration")}
        {adminMenuOptionsForUser(this.props.user).map(a =>
          this.renderButton(a)
        )}
      </section>
    );
  };

  private renderButton = (config: IMenuOption) => {
    const { menuOpen, onClick } = this.props;

    const clickHandler = () => {
      if (onClick) {
        onClick();
      }
      this.props.push(config.topLevelUrlKey);
    };
    const isActive = this.isActiveLink(config.topLevelUrlKey);

    const button = () => (
      <Button
        active={isActive}
        className="mb1 zx-left-menu-button"
        fill={true}
        alignText={Alignment.LEFT}
        icon={this.renderIcon(config.leftIcon)}
        key={config.topLevelUrlKey}
        text={menuOpen && config.title}
        onClick={clickHandler}
      />
    );

    if (menuOpen) {
      return button();
    } else {
      return (
        <Tooltip
          content={config.title}
          key={config.topLevelUrlKey}
          position={Position.RIGHT}
        >
          {button()}
        </Tooltip>
      );
    }
  };

  private renderIcon = (
    name: IconName | JSX.Element | undefined,
    colour?: string
  ) => <Icon icon={name} color={colour} />;

  private isActiveLink = (linkUrl: string) =>
    this.props.location.pathname.startsWith(linkUrl);
}

const mapStateToProps = ({ algoStore, uiStore, userStore }: IStoreState) => {
  return {
    breadCrumbs: uiStore.breadCrumbs,
    openAlgorithms: algoStore.openAlgorithms,
    openStudyId: userStore.openStudyId,
    recentSearches: uiStore.search.lastSearchTerms,
    user: userStore.loggedInUser
  };
};

export const LeftMenu = withRouter(
  connect(mapStateToProps, {
    closeAlgorithm,
    push,
    setNewAlgoDialogState,
    setUserActiveStudy
  })(LeftMenuPanel)
);
