import { IOpenxmedApiSuccessResponse } from "src/api";
import { createAsyncAction } from "typesafe-actions";

export interface IEventQuery {
  skip?: number;
  take?: number;
  refId?: string;
  sourceRefId?: string;
  id?: string;
  category?: string;
}

export const retrieveEvents = createAsyncAction(
  "retrieveEvents/REQUEST",
  "retrieveEvents/SUCCESS",
  "retrieveEvents/FAILURE"
)<IEventQuery, IOpenxmedApiSuccessResponse, Error>();
