import { plainToClass } from "class-transformer";

import { IXmedObjectInfo } from "..";
import { AlgorithmSearchResultHighlight } from "./algo-search-result-highlight";
import { AlgorithmSearchResultSource } from "./algo-search-result-source";
import { BaseModel } from "./object-base";

export class AlgorithmSearchResult extends BaseModel {
  // tslint:disable:variable-name
  public _index!: string;
  public _type!: string;
  public _id!: string;
  public _score!: number;
  public _source!: AlgorithmSearchResultSource;
  public highlight?: AlgorithmSearchResultHighlight;
}

export function algorithmSearchResultFromPayload({
  attributes,
  id,
  type
}: IXmedObjectInfo): AlgorithmSearchResult {
  const searchResult = plainToClass(AlgorithmSearchResult, { ...attributes });
  return searchResult;
}

export function algorithmSearchResultsFromPayload(
  objectArray: IXmedObjectInfo[]
): AlgorithmSearchResult[] {
  const results: AlgorithmSearchResult[] = [];
  objectArray.forEach(payload =>
    results.push(algorithmSearchResultFromPayload(payload))
  );

  return results;
}
