import {
  Button,
  Classes,
  Dialog,
  H5,
  H6,
  Icon,
  InputGroup,
  Intent,
  Label
} from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import { goBack, push } from "connected-react-router";
import * as React from "react";
import { connect } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router-dom";

import { uniqBy } from "lodash";
import { setUserRole } from "src/actions";
import {
  fullName,
  specialtyName,
  User,
  userIsContributor,
  userMatchFilter,
  UserRole,
  userRoleString
} from "src/api";
import {
  ApprovedUserRoleSelect,
  BanReason,
  BanReasonSelect
} from "src/components";
import { CollapsiblePanel } from "src/components/collapsible-panel";
import { IStoreState } from "src/store";
import { UserProfile } from "../community";

interface IUserVerificationDetailsComponentProps
  extends RouteComponentProps<{ userId: string }> {
  busy: boolean;
  goBack: typeof goBack;
  error?: Error;
  loggedInUser?: User;
  setUserRole: typeof setUserRole.request;
  possibleDuplicates: User[];
  user?: User;
}

interface IUserVerificationDetailsComponentState {
  approveRole?: UserRole;
  banReason?: BanReason;
  confirmApproveOpen: boolean;
  confirmBlockOpen: boolean;
  reasonDetails?: string;
}

class UserVerificationDetailsComponent extends React.PureComponent<
  IUserVerificationDetailsComponentProps,
  IUserVerificationDetailsComponentState
> {
  constructor(props: IUserVerificationDetailsComponentProps) {
    super(props);
    this.state = {
      confirmApproveOpen: false,
      confirmBlockOpen: false
    };
  }

  public componentDidUpdate(prevProps: IUserVerificationDetailsComponentProps) {
    const { error } = this.props;
    if (prevProps.busy && !error) {
      // go back to the list - update succeeded
      this.props.goBack();
    }
  }

  public render() {
    const { busy, possibleDuplicates, user } = this.props;

    if (!user) {
      return null;
    }

    const handleReject = () => this.setState({ confirmBlockOpen: true });
    const handleApprove = () => this.setState({ confirmApproveOpen: true });

    const possDupesRenderer = () => (
      <section className="flex flex-column flex-auto">
        <p className="ttu mb1 zx-blue">Possible duplicate users</p>
        {possibleDuplicates.map(this.renderDupe)}
      </section>
    );

    return (
      <section className="pa2">
        <section className="flex flex-row flex-wrap">
          <UserProfile user={user} showOnlyProfile={true} />
          {this.renderConfirmBlockDialog()}
          {this.renderConfirmApproveDialog()}
          {possibleDuplicates.length > 0 && possDupesRenderer()}
        </section>
        <div className="flex flex-row items-end pa1 pb3">
          <Button
            alignText="left"
            className="pa1 mr1"
            intent={Intent.SUCCESS}
            icon={IconNames.TICK}
            loading={busy}
            onClick={handleApprove}
            small={true}
            text="Approve..."
          />
          <Button
            alignText="left"
            className="pa1 ml2"
            icon={IconNames.REMOVE}
            fill={false}
            loading={busy}
            onClick={handleReject}
            small={true}
            intent={Intent.DANGER}
            text="Reject..."
          />
        </div>
      </section>
    );
  }

  private renderConfirmApproveDialog = () => {
    const { busy, user } = this.props;
    const { approveRole, confirmApproveOpen } = this.state;

    if (!user) {
      return null;
    }

    const handleChange = (value: UserRole) =>
      this.setState({ approveRole: value });

    return (
      <Dialog
        icon={IconNames.TICK_CIRCLE}
        isCloseButtonShown={false}
        isOpen={confirmApproveOpen}
        title="Approve User"
      >
        <div className={`${Classes.DIALOG_BODY}`}>
          <p>Select role for &apos;{user.email}&apos;.</p>
          <ApprovedUserRoleSelect
            disabled={busy}
            onChange={handleChange}
            value={approveRole}
          />
        </div>
        {this.renderApproveFooter()}
      </Dialog>
    );
  };

  private renderApproveFooter = () => {
    const { busy, error, loggedInUser, user } = this.props;
    const { approveRole } = this.state;

    if (!user) {
      return null;
    }

    const handleApprove = () => {
      if (approveRole) {
        this.props.setUserRole({
          ...user,
          role: approveRole,
          roleReason: `Approved to ${userRoleString(approveRole)} by ${fullName(
            loggedInUser
          )}`
        });
      }
    };

    const handleCancel = () => this.setState({ confirmApproveOpen: false });

    return (
      <div className={`${Classes.DIALOG_FOOTER} flex flex-row justify-between`}>
        <Button
          disabled={busy}
          intent={Intent.NONE}
          onClick={handleCancel}
          text={"Cancel"}
        />
        {this.errorRenderer(error)}
        <Button
          className="ml2"
          disabled={busy || !approveRole}
          intent={Intent.PRIMARY}
          loading={busy}
          onClick={handleApprove}
          text="Approve"
        />
      </div>
    );
  };

  private errorRenderer = (e?: Error) =>
    e && e.message.length > 0 && <p className="mt2 orange">{e.message}</p>;

  private renderConfirmBlockDialog = () => {
    const { busy, user } = this.props;
    const { banReason, confirmBlockOpen, reasonDetails } = this.state;

    if (!user) {
      return null;
    }

    const handleChange = (newReason: BanReason) => {
      const newDetails =
        newReason === BanReason.Duplicate
          ? "Duplicate user acct."
          : newReason === BanReason.NotVerified
          ? "Could not verify user"
          : "";
      this.setState({ banReason: newReason, reasonDetails: newDetails });
    };
    const handleReasonUpdated = (e: React.ChangeEvent<HTMLInputElement>) => {
      this.setState({ reasonDetails: e.currentTarget.value });
    };

    const banReasonInputRenderer = (
      <Label className="flex flex-column pt2">
        Details
        <InputGroup
          id="otherReason"
          disabled={busy}
          name="title"
          placeholder="Enter details"
          type="text"
          value={reasonDetails}
          onChange={handleReasonUpdated}
        />
      </Label>
    );
    return (
      <Dialog
        icon={IconNames.BAN_CIRCLE}
        isCloseButtonShown={false}
        isOpen={confirmBlockOpen}
        title="Ban User"
      >
        <div className={`${Classes.DIALOG_BODY}`}>
          <p>This action will permanently block &apos;{user.email}&apos;.</p>
          <BanReasonSelect
            disabled={busy}
            value={banReason}
            onChange={handleChange}
          />
          {banReason === BanReason.Other && banReasonInputRenderer}
        </div>
        {this.renderBanFooter()}
      </Dialog>
    );
  };

  private renderBanFooter = () => {
    const { busy, error, user } = this.props;
    const { banReason, reasonDetails } = this.state;

    if (!user) {
      return null;
    }

    const handleBlock = () => {
      this.props.setUserRole({
        ...user,
        role: UserRole.Disallowed,
        roleReason: reasonDetails
      });
    };

    const handleCancel = () => this.setState({ confirmBlockOpen: false });
    const disabled =
      busy || !banReason || (banReason === BanReason.Other && !reasonDetails);

    return (
      <div className={`${Classes.DIALOG_FOOTER} flex flex-row justify-between`}>
        <Button
          disabled={busy}
          intent={Intent.NONE}
          onClick={handleCancel}
          text={"Cancel"}
        />
        {this.errorRenderer(error)}
        <Button
          className="ml2"
          disabled={disabled}
          intent={Intent.PRIMARY}
          loading={busy}
          onClick={handleBlock}
          text="Reject"
        />
      </div>
    );
  };

  private renderDupe = (user: User) => {
    return (
      <div
        className="flex flex-row w-100 pa1 br2 zx-bg-edit-grey"
        id={user.id}
        key={user.id}
      >
        <CollapsiblePanel
          className="w-100"
          expandInitially={true}
          title={fullName(user)}
          titleNode={this.dupeTitle(user)}
        >
          {this.dupeDetails(user)}
        </CollapsiblePanel>
      </div>
    );
  };

  private dupeTitle = (user: User) => {
    const avatarUrl = user.avatar && user.avatar.thumb;
    const urlRendererer = (url?: string) =>
      avatarUrl ? (
        <img
          alt="avatar"
          className="h2 w2 br-100 ma2"
          draggable={false}
          src={url}
        />
      ) : (
        <Icon className="h2 w2 ma2" icon={IconNames.USER} iconSize={32} />
      );

    return (
      <section className="flex flex-auto justify-between">
        <section className="flex flex-column flex-auto ph2">
          <H5 className="ttc" style={{ marginBottom: 2 }}>
            <span>{fullName(user)}</span>
          </H5>
          <H6>{userRoleString(user.role)}</H6>
        </section>
        {urlRendererer(avatarUrl)}
      </section>
    );
  };

  private dupeDetails = ({
    city,
    region,
    email,
    title,
    medicalSchools,
    medicalSpecialties
  }: User) => {
    const medSchools = this.renderField(
      "Medical Schools",
      medicalSchools.map(s => s.title).join(", ")
    );
    const specialties = this.renderField(
      "Medical Specialties",
      medicalSpecialties.map(specialtyName).join(", ")
    );
    return (
      <section className="pa2">
        {this.renderField("City", city)}
        {this.renderField("Region", region)}
        {this.renderField("Email", email)}
        {this.renderField("Occupation", title)}
        {medSchools}
        {specialties}
      </section>
    );
  };

  private renderField = (text: string, value?: string) => {
    return (
      <p style={{ marginBottom: "2px" }}>
        <span className="pb1 zx-charcoal-grey ttc">{text}: </span>
        {value || "(Not entered)"}
      </p>
    );
  };
}

const mapStateToProps = (
  { userStore, usersStore, uiStore }: IStoreState,
  ownProps: IUserVerificationDetailsComponentProps
) => {
  const {
    match: {
      params: { userId }
    }
  } = ownProps;

  const user = usersStore.allUsers[userId];

  const possibleDuplicates = uniqBy(
    Object.values(usersStore.allUsers)
      .filter(u => userMatchFilter(u, fullName(user)))
      .filter(u => u.id !== (user ? user.id : undefined))
      .filter(userIsContributor),
    v => v.id
  );

  return {
    busy: uiStore.community.userLoadersCount > 0,
    error: uiStore.error,
    loggedInUser: userStore.loggedInUser,
    possibleDuplicates,
    user
  };
};

export const UserVerificationDetails = withRouter(
  connect(mapStateToProps, {
    goBack,
    push,
    setUserRole: (user: User) => setUserRole.request(user)
  })(UserVerificationDetailsComponent)
);
