import { getType } from "typesafe-actions";

import { omit } from "lodash";
import {
  clearParamsError,
  createParameter,
  createParameterUnit,
  deleteParameter,
  deleteParameterUnit,
  ReduxAction,
  retrieveParameters,
  retrieveUnits,
  updateParameter,
  updateParameterUnit
} from "src/actions";
import {
  dictionaryFromPayload,
  objectFromPayload,
  Parameter,
  ParameterUnit
} from "src/api";
import { IParamsStore } from "src/store";

const INITIAL_STATE: IParamsStore = {
  params: {},
  units: {}
};

export const parametersReducer = (
  paramsStore: IParamsStore = INITIAL_STATE,
  action: ReduxAction
) => {
  switch (action.type) {
    case getType(updateParameterUnit.request):
    case getType(createParameterUnit.request):
    case getType(retrieveUnits.request):
    case getType(updateParameter.request):
    case getType(createParameter.request):
    case getType(retrieveParameters.request):
    case getType(deleteParameter.request):
    case getType(deleteParameterUnit.request):
      return {
        ...paramsStore,
        error: undefined
      };

    case getType(updateParameterUnit.success):
    case getType(createParameterUnit.success): {
      const unit = objectFromPayload(
        action.payload.apiResponse.data,
        ParameterUnit
      );
      if (unit) {
        return {
          ...paramsStore,
          units: { ...paramsStore.units, [unit.id]: unit }
        };
      }
      return paramsStore;
    }

    case getType(retrieveUnits.success):
      const fetchedUnits = dictionaryFromPayload(
        action.payload.apiResponse.data,
        ParameterUnit
      );
      return {
        ...paramsStore,
        units: { ...paramsStore.units, ...fetchedUnits }
      };

    case getType(updateParameter.success):
    case getType(createParameter.success): {
      const param = objectFromPayload(
        action.payload.apiResponse.data,
        Parameter
      );
      if (param) {
        return {
          ...paramsStore,
          params: { ...paramsStore.params, [param.id]: param }
        };
      }
      return paramsStore;
    }

    case getType(retrieveParameters.success):
      const fetchedParams = dictionaryFromPayload(
        action.payload.apiResponse.data,
        Parameter
      );
      return {
        ...paramsStore,
        params: { ...paramsStore.params, ...fetchedParams }
      };

    case getType(deleteParameter.success):
      return {
        ...paramsStore,
        params: omit(paramsStore.params, action.payload.tag)
      };

    case getType(deleteParameterUnit.success):
      return {
        ...paramsStore,
        units: omit(paramsStore.units, action.payload.tag)
      };

    case getType(clearParamsError):
      return {
        ...paramsStore,
        error: undefined
      };

    case getType(deleteParameter.failure):
    case getType(deleteParameterUnit.failure):
      return {
        ...paramsStore,
        error: action.payload.error
      };

    case getType(createParameter.failure):
    case getType(createParameterUnit.failure):
    case getType(updateParameter.failure):
    case getType(updateParameterUnit.failure):
    case getType(retrieveParameters.failure):
    case getType(retrieveUnits.failure):
      return {
        ...paramsStore,
        error: action.payload
      };
  }
  return paramsStore;
};
