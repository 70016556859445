import { Button, Intent, Alert } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import * as React from "react";

import { IOpenAlgorithm } from "src/store";
import { AlgoNode, AlgoNodePath } from "src/api";
import { NodeVarCalculation } from ".";
import { setEditState, removePath } from "src/actions";
import { nodeTextPartsPlain } from "src/utilities";

export interface INodeEditRulesPanelProps {
  containedNode?: AlgoNode;
  setEditState: typeof setEditState;
  updateNode: (node: AlgoNode) => void;
  openAlgorithm: IOpenAlgorithm;
  path?: AlgoNodePath; // Path to operate on
  removePath: typeof removePath;
}

interface INodeEditRulesPanelState {
  pathToRemove?: AlgoNodePath;
}

export class NodeEditRulesPanel extends React.PureComponent<
  INodeEditRulesPanelProps,
  INodeEditRulesPanelState
> {
  constructor(props: INodeEditRulesPanelProps) {
    super(props);
    this.state = {};
  }

  public render() {
    const { containedNode, openAlgorithm, path } = this.props;
    const { algoNodes } = openAlgorithm;

    if (!(path && containedNode)) {
      return null;
    }
    const parentNode = algoNodes[path.parentId];

    const calcs = parentNode
      .contained(algoNodes)
      .filter(p => p.path.id === path.id)
      .filter(p => p.path.paramsJson && p.path.paramsJson.length > 0);
    const text = calcs.length > 0 ? "Display only" : "Always Displayed";
    const removeSharedText = () => this.setState({ pathToRemove: path });

    return (
      <section
        key={path.id}
        className="flex flex-column flex-auto justify-between pb2 zx-bg-charcoal-grey"
      >
        {this.renderDeleteConfirmDialog()}
        <div className="flex flex-column flex-auto">
          <div className="flex flex-row items-start justify-between pb1">
            <p className="flex flex-auto ">{text}</p>
          </div>
          <NodeVarCalculation
            hideDragHandle={true}
            key={path.id}
            path={path}
            openAlgorithm={openAlgorithm}
            parentNode={parentNode}
          />
        </div>
        <div className="flex flex-column w-100">
          <Button
            icon={IconNames.LABEL}
            minimal={true}
            onClick={this.editSharedText}
            text="Edit Shared Text"
          />
          <Button
            className="mt1"
            icon={IconNames.DELETE}
            intent={Intent.DANGER}
            onClick={removeSharedText}
            text="Remove Shared Text"
          />
        </div>
      </section>
    );
  }

  private renderDeleteConfirmDialog = () => {
    const { pathToRemove } = this.state;
    const {
      openAlgorithm: {
        algoNodes,
        algorithm: { id }
      }
    } = this.props;

    const handleCancelDelete = () => this.setState({ pathToRemove: undefined });
    const handleConfirmDelete = () => {
      if (pathToRemove) {
        this.props.removePath({ algoId: id, path: pathToRemove });
        this.setState({ pathToRemove: undefined });
      }
    };
    const parent =
      pathToRemove && pathToRemove.childId && algoNodes[pathToRemove.childId];
    const parentTitle = parent
      ? nodeTextPartsPlain(parent).title
      : "the parent";

    return (
      <Alert
        canEscapeKeyCancel={true}
        canOutsideClickCancel={true}
        cancelButtonText="Cancel"
        onCancel={handleCancelDelete}
        confirmButtonText="Delete"
        onConfirm={handleConfirmDelete}
        isOpen={pathToRemove !== undefined}
        icon={IconNames.TRASH}
        intent={Intent.DANGER}
      >
        <p>
          This will remove the shared text from {parentTitle}. Are you sure?
        </p>
      </Alert>
    );
  };

  private editSharedText = () => {
    const {
      openAlgorithm: { algorithm },
      path
    } = this.props;
    if (path) {
      this.props.setEditState({
        algoId: algorithm.id,
        state: { editSharedNodeId: path.childId }
      });
    }
  };
}
