import { routerMiddleware } from "connected-react-router";
import { createBrowserHistory } from "history";
import * as localForage from "localforage";
import { applyMiddleware, compose, createStore } from "redux";
import { createEpicMiddleware } from "redux-observable";
import {
  createMigrate,
  PersistedState,
  persistReducer,
  persistStore
} from "redux-persist";
import autoMergeLevel2 from "redux-persist/es/stateReconciler/autoMergeLevel2";

import thunk from "redux-thunk";

import { rootEpic } from "src/epics";
import createRootReducer from "src/reducers";

const migrations = {
  3: (/*state: PersistedState*/): PersistedState => {
    // Basically clearing the state
    return { _persist: { version: 3, rehydrated: false } };
  }
};
const persistConfig = {
  key: "root",
  migrate: createMigrate(migrations, { debug: true }),
  stateReconciler: autoMergeLevel2,
  storage: localForage,
  version: 3,
  whitelist: ["userStore"]
};

export const configureStore = () => {
  const history = createBrowserHistory();
  const rootReducer = createRootReducer(history);
  const persistedReducer = persistReducer(persistConfig, rootReducer);
  const epicMiddleware = createEpicMiddleware();

  // Enable chrome debug tools in dev mode
  const composeEnhancers =
    process.env.NODE_ENV === "development"
      ? // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (window && (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) ||
        compose
      : compose;

  const store = createStore(
    persistedReducer,
    {},
    composeEnhancers(
      applyMiddleware(
        routerMiddleware(history), // history manipulation
        thunk,
        epicMiddleware
      )
    )
  );

  const persistor = persistStore(store);

  epicMiddleware.run(rootEpic);

  return {
    history,
    persistor,
    store
  };
};

export default configureStore;
