import { Button, Classes, Dialog, Intent } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import * as React from "react";
import { Dictionary } from "lodash";
import { connect } from "react-redux";

import { IStoreState } from "src/store";
import { Study, User, IStudyInfo } from "src/api";
import { updateStudy, clearStudyError } from "src/actions";
import { PasswordValidator } from "src/components";

interface IPasswordVerifyDialogProps {
  isOpen: boolean;
  study?: IStudyInfo;
  onClose: (studyInfo?: IStudyInfo) => void;
}

interface IPasswordVerifyDialogInjectedProps {
  error?: Error;
  loggedInUser?: User;
  studies: Dictionary<Study>;
}

interface IPasswordVerifyDispatchProps {
  clearStudyError: typeof clearStudyError;
  updateStudy: typeof updateStudy.request;
}

interface IPasswordVerifyDialogComponentState {
  busy: boolean;
  passwordText?: string;
  passwordValidated: boolean;
}

type PasswordVerifyDialogProps = IPasswordVerifyDialogProps &
  IPasswordVerifyDialogInjectedProps &
  IPasswordVerifyDispatchProps;

class PasswordVerifyDialogComponent extends React.PureComponent<
  PasswordVerifyDialogProps,
  IPasswordVerifyDialogComponentState
> {
  private passwordField = React.createRef<HTMLInputElement>();
  private passwordValidator = React.createRef<PasswordValidator>();

  constructor(props: PasswordVerifyDialogProps) {
    super(props);

    this.state = {
      busy: false,
      passwordValidated: false
    };
    this.props.clearStudyError();
  }

  public componentDidMount() {
    this.setFocus();
  }

  public componentDidUpdate(prevProps: PasswordVerifyDialogProps) {
    const { error, isOpen } = this.props;

    if (!prevProps.isOpen && isOpen) {
      if (error) {
        this.props.clearStudyError();
      }
      this.setFocus();
    }
  }

  public render() {
    const { isOpen, study } = this.props;

    return (
      <Dialog
        autoFocus={false}
        enforceFocus={false}
        icon={IconNames.STACKED_CHART}
        title={`Switch to study "${study ? study.title : ""}"`}
        isCloseButtonShown={false}
        isOpen={isOpen}
        onOpened={this.setFocus}
        onClose={this.closeDialog}
      >
        {this.renderBody()}
        {this.renderFooter()}
      </Dialog>
    );
  }

  private setFocus = () => {
    setTimeout(() => {
      if (this.passwordField.current) {
        this.passwordField.current.focus();
      }
    }, 100);
  };

  private renderFooter = () => {
    const { error } = this.props;
    const { busy } = this.state;

    const errorRenderer = (e: Error) => (
      <span className="mh2 orange">{e.message}</span>
    );

    return (
      <div className={`${Classes.DIALOG_FOOTER}`}>
        {error && errorRenderer(error)}
        <div className="flex flex-row justify-between pt2">
          <Button id="cancel" text="Cancel" onClick={this.closeDialog} />
          <Button
            intent={Intent.SUCCESS}
            text="Confirm"
            loading={busy}
            onClick={this.validatePassword}
          />
        </div>
      </div>
    );
  };

  private renderBody = () => {
    const { busy } = this.state;

    return (
      <section className={`${Classes.DIALOG_BODY} overflow-y-auto`}>
        <section className="pv2 flex flex-row w-100">
          <PasswordValidator
            ref={this.passwordValidator}
            disabled={busy}
            inputRef={this.passwordField}
            loggedInUser={this.props.loggedInUser}
            onSubmit={this.validatePassword}
          />
        </section>
      </section>
    );
  };

  private closeDialog = async () => {
    this.props.onClose();
  };

  private validatePassword = async () => {
    if (this.passwordValidator.current) {
      this.setState({ busy: true });
      const resultOrError = await this.passwordValidator.current.submit();
      if (resultOrError as string) {
        if (resultOrError === this.props.loggedInUser!.email) {
          this.props.onClose(this.props.study);
        }
      }
      this.setState({ busy: false });
    }
  };
}

const mapStateToProps = ({ studiesStore, userStore }: IStoreState) => {
  return {
    error: studiesStore.error,
    loggedInUser: userStore.loggedInUser,
    studies: studiesStore.studies
  };
};

export const PasswordVerifyDialog = connect(mapStateToProps, {
  updateStudy: updateStudy.request,
  clearStudyError
})(PasswordVerifyDialogComponent);
