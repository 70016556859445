import { Classes, H4, H6, Icon, Spinner, Tag } from "@blueprintjs/core";
import { push } from "connected-react-router";
import * as React from "react";
import { connect } from "react-redux";

import { IconNames } from "@blueprintjs/icons";
import {
  Algorithm,
  AlgorithmStatus,
  Label,
  User,
  userIsContributor
} from "src/api";
import { AlgoTitleTag } from "src/components";
import { kLibraryRootUrl } from "src/config/routes";
import { IStoreState } from "src/store";
import { sortAlphabetically } from "src/utilities";

interface ITopicsComponentProps {
  authoredAlgorithms: Algorithm[];
  editedAlgorithms: Algorithm[];
  favouriteAlgorithms: Algorithm[];
  awaitingReviewAlgorithms: Algorithm[];
  push: typeof push;
  topics: Label[];
  user?: User;
}

class TopicsComponent extends React.PureComponent<ITopicsComponentProps> {
  public render() {
    const { awaitingReviewAlgorithms, topics } = this.props;

    const rendermapper = (a: Algorithm) => (
      <AlgoTitleTag
        algo={a}
        key={a.id}
        selectAlgo={this.selectAlgo}
        showStatus={false}
      />
    );

    const awaitingReviewItems = awaitingReviewAlgorithms.length > 0 && (
      <div className="mv2 bg-white pa3 br3 zx-shadow">
        <H4 className="ttu">Awaiting Review</H4>
        <section className="">
          {awaitingReviewAlgorithms.map(rendermapper)}
        </section>
      </div>
    );

    return (
      <section className="flex flex-column flex-row-l items-stretch flex-auto">
        <section className="w-30-l pa1">
          <div className="bg-white pa3 br3 zx-shadow">
            <H4 className="ttu">Your Algorithms</H4>
            {this.renderYourAlgorithms(this.props)}
          </div>
          {awaitingReviewItems}
        </section>
        <section className="w-70-l pa1">
          <div className="bg-white pa3 br3 zx-shadow">
            <H4 className="ttu">Topics</H4>
            {this.renderTopics(topics)}
          </div>
        </section>
      </section>
    );
  }

  private selectTopic = (e: React.MouseEvent<HTMLDivElement>) => {
    this.props.push(`${kLibraryRootUrl}/${e.currentTarget.id}`);
  };

  private selectAlgo = (topicId: string, algoId: string) => {
    this.props.push(`${kLibraryRootUrl}/${topicId}/${algoId}`);
  };

  private renderTopics = (topics: Label[], singleColumn?: boolean) => {
    if (!topics || topics.length === 0) {
      return <Spinner />;
    }
    const topicMapper = (t: Label) => (
      <Tag
        style={{ background: "transparent" }}
        className={`w-100 ${!singleColumn && "w-50-ns"} mv1 ph3`}
        key={t.id}
        id={t.id}
        icon={<Icon icon={IconNames.LAYERS} color="var(--app-blue)" />}
        interactive={true}
        multiline={true}
        onClick={this.selectTopic}
      >
        {t.topic
          ? `${t.topic}: ${t.title}` // ${t.algoCount ? `(${t.algoCount})` : ""}`
          : `${t.title}` /* (${t.algoCount})`*/}
      </Tag>
    );

    return (
      <section
        className={`${Classes.DARK} zx-enable-smooth-scroll flex flex-wrap dt animated fadeIn faster`}
      >
        {topics.map(topicMapper)}
      </section>
    );
  };

  private renderYourAlgorithms = (props: ITopicsComponentProps) => {
    const { authoredAlgorithms, editedAlgorithms, favouriteAlgorithms } = props;

    const workInProgress = authoredAlgorithms.filter(
      a => a.status !== AlgorithmStatus.published
    );
    const published = authoredAlgorithms.filter(
      a => a.status === AlgorithmStatus.published
    );
    const forReview = editedAlgorithms.filter(
      a => a.status !== AlgorithmStatus.published
    );

    const tagMapper = (a: Algorithm, showStatus?: boolean) => (
      <AlgoTitleTag
        algo={a}
        key={a.id}
        selectAlgo={this.selectAlgo}
        showStatus={showStatus}
      />
    );

    const favesSection = (
      <section className="pv2">
        <H6 className="ttu">Favourites</H6>
        {favouriteAlgorithms.map(a => tagMapper(a))}
      </section>
    );
    const wipSection = (
      <section className="pv2">
        <H6 className="ttu">Work In Progress</H6>
        {workInProgress.map(a => tagMapper(a))}
      </section>
    );
    const reviewSection = (
      <section className="pv2">
        <H6 className="ttu">To Review</H6>
        {forReview.map(a => tagMapper(a))}
      </section>
    );
    const publishedSection = (
      <section className="pv2">
        <H6 className="ttu">Published</H6>
        {published.map(a => tagMapper(a, false))}
      </section>
    );
    return (
      <section className={"zx-enable-smooth-scroll animated fadeIn faster"}>
        {favouriteAlgorithms.length > 0 && favesSection}
        {workInProgress.length > 0 && wipSection}
        {forReview.length > 0 && reviewSection}
        <section className="pv2">
          <H6 className="ttu">Saved</H6>
          <p>You have no saved algorithms.</p>
        </section>
        {published.length > 0 && publishedSection}
      </section>
    );
  };
}

const mapStateToProps = ({ algoStore, userStore }: IStoreState) => {
  const { allAlgorithms } = algoStore;
  const { loggedInUser, faveAlgos } = userStore;

  const editedAlgorithms: Algorithm[] = [];
  const authoredAlgorithms: Algorithm[] = [];
  const awaitingReviewAlgorithms: Algorithm[] = [];
  const allAlgosArray = Object.values(allAlgorithms)
    .filter(a => a.status !== AlgorithmStatus.superceded)
    .sort((a, b) => sortAlphabetically(a.title, b.title));

  allAlgosArray.forEach(a => {
    if (a.authors.find(e => e.id === (loggedInUser && loggedInUser.id))) {
      authoredAlgorithms.push(a);
    } else if (
      a.editors.find(e => e.id === (loggedInUser && loggedInUser.id))
    ) {
      editedAlgorithms.push(a);
    }
    if (
      loggedInUser &&
      userIsContributor(loggedInUser) &&
      a.status === AlgorithmStatus.awaitingReview
    ) {
      awaitingReviewAlgorithms.push(a);
    }
  });

  const newTopics = Object.values(algoStore.topics);

  const topics = newTopics.sort((a, b) => {
    const topicA = a.topic.toLowerCase();
    const topicB = b.topic.toLowerCase();
    if (topicA > topicB) {
      return 1;
    }
    if (topicA < topicB) {
      return -1;
    }
    return 0;
  });

  const favourites = faveAlgos
    .map(fId => algoStore.allAlgorithms[fId])
    .filter(a => a);

  return {
    authoredAlgorithms,
    awaitingReviewAlgorithms,
    editedAlgorithms,
    favouriteAlgorithms: favourites,
    topics,
    user: loggedInUser
  };
};

export const Topics = connect(mapStateToProps, { push })(TopicsComponent);
