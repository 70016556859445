import { getType } from "typesafe-actions";

import {
  addComment,
  changeAlgoPage,
  changeAlgoVar,
  clearAlgoError,
  clearQueueErrors,
  closeAlgorithm,
  createAlgorithm,
  createBackendComment,
  createBackendNode,
  createBackendPath,
  createNode,
  createPath,
  deleteAlgorithm,
  deleteBackendComment,
  deleteBackendNode,
  deleteBackendPath,
  getAlgorithm,
  getAlgorithmSearchResults,
  getAlgosByTopic,
  getCommentsForAlgorithm,
  getMyAlgos,
  getTopics,
  linkNodes,
  logout,
  processQueue,
  ReduxAction,
  removeNodes,
  removePath,
  reorderPaths,
  resetStore,
  retrieveAlgoList,
  saveAlgoState,
  setCssSize,
  setEditState,
  tappedInfoOrComments,
  tappedNode,
  toggleEditMode,
  updateAlgo,
  updateAlgorithmStatus,
  updateBackendAlgorithm,
  updateBackendComment,
  updateBackendNode,
  updateBackendPath,
  updateComment,
  updateConsumePanelFocus,
  updateInProgressComment,
  updateNodes,
  updateNodeText,
  updatePath,
  containNode,
  setNodeImage,
  getUserFavouriteAlgorithms,
  setUserFavouriteAlgorithms
} from "src/actions";
import {
  AlgoNode,
  Algorithm,
  algorithmSearchResultsFromPayload,
  AlgoState,
  commentsFromPayload,
  dictionaryFromPayload,
  kAlgosFetchSize,
  kFromAlgo,
  Label,
  objectFromPayload,
  variableValue
} from "src/api";

import { keyBy, omit, Dictionary, cloneDeep } from "lodash";
import { IAlgoStore } from "src/store";
import {
  updatedOpenAlgoArrayProcessingFailure,
  updatedOpenAlgoArrayQueuingNext
} from ".";
import {
  handleChangePage,
  handleCommentChange,
  insertOrUpdateComment,
  setEditStateForAlgo,
  toggleEditForAlgo,
  updatedOpenAlgoArray,
  updatedOpenAlgoArrayChangingVariable,
  updatedOpenAlgoArrayCreatingComment,
  updatedOpenAlgoArrayCreatingPath,
  updatedOpenAlgoArrayForNodeText,
  updatedOpenAlgoArrayLinkingNodes,
  updatedOpenAlgoArrayRemovingNodes,
  updatedOpenAlgoArrayUpdatingAlgo,
  updatedOpenAlgoArrayUpdatingComment,
  updatedOpenAlgoArrayWithNodes,
  updatedOpenAlgoArrayWithPanelFocus,
  updatedOpenAlgoArrayWithPathAction,
  updatedOpenAlgoArrayWithPathReorder,
  updatedOpenAlgoArrayContainingNode
} from "./algorithm-updaters";
import {
  algoStoreUpdatedWithPayload,
  updatedOpenAlgoArrayClearingQueue,
  updateAlgoWithNode
} from "./algorithm-updaters/event-queue";
import {
  handleTappedInfoNode,
  handleTappedNode,
  removeOpenAlgoFromArray,
  updatedOpenAlgoArrayWithPersistedState
} from "./algorithm-updaters/handle-consumption";
import { accessLoggedInUser } from "./user-reducer";

const INITIAL_STATE: IAlgoStore = {
  allAlgorithms: {},
  fetchOffset: 0,
  moreAvailable: true,
  openAlgorithms: [],
  searchResults: [],
  topics: {}
};

const topicsFromAlgos = (
  algos: Dictionary<Algorithm>,
  topics: Dictionary<Label>
) => {
  const newTopics = cloneDeep(topics);
  Object.keys(algos).forEach(k => {
    const algo = algos[k];
    algo.medicalSpecialties.forEach(ms => {
      const existing = newTopics[ms.id];
      if (!existing) {
        newTopics[ms.id] = ms;
      }
    });
  });
  return newTopics;
};

const deleteAlgo = (algoUid: string | undefined, algoStore: IAlgoStore) => {
  if (algoUid) {
    return {
      ...algoStore,
      allAlgorithms: omit(algoStore.allAlgorithms, algoUid),
      openAlgorithms: algoStore.openAlgorithms.filter(
        oa => oa.algorithm.id !== algoUid
      )
    };
  }
  return algoStore;
};

export const algorithmReducer = (
  algoStore: IAlgoStore = INITIAL_STATE,
  action: ReduxAction
): IAlgoStore => {
  const loggedInUser = accessLoggedInUser();

  if (!loggedInUser) {
    return algoStore;
  }

  switch (action.type) {
    case getType(logout):
      return INITIAL_STATE;

    case getType(setCssSize):
      // Edit mode not supported on small screens.
      if (action.payload === 0) {
        return {
          ...algoStore,
          openAlgorithms: algoStore.openAlgorithms.map(oa => ({
            ...oa,
            editModeActive: false
          }))
        };
      } else {
        return algoStore;
      }

    case getType(resetStore):
      return {
        ...algoStore,
        openAlgorithms: [],
        searchResults: []
      };

    case getType(clearAlgoError):
      return {
        ...algoStore,
        error: undefined
      };

    case getType(tappedNode):
      return {
        ...algoStore,
        openAlgorithms: handleTappedNode(
          action.payload,
          algoStore.openAlgorithms
        )
      };

    case getType(tappedInfoOrComments):
      return {
        ...algoStore,
        openAlgorithms: handleTappedInfoNode(
          action.payload,
          algoStore.openAlgorithms
        )
      };
    case getType(changeAlgoPage):
      return {
        ...algoStore,
        openAlgorithms: handleChangePage(
          action.payload,
          algoStore.openAlgorithms
        )
      };

    case getType(closeAlgorithm):
      return {
        ...algoStore,
        openAlgorithms: removeOpenAlgoFromArray(
          algoStore.openAlgorithms,
          action.payload
        )
      };

    case getType(updateInProgressComment):
      return {
        ...algoStore,
        openAlgorithms: handleCommentChange(
          action.payload,
          algoStore.openAlgorithms
        )
      };

    case getType(getTopics.success):
      const fetchedTopics = dictionaryFromPayload(
        action.payload.apiResponse.data,
        Label
      );

      return {
        ...algoStore,
        topics: fetchedTopics
      };

    case getType(retrieveAlgoList.success):
    case getType(getAlgosByTopic.success):
    case getType(getMyAlgos.success):
    case getType(getUserFavouriteAlgorithms.success):
    case getType(setUserFavouriteAlgorithms.success):
      const fetchedAlgos = dictionaryFromPayload(
        action.payload.apiResponse.data,
        Algorithm
      );

      const algoCount = Object.keys(fetchedAlgos).length;
      const updatedAllAlgos = {
        ...algoStore.allAlgorithms,
        ...fetchedAlgos
      };

      const updatedTopics = topicsFromAlgos(fetchedAlgos, algoStore.topics);

      return {
        ...algoStore,
        allAlgorithms: updatedAllAlgos,
        fetchOffset: Object.keys(updatedAllAlgos).length,
        moreAvailable: !(algoCount < kAlgosFetchSize),
        topics: updatedTopics
      };

    case getType(getAlgorithm.success):
    case getType(createAlgorithm.success): {
      const fullAlgo = objectFromPayload(
        action.payload.apiResponse.data,
        Algorithm
      );

      const urlParams = new URLSearchParams(window.location.search);

      if (fullAlgo) {
        let openAlgorithms = updatedOpenAlgoArray({
          loggedInUser,
          openAlgos: algoStore.openAlgorithms,
          updatedAlgo: fullAlgo
        });

        if (urlParams.has(kFromAlgo)) {
          openAlgorithms = openAlgorithms.map(oa => {
            if (oa.algorithm.id !== fullAlgo.id) {
              return oa;
            }

            // Find the previous decisions
            const linkingOpenAlgo = openAlgorithms.find(
              loa => loa.algorithm.id === urlParams.get(kFromAlgo)
            );

            if (linkingOpenAlgo) {
              // Copy the previous history into that algorithm for reference
              const variableValues = keyBy(
                oa.algorithm.variables
                  .map(v => ({
                    values: variableValue(
                      linkingOpenAlgo.algoState.decisionsJson,
                      v.id
                    )[0],
                    variableId: v.id
                  }))
                  .filter(o => o.values)
                  .map(v => ({
                    values: v.values.value.values,
                    variableId: v.variableId
                  })),
                "variableId"
              );
              return {
                ...oa,
                algoState: {
                  ...oa.algoState,
                  decisionsJson: oa.algoState.decisionsJson
                },
                variableValues
              };
            }
            return oa;
          });
        }

        return {
          ...algoStore,
          allAlgorithms: {
            ...algoStore.allAlgorithms,
            [fullAlgo.id]: fullAlgo
          },
          openAlgorithms
        };
      }
      return algoStore;
    }

    case getType(updateAlgorithmStatus.success): {
      const updatedAlgo = objectFromPayload(
        action.payload.apiResponse.data,
        Algorithm
      );

      if (updatedAlgo) {
        return {
          ...algoStore,
          allAlgorithms: {
            ...algoStore.allAlgorithms,
            [updatedAlgo.id]: updatedAlgo
          },
          openAlgorithms: updatedOpenAlgoArray({
            createOnly: false,
            loggedInUser,
            openAlgos: algoStore.openAlgorithms,
            updatedAlgo
          })
        };
      }
      return algoStore;
    }

    case getType(deleteAlgorithm.success): {
      const deletedAlgo = objectFromPayload(
        action.payload.apiResponse.data,
        Algorithm
      );
      if (deletedAlgo) {
        return deleteAlgo(deletedAlgo.id, algoStore);
      }
      return algoStore;
    }

    case getType(saveAlgoState.success):
      const algoState = objectFromPayload(
        action.payload.apiResponse.data,
        AlgoState
      );

      if (algoState) {
        return {
          ...algoStore,
          openAlgorithms: updatedOpenAlgoArrayWithPersistedState(
            algoStore.openAlgorithms,
            algoState
          )
        };
      }
      return algoStore;

    case getType(getCommentsForAlgorithm.success): {
      const comments = commentsFromPayload(action.payload.apiResponse.data);
      let updatedStore = { ...algoStore };
      comments.forEach(c => {
        updatedStore = insertOrUpdateComment(c, updatedStore, loggedInUser);
      });

      return updatedStore;
    }

    case getType(getAlgorithmSearchResults.success):
      return {
        ...algoStore,
        searchResults: algorithmSearchResultsFromPayload(
          action.payload.apiResponse.data
        )
      };

    case getType(updateConsumePanelFocus):
      return {
        ...algoStore,
        openAlgorithms: updatedOpenAlgoArrayWithPanelFocus(
          algoStore.openAlgorithms,
          action.payload.algoId,
          action.payload.focus,
          action.payload.firstNavigation
        )
      };

    case getType(toggleEditMode):
      return {
        ...algoStore,
        openAlgorithms: toggleEditForAlgo(
          action.payload,
          algoStore.openAlgorithms
        )
      };

    case getType(setEditState):
      return {
        ...algoStore,
        openAlgorithms: setEditStateForAlgo(
          action.payload.algoId,
          action.payload.state,
          algoStore.openAlgorithms
        )
      };

    case getType(updateNodes):
      return {
        ...algoStore,
        openAlgorithms: updatedOpenAlgoArrayWithNodes(
          action.payload.nodes,
          action.payload.algoId,
          algoStore.openAlgorithms,
          loggedInUser
        )
      };

    case getType(updateNodeText):
      return {
        ...algoStore,
        openAlgorithms: updatedOpenAlgoArrayForNodeText(
          action.payload.node,
          action.payload.algoId,
          algoStore.openAlgorithms
        )
      };

    case getType(createNode): {
      const { algoId, kind, parentId } = action.payload;
      const openAlgo = algoStore.openAlgorithms.find(
        a => a.algorithm.id === algoId
      );
      const parentNode =
        openAlgo && parentId ? openAlgo.algoNodes[parentId] : undefined;
      const newNode = new AlgoNode(kind, algoId, parentNode && parentNode.kind);

      return {
        ...algoStore,
        openAlgorithms: updatedOpenAlgoArrayWithNodes(
          [newNode],
          algoId,
          algoStore.openAlgorithms,
          loggedInUser,
          action.payload
        )
      };
    }

    case getType(removeNodes):
      return {
        ...algoStore,
        openAlgorithms: updatedOpenAlgoArrayRemovingNodes(
          action.payload.nodeIds,
          action.payload.algoId,
          algoStore.openAlgorithms,
          loggedInUser
        )
      };

    case getType(linkNodes):
      return {
        ...algoStore,
        openAlgorithms: updatedOpenAlgoArrayLinkingNodes(
          action.payload,
          algoStore.openAlgorithms,
          loggedInUser
        )
      };

    case getType(containNode):
      return {
        ...algoStore,
        openAlgorithms: updatedOpenAlgoArrayContainingNode(
          action.payload,
          algoStore.openAlgorithms,
          loggedInUser
        )
      };

    case getType(createPath):
      return {
        ...algoStore,
        openAlgorithms: updatedOpenAlgoArrayCreatingPath(
          action.payload,
          algoStore.openAlgorithms,
          loggedInUser
        )
      };

    case getType(updatePath):
      return {
        ...algoStore,
        openAlgorithms: updatedOpenAlgoArrayWithPathAction(
          action.payload,
          algoStore.openAlgorithms,
          "update",
          loggedInUser
        )
      };

    case getType(removePath):
      return {
        ...algoStore,
        openAlgorithms: updatedOpenAlgoArrayWithPathAction(
          action.payload,
          algoStore.openAlgorithms,
          "delete",
          loggedInUser
        )
      };

    case getType(reorderPaths):
      return {
        ...algoStore,
        openAlgorithms: updatedOpenAlgoArrayWithPathReorder(
          action.payload,
          algoStore.openAlgorithms,
          loggedInUser
        )
      };

    case getType(updateAlgo):
      return {
        ...algoStore,
        openAlgorithms: updatedOpenAlgoArrayUpdatingAlgo({
          createOnly: false,
          openAlgos: algoStore.openAlgorithms,
          updatedAlgo: action.payload,
          loggedInUser
        })
      };

    case getType(addComment):
      return {
        ...algoStore,
        openAlgorithms: updatedOpenAlgoArrayCreatingComment(
          action.payload,
          algoStore.openAlgorithms,
          loggedInUser
        )
      };

    case getType(updateComment):
      return {
        ...algoStore,
        openAlgorithms: updatedOpenAlgoArrayUpdatingComment(
          action.payload,
          algoStore.openAlgorithms,
          loggedInUser
        )
      };

    case getType(processQueue):
      return {
        ...algoStore,
        openAlgorithms: updatedOpenAlgoArrayQueuingNext(
          action.payload,
          algoStore.openAlgorithms
        )
      };

    case getType(clearQueueErrors):
      return {
        ...algoStore,
        openAlgorithms: updatedOpenAlgoArrayClearingQueue(
          action.payload,
          algoStore.allAlgorithms,
          algoStore.openAlgorithms,
          loggedInUser
        )
      };

    case getType(changeAlgoVar): {
      return {
        ...algoStore,
        openAlgorithms: updatedOpenAlgoArrayChangingVariable(
          action.payload,
          algoStore.openAlgorithms
        )
      };
    }

    case getType(setNodeImage.success): {
      const node = objectFromPayload(action.payload.apiResponse.data, AlgoNode);
      if (node) {
        const algo = updateAlgoWithNode(node, algoStore);
        if (algo) {
          return {
            ...algoStore,
            allAlgorithms: { ...algoStore.allAlgorithms, [algo.id]: algo },
            openAlgorithms: updatedOpenAlgoArray({
              createOnly: false,
              openAlgos: algoStore.openAlgorithms,
              updatedAlgo: algo,
              updatedNodes: { [node.id]: node },
              loggedInUser
            })
          };
        }
      }
      return algoStore;
    }

    case getType(updateBackendNode.success):
    case getType(createBackendNode.success):
    case getType(createBackendPath.success):
    case getType(updateBackendPath.success):
    case getType(deleteBackendNode.success):
    case getType(deleteBackendPath.success):
    case getType(updateBackendAlgorithm.success):
    case getType(createBackendComment.success):
    case getType(updateBackendComment.success):
    case getType(deleteBackendComment.success):
      return algoStoreUpdatedWithPayload(
        action.payload,
        algoStore,
        loggedInUser
      );

    case getType(updateBackendNode.failure):
    case getType(createBackendNode.failure):
    case getType(createBackendPath.failure):
    case getType(updateBackendPath.failure):
    case getType(deleteBackendNode.failure):
    case getType(deleteBackendPath.failure):
    case getType(updateBackendAlgorithm.failure):
    case getType(createBackendComment.failure):
    case getType(updateBackendComment.failure):
    case getType(deleteBackendComment.failure):
      return {
        ...algoStore,
        error: action.payload.error,
        openAlgorithms: updatedOpenAlgoArrayProcessingFailure(
          action.payload,
          algoStore.openAlgorithms
        )
      };

    case getType(deleteAlgorithm.failure): {
      if (action.payload.stack) {
        const uid = action.payload.stack.split("/").pop();
        return deleteAlgo(uid, algoStore);
      }
    }
    // Fall through intentional
    case getType(setNodeImage.failure):
    case getType(getAlgorithm.failure):
    case getType(getTopics.failure):
    case getType(getAlgosByTopic.failure):
    case getType(getMyAlgos.failure):
    case getType(retrieveAlgoList.failure):
    case getType(getCommentsForAlgorithm.failure):
    case getType(getAlgorithmSearchResults.failure):
    case getType(saveAlgoState.failure):
    case getType(createAlgorithm.failure):
    case getType(updateAlgorithmStatus.failure):
      return {
        ...algoStore,
        error: action.payload
      };

    default:
      return algoStore;
  }
};
