import { H6, Icon } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import { push } from "connected-react-router";
import * as React from "react";
import { connect } from "react-redux";

import { changeAlgoPage, saveAlgoState } from "src/actions";
import {
  AlgoNode,
  AlgoNodeType,
  kFromAlgo,
  notEmpty,
  Parameter,
  valsToArrayResolvingDiscretes
} from "src/api";
import { kLibraryRootUrl } from "src/config/routes";
import { IOpenAlgorithm, ISelectionData } from "src/store";
import { nodeTitleTextPlain, sortAlphabetically } from "src/utilities";

export interface IDecisionTreeProps {
  algorithm: IOpenAlgorithm;
  className?: string;
  style?: React.CSSProperties;
}

interface IDecisionTreeDispatchProps {
  changeAlgoPage: typeof changeAlgoPage;
  push: typeof push;
  saveState: typeof saveAlgoState.request;
}

class DecisionTreeComponent extends React.PureComponent<
  IDecisionTreeProps & IDecisionTreeDispatchProps
> {
  public render() {
    const { algorithm, className, /*saveState,*/ style } = this.props;

    return (
      <section
        style={style}
        className={`flex flex-column ${className} w-100 min-h-100`}
      >
        {this.renderChoices(algorithm)}
      </section>
    );
  }

  private renderChoices = (openAlgo: IOpenAlgorithm) => {
    const {
      algoNodes,
      algoState: { decisionsJson },
      currentPage
    } = openAlgo;

    return decisionsJson.map((sd, index) => {
      const pageIndex = openAlgo.decisionNodes.findIndex(
        dn => dn.id === sd.nodeId
      );
      const isCurrent = currentPage === pageIndex;
      const handleClick = () => {
        if (!isCurrent) {
          this.props.changeAlgoPage({ details: openAlgo, page: pageIndex });
        }
      };

      const node = algoNodes[sd.nodeId];
      if (!node) {
        return null;
      }

      const isActionable = [
        AlgoNodeType.multiSelect,
        AlgoNodeType.singleSelect,
        AlgoNodeType.varInput
      ].includes(node.kind);

      const questionTitle = nodeTitleTextPlain(node);
      const answers = sd.selection
        .map(id => openAlgo.algorithm.nodes.find(n => n.id === id))
        .filter(notEmpty)
        .filter(a => a.id !== node.id)
        .map(a => nodeTitleTextPlain(a));

      type VarsType = {
        param: Parameter;
        values: string[];
      };
      const vars: VarsType[] = this.varsForDecisionNode(sd, openAlgo, node);

      const navToNextAlgo = () => {
        if (sd.nextAlgoTargetId) {
          this.props.push(
            `${kLibraryRootUrl}/undefined/${sd.nextAlgoTargetId}?${kFromAlgo}=${node.algorithmId}`
          );
        }
      };

      const renderAnswers = (anws: string[]) => {
        const answerRenderer = (s: string, i: number) => <li key={i}>{s}</li>;
        return <ul className="pl3">{anws.map(answerRenderer)}</ul>;
      };

      const renderValues = (vals: VarsType[]) => {
        const valueRenderer = (s: VarsType, i: number) => {
          const item =
            s.values.length === 1 ? (
              <div className="pl2">{s.values[0]}</div>
            ) : (
              <ul className="pl2">
                {s.values.map((v, k) => (v ? <li key={k}>{v}</li> : null))}
              </ul>
            );
          return (
            <li className="pb1" key={i}>
              <b>{s.param.title}</b>
              {item}
            </li>
          );
        };
        return (
          <ul className="pl3">
            {vals.filter(v => !v.param.output).map(valueRenderer)}
          </ul>
        );
      };

      const renderProcessed = (vals: VarsType[]) => {
        const valueRenderer = (v: VarsType, i: number) => (
          <li key={i}>
            {v.param.title} updated to {v.values.join("/") || ""}
          </li>
        );
        return <ul className="pl3 pb1">{vals.map(valueRenderer)}</ul>;
      };

      const renderDecisions = (anws: string[]) => {
        const valueRenderer = (s: string, i: number) => (
          <div className="flex flex-row" key={i}>
            <Icon icon={IconNames.ARROW_RIGHT} />
            <span className="ph2">{s}</span>
          </div>
        );
        return <div className="pl3 pb2">{anws.map(valueRenderer)}</div>;
      };

      const nextTarget = (
        <div className="pl3">
          <div className="flex flex-row">
            <Icon icon={IconNames.ARROW_RIGHT} />
            <span className="ph2 pointer" onClick={navToNextAlgo}>
              Continues in next algorithm...
            </span>
          </div>
        </div>
      );

      return (
        <section
          key={index}
          onClick={isActionable ? handleClick : undefined}
          className={`pl2 pv1 ${
            isCurrent ? "bg-black-10 br2" : isActionable ? "pointer" : ""
          }`}
        >
          <H6>{`${index + 1}. ${questionTitle}`}</H6>
          {node.isDecision() && renderAnswers(answers)}
          {node.kind === AlgoNodeType.varInput && renderValues(vars)}
          {node.kind === AlgoNodeType.varProcessor && renderProcessed(vars)}
          {node.kind === AlgoNodeType.varDecision && renderDecisions(answers)}
          {sd.nextAlgoTargetId && nextTarget}
        </section>
      );
    });
  };

  private varsForDecisionNode = (
    sd: ISelectionData,
    openAlgo: IOpenAlgorithm,
    node: AlgoNode
  ) => {
    const {
      algorithm: { localVars, variables },
      algoState: { decisionsJson }
    } = openAlgo;

    const combinedVariables = localVars
      ? [...variables, ...Object.values(localVars)]
      : variables;

    return Object.keys(sd.variableValues)
      .map(key => combinedVariables.find(v => v.id === key))
      .filter(notEmpty)
      .sort((a, b) => sortAlphabetically(a.title, b.title))
      .map(param => valsToArrayResolvingDiscretes(param, decisionsJson, node));
  };
}

export const DecisionTree = connect(undefined, {
  changeAlgoPage,
  saveState: saveAlgoState.request,
  push
})(DecisionTreeComponent);
