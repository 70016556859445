import * as React from "react";
import { connect } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router-dom";

import { ILocationDetails, updateUser } from "src/actions";
import { User, userLocationString } from "src/api";
import { IStoreState } from "src/store";
import { AdminEditUserForm } from ".";
import { UserEvents } from "./user-events";

interface IUserAdminProps extends RouteComponentProps<{ userId: string }> {
  busy: boolean;
  loggedInUser?: User;
  user?: User;
  updateUser: typeof updateUser.request;
}

class UserAdminComponent extends React.PureComponent<IUserAdminProps> {
  public render() {
    const { loggedInUser, user, busy } = this.props;

    if (!user) {
      return null;
    }

    const initialValues = {
      ...user,
      locationDetails: {
        label: userLocationString(user),
        value: {
          city: user.city,
          country: user.country,
          region: user.region
        }
      }
    };

    const handleSubmit = (
      values: User & { locationDetails: ILocationDetails }
    ) => {
      // Break out new location info
      const { locationDetails } = values;
      if (locationDetails) {
        const {
          value: { country, region, city }
        } = locationDetails;
        if (country) {
          values.country = country;
        }
        if (region) {
          values.region = region;
        }
        if (city) {
          values.city = city;
        }
      }
      this.props.updateUser(values);
    };

    return (
      <article className="overflow-y-auto">
        <section className="ma2 pa2 br3 zx-shadow">
          <AdminEditUserForm
            className="pa2"
            showAdminControls={true}
            initialValues={initialValues}
            currentLoggedInUser={loggedInUser}
            onSubmit={handleSubmit}
            busy={busy}
            form={`${user.id}`}
          />
        </section>
        <UserEvents user={user} />
      </article>
    );
  }
}

const mapStateToProps = (
  { userStore, usersStore, uiStore }: IStoreState,
  props: IUserAdminProps
) => {
  const userId = props.match.params.userId;
  const user = usersStore.allUsers[userId];

  return {
    busy: uiStore.community.userLoadersCount > 0,
    loggedInUser: userStore.loggedInUser,
    user
  };
};

export const UserAdmin = withRouter(
  connect(mapStateToProps, {
    updateUser: (user: User) => updateUser.request(user)
  })(UserAdminComponent)
);
