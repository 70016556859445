import { Spinner } from "@blueprintjs/core";
import * as React from "react";
import { connect } from "react-redux";

import { setEditState } from "src/actions";
import { IOpenAlgorithm, IStoreState } from "src/store";
import { EditToolbar, SharedNodeEditDialog } from ".";
import { AlgoInfoEditor } from "./algo-info-editor";
import { AlgoVarsEditor } from "./algo-vars-editor";
import { AlgorithmCanvas } from "./algorithm-canvas";
import { EditPanel } from "./edit-panel";

export interface IAlgorithmEditProps {
  nodeId?: string;
  openAlgorithm: IOpenAlgorithm;
  queryString?: string;
}

interface IAlgorithmEditDispatchProps {
  setEditState: typeof setEditState;
}

class AlgorithmEditComponent extends React.PureComponent<
  IAlgorithmEditProps & IAlgorithmEditDispatchProps
> {
  public render() {
    const { nodeId, openAlgorithm, queryString } = this.props;

    if (!openAlgorithm) {
      return (
        <section className="w-100 mt4">
          <Spinner size={50} />
        </section>
      );
    }
    const {
      editingState: { algoInfoPanel },
      eventQueue
    } = openAlgorithm;

    let disableEdits = false;
    eventQueue.forEach(e => {
      if (e.error) {
        disableEdits = true;
      }
    });

    const panelBody =
      algoInfoPanel === "info" || algoInfoPanel === "refs" ? (
        <AlgoInfoEditor openAlgorithm={openAlgorithm} />
      ) : algoInfoPanel === "vars" ? (
        <AlgoVarsEditor openAlgorithm={openAlgorithm} />
      ) : (
        <>
          <AlgorithmCanvas
            disabled={disableEdits}
            openAlgorithm={openAlgorithm}
            queryString={queryString}
            urlNodeId={nodeId}
          />
          <EditPanel disabled={disableEdits} openAlgorithm={openAlgorithm} />
        </>
      );

    return (
      <section id="editRoot" className="flex flex-column flex-auto">
        <SharedNodeEditDialog openAlgorithm={openAlgorithm} />
        <EditToolbar disabled={disableEdits} openAlgorithm={openAlgorithm} />
        <section className="flex flex-auto relative">{panelBody}</section>
      </section>
    );
  }
}

const mapStateToProps = ({ uiStore, userStore }: IStoreState) => {
  return {
    error: uiStore.error,
    loggedInUser: userStore.loggedInUser
  };
};

export const AlgorithmEdit = connect(mapStateToProps, { setEditState })(
  AlgorithmEditComponent
);
