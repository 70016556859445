import {
  IPopoverProps,
  Menu,
  MenuItem,
  OverflowList,
  Popover,
  Position,
  Tag
} from "@blueprintjs/core";

import { push } from "connected-react-router";
import * as React from "react";
import { connect } from "react-redux";

import { kLibraryRootUrl } from "src/config/routes";
import { IBreadcrumbLocation, IStoreState } from "src/store";

interface IConnectedBreadcrumbProps {
  breadcrumbs: IBreadcrumbLocation[];
  push: typeof push;
  width?: number;
}

interface IConnectedBreadcrumbState {
  overflowed: boolean;
  libraryMode: boolean;
}

class ConnectedBreadcrumbComponent extends React.PureComponent<
  IConnectedBreadcrumbProps,
  IConnectedBreadcrumbState
> {
  constructor(props: IConnectedBreadcrumbProps) {
    super(props);
    this.state = {
      libraryMode: this.isLibraryMode(props.breadcrumbs),
      overflowed: false
    };
  }

  public componentDidUpdate() {
    const { breadcrumbs } = this.props;
    const libraryMode = this.isLibraryMode(breadcrumbs);

    if (libraryMode !== this.state.libraryMode) {
      this.setState({ libraryMode });
    }
  }

  public render() {
    const { width } = this.props;
    const handleOverflow = (overflowItems: IBreadcrumbLocation[]) => {
      this.setState({
        overflowed: overflowItems.length > 0
      });
    };

    return (
      <OverflowList
        {...this.props}
        style={{ width }}
        className={"h-100 flex-wrap flex-nowrap-ns overflow-hidden"}
        items={this.props.breadcrumbs}
        overflowRenderer={this.overflowRenderer}
        onOverflow={handleOverflow}
        visibleItemRenderer={this.renderBreadcrumbWrapper}
      />
    );
  }

  private isLibraryMode = (breadCrumbs?: IBreadcrumbLocation[]) => {
    if (breadCrumbs && breadCrumbs.length > 0) {
      const firstItem = breadCrumbs[0];
      if (
        firstItem.targetLocation &&
        firstItem.targetLocation === kLibraryRootUrl
      ) {
        return true;
      }
    }
    return false;
  };

  private renderBreadcrumbWrapper = (
    props: IBreadcrumbLocation,
    index: number
  ) => {
    const isLast = index === this.props.breadcrumbs.length - 1;

    return (
      <div key={index} className={`nowrap ${isLast ? "truncate" : ""}`}>
        •{this.renderBreadcrumb(props, index, isLast, true)}
      </div>
    );
  };

  private renderBreadcrumb = (
    props: IBreadcrumbLocation,
    index: number,
    isCurrent: boolean,
    enableClick = false
  ) => {
    const last = index === this.props.breadcrumbs.length - 1;
    const isActionable =
      (props.targetLocation && !isCurrent && enableClick) || false;

    if (index === 0) {
      return this.renderCrumb(
        props,
        isCurrent,
        isActionable,
        index,
        enableClick
      );
    }

    const crumbRenderer =
      " / " &&
      this.renderCrumb(props, isCurrent, isActionable, index, enableClick);

    if (props.title && props.title.length > 0) {
      return (
        <span className="nowrap">
          {crumbRenderer}
          {!enableClick && !last && " / "}
        </span>
      );
    }
    return null;
  };

  private renderCrumb = (
    props: IBreadcrumbLocation,
    isCurrent: boolean,
    actionable: boolean,
    index: number,
    enableClick: boolean
  ) => {
    const handleClick = () => {
      if (props.targetLocation && !isCurrent) {
        this.props.push(props.targetLocation);
      }
    };

    return (
      <Tag
        style={{ backgroundColor: "transparent" }}
        key={index}
        interactive={actionable}
        onClick={enableClick ? handleClick : undefined}
        minimal={true}
      >
        {props.title}
      </Tag>
    );
  };

  private overflowRenderer = (items: IBreadcrumbLocation[]) => {
    const popoverProps: IPopoverProps = { minimal: true };
    const index = items.length - 1;

    return (
      <Popover
        position={Position.BOTTOM_LEFT}
        {...popoverProps}
        content={<Menu>{items.map(this.renderOverflowBreadcrumb)}</Menu>}
      >
        <div className="pointer">
          {this.renderBreadcrumb(items[index], index, false)}
          {"▾"}
        </div>
      </Popover>
    );
  };

  private renderOverflowBreadcrumb = (
    props: IBreadcrumbLocation,
    index: number
  ) => {
    const last = index === this.props.breadcrumbs.length - 1;

    // We don't want the last one appearing in the list.
    if (last) {
      return null;
    }

    const handleClick = () => {
      if (props.targetLocation) {
        this.props.push(props.targetLocation);
      }
    };

    return (
      <MenuItem
        key={index}
        disabled={!props.targetLocation}
        onClick={handleClick}
        text={props.title}
      />
    );
  };
}

const mapStateToProps = ({ userStore, uiStore }: IStoreState) => {
  return {
    breadcrumbs: uiStore.breadCrumbs,
    user: userStore.loggedInUser
  };
};

export const ConnectedBreadcrumbs = connect(mapStateToProps, { push })(
  ConnectedBreadcrumbComponent
);
