import { Button, MenuItem } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import { ItemRenderer, Select } from "@blueprintjs/select";
import * as React from "react";

import {
  AlgorithmStatus,
  algoStatusString,
  kSelectableAlgoStatuses
} from "src/api";

export interface IAlgorithmStatusSelectProps {
  className?: string;
  disabled?: boolean;
  value: AlgorithmStatus;
  onChange?: (value: AlgorithmStatus) => void;
}

const AlgoStatusSelect = Select.ofType<AlgorithmStatus>();

export class AlgorithmStatusSelect extends React.PureComponent<
  IAlgorithmStatusSelectProps
> {
  public render() {
    const { disabled, value } = this.props;
    return (
      <div className={`${this.props.className}`}>
        <AlgoStatusSelect
          disabled={disabled}
          filterable={false}
          popoverProps={{ minimal: true }}
          items={kSelectableAlgoStatuses}
          itemRenderer={this.itemRenderer}
          onItemSelect={this.selectionHandler}
          inputProps={{ disabled }}
        >
          <Button
            text={algoStatusString(value)}
            rightIcon={IconNames.CARET_DOWN}
          />
        </AlgoStatusSelect>
      </div>
    );
  }

  private itemRenderer: ItemRenderer<AlgorithmStatus> = (
    value,
    { handleClick, modifiers: { disabled } }
  ) => {
    return (
      <MenuItem
        active={value === this.props.value}
        disabled={disabled}
        key={value}
        onClick={handleClick}
        text={algoStatusString(value)}
      />
    );
  };

  private selectionHandler = (i: AlgorithmStatus) => {
    const { onChange } = this.props;
    if (onChange) {
      onChange(i);
    }
  };
}
