import { Button, Icon, Spinner } from "@blueprintjs/core";
import { IconName, IconNames } from "@blueprintjs/icons";
import * as React from "react";
import { connect } from "react-redux";
import * as QueryString from "query-string";

import { push } from "connected-react-router";
import {
  changeAlgoPage,
  clearUiError,
  getAlgorithm,
  IConsumeAlgoPayload,
  tappedInfoOrComments,
  tappedNode,
  updateConsumePanelFocus
} from "src/actions";
import { AlgoNode, AlgoNodeInfoType, User, AlgoNodeType } from "src/api";
import { CssSize, ErrorRetry } from "src/components";

import { kLeftMenuWidthClosed, kLeftMenuWidthOpen } from "src/pages/left-menu";
import {
  ConsumePanelFocusType,
  InfoTabType,
  IOpenAlgorithm,
  IStoreState
} from "src/store";
import { notEmpty } from "src/utilities";
import { AlgoInfo, AlgoScroller, DecisionTabs } from ".";

const kConsumeRoot = "consumeRoot";

export interface IAlgorithmConsumeProps {
  nodeId?: string;
  openAlgorithm: IOpenAlgorithm;
  search: string;
}

interface IAlgorithmConsumeInjectedProps {
  error?: Error;
  leftMenuSize: number;
  loggedInUser?: User;
}

interface IAlgorithmConsumeDispatchProps {
  changeAlgoPage: typeof changeAlgoPage;
  clearUiError: typeof clearUiError;
  cssSize: CssSize;
  loadAlgorithm: (id: string) => void;
  push: typeof push;
  tappedInfoOrComments: typeof tappedInfoOrComments;
  tappedNode: typeof tappedNode;
  updateConsumePanelFocus: typeof updateConsumePanelFocus;
}

interface IAlgorithmConsumeState {
  algoWidth?: number;
  error?: Error;
}

type AlgorithmConsumeProps = IAlgorithmConsumeProps &
  IAlgorithmConsumeInjectedProps &
  IAlgorithmConsumeDispatchProps;

class AlgorithmConsumeComponent extends React.PureComponent<
  AlgorithmConsumeProps,
  IAlgorithmConsumeState
> {
  constructor(props: AlgorithmConsumeProps) {
    super(props);

    this.state = {
      algoWidth: 300
    };
  }

  public componentDidMount() {
    window.addEventListener("resize", this.resizeHandler);
    this.resizeHandler();
  }

  public componentWillUnmount() {
    window.removeEventListener("resize", this.resizeHandler);
  }

  public componentDidUpdate(
    prevProps: IAlgorithmConsumeProps & IAlgorithmConsumeInjectedProps
  ) {
    const { error, openAlgorithm, nodeId, search } = this.props;

    const {
      algoNodes,
      algorithm,
      consumePanelFocus,
      decisionNodes,
      firstNavigation,
      sectionNodes
    } = openAlgorithm;

    if (
      !firstNavigation &&
      prevProps.openAlgorithm &&
      openAlgorithm.algorithm.id !== prevProps.openAlgorithm.algorithm.id
    ) {
      // Algo changed
      this.props.updateConsumePanelFocus({
        algoId: algorithm.id,
        firstNavigation: true,
        focus: consumePanelFocus
      });
    }

    if (error && this.state.error !== error) {
      this.setState({ error });
      this.props.clearUiError();
    } else {
      if (firstNavigation) {
        if (nodeId) {
          let questionIndex = decisionNodes.findIndex(n => n.id === nodeId);
          // TODO: add support for other nodes and move to redux.
          if (questionIndex < 0) {
            questionIndex = sectionNodes.findIndex(sn => {
              const nodeIndex = sn.findIndex(n => n.node.id === nodeId);
              return nodeIndex > -1;
            });
          }
          if (questionIndex > -1) {
            const node = algoNodes[nodeId];
            this.doFirstNavToNode(questionIndex, node, search);
          }
        } else {
          if (search.includes("info") && consumePanelFocus !== "info") {
            this.props.updateConsumePanelFocus({
              algoId: algorithm.id,
              firstNavigation,
              focus: "info"
            });
          }
        }
      }
    }
    this.resizeHandler();
  }

  public render() {
    const { openAlgorithm, cssSize } = this.props;
    const { error } = this.state;

    if (error) {
      return <ErrorRetry error={error} retryHandler={this.loadAlgo} />;
    }

    if (!openAlgorithm || openAlgorithm.algorithm.nodes.length === 0) {
      return (
        <section className="mt4">
          <Spinner size={50} />
        </section>
      );
    }

    return (
      <div
        id={kConsumeRoot}
        className="zx-consume-height flex flex-column w-100"
      >
        {this.renderNavigationControls(cssSize, openAlgorithm)}
        <section className="flex flex-column flex-auto flex-row-l">
          {this.renderMainPanel()}
          {this.renderSidePanel()}
        </section>
      </div>
    );
  }

  private doFirstNavToNode = (
    page: number,
    node: AlgoNode,
    queryString: string
  ) => {
    const options = QueryString.parse(queryString || "");
    const commentId = options["comment"];

    if (commentId) {
      this.props.tappedInfoOrComments({ node, focus: "comments" });
    } else if (queryString.includes("info")) {
      this.props.tappedInfoOrComments({ node, focus: AlgoNodeInfoType.info });
    }

    this.moveToPage(page);
    const { algorithm, consumePanelFocus } = this.props.openAlgorithm;
    this.props.updateConsumePanelFocus({
      algoId: algorithm.id,
      firstNavigation: false,
      focus: consumePanelFocus
    });
  };

  private renderMainPanel = () => {
    const { cssSize, openAlgorithm, loggedInUser } = this.props;
    const { algoWidth } = this.state;

    if (!openAlgorithm) {
      return null;
    }
    const { consumePanelFocus, currentPage } = openAlgorithm;

    const handleFocusChange = (newFocus: ConsumePanelFocusType) =>
      this.props.updateConsumePanelFocus({
        algoId: openAlgorithm.algorithm.id,
        focus: newFocus
      });

    const handleClick = () => {
      this.props.updateConsumePanelFocus({
        algoId: openAlgorithm.algorithm.id,
        focus: undefined
      });
    };

    return consumePanelFocus && cssSize < 2 ? (
      consumePanelFocus === "info" ? (
        <AlgoInfo
          onClick={handleClick}
          algoDetails={openAlgorithm}
          loggedInUser={loggedInUser}
        />
      ) : (
        <DecisionTabs
          onClick={handleClick}
          algorithm={openAlgorithm}
          focus={consumePanelFocus}
          onFocusChange={handleFocusChange}
        />
      )
    ) : (
      <AlgoScroller
        openAlgo={openAlgorithm}
        currentPage={currentPage}
        width={algoWidth}
        cssSize={cssSize}
        tappedNode={this.handleNodeTap}
        moveToPage={this.moveToPage}
        tappedInfo={this.handleInfoTap}
        tappedComments={this.handleCommentsTap}
      />
    );
  };

  private renderSidePanel = () => {
    const { cssSize, openAlgorithm, leftMenuSize, loggedInUser } = this.props;
    const { algoWidth } = this.state;
    const { algorithm, consumePanelFocus } = openAlgorithm;

    const handleFocusChange = (newFocus: ConsumePanelFocusType) =>
      this.props.updateConsumePanelFocus({
        algoId: algorithm.id,
        focus: newFocus
      });

    const style = algoWidth
      ? { width: window.innerWidth - algoWidth - leftMenuSize - 20 }
      : undefined;

    return (
      cssSize > 1 &&
      (consumePanelFocus === "info" ? (
        <AlgoInfo
          style={style}
          className="flex flex-auto pl2"
          algoDetails={openAlgorithm}
          loggedInUser={loggedInUser}
        />
      ) : (
        <DecisionTabs
          style={style}
          className="flex flex-auto pl2"
          algorithm={openAlgorithm}
          focus={consumePanelFocus}
          onFocusChange={handleFocusChange}
        />
      ))
    );
  };

  private handleCommentsTap = (node: AlgoNode) =>
    this.updateFocusedInfo(node, "comments");

  private handleInfoTap = (node: AlgoNode, focus: InfoTabType) =>
    this.updateFocusedInfo(node, focus);

  private updateFocusedInfo = (node: AlgoNode, focus: InfoTabType) =>
    this.props.tappedInfoOrComments({ node, focus });

  private handleNodeTap = (details: IConsumeAlgoPayload) => {
    // Update the store
    this.props.tappedNode(details);
  };

  // This is to make the algo layout area fixed-size correctly (flexbox no worky)
  private resizeHandler = () => {
    const { width } = this.containerSize();

    const algoWidth = this.calculateSize(width);
    if (algoWidth !== this.state.algoWidth) {
      this.setState({ algoWidth });
    }
  };

  private containerSize = () => {
    let width = 0;
    let height = 0;

    const container = document.querySelector(
      `#${kConsumeRoot}`
    ) as HTMLDivElement;
    if (container instanceof HTMLDivElement) {
      height = container.offsetHeight;
      width = container.offsetWidth;
    }

    return { width, height };
  };

  private calculateSize = (width: number) => {
    let scale = 1;

    if (window.innerWidth > 959) {
      scale = 0.65;
    } else {
      scale = 1;
    }

    const algoWidth = Math.floor(scale * width);
    return algoWidth;
  };

  private moveToPage = (pageIndex: number) => {
    const { openAlgorithm } = this.props;
    const currentPage = openAlgorithm ? openAlgorithm.currentPage : 0;

    if (openAlgorithm && pageIndex !== currentPage) {
      this.props.changeAlgoPage({
        details: openAlgorithm,
        page: pageIndex
      });
    }
  };

  private moveInAlgo = (forward: boolean) => {
    const { openAlgorithm } = this.props;
    if (openAlgorithm) {
      const {
        algoNodes,
        currentPage,
        decisionNodes,
        algoState: { decisionsJson }
      } = openAlgorithm;

      const currentPageNode = decisionNodes[currentPage];
      let currentDecisionIndex;

      if (currentPageNode) {
        currentDecisionIndex = decisionsJson.findIndex(
          dn => dn.nodeId === currentPageNode.id
        );
      } else {
        currentDecisionIndex = decisionsJson.length; // Deliberately off the end of the array
      }

      let targetIndex = forward ? currentPage + 1 : currentPage - 1;

      if (currentDecisionIndex > 0) {
        const getNext = (index: number) => {
          const nextIndex = forward ? index + 1 : index - 1;
          let decisionParams = forward
            ? decisionsJson[nextIndex]
            : decisionsJson[nextIndex];
          if (decisionParams) {
            const dNode = algoNodes[decisionParams.nodeId];
            if (dNode && dNode.kind === AlgoNodeType.varDecision) {
              // Decision nodes record but don't show for consumption, so go up the list.
              decisionParams = getNext(nextIndex);
            }
          }
          return decisionParams;
        };

        const dParams = getNext(currentDecisionIndex);

        if (dParams) {
          const container = algoNodes[dParams.nodeId];
          if (container) {
            const decisionNodeIdx = decisionNodes.findIndex(
              dn => dn.id === container.id
            );
            if (decisionNodeIdx > -1) {
              targetIndex = decisionNodeIdx;
            }
          }
        }
      } else {
        if (!forward) {
          // Not got a record for the current page, so see if we have one for a path back from this node.
          const prevNodePossible = currentPageNode
            .backwardLinks()
            .map(np => algoNodes[np.parentId]) // Get the previous node
            .filter(notEmpty)
            .map(n => n.containerId()) // Get the container
            .map(n => decisionNodes.findIndex(dn => dn.id === n))
            .sort((a, b) => a - b)[0];

          if (prevNodePossible !== undefined) {
            targetIndex = prevNodePossible;
          }
        }
      }
      this.moveToPage(targetIndex);
    }
  };

  private moveBackInAlgo = () => this.moveInAlgo(false);
  private moveForwardInAlgo = () => this.moveInAlgo(true);

  private renderNavigationControls = (
    cssSize: CssSize,
    openAlgorithm: IOpenAlgorithm
  ) => {
    const { algorithm, consumePanelFocus } = openAlgorithm;

    const handleInfoTap = () =>
      this.props.updateConsumePanelFocus({
        algoId: openAlgorithm.algorithm.id,
        focus: consumePanelFocus !== "info" ? "info" : undefined
      });

    const handleDecisionListTap = (e: React.MouseEvent<HTMLElement>) => {
      const hitButton = e.currentTarget.id as ConsumePanelFocusType;
      this.props.updateConsumePanelFocus({
        algoId: algorithm.id,
        focus: consumePanelFocus !== hitButton ? hitButton : undefined
      });
    };

    const iconWithActiveState = (iconName: IconName, active: boolean) => {
      if (active) {
        return <Icon icon={iconName} color={active ? "white" : undefined} />;
      } else {
        return iconName;
      }
    };

    const infoActive = consumePanelFocus === "info";
    const choicesActive = consumePanelFocus === "choices";
    const contentsActive = consumePanelFocus === "contents";
    const smallerLayoutButtons = (
      <>
        <Button
          className="zx-button-spacing"
          id="choices"
          style={{ width: 50 }}
          active={choicesActive}
          icon={iconWithActiveState(IconNames.HISTORY, choicesActive)}
          onClick={handleDecisionListTap}
        />
        <Button
          className="zx-button-spacing"
          id="contents"
          style={{ width: 50 }}
          active={contentsActive}
          icon={iconWithActiveState(IconNames.PROPERTIES, contentsActive)}
          onClick={handleDecisionListTap}
        />
      </>
    );

    const forwardDisabled =
      openAlgorithm.currentPage === openAlgorithm.sectionNodes.length - 1;
    return (
      <section
        className={`${cssSize > 0 ? " flex flex-row-reverse" : ""} w-100 pb2`}
        style={{ flexShrink: 0 }}
      >
        <div>
          <Button
            className="zx-button-spacing"
            style={{ width: 50 }}
            active={infoActive}
            icon={iconWithActiveState(IconNames.INFO_SIGN, infoActive)}
            onClick={handleInfoTap}
          />
          {cssSize < 2 && smallerLayoutButtons}
          <Button
            className="zx-button-spacing"
            style={{ width: 50 }}
            disabled={openAlgorithm.currentPage === 0}
            icon={IconNames.CHEVRON_LEFT}
            onClick={this.moveBackInAlgo}
          />
          <Button
            className="zx-button-spacing"
            style={{ width: 50 }}
            disabled={forwardDisabled}
            icon={IconNames.CHEVRON_RIGHT}
            onClick={this.moveForwardInAlgo}
          />
        </div>
      </section>
    );
  };

  private loadAlgo = async () => {
    const {
      openAlgorithm: { algorithm }
    } = this.props;
    this.setState({ error: undefined });
    this.props.loadAlgorithm(algorithm.id);
  };
}

const mapStateToProps = ({
  uiStore,
  userStore
}: IStoreState): IAlgorithmConsumeInjectedProps => {
  return {
    error: uiStore.error,
    leftMenuSize: uiStore.leftMenuOpen
      ? kLeftMenuWidthOpen
      : kLeftMenuWidthClosed,
    loggedInUser: userStore.loggedInUser
  };
};

export const AlgorithmConsume = connect(mapStateToProps, {
  changeAlgoPage,
  clearUiError,
  loadAlgorithm: (id: string) =>
    getAlgorithm.request({ id, includeNodes: true }),
  push,
  tappedInfoOrComments,
  tappedNode,
  updateConsumePanelFocus
})(AlgorithmConsumeComponent);
