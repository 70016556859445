import { Spinner } from "@blueprintjs/core";
import * as React from "react";
import { connect } from "react-redux";
import {
  Route,
  RouteComponentProps,
  Switch,
  withRouter
} from "react-router-dom";

import { Algorithm } from "src/api";
import { ConnectedPageTitle, CssSize } from "src/components";
import { kLibraryRootUrl } from "src/config/routes";
import { IStoreState } from "src/store";
import { AlgorithmList, ConnectedEditRunButton, Topics } from "./algorithms";
import { AlgorithmContainer } from "./algorithms/algorithm-container";

interface ILibraryProps extends RouteComponentProps {
  cssSize: CssSize;
}

interface ILibraryInjectedProps {
  algorithms: Algorithm[];
  busy: boolean;
}

class LibraryComponent extends React.PureComponent<
  ILibraryProps & ILibraryInjectedProps
> {
  public render() {
    const { busy, algorithms, cssSize } = this.props;

    if (busy && algorithms.length === 0) {
      return (
        <section className="pa4 w-100">
          <Spinner />
        </section>
      );
    }

    const AlgoContainer = () => <AlgorithmContainer cssSize={cssSize} />;

    return (
      <section className="pa2 flex flex-column flex-auto mt2 overflow-y-auto overflow-x-hidden">
        <section
          className="flex flex-row items-start justify-between"
          style={{ flexShrink: 0 }} // Needed to fix Safari, else it squishes the header.
        >
          <ConnectedPageTitle />
          <Route
            path={`${kLibraryRootUrl}/:topicId/:algoId`}
            component={ConnectedEditRunButton}
          />
        </section>
        <Switch>
          <Route
            path={`${kLibraryRootUrl}/:topicId/:algoId/:nodeId?`}
            render={AlgoContainer}
          />
          <Route
            path={`${kLibraryRootUrl}/:topicId`}
            component={AlgorithmList}
          />
          <Topics />
        </Switch>
      </section>
    );
  }
}

const mapStateToProps = ({ algoStore, uiStore }: IStoreState) => {
  return {
    algorithms: Object.values(algoStore.allAlgorithms),
    busy: uiStore.library.libraryLoadersCount > 0
  };
};

export const LibraryPage = withRouter(
  connect(mapStateToProps, {})(LibraryComponent)
);
