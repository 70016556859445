import { Epic } from "redux-observable";

import { handleAsyncAction } from ".";
import {
  getSelf,
  getUser,
  updateUser,
  getUserFavouriteAlgorithms,
  getUsers,
  postUserAvatar,
  retrieveEvents,
  setUserFavouriteAlgorithms,
  setUserRole
} from "../actions";

export const executeGetSelf: Epic = (action$, state$) =>
  handleAsyncAction(action$, state$, getSelf);

export const executeGetUsers: Epic = (action$, state$) =>
  handleAsyncAction(action$, state$, getUsers);

export const executeGetUser: Epic = (action$, state$) =>
  handleAsyncAction(action$, state$, getUser);

export const executeUpdateUser: Epic = (action$, state$) =>
  handleAsyncAction(action$, state$, updateUser);

export const executeSetUserRole: Epic = (action$, state$) =>
  handleAsyncAction(action$, state$, setUserRole);

export const executeRetrieveEvents: Epic = (action$, state$) =>
  handleAsyncAction(action$, state$, retrieveEvents);

export const executePostAvatar: Epic = (action$, state$) =>
  handleAsyncAction(action$, state$, postUserAvatar);

export const executeGetAlgoFaves: Epic = (action$, state$) =>
  handleAsyncAction(action$, state$, getUserFavouriteAlgorithms);

export const executeSetAlgoFaves: Epic = (action$, state$) =>
  handleAsyncAction(action$, state$, setUserFavouriteAlgorithms);
