import { push } from "connected-react-router";
import * as QueryString from "query-string";
import { Epic, StateObservable } from "redux-observable";
import { of } from "rxjs";
import { filter, flatMap, ignoreElements, map } from "rxjs/operators";
import { isActionOf } from "typesafe-actions";

import {
  getAlgorithm,
  getAlgorithmSearchResults,
  getAlgosByTopic,
  getMyAlgos,
  getSelf,
  getUser,
  getUserFavouriteAlgorithms,
  getUsers,
  locationChange,
  retrieveParameters,
  retrieveStudies,
  retrieveUnits
} from "src/actions";
import { fullName, kUserFetchSize, UserRole } from "src/api";
import {
  kCommunityRootUrl,
  kLibraryRootUrl,
  kProfileUrlKey,
  kSearchUrlKey,
  kStudiesUrlKey,
  kUserSearchUrlKey,
  kUsersRootUrl,
  kVariablesMenuUrlKey,
  kVerify,
  kStudyUrlKey
} from "src/config/routes";
import { IStoreState } from "src/store";

/**
 * Handles redirects for error cases
 */
export const errorRedirector: Epic = action$ =>
  action$.pipe(
    filter(isActionOf(getAlgorithm.failure)),
    map(a => {
      if (["403", "404"].includes(a.payload.name)) {
        return of(push(kLibraryRootUrl));
      }
      return undefined;
    }),
    flatMap(op => op || of(ignoreElements))
  );

const handleLibraryRouting = (parts: string[], queryString: string) => {
  if (parts.length === 2) {
    // We are at the root level; refresh the topics
    return of(
      getMyAlgos.request(undefined),
      getUserFavouriteAlgorithms.request()
    );
  }
  if (parts.length === 3) {
    // Go grab the algos for this topic
    const topicId = parts[2];
    if (topicId !== "undefined") {
      return of(getAlgosByTopic.request(topicId));
    }
    return undefined;
  }
  if (parts.length > 3) {
    const params = QueryString.parse(queryString);
    let allowOld = false;
    if (params["allowOld"] === "true") {
      allowOld = true;
    }
    // we have at least a topic and an algo ID, so grab the algo details
    return of(
      getAlgorithm.request({ id: parts[3], includeNodes: true, allowOld })
    );
  }
  return undefined;
};

const handleCommunityRouting = (parts: string[]) => {
  if (parts.length === 2) {
    return of(
      getUsers.request({
        skip: 0,
        take: kUserFetchSize
      })
    );
  }
  if (parts.length === 3) {
    return of(getUser.request(parts[2]));
  }
  return undefined;
};

const handleUsersRouting = (
  parts: string[],
  state$: StateObservable<IStoreState>
) => {
  if (parts.length === 2) {
    // Root of the content, request users from start of page
    return of(
      getUsers.request({
        role: UserRole.Basic,
        skip: 0,
        take: kUserFetchSize
      }),
      getUsers.request({
        skip: 0,
        take: kUserFetchSize
      })
    );
  }
  if (parts.length === 3) {
    const part = parts[2];
    if (part === kVerify) {
      return undefined;
    }
    // We have a user ID here
    return of(getUser.request(parts[2]));
  }
  if (parts.length === 4) {
    const part = parts[3];
    if (parts[2] === kVerify) {
      const user = state$.value.usersStore.allUsers[part];
      const nameQuery = fullName(user);
      return of(
        getUser.request(part),
        getUsers.request({ skip: 0, take: kUserFetchSize, nameQuery })
      );
    }
    // We have a user ID
    return of(getUser.request(part));
  }
  return undefined;
};

const handleProfileRouting = (parts: string[]) => {
  if (parts.length === 2) {
    return of(getSelf.request());
  }
  if (parts.length === 3) {
    return of(getUser.request(parts[2]));
  }
  return undefined;
};

/**
 * Performs load requests based on the navigation
 */
export const navigationLoader: Epic = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(locationChange)),
    map(a => ({
      parts: a.payload.location.pathname.split("/"),
      queryString: a.payload.location.search
    })),
    map(({ parts, queryString }) => {
      if (parts.length > 0) {
        const root = parts[1];

        switch (`/${root}`) {
          case kLibraryRootUrl:
            return handleLibraryRouting(parts, queryString);

          case kCommunityRootUrl:
            return handleCommunityRouting(parts);

          case kUsersRootUrl:
            return handleUsersRouting(parts, state$);

          case kProfileUrlKey:
            return handleProfileRouting(parts);

          case kSearchUrlKey:
            return of(
              getAlgorithmSearchResults.request({
                query: parts[2],
                user: state$.value.userStore.loggedInUser
              }),
              getUsers.request({ nameQuery: parts[2], skip: 0, take: 100 })
            );

          case kUserSearchUrlKey:
            return of(
              getUsers.request({ nameQuery: parts[2], skip: 0, take: 100 })
            );

          case kVariablesMenuUrlKey:
            switch (parts[2]) {
              case "units":
                return of(retrieveUnits.request({ skip: 0, take: 100 }));
            }
            return of(retrieveParameters.request({ skip: 0, take: 100 }));

          case kStudiesUrlKey: // Admin
            if (parts.length === 2) {
              return of(retrieveStudies.request({ skip: 0, take: 50 }));
            }
            if (parts.length > 2) {
              return of(
                retrieveStudies.request({ skip: 0, take: 1, id: parts[2] })
              );
            }
            break;

          case kStudyUrlKey: // Consumption
            if (parts.length > 2) {
              return of(
                retrieveStudies.request({ skip: 0, take: 1, id: parts[2] })
              );
            }
        }
      }
      return undefined;
    }),
    flatMap(op => op || of(ignoreElements))
  );
