import { Classes, Label } from "@blueprintjs/core";
import * as React from "react";
import { WrappedFieldProps } from "redux-form";

import { Label as DataLabel } from "src/api";

export interface IRenderFieldProps extends WrappedFieldProps {
  className?: string;
  disabled?: boolean;
  inputRef?: React.Ref<HTMLInputElement | HTMLTextAreaElement>;
  label: string;
  placeholder?: string;
  specialties?: DataLabel[];
  type?: string;
}

const renderFormField: React.SFC<IRenderFieldProps> = props => {
  const {
    className: classes,
    disabled = false,
    input,
    inputRef,
    type,
    label,
    meta: { touched, error }
  } = props;

  const className = `${classes} ${Classes.INPUT} w-100 mb1 ${
    touched && error ? Classes.INTENT_DANGER : ""
  }`;

  const typeRenderer =
    type === "textarea" ? (
      <textarea
        className={className}
        disabled={disabled}
        {...input}
        ref={inputRef as React.Ref<HTMLTextAreaElement>}
        style={{ resize: "vertical", maxHeight: 400 }}
      />
    ) : (
      <input
        className={className}
        disabled={disabled}
        {...input}
        ref={inputRef as React.Ref<HTMLInputElement>}
        type={type}
      />
    );

  return (
    <Label>
      {label}
      {typeRenderer}
      <span className="red">{touched ? error : ""}</span>
    </Label>
  );
};

const renderFormArrayField: React.SFC<IRenderFieldProps> = props => {
  const {
    className: classes,
    disabled = false,
    input,
    inputRef,
    type,
    meta: { touched, error }
  } = props;

  const className = `${classes} ${Classes.INPUT} w-100 mb1 ${
    touched && error ? Classes.INTENT_DANGER : ""
  }`;

  const keyAndId = input.name.replace("[", "").replace("]", "");
  return type === "textarea" ? (
    <textarea
      className={className}
      disabled={disabled}
      id={keyAndId}
      key={keyAndId}
      {...input}
      style={{ resize: "vertical", maxHeight: 400 }}
      ref={inputRef as React.Ref<HTMLTextAreaElement>}
    />
  ) : (
    <input
      className={className}
      disabled={disabled}
      {...input}
      key={keyAndId}
      ref={inputRef as React.Ref<HTMLInputElement>}
      type={type}
    />
  );
};

export const FormField = renderFormField;
export const FormArrayField = renderFormArrayField;
