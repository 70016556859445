import { Dictionary } from "lodash";

import { AlgoNode, AlgoNodeType, IAlgoNodeTarget } from "src/api";

export interface ICardPosition {
  column: number;
  row: number;
}

export class CardAttributes {
  public expanded = false;
  public selected = false; // Maintain visual selection state for
  public multiOption = false;
  public compactLayout = false;
  public multiOptionRange?: string; // If exists, we have a multi-option target
  public notNextTargetIp?: ICardPosition;
  public nextTarget?: AlgoNode;
  public position: ICardPosition = { column: 0, row: 0 };
  public readonly node: AlgoNode;
  public readonly options: IAlgoNodeTarget[];

  constructor(
    pos: ICardPosition,
    node: AlgoNode,
    allNodes: Dictionary<AlgoNode>
  ) {
    this.position = pos;
    this.node = node;
    this.options = [];

    if (
      node.kind === AlgoNodeType.singleSelect ||
      node.kind === AlgoNodeType.multiSelect ||
      node.kind === AlgoNodeType.varInput
    ) {
      this.expanded = true;

      const options: IAlgoNodeTarget[] = [];
      node.options(allNodes).forEach(nt => {
        options.push(nt);
      });
      this.options = options;
      if (node.kind === AlgoNodeType.varInput) {
        const next = node.targets(allNodes);
        this.nextTarget = next && next[0] ? next[0].node : undefined;
      }
    }
  }
}
