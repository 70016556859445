import { Spinner } from "@blueprintjs/core";
import * as React from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { AlgorithmConsume } from "./consumption/algorithm-consume";
import { connect } from "react-redux";

import { CssSize } from "src/components";
import { IOpenAlgorithm, IStoreState } from "src/store";
import { AlgorithmEdit } from "./editing/algorithm-edit";

interface IAlgorithmContainerProps
  extends RouteComponentProps<{
    topicId: string;
    algoId: string;
    nodeId?: string;
  }> {
  cssSize: CssSize;
  openAlgorithm?: IOpenAlgorithm;
}

class AlgorithmContainerComponent extends React.PureComponent<
  IAlgorithmContainerProps
> {
  public render() {
    const {
      cssSize,
      openAlgorithm,
      match: {
        params: { nodeId }
      },
      location: { search }
    } = this.props;

    if (!openAlgorithm) {
      return (
        <section className="w-100 mt4">
          <Spinner size={50} />
        </section>
      );
    }

    const subRenderer = openAlgorithm.editModeActive ? (
      <AlgorithmEdit
        openAlgorithm={openAlgorithm}
        nodeId={nodeId}
        queryString={search}
      />
    ) : (
      <AlgorithmConsume
        cssSize={cssSize}
        nodeId={nodeId}
        search={search}
        openAlgorithm={openAlgorithm}
      />
    );
    return <section className="flex flex-auto">{subRenderer}</section>;
  }
}

const mapStateToProps = (
  { algoStore, uiStore, userStore }: IStoreState,
  props: IAlgorithmContainerProps
) => {
  const nodeId = props.match.params.nodeId;
  const algoId = props.match.params.algoId;
  const algorithm = algoStore.openAlgorithms.find(
    a => a.algorithm.id === algoId
  );

  return {
    algoId,
    error: uiStore.error,
    loggedInUser: userStore.loggedInUser,
    nodeId,
    openAlgorithm: algorithm
  };
};

export const AlgorithmContainer = withRouter(
  connect(mapStateToProps, {})(AlgorithmContainerComponent)
);
