import { Button, Classes, Dialog, Label, Intent } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import * as React from "react";
import { Dictionary } from "lodash";
import { connect } from "react-redux";

import { IStoreState } from "src/store";
import { Study, User } from "src/api";
import { createStudy, clearStudyError } from "src/actions";

interface INewStudyDialogProps {
  isOpen: boolean;
  onClose: (createdStudyId?: string) => void;
}

interface INewStudyDialogInjectedProps {
  error?: Error;
  loggedInUser?: User;
  studies: Dictionary<Study>;
}

interface INewStudyDispatchProps {
  clearStudyError: typeof clearStudyError;
  createStudy: typeof createStudy.request;
}

interface INewStudyDialogComponentState {
  busy: boolean;
  newStudyTitle?: string;
  newStudyUuid?: string;
}

type CreateStudyDialogProps = INewStudyDialogProps &
  INewStudyDialogInjectedProps &
  INewStudyDispatchProps;

class CreateStudyDialogComponent extends React.PureComponent<
  CreateStudyDialogProps,
  INewStudyDialogComponentState
> {
  constructor(props: CreateStudyDialogProps) {
    super(props);

    this.state = {
      busy: false
    };
    this.props.clearStudyError();
  }

  public componentDidUpdate(prevProps: CreateStudyDialogProps) {
    const { newStudyUuid } = this.state;
    const { error, isOpen, studies } = this.props;

    if (newStudyUuid && studies[newStudyUuid]) {
      this.setState({ newStudyUuid: undefined, newStudyTitle: undefined });
      this.props.onClose(newStudyUuid);
    }

    if (!prevProps.isOpen && isOpen) {
      if (error) {
        this.props.clearStudyError();
      }
    }
  }

  public render() {
    const { isOpen } = this.props;

    return (
      <Dialog
        icon={IconNames.STACKED_CHART}
        title="New Study"
        isOpen={isOpen}
        onClose={this.closeDialog}
      >
        {this.renderBody()}
        {this.renderFooter()}
      </Dialog>
    );
  }

  private renderFooter = () => {
    const { error } = this.props;

    const errorRenderer = (e: Error) => (
      <span className="mh2 orange">{e.message}</span>
    );

    return (
      <div className={`${Classes.DIALOG_FOOTER}`}>
        {error && errorRenderer(error)}
        <div className="flex flex-row justify-between pt2">
          <Button id="cancel" text="Cancel" onClick={this.closeDialog} />
          <Button
            disabled={this.createDisabled()}
            intent={Intent.SUCCESS}
            text="Create Study"
            onClick={this.createNewStudy}
          />
        </div>
      </div>
    );
  };

  private createDisabled = () =>
    !this.state.newStudyTitle || this.state.newStudyTitle.length < 5;

  private renderBody = () => {
    const { error } = this.props;

    const updateTitle = (e: React.ChangeEvent<HTMLInputElement>) => {
      this.setState({ newStudyTitle: e.target.value });
    };

    const handleEnter = (e: React.KeyboardEvent<HTMLInputElement>) => {
      const keycode = e.keyCode || e.which;
      if (keycode === 13) {
        if (!this.createDisabled()) {
          this.createNewStudy();
        }
      }
    };

    const style: React.CSSProperties =
      error && error.name === "409" ? { color: "var(--app-orange)" } : {};
    return (
      <section className={`${Classes.DIALOG_BODY} overflow-y-auto`}>
        Create a Study to manage visits and improve and track outcomes for a
        disease.
        <section className="pv2 flex flex-row w-100">
          <Label className="pa1 w-100">
            Study name
            <input
              style={style}
              className={`${Classes.INPUT} w-100`}
              value={this.state.newStudyTitle || ""}
              onChange={updateTitle}
              onKeyDown={handleEnter}
            />
          </Label>
        </section>
      </section>
    );
  };

  private createNewStudy = () => {
    const { loggedInUser } = this.props;
    const { newStudyTitle } = this.state;

    if (newStudyTitle) {
      // Grab name, do the thing
      const study = new Study();
      study.creator = loggedInUser;

      study.title = newStudyTitle;
      this.props.createStudy(study);
      this.setState({ newStudyUuid: study.id, busy: true });
    }
  };

  private closeDialog = async () => {
    this.props.onClose();
  };
}

const mapStateToProps = ({ studiesStore, userStore }: IStoreState) => {
  return {
    error: studiesStore.error,
    loggedInUser: userStore.loggedInUser,
    studies: studiesStore.studies
  };
};

export const CreateStudyDialog = connect(mapStateToProps, {
  createStudy: createStudy.request,
  clearStudyError
})(CreateStudyDialogComponent);
