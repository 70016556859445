import firebase from "firebase/app";
import "firebase/auth";
import "firebase/messaging";
import { Epic } from "redux-observable";
import { from, of } from "rxjs";
import {
  catchError,
  filter,
  flatMap,
  ignoreElements,
  map,
  switchMap
} from "rxjs/operators";
import { isActionOf } from "typesafe-actions";

import { push } from "connected-react-router";
import { store } from "src";
import { v4 as uuidv4 } from "uuid";
import { handleAsyncAction } from ".";
import {
  getSelf,
  login,
  logout,
  registerForPush,
  registerPushToken,
  registerUser,
  resetPassword,
  setAuthToken,
  setFirebaseUser
} from "../actions";
import { makeApiRequest, requestConfigForAction } from "../api";

export const loginAction: Epic = action$ =>
  action$.pipe(
    filter(isActionOf(login.request)),
    switchMap(action =>
      from(
        firebase
          .auth()
          .signInWithEmailAndPassword(
            action.payload.email,
            action.payload.password
          )
      ).pipe(
        map(login.success),
        catchError(error => {
          if (error.code === "auth/user-not-found") {
            // tslint:disable-next-line:no-console
            console.error("firebase user not found");
          }
          // tslint:disable-next-line:no-console
          console.error(error);
          return of(login.failure(error));
        })
      )
    )
  );

/**
 * Updates the local auth token on a firebase update
 */
export const updateFirebaseToken: Epic = action$ =>
  action$.pipe(
    filter(isActionOf(setFirebaseUser)),
    map(action => {
      const firebaseUser = action.payload;
      if (firebaseUser) {
        return firebaseUser.getIdToken();
      } else {
        return Promise.resolve(undefined);
      }
    }),
    flatMap(t => t), // resolve the token promise
    flatMap(token => {
      if (token && token.length > 0) {
        return of(setAuthToken(token), getSelf.request());
      }
      return of(setAuthToken(token));
    })
  );

let tokenTimeout: NodeJS.Timeout | undefined;

export const renewFirebaseToken: Epic = action$ =>
  action$.pipe(
    filter(isActionOf(setAuthToken)),
    map(() => {
      // Clear any old timeout if we have one.
      if (tokenTimeout) {
        clearTimeout(tokenTimeout);
      }

      tokenTimeout = setTimeout(
        async () => {
          const currentUser = firebase.auth().currentUser;
          if (currentUser !== null) {
            try {
              const newToken = await currentUser.getIdToken(true);
              if (newToken) {
                store.dispatch(setAuthToken(newToken));
              }
            } catch (error) {
              // tslint:disable-next-line:no-console
              console.error(error);
              // Any error here and it means the user must sign in again
              logout();
            }
          }
        },
        30000 * 60 // Tokens are usually valid for an hour, renew at 30 mins
      );
    }),
    ignoreElements()
  );

export const resetPasswordRequest: Epic = action$ =>
  action$.pipe(
    filter(isActionOf(resetPassword.request)),
    switchMap(action =>
      from(firebase.auth().sendPasswordResetEmail(action.payload.email)).pipe(
        map(resetPassword.success),
        catchError(error => of(resetPassword.failure(error)))
      )
    )
  );

export const firebaseLogout: Epic = action$ =>
  action$.pipe(
    filter(isActionOf(logout)),
    flatMap(() => firebase.auth().signOut()),
    map(() => push("/"))
  );

export const signUpNewUser: Epic = action$ =>
  action$.pipe(
    filter(isActionOf(registerUser.request)),
    switchMap(action =>
      from(
        firebase
          .auth()
          .createUserWithEmailAndPassword(
            action.payload.email,
            action.payload.password
          )
      ).pipe(
        flatMap(userCreds => {
          if (userCreds.user) {
            // Firebase account created now,
            userCreds.user.sendEmailVerification();
            return userCreds.user.getIdToken();
          } else {
            throw new Error("Temporary failure with Firebase");
          }
        }),
        flatMap(token =>
          from(makeApiRequest(requestConfigForAction(action, token))).pipe(
            map(response => registerUser.success(response), setAuthToken(token))
          )
        ),
        catchError(error => {
          return of(registerUser.failure(error), setAuthToken(undefined));
        })
      )
    )
  );

export const registerForPushHandler: Epic = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(registerForPush.request)),
    switchMap(async () => {
      await firebase.messaging().requestPermission();
      const token = await firebase.messaging().getToken();
      if (token) {
        return of(
          registerForPush.success(token),
          registerPushToken.request({
            deviceId: state$.value.userStore.deviceId || uuidv4(),
            pushToken: token
          })
        );
      }
      throw new Error("Messaging token is null");
    }),
    flatMap(t => t),
    catchError(error => of(registerForPush.failure(error)))
  );

export const handlePushRegistration: Epic = (action$, state$) =>
  handleAsyncAction(action$, state$, registerPushToken);
