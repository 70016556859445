import {
  MenuItem,
  Menu,
  Button,
  Alignment,
  MenuDivider
} from "@blueprintjs/core";
import { Select, ItemListRenderer, ItemRenderer } from "@blueprintjs/select";
import * as React from "react";

import { IStudyInfo } from "src/api";
import { IconNames } from "@blueprintjs/icons";
import { kLibraryRootUrl } from "src/config/routes";
import { preventDefault } from "src/utilities";

const StudySelectComponent = Select.ofType<IStudyInfo>();

export interface IStudySelectProps {
  className?: string;
  showText: boolean;
  disabled?: boolean;
  location: string;
  studyInfo?: IStudyInfo[];
  studyId?: string;
  onChange: (newValue?: IStudyInfo) => void;
}

export class StudySelect extends React.PureComponent<IStudySelectProps> {
  public render() {
    const {
      className,
      showText,
      disabled,
      location,
      studyId,
      studyInfo
    } = this.props;
    const popoverProps = {
      minimal: true,
      popoverClassName: "zx-scrolling-popover"
    };

    const active = location.startsWith(kLibraryRootUrl);
    const studyCount = (studyInfo || []).length;
    const rightIcon = showText && studyCount > 0 && IconNames.CARET_DOWN;
    const buttonClick = (e: React.MouseEvent) => {
      if (studyCount === 0) {
        e.stopPropagation();
        this.props.onChange();
      }
    };
    const study = studyInfo && studyInfo.find(s => s.id === studyId);
    const text = (studyId && study && study.title) || "Library";

    return (
      <div className={`${className}`} onClick={preventDefault}>
        <StudySelectComponent
          disabled={disabled}
          filterable={false}
          inputProps={{ disabled }}
          items={studyInfo || []}
          itemRenderer={this.itemRenderer}
          itemListRenderer={this.itemListRenderer}
          onItemSelect={this.selectionHandler}
          popoverProps={popoverProps}
          resetOnClose={true}
        >
          <Button
            active={active}
            alignText={Alignment.LEFT}
            className="zx-left-menu-button"
            disabled={disabled}
            fill={true}
            icon={study ? IconNames.STACKED_CHART : IconNames.BOOK}
            onClick={buttonClick}
            rightIcon={rightIcon}
            text={showText && text}
          />
        </StudySelectComponent>
      </div>
    );
  }
  private itemListRenderer: ItemListRenderer<IStudyInfo> = itemListProps => {
    const { items, renderItem } = itemListProps;
    const { location, onChange } = this.props;

    const goToLibrary = () => onChange();

    return (
      <Menu>
        {items.map(renderItem)}
        <MenuDivider key="div" />
        <MenuItem
          active={location.startsWith(kLibraryRootUrl)}
          icon={IconNames.BOOK}
          key="Library"
          onClick={goToLibrary}
          text="Library"
        />
      </Menu>
    );
  };

  private itemRenderer: ItemRenderer<IStudyInfo> = (
    value,
    { handleClick, modifiers: { disabled } }
  ) => {
    return (
      <MenuItem
        active={value.id === this.props.studyId}
        disabled={disabled}
        icon={IconNames.STACKED_CHART}
        key={value.id}
        onClick={handleClick}
        text={value.title}
      />
    );
  };

  private selectionHandler = (i: IStudyInfo) => this.props.onChange(i);
}
