import {
  IconName,
  MenuItem,
  Spinner,
  IInputGroupProps
} from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import { ItemRenderer, Suggest } from "@blueprintjs/select";
import { Dictionary } from "lodash";
import * as React from "react";
import { connect } from "react-redux";

import { retrieveParameters } from "src/actions";
import {
  dictionaryFromPayload,
  makeApiRequest,
  notEmpty,
  Parameter,
  requestConfigForAction,
  IXmedObjectInfo
} from "src/api";
import { IOpenAlgorithm, IStoreState } from "src/store";
import { highlightText, sortAlphabetically } from "src/utilities";

interface IParameterSuggestProps {
  className?: string;
  openAlgo?: IOpenAlgorithm;
  leftIcon?: IconName;
  onChange: (unit: Parameter) => void;
  onCreate?: (unit: Parameter) => void;
  value?: Parameter;
}

interface IParameterSuggestInjectedProps {
  token?: string;
  parameters: Dictionary<Parameter>;
}

interface IParameterSuggestState {
  abortController?: AbortController;
  error?: Error;
  items: Parameter[];
  loading: boolean;
  query?: string;
}

type ParameterSuggestComponentProps = IParameterSuggestProps &
  IParameterSuggestInjectedProps;
class ParameterSuggestComponent extends React.PureComponent<
  ParameterSuggestComponentProps,
  IParameterSuggestState
> {
  private debouncer: NodeJS.Timeout | undefined;
  constructor(props: ParameterSuggestComponentProps) {
    super(props);

    this.state = {
      items: props.value ? [props.value] : [],
      loading: false,
      query: ""
    };
  }

  public componentDidUpdate(prevProps: ParameterSuggestComponentProps) {
    const { openAlgo, parameters, value } = this.props;
    const extraItems = openAlgo ? openAlgo.algorithm.localVars : undefined;

    if (
      parameters !== prevProps.parameters ||
      (value && value !== prevProps.value)
    ) {
      const items = this.mergeResults(
        parameters,
        extraItems,
        value ? { [value.id]: value } : undefined
      );
      this.setState({ items });
    }
  }

  public render() {
    const { className, leftIcon, onCreate, value } = this.props;
    const { items } = this.state;

    const popoverProps = {
      minimal: true,
      usePortal: true
    };

    const inputProps: IInputGroupProps = {
      className,
      leftIcon,
      placeholder: "Add Variable"
    };

    return (
      <Suggest<Parameter>
        items={items}
        createNewItemFromQuery={this.handleCreate}
        createNewItemRenderer={onCreate ? this.createRenderer : undefined}
        noResults={this.renderNoResults()}
        itemRenderer={this.itemRenderer}
        inputValueRenderer={this.inputValueRenderer}
        onItemSelect={this.handleSelect}
        selectedItem={value || null}
        onQueryChange={this.getLabelsDebounced}
        popoverProps={popoverProps}
        openOnKeyDown={false}
        resetOnQuery={false}
        resetOnSelect={true}
        resetOnClose={false}
        inputProps={inputProps}
      />
    );
  }

  private handleCreate = (term: string) => {
    const { openAlgo } = this.props;

    const newUnit = new Parameter(term);
    if (openAlgo) {
      newUnit.algoLocal = true;
    }
    return newUnit;
  };

  private renderNoResults = () => {
    const { loading } = this.state;
    return loading ? <Spinner size={Spinner.SIZE_SMALL} /> : null;
  };

  private handleSelect = (item: Parameter) => this.props.onChange(item);

  private inputValueRenderer = (item: Parameter) => item.title;

  private itemRenderer: ItemRenderer<Parameter> = (
    { id, title },
    { handleClick, modifiers: { active, disabled, matchesPredicate }, query }
  ) => {
    if (!matchesPredicate) {
      return null;
    }

    return (
      <MenuItem
        active={active}
        disabled={disabled}
        key={id}
        onClick={handleClick}
        text={highlightText(title, query)}
      />
    );
  };

  private createRenderer = (query: string, active: boolean) => {
    const { items } = this.state;
    const { onCreate } = this.props;

    const createNew = () => {
      const newUnit = new Parameter(query);
      if (onCreate) {
        onCreate(newUnit);
      }
      this.setState({ items: [newUnit, ...items] });
    };
    return (
      <MenuItem
        active={active}
        icon={IconNames.ADD}
        onClick={createNew}
        text={`Create variable named '${query}'`}
      />
    );
  };

  private getLabelsDebounced = (query: string) => {
    if (this.debouncer) {
      clearTimeout(this.debouncer);
    }
    this.debouncer = setTimeout(() => this.getLabels(query), 600);
  };

  private getLabels = async (query: string) => {
    const { token } = this.props;
    const { abortController: prevController } = this.state;

    if (prevController) {
      prevController.abort();
    }

    if (query.length === 0) {
      this.setState({ items: [], query });
    } else {
      const newController = new AbortController();
      this.setState({
        abortController: newController,
        loading: true,
        query,
        error: undefined
      });

      try {
        const response = await makeApiRequest(
          requestConfigForAction(
            retrieveParameters.request({ query, skip: 0, take: 10 }),
            token,
            newController.signal
          )
        );
        if (response.apiResponse.data) {
          const results = dictionaryFromPayload(
            response.apiResponse.data as IXmedObjectInfo[],
            Parameter
          );
          const items = this.mergeResults(
            results,
            this.props.parameters
          ).filter(i => i.title.toLowerCase().includes(query.toLowerCase()));
          this.setState({ abortController: undefined, items, loading: false });
        }
      } catch (error) {
        this.setState({ abortController: undefined, loading: false, error });
      }
    }
  };

  private mergeResults = (
    setA: Dictionary<Parameter>,
    setB: Dictionary<Parameter> | undefined,
    setC?: Dictionary<Parameter>
  ): Parameter[] => {
    const results: Parameter[] = Object.values({
      ...setA,
      ...setB,
      ...setC
    }).filter(notEmpty);

    results.sort((a, b) => sortAlphabetically(a.title, b.title));
    return results;
  };
}

const mapStateToProps = ({
  userStore,
  parameterStore
}: IStoreState): IParameterSuggestInjectedProps => {
  return {
    parameters: parameterStore.params,
    token: userStore.authToken
  };
};

export const ParameterSuggest = connect(
  mapStateToProps,
  {}
)(ParameterSuggestComponent);
