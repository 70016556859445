import {
  Alert,
  Button,
  Checkbox,
  H4,
  H5,
  Intent,
  Icon,
  Tabs,
  Tab,
  H6
} from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import DOMPurify from "dompurify";
import { omit } from "lodash";
import * as React from "react";
import { connect } from "react-redux";

import { setEditState, updateAlgo, removeNodes, createNode } from "src/actions";
import {
  Parameter,
  removeFromArray,
  updateArray,
  User,
  AlgoNodeType,
  AlgoNode
} from "src/api";
import { ParameterSuggest } from "src/components";
import { EditVariableDialog } from "src/pages/admin";
import { IOpenAlgorithm, IStoreState, IVariableDefinition } from "src/store";
import {
  sortAlphabetically,
  nodeTextPartsPlain,
  nodeTitleTextParts
} from "src/utilities";
import marked from "marked";

export interface IAlgoVarsEditorProps {
  openAlgorithm: IOpenAlgorithm;
}

interface IAlgoVarsEditorInjectedProps {
  currentUser?: User;
}

interface IAlgoVarsEditorDispatchProps {
  createNode: typeof createNode;
  setEditState: typeof setEditState;
  removeNodes: typeof removeNodes;
  updateAlgo: typeof updateAlgo;
}

interface IAlgoVarsEditorState {
  createdParam?: Parameter;
  newVarPanelOpen: boolean;
  nodeToRemove?: AlgoNode;
  varToRemove?: Parameter;
}

type AlgoVarsEditorProps = IAlgoVarsEditorProps &
  IAlgoVarsEditorInjectedProps &
  IAlgoVarsEditorDispatchProps;

class AlgoVarsEditorComponent extends React.PureComponent<
  AlgoVarsEditorProps,
  IAlgoVarsEditorState
> {
  constructor(props: AlgoVarsEditorProps) {
    super(props);
    this.state = {
      newVarPanelOpen: false
    };
  }

  public render() {
    const { openAlgorithm } = this.props;
    const { createdParam, newVarPanelOpen } = this.state;

    const closeVarDialog = (parameter?: Parameter) => {
      if (parameter) {
        this.handleParamChange(parameter);
      }
      this.setState({ newVarPanelOpen: false });
    };

    return (
      <article className="flex flex-column flex-auto animated fadeIn faster zx-shadow flex-auto bg-white mt1 pa2 br2 overflow-y-auto">
        {this.renderDeleteConfirmDialog()}
        <EditVariableDialog
          openAlgo={openAlgorithm}
          isOpen={newVarPanelOpen}
          onClose={closeVarDialog}
          paramToShow={createdParam}
        />
        <div
          className="flex flex-row justify-between pa2"
          style={{ flexShrink: 0 }}
        >
          <div>
            <div className="flex flex-row">
              <Icon className="pr2" icon={IconNames.VARIABLE} iconSize={20} />
              <H4>Variables and Text</H4>
            </div>
          </div>
          <Button
            icon={IconNames.CROSS}
            minimal={true}
            onClick={this.handleClose}
          />
        </div>
        <Tabs className="pa2">
          <Tab
            id="vars"
            panel={this.renderVars(openAlgorithm)}
            title="Variables"
          />
          <Tab
            id="snippets"
            panel={this.renderSnippets(openAlgorithm)}
            title="Shared Text"
          />
        </Tabs>
        {}
      </article>
    );
  }

  private renderDeleteConfirmDialog = () => {
    const { nodeToRemove, varToRemove } = this.state;
    const {
      openAlgorithm: { algorithm }
    } = this.props;

    const handleCancelDelete = () =>
      this.setState({ varToRemove: undefined, nodeToRemove: undefined });

    const handleConfirmDelete = () => {
      if (varToRemove) {
        if (varToRemove.algoLocal) {
          this.props.updateAlgo({
            ...algorithm,
            localVars: omit(algorithm.localVars, varToRemove.id)
          });
        } else {
          const newVars = removeFromArray(algorithm.variables, varToRemove.id);
          this.props.updateAlgo({ ...algorithm, variables: newVars });
        }
        this.setState({ varToRemove: undefined });
      }
      if (nodeToRemove) {
        this.props.removeNodes({
          algoId: algorithm.id,
          nodeIds: { [nodeToRemove.id]: 1 }
        });
        this.setState({ nodeToRemove: undefined });
      }
    };

    const title = varToRemove
      ? varToRemove.title
      : nodeToRemove
      ? nodeTextPartsPlain(nodeToRemove).title
      : "";

    return (
      <Alert
        canEscapeKeyCancel={true}
        canOutsideClickCancel={true}
        cancelButtonText="Cancel"
        onCancel={handleCancelDelete}
        confirmButtonText="Delete"
        onConfirm={handleConfirmDelete}
        isOpen={varToRemove !== undefined || nodeToRemove !== undefined}
        icon={IconNames.TRASH}
        intent={Intent.DANGER}
      >
        <p>This will remove &apos;{title}&apos;. Are you sure?</p>
      </Alert>
    );
  };

  private handleParamChange = (parameter: Parameter) => {
    const {
      openAlgorithm: { algorithm }
    } = this.props;
    if (parameter.algoLocal) {
      this.props.updateAlgo({
        ...algorithm,
        localVars: { ...algorithm.localVars, [parameter.id]: parameter }
      });
    } else {
      const newVars = updateArray(algorithm.variables, parameter);
      this.props.updateAlgo({ ...algorithm, variables: newVars });
    }
  };

  private renderVars = (openAlgo: IOpenAlgorithm) => {
    const { algorithm } = openAlgo;
    const { localVars, variables } = algorithm;

    const handleCreate = (parameter: Parameter) => {
      // Go bring up the dialog to do this.
      this.setState({ newVarPanelOpen: true, createdParam: parameter });
    };

    const algoLocalVars = Object.values(localVars || {}).sort((a, b) =>
      sortAlphabetically(a.title, b.title)
    );

    const renderedVars =
      variables.length === 0 ? (
        <p className="gray ph1">No system variables added yet.</p>
      ) : (
        Object.values(variables)
          .sort((a, b) => sortAlphabetically(a.title, b.title))
          .map(this.renderVariableRow)
      );

    const renderedLocalVars =
      algoLocalVars.length === 0 ? (
        <p className="gray ph1">No algorithm local variables present</p>
      ) : (
        algoLocalVars.map(this.renderVariableRow)
      );

    return (
      <section className="bg-white pa2 br3 w-100">
        <section className="w-100 flex b bb b--light-gray pv2 pb1 mb1">
          <div className="fl w-40 pr1 pv1">Name</div>
          <div className="fl w-10 pr1 pv1">Output?</div>
          <div className="fl w-50 flex flex-row justify-between">
            <div className="pv1">Components</div>
            <ParameterSuggest
              openAlgo={openAlgo}
              onChange={this.handleParamChange}
              onCreate={handleCreate}
              value={undefined}
            />
          </div>
        </section>
        {renderedVars}
        <section className="w-100 flex b bb b--light-gray mt3 pv2 pb1 mb1">
          <H5>Algorithm-local Variables</H5>
        </section>
        {renderedLocalVars}
      </section>
    );
  };

  private renderSnippets = (openAlgo: IOpenAlgorithm) => {
    const {
      algoNodes,
      algorithm: { id }
    } = openAlgo;

    const snippetNodes = Object.values(algoNodes).filter(
      n => n.kind === AlgoNodeType.sharedText
    );
    const createNew = () => {
      this.props.createNode({
        algoId: id,
        kind: AlgoNodeType.sharedText
      });
    };

    return (
      <section className="bg-white pa2 br3">
        <section className="w-100 flex b bb b--light-gray pv2 pb1 mb1">
          <div className="fl w-60 pr1 pv1">Name</div>
          <div className="fl w-30 pr1 pv1">Used in Nodes</div>
          <div className="fl w-10 flex flex-row justify-end">
            <Button
              text="New..."
              icon={IconNames.ADD}
              minimal={true}
              onClick={createNew}
            />
          </div>
        </section>
        {snippetNodes.map(this.renderSharedTextNode)}
      </section>
    );
  };

  private renderSharedTextNode = (stNode: AlgoNode) => {
    const {
      openAlgorithm: { algoNodes }
    } = this.props;
    const textComponents = nodeTitleTextParts(stNode);
    const parents = stNode
      .backwardLinks()
      .map(l => (
        <p key={l.id}>{nodeTextPartsPlain(algoNodes[l.parentId]).title}</p>
      ));

    const handleDelete = () => this.setState({ nodeToRemove: stNode });
    const editText = () => this.editSharedText(stNode.id);
    const textHtml = DOMPurify.sanitize(marked(textComponents.theRest));

    return (
      <section
        className="w-100 flex  bb b--light-gray pv2 pb1 mb1"
        key={stNode.id}
      >
        <div className="fl w-60 pointer" onClick={editText}>
          <div className="flex flex-row">
            <Icon className="mr2" icon={IconNames.EDIT} />
            <div>
              <H6>{textComponents.title}</H6>
              <span dangerouslySetInnerHTML={{ __html: textHtml }} />
            </div>
          </div>
        </div>
        <div className="fl w-30 flex flex-column">{parents}</div>
        <div className="fl w-10 flex flex-row justify-end">
          <Button
            intent={Intent.DANGER}
            icon={IconNames.TRASH}
            minimal={true}
            onClick={handleDelete}
          />
        </div>
      </section>
    );
  };

  private renderVariableRow = (param: Parameter, index: number) => {
    const isGroup = param.detailsJson.length !== 1;

    const showDeleteConfirm = (e: React.MouseEvent) => {
      e.stopPropagation();
      this.setState({ varToRemove: param });
    };

    const handleCheckbox: React.FormEventHandler<HTMLInputElement> = e => {
      this.handleParamChange({
        ...param,
        output: e.currentTarget.checked || false
      });
    };

    const selectHandler = param.algoLocal
      ? (/*e: React.MouseEvent*/) => {
          // Edit the variable.
          this.setState({ createdParam: param, newVarPanelOpen: true });
        }
      : undefined;

    const outputSelector = !param.algoLocal && (
      <Checkbox checked={param.output || false} onChange={handleCheckbox} />
    );

    const components = isGroup
      ? param.detailsJson.map(this.renderVarComponent)
      : this.renderVarComponent(param.detailsJson[0]);

    return (
      <section className={"flex b bb b--light-gray"} id={param.id} key={index}>
        <div
          className={`fl w-40 pa1 ${selectHandler ? "pointer" : ""}`}
          onClick={selectHandler}
        >
          <p>{param.title}</p>
        </div>
        <div className="fl w-10 pa1">{outputSelector || "-"}</div>
        <div className="fl w-50 pa1 flex flex-row justify-between">
          <div>{components}</div>
          <div className="fl w-20 pa1 flex justify-end">
            <Button
              icon={IconNames.REMOVE}
              minimal={true}
              onClick={showDeleteConfirm}
              small={true}
            />
          </div>
        </div>
      </section>
    );
  };

  private renderVarComponent = (
    varDef: IVariableDefinition,
    index?: number
  ) => {
    if (index !== undefined) {
      return (
        <div key={index}>
          {varDef.name} ({varDef.type})
        </div>
      );
    } else {
      switch (varDef.type) {
        case "discrete":
          return varDef.discreteValues
            ? varDef.discreteValues.map(v => v.value || v).join(" / ")
            : "-";
        case "boolean":
          return "true / false";
        case "numeric":
          if (varDef.range) {
            return `Range ${varDef.min}-${varDef.max}`;
          }
          return varDef.unit && varDef.unit.name;

        default:
          return null;
      }
    }
  };

  private handleClose = async () => {
    const { openAlgorithm } = this.props;

    this.props.setEditState({
      algoId: openAlgorithm.algorithm.id,
      state: { algoInfoPanel: undefined }
    });
  };

  private editSharedText = (editSharedNodeId: string) => {
    const {
      openAlgorithm: { algorithm }
    } = this.props;

    this.props.setEditState({
      algoId: algorithm.id,
      state: { editSharedNodeId }
    });
  };
}

const mapStateToProps = ({ userStore }: IStoreState) => {
  return {
    currentUser: userStore.loggedInUser
  };
};

export const AlgoVarsEditor = connect(mapStateToProps, {
  createNode,
  setEditState,
  updateAlgo,
  removeNodes
})(AlgoVarsEditorComponent);
