import { IconName, IconNames } from "@blueprintjs/icons";
import { NumericDictionary } from "lodash";
import { Parameter } from "./parameter";

export enum AlgoVariableType {
  weight = "weight",
  numeric = "numeric"
}

export const kSelectableVariableTypes = [AlgoVariableType.weight];

export function textForAlgoVariableType(type: AlgoVariableType) {
  switch (type) {
    case AlgoVariableType.weight:
      return "Option weighting";
    case AlgoVariableType.numeric:
      return "Numeric";
  }
}

export enum NodeActionType {
  goToNode,
  goToAlgorithm,
  setVariable,
  checkVariable,
  sendEmail
}

export const kSingleSelectNodeActionTypes = (hasVars: boolean) =>
  hasVars
    ? [NodeActionType.goToNode, NodeActionType.goToAlgorithm]
    : [NodeActionType.goToNode, NodeActionType.goToAlgorithm];

export const kMultiSelectNodeActionTypes = [NodeActionType.setVariable];

export function detailsForNodeActionType(
  type: NodeActionType
): { text: string; icon: IconName } {
  switch (type) {
    case NodeActionType.goToNode:
      return { text: "Go to Node", icon: IconNames.ARROW_RIGHT };
    case NodeActionType.goToAlgorithm:
      return { text: "Go to Algorithm", icon: IconNames.LAYER };
    case NodeActionType.setVariable:
      return { text: "Variable...", icon: IconNames.VARIABLE };
    case NodeActionType.checkVariable:
      return { text: "Use Variable...", icon: IconNames.VARIABLE };
    case NodeActionType.sendEmail:
      return { text: "Send email...", icon: IconNames.ENVELOPE };
  }
}

export enum VariableOperator {
  add = "add",
  subtract = "subtract",
  between = "between",
  isEqualTo = "equals",
  clear = "clear",
  greaterThan = "greaterThan",
  lessThan = "lessThan",
  set = "Set"
}

export enum VariableType {
  text,
  number
}

export function operatorsForVariableType(type: AlgoVariableType) {
  switch (type) {
    case AlgoVariableType.weight:
      return [
        [VariableOperator.add, VariableOperator.clear],
        [VariableOperator.isEqualTo, VariableOperator.between]
      ];
    case AlgoVariableType.numeric:
      return [
        [VariableOperator.add, VariableOperator.subtract],
        [
          VariableOperator.isEqualTo,
          VariableOperator.between,
          VariableOperator.greaterThan,
          VariableOperator.lessThan
        ]
      ];
  }
}

export interface IVariableOperatorDetails {
  booleanOutput: boolean;
  text: string[];
  icon?: IconName;
  inputs: Array<{ type: string; constraint: VariableType }>;
}

export function detailsForVariableOperator(
  type: VariableOperator
): IVariableOperatorDetails {
  switch (type) {
    case VariableOperator.add:
      return {
        booleanOutput: false,
        icon: IconNames.SMALL_PLUS,
        inputs: [{ type: "number", constraint: VariableType.number }],
        text: ["add"]
      };
    case VariableOperator.subtract:
      return {
        booleanOutput: false,
        icon: IconNames.SMALL_MINUS,
        inputs: [{ type: "number", constraint: VariableType.number }],
        text: ["subtract"]
      };
    case VariableOperator.between:
      return {
        booleanOutput: true,
        icon: IconNames.FLOW_REVIEW,
        inputs: [
          { type: "number", constraint: VariableType.number },
          { type: "number", constraint: VariableType.number }
        ],
        text: ["between ", "&"]
      };
    case VariableOperator.isEqualTo:
      return {
        booleanOutput: true,
        icon: IconNames.EQUALS,
        inputs: [{ type: "number", constraint: VariableType.number }],
        text: ["equals"]
      };
    case VariableOperator.clear:
      return {
        booleanOutput: false,
        icon: IconNames.DELETE,
        inputs: [],
        text: ["Clear other choices"]
      };
    case VariableOperator.lessThan:
      return {
        booleanOutput: true,
        icon: IconNames.LESS_THAN,
        inputs: [{ type: "number", constraint: VariableType.number }],
        text: ["is less than"]
      };
    case VariableOperator.greaterThan:
      return {
        booleanOutput: true,
        icon: IconNames.GREATER_THAN,
        inputs: [{ type: "number", constraint: VariableType.number }],
        text: ["is greater than"]
      };
    case VariableOperator.set:
      return {
        booleanOutput: true,
        icon: IconNames.EQUALS,
        inputs: [],
        text: ["set to"]
      };
  }
}

export interface IVariableConstraint {
  operator: VariableOperator;
  values: NumericDictionary<
    NumericDictionary<boolean | number | string | undefined>
  >;
}

export interface IVariableOperation {
  operator?: VariableOperator;
  values?: NumericDictionary<boolean | number | string>;
}

export interface IVariableUsage {
  id?: string;
  groupNumber?: number;
  operatorDetails?: IVariableConstraint;
  resultDetails?: IVariableOperation;
}

export function varUsageString(
  varUsage: IVariableUsage,
  parameters: Parameter[]
) {
  const param = parameters.find(p => p.id === varUsage.id);
  if (param) {
    const op = varUsage.operatorDetails;
    if (op) {
      const deets = detailsForVariableOperator(op.operator);
      const paramInfo = param.detailsJson[varUsage.groupNumber || 0];
      const unit = paramInfo.unit;
      const valueString = Object.values(op.values).reduce(
        (prev, cur, i) => {
          const operatorText = deets.text[i];
          if (operatorText) {
            let value = cur[varUsage.groupNumber || 0];
            if (paramInfo.discreteValues) {
              // Change the UUID to a string
              const realVal = paramInfo.discreteValues.find(
                v => v.id === value
              );
              if (realVal) {
                value = realVal.value;
              }
            }
            return `${prev} ${operatorText} ${value} ${
              unit ? unit.shortName : ""
            }`;
          }
          return prev;
        },
        varUsage.groupNumber === undefined || param.detailsJson.length === 1
          ? param.title
          : `${param.title} - ${param.detailsJson[varUsage.groupNumber].name}`
      );
      return valueString;
    }
    return `${param.title} criterion`;
  }
  return "Set variable(s)...";
}
