import { IconNames } from "@blueprintjs/icons";
import { Menu, MenuItem, Spinner, Icon, MenuDivider } from "@blueprintjs/core";
import { push } from "connected-react-router";
import moment from "moment";
import * as React from "react";
import { connect } from "react-redux";

import {
  Algorithm,
  requestConfigForAction,
  makeApiRequest,
  arrayFromPayload,
  IXmedObjectInfo,
  AlgorithmStatus
} from "src/api";
import { getAlgorithmRevisions } from "src/actions";
import { kLibraryRootUrl } from "src/config/routes";
import { IStoreState } from "src/store";
import { algoIconForStatus } from ".";

interface IAlgorithmVersionsMenuProps {
  algo: Algorithm;
  visible: boolean;
}

interface IAlgorithmVersionsMenuInjectedProps {
  token?: string;
}
interface IAlgorithmVersionsMenuDispatchProps {
  push: typeof push;
}

type AlgorithmVersionsMenuComponentProps = IAlgorithmVersionsMenuProps &
  IAlgorithmVersionsMenuInjectedProps &
  IAlgorithmVersionsMenuDispatchProps;
interface IAlgorithmVersionsMenuState {
  abortController?: AbortController;
  error?: Error;
  items: Algorithm[];
  loading: boolean;
}

class AlgorithmVersionsMenuComponent extends React.PureComponent<
  AlgorithmVersionsMenuComponentProps,
  IAlgorithmVersionsMenuState
> {
  constructor(props: AlgorithmVersionsMenuComponentProps) {
    super(props);
    this.state = {
      items: [],
      loading: true
    };
    this.retrieveVersions(props.algo.id);
  }

  public componentDidUpdate(prevProps: IAlgorithmVersionsMenuProps) {
    const { algo, visible } = this.props;
    const { loading } = this.state;

    if (!prevProps.visible && visible && !loading) {
      this.retrieveVersions(algo.id);
    }
  }

  public render() {
    const { visible } = this.props;
    const { items, loading } = this.state;

    if (!visible) {
      return null;
    }
    if (loading) {
      return (
        <section className="w4 pa2">
          <Spinner size={Spinner.SIZE_SMALL} />
        </section>
      );
    }

    return <Menu>{items.map(this.itemRenderer)}</Menu>;
  }

  private itemRenderer = (algo: Algorithm, idx: number, array: Algorithm[]) => {
    const icon =
      algo.id === this.props.algo.id ? IconNames.TICK : IconNames.BLANK;

    const publishedIndex = array.findIndex(
      a => a.status === AlgorithmStatus.published
    );
    const firstAlgoMedSpecialty = algo.medicalSpecialties[0];
    const topicId = firstAlgoMedSpecialty
      ? firstAlgoMedSpecialty.id
      : "undefined";

    let url = `${kLibraryRootUrl}/${topicId}/${algo.id}`;
    const textRenderer = () => {
      let icon = <Icon icon={IconNames.BOX} />;
      let text = "Current";

      switch (algo.status) {
        case AlgorithmStatus.published:
          icon = algoIconForStatus(algo.status);
          text = "In Publication";

          break;

        case AlgorithmStatus.draft:
          icon = algoIconForStatus(algo.status);
          text = "Current Draft";
          break;

        case AlgorithmStatus.inReview:
          icon = algoIconForStatus(algo.status);
          text = "Current Draft (In Review)";
          break;

        default:
          text = `${moment(algo.updatedAt).format("YY-MM-DD")} - version ${
            algo.version
          }`;
          url += "?allowOld=true";
      }

      return (
        <div>
          {icon}
          <span className="pl2">{text}</span>
        </div>
      );
    };

    const handleClick = () => {
      // Open this algo / switch to it.
      this.props.push(url);
    };
    const divider = publishedIndex !== -1 && idx === publishedIndex + 1 && (
      <MenuDivider />
    );

    return [
      divider,
      <MenuItem
        style={{ maxWidth: 400 }}
        // disabled={disabled}
        icon={icon}
        key={algo.id}
        onClick={handleClick}
        text={textRenderer()}
      />
    ];
  };

  private retrieveVersions = async (algoId: string) => {
    const { token } = this.props;
    const { abortController: prevController } = this.state;

    if (prevController) {
      prevController.abort();
    }
    const newController = new AbortController();
    this.setState({
      abortController: newController,
      loading: true,
      error: undefined
    });

    // Make the call
    try {
      const response = await makeApiRequest(
        requestConfigForAction(
          getAlgorithmRevisions.request(algoId),
          token,
          newController.signal
        )
      );
      if (response.apiResponse.data) {
        const items = arrayFromPayload(
          response.apiResponse.data as IXmedObjectInfo[],
          Algorithm
        ).sort((a, b) => b.version - a.version);
        this.setState({ abortController: undefined, items, loading: false });
      }
    } catch (error) {
      this.setState({ abortController: undefined, loading: false, error });
    }
  };
}

const mapStateToProps = ({ userStore }: IStoreState) => {
  return {
    token: userStore.authToken
  };
};

export const AlgorithmVersionsMenu = connect(mapStateToProps, { push })(
  AlgorithmVersionsMenuComponent
);
