import {
  Button,
  ButtonGroup,
  Classes,
  Dialog,
  InputGroup,
  Intent,
  Label
} from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import * as React from "react";
import { connect } from "react-redux";

import { destroy, submit } from "redux-form";
import {
  clearUiError,
  createAlgorithm,
  setNewAlgoDialogState
} from "src/actions";
import { Algorithm, AlgorithmStatus, createNewAlgorithm, User } from "src/api";
import { AlgoEditForm } from "src/pages/algorithms";
import { IStoreState } from "src/store";
import { focusFieldOnMount, sortAlphabetically } from "src/utilities";
import { AlgorithmSelect } from ".";

interface INewAlgorithmDialogInjectedProps {
  allAlgorithms: Algorithm[];
  error?: Error;
  isOpen: boolean;
  user?: User;
  visibleAlgorithms: Algorithm[];
  requestedAlgoId?: string;
  requestedVersion?: number;
}

interface INewAlgorithmDialogDispatchProps {
  clearUiError: typeof clearUiError;
  createNewAlgo: typeof createAlgorithm.request;
  submit: typeof submit;
  setNewAlgoDialogState: typeof setNewAlgoDialogState;
}

type NewAlgorithmDialogProps = INewAlgorithmDialogInjectedProps &
  INewAlgorithmDialogDispatchProps;

interface INewAlgorithmDialogState {
  algorithm?: Algorithm;
  baseAlgo?: Algorithm;
  isBusy: boolean;
  revisionMessage?: string;
  page: number;
  title: string;
}

class NewAlgorithmDialogComponent extends React.PureComponent<
  NewAlgorithmDialogProps,
  INewAlgorithmDialogState
> {
  constructor(props: NewAlgorithmDialogProps) {
    super(props);

    this.state = {
      isBusy: false,
      page: 1,
      title: ""
    };
  }

  public componentDidMount() {
    this.createAlgorithm();
  }

  public async componentDidUpdate() {
    const { algorithm, baseAlgo, isBusy } = this.state;
    const { allAlgorithms, error, requestedAlgoId } = this.props;

    if (error && isBusy) {
      this.setState({ isBusy: false });
      return;
    }

    if (isBusy) {
      if (algorithm && allAlgorithms.find(a => a.id === algorithm.id)) {
        this.resetDialog();
      }
    }

    const requestedAlgo = allAlgorithms.find(a => a.id === requestedAlgoId);
    if (!baseAlgo && requestedAlgo) {
      this.handleAlgoChange(requestedAlgo, 2);
    } else {
      this.createAlgorithm();
    }
  }

  public render() {
    const { page } = this.state;
    const { isOpen, requestedVersion } = this.props;

    return (
      <Dialog
        canOutsideClickClose={true}
        isCloseButtonShown={false}
        icon={IconNames.LAYER}
        title={`New ${
          requestedVersion ? `Version  (${requestedVersion})` : "Algorithm"
        }`}
        isOpen={isOpen}
      >
        {!requestedVersion && page === 1 && this.renderPageOne()}
        {page === 2 && this.renderPageTwo()}
        {this.renderFooter()}
      </Dialog>
    );
  }

  private renderPageOne = () => {
    const { baseAlgo, isBusy, title } = this.state;
    const { visibleAlgorithms } = this.props;

    const algoBaseListRenderer = (
      <>
        <span>Base on existing algorithm... (optional)</span>
        <AlgorithmSelect
          className="mt1"
          disabled={isBusy}
          onChange={this.handleAlgoChange}
          value={baseAlgo}
        />
      </>
    );

    return (
      <div className={`${Classes.DIALOG_BODY}`}>
        <Label className="flex flex-colum">
          Algorithm Title
          <InputGroup
            id="algoTitle"
            disabled={isBusy}
            name="title"
            placeholder="Enter the title of the algorithm"
            inputRef={focusFieldOnMount}
            type="text"
            value={title}
            onChange={this.handleTitleUpdate}
          />
        </Label>
        {visibleAlgorithms.length > 0 && algoBaseListRenderer}
      </div>
    );
  };

  private renderPageTwo = () => {
    const { algorithm } = this.state;
    const { requestedVersion } = this.props;
    if (!algorithm) {
      return this.renderPageOne();
    }
    if (requestedVersion) {
      return this.renderNewVersion();
    }

    return (
      <div className={`${Classes.DIALOG_BODY}`}>
        <AlgoEditForm
          algorithm={algorithm}
          initialValues={algorithm}
          form={algorithm.id}
          onSubmit={this.handleSubmit}
        />
      </div>
    );
  };

  private renderNewVersion = () => {
    const { revisionMessage } = this.state;

    const handleMessageChange = (
      event: React.ChangeEvent<HTMLTextAreaElement>
    ) => this.setState({ revisionMessage: event.currentTarget.value });

    return (
      <div className={`${Classes.DIALOG_BODY}`}>
        <p>
          Create a new version of this algorithm. Once published, it will
          replace the existing version.
        </p>
        <Label>
          Revision Notes (100 characters max.)
          <textarea
            className="mv2 w-100 br2"
            style={{ height: 100, maxHeight: 150, resize: "none" }}
            value={revisionMessage}
            onChange={handleMessageChange}
          />
        </Label>
      </div>
    );
  };

  private resetDialog = async () => {
    this.setState({
      algorithm: undefined,
      baseAlgo: undefined,
      isBusy: false,
      page: 1,
      title: ""
    });
    if (this.props.error) {
      this.props.clearUiError();
    }
    this.props.setNewAlgoDialogState({ open: false, baseAlgoId: undefined });
  };

  private renderFooter = () => {
    const { algorithm, page, isBusy, revisionMessage, title } = this.state;
    const { error, requestedVersion, user } = this.props;

    let buttonActionText = "Next";

    let actionFunction = () => {
      if (title && user) {
        let createdAlgo = algorithm;
        if (page === 1 && !algorithm) {
          createdAlgo = createNewAlgorithm(title, user);
        }
        this.setState({ algorithm: createdAlgo, page: page + 1 });
      }
    };

    const prevActionFunction = () => this.setState({ page: page - 1 });

    switch (page) {
      case 2:
        buttonActionText = "Create";
        actionFunction = this.createNewAlgo;
    }

    const previousButton = requestedVersion ? (
      undefined
    ) : (
      <Button
        disabled={isBusy || !title || title.length < 5}
        intent={Intent.NONE}
        onClick={prevActionFunction}
        text="Previous"
      />
    );

    const disabled =
      isBusy ||
      !title ||
      title.length < 5 ||
      (requestedVersion !== undefined &&
        (!revisionMessage || revisionMessage.length < 5));

    return (
      <div className={`${Classes.DIALOG_FOOTER} flex flex-row justify-between`}>
        <Button
          disabled={isBusy}
          intent={Intent.NONE}
          onClick={this.resetDialog}
          text={"Cancel"}
        />
        <ButtonGroup>
          {error && <span className="mt2 orange ph2">{error.message}</span>}
          {page > 1 && previousButton}
          <Button
            className="ml2"
            disabled={disabled}
            intent={Intent.PRIMARY}
            loading={isBusy}
            onClick={actionFunction}
            text={buttonActionText}
          />
        </ButtonGroup>
      </div>
    );
  };

  private handleTitleUpdate = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { algorithm: algo } = this.state;
    const { value: title } = e.target;
    if (algo) {
      this.setState({ title, algorithm: { ...algo, title } });
    }
  };

  private handleAlgoChange = (a: Algorithm, page: number = this.state.page) => {
    const { requestedVersion } = this.props;
    const title = requestedVersion ? a.title : `Copy of ${a.title}`;
    const { algorithm: newAlgo } = this.state;

    if (newAlgo) {
      const algorithm = {
        ...newAlgo,
        editors: a.editors,
        epidemiology: a.epidemiology,
        keywords: a.keywords,
        medicalSpecialties: a.medicalSpecialties,
        refId: a.id,
        references: a.references,
        summary: a.summary,
        synopsis: a.synopsis,
        title,
        version: requestedVersion || 1
      };

      this.setState({
        algorithm,
        baseAlgo: a,
        page,
        title
      });
    }
  };

  private createNewAlgo = async () => {
    const { algorithm, baseAlgo, revisionMessage } = this.state;
    const { requestedVersion } = this.props;

    if (algorithm) {
      if (requestedVersion) {
        this.props.createNewAlgo({
          algorithm,
          baseAlgo,
          newRevisionMessage: revisionMessage
        });
      } else {
        this.props.submit(algorithm.id);
      }

      this.setState({ isBusy: true });
    }
  };

  private handleSubmit = (values: Algorithm) => {
    const { baseAlgo } = this.state;

    this.props.createNewAlgo({ algorithm: values, baseAlgo });

    // Cleanup the form
    destroy(values.id);
  };

  private createAlgorithm = () => {
    const { algorithm, title } = this.state;
    const { user } = this.props;

    if (!algorithm && user) {
      const createdAlgo = createNewAlgorithm(title, user);
      this.setState({ algorithm: createdAlgo });
    }
  };
}

const mapStateToProps = ({
  algoStore: { allAlgorithms: storeAlgos },
  uiStore: { error, newAlgoDialogOpen, newAlgoBaseId, newAlgoVersion },
  userStore: { loggedInUser }
}: IStoreState) => {
  const allAlgorithms = Object.values(storeAlgos);

  return {
    allAlgorithms,
    error,
    isOpen: newAlgoDialogOpen,
    requestedAlgoId: newAlgoBaseId,
    requestedVersion: newAlgoVersion,
    user: loggedInUser,
    visibleAlgorithms: allAlgorithms
      .filter(
        a =>
          a.status === AlgorithmStatus.published ||
          (loggedInUser &&
            (a.authors.find(u => u.id === loggedInUser.id) ||
              a.editors.find(u => u.id === loggedInUser.id)))
      )
      .sort((a, b) => sortAlphabetically(a.title, b.title))
  };
};

export const NewAlgorithmDialog = connect(mapStateToProps, {
  clearUiError,
  createNewAlgo: createAlgorithm.request,
  setNewAlgoDialogState,
  submit
})(NewAlgorithmDialogComponent);
