import { Button, Classes, Intent } from "@blueprintjs/core";
import * as React from "react";

interface ITermsProps {
  acceptedTerms: () => void;
  refusedTerms: () => void;
}

interface ITermsState {
  seenAllTerms: boolean;
}

export class ZenxTerms extends React.PureComponent<ITermsProps, ITermsState> {
  private scroller?: HTMLDivElement;

  constructor(props: ITermsProps) {
    super(props);

    this.state = {
      seenAllTerms: false
    };
  }

  public componentWillUnmount() {
    if (this.scroller) {
      this.scroller.removeEventListener("scroll", this.handleScrolling);
    }
  }

  public render() {
    const allTermsWarning = (
      <p className="zx-orange pt3">
        Please read the entire agreement to continue.
      </p>
    );

    return (
      <>
        <section
          className={`${Classes.DIALOG_BODY} overflow-auto vh-75`}
          style={{ margin: 0, padding: 20 }}
          ref={this.assignScroller}
        >
          <p>
            <span>
              <b>ZENXMED TERMS OF USE AGREEMENT</b>
            </span>
          </p>
          <p>
            <span>
              This ZENXMED TERMS OF USE AGREEMENT (this “<b>Agreement</b>”) is
              made as of the Effective Date (as defined below), by and between
              ZENXMED CORPORATION, a Canadian Corporation (“<b>ZenXMed</b>”),
              and the party agreeing to these terms and conditions or checking
              the “Accept” icon pertaining to this Agreement on the ZenXMed
              application (“<b>you</b>”).This Agreement is effective as of the
              date you first click or check such “Accept” icon (the “
              <b>Effective Date</b>
              ”).ZenXMed and you are sometimes referred to as the “
              <b>Parties</b>” and each individually as a “<b>Party</b>”.
            </span>
          </p>
          <p>
            <span>
              <b>ZENXMED SERVICE AND APPS</b>
            </span>
          </p>
          <p>
            <span>
              ZenXMed has developed an application-based medical information
              software service called “OpenXMed” which is intended to allow
              healthcare professionals to access medical and other information
              (the “<b>Service</b>”).As used herein, the term “Service”
              expressly includes, without limitation: (a) the ZenXMed software
              applications (including, without limitation, all source and object
              code related thereto) (collectively, the “<b>Apps</b>”); (b) the
              www.OpenXMed.com website (the “<b>Website</b>”); (c) all
              documentation related to the Apps and the Website (including,
              without limitation, all operator and user manuals, training
              materials, guides, listings, drawings, reports, descriptions, and
              specifications describing the performance, functionality,
              operation and use of such applications); and (d) all information
              and content (including without limitation photos, videos, audio,
              text, etc.) (the “<b>Content</b>”) provided on and delivered
              through the Apps and/or the Website.
            </span>
          </p>
          <p>
            <span>
              <b>
                Your use of the Service, the Apps and the Website shall be
                subject to and conditioned upon your acceptance of the following
                terms:
              </b>
            </span>
          </p>
          <p>
            <span>
              <b>AGREEMENT</b>
            </span>
          </p>
          <ol>
            <li>
              <span>
                <b>No Professional or Medical Advice</b>
              </span>
              <span>.</span>
            </li>
            <ol>
              <li>
                <span>
                  The Service is intended for use only by licensed medical
                  healthcare professionals, such as physicians.By using the
                  Service, you represent that you are a physician or similar
                  healthcare professional licensed in the United States or
                  Canada (or are a medical student in the United States or
                  Canada).The Service is provided for educational purposes only,
                  and are not intended to be considered or used as a substitute
                  for professional judgment, medical advice, or for diagnosis or
                  treatment.The Service should not be construed as giving advice
                  or making any recommendations regarding medical diagnosis
                  and/or medical treatment and should be checked with
                  appropriate medical references and sources for accuracy,
                  completeness and currency.
                </span>
              </li>
              <li>
                <span>
                  The Service is no substitute for your own medical judgment,
                  which you should exercise in evaluating the information
                  provided by the Service.Any information communicated through
                  the Services is based solely on the assembled, available
                  medical data in the Apps.Medicine is a rapidly changing field
                  and the information provided may not reflect all developments
                  in the published literature, clinical guidelines or
                  research.ZenXMed makes no representations or warranties
                  regarding the accuracy, completeness or currency of such
                  information.
                </span>
              </li>
              <li>
                <span>
                  You are solely responsible for your clinical judgment,
                  including decisions on diagnosis and treatment of a
                  patient.Medical professionals often have different opinions
                  when looking at the same or similar medical
                  information.Accordingly, you should confirm and use your own
                  education, training, experience and judgment when considering
                  for your patients various diagnostic methods, studies,
                  treatments, and drugs, including but not limited to
                  indications, contraindications and/or side effects.There is no
                  substitute for individual patient assessment and sound
                  clinical judgment in considering objective data and other
                  relevant data unique to the patient.You should determine if
                  consultation with a specialist is required, and are solely
                  responsible for your own professional judgments and for the
                  care delivered to your patients.Reliance on any information
                  you obtain from the Service is strictly at your own risk.You
                  should not act or refrain from acting solely on the basis of
                  any information regarding medical diagnosis and/or medical
                  treatment.You assume full responsibility for the use of the
                  information and agree that ZenXMed is not responsible or
                  liable for any claim, loss, or damage arising from the use of
                  the Service.
                </span>
              </li>
            </ol>
            <li>
              <span>
                <b>Your Use of App Content</b>
              </span>
              <span>.</span>
            </li>
            <ol>
              <li>
                <span>Content Policies</span>
                <span>
                  .ZenXMed does not endorse any Content and reserves the right
                  to refuse to post submitted Content.The Apps are a forum for
                  respectful discussion, and no political statements, personal
                  attacks, threatening, harassing, embarrassing, libelous,
                  abusive, profane, or obscene language is allowed.You may not
                  advertise or solicit for business or post copyrighted material
                  unless the copyright owner of the material has expressly
                  granted advance written consent to such submission.You may not
                  post confidential or patient health information, because
                  comments will be seen by the public.ZenXMed reserves the right
                  to implement and modify content policies from time to time.
                </span>
              </li>
              <li>
                <span>Content Submitted by Others</span>
                <span>
                  .Content provided by other users should not be relied upon as
                  medical advice and ZenXMed will not be liable for any loss or
                  damage resulting from reliance on that Content (or any other
                  content).ZenXMed does not undertake to review or evaluate the
                  content posted by other users, nor does ZenXMed verify or
                  review the credentials of any users.The views expressed in
                  comments reflect only the opinions of those authors and not
                  those of ZenXMed.ZenXMed reserves the right to remove any
                  comments, in whole or part, at any time, for any
                  reason.ZenXMed is not responsible for, and does not make any
                  representations or warranties regarding, user-provided
                  content.
                </span>
              </li>
            </ol>
            <li>
              <span>
                <b>License Grant; Use of Software</b>
              </span>
              <span>.</span>
            </li>
            <ol>
              <li>
                <span>License</span>
                <span>
                  .ZenXMed grants you a non-exclusive, non-transferable,
                  non-assignable, non-sublicensable, revocable, limited right
                  and license to access and use the Service until terminated as
                  provided below, solely for information purposes.Subject to the
                  limited rights expressly granted hereunder, ZenXMed reserves
                  all right, title and interest in and to the Service, including
                  any and all related intellectual property rights.No rights are
                  granted to you hereunder other than as expressly set forth in
                  this Agreement.
                </span>
              </li>
              <li>
                <span>Ownership of the Service</span>
                <span>.</span>
              </li>
              <ol>
                <li>
                  <span>
                    The Service (including, without limitation, the software,
                    technology, and documentation incorporated in and underlying
                    the Service) is ZenXMed’s sole and exclusive
                    property.Subject to the limited rights expressly granted in
                    this Agreement, ZenXMed reserves all right, title and
                    interest in and to the Service (including, without
                    limitation, all software, technology, and documentation, as
                    well as all derivative works thereof), including all related
                    intellectual property and proprietary rights (including,
                    without limitation, all copyrights, patent rights, trademark
                    and service mark rights, trade secret rights, and moral
                    rights).No rights are granted to you other than as expressly
                    described in this Agreement.
                  </span>
                </li>
                <li>
                  <span>
                    ZenXMed is also the sole and exclusive owner of: (A) all
                    updates, improvements, enhancements, revisions,
                    modifications, new releases and versions, fixes, patches,
                    and derivative works of the Service; (B) all materials
                    related to the Service including, without limitation, all
                    operator and user manuals, training materials, guides,
                    listings, drawings, reports, descriptions, materials
                    providing feedback, and specifications describing the
                    performance, functionality, operation and use of such
                    applications, and other supporting documentation related to
                    the Service, in whatever form recorded; and (C) all
                    integrations, customizations, components, modules, workflows
                    or other work product produced by ZenXMed (whether alone or
                    jointly with you) for you.Any of the items described in this{" "}
                  </span>
                  <span>Section 3(b)(ii)</span>
                  <span>
                    {" "}
                    that are provided by ZenXMed, in ZenXMed’s sole discretion,
                    to you will be deemed to be included in the definition of
                    the “Service” hereunder and available for use by you under
                    the terms and subject to the conditions in this Agreement.
                  </span>
                </li>
              </ol>
            </ol>
            <li>
              <span>
                <b>Content that You Submit</b>
              </span>
              <span>.</span>
            </li>
            <ol>
              <li>
                <span>Generally</span>
                <span>
                  .The Service, the Apps and the Website allow users, including
                  you, to upload Content.By providing Content, you represent and
                  warrant that you are entitled to and have the requisite rights
                  to submit the information and that the information is
                  accurate, not confidential or proprietary, and not in
                  violation of any contractual restrictions or other third party
                  rights, such as third party trademark, copyright, privacy or
                  other rights.You represent that you own the Content that you
                  submit.ZenXMed will have no obligations with respect to the
                  information you submit.You agree to indemnify, defend and hold
                  us harmless from and against any and all claims that Content
                  that you submit infringes upon the intellectual property
                  rights of a third party.You agree that ZenXMed shall not be
                  required to return any information that you have provided to
                  ZenXMed.You agree not to submit any Content to the CMS that
                  you do not own.
                </span>
              </li>
              <li>
                <span>
                  Your Submissions to the Community (including “comments”)
                </span>
                <span>
                  .By providing Content through the Apps or the Website (aside
                  from Content that you provide via our Content Management
                  System or CMS, which is discussed below), you automatically
                  grant ZenXMed a royalty-free, perpetual, irrevocable,
                  world-wide nonexclusive license to use, reproduce, create
                  derivative works from, modify, publish, edit, translate,
                  distribute, perform and display the Content in any media or
                  medium, or any form, format or forum now known or hereafter
                  developed. You also waive any “moral” rights which you may
                  have in the Content under any copyright law or other similar
                  law.
                </span>
              </li>
              <li>
                <span>Your Submissions to the Content Management System</span>
                <span>
                  .Some users of the Service are invited to submit Content to
                  our Content Management System (CMS) via the Website.If you
                  submit content to the CMS, you automatically and irrevocably
                  assign ownership of that Content to us.You also waive any
                  “moral” rights which you may have in that Content under any
                  copyright law or other similar law.
                </span>
              </li>
              <li>
                <span>Your Feedback</span>
                <span>
                  .If you have any information, ideas, concepts or inventions
                  that you wish to keep private or consider to be proprietary,
                  do not submit them to ZenXMed.Any materials and Intellectual
                  Property embodied therein: (A) made, conceived, developed, or
                  reduced to practice, or caused to be made, conceived,
                  developed, or reduced to practice, by you, alone or in
                  conjunction with others, after the date you first use the
                  Apps, the Website or the Service and ending when you provide
                  notice of termination of this Agreement;{" "}
                </span>
                <span>and</span>
                <span>
                  {" "}
                  (B) disclosed to ZenXMed (whether orally, in writing,
                  electronically or otherwise, such as providing an addition or
                  change to the Service, or providing feedback about the
                  Service) (“
                  <b>Service-Related IP</b>”) will be deemed to have been made
                  or developed by you solely for ZenXMed’s benefit, will be held
                  in trust for ZenXMed’s exclusive use and benefit, and will be
                  ZenXMed’s sole and exclusive property.You will not, either
                  during the term of this Agreement or at any time thereafter,
                  use or disclose Service-Related IP to any party.You agree to
                  assign, and do hereby assign, to ZenXMed all right, title, and
                  interest in and to any Service-Related IP, and waive any
                  “moral” rights which you may have in the Service-Related IP
                  under any copyright law or other similar law.You also agree,
                  during the term of this Agreement and at any time thereafter,
                  at ZenXMed’s request and expense but without further
                  consideration, to review, execute, acknowledge, and deliver
                  any and all papers necessary to secure and/or perfect
                  ZenXMed’s rights and ownership in the Service-Related IP in
                  any country in the world.Without limiting the generality of
                  the foregoing, ZenXMed may, in its sole discretion,
                  incorporate the Service-Related IP and the Content into the
                  Service.As used herein, “<b>Intellectual Property</b>” means
                  any and all discoveries, improvements, ideas, concepts,
                  creative works, processes, methods, formulas, techniques,
                  know-how, designs, works of authorship, trade secrets,
                  copyrights, patent rights, trademarks, service marks, and any
                  other intellectual property that relates to, or is used to
                  develop or improve, the Service.
                </span>
              </li>
              <li>
                <span>Patient Confidentiality Laws</span>
                <span>
                  .You agree not to transmit, maintain or store any protected
                  health information (as defined by the U.S. Health Insurance
                  Portability and Accountability Act of 1996, as amended (HIPAA)
                  or the regulations thereunder), or any other information
                  protected by U.S. or Canadian laws, via the Service.Any
                  Content that you provide to ZenXMed is provided on a
                  non-confidential basis, and ZenXMed has no obligation to keep
                  the information confidential.
                </span>
              </li>
            </ol>
            <li>
              <span>
                <b>User Account and Password</b>
              </span>
              <span>.</span>
            </li>
            <ol>
              <li>
                <span>
                  You are solely responsible for: (A) all activities occurring
                  under your account; (B) maintaining the confidentiality of
                  your account and password; (C) the content of all electronic
                  and oral data submitted by you to and through the Service; and
                  (D) setting and modifying your profile and preferences for the
                  Service.
                </span>
              </li>
              <li>
                <span>
                  You shall: (A) prevent unauthorized access to, or use of, the
                  Service, and will notify ZenXMed promptly of any unauthorized
                  use that you become aware of; and (B) comply with all
                  applicable local, state, provincial, federal and foreign laws,
                  treaties and regulations in using the including, without
                  limitation, laws and regulations governing data privacy,
                  international communications, and transmission of technical or
                  personal data).
                </span>
              </li>
              <li>
                <span>
                  You shall use the Service solely for the purposes permitted
                  pursuant to{" "}
                </span>
                <span>Section 1(a)</span>
                <span>
                  {" "}
                  above.Except as expressly set forth in this Agreement, you
                  shall not: (A) make the Service available to any third party;
                  (B) share your account password and login credentials with any
                  third party; (C) use, or attempt to use, any content obtained
                  through the Service for any commercial purpose, or share such
                  content with any third party; (D) use the Service to provide
                  or aid in the evaluation, diagnosis, or treatment of medical
                  conditions, nor use the Service to provide any medical
                  services or information to third parties; (E) send spam or
                  similar unsolicited messages; (F) send or store infringing,
                  obscene, threatening, libelous, defamatory, or otherwise
                  unlawful material; (G) send or store viruses or other harmful
                  or malicious code, files, scripts, agents or programs; (H)
                  interfere with or disrupt the integrity or performance of the
                  Service or ZenXMed’s sites, servers, or networks; (I) attempt
                  to gain unauthorized access to ZenXMed’s systems, networks,
                  infrastructure, or the Service; or (J) print, download, or
                  otherwise extract information or materials from the Service.
                </span>
              </li>
              <li>
                <span>
                  You agree not to (and will not authorize any third party to):
                  (A) decompile, disassemble, translate, reverse engineer or
                  otherwise attempt to identify, reconstruct, derive or discover
                  the source code (or the underlying ideas, user interface
                  techniques, algorithms, structure or organization) of the
                  Service; (B) remove or alter any identification, copyright,
                  trademark, patent, or other proprietary notices, legends,
                  symbols, or labels appearing in the Service; (C) directly or
                  indirectly circumvent or violate the technical restrictions of
                  the Service; (D) publicly disseminate performance information
                  about or analysis of the Service; (E) access the Service in
                  order to (1) build a competitive product or service, or (2)
                  copy any ideas, features, functions or graphics of the
                  Service; (F) use the Service for any purpose other than as
                  expressly authorized herein; (G) take any action that would
                  cause any part of the Service to be placed in the public
                  domain; or (H) challenge the validity of the copyrights,
                  trademarks, patent rights, or any other rights of ZenXMed to
                  the Service or title or interest thereto.
                </span>
              </li>
            </ol>
            <li>
              <span>
                <b>Privacy Policy</b>
              </span>
              <span>
                .ZenXMed collects information about you and your use of the
                Service both directly from you, which you provide, and
                automatically resulting from your use of the Service.ZenXMed
                will use the information collected to the fullest extent
                allowable by applicable law, including for the purpose of
                modifying and improving the Service.ZenXMed’s{" "}
              </span>
              <span>privacy policy</span>
              <span>
                <sup>1</sup>
              </span>
              <span>
                {" "}
                is available on its website and is subject to change from time
                to time.
              </span>
            </li>
            <li>
              <span>
                <b>Modification of the Service</b>
              </span>
              <span>
                .ZenXMed shall be entitled to modify, change, remove, limit,
                delete, and restrict the functionality, features, and
                capabilities of the Service, and the underlying technical
                infrastructure, in its sole and absolute discretion and without
                any notice (including any prior notice) being provided to you,
                to reflect the continuing development of the Service and
                technical advances.ZenXMed does not currently charge fees for
                the Service or the App, but reserves the right to charge fees in
                the future upon notice to you.
              </span>
            </li>
            <li>
              <span>
                <b>Term and Termination</b>
              </span>
              <span>
                .Either you or ZenXMed may terminate this Agreement at any time
                without cause upon written notice to the other.When this
                Agreement is terminated: (A) you will immediately cease use of
                the Service, delete the Service and any related materials in
                your possession, and refrain from accessing the Service or any
                information therefrom; (B) your license to the Service will be
                immediately terminated; (C) ZenXMed will immediately cease
                making the Service available to you; and (D) ZenXMed shall
                continue to be entitled to all rights granted to it under
                Sections 3 and 4 above.You acknowledge and agree that ZenXMed
                will have no liability to you whatsoever with respect to the
                termination of your use of the Service (even if ZenXMed has been
                advised of the possibility of damages).You will also pay any
                damages, losses, liabilities, costs and expenses (including,
                without limitation, reasonable attorneys’ fees and court costs)
                that arise out of or in connection with any breach of this
                Agreement occurring prior to or after the termination hereof.
              </span>
            </li>
            <li>
              <span>
                <b>Disclaimers</b>
              </span>
              <span>
                .THE SERVICE IS PROVIDED ON AN “AS IS” BASIS.ZENXMED MAKES NO
                WARRANTY, REPRESENTATION, GUARANTY OR CONDITION OF ANY KIND,
                WHETHER EXPRESS, IMPLIED, STATUTORY OR OTHERWISE (INCLUDING,
                WITHOUT LIMITATION, WARRANTIES OF RELIABILITY, TIMELINESS,
                QUALITY, SUITABILITY, AVAILABILITY, SECURITY, ACCURACY,
                COMPLETENESS, TITLE OR NON-INFRINGEMENT, OR IMPLIED WARRANTIES
                OF MERCHANTABILITY OR FITNESS FOR A PARTICULAR PURPOSE), WITH
                RESPECT TO THE SERVICE OR ANY OF THE CONTENT, SERVICES, OR
                PRODUCTS PROVIDED IN CONNECTION THEREWITH.
              </span>
            </li>
          </ol>
          <p>
            <span>
              ZENXMED DOES NOT REPRESENT, WARRANT OR GUARANTY THAT: (a) THE
              SERVICE WILL BE 100% SECURE OR ERROR-FREE OR OPERATE IN
              COMBINATION WITH ANY OTHER APPLICATION, SOFTWARE, HARDWARE,
              SERVICE OR DATA; (b) THE SERVICE WILL MEET YOUR NEEDS OR
              EXPECTATIONS; (c) ANY DATA STORED USING THE SERVICE WILL BE
              ACCURATE, RELIABLE, OR SECURE; (d) ERRORS OR DEFECTS IN THE
              SERVICE WILL BE CORRECTED; (e) THE SERVICE OR THE THIRD PARTY
              PRODUCTS OR SERVICES USED BY ZENXMED IN CONNECTION WITH THE
              SERVICE ARE FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS; OR (f)
              THE HEALTHCARE PROVIDERS WITH WHICH YOU MAY INTERACT THROUGH THE
              SERVICE WILL PROVIDE ACCURATE AND COMPLETE MEDICAL INFORMATION.
            </span>
          </p>
          <p>
            <span>
              ZENXMED DOES NOT MAKE ANY REPRESENTATIONS, WARRANTIES OR
              GUARANTIES OF ANY KIND, AND WILL HAVE NO RESPONSIBILITY
              WHATSOEVER, WITH RESPECT TO ANY THIRD PARTY PRODUCTS, SERVICES,
              CONTENT OR OTHER MATERIALS OFFERED, ACCESSED, ENCOUNTERED OR
              OBTAINED BY YOU THROUGH THE USE OF THE SERVICE.
            </span>
          </p>
          <ol>
            <li>
              <span>
                <b>Limitation of Liability</b>
              </span>
              <span>.</span>
            </li>
          </ol>
          <ol>
            <ol>
              <li>
                <span>
                  TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, ZENXMED
                  WILL NOT BE LIABLE TO YOU OR ANY THIRD PARTY FOR ANY DIRECT,
                  INDIRECT, SPECIAL, INCIDENTAL, CONSEQUENTIAL, PUNITIVE,
                  EXEMPLARY OR MULTIPLE DAMAGES IN CONNECTION WITH OR ARISING
                  OUT OF: (i) THE SERVICE; (ii) ANY EVALUATION, DIAGNOSIS,
                  TREATMENT OR OTHER MEDICAL CARE OR ADVICE BY YOU TO ANY THIRD
                  PARTIES; (iii) ANY THIRD PARTY PRODUCTS, SERVICES, CONTENT OR
                  OTHER MATERIALS OFFERED, ACCESSED, ENCOUNTERED OR OBTAINED ON,
                  WITH, OR THROUGH THE USE OF THE SERVICE; OR (iv) THIS
                  AGREEMENT (INCLUDING, WITHOUT LIMITATION, FOR ZENXMED’S
                  PERFORMANCE OR THE FAILURE OF SUCH PERFORMANCE HEREUNDER, OR
                  FOR ANY BREACH BY ZENXMED HEREOF), REGARDLESS OF THE LEGAL
                  THEORY ON WHICH SUCH CLAIM IS BASED (WHETHER CONTRACT, TORT OR
                  OTHERWISE) AND EVEN IF ZENXMED IS ADVISED OF THE POSSIBILITY
                  OF SUCH DAMAGES (INCLUDING, BUT NOT LIMITED TO, DAMAGES FOR
                  INJURY TO PERSON OR PROPERTY, LOSS OF REVENUE OR PROFITS,
                  BUSINESS INTERRUPTION, LOSS OF GOODWILL, USE OR LOSS OF DATA,
                  COST OF PROCUREMENT OF SUBSTITUTE GOODS OR SERVICES, SYSTEM
                  DOWNTIME, AND THE CLAIMS OF THIRD PARTIES). IF ANY
                  JURISDICTION DOES NOT ALLOW EXCLUSION OF IMPLIED WARRANTIES OR
                  LIMITATION OF LIABILITY FOR DIRECT, INDIRECT, SPECIAL,
                  INCIDENTAL, CONSEQUENTIAL, PUNITIVE, EXEMPLARY OR MULTIPLE
                  DAMAGES, ZENXMED’S LIABILITY WILL BE LIMITED TO THE GREATEST
                  EXTENT PERMITTED BY LAW AND IN NO EVENT WILL SUCH LIABILITY
                  EXCEED ONE HUNDRED DOLLARS ($100.00).
                </span>
              </li>
              <li>
                <span>
                  YOU EXPRESSLY AGREE THAT YOUR SOLE AND EXCLUSIVE REMEDY UNDER
                  THIS AGREEMENT FOR ZENXMED’S PERFORMANCE OR THE FAILURE OF
                  SUCH PERFORMANCE HEREUNDER, OR FOR ANY BREACH BY ZENXMED
                  HEREOF, WILL BE TO TERMINATE THIS AGREEMENT.YOU ACCEPT THE
                  RESTRICTIONS ON YOUR RIGHT TO RECOVER ADDITIONAL DAMAGES AS
                  PART OF YOUR BARGAIN WITH ZENXMED, AND YOU UNDERSTAND AND
                  ACKNOWLEDGE THAT, WITHOUT SUCH RESTRICTIONS, GREATER
                  CONSIDERATION WOULD BE REQUIRED OF YOU.
                </span>
              </li>
            </ol>
            <li>
              <span>
                <b>Indemnification</b>
              </span>
              <span>
                .You will defend, indemnify and hold harmless ZenXMed and
                ZenXMed’s affiliates, officers, directors, members, managers,
                equity holders, employees, insurers, legal counsel,
                representatives, and agents (each an “<b>Indemnitee</b>”) from
                and against any and all claims (including, without limitation,
                any investigation, action or other proceeding, whether
                instituted by a third party against an Indemnitee or by an
                Indemnitee for the purpose of enforcing its rights hereunder),
                damages, losses, liabilities, costs and expenses (including,
                without limitation, attorneys’ fees and court costs) that
                constitute, or arise out of or in connection with (i) any breach
                by you of your representations, warranties, agreements and
                covenants set forth in this Agreement; or (ii) your use or
                misuse of the Service, including, but not limited to, using the
                Service for providing medical information, evaluation, or
                treatment to you or a third party.
              </span>
            </li>
            <li>
              <span>
                <b>Miscellaneous</b>
              </span>
              <span>.</span>
            </li>
            <ol>
              <li>
                <span>Entire Agreement; Amendment</span>
                <span>
                  .This Agreement (including, without limitation, all Exhibits
                  hereto) constitutes the entire agreement of the Parties with
                  respect to the transactions contemplated hereby and supersedes
                  all prior and contemporaneous written and oral agreements,
                  representations and communications between the Parties
                  relating to such transactions.This Agreement may be amended,
                  supplemented, or otherwise modified prospectively by ZenXMed
                  at any time, provided that ZenXMed shall provide prior written
                  notice of the amendment to you via the App or in accordance
                  with{" "}
                </span>
                <span>Section 12(e)</span>
                <span>
                  {" "}
                  below, and any such amendment shall be effective only to the
                  extent specifically set forth in such writing.
                </span>
              </li>
              <li>
                <span>Equitable Relief</span>
                <span>
                  .User acknowledges and agrees that ZenXMed would be
                  irreparably damaged in the event that any of the provisions of{" "}
                </span>
                <span>Sections 1, 2, 3, 4 and 5</span>
                <span>
                  {" "}
                  are not performed by User in accordance with their specific
                  terms or are otherwise breached, and that money damages would
                  not be a sufficient remedy for such breach.Accordingly, User
                  agrees that ZenXMed will be entitled to equitable relief,
                  including injunction and specific performance, as a remedy for
                  any such breach, without any requirement to post bond or other
                  security or to prove actual damage or harm.Such remedies shall
                  not be deemed to be the exclusive remedies for any such breach
                  but shall be in addition to all other remedies available at
                  law or in equity.
                </span>
              </li>
              <li>
                <span>Electronic Signatures Effective</span>
                <span>
                  .This Agreement is an electronic contract that sets out the
                  legally binding terms of your purchase of a subscription for
                  the Service.You indicate your acceptance of this Agreement by
                  clicking or checking the “Accept” icon in connection with its
                  purchase of a subscription for the Service from ZenXMed.This
                  action creates an electronic signature that has the same legal
                  force and effect as a handwritten signature.By clicking or
                  checking the “Accept” icon, you agree to the terms and
                  conditions contained or referenced in this Agreement.When you
                  click or check the “Accept” icon, you also consent to have
                  this Agreement provided to it in electronic form.You are
                  encouraged to print a copy of this Agreement for you records.
                </span>
              </li>
              <li>
                <span>Governing Law; Consent to Jurisdiction</span>
                <span>
                  .This Agreement is governed by, and shall be construed and
                  enforced in accordance with, the laws of the Province of
                  Alberta and the federal laws of Canada applicable therein,
                  without giving effect to any conflict of laws rules, and each
                  Party irrevocably submits to the exclusive jurisdiction of the
                  Court of Queen&apos;s Bench of Alberta for the purposes of any
                  action or proceeding arising out of or relating to this
                  Agreement.Each Party hereby consents to such jurisdiction and
                  agrees that venue shall lie in the Court of Queen&apos;s Bench
                  of Alberta with respect to any claim or cause of action
                  arising under or relating to this Agreement.Each Party hereby
                  waives any objection based on <i>forum non conveniens</i> and
                  waives any objection to venue of any action instituted under
                  this Agreement.If any legal action or any arbitration or other
                  proceeding is brought in connection with this Agreement, the
                  prevailing Party shall be entitled to recover reasonable
                  attorneys’ fees, accounting fees, and other costs incurred in
                  that action or proceeding, in addition to any other relief to
                  which it may be entitled.EACH PARTY ACKNOWLEDGES AND AGREES
                  THAT ANY CONTROVERSY WHICH MAY ARISE UNDER THIS AGREEMENT IS
                  LIKELY TO INVOLVE COMPLICATED AND DIFFICULT ISSUES AND,
                  THEREFORE, EACH PARTY HEREBY IRREVOCABLY AND UNCONDITIONALLY
                  WAIVES ANY RIGHT SUCH PARTY MAY HAVE TO A TRIAL BY JURY IN
                  RESPECT OF ANY LITIGATION DIRECTLY OR INDIRECTLY ARISING OUT
                  OF OR RELATING TO THIS AGREEMENT OR THE TRANSACTIONS
                  CONTEMPLATED HEREBY.
                </span>
              </li>
              <li>
                <span>Notices</span>
                <span>
                  .Any notice, demand or request required or permitted under
                  this Agreement shall be in writing and deemed delivered (i)
                  when delivered personally (including by recognized national
                  courier), (ii) five (5) Business Days after deposited in the
                  U.S. mail, first class mail, registered or certified, with
                  postage prepaid; or (iii) one (1) Business Day after sent via
                  email.Notices to (A) ZenXMed shall be addressed to ZenXMed
                  Corporation, 6-4700 Fountain Dr., Red Deer, Alberta, Canada,
                  T4N 6W4, Attn: President, Email: legal@zenxmed.com, and (B)
                  you shall be addressed to the mailing address or email address
                  on file with ZenXMed.
                </span>
              </li>
              <li>
                <span>Severability</span>
                <span>
                  .Any provision of this Agreement which is prohibited or
                  unenforceable in any jurisdiction shall, as to such
                  jurisdiction, be ineffective to the extent of such prohibition
                  or unenforceability without invalidating the remaining
                  portions hereof or affecting the validity or enforceability of
                  such provision in any other jurisdiction.
                </span>
              </li>
              <li>
                <span>Successors and Assigns</span>
                <span>
                  .You may not assign your rights or delegate or cause to be
                  assumed your obligations hereunder without ZenXMed’s prior
                  written consent (which consent may be withheld in ZenXMed’s
                  sole and absolute consent).Any attempted assignment,
                  delegation or assumption not in accordance with this{" "}
                </span>
                <span>Section 8(g)</span>
                <span>
                  {" "}
                  shall be null and void and of no force or effect
                  whatsoever.The terms and conditions of this Agreement shall
                  inure to the benefit of and be binding upon the respective
                  successors and permitted assigns of the Parties.
                </span>
              </li>
              <li>
                <span>Survival</span>
                <span>.The provisions of </span>
                <span>Sections 1-5</span>
                <span> and </span>
                <span>7-12</span>
                <span>
                  {" "}
                  will survive any termination or expiration of this Agreement.
                </span>
              </li>
              <ol>
                <li>
                  <span>Waivers</span>
                  <span>
                    .The due performance or observance by the Parties of their
                    respective obligations under this Agreement shall not be
                    waived, and the rights and remedies of the Parties shall not
                    be affected, by any course of dealing or performance or by
                    any delay or failure of any Party in exercising any such
                    right or remedy.The due performance or observance by a Party
                    of any of its obligations under this Agreement may be waived
                    only by a writing signed by the Party against whom
                    enforcement of such waiver is sought.
                  </span>
                </li>
              </ol>
            </ol>
          </ol>
        </section>
        <div className={Classes.DIALOG_FOOTER}>
          <div className={Classes.DIALOG_FOOTER_ACTIONS}>
            {!this.state.seenAllTerms && allTermsWarning}
            <Button
              className="mv2"
              text="Refuse"
              intent={Intent.NONE}
              onClick={this.refusedTerms}
            />
            <Button
              className="ma2"
              text="Accept"
              intent={Intent.PRIMARY}
              disabled={!this.state.seenAllTerms}
              onClick={this.acceptedTerms}
            />
          </div>
        </div>
      </>
    );
  }

  private handleScrolling = () => {
    const mc = this.scroller;
    if (mc) {
      if (mc.scrollHeight < mc.offsetHeight + mc.scrollTop + 20) {
        if (!this.state.seenAllTerms) {
          this.setState({ seenAllTerms: true });
        }
      }
    }
  };

  private assignScroller = (e: HTMLDivElement) => {
    this.scroller = e;
    if (e) {
      e.addEventListener("scroll", this.handleScrolling);
    }
  };

  private refusedTerms = () => {
    this.props.refusedTerms();
  };

  private acceptedTerms = () => {
    this.props.acceptedTerms();
  };
}
