import { Button, H4, H6 } from "@blueprintjs/core";
import * as React from "react";

export interface IErrorRetryProps {
  error: Error;
  retryHandler: () => void;
}

export class ErrorRetry extends React.PureComponent<IErrorRetryProps> {
  public render() {
    const { error, retryHandler } = this.props;

    return (
      <section className="w-100 h-100 mt3 pa2">
        <H4>Error</H4>
        <H6>{error.message}</H6>
        <p>Would you like to retry the action?</p>
        <Button title="Retry" text="Retry" onClick={retryHandler} />
      </section>
    );
  }
}
