import { Button } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import { push } from "connected-react-router";
import { Dictionary } from "lodash";
import moment from "moment";
import * as React from "react";
import { connect } from "react-redux";
import { RouteComponentProps } from "react-router";

import { fullName, Study, User, studyStatusString, userIsAdmin } from "src/api";
import { ConnectedPageTitle } from "src/components";
import { kStudiesUrlKey } from "src/config/routes";
import { IStoreState } from "src/store";
import { sortAlphabetically } from "src/utilities";
import { CreateStudyDialog } from "./studies/create-study-dialog";

type IStudyAdminProps = RouteComponentProps;

interface IStudyAdminInjectedProps {
  error?: Error;
  loggedInUser?: User;
  studies: Dictionary<Study>;
}

interface IStudyaAdminDispatchProps {
  push: typeof push;
}

type StudyAdminComponentProps = IStudyAdminProps &
  IStudyAdminInjectedProps &
  IStudyaAdminDispatchProps;

interface IStudyAdminComponentState {
  busy: boolean;
  newStudyDialogOpen: boolean;
}

class StudyAdminComponent extends React.PureComponent<
  StudyAdminComponentProps,
  IStudyAdminComponentState
> {
  constructor(props: StudyAdminComponentProps) {
    super(props);
    this.state = {
      busy: false,
      newStudyDialogOpen: false
    };
  }

  public render() {
    const handleClose = (createdStudyId?: string) => {
      this.setState({ newStudyDialogOpen: false });
      if (createdStudyId) {
        this.props.push(`${kStudiesUrlKey}/${createdStudyId}`);
      }
    };

    return (
      <section className="pa2 w-100 h-100">
        <ConnectedPageTitle />
        <CreateStudyDialog
          isOpen={this.state.newStudyDialogOpen}
          onClose={handleClose}
        />
        {this.renderStudies()}
      </section>
    );
  }

  private renderStudies = () => {
    const { studies } = this.props;

    const rowRenderer = (sdys: Dictionary<Study>) =>
      Object.values(sdys)
        .sort((a, b) => sortAlphabetically(a.title, b.title))
        .map(this.renderStudyRow);

    const openCreateDialog = () => this.setState({ newStudyDialogOpen: true });

    return (
      <section className="bg-white mt2 pa2 br3">
        <section className="w-100 flex b bb b--light-gray pv2 pb1 mb1">
          <div className="fl w-30 pl1 pv1">Name</div>
          <div className="fl w-20 pl1 pv1">Author</div>
          <div className="fl w-20 pl1 pv1">Status</div>
          <div className="fl w-30 flex flex-row justify-between">
            <p className="pl1 pv1">Modified</p>
            <Button
              icon={IconNames.ADD}
              minimal={true}
              onClick={openCreateDialog}
              small={true}
              text="Add Study"
            />
          </div>
        </section>
        {rowRenderer(studies)}
      </section>
    );
  };

  private renderStudyRow = (study: Study, index: number) => {
    const { loggedInUser } = this.props;

    const openEditStudy = () =>
      this.props.push(`${kStudiesUrlKey}/${study.id}`);
    const canEdit =
      userIsAdmin(loggedInUser) || study.editableByUser(loggedInUser);

    return (
      <section
        className={`flex ${canEdit ? "pointer" : ""} b bb b--light-gray`}
        id={study.id}
        key={index}
        onClick={canEdit ? openEditStudy : undefined}
      >
        <div className="fl w-30 pa1">
          <p>{study.title}</p>
        </div>
        <div className="fl w-20 pa1">
          <p>{fullName(study.creator)}</p>
        </div>
        <div className="fl w-20 pa1">
          <p>{studyStatusString(study.status)}</p>
        </div>
        <div className="fl w-30 pa1">
          <p>{moment(study.updatedAt).format("MMM Do YYYY")}</p>
        </div>
      </section>
    );
  };
}

const mapStateToProps = ({ studiesStore, userStore }: IStoreState) => {
  return {
    error: studiesStore.error,
    loggedInUser: userStore.loggedInUser,
    studies: studiesStore.studies
  };
};

export const StudyAdmin = connect(mapStateToProps, { push })(
  StudyAdminComponent
);
