import { LOCATION_CHANGE, RouterState } from "connected-react-router";
import { REHYDRATE } from "redux-persist";
import { ActionType, createAction } from "typesafe-actions";

import * as actions from ".";

export type ReduxAction = ActionType<typeof actions>;

export const rehydrate = createAction(REHYDRATE)();
export const locationChange = createAction(LOCATION_CHANGE)<RouterState>();

export interface IPageParameters {
  skip: number;
  take: number;
}

export const kTopicIdBreadcrumbLocation = 1;
export const kAlgorithmIdBreadcrumbLocation = 2;
export const kNodeIdBreadcrumbLocation = 3;

export * from "./user-actions";
export * from "./algorithm-actions";
export * from "./interface-actions";
export * from "./event-actions";
export * from "./study-actions";
