import { Button, H5, H6 } from "@blueprintjs/core";
import DOMPurify from "dompurify";
import marked from "marked";
import * as React from "react";

import { IconNames } from "@blueprintjs/icons";
import { AlgoNode } from "src/api";
import { IOpenAlgorithm } from "src/store";
import { nodeTitleTextPlain, sortAlphabetically } from "src/utilities";

export interface IAlgoRefsProps {
  algoDetails: IOpenAlgorithm;
}

export class AlgoRefs extends React.PureComponent<IAlgoRefsProps> {
  public render() {
    const { algorithm } = this.props.algoDetails;

    const nodesWithRefs = algorithm.nodes
      .filter(n => n.references.length > 0)
      .filter(n => n.references.filter(r => r.length > 0).length > 0)
      .sort((a, b) => sortAlphabetically(a.id, b.id));

    return (
      <section className="flex flex-column flex-auto">
        <H5>Algorithm References</H5>
        {this.renderRefs(algorithm.references)}
        {this.renderNodeRefs(nodesWithRefs)}
      </section>
    );
  }

  private renderNodeRefs = (nodes: AlgoNode[]) => {
    if (nodes.length === 0) {
      return null;
    }
    return (
      <section>
        <H5>Node References</H5>
        {nodes.map(this.renderNodeRef)}
      </section>
    );
  };

  private renderNodeRef = (node: AlgoNode, index: number) => {
    const goToNode = () => {
      // TODO: make this work ?
    };

    const references = node.references.map((r, j) => {
      const html = DOMPurify.sanitize(marked(r));
      return <li key={j} dangerouslySetInnerHTML={{ __html: html }} />;
    });

    return (
      <section key={index}>
        <div className="flex flex-row w-100 justify-between">
          <H5>{nodeTitleTextPlain(node)}</H5>
          <Button
            style={{ visibility: "hidden" }}
            icon={IconNames.EYE_OPEN}
            minimal={true}
            onClick={goToNode}
          />
        </div>
        <ul>{references}</ul>
      </section>
    );
  };

  private renderRefs = (refs: string[]) => {
    if (refs.length === 0) {
      return <H6>Algorithm is missing references!</H6>;
    }
    return refs.map((r, index) => (
      <p
        key={index}
        dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(marked(r)) }}
      />
    ));
  };
}
