import { Button, Label, MenuItem } from "@blueprintjs/core";
import { ItemRenderer, Select } from "@blueprintjs/select";
import * as React from "react";

import {
  AlgoNodeInfoType,
  INodeInfoTypeLookup,
  nodeInfoTypes,
  textForNodeInfoType
} from "src/api";

export interface INodeInfoTypeSelectProps {
  disabled?: boolean;
  onChange: (value: AlgoNodeInfoType) => void;
  value: AlgoNodeInfoType;
}

const NodeInfoTypeSelectComponent = Select.ofType<INodeInfoTypeLookup>();

export class NodeInfoTypeSelect extends React.PureComponent<
  INodeInfoTypeSelectProps
> {
  public render() {
    const { disabled, value } = this.props;
    const items = nodeInfoTypes().filter(i => i.type !== value);

    return (
      <Label className="w-100">
        <p className="ttu zx-edit-grey">Info area</p>
        <NodeInfoTypeSelectComponent
          filterable={false}
          items={items}
          itemRenderer={this.itemRenderer}
          onItemSelect={this.onSelect}
          popoverProps={{ minimal: true }}
        >
          <Button
            alignText="left"
            disabled={disabled}
            fill={true}
            rightIcon="caret-down"
            text={textForNodeInfoType(value)}
          />
        </NodeInfoTypeSelectComponent>
      </Label>
    );
  }

  private onSelect = (
    item: INodeInfoTypeLookup,
    event?: React.SyntheticEvent<HTMLElement>
  ) => {
    this.props.onChange(item.type);
  };

  private itemRenderer: ItemRenderer<INodeInfoTypeLookup> = (
    { label, type },
    { handleClick }
  ) => <MenuItem key={type} tagName="ul" text={label} onClick={handleClick} />;
}
