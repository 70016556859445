import { Classes, Collapse, Icon } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import classNames from "classnames";
import * as React from "react";

export interface ICollapsiblePanelProps {
  className?: string;
  expandInitially?: boolean;
  isExpanded?: boolean;
  onClick?: (isExpanded: boolean) => void;
  title: string;
  titleNode?: JSX.Element;
}

interface ICollapsiblePanelState {
  expanded: boolean;
}

export class CollapsiblePanel extends React.PureComponent<
  ICollapsiblePanelProps,
  ICollapsiblePanelState
> {
  constructor(props: ICollapsiblePanelProps) {
    super(props);
    this.state = {
      expanded: props.isExpanded || props.expandInitially || false
    };
  }

  public componentDidUpdate() {
    const { isExpanded } = this.props;
    const { expanded } = this.state;

    if (isExpanded !== undefined && isExpanded !== expanded) {
      this.setState({ expanded: isExpanded });
    }
  }

  public render() {
    const { children, className, onClick, title, titleNode } = this.props;
    const { expanded } = this.state;

    const caretClasses = classNames(
      Classes.TREE_NODE_CARET,
      expanded ? Classes.TREE_NODE_CARET_OPEN : Classes.TREE_NODE_CARET_CLOSED
    );

    const clickHandler = () => {
      if (onClick) {
        onClick(expanded);
      } else {
        this.setState({ expanded: !expanded });
      }
    };
    const titleRenderer = titleNode ? (
      titleNode
    ) : (
      <span className="ttu zx-edit-grey">{title}</span>
    );

    return (
      <div className={`flex flex-column zx-bt-blue ${className}`}>
        <div className="flex flex-row items-center">
          <Icon
            className={caretClasses}
            onClick={clickHandler}
            icon={IconNames.CHEVRON_RIGHT}
          />
          {titleRenderer}
        </div>
        <Collapse isOpen={expanded}>{children}</Collapse>
      </div>
    );
  }
}
