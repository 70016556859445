import Konva from "konva";
import { Vector2d } from "konva/types/types";

import { IAlgoNodeRenderProps, kEditGrey } from ".";
import { IRenderedNode } from "./utilities";

export const createStartNode = (
  props: IAlgoNodeRenderProps
): IRenderedNode | undefined => {
  const { algoNodes, interactionDisabled, nodeId } = props;
  const node = algoNodes()[nodeId];
  if (!node) {
    return;
  }
  const { x, y } = node;

  const containerGroup: Konva.Group = new Konva.Group({
    dragBoundFunc: (e: Vector2d) => {
      if (interactionDisabled()) {
        return containerGroup.getAbsolutePosition();
      }
      return e;
    },
    draggable: false,
    id: `${nodeId}`,
    x,
    y
  });

  const backgroundCircle = new Konva.Circle({
    draggable: false,
    fill: kEditGrey,
    id: `${nodeId}=out`,
    radius: 30
  });
  containerGroup.add(backgroundCircle);

  // Arrow shape
  const arrowHead = new Konva.Path({
    data:
      "M15.71,9.29l-4-4C11.53,5.11,11.28,5,11,5c-0.55,0-1,0.45-1,1c0,0.28,0.11,0.53,0.29,0.71L12.59,9H5c-0.55,0-1,0.45-1,1c0,0.55,0.45,1,1,1h7.59l-2.29,2.29C10.11,13.47,10,13.72,10,14c0,0.55,0.45,1,1,1c0.28,0,0.53-0.11,0.71-0.29l4-4C15.89,10.53,16,10.28,16,10C16,9.72,15.89,9.47,15.71,9.29z M10,0    C4.48,0,0,4.48,0,10c0,5.52,4.48,10,10,10s10-4.48,10-10C20,4.48,15.52,0,10,0z M10,18c-4.42,0-8-3.58-8-8s3.58-8,8-8s8,3.58,8,8S14.42,18,10,18z",
    fill: "#38617f",
    listening: false,
    scaleX: 3,
    scaleY: 3,
    stroke: "#38617f",
    strokeWidth: 1,
    x: -30,
    y: -30
  });

  containerGroup.add(arrowHead);
  return {
    anchorIn: containerGroup,
    anchorOut: containerGroup,
    group: containerGroup,
    height: 40,
    links: {},
    subOuts: {}
  };
};
