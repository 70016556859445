import { createAction, createAsyncAction } from "typesafe-actions";

import { IPageParameters } from ".";
import {
  Algorithm,
  IOpenxmedApiSuccessResponse,
  Label,
  User,
  UserRole
} from "../api";

export interface IUserEmail {
  email: string;
}

export interface ILoginCredentials extends IUserEmail {
  password: string;
}

export const login = createAsyncAction(
  "login/REQUEST",
  "login/SUCCESS",
  "login/FAILURE"
)<ILoginCredentials, firebase.auth.UserCredential, Error>();

export const registerForPush = createAsyncAction(
  "registerForPush/REQUEST",
  "registerForPush/SUCCESS",
  "registerForPush/FAILURE"
)<undefined, string, Error>();

export const registerPushToken = createAsyncAction(
  "registerPushToken/REQUEST",
  "registerPushToken/SUCCESS",
  "registerPushToken/FAILURE"
)<
  { deviceId: string; pushToken: string },
  IOpenxmedApiSuccessResponse,
  Error
>();

export const resetPassword = createAsyncAction(
  "resetPassword/REQUEST",
  "resetPassword/SUCCESS",
  "resetPassword/FAILURE"
)<IUserEmail, undefined, Error>();

export const getSelf = createAsyncAction(
  "getSelf/REQUEST",
  "getSelf/SUCCESS",
  "getSelf/FAILURE"
)<undefined, IOpenxmedApiSuccessResponse, Error>();

export const setFirebaseUser = createAction(
  "setFirebaseUser"
)<firebase.User | null>();
export const setAuthToken = createAction("setAuthToken")<string | undefined>();

export const clearUserError = createAction("clearUserError")();
export const clearUsersError = createAction("clearUsersError")();
export const logout = createAction("logout")();
export const resetStore = createAction("resetStore")();

export interface ILocationDetails {
  label: string;
  value: {
    country: string | null;
    region: string | null;
    city: string | null;
  };
}

export interface IRegistrationData {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  confirmPassword: string;
  recaptchaResponse: string;
  medicalSchools: Label[];
  medicalSpecialties: Label[];
  occupation: string;
  country: string;
  region: string;
  city: string;
  locationDetails: ILocationDetails;
}

export const registerUser = createAsyncAction(
  "registerUser/REQUEST",
  "registerUser/SUCCESS",
  "registerUser/FAILURE"
)<IRegistrationData, IOpenxmedApiSuccessResponse, Error>();

export interface IUsersRequestData extends IPageParameters {
  nameQuery?: string;
  role?: UserRole;
  specialtyIds?: string[];
}

export const getUsers = createAsyncAction(
  "getUsers/REQUEST",
  "getUsers/SUCCESS",
  "getUsers/FAILURE"
)<IUsersRequestData, IOpenxmedApiSuccessResponse, Error>();

export const getUser = createAsyncAction(
  "getUser/REQUEST",
  "getUser/SUCCESS",
  "getUser/FAILURE"
)<string, IOpenxmedApiSuccessResponse, Error>();

export const updateUser = createAsyncAction(
  "updateUser/REQUEST",
  "updateUser/SUCCESS",
  "updateUser/FAILURE"
)<User, IOpenxmedApiSuccessResponse, Error>();

export const setUserRole = createAsyncAction(
  "setUserRole/REQUEST",
  "setUserRole/SUCCESS",
  "setUserRole/FAILURE"
)<User, IOpenxmedApiSuccessResponse, Error>();

export const postUserAvatar = createAsyncAction(
  "postUserAvatar/REQUEST",
  "postUserAvatar/SUCCESS",
  "postUserAvatar/FAILURE"
)<Blob, IOpenxmedApiSuccessResponse, Error, undefined>();

export const setUserActiveStudy = createAction("setUserActiveStudy")<
  string | undefined
>();

export interface AlgoFavesPayload {
  add?: Pick<Algorithm, "id">;
  remove?: Pick<Algorithm, "id">;
}

export const setUserFavouriteAlgorithms = createAsyncAction(
  "setAlgoFaves/REQUEST",
  "setAlgoFaves/SUCCESS",
  "setAlgoFaves/FAILURE"
)<AlgoFavesPayload, IOpenxmedApiSuccessResponse, Error>();

export const getUserFavouriteAlgorithms = createAsyncAction(
  "getAlgoFaves/REQUEST",
  "getAlgoFaves/SUCCESS",
  "getAlgoFaves/FAILURE"
)<undefined, IOpenxmedApiSuccessResponse, Error>();
