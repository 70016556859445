import { AnchorButton, MenuItem } from "@blueprintjs/core";
import { IconName, IconNames } from "@blueprintjs/icons";
import { ItemRenderer, Select } from "@blueprintjs/select";
import * as React from "react";

export enum BanReason {
  Duplicate = "dup",
  NotVerified = "invalid",
  Other = "other"
}

export const banReason = (
  reason: BanReason
): { text: string; icon: IconName } => {
  switch (reason) {
    case BanReason.Duplicate:
      return { text: "Duplicate account", icon: IconNames.PEOPLE };
    case BanReason.NotVerified:
      return { text: "Not able to verify", icon: IconNames.DISABLE };
    default:
      return { text: "Other...", icon: IconNames.MORE };
  }
};

const kBanReasons = [
  BanReason.Duplicate,
  BanReason.NotVerified,
  BanReason.Other
];

export interface IBanReasonSelectProps {
  className?: string;
  disabled?: boolean;
  value?: BanReason;
  onChange?: (value: BanReason) => void;
}

const BanReasonSelectComponent = Select.ofType<BanReason>();

export class BanReasonSelect extends React.PureComponent<
  IBanReasonSelectProps
> {
  public render() {
    const { disabled, value } = this.props;
    return (
      <div className={`${this.props.className}`}>
        <BanReasonSelectComponent
          disabled={disabled}
          filterable={false}
          popoverProps={{ minimal: true }}
          items={kBanReasons}
          itemRenderer={this.itemRenderer}
          onItemSelect={this.selectionHandler}
          inputProps={{ disabled }}
        >
          <AnchorButton
            disabled={disabled}
            text={value ? banReason(value).text : "Select ban reason..."}
            rightIcon={IconNames.CARET_DOWN}
          />
        </BanReasonSelectComponent>
      </div>
    );
  }

  private itemRenderer: ItemRenderer<BanReason> = (
    value,
    { handleClick, modifiers: { disabled } }
  ) => {
    const details = banReason(value);
    return (
      <MenuItem
        active={value === this.props.value}
        disabled={disabled}
        icon={details.icon}
        key={value}
        onClick={handleClick}
        text={details.text}
      />
    );
  };

  private selectionHandler = (i: BanReason) => {
    const { onChange } = this.props;
    if (onChange) {
      onChange(i);
    }
  };
}
