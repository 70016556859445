import { MenuItem, Spinner } from "@blueprintjs/core";
import { ItemRenderer, Suggest } from "@blueprintjs/select";
import * as React from "react";
import { WrappedFieldProps } from "redux-form";

import { ILocationDetails } from "src/actions";
import { kTeleportApiSearch } from "src/api";
import { highlightText } from "src/utilities";

export interface ILocationSuggestProps extends WrappedFieldProps {
  className?: string;
  disabled?: boolean;
}

interface ILocationSuggestState {
  items: ILocationDetails[];
  loading: boolean;
  query: string;
}

export class LocationSuggest extends React.PureComponent<
  ILocationSuggestProps,
  ILocationSuggestState
> {
  constructor(props: ILocationSuggestProps) {
    super(props);

    this.state = {
      items: [],
      loading: false,
      query: ""
    };
  }
  public render() {
    const { items } = this.state;
    const {
      disabled,
      meta: { error }
    } = this.props;

    return (
      <div className={this.props.className}>
        <Suggest<ILocationDetails>
          items={items}
          selectedItem={this.props.input.value}
          noResults={this.renderNoResults()}
          itemRenderer={this.itemRenderer}
          inputValueRenderer={this.inputValueRenderer}
          onItemSelect={this.handleSelect}
          onQueryChange={this.searchForLocations}
          popoverProps={{ minimal: true }}
          openOnKeyDown={false}
          resetOnQuery={true}
          disabled={disabled}
        />
        {error && <span className="zx-orange pv1">{error}</span>}
      </div>
    );
  }

  private renderNoResults = () => {
    const { loading, query } = this.state;
    return loading ? (
      <Spinner size={Spinner.SIZE_SMALL} />
    ) : (
      <MenuItem
        disabled={true}
        text={query.length === 0 ? "Start typing..." : "No results."}
      />
    );
  };

  private handleSelect = (item: ILocationDetails) => {
    const {
      input: { onChange }
    } = this.props;

    onChange(item);
  };

  private inputValueRenderer = (item: ILocationDetails) =>
    item.value.city === null
      ? ""
      : `${item.value.city}${
          (item.value.country !== null && item.value.country.length) > 0
            ? `, ${item.value.country}`
            : ""
        }`;

  private itemRenderer: ItemRenderer<ILocationDetails> = (
    { value: { city, country, region } },
    { handleClick, modifiers, query }
  ) => {
    if (!modifiers.matchesPredicate) {
      return null;
    }

    const text = `${city}, ${region}`;
    return (
      <MenuItem
        active={modifiers.active}
        disabled={modifiers.disabled}
        label={country || ""}
        key={text}
        onClick={handleClick}
        text={highlightText(text, query)}
      />
    );
  };

  private searchForLocations = async (query: string) => {
    if (query.length === 0) {
      this.setState({ items: [], query });
    } else {
      const lookupUrl = `${kTeleportApiSearch}?embed=city%3Asearch-results%2Fcity%3Aitem&limit=7&search=${query}`;
      this.setState({ loading: true, query });
      try {
        const response = await fetch(lookupUrl, { method: "GET" });
        const json = await response.json();

        const results = json._embedded["city:search-results"];
        const options = results.map((r: any) => {
          return {
            label: r.matching_full_name,
            value: {
              city: r._embedded["city:item"].name,
              country: r._embedded["city:item"]._links["city:country"].name,
              region:
                r._embedded["city:item"]._links["city:admin1_division"].name
            }
          };
        });
        this.setState({ items: options, loading: false });
      } catch (reason) {
        this.setState({ loading: false });
        // tslint:disable-next-line:no-console
        console.error(`Country lookup error: ${reason}`);
      }
    }
  };
}
