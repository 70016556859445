import {
  Alert,
  Button,
  Intent,
  Spinner,
  Tab,
  Tabs,
  Icon
} from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import { replace } from "connected-react-router";
import { Dictionary } from "lodash";
import * as React from "react";
import { connect } from "react-redux";
import { RouteComponentProps } from "react-router-dom";

import { deleteParameter, deleteParameterUnit } from "src/actions";
import { Parameter, ParameterUnit } from "src/api";
import { ConnectedPageTitle } from "src/components";
import { kVariablesMenuUrlKey } from "src/config/routes";
import { IStoreState, IVariableDefinition } from "src/store";
import { sortAlphabetically } from "src/utilities";
import { EditUnitDialog } from "./edit-unit-dialog";
import { EditVariableDialog } from "./edit-variable-dialog";

type VarTabType = "vars" | "units";

type IVariableAuthoringPageProps = RouteComponentProps<{ tabId?: VarTabType }>;

interface IVariableAuthoringPageInjectedProps {
  parameters: Dictionary<Parameter>;
  units: Dictionary<ParameterUnit>;
}

interface IVariableAuthoringPageDispatchProps {
  deleteParameter: typeof deleteParameter.request;
  deleteUnit: typeof deleteParameterUnit.request;
  replace: typeof replace;
}

interface IVariableAuthoringPageState {
  objectToDelete?: Parameter | ParameterUnit;
  newVarPanelOpen: boolean;
  varToShow?: Parameter;
  newVarPanelUnit?: ParameterUnit;
  newUnitPanelOpen: boolean;
  unitEditMode: boolean;
}

export const renderVarComponent = (
  varDef: IVariableDefinition,
  index?: number
) => {
  if (index !== undefined) {
    return (
      <div key={index}>
        {varDef.name} ({varDef.type})
      </div>
    );
  } else {
    switch (varDef.type) {
      case "discrete":
        return (
          varDef.discreteValues &&
          varDef.discreteValues.map(v => v.value).join(" / ")
        );
      case "boolean":
        return "true / false";
      case "numeric":
        if (varDef.range) {
          return `Range ${varDef.min}-${varDef.max}`;
        }
        return varDef.unit && varDef.unit.name;

      default:
        return null;
    }
  }
};

type VariableAuthoringPageComponentProps = IVariableAuthoringPageProps &
  IVariableAuthoringPageInjectedProps &
  IVariableAuthoringPageDispatchProps;

class VariableAuthoringPageComponent extends React.PureComponent<
  VariableAuthoringPageComponentProps,
  IVariableAuthoringPageState
> {
  constructor(props: VariableAuthoringPageComponentProps) {
    super(props);
    this.state = {
      newUnitPanelOpen: false,
      newVarPanelOpen: false,
      unitEditMode: false
    };
  }

  public render() {
    const {
      newUnitPanelOpen,
      newVarPanelOpen,
      newVarPanelUnit,
      unitEditMode,
      varToShow
    } = this.state;
    const panelFocus = this.props.match.params.tabId || "vars";

    const handleTabChange = (tabId: VarTabType) =>
      this.props.replace(`${kVariablesMenuUrlKey}/${tabId}`);

    const closeUnitDialog = () =>
      this.setState({
        newUnitPanelOpen: false,
        newVarPanelUnit: undefined,
        unitEditMode: false
      });

    const closeVarDialog = () =>
      this.setState({ newVarPanelOpen: false, varToShow: undefined });

    const handleNewUnitRequest = (unit: ParameterUnit) =>
      this.setState({
        newUnitPanelOpen: true,
        newVarPanelUnit: unit,
        unitEditMode: false
      });

    return (
      <section className="pa2 mb2 flex flex-column flex-auto overflow-y-auto overflow-x-hidden animated fadeIn faster">
        <ConnectedPageTitle />
        {this.renderDeleteConfirmDialog()}
        <EditVariableDialog
          isOpen={newVarPanelOpen}
          onClose={closeVarDialog}
          onNewUnit={handleNewUnitRequest}
          paramToShow={varToShow}
        />
        <EditUnitDialog
          isOpen={newUnitPanelOpen}
          onClose={closeUnitDialog}
          unitToShow={newVarPanelUnit}
          editMode={unitEditMode}
        />
        <Tabs
          className="pa2 flex flex-column flex-auto"
          selectedTabId={panelFocus}
          onChange={handleTabChange}
        >
          <Tab id="vars" title="Variables" panel={this.varsTabRenderer()} />
          <Tab id="units" title="Units" panel={this.unitsTabRenderer()} />
        </Tabs>
      </section>
    );
  }

  private varsTabRenderer = () => {
    const { parameters } = this.props;

    const openDialog = () =>
      this.setState({
        newVarPanelOpen: true,
        newVarPanelUnit: undefined,
        varToShow: undefined
      });

    const paramRowRenderer = (params: Dictionary<Parameter>) =>
      Object.values(params)
        .sort((a, b) => sortAlphabetically(a.title, b.title))
        .map(this.renderVariableRow);

    return (
      <section className="bg-white pa2 br3 flex flex-column">
        <section className="flex flex-row b bb b--light-gray pv2 pb1 mb1">
          <div className="fl w-40 pl1 pv1">Name</div>
          <div className="fl w-10 pl1 pv1">Type</div>
          <div className="fl w-50 flex flex-row justify-between">
            <p className="pl1 pv1">Components</p>
            <Button text="New Variable" onClick={openDialog} />
          </div>
        </section>
        {Object.values(parameters).length === 0 && <Spinner />}
        {paramRowRenderer(parameters)}
      </section>
    );
  };

  private renderVariableRow = (param: Parameter, index: number) => {
    const isGroup = param.detailsJson.length !== 1;

    const openEditParameter = () =>
      this.setState({ newVarPanelOpen: true, varToShow: param });

    const deleteParam = (e: React.MouseEvent) => {
      e.stopPropagation();
      this.setState({ objectToDelete: param });
    };

    const componentRenderer = isGroup
      ? param.detailsJson.map(renderVarComponent)
      : renderVarComponent(param.detailsJson[0]);

    const removeButton = () =>
      !param.inUse && (
        <Button
          icon={IconNames.REMOVE}
          minimal={true}
          onClick={deleteParam}
          small={true}
        />
      );

    return (
      <section
        className={"flex pointer b bb b--light-gray"}
        id={param.id}
        key={index}
        onClick={openEditParameter}
      >
        <div className="fl w-40 pa1 flex flex-row">
          <Icon
            className="pr2"
            icon={IconNames.LOCK}
            color={param.inUse ? undefined : "white"}
          />
          <p>{param.title}</p>
        </div>
        <div className="fl w-10 pa1">
          {isGroup ? "Group" : param.detailsJson[0].type}
        </div>
        <div className="fl w-50 pa1 flex flex-row justify-between">
          <div>{componentRenderer}</div>
          <div className="fl w-20 pa1 flex justify-end">{removeButton()}</div>
        </div>
      </section>
    );
  };

  private unitsTabRenderer = () => {
    const { units } = this.props;
    const openDialog = () =>
      this.setState({
        newUnitPanelOpen: true,
        newVarPanelUnit: undefined,
        unitEditMode: false
      });

    const unitRowRenderer = (uNs: Dictionary<ParameterUnit>) =>
      Object.values(uNs)
        .sort((a, b) => sortAlphabetically(a.name, b.name))
        .map(this.renderUnitRow);

    return (
      <section className="bg-white pa2 br3 flex flex-column flex-auto">
        <section className="flex flex-auto b bb b--light-gray pv2 pb1 mb1">
          <div className="fl w-60 pl1 pv1">Name</div>
          <div className="fl w-20 pl1 pv1">Short form</div>
          <div className="fl w-20 flex justify-end">
            <Button text="New Unit" onClick={openDialog} />
          </div>
        </section>
        {Object.values(units).length === 0 && <Spinner />}
        {unitRowRenderer(units)}
      </section>
    );
  };

  private renderUnitRow = (unit: ParameterUnit, index: number) => {
    const openEditUnit = () =>
      this.setState({
        newUnitPanelOpen: true,
        newVarPanelUnit: unit,
        unitEditMode: true
      });

    const deleteUnit = (e: React.MouseEvent) => {
      e.stopPropagation();
      this.setState({ objectToDelete: unit });
    };

    return (
      <section
        className="flex pointer b bb b--light-gray"
        id={unit.id}
        key={index}
        onClick={openEditUnit}
      >
        <div className="fl w-60 pa1">
          <p>{unit.name}</p>
        </div>
        <div className="fl w-20 pa1">{unit.shortName}</div>
        <div className="fl w-20 pa1 flex justify-end">
          <Button
            icon={IconNames.REMOVE}
            small={true}
            minimal={true}
            onClick={deleteUnit}
          />
        </div>
      </section>
    );
  };

  private renderDeleteConfirmDialog = () => {
    const { objectToDelete } = this.state;

    const handleCancelDelete = () =>
      this.setState({ objectToDelete: undefined });
    const handleConfirmDelete = () => {
      // DO the delete
      if (objectToDelete instanceof Parameter) {
        this.props.deleteParameter({
          parameter: objectToDelete,
          tag: objectToDelete.id
        });
      } else if (objectToDelete instanceof ParameterUnit) {
        this.props.deleteUnit({ unit: objectToDelete, tag: objectToDelete.id });
      }
      this.setState({ objectToDelete: undefined });
    };

    return (
      <Alert
        canEscapeKeyCancel={true}
        canOutsideClickCancel={true}
        cancelButtonText="Cancel"
        onCancel={handleCancelDelete}
        confirmButtonText="Delete"
        onConfirm={handleConfirmDelete}
        isOpen={objectToDelete !== undefined}
        icon={IconNames.TRASH}
        intent={Intent.DANGER}
      >
        <p>This action will permanently remove the item. Are you sure?</p>
      </Alert>
    );
  };
}

const mapStateToProps = ({ parameterStore }: IStoreState) => {
  return {
    parameters: parameterStore.params,
    units: parameterStore.units
  };
};

export const VariableAuthoringPage = connect(mapStateToProps, {
  deleteParameter: deleteParameter.request,
  deleteUnit: deleteParameterUnit.request,
  replace
})(VariableAuthoringPageComponent);
