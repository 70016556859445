import { Button } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import { cloneDeep } from "lodash";
import * as React from "react";
import { change, formValueSelector } from "redux-form";
import { connect } from "react-redux";

import {
  fullName,
  Study,
  removeFromArray,
  StudyStatus,
  Patient,
  updateArray
} from "src/api";
import { IStoreState } from "src/store";
import { AddPatientDialog } from ".";

interface IEditStudyPatientsProps {
  className?: string;
  study: Study;
}

interface IEditStudyPatientsInjectedProps {
  patients: Patient[];
}

interface IEditStudyPatientsDispatchProps {
  changeValues: typeof change;
}

interface IEditStudyPatientsState {
  addPatientDialogOpen: boolean;
}

type EditStudyPatientsProps = IEditStudyPatientsProps &
  IEditStudyPatientsInjectedProps &
  IEditStudyPatientsDispatchProps;

class EditStudyAlgorithmComponent extends React.PureComponent<
  EditStudyPatientsProps,
  IEditStudyPatientsState
> {
  constructor(props: EditStudyPatientsProps) {
    super(props);

    this.state = {
      addPatientDialogOpen: false
    };
  }

  public render() {
    const { patients, study } = this.props;
    const { addPatientDialogOpen: addAlgoDialogOpen } = this.state;

    const handleClose = (newPatient?: Patient) => {
      if (newPatient) {
        let updated = cloneDeep(study.patients);
        updated = updateArray(updated, newPatient);
        this.props.changeValues(study.id, "patients", updated);
      }
      this.setState({ addPatientDialogOpen: false });
    };

    return (
      <section>
        <AddPatientDialog
          isOpen={addAlgoDialogOpen}
          onClose={handleClose}
          study={study}
        />
        {this.renderPatients(study, patients)}
      </section>
    );
  }

  private renderPatients = (study: Study, patients: Patient[]) => {
    const openDialog = () => this.setState({ addPatientDialogOpen: true });

    const editable = study.status !== StudyStatus.approved;
    const editButton = editable && (
      <Button
        minimal={true}
        rightIcon={IconNames.ADD}
        text="Add patient..."
        onClick={openDialog}
      />
    );

    return (
      <section>
        <section className="w-100 flex b bb b--gray pb1 mb1">
          <div className="fl w-60 pt2 pa1">Name</div>
          <div className="fl w-40 flex justify-between">
            <span className="pa1 pt2">ID</span>
            {editButton}
          </div>
        </section>
        <section>
          {patients.length === 0 && (
            <span className="zx-blue">No patients added yet</span>
          )}
          {this.renderPatientRows(study, patients)}
        </section>
      </section>
    );
  };

  private renderPatientRows = (study: Study, patients: Patient[]) => {
    const {
      study: { id }
    } = this.props;

    return patients.map(a => {
      const removeRow = () => {
        const updated = removeFromArray(patients, a.id);
        this.props.changeValues(id, "patients", updated);
      };
      return this.renderPatientRow(study, a, removeRow);
    });
  };

  private renderPatientRow = (
    study: Study,
    patient: Patient,
    removeAlgo: () => void
  ) => {
    const editable = study.status !== StudyStatus.approved;
    return (
      <div key={patient.id} className="flex flex-row w-100">
        <div className="fl w-60 pa1">{fullName(patient)}</div>
        <div className="fl w-40 flex justify-between">
          <span className="pa1 pt2">{patient.regionalPatientId}</span>
          {editable && (
            <Button
              icon={IconNames.REMOVE}
              minimal={true}
              onClick={removeAlgo}
            />
          )}
        </div>
      </div>
    );
  };
}

const mapStateToProps = (
  state: IStoreState,
  { study }: IEditStudyPatientsProps
) => {
  const selector = formValueSelector(study.id);
  const patients = selector(state, "patients");
  return {
    patients
  };
};

export const EditStudyPatients = connect(mapStateToProps, {
  changeValues: change
})(EditStudyAlgorithmComponent);
