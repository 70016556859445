import { Button, Classes, Dialog, Label } from "@blueprintjs/core";
import { Dictionary } from "lodash";
import * as React from "react";
import { connect } from "react-redux";

import { createParameterUnit, updateParameterUnit } from "src/actions";
import { ParameterUnit } from "src/api";
import { IStoreState } from "src/store";

import rulerIcon from "src/resources/ruler-icon.svg";

interface INewUnitDialogProps {
  isOpen: boolean;
  onClose: () => void;
  unitToShow?: ParameterUnit;
  editMode?: boolean;
}

interface INewUnitDialogInjectedProps {
  error?: Error;
  units: Dictionary<ParameterUnit>;
}

interface INewUnitDialogDispatchProps {
  createUnit: typeof createParameterUnit.request;
  updateUnit: typeof updateParameterUnit.request;
}

interface INewUnitDialogState {
  busy: boolean;
  unit: ParameterUnit;
}

type NewUnitDialogComponentProps = INewUnitDialogProps &
  INewUnitDialogInjectedProps &
  INewUnitDialogDispatchProps;

class NewUnitDialogComponent extends React.PureComponent<
  NewUnitDialogComponentProps,
  INewUnitDialogState
> {
  constructor(props: NewUnitDialogComponentProps) {
    super(props);
    this.state = {
      busy: false,
      unit: props.unitToShow || new ParameterUnit()
    };
  }

  public componentDidUpdate() {
    const { busy, unit } = this.state;
    const { error, units, unitToShow } = this.props;

    if (busy) {
      if (units[unit.id]) {
        // Added unit
        this.closeDialog();
      } else if (error) {
        this.setState({ busy: false });
      }
    } else {
      if (unitToShow && unitToShow.id !== unit.id) {
        this.setState({ unit: unitToShow });
      }
    }
  }

  public render() {
    const { editMode, isOpen } = this.props;
    const { unit } = this.state;

    const icon = (
      <img
        alt="logo"
        src={rulerIcon}
        style={{ height: 20, paddingRight: "1em" }}
      />
    );

    return (
      <Dialog
        icon={icon}
        title={editMode ? unit.name : "New Unit"}
        isOpen={isOpen}
        onClose={this.closeDialog}
      >
        {this.renderBody()}
        {this.renderFooter()}
      </Dialog>
    );
  }

  private closeDialog = async () => {
    this.setState({ unit: new ParameterUnit(), busy: false });
    this.props.onClose();
  };

  private renderFooter = () => {
    const { error } = this.props;
    const { busy, unit } = this.state;

    const saveDisabled =
      busy || unit.name.length < 2 || unit.shortName.length < 1;

    return (
      <div className={`${Classes.DIALOG_FOOTER} flex flex-row justify-end`}>
        {error && <span className="mt2 orange ph2">{error.message}</span>}
        <Button
          disabled={saveDisabled}
          loading={busy}
          text="Save"
          onClick={this.saveUnit}
        />
        <Button text="Cancel" onClick={this.closeDialog} />
      </div>
    );
  };

  private saveUnit = () => {
    const { unit } = this.state;

    if (this.props.editMode) {
      this.props.updateUnit(unit);
    } else {
      this.props.createUnit(unit);
    }
    this.setState({ busy: true });
  };

  private renderBody = () => {
    const { unit } = this.state;

    const updateName = (e: React.ChangeEvent<HTMLInputElement>) =>
      this.setState({
        unit: { ...unit, name: e.currentTarget.value }
      });
    const updateShortName = (e: React.ChangeEvent<HTMLInputElement>) =>
      this.setState({
        unit: { ...unit, shortName: e.currentTarget.value }
      });

    return (
      <section className={`${Classes.DIALOG_BODY} overflow-y-auto`}>
        <section className="flex flex-row flex-auto">
          <Label className="pa1">
            Unit Name
            <input
              className={`${Classes.INPUT}`}
              onChange={updateName}
              placeholder="Unit name"
              value={unit.name}
            />
          </Label>
          <Label className="pa1">
            Short Form
            <input
              className={`${Classes.INPUT}`}
              onChange={updateShortName}
              placeholder="Abbreviation"
              value={unit.shortName}
            />
          </Label>
        </section>
      </section>
    );
  };
}

const mapStateToProps = ({ parameterStore }: IStoreState) => {
  return {
    error: parameterStore.error,
    units: parameterStore.units
  };
};

export const EditUnitDialog = connect(mapStateToProps, {
  createUnit: createParameterUnit.request,
  updateUnit: updateParameterUnit.request
})(NewUnitDialogComponent);
