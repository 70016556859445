import { Button, Intent, Label } from "@blueprintjs/core";
import * as React from "react";
import { Field, FormErrors, InjectedFormProps, reduxForm } from "redux-form";

import { ILocationDetails } from "src/actions";
import { specialtyName, User, userIsAdmin } from "src/api";
import {
  FormField,
  IRenderFieldProps,
  LocationSuggest,
  renderMedSchools,
  renderMedSpecialties
} from "src/components";

import { disableEnterSubmit } from "src/utilities";

interface IUserProfileDetailsProps {
  busy: boolean;
  editMode: boolean;
  loggedInUser?: User;
  user?: User;
}

type UserProfileDetailsProps = InjectedFormProps<
  User & { locationDetails: ILocationDetails },
  IUserProfileDetailsProps
> &
  IUserProfileDetailsProps;

class UserProfileDetailsComponent extends React.PureComponent<
  UserProfileDetailsProps
> {
  public render() {
    const {
      busy,
      dirty,
      editMode,
      handleSubmit,
      loggedInUser,
      pristine,
      submitting,
      valid,
      user
    } = this.props;

    if (!user || !loggedInUser) {
      return null;
    }

    const saveButton = (
      <Button
        intent={Intent.PRIMARY}
        type="submit"
        text="Save Changes"
        onClick={handleSubmit}
        disabled={!dirty || !valid || pristine || submitting}
        loading={busy}
      />
    );

    return (
      <form onKeyPress={disableEnterSubmit} onSubmit={handleSubmit}>
        <section className="flex flex-wrap">
          {this.renderFirstSection(user, loggedInUser, editMode, busy)}

          {this.renderSecondSection(user, loggedInUser, editMode, busy)}
        </section>
        <div className="pb2">{editMode && saveButton}</div>
      </form>
    );
  }

  private renderFirstSection = (
    { city, country, region, email, id }: User,
    loggedInUser: User,
    editMode: boolean,
    busy: boolean | undefined
  ) => {
    const bodySection = editMode ? (
      <Field
        label="City"
        name="locationDetails"
        component={this.renderCountrySearch}
        disabled={busy}
      />
    ) : (
      <section>
        {this.renderField("City", city)}
        {this.renderField("Region", region)}
        {this.renderField("Country", country)}
      </section>
    );

    return (
      <section className="flex flex-column flex-auto pr3 mw5 ">
        {(userIsAdmin(loggedInUser) || id === loggedInUser.id) &&
          this.renderField("Email", email)}
        {bodySection}
      </section>
    );
  };

  private renderSecondSection = (
    { title, medicalSchools, medicalSpecialties, biography, interests }: User,
    loggedInUser: User,
    editMode: boolean,
    busy: boolean | undefined
  ) => {
    const occupationField = editMode ? (
      <Field
        name="title"
        label="Occupation"
        component={FormField}
        disabled={busy}
      />
    ) : (
      this.renderField("Occupation", title)
    );

    const medSchoolField = editMode ? (
      <Field
        name="medicalSchools"
        label="Medical Schools"
        component={renderMedSchools}
        disabled={busy}
      />
    ) : (
      this.renderField(
        "Medical Schools",
        medicalSchools.map(s => s.title).join(", ")
      )
    );

    const medSpecialtiesField = editMode ? (
      <Field
        name="medicalSpecialties"
        label="Medical Specialties"
        component={renderMedSpecialties}
        disabled={busy}
      />
    ) : (
      this.renderField(
        "Medical Specialties",
        medicalSpecialties.map(specialtyName).join("\n ")
      )
    );

    const bioField = editMode ? (
      <Field
        name="biography"
        type="textarea"
        label="Bio"
        component={FormField}
        disabled={busy}
      />
    ) : (
      this.renderField("Biography", biography)
    );

    const interestsField = editMode ? (
      <Field
        name="interests"
        type="text"
        label="Interests"
        component={FormField}
        disabled={busy}
      />
    ) : (
      this.renderField("Interests", interests)
    );

    return (
      <section className="flex flex-column flex-auto">
        {occupationField}
        {medSchoolField}
        {medSpecialtiesField}
        {bioField}
        {interestsField}
      </section>
    );
  };

  private renderField = (text: string, value?: string) => {
    return (
      <section className="pa1">
        <span className="pb1 zx-blue ttc">{text}</span>
        <p>{value || "(Not entered)"}</p>
      </section>
    );
  };

  private renderCountrySearch: React.SFC<IRenderFieldProps> = props => {
    const { label } = props;

    return (
      <Label>
        <span className="ttc">{label}</span>
        <LocationSuggest {...props} />
      </Label>
    );
  };
}

const validate = (
  values: Readonly<User & { locationDetails: ILocationDetails }>
): FormErrors<User & { locationDetails: ILocationDetails }> => {
  const errors: FormErrors<User & { locationDetails: ILocationDetails }> = {}; // empty errors = form good

  return errors;
};

export const UserProfileDetails = reduxForm<
  User & { locationDetails: ILocationDetails },
  IUserProfileDetailsProps
>({
  validate
})(UserProfileDetailsComponent);
