import { Checkbox } from "@blueprintjs/core";
import * as React from "react";
import { WrappedFieldProps } from "redux-form";

import {
  kSelectablePrivileges,
  RolePriviledge,
  systemPrivilegeString
} from "src/api";

export interface IRolePrivilegeMultiSelectProps extends WrappedFieldProps {
  className?: string;
  disabled?: boolean;
  label: string;
  multiSelect?: boolean;
  placeholder?: string;
}

export class RolePrivilegeMultiSelect extends React.PureComponent<
  IRolePrivilegeMultiSelectProps
> {
  public render() {
    const {
      input: { value },
      label,
      meta: { error }
    } = this.props;

    const errorRenderer = error && (
      <span className="zx-orange pv1">{error}</span>
    );

    return (
      <div className={`${this.props.className} pb2`}>
        <span className="ttc">{label}</span>
        <div className="flex flex-wrap pt1">
          {kSelectablePrivileges.map(sp => this.renderCheckbox(sp, value))}
        </div>
        {errorRenderer}
      </div>
    );
  }

  private renderCheckbox = (
    priv: RolePriviledge,
    selected: RolePriviledge[]
  ) => {
    const handleChange = (event: React.FormEvent<HTMLInputElement>) => {
      if (event.currentTarget.checked) {
        this.handleSelect(priv, selected);
      } else {
        this.deselectItem(priv, selected);
      }
    };
    return (
      <Checkbox
        inline={true}
        label={systemPrivilegeString(priv)}
        checked={selected.includes(priv)}
        onChange={handleChange}
      />
    );
  };

  private deselectItem = (item: RolePriviledge, selected: RolePriviledge[]) => {
    const selectedItems = selected.filter(i => i !== item);
    this.props.input.onChange(selectedItems);
  };

  private handleSelect = (item: RolePriviledge, selected: RolePriviledge[]) => {
    const {
      input: { onChange }
    } = this.props;

    const selectedItems = [...selected, item];
    onChange(selectedItems);
  };
}
