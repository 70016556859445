import { H6, HTMLTable } from "@blueprintjs/core";
import { push } from "connected-react-router";
import moment from "moment";
import * as React from "react";
import { connect } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router-dom";

import {
  Algorithm,
  AlgorithmStatus,
  algoStatusString,
  fullName,
  User,
  userIsContributor
} from "src/api";
import { IStoreState } from "src/store";
import { sortAlphabetically } from "src/utilities";

interface IAlgoListRouteMatches {
  topicId: string;
}

export interface IAlgorithmListProps
  extends RouteComponentProps<IAlgoListRouteMatches> {
  algorithms: Algorithm[];
  user?: User;
  pushUrl: typeof push;
}

export const AlgorithmListComponent: React.SFC<IAlgorithmListProps> = props => {
  const { algorithms, location, pushUrl, user } = props;

  const renderRow = (
    algorithm: Algorithm,
    currentPath: string,
    p: typeof push,
    user?: User
  ) => {
    const handleClick = () => {
      p(`${currentPath}/${algorithm.id}`);
    };

    const showStatus = user && userIsContributor(user);
    return (
      <tr
        id={algorithm.id}
        key={algorithm.id}
        className="pointer"
        onClick={handleClick}
      >
        <td>
          <H6 className="ttc zx-link-colour" style={{ marginBottom: 2 }}>
            {algorithm.title}
          </H6>
          <p>{algorithm.authors.map(a => fullName(a)).join(", ")}</p>
        </td>
        <td>{moment(algorithm.updatedAt).fromNow()}</td>
        {showStatus && <td>{algoStatusString(algorithm.status)}</td>}
      </tr>
    );
  };

  if (algorithms) {
    const algoRows = algorithms
      .sort((a, b) => sortAlphabetically(a.title, b.title))
      .map(a => renderRow(a, location.pathname, pushUrl, user));

    return (
      <HTMLTable
        className={"w-100 mt2 animated fadeIn faster bg-white br3 flex-auto"}
      >
        <thead>
          <tr>
            <th>Name</th>
            <th>Updated</th>
            {user && userIsContributor(user) && <th>Status</th>}
          </tr>
        </thead>
        <tbody>{algoRows}</tbody>
      </HTMLTable>
    );
  } else {
    return null;
  }
};

const mapStateToProps = (
  { algoStore, userStore }: IStoreState,
  { match }: IAlgorithmListProps
) => {
  const algorithms = Object.values(algoStore.allAlgorithms)
    .filter(a => a.medicalSpecialties.find(m => m.id === match.params.topicId))
    .filter(a => a.status !== AlgorithmStatus.superceded);
  return { algorithms, user: userStore.loggedInUser };
};

export const AlgorithmList = withRouter(
  connect(mapStateToProps, { pushUrl: push })(AlgorithmListComponent)
);
