import { PayloadAction } from "typesafe-actions";

import { IEventQuery, IRegistrationData, IUsersRequestData } from "src/actions";
import { getRequestInit, kUsers, serverRoot } from ".";
import { kEvents } from "./endpoints";

export const getUsersParams = (
  action: PayloadAction<"getUsers/REQUEST", IUsersRequestData>,
  token: string | undefined,
  signal: AbortSignal | null
) => {
  const { nameQuery, role, skip, specialtyIds, take } = action.payload;

  let url = `${serverRoot}/${kUsers}?skip=${skip}&take=${take}`;
  if (nameQuery) {
    url += `&name=${nameQuery}`;
  }
  if (role) {
    url += `&role=${role}`;
  }
  if (specialtyIds) {
    url += `&${specialtyIds.map(s => `specialtyIds=${s}`).join("&")}`;
  }

  return { config: { ...getRequestInit(token), signal }, url };
};

export const getEventParams = (
  action: PayloadAction<"retrieveEvents/REQUEST", IEventQuery>,
  token: string | undefined,
  signal: AbortSignal | null
) => {
  const { category, refId, sourceRefId, skip = 0, take = 20 } = action.payload;

  let url = `${serverRoot}/${kEvents}?skip=${skip}&take=${take}`;
  if (refId) {
    url += `&refId=${refId}`;
  }
  if (sourceRefId) {
    url += `&sourceRefId=${sourceRefId}`;
  }
  if (category) {
    url += `&category=${category}`;
  }

  return { config: { ...getRequestInit(token), signal }, url };
};

export const mapRegistrationData = (regData: IRegistrationData) => {
  return {
    city: regData.locationDetails.value.city,
    country: regData.locationDetails.value.country,
    email: regData.email,
    firstName: regData.firstName,
    lastName: regData.lastName,
    medicalSchools: regData.medicalSchools,
    medicalSpecialties: regData.medicalSpecialties,
    region: regData.locationDetails.value.region,
    title: regData.occupation
  };
};
