import { H5 } from "@blueprintjs/core";
import * as React from "react";

import { User } from "src/api";
import { UserCard } from "src/pages/community";
import { IOpenAlgorithm } from "src/store";

export interface IAlgoContributorsProps {
  algoDetails: IOpenAlgorithm;
  loggedInUser?: User;
}

export class AlgoContributors extends React.PureComponent<
  IAlgoContributorsProps
> {
  public render() {
    const {
      algoDetails: {
        algorithm: { authors, editors }
      },
      loggedInUser
    } = this.props;

    return (
      <section>
        <H5>{authors.length === 1 ? "Author" : "Authors"}</H5>
        {this.renderContributors(authors, loggedInUser)}
        <H5>{editors.length === 1 ? "Reviewer" : "Reviewers"}</H5>
        {this.renderContributors(editors, loggedInUser)}
      </section>
    );
  }

  private renderContributors = (users: User[], loggedInUser?: User) => {
    const userRows = users.map(c => (
      <UserCard key={c.id} user={c} loggedInUser={loggedInUser} />
    ));
    return <section className="pa2">{userRows}</section>;
  };
}
