import { cloneDeep } from "lodash";
import { IConsumeAlgoPayload, ITappedInfoPayload } from "src/actions";
import {
  AlgoNode,
  AlgoNodeInfoType,
  AlgoNodeType,
  AlgoState,
  evaluateSelection,
  nextDecisionNodePathForNode,
  updateDecisions,
  unwindDecisions
} from "src/api";
import { InfoTabType, IOpenAlgorithm, ISelectionData } from "src/store";
import { nodeTitleTextPlain } from "src/utilities";
import { createSectionData } from ".";

export const removeOpenAlgoFromArray = (
  algoArray: IOpenAlgorithm[],
  algoId: string
) => {
  return algoArray.filter(a => a.algorithm.id !== algoId);
};

export const updatedOpenAlgoArrayWithPersistedState = (
  openAlgos: IOpenAlgorithm[],
  updated?: AlgoState
) => {
  if (updated) {
    // TODO: Find affected open algo, update the state. maybe.
  }

  return openAlgos;
};

const nodeHasInfoSectionFor = (val: InfoTabType, node: AlgoNode) => {
  switch (val) {
    case AlgoNodeInfoType.info:
      return node.info ? true : false;
    case "comments":
      return node.hasComments();
    case AlgoNodeInfoType.refs:
      return node.references.length !== 0;
    case AlgoNodeInfoType.defs:
      return node.definitions ? true : false;
    case AlgoNodeInfoType.meds:
      return node.dosages ? true : false;
  }
  return false;
};

const firstAvailableFocusFor = (
  val: InfoTabType,
  node: AlgoNode
): InfoTabType => {
  if (!nodeHasInfoSectionFor(val, node)) {
    switch (val) {
      case AlgoNodeInfoType.info:
        if (node.info) {
          return val;
        }
      // Fallthrough intentional
      case AlgoNodeInfoType.refs:
        if (node.references.length !== 0) {
          return val;
        }
      // Fallthrough intentional

      case AlgoNodeInfoType.defs:
        if (node.definitions) {
          return val;
        }
      // Fallthrough intentional

      case AlgoNodeInfoType.meds:
        if (node.dosages) {
          return val;
        }
      // Fallthrough intentional

      case "comments":
        if (node.hasComments()) {
          return "comments";
        }
    }
  }
  return val;
};

export const handleTappedInfoNode = (
  { node, focus }: ITappedInfoPayload,
  openAlgorithms: IOpenAlgorithm[]
) => {
  return openAlgorithms.map(item => {
    if (item.algorithm.id !== node.algorithmId) {
      return item;
    }
    let { activeInfoNodeId, infoNodePanelFocus } = item;
    if (
      activeInfoNodeId &&
      activeInfoNodeId === node.id &&
      infoNodePanelFocus === focus
    ) {
      // clear the selection
      activeInfoNodeId = undefined;
      infoNodePanelFocus = undefined;
    } else {
      activeInfoNodeId = node.id;
      infoNodePanelFocus = firstAvailableFocusFor(focus, node);
    }

    return {
      ...item,
      activeInfoNodeId,
      infoNodePanelFocus
    };
  });
};

const evaluateNext = (
  algoDetails: IOpenAlgorithm,
  selections: ISelectionData[]
): ITargetNode => {
  const {
    algoNodes,
    algorithm,
    currentPage,
    decisionNodes,
    sectionNodes,
    algoState: { decisionsJson }
  } = algoDetails;

  if (selections.length > 0) {
    const lastSelection = selections[selections.length - 1];
    const lastNodeWithSelection = algorithm.nodes.find(
      n => n.id === lastSelection.nodeId
    );

    if (lastNodeWithSelection) {
      const {
        targetAlgorithmId,
        targetNode,
        updatedDecisions
      } = evaluateSelection(
        lastNodeWithSelection,
        lastSelection.selection,
        algoDetails,
        selections
      );

      // Ensure we have the corect length
      if (sectionNodes.length > decisionNodes.length) {
        sectionNodes.pop();
      }

      if (targetAlgorithmId) {
        const decisions = updateDecisions(updatedDecisions, {
          nextAlgoTargetId: targetAlgorithmId,
          nodeId: lastNodeWithSelection.id
        });

        return {
          otherAlgoTarget: { targetAlgorithmId },
          updatedDecisions: decisions
        };
      } else if (targetNode) {
        // tslint:disable-next-line:no-console
        console.log(`Next target node is "${nodeTitleTextPlain(targetNode)}"`);
        const dNodePath = nextDecisionNodePathForNode(
          targetNode,
          algoDetails,
          updatedDecisions
        );
        let dNode: AlgoNode | undefined;

        if (dNodePath && dNodePath.length > 0) {
          dNode = dNodePath[dNodePath.length - 1];
        }

        if (dNode instanceof AlgoNode && dNode.kind !== AlgoNodeType.terminal) {
          // return the decision node
          const column = decisionNodes.findIndex(
            dn => dn.id === (dNode as AlgoNode).id
          );
          return {
            inAlgoTarget: {
              column,
              decisionNode: dNode,
              multiSelectNext: dNodePath && dNodePath[0],
              targetNode
            },
            updatedDecisions
          };
        } else {
          let lastSectionFakeDnode = targetNode;
          if (dNode) {
            // The terminus
            lastSectionFakeDnode = dNode;
          }
          const { sectionInfo } = createSectionData(
            lastSectionFakeDnode,
            algoDetails,
            updatedDecisions,
            decisionNodes.length
          );

          algoDetails.sectionNodes.push(sectionInfo);
          return {
            inAlgoTarget: {
              column: decisionNodes.length,
              multiSelectNext: (dNodePath && dNodePath[0]) || targetNode,
              targetNode: lastSectionFakeDnode
            },
            updatedDecisions
          };
        }
      }
      return {
        otherAlgoTarget: targetAlgorithmId ? { targetAlgorithmId } : undefined,
        updatedDecisions: decisionsJson
      };
    }
  } else {
    // No selection, but we might have a target from that page anyway.
    const dNode = decisionNodes[currentPage];
    const targets = dNode.targets(algoNodes);
    if (targets.length === 1) {
      const target = targets[0];
      if (target.node) {
        const column = decisionNodes.findIndex(
          dn => dn.id === target.path.childId
        );
        return {
          inAlgoTarget: { column, targetNode: target.node },
          updatedDecisions: updateDecisions(decisionsJson, {
            selection: [dNode.id],
            nodeId: dNode.id
          })
        };
      }
    }
  }
  return {
    updatedDecisions: decisionsJson
  };
};

const targetDecisionNodeForAlgo = (
  algoDetails: IOpenAlgorithm
): ITargetNode => {
  const {
    algoState: { decisionsJson }
  } = algoDetails;
  let target: ITargetNode | undefined;
  let decisions = decisionsJson;
  let processNext = true;
  do {
    const nextTargetNode = evaluateNext(algoDetails, decisions);

    decisions = nextTargetNode.updatedDecisions;

    if (nextTargetNode.inAlgoTarget) {
      if (
        nextTargetNode.inAlgoTarget.targetNode ===
        (target && target.inAlgoTarget && target.inAlgoTarget.targetNode)
      ) {
        // Same target returned. Don't continue
        // tslint:disable-next-line:no-console
        console.log(
          `next target unchanged- ${
            nextTargetNode.inAlgoTarget.targetNode.kind
          }, "${nodeTitleTextPlain(nextTargetNode.inAlgoTarget.targetNode)}"`
        );
        return nextTargetNode;
      }

      if (nextTargetNode.inAlgoTarget.targetNode) {
        if (
          // Nodes with potential processing - enable
          [
            AlgoNodeType.varProcessor,
            AlgoNodeType.varDecision,
            AlgoNodeType.page
          ].includes(nextTargetNode.inAlgoTarget.targetNode.kind)
        ) {
          // Add an item to the decisions.
          decisions = updateDecisions(decisions, {
            nodeId: nextTargetNode.inAlgoTarget.targetNode.id,
            variableValues: {}
          });
          processNext = true;
        } else {
          processNext = false;
        }
      }
    } else {
      processNext = false;
    }

    target = nextTargetNode;
  } while (processNext);

  return target;
};

const updateSelection = (
  algorithm: IOpenAlgorithm,
  node: AlgoNode,
  selection: AlgoNode
) => {
  const selections = algorithm.algoState.decisionsJson;
  const selectionIndex = selections.findIndex(s => s.nodeId === node.id);
  let updatedSelection: ISelectionData;

  if (selectionIndex < 0) {
    updatedSelection = {
      nodeId: node.id,
      selection: [selection.id],
      variableValues: {}
    };
    selections.push(updatedSelection);
  } else {
    updatedSelection = { ...selections[selectionIndex] };

    switch (node.kind) {
      case AlgoNodeType.singleSelect:
        updatedSelection.selection = [selection.id];
        break;

      case AlgoNodeType.multiSelect:
        const existingIndex = updatedSelection.selection.indexOf(selection.id);
        if (existingIndex < 0) {
          if (selection.weight === 0) {
            if (
              updatedSelection.selection.length === 1 &&
              updatedSelection.selection[0] === selection.id
            ) {
              updatedSelection.selection = [];
            } else {
              // Then remove any other selections
              updatedSelection.selection = [selection.id];
            }
          } else if (updatedSelection.selection.length === 1) {
            const selectedId = updatedSelection.selection[0];
            const n = algorithm.algorithm.nodes.find(
              an => an.id === selectedId
            );
            if (n && n.weight === 0) {
              // Then remove any other selections
              updatedSelection.selection = [selection.id];
            } else {
              updatedSelection.selection.push(selection.id);
            }
          } else {
            updatedSelection.selection.push(selection.id);
          }
        } else {
          updatedSelection.selection.splice(existingIndex, 1);
        }
        break;

      default:
        // No selection change
        return selections;
    }
  }

  const updatedSelections = [...selections];
  updatedSelections.splice(
    selectionIndex,
    selections.length - selectionIndex,
    updatedSelection
  );
  return updatedSelections;
};

const updateVariables = (openAlgo: IOpenAlgorithm, inNode: AlgoNode) => {
  const {
    algoState: { decisionsJson },
    variableValues
  } = openAlgo;
  const index = decisionsJson.findIndex(d => d.nodeId === inNode.id);
  //
  if (index !== -1 && index !== decisionsJson.length - 1) {
    // If this not the most recent entry, start fresh
    return updateDecisions(decisionsJson.slice(0, index), {
      nodeId: inNode.id,
      variableValues: { ...variableValues }
    });
  } else {
    return updateDecisions(decisionsJson, {
      nodeId: inNode.id,
      variableValues: { ...variableValues }
    });
  }
};

const updateSection = (
  decisionNode: AlgoNode,
  column: number,
  algoDetails: IOpenAlgorithm,
  updatedDecisions: ISelectionData[],
  newTarget?: AlgoNode
) => {
  // Update new section
  const { sectionInfo, decisionIndex } = createSectionData(
    decisionNode,
    algoDetails,
    updatedDecisions,
    column,
    newTarget
  );
  algoDetails.decisionNodeIndicies[column] = decisionIndex;
  return algoDetails.sectionNodes.map((s, i) => {
    if (i !== column) {
      return s;
    }
    return sectionInfo;
  });
};

const updateSectionNodes = (
  openAlgo: IOpenAlgorithm,
  {
    node,
    column,
    decisions,
    inAlgoTarget
  }: {
    node: AlgoNode;
    column: number;
    decisions: ISelectionData[];
    inAlgoTarget: IInAlgoTarget | undefined;
  }
): IOpenAlgorithm => {
  const updatedSectionNodes = updateSection(
    node,
    column,
    openAlgo,
    decisions,
    inAlgoTarget && inAlgoTarget.multiSelectNext
  );

  return {
    ...openAlgo,
    sectionNodes: updatedSectionNodes
  };
};

export const handleTappedNode = (
  payload: IConsumeAlgoPayload,
  openAlgorithms: IOpenAlgorithm[]
) => {
  const {
    openAlgorithm,
    nodeAttributes: { node, position },
    tappedChoice
  } = payload;
  const { sectionNodes } = openAlgorithm;
  let updatedAlgorithm = cloneDeep(openAlgorithm);
  const algoIndex = openAlgorithms.findIndex(
    a => a.algorithm.id === openAlgorithm.algorithm.id
  );

  const updatedRow = { ...sectionNodes[position.column][position.row] };
  let needsPageUpdate = true;
  let previousTarget;

  // Unwind the decision stack if we've got this decision in the map already
  updatedAlgorithm.algoState.decisionsJson = unwindDecisions(
    updatedAlgorithm.algoState.decisionsJson,
    node.id
  );

  let updateAll = true;

  if (!updatedRow.multiOptionRange) {
    // Handle any tap event that would change selection
    switch (node.kind) {
      case AlgoNodeType.intermediate:
        updatedRow.expanded = !updatedRow.expanded;
        updatedAlgorithm.sectionNodes[position.column][
          position.row
        ] = updatedRow;
        updateAll = false;
        break;

      case AlgoNodeType.multiSelect:
        needsPageUpdate = false;
      // Fallthrough intentional
      case AlgoNodeType.singleSelect:
        if (tappedChoice) {
          previousTarget = targetDecisionNodeForAlgo(openAlgorithm);
          updatedAlgorithm.algoState.decisionsJson = updateSelection(
            updatedAlgorithm,
            node,
            tappedChoice
          );
        }
        break;

      case AlgoNodeType.varInput:
        updatedAlgorithm.algoState.decisionsJson = updateVariables(
          updatedAlgorithm,
          node
        );
        break;
    }
  }

  if (updateAll) {
    // Go get the next page; this updates decisions and runs the automated processing
    // for variable nodes.
    const {
      inAlgoTarget,
      // otherAlgoTarget,
      updatedDecisions
    } = targetDecisionNodeForAlgo(updatedAlgorithm);

    updatedAlgorithm = {
      ...updatedAlgorithm,
      algoState: {
        ...updatedAlgorithm.algoState,
        decisionsJson: updatedDecisions
      }
    };

    // Update the section that contained the previous target
    if (
      previousTarget &&
      previousTarget.inAlgoTarget &&
      previousTarget.inAlgoTarget !== inAlgoTarget
    ) {
      updatedAlgorithm = updateSectionNodes(updatedAlgorithm, {
        column: previousTarget.inAlgoTarget.column,
        decisions: updatedDecisions,
        inAlgoTarget: undefined,
        node: previousTarget.inAlgoTarget.decisionNode
          ? previousTarget.inAlgoTarget.decisionNode
          : previousTarget.inAlgoTarget.targetNode
      });
    }

    // Update the next target section
    if (inAlgoTarget) {
      updatedAlgorithm = updateSectionNodes(updatedAlgorithm, {
        column: inAlgoTarget.column,
        decisions: updatedDecisions,
        inAlgoTarget: undefined,
        node: inAlgoTarget.decisionNode
          ? inAlgoTarget.decisionNode
          : inAlgoTarget.targetNode
      });
    }

    // If we clicked a multi-select, then we should update the current section too
    if (node.kind === AlgoNodeType.multiSelect) {
      updatedAlgorithm = updateSectionNodes(updatedAlgorithm, {
        column: position.column,
        decisions: updatedDecisions,
        inAlgoTarget,
        node
      });
    }

    // handle the page we should show the user
    if (needsPageUpdate) {
      let pageFocus = position.column;
      if (inAlgoTarget) {
        pageFocus = inAlgoTarget.column;
      }
      if (pageFocus !== updatedAlgorithm.currentPage) {
        updatedAlgorithm = { ...updatedAlgorithm, currentPage: pageFocus };
      }
    }

    // Update all paged sections
    updatedAlgorithm.decisionNodes.forEach((n, i) => {
      if (n.kind === AlgoNodeType.page) {
        updatedAlgorithm = updateSectionNodes(updatedAlgorithm, {
          column: i,
          decisions: updatedDecisions,
          inAlgoTarget,
          node: n
        });
      }
    });
  }

  return openAlgorithms.map((item, index) => {
    if (index !== algoIndex) {
      return item;
    }
    return {
      ...item,
      ...updatedAlgorithm
    };
  });
};

interface IInAlgoTarget {
  column: number;
  decisionNode?: AlgoNode;
  multiSelectNext?: AlgoNode;
  targetNode: AlgoNode;
}

interface ITargetNode {
  inAlgoTarget?: IInAlgoTarget;
  otherAlgoTarget?: {
    targetAlgorithmId: string;
  };
  updatedDecisions: ISelectionData[];
}
